import React, { Component } from 'react';
import { Box, Typography, Divider, Grid } from '@material-ui/core';
import CustomInput from '../../../../../components/src/CustomInput.web';
import CustomPhoneInput from '../../../../../components/src/CustomPhoneInput.web';
import CustomButton from '../../../../../components/src/CustomButton.web';
import CustomSelectMenu from '../../../../../components/src/CustomSelectMenu.web';
import { IPersonalData } from '../../PeopleModuleController.web';
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import CustomDatePicker from '../../../../../components/src/CustomFields/CustomDatePicker.web';
const Config = require("../../config");

dayjs.extend(customParseFormat);
const regexDialCode = Config.isDialCode;

type Props = {
	personalData: IPersonalData;
	handleChangeValue: (val: string, key: string) => void;
	handlePostPersonalData: () => void;
	handleUpdatePersonalData: () => void;
	handleSwitchMode: (section: string, mode: 'update' | 'read' | 'post', onCancel?:boolean) => void;
	nationalityArray: any;
};

type State = {};

export default class UpdatePersonalData extends Component<Props, State> {
	constructor(props: any) {
		super(props);
	}

	renderPersonalError = (errorKey: string) => {
		const { errors } = this.props.personalData;
		const errorP = errors.some((error) => error.hasOwnProperty(errorKey))
		const textP = errors.find((x) => x.hasOwnProperty(errorKey))?.[errorKey]
		return {error: errorP, text : textP ?? undefined}
	}

	render() {
		const maxDate = new Date();
		maxDate.setDate(maxDate.getDate() - 1);

		const {
			first_name,
			last_name,
			country_code,
			date_of_birth,
			email,
			mode,
			nationality,
			phone_number,
			isCancelButtonEnabled,
		} = this.props.personalData;

		const defaultDateOfBirth = dayjs(date_of_birth, "DD-MM-YYYY");

		return (
			<Box
				sx={{ display: 'flex', flexDirection: 'column', marginBottom: '52px' }}>
				{/* TOP PART */}
				<Box>
					<Typography
						style={{
							color: '#1F1F1F',
							fontSize: '18px',
							fontStyle: 'normal',
							fontWeight: 700,
							lineHeight: '16px',
							marginBottom: '24px',
							fontFamily: 'Source Sans Pro',
						}}>
						Personal
					</Typography>
					{/* FIRST-LAST NAME */}
					<Box
						display="flex"
						alignItems="center"
						flexDirection="row"
						marginBottom="32px">
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={4}>
								<CustomInput
									required
									fullWidth
									variant="outlined"
									value={first_name}
									onChangeValue={(val) =>
										this.props.handleChangeValue(val as string, 'first_name')
									}
									data-testId="inputFirstName"
									label="First name"
									placeholder="First name"
									type="text"
									error={this.renderPersonalError("first_name").error}
									helperText={this.renderPersonalError("first_name").text}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<CustomInput
									required
									fullWidth
									variant="outlined"
									value={last_name}
									onChangeValue={(val) =>
										this.props.handleChangeValue(val as string, 'last_name')
									}
									data-testId="inputLastname"
									label="Last name"
									placeholder="Last name"
									type="text"
									error={this.renderPersonalError("last_name").error}
									helperText={this.renderPersonalError("last_name").text}
								/>
							</Grid>
						</Grid>
					</Box>

					{/* BIRTHDATE - NATIONALITY */}
					<Box
						display="flex"
						alignItems="center"
						flexDirection="row"
						marginBottom={'32px'}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={4}>
								<CustomDatePicker
									name="date_of_birth"
									dateFormat='DD/MM/YYYY'
									value={date_of_birth}
									onChange={(val) =>
										this.props.handleChangeValue(val, 'date_of_birth')
									}
									data-testId="inputBirthday"
									minDate={new Date("01/01/1900")}
									maxDate={new Date()}
									label="Date of Birth"
									placeholder='dd/mm/yyyy'
									error={this.renderPersonalError("date_of_birth").error}
									helperText={this.renderPersonalError("date_of_birth").text}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<CustomSelectMenu
									value={nationality}
									onChangeValue={(val: any) => {
										this.props.handleChangeValue(val, 'nationality')
									}}
									options={this.props.nationalityArray}
									data-testId="selectNationality"
									label="Nationality"
									placeholder="Nationality"
									error={this.renderPersonalError("nationality").error}
									helperText={this.renderPersonalError("nationality").text}
								/>
							</Grid>
						</Grid>
					</Box>
				</Box>

				<Divider />

				{/* BOTTOM PART */}
				<Box>
					<Typography
						style={{
							fontSize: '18px',
							fontWeight: 700,
							fontStyle: 'normal',
							lineHeight: '16px',
							marginTop: '24px',
							marginBottom: '24px',
							fontFamily: 'Source Sans Pro',
						}}>
						Contact details
					</Typography>
					{/* EMAIL & PHONE */}

					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={4}>
							<CustomInput
								fullWidth
								variant="outlined"
								value={email}
								onChangeValue={(e: any) =>
									this.props.handleChangeValue(e, 'email')
								}
								data-testId="inputEmail"
								label="Email address"
								placeholder="Email address"
								type="text"
								error={this.renderPersonalError("email").error}
								helperText={this.renderPersonalError("email").text}
							/>
						</Grid> 
					</Grid>
					<Box
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}>
						<Typography
							style={{
								fontSize: '16px',
								fontWeight: 600,
								fontStyle: 'normal',
								lineHeight: '16px',
								marginTop: '24px',
								marginBottom: '7px',
								fontFamily: 'Source Sans Pro',
							}}>
							Phone number
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={4}>
								<CustomPhoneInput
									variant="outlined"
									value={phone_number}
									onChangeValue={(val: string) =>
										this.props.handleChangeValue(val, 'phone_number')
									}
									data-testId="inputPhoneNumber"
									label="Phone number"
									placeholder="Phone number"
									type="text"
									countryCode={country_code}
									handleCountryCode={(val) =>
										this.props.handleChangeValue(val, 'country_code')
									}
									countryError={this.renderPersonalError("country_code").error}
									countryHelperText={this.renderPersonalError("country_code").text}
									error={this.renderPersonalError("phone_number").error}
									helperText={this.renderPersonalError("phone_number").text}
								/>
							</Grid>
						</Grid>
					</Box>
				</Box>

				{/* BUTTONS */}
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="flex-end"
							style={{
								gap: '20px',
								marginTop: '20px',
							}}>
							{isCancelButtonEnabled && (
								<CustomButton
									onClick={() =>
										this.props.handleSwitchMode('personalData', 'read', true)
									}
									data-testId="btnCancelPerson"
									disabled={!isCancelButtonEnabled}
									colorVariant="special">
									Cancel
								</CustomButton>
							)}

							<CustomButton
								colorVariant="default"
								data-testId="btnSavePerson"
								onClick={
									mode === 'post'
										? this.props.handlePostPersonalData
										: this.props.handleUpdatePersonalData
								}
							>
								Save
							</CustomButton>
						</Box>
					</Grid>
				</Grid>
			</Box>
		);
	}
}

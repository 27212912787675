import React, { Component } from 'react';
import { Box, Divider, Grid, withStyles } from '@material-ui/core';
import CustomSecondaryButton from '../../../../../components/src/CustomSecondaryButton.web';
import { ITenderData, S, configJSON } from '../../TenderModuleController.web';
import { editPageButtonSvg, projectModuleCommonStyle, requestIcon } from '../../assets';
import CustomTypography from '../../../../../components/src/CustomTypography.web';
import CustomButton from '../../../../../components/src/CustomButton.web';
import { AccessControl } from '../../../../../components/src/Permission';
import getSymbolFromCurrency from "currency-symbol-map";

type Props = {
    tenderDetails: ITenderData;
    handleSwitchMode: (section: string, mode: 'update' | 'read' | 'post') => void;
    onChange: (objectKey: keyof S, property: any, value: any | null) => void;
    projectRequestNavigation?: ()=> void;
};

type State = {};

export default class ReadTenderDetails extends Component<Props, State> {
    render() {
        const {
            tendername,
            tenderCode,
            country,
            tenderType,
            groupCompany,
            clientName,
            client,
            services,
            sectors,
            typeCurrencyLocal,
            valueOfWorkLocal,
            ourFeeLocal,
            valueOfWorkRangsUsd,
            valueOfWorkUsd,
            ourFeeUsd,
            date,
            status,
        } = this.props.tenderDetails;
        const webStyle = projectModuleCommonStyle;
        return (
            <Box
                sx={{ display: 'flex', flexDirection: 'column', marginBottom: '52px' }}>
                {/* TOP PART */}
                <Box>
                    <HeaderBox>
                        <AccessControl
                            module='Tender'
                            feature='update'
                        >
                            <CustomSecondaryButton
                                label="Edit"
                                startIcon={<img src={editPageButtonSvg} alt="Edit Page" />}
                                style={{
                                    color: '#0094A7',
                                    boxShadow: 'none',
                                    border: 'none',
                                    textDecoration: 'underline',
                                    backgroundColor: 'transparent',
                                }}
                                id="edit-btn-personal-data"
                                onClick={() => {
                                    this.props.handleSwitchMode('tenderDetails', 'update')
                                    this.props.onChange('tenderDetails', 'isCancelButtonEnabled', true)
                                }
                                }
                            />
                        </AccessControl>
                        
                    </HeaderBox>
                    {/* FIRST-LAST NAME */}
                    <RowBox>
                        <Grid container>
                            <Grid item xs={12} sm={8}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '7px' }}>Tender name</CustomTypography>
                                    <CustomTypography isContent data-test-id="tenderNameText">{tendername}</CustomTypography>
                                </Box>
                            </Grid>
                        </Grid>
                    </RowBox>

                    {/* BIRTHDATE - NATIONALITY */}
                    <RowBox>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '10px' }}>Tender Code</CustomTypography>
                                    <CustomTypography isContent>{tenderCode}</CustomTypography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '10px' }}>Tender Type</CustomTypography>
                                    <CustomTypography isContent>{tenderType}</CustomTypography>
                                </Box>
                            </Grid>
                        </Grid>
                    </RowBox>
                    <Divider />

                    <RowBox marginTop={3}>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '7px' }}>Group company</CustomTypography>
                                    <CustomTypography isContent>{groupCompany}</CustomTypography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '7px' }}>Country</CustomTypography>
                                    <CustomTypography isContent>{country}</CustomTypography>
                                </Box>
                            </Grid>
                        </Grid>
                    </RowBox>
                    <RowBox>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '7px' }}>Client</CustomTypography>
                                    <CustomTypography isContent>{client}</CustomTypography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '7px' }}>Client Name</CustomTypography>
                                    <CustomTypography isContent>{clientName}</CustomTypography>
                                </Box>
                            </Grid>
                        </Grid>
                    </RowBox>
                    <RowBox>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '10px' }}>Services</CustomTypography>
                                    <CustomTypography isContent>{services}</CustomTypography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '10px' }}>Sector</CustomTypography>
                                    <CustomTypography isContent>{sectors}</CustomTypography>
                                </Box>
                            </Grid>
                        </Grid>
                    </RowBox>
                    <RowBox>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '7px' }}>Local Currency</CustomTypography>
                                    <CustomTypography isContent>{typeCurrencyLocal}</CustomTypography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '7px' }}>Value of Work(Local Currency)</CustomTypography>
                                    <CustomTypography isContent><b>{getSymbolFromCurrency(typeCurrencyLocal)}</b>&nbsp;{valueOfWorkLocal?.toString().replace(configJSON.valueFormater, ",")}</CustomTypography>
                                    
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '7px' }}>Our Fee(Local Currency)</CustomTypography>
                                    <CustomTypography isContent><b>{getSymbolFromCurrency(typeCurrencyLocal)}</b>&nbsp;{ourFeeLocal?.toString().replace(configJSON.valueFormater, ",")}</CustomTypography>
                                </Box>
                            </Grid>
                        </Grid>
                    </RowBox>
                    <RowBox>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '7px' }}>Value of Work Range(USD)</CustomTypography>
                                    <CustomTypography isContent>{valueOfWorkRangsUsd}</CustomTypography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '7px' }}>Value of Work(USD)</CustomTypography>
                                    <CustomTypography isContent><b>{getSymbolFromCurrency('USD')}</b>&nbsp;{valueOfWorkUsd?.toString().replace(configJSON.valueFormater, ",")}</CustomTypography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '7px' }}>Our fee(USD)</CustomTypography>
                                    <CustomTypography isContent><b>{getSymbolFromCurrency('USD')}</b>&nbsp;{ourFeeUsd?.toString().replace(configJSON.valueFormater, ",")}</CustomTypography>
                                </Box>
                            </Grid>
                        </Grid>
                    </RowBox>
                    <Divider />
                    <RowBox marginTop={3}>
                        <Grid container>
                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '7px' }}>Status</CustomTypography>
                                    <CustomTypography isContent>{status}</CustomTypography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <Box>
                                    <CustomTypography isLabel style={{ marginBottom: '7px' }}>Date</CustomTypography>
                                    <CustomTypography isContent>{date}</CustomTypography>
                                </Box>
                            </Grid>
                           
                        </Grid>
                    </RowBox>
                    <Box sx={webStyle.requestButtonTender}>
                                <CustomButton
                                    colorVariant="projectRequest"
                                    startIcon={<img src={requestIcon} alt="warn" />}
                                    onClick={this.props.projectRequestNavigation}
                                >
                                    Request
                                </CustomButton>
                            </Box>
                </Box>
            </Box>
        );
    }
}

const RowBox = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: '32px',
    },
})(Box);

const HeaderBox = withStyles({
    root: {
        width: '100%',
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'end',
        flexDirection: 'row',
    },
})(Box);

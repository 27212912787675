import Alert from '@material-ui/lab/Alert';
import {
  Grid,
  Theme,
  Box,
  createTheme,
  MuiThemeProvider,
  createStyles,
  Typography,
  Accordion,
  TableRow as TabRow,
  TableCell as TabCell,
  TableBody as TabBody,
  TableHead as TabHead,
  Table,
  TableContainer as TabContainer,
  Paper,
  AccordionDetails as AccDetails,
  AccordionSummary as AccSummary,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  withStyles,
} from "@material-ui/core";
import React from "react";

import ProjectModuleHeader from "../../multipageforms/src/Components/ProjectModule/ProjectModuleHeader.web";
import PartnerImportDataController, { Props } from "./PartnerImportDataController";
import {
  configJSON 
} from "./ImportExportDataController.web";
import ImportView from "./Components/ImportView.web";
import Button from "../../../components/src/CustomButton.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const THEME = createTheme({
  typography: {
    fontFamily: "Source Sans Pro",
    fontWeightMedium: 600,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontSize: 14,
  },
});

export class PartnerImportData extends PartnerImportDataController {

  constructor(props: Props) {
    super(props);
  }

  renderImportView() {
    const classes = this.props.classes;

    return (
      <Box marginTop={2} className={classes.containerSucess}>
        <ProjectModuleHeader data-testId="projectModuleHeader" saveAsPDF={false} handleBackClick={this.handleGoBack}/>
        <ImportView
          sampleXLSFile={configJSON.sampleXLSXPartnerData}
          onSubmitImport = {this.handleImportFile}
          module={"partners"}
          navigation={this.props.navigation}
          sampleCSVFormat={configJSON.exportSamplePartnerCSVData}
          data-testId="importView"
        />
      </Box>
    );
  }

  renderSuccessPage() {
    const classes = this.props.classes;
    const { reportMetaData } = this.state
    return (
      <Box marginTop={2} className={classes.containerSucess} paddingBottom={2}>
        <ProjectModuleHeader data-testId="projectModuleHeader" handleBackClick={this.handleGoBack} saveAsPDF={false}/>
        <Grid container  alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={10} md={8} lg={7}>
            <Box className={classes.sucessBox} marginTop={2}>
              <Alert 
                className={classes.alertBox}
                severity="success" 
                data-testId="summary" 
                >
                  { reportMetaData?.summary.summaryMessage  }
              </Alert>

              <Box className={classes.listErrorBox}>

                <Accordion  style={{width: '100%'}} key={`report_summary`} className={classes.customAccordion}>
                  <AccSummary
                    id="panel1a-header"
                    aria-controls="panel1a-content"
                    expandIcon={<ExpandMoreIcon />}
                    style={{borderBottom: "1px solid #f3f3f3"}}
                  >
                    <Typography variant='body1' className={classes.headingReport}>{ reportMetaData?.importedMessage }</Typography>
                  </AccSummary>
                  <AccDetails>
                    <List dense={true} className={classes.listDetailReport}>
                        <ListItem key={`error_report_1`}>
                          <ListItemText primary={ reportMetaData?.summary.createdRecords } />
                        </ListItem>

                        <ListItem key={`error_report_2`}>
                          <ListItemText primary={ reportMetaData?.summary.oveririddenRecords} />
                        </ListItem>
                    </List>
                  </AccDetails>
                
                </Accordion>

                <Accordion key={`report_skip`} style={{width: '100%'}}  className={classes.customAccordion}>
                  <AccSummary
                    style={{borderBottom: "1px solid #f3f3f3"}}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant='body1' className={classes.headingReport}> { reportMetaData?.skippedMessage } </Typography>
                  </AccSummary>
                  { (reportMetaData?.skippedErrors || []).length > 0 &&
                    <AccDetails>
                    <TabContainer component={Paper}>
                      <Table className={classes.table} size="small" >
                        <TabHead>
                          <TabRow>
                            <TabCell>Row Number</TabCell>
                            <TabCell>Company Name</TabCell>
                            <TabCell>Error Message</TabCell>
                          </TabRow>
                        </TabHead>
                        <TabBody>
                          {reportMetaData?.skippedErrors.map((row) => (
                            <TabRow key={`row_${row.row}`}>
                              <TabCell component="th">
                                Row {row.row}
                              </TabCell>
                              <TabCell>{row?.value || 'Nil'}</TabCell>
                              <TabCell>{row.error}</TabCell>
                            </TabRow>
                          ))}
                        </TabBody>
                      </Table>
                    </TabContainer>
                  </AccDetails>
                  }
                  
                
                </Accordion>

                <Accordion key={`report_override`} style={{width: '100%'}}  className={classes.customAccordion}>
                  <AccSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{borderBottom: "1px solid #f3f3f3"}}
                  >
                    <Typography variant='body1' className={classes.headingReport}> {reportMetaData?.notImportMessage} </Typography>
                  </AccSummary>
                  { (reportMetaData?.recordErrors || []).length > 0 &&
                    <AccDetails>
                      <TabContainer component={Paper}>
                        <Table className={classes.table} size="small" >
                          <TabHead>
                            <TabRow>
                              <TabCell>Row Number</TabCell>
                              <TabCell>Company Name</TabCell>
                              <TabCell>Field Name</TabCell>
                              <TabCell>Error Message</TabCell>
                            </TabRow>
                          </TabHead>
                          <TabBody>
                            {reportMetaData?.recordErrors.map((row) => (
                              <TabRow key={`row_${row.row}`}>
                                <TabCell component="th">
                                  Row {row.row}
                                </TabCell>
                                <TabCell>{row?.value || 'Nil'}</TabCell>
                                <TabCell>{row.fieldName}</TabCell>
                                <TabCell>{row.error}</TabCell>
                              </TabRow>
                            ))}
                          </TabBody>
                        </Table>
                      </TabContainer>
                  </AccDetails>
                  }
                  
                </Accordion>
              </Box>
          
              <Button 
                onClick={() => this.resetImport() }
                data-testId="addAnotherBtn" 
                style={{ marginTop: 16 }} 
              >
                  Add Another
                </Button>
            </Box>
            
          </Grid>
        </Grid>
      </Box>

    )
  }

  
  render() {
    // Customizable Area Start
    return (<>
      <MuiThemeProvider 
        theme={THEME}
      >
        <div style={cusStyle.rootContainer}>
        {
        this.state.isShowReport ? 
          this.renderSuccessPage() :
          this.renderImportView()
        }
          {
          this.state.hasError && (
          <Snackbar
          open={!!this.state.hasError} 
          onClose={() => {
            this.setState({
              hasError: "",
            });
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert 
              data-testId="snack-error" 
              severity="error">
                { this.state.hasError }
              </Alert>
          </Snackbar>
        )}
        </div>
      </MuiThemeProvider>
    </>);
    // Customizable Area Start
  }
}

export const cusStyle = {
  rootContainer: {
    width: '100%',
    display: 'flex',
    backgroundColor: '#F3F3F3',
    fontFamily: 'Source Sans Pro',
  },
}
export const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      position: "relative",
    },
    mainChildWrapper: {
      transition: "width 0.4s ease-out",
      minHeight: "100vh",
      marginTop: "85px",
    },
    uploadFileBox: {
      padding: "40px",
      justifyContent: "center",
      borderRadius: "8px",
      background: "rgba(0, 148, 167, 0.08)",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      alignItems: "center",
      // border: "1px solid rgba(0, 148, 167, 0.40)",
    },
    expandSidebarIconBox: {   
      borderRadius: "8px",
      justifyContent: "center",
      height: "20px",
      alignItems: "center",
      position: "fixed",
      backgroundColor: "rgb(253, 215, 47)",
      padding: "5px",
      display: "flex",
      cursor: "pointer",
      transition: "left 0.4s ease-out",
      top: "127px",
      width: "20px",
      zIndex: 1,
    },
    earch: {
      borderRadius: '8px',
      position: "relative",
      "&:hover": {
        backgroundColor: "white",
      },
      "& fieldset": {
        border: "1px solid #E8E8E8 !important"
      },
      backgroundColor: "white",
      marginLeft: 0,
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px'
      },
      "& .MuiOutlinedInput-input": {
        padding: '14px'
      },
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '0px'
      },
      width: "40%",
      "& .MuiOutlinedInput-adornedStart": {
      },
    },
    sucessBox: {
      marginBottom: 24,
      borderRadius: "8px",
      justifyContent: "center",
      display: "flex",
      padding: "40px",
      alignItems: "center",
      // border: "1px solid rgba(0, 148, 167, 0.40)",
      flexDirection: "column",
      background: "rgba(0, 148, 167, 0.08)",
    },
    sidenav: {
      transition: "width 0.3s ease-in-out",
      paddingTop: "28px",
      backgroundColor: "rgb(10, 25, 41)",
      height: "100vh",
      width: "250px",
    },
    container: {
      gap: "25px",
      height: "100%",
      backgroundColor: "#FFF",
      flexDirection: "column",
      marginTop: "20px",
      display: "flex",
    },
    sidenavClosed: {
      width: "60px",
      composes: "sidenav",
      transition: "width 0.3s ease-in-out",
    },
    sideitem: {
      overflow: "hidden",
      display: "flex",
      padding: "10px 20px",
      cursor: "pointer",
      color: "#b2bac2",
      textDecoration: "none",
      alignItems: "center",
    },
    "& .sideitem:hover": {
      backgroundColor: "#244f7d1c",
    },
    
    menuBtn: {
      paddingLeft: "20px",
      cursor: "pointer",
      boxShadow: "3px",
      borderRadius: "10px",
      position: "relative",
      top: "124px",
      justifySelf: "flex-end",
      transition: "width 0.3s ease-in-out",
      border: "none",
      backgroundColor: "transparent",
      zIndex: 1000000,
      alignSelf: "center",
      color: "#b2bac2",
      left: "40px",
    },
    linkText: {
      paddingLeft: "16px",
    },
    menuBtnExpand: {
      top: "124px",
      position: "relative",
      paddingLeft: "20px",
      backgroundColor: "transparent",
      left: "230px",
      zIndex: 1000000,
      cursor: "pointer",
      justifySelf: "flex-end",
      border: "none",
      alignSelf: "center",
      color: "#b2bac2",
      transition: "width 0.3s ease-in-out",
    },
    containerSucess: {
      width: '100%',
      height: "100%",
      "@media (max-width: 960px)": {
        padding: 5
       },
      backgroundColor: "#FFF",
    },
    headingReport: {
      color: "#6a6e6f",
      fontSize: 16,
      "& span": {
        color: "#f50057",
        fontWeight: 600,
        marginLeft: 4
      },
      marginLeft: '8px',
    },

    reportText: {
      fontWeight: 600,
      textTransform: 'uppercase',
      color: '#0094a7',
      marginBottom: '24px',
    },

    listDetailReport: {
      "& .MuiTypography-body2": {
        color: "#6a6e6f",
      }
    },
    customAccordion: {
      '& .MuiAccordion-root.Mui-expanded': {
        margin: 0
      }
    },
    reportSingleBox: {
      margin: '8px 0',
      width: '100%',
      padding: '12px',

      "& span.value": {
        color: '#3c7e40',
        fontWeight: '600'
      },

      "& span.title": {
        width: 150,
        color: "#1f1f1f",
        display: 'inline-block',
      },
    },
    rowErrorText: {
      marginBottom: '10px',
      width: '100%',
      color: "#4c4c4c",
      marginLeft: -20,
      textAlign: 'left',
    },
    alertBox: {
      width: '100%',
      marginBottom: '12px'
    },
    table: {
      minWidth: 650,
    },
    listErrorBox: {
      maxHeight: '600px',
      overflowY: 'scroll',
      width: '100%',
    }
});

export default withStyles(styles)(PartnerImportData)




import React, { Component } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import CustomTypographyWeb from "../../../../../components/src/CustomTypography.web";
import { Certificate, S, configJSON } from "../../ProjectModuleController.web";
import { deleteIcon, exampleCertificateImg, projectModuleCommonStyle } from "../../assets";
import CustomButton from "../../../../../components/src/CustomButton.web";
import ClickedDropDown from "../../../../../components/src/CustomFields/DropDownOptions.web";
import { ArrowUpward } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";

interface Props {
  mode: string;
  certificates: Certificate[];
  testId: string;
  onChange: (changedPropName: keyof S, value: any) => void;
  onDeleteCertificate: (id: number, type: string) => void;
  onCertificateUpload: boolean;
  onFileChange: (event: any) => void;
  openImage: (imageUrl: string) => void;
  certificatesToUpload: File[];
  removeStagedFile: (fileIndex: number) => void;
}

export default class CertificatesView extends Component<Props> {
  truncateText = (str: string, length: number = 15) => {
    if (str.length >= length) return str.slice(0, length) + "...";
    return str;
  };
  render() {
    const {
      mode,
      certificates,
      onCertificateUpload,
      certificatesToUpload,
      testId,
    } = this.props;
    const webStyle = projectModuleCommonStyle;

    const showButton =
      onCertificateUpload ||
      certificates.length === 0 ||
      certificatesToUpload.length > 0;

    const uploadCertRef: React.RefObject<HTMLInputElement> = React.createRef();
    return (
      <Box sx={webStyle.containerWrapper} data-test-id={testId}>
        <Grid container style={webStyle.gridContainer}>
          <Grid item xs={12}>
            <Box sx={projectModuleCommonStyle.galleryContainer}>
              <CustomTypographyWeb isHeader>
                Certificates
              </CustomTypographyWeb>
              {mode !== configJSON.viewRecordMode && showButton ? (
                <>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                    }}
                  >
                    {certificatesToUpload.length > 0 &&
                      certificatesToUpload.map(
                        (certificate: File, index: number) => (
                          <Box
                            key={index}
                            data-test-id={`${configJSON.certificatesToUploadTestId}-${index}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "8px",
                              justifyContent: 'start',
                            }}
                          >
                            <Typography
                            data-test-id={`label-${configJSON.certificatesToUploadTestId}-${index}`}
                              style={{
                                color: "#0094A7",
                                fontFamily: "Source Sans Pro",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "16px",
                              }}
                            >
                              {this.truncateText(certificate.name, (window.innerWidth < 768 ? 20 : 30))}
                            </Typography>
                            <Button
                            data-test-id={`removebutton-${configJSON.certificatesToUploadTestId}-${index}`}
                              style={{ marginLeft: "8px" }}
                              onClick={() => this.props.removeStagedFile(index)}
                              size="small"
                            >
                              <CloseIcon />
                            </Button>
                          </Box>
                        )
                      )}
                  </Box>
                  <input
                    data-test-id={
                      configJSON.projectCertificatesUploadFileInputTestId
                    }
                    type="file"
                    hidden
                    onChange={this.props.onFileChange}
                    ref={uploadCertRef}
                    accept=".jpg, .jpeg, .pdf"
                  />

                  <CustomButton
                    data-test-id={
                      configJSON.projectCertificatesUploadButtonTestId
                    }
                    startIcon={<ArrowUpward />}
                    style={projectModuleCommonStyle.imageUpload}
                    onClick={() => uploadCertRef.current?.click()}
                  >
                    Upload
                  </CustomButton>
                  <Typography
                    style={projectModuleCommonStyle.imageDocumentInfo}
                  >
                    Note: <br />
                    Supported files: .PDF, JPG, JPEG <br />
                    Max size: 10MB
                  </Typography>
                </>
              ) : (
                <Grid container>
                  <Grid item xs={10}>
                    <Box
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "25px",
                      }}
                    >
                      {certificates.length > 0 &&
                        certificates.map(
                          (certificate: Certificate, index: number) => {
                            return (
                              <Box
                                data-test-id={`${configJSON.projectCerticateTestId}-${index}`}
                                key={certificate.certificate_id}
                                sx={projectModuleCommonStyle.certificateCard}
                              >
                                <Box
                                  data-test-id={`certificate-${configJSON.projectCerticateTestId}-${index}`}
                                  style={{
                                    ...projectModuleCommonStyle.imageWithBackground,
                                    backgroundImage: `url(${
                                      certificate.certificate_name.includes(
                                        "pdf"
                                      )
                                        ? exampleCertificateImg
                                        : certificate.certificate_link
                                    })`,
                                  }}
                                  onClick={() => {
                                    this.props.openImage(
                                      certificate.certificate_link
                                    );
                                  }}
                                />
                                <Box
                                  style={
                                    projectModuleCommonStyle.certificateCardLabel
                                  }
                                >
                                  <Box
                                    style={{
                                      flexGrow: 1,
                                      textAlign: "center",
                                    }}
                                  >
                                    <CustomTypographyWeb
                                      isLabel
                                      data-test-id={`${configJSON.projectCertificateDocumentName}-${index}`}
                                    >
                                      {this.truncateText(
                                        certificate.certificate_name
                                      )}
                                    </CustomTypographyWeb>
                                  </Box>
                                  {mode !== configJSON.viewRecordMode && (
                                    <ClickedDropDown
                                      data-test-id={`certificate-remove-${configJSON.projectCerticateTestId}-${index}`}
                                      options={[
                                        {
                                          label: "Delete",
                                          icon: deleteIcon,
                                          onClick: () => {
                                            this.props.onDeleteCertificate(
                                              certificate.certificate_id,
                                              "certificate"
                                            );
                                          },
                                        },
                                      ]}
                                    />
                                  )}
                                </Box>
                              </Box>
                            );
                          }
                        )}
                    </Box>
                    {mode !== configJSON.viewRecordMode &&
                      certificates.length !== 0 && (
                        <CustomButton
                          data-test-id={
                            configJSON.projectCertificatesAddMoreButtonTestId
                          }
                          colorVariant="special"
                          style={projectModuleCommonStyle.addMoreButton}
                          onClick={() =>
                            this.props.onChange("onCertificateUpload", true)
                          }
                        >
                          Add more
                        </CustomButton>
                      )}
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

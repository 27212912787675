import React, { Component } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
} from '@material-ui/core';
import CustomTypographyWeb from '../../../../../components/src/CustomTypography.web';
import { ErrorObject, S, configJSON, dropDownItems } from '../../ProjectModuleController.web';
import CustomRadioButton from '../../../../../components/src/CustomRadioButton.web';
import { CustomTextField, projectCurrency, projectModuleCommonStyle, NumericTextField, DisabledTextField } from '../../assets';
import AutocompleteSelect from '../../../../../components/src/CustomFields/AutocompleteSelectMenu.web';
import { ErrorRounded } from '@material-ui/icons';
import { NumericFormat } from 'react-number-format';
import getSymbolFromCurrency from "currency-symbol-map";
import CustomDatePicker from '../../../../../components/src/CustomFields/CustomDatePicker.web';
import moment from 'moment';

interface Props {
  mode: string;
  projectDetails: any;
  testId: string;
  errors: ErrorObject[];
  projectServices: dropDownItems[];
  projectSectors: dropDownItems[];
  projectClients: dropDownItems[];
  projectCountries: dropDownItems[];
  projectGroupCompanies: dropDownItems[];
  projectServiceTypes: dropDownItems[];
  projectTypesOfCurrency: dropDownItems[];
  projectValuesOfWork: dropDownItems[];
  projectStatusOptions: dropDownItems[];
  onChange: (changedPropName: keyof S, value: any) => void;
  onRadioChange: (event: any) => void;
}

export const CustomInputComponent = ({ inputRef,prefix, ...rest } : any) => {
  return (
     <NumericFormat
         ref={inputRef}
         {...rest}
         thousandsGroupStyle={"lakh"}
         thousandSeparator=","
         decimalScale={4}
         allowNegative={false}
     />
  )
 };
export default class ProjectDetailsView extends Component<Props> {

  renderProjectError = (errorK: string) => {
		const { errors } = this.props;
		const errorPr = errors.some((error: ErrorObject) => error.hasOwnProperty(errorK))
		const textPr = errors.find((x: ErrorObject) => x.hasOwnProperty(errorK))?.[errorK] ?? undefined
		return {error: errorPr, text : textPr }
	}

  renderProjectDuration = () => {
    const webStyle = projectModuleCommonStyle
    const { projectDetails, mode, projectServiceTypes, projectStatusOptions} = this.props;
    const isoStartDate = projectDetails.start_date !== '' && !isNaN(Date.parse(projectDetails.start_date))
    ? new Date(projectDetails.start_date).toISOString().slice(0, 10)
    : '';
    return <> 
       <Grid container spacing={2} style={webStyle.gridContainer1} id="duration-1">
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Service Type</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent data-test-id={configJSON.service_typeTestId}>{projectDetails.service_type}</CustomTypographyWeb></Grid>
              ) : (
                <Grid item sm={10}>
                  <AutocompleteSelect
                    data-test-id={configJSON.service_typeTestId}
                    placeholder='Service type'
                    onChange={(value: string) => this.props.onChange('service_type', value)}
                    options={projectServiceTypes}
                    value={projectDetails.service_type}
                    error={this.renderProjectError('service_type').error}
                    helperText={this.renderProjectError('service_type').text}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>
          <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Project Status</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent data-test-id={configJSON.project_statusTestId}>{projectDetails.project_status}</CustomTypographyWeb></Grid>
              ) : (
                <Grid item sm={10}>
                  <AutocompleteSelect
                    data-test-id={configJSON.project_statusTestId}
                    placeholder='Project Status'
                    onChange={(value: string) => this.props.onChange('project_status', value)}
                    options={projectStatusOptions}
                    value={projectDetails.project_status}
                    error={this.renderProjectError('project_status').error}
                    helperText={this.renderProjectError('project_status').text}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      <CustomTypographyWeb isLabel style={webStyle.viewComponent1}>Project Duration</CustomTypographyWeb>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          {/* <Box> */}
            {mode === configJSON.viewRecordMode ? (<>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Start Date</CustomTypographyWeb>
              <CustomTypographyWeb isContent data-test-id={configJSON.start_dateTestId}>{projectDetails.start_date}</CustomTypographyWeb>
            </>) : (
              <Grid item sm={10}>
                <CustomDatePicker
                  name="start_date"
                  dateFormat='DD/MM/YYYY'
                  value={projectDetails.start_date}
                  data-test-id={configJSON.start_dateTestId}

                  onChange={(val) =>
                    this.props.onChange('start_date', val)
                  }
                  label=""
                  minDate={new Date("01/01/1900")}
                  maxDate={projectDetails.end_date ? moment(projectDetails.end_date, "DD/MM/YYYY").toDate() : undefined}
                  inputLabel='Start date'
                  placeholder='dd/mm/yyyy'
                  error={this.renderProjectError('start_date').error}
                  helperText={this.renderProjectError('start_date').text}
                />
              </Grid>
            )}
          {/* </Box> */}
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          {/* <Box> */}
            {mode === configJSON.viewRecordMode ? (<>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>End Date</CustomTypographyWeb>
              <CustomTypographyWeb isContent data-test-id={configJSON.end_dateTestId}>{projectDetails.end_date}</CustomTypographyWeb>
            </>) : (
              <Grid item sm={10}>
                 <CustomDatePicker
                  name="end_date"
                  value={projectDetails.end_date}
                  dateFormat='DD/MM/YYYY'
                  minDate={projectDetails.start_date ? moment(projectDetails.start_date, "DD/MM/YYYY").toDate() : new Date("01/01/1900")}
                  data-test-id={configJSON.end_dateTestId}
                  onChange={(val) =>
                    this.props.onChange('end_date', val)
                  }
                  placeholder='dd/mm/yyyy'
                  error={this.renderProjectError('end_date').error}
                  label=""
                  inputLabel='End date'
                  helperText={this.renderProjectError('end_date').text}
                />
              </Grid>
            )}
          {/* </Box> */}
        </Grid>
      </Grid>
    </>
  }

  renderGroupDetails = () => {
    const webStyle = projectModuleCommonStyle
    const { 
      projectDetails,
      mode,
      projectClients,
      projectSectors,
      projectCountries,
      projectGroupCompanies,
      projectServices,} = this.props;
    return <>
       <Grid container spacing={2} style={webStyle.gridContainer1} id="group_details_1">
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Group Company</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent data-test-id={configJSON.group_companyTestId}>{projectDetails.group_company}</CustomTypographyWeb></Grid>
              ) : (
                <Grid item sm={10}>
                  <AutocompleteSelect
                    data-test-id={configJSON.group_companyTestId}
                    value={projectDetails.group_company}
                    helperText={this.renderProjectError('group_company').text}
                    options={projectGroupCompanies}
                    onChange={(value: string) => this.props.onChange('group_company', value)}
                    placeholder='Group Company'
                    error={this.renderProjectError('group_company').error}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Country</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent data-test-id={configJSON.countryTestId}>{projectDetails.country}</CustomTypographyWeb></Grid>
              ) : (
                <Grid item sm={10}>
                  <AutocompleteSelect
                    data-test-id={configJSON.countryTestId}
                    placeholder='Country'
                    value={projectDetails.country}
                    onChange={(value: string) => this.props.onChange('country', value)}
                    error={this.renderProjectError('country').error}
                    options={projectCountries}
                    helperText={this.renderProjectError('country').text}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={webStyle.gridContainer} id="group_details_2">
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Client</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent data-test-id={configJSON.clientTestId}>{projectDetails.client}</CustomTypographyWeb></Grid>
              ) : (
                <Grid item sm={10}>
                  <AutocompleteSelect
                    data-test-id={configJSON.clientTestId}
                    placeholder='Client'
                    value={projectDetails.client}
                    error={this.renderProjectError('client').error}
                    onChange={(value: string) => this.props.onChange('client', value)}
                    helperText={this.renderProjectError('client').text}
                    options={projectClients}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Client Name</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent data-test-id={configJSON.client_nameTestId}>{projectDetails.client_name}</CustomTypographyWeb></Grid>
              ) : (
                <Grid item sm={10}>
                  <CustomTextField
                    placeholder='Client name'
                    data-test-id={configJSON.client_nameTestId}
                    fullWidth
                    value={projectDetails.client_name}
                    error={this.renderProjectError('client_name').error}
                    onChange={(event: any) => this.props.onChange('client_name', event.target.value)}
                    variant="outlined"
                    helperText={this.renderProjectError('client_name').text}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={webStyle.gridContainer} id="group_details_3">
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Services</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent data-test-id={configJSON.servicesTestId}>{projectDetails.services}</CustomTypographyWeb></Grid>
              ) : (
                <Grid item sm={10}>
                  <AutocompleteSelect
                    data-test-id={configJSON.servicesTestId}
                    multipleSelection
                    placeholder='Services'
                    onChange={(value: string) => this.props.onChange('services', value)}
                    value={projectDetails.services}
                    error={this.renderProjectError('services').error}
                    helperText={this.renderProjectError('services').text}
                    options={projectServices}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Sector</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent data-test-id={configJSON.sectorTestId}>{projectDetails.sector}</CustomTypographyWeb></Grid>
              ) : (
                <Grid item sm={10}>
                  <AutocompleteSelect
                    data-test-id={configJSON.sectorTestId}
                    placeholder='Sector'
                    onChange={(value: string) => this.props.onChange('sector', value)}
                    options={projectSectors}
                    error={this.renderProjectError('sector').error}
                    value={projectDetails.sector}
                    helperText={this.renderProjectError('sector').text}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
    </>
  }

  renderProjectDetails = () => {
    const webStyle = projectModuleCommonStyle
    const { 
      projectDetails,
      mode,

      } = this.props;
    
    return <>
      <Grid container spacing={2} style={webStyle.gridContainer} id="project_details_1">
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Project Code</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}>
                  <CustomTypographyWeb isContent
                    data-test-id={configJSON.project_codeTestId}>
                    {projectDetails.project_code}
                  </CustomTypographyWeb>
                </Grid>
              ) : (
                <Grid item sm={10}>
                  <CustomTextField
                    variant="outlined"
                    placeholder='Project code'
                    data-test-id={configJSON.project_codeTestId}
                    value={projectDetails.project_code}
                    onChange={(event: any) => this.props.onChange('project_code', event.target.value)}
                    error={this.renderProjectError('project_code').error}
                    fullWidth
                    helperText={this.renderProjectError('project_code').text}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}
              >Project Title</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent data-test-id={configJSON.project_titleTestId}>{projectDetails.project_title}</CustomTypographyWeb></Grid>
              ) : (
                <Grid item sm={10}>
                  <CustomTextField
                    variant="outlined"
                    placeholder='Project title'
                    value={projectDetails.project_title}
                    fullWidth
                    onChange={(event: any) => this.props.onChange('project_title', event.target.value)}
                    error={this.renderProjectError('project_title').error}
                    data-test-id={configJSON.project_titleTestId}
                    helperText={this.renderProjectError('project_title').text}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={webStyle.gridContainer} id="project_details_2">
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Joint Venture {this.renderProjectError('joint_venture').error && (
                <ErrorRounded
                  color="error"
                  fill="#DC2626"
                  style={{
                    width: '16px',
                    height: '16px',
                  }}
                />
              )}</CustomTypographyWeb>

              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent data-test-id={configJSON.joint_ventureTestId}>{projectDetails.joint_venture === configJSON.jointVentureTrue ? 'Yes' : 'No'}</CustomTypographyWeb></Grid>
              ) : (
                <Box
                  style={webStyle.jointVentureInput}>
                  <FormControl
                    component="fieldset">
                    <RadioGroup row data-test-id={configJSON.joint_ventureTestId}
                      onChange={this.props.onRadioChange}
                      value={projectDetails.joint_venture}
                      style={webStyle.formInputRadioGroup}
                    >
                      <FormControlLabel
                        control={
                          <CustomRadioButton
                            data-test-id={configJSON.joint_ventureYesTestId}
                            checked={projectDetails.joint_venture == configJSON.jointVentureTrue}
                            name='yes'
                          />
                        }
                        label={(<CustomTypographyWeb isContent>Yes</CustomTypographyWeb>)}
                        style={webStyle.checkBoxLabelStyle}
                      />
                      <FormControlLabel
                        control={
                          <CustomRadioButton
                            data-test-id={configJSON.joint_ventureNoTestId}
                            checked={projectDetails.joint_venture == configJSON.jointVentureFalse}
                            name='no'
                          />
                        }
                        label={(<CustomTypographyWeb isContent>No</CustomTypographyWeb>)}
                        style={webStyle.checkBoxLabelStyle}
                      />
                    </RadioGroup>
                  </FormControl>
                  {this.renderProjectError('joint_venture').error && (
                    <span
                      data-test-id={configJSON.projectModuleJointVentureErrorTestId}
                      style={{
                        fontFamily: "Source Sans Pro",
                        margin: '0 0 0 5px',
                        textAlign: 'left',
                        fontWeight: 400,
                        lineHeight: 1.66,
                        letterSpacing: '0.03em',
                        fontSize: '0.75rem',
                        color: '#f44336',
                      }}>
                      {this.renderProjectError('joint_venture').text}
                    </span>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>JV Share(%)</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <CustomTypographyWeb
                  isContent
                  style={projectDetails.joint_venture === configJSON.jointVentureFalse ? webStyle.disabledViewContent : {}}
                  data-test-id={configJSON.joint_venture_shareTestId}>{projectDetails.joint_venture_share} {" "}%</CustomTypographyWeb>
              ) : (
                <Grid item sm={10}>
                  <CustomTextField
                    data-test-id={configJSON.joint_venture_shareTestId}
                    variant="outlined"
                    placeholder='JVShare percentage'
                    fullWidth
                    disabled={!projectDetails.joint_venture}
                    value={projectDetails.joint_venture_share}
                    onChange={(event: any) => this.props.onChange('joint_venture_share', event.target.value)}
                    type="number"
                    inputProps={{
                      maxLength: 3
                    }}
                    style={projectDetails.joint_venture === configJSON.jointVentureFalse ? webStyle.disabledViewContent : {}}
                    error={this.renderProjectError('joint_venture_share').error}
                    helperText={this.renderProjectError('joint_venture_share').text}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
    </>
  }

  render() {
    const {
      mode,
      projectDetails,
      projectTypesOfCurrency,
      testId,
    } = this.props;
    const webStyle = projectModuleCommonStyle;
    return (
      <Box
        sx={webStyle.containerWrapper}
        data-test-id={testId}>
        <Grid container spacing={2} style={webStyle.gridContainer} id="project_details_3">
          <Grid item xs={12} md={10} lg={8}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Project Name</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent data-test-id={configJSON.project_nameTestId}>{projectDetails.project_name}</CustomTypographyWeb></Grid>
              ) : (
                <Grid item sm={11}>
                  <CustomTextField
                    variant="outlined"
                    placeholder='Project name'
                    helperText={this.renderProjectError('project_name').text}
                    data-test-id={configJSON.project_nameTestId}
                    fullWidth
                    value={projectDetails.project_name}
                    onChange={(event: any) => this.props.onChange('project_name', event.target.value)}
                    error={this.renderProjectError('project_name').error}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>

        {this.renderProjectDetails()}

        <Divider />
        {this.renderGroupDetails()}
        <Divider />

        <Grid container spacing={2} style={webStyle.gridContainer1} id="currency_details_1">
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Local Currency</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent data-test-id={configJSON.inputTypeOfCurrencyTestId}>{projectDetails.type_of_currency}</CustomTypographyWeb></Grid>
              ) : (
                <Grid item sm={10}>
                  <AutocompleteSelect
                    data-test-id={configJSON.inputTypeOfCurrencyTestId}
                    placeholder='Local Currency'
                    onChange={(value: string) => this.props.onChange('type_of_currency', value)}
                    error={this.renderProjectError('type_of_currency').error}
                    options={projectTypesOfCurrency}
                    value={projectDetails.type_of_currency}
                    helperText={this.renderProjectError('type_of_currency').text}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Value Of Work(Local Currency)</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent data-test-id={configJSON.value_of_workTestId}><b>{getSymbolFromCurrency(projectDetails.type_of_currency)}</b> {projectDetails.value_of_work?.toString().replace(configJSON.valueFormater, ",")}</CustomTypographyWeb></Grid>
              ) : (
                <Grid item sm={10}>
                    <NumericTextField
                      data-test-id={configJSON.value_of_workTestId}
                      error={this.renderProjectError('value_of_work').error}
                      variant="outlined"
                      placeholder='Enter value'
                      fullWidth
                      value={projectDetails.value_of_work}
                      disabled={!projectDetails.type_of_currency}
                      onChange={(event: any) => this.props.onChange('value_of_work', event.target.value)}
                      helperText={this.renderProjectError('value_of_work').text}
                      InputProps={{
                        inputComponent: CustomInputComponent,
                        startAdornment: <span style={{ fontSize: "14px" }}>{`${getSymbolFromCurrency(projectDetails.type_of_currency) || ""}`}</span>
                      }}
                    />
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
                <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Our fee(Local Currency)</CustomTypographyWeb>
                {mode === configJSON.viewRecordMode ? (
                  <Grid item sm={10}><CustomTypographyWeb isContent data-test-id={configJSON.currency_valueTestId}><b>{getSymbolFromCurrency(projectDetails.type_of_currency)}</b> {projectDetails.currency_value?.toString().replace(configJSON.valueFormater, ",")}</CustomTypographyWeb></Grid>
                ) : (
                  <Grid item sm={10}>
                    <NumericTextField
                      data-test-id={configJSON.currency_valueTestId}
                      error={this.renderProjectError('currency_value').error}
                      variant="outlined"
                      placeholder='Enter value'
                      fullWidth
                      disabled={!projectDetails.type_of_currency}
                      InputProps={{
                        inputComponent: CustomInputComponent ,
                        startAdornment: <span style={{fontSize: "14px"}}>{`${getSymbolFromCurrency(projectDetails.type_of_currency) || ""}`}</span>,
                      }}
                      value={projectDetails.currency_value}
                      onChange={(event: any) => this.props.onChange('currency_value', event.target.value)}
                      helperText={this.renderProjectError('currency_value').text}
                    />
                  </Grid>
                )}
              </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={webStyle.gridContainer} id="work_details_1">
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Value of Work Range({projectCurrency})</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent >{projectDetails.value_of_work_range}</CustomTypographyWeb></Grid>
              ) : (
                <Grid item sm={10}>
                  <NumericTextField
                    variant="outlined"
                    fullWidth
                    disabled
                    inputProps={{
                      mode: 'numeric',
                      maxLength: 10
                    }}
                    style={webStyle.disabledViewContent}
                    value={projectDetails.value_of_work_range}
                    type="text"
                  />
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle}>Value of Work({projectCurrency})</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent ><b>{getSymbolFromCurrency(projectCurrency)}</b>&nbsp;{projectDetails.value_of_work_usd?.toString().replace(configJSON.valueFormater, ",")}</CustomTypographyWeb></Grid>
              ) : (
                <Grid item sm={10}>
                  <NumericTextField
                    variant="outlined"
                    fullWidth
                    disabled
                    InputProps={{
                        inputComponent: CustomInputComponent ,
                        startAdornment: <span style={{fontSize: "14px"}}>{`${getSymbolFromCurrency(projectCurrency) || ""}`}</span>,
                    }}
                    style={webStyle.disabledViewContent}
                    value={projectDetails.value_of_work_usd}
                    onChange={(event: any) => this.props.onChange('value_of_work_usd', event.target.value)}
                    error={this.renderProjectError('value_of_work_usd').error}
                    helperText={this.renderProjectError('value_of_work_usd').text}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <Box style={projectModuleCommonStyle.viewComponent}>
              <CustomTypographyWeb isLabel style={webStyle.isLabelStyle} >Our fee({projectCurrency})</CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={10}><CustomTypographyWeb isContent data-test-id={configJSON.feeTestId} ><b>{getSymbolFromCurrency(projectCurrency)}</b>&nbsp;{projectDetails.fee?.toString().replace(configJSON.valueFormater, ",")}</CustomTypographyWeb></Grid>
              ) : (
                <Grid item sm={10}>
                  <NumericTextField
                    variant="outlined"
                    fullWidth
                    data-test-id={configJSON.feeTestId}
                    disabled
                    InputProps={{
                      inputComponent: CustomInputComponent ,
                      startAdornment: <span style={{fontSize: "14px"}}>{`${getSymbolFromCurrency(projectCurrency) || ""}`}</span>,
                    }}
                    style={webStyle.disabledViewContent}
                    value={projectDetails.fee}
                    onChange={(event: any) => this.props.onChange('fee', event.target.value)}
                    error={this.renderProjectError('fee').error}
                    helperText={this.renderProjectError('fee').text}
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>

        <Divider />
        {this.renderProjectDuration()}
      </Box>
    );
  }
}
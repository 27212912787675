import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  buildUrlParams,
  removeUndefinedPropertiesFromGivenObject,
  queryArray,
  checkLoadTimes
} from "../../../components/src/Utils";
import { favEmpty, favFilled } from "./assets";
import { SupplierExportData } from '../../importexportdata/src/SupplierExportData.web';
import React from "react";


export type FilterObjType = {
  [key: string]: any[] | string | boolean;
};

export interface SupplierData {
  data: Array<SupplierDataResponse>;
  meta: SupplierDataMeta;
}

export interface SupplierDataResponse {
  id?: string | null;
  type?: string | null;
  country: string | null;
  group_company: string | null;
  internal_rate: string | null;
  sector: string | null;
  favourite?: {
    is_favourite: boolean | null;
    favourite_id: number | string | undefined | null;
  } | null;
  services: {
    data: [
      {
        id: string;
        type: string;
        attributes: {
          id: number;
          name: string;
        };
      }
    ];
  } | null;
  supplier_name: string | null;
}

export interface SupplierDataMeta {
  current_page: number;
  next_page: number;
  prev_page: number;
  total_pages: number;
  total_count: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isSupplierListLoading: boolean;
  isOpenSupplierFilter: boolean;
  isSupplierSearchSaved: boolean;
  isResetSupplierFilters: boolean;
  isShowSaveSearchSupplierLabel: boolean | undefined;
  currentSupplierSortColumn: string;
  sortSupplierOrder: string;
  searchSupplierQuery: string;
  hasSupplierError: string | null;
  supplierFilterObj: FilterObjType;
  supplierPaginationDetails: {
    currentPage: number;
    rowsPerPage: number;
    totalLength: number;
    rowsPerPageOptions: number[];
  };
  tableSupplierInputs: {
    apiData: SupplierDataResponse[];
    colSpanList: SupplierDataResponse[];
    columsList: SupplierDataResponse[];
    columnSep: SupplierDataResponse[];
  };
  searchQuery: string;
  isSupplierSavingSearch: boolean;
  savingSearchSupplierName: string;
  onSaveSearchErrorSupplierMessage: string;
  onSaveSearchErrorSupplier: boolean;
  searchIds: number[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SupplierDataTableController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;
  // Customizable Area Start
  getSupplierApiCallId: string = "";
  apiForFavouriteSupplier: string = "";
  apiForSaveSupplierSearch: string = "";

  refExportBox = React.createRef<SupplierExportData>()
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isOpenSupplierFilter: true,
      isSupplierSearchSaved: false,
      isResetSupplierFilters: false,
      isShowSaveSearchSupplierLabel: false,
      isSupplierListLoading: false,
      hasSupplierError: null,
      supplierFilterObj: {},
      supplierPaginationDetails: {
        rowsPerPage: configJSON.defaultRawsPerPage,
        totalLength: 0,
        currentPage: 0,
        rowsPerPageOptions: configJSON.rowsPerPageOptions,
      },
      tableSupplierInputs: {
        columnSep: [],
        columsList: [],
        colSpanList: [],
        apiData: [],
      },
      sortSupplierOrder: "",
      searchSupplierQuery: "",
      currentSupplierSortColumn: "",
      searchQuery: "",
      isSupplierSavingSearch: false,
      savingSearchSupplierName: "",
      onSaveSearchErrorSupplierMessage: "",
      onSaveSearchErrorSupplier: false,
      searchIds: []
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiSupplierRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const supplierResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleAllSupplierApiResponses(
        apiSupplierRequestCallId,
        supplierResponseJson
      );
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    if (checkLoadTimes() > 1) {
      history.pushState(null, '', '/Modules/Suppliers')
    }
    this.handleSupplierData();
    localStorage.removeItem('suppliers');
    // Customizable Area End
  }


  // Customizable Area Start

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      JSON.stringify(prevState.supplierFilterObj) !== JSON.stringify(this.state.supplierFilterObj)
    ) {
      this.setState({
        searchQuery: "",
      }, () => {
        this.handleSupplierData();
      });
    }
  }

  handleAllSupplierApiResponses = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    const successCallbackMap = {
      [this.getSupplierApiCallId]: this.handleSupplierDataResponse,
      [this.apiForFavouriteSupplier]: this.favouriteSupplierResponse,
      [this.apiForSaveSupplierSearch]: this.saveSupplierSearchResponse
    };

    if (apiRequestCallId) {
      const successCallback: (responseJson: any) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJson);
    }
  };

  toggleSupplierFilterOpen = () => {
    this.setState({ isOpenSupplierFilter: !this.state.isOpenSupplierFilter });
  };

  handleSupplierFilterObj = (
    value: FilterObjType,
    isApplySaveSearch?: boolean | undefined
  ) => {
    this.setState({
      supplierFilterObj: value,
      isShowSaveSearchSupplierLabel: isApplySaveSearch,
      isSupplierSearchSaved: !!isApplySaveSearch,
    });
  };

  handleCheckedSupplierSaveSearch = () => {
    if(!this.state.isSupplierSearchSaved){
      this.setState({
        isSupplierSavingSearch: true,
        onSaveSearchErrorSupplier: false
      })
    }
  }

  onChangeSupplierSavedSeachName = (event: React.ChangeEvent<HTMLInputElement>) => { 
    this.setState({
      onSaveSearchErrorSupplier: false,
      savingSearchSupplierName: event.target.value
    })
  }

  cancelSavingSupplierSearch = ()=>{
    this.setState({
      savingSearchSupplierName: '',
      isSupplierSavingSearch: false,
      isSupplierSearchSaved: false,
    })
  }

  saveSupplierSaveSearchRequest = () => {
    const {
      savingSearchSupplierName,
      searchQuery,
      sortSupplierOrder,
      supplierFilterObj,
      currentSupplierSortColumn,
    } = this.state;

    if(savingSearchSupplierName === ''){
      this.setState({
        onSaveSearchErrorSupplier: true,
        onSaveSearchErrorSupplierMessage: configJSON.onSaveSearchErrorMessage
      })
      return;
    }
    let isFIlter = Object.values(supplierFilterObj).some((array:any) => array.length > 0);
    if(!isFIlter){
      this.setState({
        onSaveSearchErrorSupplierMessage: configJSON.onSaveSearchNoFilterMessage,
        onSaveSearchErrorSupplier:true
      })
      return
    }
    const supplierSaveBody = {
      search_name: savingSearchSupplierName,
      filters: {
        ...(searchQuery ? { project_name: searchQuery } : {}),
        ...supplierFilterObj,
        ...(currentSupplierSortColumn ? {
          sort_key: currentSupplierSortColumn,
          sort_direction: sortSupplierOrder,
        } : {})
      },
      type: "BxBlockSavesearch::SupplierSavedSearch",
    }      
    const supplierSaveUrl = `${configJSON.apiEndPointForSaveProjectSearch}`

    const supplierSaveHeader = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForSaveSupplierSearch = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(supplierSaveBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      supplierSaveUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(supplierSaveHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveSupplierSearchResponse = (resp: any)=>{
    if (this.handleGetSupplierResponsesError(resp)) {
      this.setState({
        isSupplierSavingSearch: false,
      })
      return true;
    }
    if(resp.data) {
      this.setState({
        savingSearchSupplierName: '',
        isSupplierSavingSearch: false,
        isSupplierSearchSaved: true,
        hasSupplierError: null,
      })
    }
  }

  handleSupplierAddView = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "SupplierModuleAdd"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  };

  handleSupplierListChangePage = (
    event: React.ChangeEvent<HTMLInputElement>,
    newPage: number
  ) => {
    this.setState(
      (prevState, props) => ({
        supplierPaginationDetails: {
          ...prevState.supplierPaginationDetails,
          currentPage: newPage,
        },
      }),
      () => {
        this.handleSupplierData();
      }
    );
  };

  handleSupplierListChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState(
      (prevState, props) => ({
        supplierPaginationDetails: {
          ...prevState.supplierPaginationDetails,
          rowsPerPage: +event.target.value,
          currentPage: 0,
        },
      }),
      () => {
        this.handleSupplierData();
      }
    );
  };

  handleLoadSupplierBtn = async () => {
    this.setState(
      {
        searchSupplierQuery: "",
        isResetSupplierFilters: true,
        currentSupplierSortColumn: "",
        sortSupplierOrder: "",
        supplierFilterObj: {},
      },
      async () => {
        try {
          this.getSupplierApiCallId = await this.apiCallSupplierModule({
            contentTypeSupplier: configJSON.exampleApiContentType,
            methodSupplier: configJSON.getApiMethodType,
            endPointSupplier: `${configJSON.getSupplierDataEndpoint}?page=1&per_page=10`,
          });
          this.setState({ isSupplierListLoading: true });
        } catch (error) {
          console.error("Error in handleLoadSupplierBtn:", error);
        }
      }
    );
  };

  handleSupplierSort(columnName: string, sortOrder: string) {
    const sort = sortOrder === "ASC" ? "DESC" : "ASC";
    let column = "";
    if(columnName === "internal_rate"){
      column = "internal_rating"
    } else if(columnName === "supplier_name") {
      column = "company_name"
    } else {
      column = columnName
    }
    this.setState(
      {
        sortSupplierOrder: sort,
        currentSupplierSortColumn: column,
        tableSupplierInputs: {
          ...this.state.tableSupplierInputs,
        },
      },
      () => this.handleSupplierData()
    );
  }

  handleFavSupplierIconClicked = (rowIndex: number) => {
    const { tableSupplierInputs } = this.state;
    const supplier: any = tableSupplierInputs.apiData[rowIndex];
    const favouriteable_id = supplier.id;

    const supplierHeader = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let supplierUrl: string = "";
    let supplierMethod: string = "";
    if (supplier?.favourite?.is_favourite) {
      supplierUrl = `${configJSON.apiEndPointForFavouriteSupplier}/${supplier.favourite.favourite_id}`;
      supplierMethod = configJSON.apiDeleteMethod;
      const newtableSupplierInputs = tableSupplierInputs.apiData.map((recd: SupplierDataResponse) => {
        if (recd.id !== supplier.id) return recd;
    
        const shouldClearSupplierRecd = !!this.state.supplierFilterObj.favourites;
        if (shouldClearSupplierRecd) return {...recd, id: null};
    
        return {
          ...recd,
          favourite: {
            is_favourite: false,
            favourite_id: null,
          },
          iconValue: favEmpty,
        };
      });
      this.setState({
        tableSupplierInputs: {
          ...tableSupplierInputs,
          apiData: newtableSupplierInputs.filter((objectS) => !!objectS.id),
        },
      });
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            favouriteable_id,
            favouriteable_type: "BxBlockMultipageforms2::Supplier",
          },
        })
      );
      supplierUrl = configJSON.apiEndPointForFavouriteProject;
      supplierMethod = configJSON.apiPostMethod;
    }

    this.apiForFavouriteSupplier = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      supplierUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(supplierHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      supplierMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  favouriteSupplierResponse = (responseJson: any) => {
    if (this.handleGetSupplierResponsesError(responseJson)) {
      return true;
    }
    const { data } = responseJson;
    if (data && data.attributes && data.attributes.favouriteable_id) {
      const favSupplierId = data.attributes.favouriteable_id;
      const { tableSupplierInputs } = this.state;
      const apiData = tableSupplierInputs.apiData.map((row: any) => {
        if (row.id === favSupplierId) {
          return {
            ...row,
            favourite: {
              is_favourite: true,
              favourite_id: data.id,
            },
            iconValue: favFilled,
          };
        } else {
          return row;
        }
      });
      this.setState({
        tableSupplierInputs: {
          ...tableSupplierInputs,
          apiData,
        },
        hasSupplierError: null,
      });
    }
  };

  handleSupplierData = async (searchSupplierQuery?: string) => {
    const {
      supplierPaginationDetails,
      supplierFilterObj,
      currentSupplierSortColumn,
      sortSupplierOrder,
    } = this.state;
    const { currentPage, rowsPerPage } = supplierPaginationDetails;
    const supplierQueryParams = buildUrlParams(supplierFilterObj);

    let endpoint = "";

    if (!currentSupplierSortColumn && !sortSupplierOrder) {
      this.setState({ isSupplierListLoading: true });
    }
    currentSupplierSortColumn &&
      supplierQueryParams.append("sort_key", currentSupplierSortColumn);
    sortSupplierOrder &&
      supplierQueryParams.append(
        "sort_direction",
        sortSupplierOrder.toLocaleLowerCase()
      );

    if (!searchSupplierQuery) {
      endpoint = `${configJSON.getSupplierDataEndpoint}?page=${
        currentPage + 1
      }&per_page=${rowsPerPage}&${supplierQueryParams.toString()}&advance_search=${this.state.searchSupplierQuery}`;
    } else {
      this.setState({searchSupplierQuery: searchSupplierQuery.trim()});
      endpoint = `${
        configJSON.getSupplierDataEndpoint
      }?advance_search=${searchSupplierQuery}&page=${
        currentPage + 1
      }&per_page=${rowsPerPage}&${supplierQueryParams.toString()}`;
      
    }

    let filterIdsString = ""
    const targetIds = queryArray("ids", window.location.href);
    if (targetIds.length > 0) {
       filterIdsString = `&ids=[${targetIds.join(',')}]`;
       endpoint += filterIdsString 
    }

    this.getSupplierApiCallId = await this.apiCallSupplierModule({
      contentTypeSupplier: configJSON.exampleApiContentType,
      methodSupplier: configJSON.getApiMethodType,
      endPointSupplier: endpoint,
    });
  };

  handleGetSupplierResponsesError = (supplierError: any) => {
    if (supplierError.errors && supplierError.errors.length > 0) {
      let errorMsg = "";
      supplierError.errors.forEach((err: any) => {
        errorMsg += Object.values(err)[0] + "\n";
      });
      this.setState({
        hasSupplierError: errorMsg
      })
      return true;
    } else if (supplierError.error) {
      this.setState({
        hasSupplierError: supplierError.error
      })
      return true;
    } else {
      return false;
    }
  };

  handleSupplierDataResponse = (supplierRes: any) => {
    let data = supplierRes.data;

    const searchIds =supplierRes.meta?.search_ids || [];
    
    if (data && Array.isArray(data)) {
      const apiData: SupplierDataResponse[] =
        this.prepareSupplierModuleTableResult(data);
      const tableSupplierInputs = {
        apiData,
        colSpanList: configJSON.supplierModuleColSpanListDefiniation,
        columsList: this.hasFormatSupplierColumnSortingOrder(),
        columnSep: [],
      };


      let totalLength: number = 0,
        currentPage: number = 1;

      if (supplierRes.meta?.total_count && supplierRes.meta?.current_page) {
        totalLength = supplierRes.meta.total_count;
        currentPage = supplierRes.meta.current_page;
      }

      const supplierPaginationDetails = {
        rowsPerPageOptions:
          this.state.supplierPaginationDetails.rowsPerPageOptions,
        rowsPerPage: this.state.supplierPaginationDetails.rowsPerPage,
        totalLength: totalLength,
        currentPage: currentPage - 1,
      };

      this.setState({
        supplierPaginationDetails,
        tableSupplierInputs,
        searchIds,
      });
    } else {
      const tableSupplierInputs = {
        apiData: [],
        colSpanList: configJSON.supplierModuleColSpanListDefiniation,
        columsList: configJSON.supplierModuleColumnListDefiniation,
        columnSep: [],
      };

      this.setState({
        searchIds,
        tableSupplierInputs,
      });
    }
    this.setState({
      searchIds,
      isSupplierListLoading: false,
      isResetSupplierFilters: false,
    });
  };

  apiCallSupplierModule = async (valueSupplierData: {
    methodSupplier?: string;
    contentTypeSupplier?: string;
    endPointSupplier?: string;
    typeSupplier?: string;
    apiTypeSupplier?: string;
  }) => {
    let {
      contentTypeSupplier,
      methodSupplier,
      endPointSupplier,
    } = valueSupplierData;
    const token = (await localStorage.getItem("authToken")) || "";
    let headerSupplier = {
      "Content-Type": contentTypeSupplier,
      token,
    };
    let requestMessageSupplier = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageSupplier.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodSupplier
    );
    requestMessageSupplier.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointSupplier
    );
    requestMessageSupplier.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerSupplier)
    );
    runEngine.sendMessage(requestMessageSupplier.id, requestMessageSupplier);
    return requestMessageSupplier.messageId;
  };

  prepareSupplierModuleTableResult = (data: SupplierDataResponse[]) => {
    return data.map((supplierDetails: any) => {
      let iconValue: string = favEmpty;
      if (supplierDetails.services) {
        supplierDetails.services = this.truncateSupplierText(
          supplierDetails.services,
          27
        );
      }

      if (supplierDetails.supplier_name) {
        supplierDetails.supplier_name = this.truncateSupplierText(
          supplierDetails.supplier_name,
          25
        );
      }

      if (supplierDetails.favourite && supplierDetails.favourite.is_favourite) {
        iconValue = favFilled;
      }

      const viewedProjectDetails = { ...supplierDetails, iconValue, ...supplierDetails.connections };
      return removeUndefinedPropertiesFromGivenObject(viewedProjectDetails);
    });
  };

  truncateSupplierText = (title: string, titleLength: number) => {
    if (title === undefined || title === null) return "";
    if (title.length > titleLength) {
      return `${title.slice(0, titleLength)}...`;
    } else {
      return title;
    }
  };

  hasFormatSupplierColumnSortingOrder = () => {
    const { currentSupplierSortColumn, sortSupplierOrder } = this.state;
    let columnName = "";
    if(currentSupplierSortColumn === "internal_rating"){
      columnName = "internal_rate"
    } else if(currentSupplierSortColumn === "company_name") {
      columnName = "supplier_name"
    } else {
      columnName = currentSupplierSortColumn
    }
    return configJSON.supplierModuleColumnListDefiniation.map(
      (column: { dataLabel: string; sortOption: { isSortable: boolean } }) => ({
        ...column,
        sortOption:
          columnName === column.dataLabel && column.sortOption?.isSortable
            ? { ...column.sortOption, sortOrder: sortSupplierOrder }
            : column.sortOption,
      })
    );
  };

  // Customizable Area End
}

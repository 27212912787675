import {
  Snackbar,
  Grid,
  Divider,
  FormControl,
  RadioGroup,
  Link,
  Box,
  Button,
  FormControlLabel,
} from "@material-ui/core";
import React, { Component } from "react";
import { downloadFileByContent } from "../../../../components/src/Utils";
import { CloudUpload } from "@material-ui/icons";
import CustomRadioButton from "../../../../components/src/CustomRadioButton.web";
import CustomButton from "../../../../components/src/CustomButton.web"
import CustomTypographyWeb from "../../../../components/src/CustomTypography.web";
import { successImage, uploadIconSvg } from "../assets";
import ExcelJS from "exceljs";

export const configJSON = require("../config");
const MAX_UPLOAD_FILE_SIZE = 5 * 1024 * 1024;

export interface Props {
  navigation: any;
  sampleCSVFormat: string;
  sampleXLSFile: any;
  onSubmitImport: (file: File, isSkipOverided: boolean) => void;
  onErrorOccur?: (error: string) => void;
  module?: string;
}

interface State {
  isSkippedUpload: boolean
  isFileImported: boolean
  isLoading: boolean
  loadingCSV: boolean;
  uploadFileSelected: File | null;
  hasUploadError: string
  hasImportExportButtonClicked: boolean;
  showValidImportFile: boolean;
}

interface SS {
  id: any;
}

export default class ImportView extends Component<Props, State> {
  uploadFileRef = React.createRef<HTMLInputElement>();
  uploadContainerRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props)

    this.state = {
      loadingCSV: false,
      uploadFileSelected: null,
      hasUploadError: '',
      isSkippedUpload: true,
      isFileImported: false,
      isLoading: false,
      hasImportExportButtonClicked: false,
      showValidImportFile: false
    };
  }


  handleUploadButtonClick = (uploadFileRef: any) => {
    if (uploadFileRef.current) {
      uploadFileRef.current.click();
    }
  };

  handleDragIn = (e: any, uploadContainerRef: any) => {
    e.preventDefault();
    if (uploadContainerRef.current) {
      uploadContainerRef.current.style.backgroundColor = "#E5E5E5";
    }
  };

  handleDragOut = (e: any, uploadContainerRef: any) => {
    e.preventDefault();
    if (uploadContainerRef.current) {
      uploadContainerRef.current.style.backgroundColor = "#FFF";
    }
  };

  handleImportData = () => {
    const file = this.state.uploadFileSelected as File;
    this.props.onSubmitImport(file, this.state.isSkippedUpload);
  }

  handleError = (error: string) => {
    this.setState({ hasUploadError: error });

    this.props.onErrorOccur?.(error)
  }

  handleDrop = (e: any, uploadContainerRef: any) => {
    e.preventDefault();
    
    const file = e.dataTransfer.files[0];
    if (this.isValidFile(file)) {
      this.setState({
        uploadFileSelected: file, 
        hasUploadError: '' 
      });
    } else {
      this.handleError(configJSON.errorForBigFileUpload);
      this.props.onErrorOccur?.(configJSON.errorForBigFileUpload)
    }

    if (uploadContainerRef.current) {
      uploadContainerRef.current.style.backgroundColor = "#FFF";
    }
    
  };

  downloadSampleXLSX = async ()  => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    const data = this.props.sampleXLSFile
    const {module} = this.props

    worksheet.addRows(data);
    worksheet.getRow(1).font = { bold: true };

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    downloadFileByContent(`same_${module}_xlsx.xlsx`, blob);
  }

  isValidFile = (file: File) => {
    return file.size < MAX_UPLOAD_FILE_SIZE && (file.name.includes(".csv") || file.name.includes(".xlsx"))
  }

  handleFileChange = (eventUpload: any) => {
    if (eventUpload.target.files && eventUpload.target.files[0]) {
      const file = eventUpload.target.files[0];
      if (this.isValidFile(file)) {
        this.setState({ 
          uploadFileSelected: file,
          hasUploadError: ''
        });
      } else {
        this.handleError(
          configJSON.errorForBigFileUpload
        );
        this.props.onErrorOccur?.(configJSON.errorForBigFileUpload)
        eventUpload.target.value = "";
      }
    }
  }

  truncateText = (text: string | null, maxLength: number) => {
    if (text && text.length > maxLength) {
      return `${text.slice(0, maxLength) + '...'}`;
    }
    return text;
  }

  exportSampleData (method: string) {
    const {module} = this.props
    if (method === 'csv') {
      const blob = new Blob([this.props.sampleCSVFormat], { type: 'text/csv;charset=utf-8' });
      downloadFileByContent(`same_${module}_csv.csv`, blob)
    } else {
      this.downloadSampleXLSX();
    }
  }


  render() {
    return (
      <Box sx={webStyles.container}>
        {this.state.isFileImported ? (
          <>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={10} md={8} lg={6}>
                <Box sx={webStyles.uploadFile}>
                  <img src={successImage} alt="success" />
                  <CustomTypographyWeb
                    data-testid='import-data-success'>
                    You have successfully imported
                  </CustomTypographyWeb>
                  <CustomButton onClick={() => { this.props.navigation.navigate(this.props.module === "projects" ? 'ProjectModuleTable': "TenderModuleTable") }}>Click to view</CustomButton>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} sm={8} md={6} lg={6}>
                <div
                  onDragOver={(e) => { this.handleDragIn(e, this.uploadContainerRef); }}
                  onDragLeave={(e) => { this.handleDragOut(e, this.uploadContainerRef); }}
                  onDrop={(e) => {  this.handleDrop(e, this.uploadContainerRef);}}
                  data-testid="upload-box"
                  ref={this.uploadContainerRef}
                >
                  <Box sx={webStyles.uploadFile}>
                    <Box
                      sx={{
                        height: "60px",
                        borderRadius: "50%",
                        width: "60px",
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#FFF",
                      }}
                    >
                      <img src={uploadIconSvg} alt="upload" />
                    </Box>

                    <CustomTypographyWeb isLabel>UPLOAD A FILE</CustomTypographyWeb>
                    <input data-testid="import-data-input"
                      ref={this.uploadFileRef}
                      type="file"
                      hidden
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      onChange={this.handleFileChange}
                      name="ImportData"
                    />

                    <Button
                      data-testid="importdata-upload-button"
                      component="label"
                      style={{
                        color: "white",
                        textTransform: "none",
                        backgroundColor: "#0094A7",
                        padding: '8px 16px'
                      }}
                      variant="contained"
                      endIcon={<CloudUpload />}
                      href="#file-import-people"
                      onClick={() => { this.handleUploadButtonClick(this.uploadFileRef); }}
                      disabled={this.state.isLoading}
                    >
                      {this.state.uploadFileSelected &&
                        this.state.uploadFileSelected instanceof File ? (
                        <CustomTypographyWeb  data-testid="import-data-imported-file-name" style={{ textDecoration: "underline" }}>
                          {this.truncateText(this.state.uploadFileSelected.name, 32)}
                        </CustomTypographyWeb>
                      ) : (
                        <CustomTypographyWeb isLabel style={{
                          color: '#FFF',
                        }}>Choose file</CustomTypographyWeb>
                      )}
                    </Button>

                    {this.state.hasUploadError &&
                      <Box style={webStyles.validImportmessage} data-testid="import-data-error">{configJSON.errorForBigValidFileUpload}</Box>
                    }


                    <Box sx={webStyles.uploadFileInfo}>
                      <CustomTypographyWeb
                        style={{
                          fontSize: "14px",
                          color: "#9F9F9F",
                          fontWeight: 600,
                        }}
                        isContent
                        
                      >
                        Max file size 5 MB
                      </CustomTypographyWeb>
                      <div
                        style={{ width: "1px", height: "20px", background: "#9F9F9F" }}
                      />
                      <CustomTypographyWeb isContent
                        style={{
                          fontWeight: 600,
                          fontSize: "14px",
                          color: "#9F9F9F",
                        }}
                      >
                        File format CSV or XLSX
                      </CustomTypographyWeb>
                    </Box>
                  </Box>
                </div>
                <CustomTypographyWeb
                  style={webStyles.uploadFileInfoText}
                >
                  Download a{" "}
                  <span style={{ color: "#0094A7" }}>
                    <Link data-test-id={configJSON.sampleCSVlinkTestId} onClick={() => {this.exportSampleData('csv')}} color="inherit" style={{ cursor: 'pointer' }}>
                      sample CSV
                    </Link>
                  </span> file or <span style={{ color: "#0094A7" }}>
                    <Link data-test-id={configJSON.sampleXLSlinkTestId} onClick={() => {this.exportSampleData('xls')}} color="inherit" style={{ cursor: 'pointer' }}>
                      sample XLSX
                    </Link>
                  </span> file and complete it to your import file to ensure you have the file
                  perfect for the import
                </CustomTypographyWeb>

                <Grid container style={{ marginTop: "30px" }} spacing={4}>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <CustomTypographyWeb
                      style={{
                        lineHeight: "24px",
                        fontWeight: 600,
                        color: "#DC2626",
                        fontSize: "18px",
                      }}
                    >
                      Duplicate Handling:*
                    </CustomTypographyWeb>
                  </Grid>
                  <Grid item xs={12} sm={8} md={8} lg={8}>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <FormControl component="fieldset">
                        <RadioGroup>
                          <FormControlLabel
                            control={
                              <CustomRadioButton
                                data-testId="handleSkip"
                                onChange={() => {
                                  this.setState({
                                    isSkippedUpload: true,
                                  });
                                }}
                                checked={this.state.isSkippedUpload}
                              />
                            }
                            label="Skip"
                          />
                          <CustomTypographyWeb
                            style={{
                              fontSize: "14px",
                              lineHeight: "24px",
                              color: "#6F6F70",
                              fontWeight: 400,
                              marginBottom: '28px'
                            }}
                          >
                            If an imported file includes a record with a duplicate entry based on a unique field (like ID or email), the system will skip importing that record
                          </CustomTypographyWeb>

                          <FormControlLabel
                            control={
                              <CustomRadioButton
                                checked={!this.state.isSkippedUpload}
                                onChange={() => {
                                  this.setState({
                                    isSkippedUpload: false,
                                  });
                                }}
                              />
                            }
                            label="Override"
                          />
                          <CustomTypographyWeb
                            style={{
                              fontWeight: 400,
                              lineHeight: "24px",
                              color: "#6F6F70",
                              fontSize: "14px",
                            }}
                          >
                            If an imported file includes a record with a duplicate entry based on a unique field (like ID or email), the system will override the existing entry with the new imported record
                          </CustomTypographyWeb>
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Divider />

            <Box
              sx={webStyles.buttonContainer}
            >
              <CustomButton
                colorVariant="special"
                onClick={() => this.props.navigation.goBack()}
                data-testid="import-data-cancel-button"
                disabled={this.state.isLoading}
              >
                Cancel
              </CustomButton>
              <CustomButton
                data-testid="importCSV-button"
                onClick={() => {
                  this.handleImportData();
                }}
                disabled={
                  (this.state.uploadFileSelected instanceof File ? false : true) || this.state.isLoading
                }
              >
                Import
              </CustomButton>
            </Box>
          </>
        )}
      </Box>
    );
  }
}

const webStyles = {
  uploadFile: {
    alignItems: "center",
    border: "1px solid rgba(0, 148, 167, 0.40)",
    background: "rgba(0, 148, 167, 0.08)",
    padding: "40px",
    justifyContent: "center",
    gap: "20px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
  },
  container: {
    marginTop: "20px",
    gap: "25px",
    display: "flex",
    backgroundColor: "#FFF",
    flexDirection: "column",
    "@media (max-width: 600px)": {
      padding: "0 10px"
     }
  },
  uploadFileInfoText: {
    fontFamily: "Source Sans Pro",
    color: "#6F6F70",
    marginTop: "20px",
    fontSize: "16px",
    lineHeight: "24px",
    fontStyle: 'normal',
    fontWeight: 600,
  },
  uploadFileInfo: {
    display: "flex",
    marginBottom: "20px",
    alignItems: "center",
    flexDirection: "row",
    gap: "15px",
  },

  validImportmessage: {
    fontSize: "14px",
    color: "#f44336",
    fontFamily: "Source Sans Pro"
  },
  buttonContainer: {
    gap: "30px",
    marginBottom: "25px",
    marginRight: "40px",
    flexDirection: "row",
    justifyContent: "end",
    display: "flex",
    "@media (max-width: 500px)": {
      margin: "0 auto 10px auto"
    }
  }
  
};

import React from "react";
import {
  Box,
  styled,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";
import { ChevronRight } from '@material-ui/icons';
import { customSort } from "./Utils";

interface INestedMenuParentItem {
  label: string;
  nested: string[];
}

interface Props {
  menuItems: INestedMenuParentItem[];
  groupRelationMenus: any;
  groupRelationRadio: 'related' | 'unrelated';
  openGroupRelation: boolean;
  pickOne?: boolean;
  handleChangeGroupRelation: (
    val: 'related' | 'unrelated',
    groupRelations: any,
  ) => void;  
  handleOpenGroupRelation: (value: boolean) => void;
}

interface S {
    openNestedMenu: string | null;
}

const StyledButton = styled("button")({
  position: "relative",
  margin: "10px 0px",
  padding: "0",
  border: "none",
  backgroundColor: "transparent",
  textAlign: "left",
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  flexWrap: "nowrap",
  cursor: "pointer",
  color: "#2B3132",
  fontSize: 16,
  fontFamily: "Source Sans Pro",
  fontWeight: 600,
  "&:hover": {
    backgroundColor: "transparent !important",
  },
  "& span": {
    fontWeight: 400,
  },
});

const CustomMainPopper = styled(Popper)({
  zIndex: 100,
  "& .mainWrapper": {
    width: "fit-content",
    borderRadius: "8px",
    border: "1px solid #9F9F9F",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    padding: "4px 0",
    "& .menuItem": {
      position: "relative",
      padding: "11px 18px 11px 18px",
      color: "#2B3132",
      fontSize: 16,
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      gap: "45px",
      justifyContent: "space-between",
      fontFamily: "Source Sans Pro",
      fontWeight: "600",
      lineHeight: "16px",
      "&:hover": {
        backgroundColor: "rgb(214, 238, 241) !important",
      },
    },
  },
  "& .nestedWrapper": {
    minWidth: "160px",
    boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.25)"
  }
});

class GroupRelationMenu extends React.Component<Props, S> {
    constructor(props: Props) {
      super(props);
      this.state = {
        openNestedMenu: null,
      };
    }

    handleNestedMenuOpen = (value: string | null) => {
      this.setState({ openNestedMenu: value });
    };

    handleNestedMenuClick = (label: string, value?: string) => {
      const isPastEmployeLabel = label === "Past Employed"
      const { handleChangeGroupRelation, groupRelationRadio, groupRelationMenus } = this.props;
      const menus:any = groupRelationMenus ? groupRelationMenus?.filter(
        (item:any) => item.relation === groupRelationRadio
      ): [];
      const findLabelGroup = menus.find((f:any) => f.employe_status === label);

      let resultMenus: any[] = []
       
      if (findLabelGroup) {
        resultMenus = menus.map((item: any) => { 
          const count = item.status_option ?  item.status_option.filter((o:any) => o === value).length : 0
          if(item.employe_status === label) {
            if(isPastEmployeLabel){
             if (count === 1 && item.status_option.length === 1 ) {
                return menus.length > 1 ? {}: item 
              } else if (count === 1 && item.status_option.length > 1 ) {
                const list =  item.status_option.filter((o:any) => o !== value)
                return { ...item, status_option: list}
              } else {
                return { ...item, status_option: [...item.status_option, value]}
              }
            } else {
              return (count === 1 && item.status_option.length === 1) ? menus.length > 1 ? {}: item : { ...item, status_option: [value] };
            }
          }else { 
            return item
          }
        })
      } else {
          const newGroup = { relation: groupRelationRadio, employe_status: label, status_option:[value] };
          resultMenus = [...menus, newGroup];
      }

      handleChangeGroupRelation(groupRelationRadio, resultMenus);
      !isPastEmployeLabel && this.handleNestedMenuOpen(null)
    };

    handleMenuClick = async (label:string, isNested: boolean) => {
      const { pickOne } = this.props;
      
      if(!isNested){
        const { handleChangeGroupRelation, groupRelationRadio, groupRelationMenus } = this.props;
        const menus: any = groupRelationMenus ? groupRelationMenus.filter(
          (item:any) => item.relation === groupRelationRadio
        ): [];
        const findLabelGroup = menus.find((f:any) => f.employe_status === label);
        let resultMenus: any[] = [];

        if (findLabelGroup) {
          resultMenus = menus
        } else {
            const newGroup = { relation: groupRelationRadio, employe_status: label, status_option: [] };
            if (pickOne) {
              resultMenus = [ newGroup];
            } else {
              resultMenus = [...menus, newGroup];
            }
        }
        await handleChangeGroupRelation(groupRelationRadio, resultMenus)
        this.handleCloseAll()
      }
      this.handleNestedMenuOpen(label)
    }

    handleCloseAll = () => {
      this.props.handleOpenGroupRelation(false);
      this.handleNestedMenuOpen(null)
    }

    handleClickButton = (status: string) => {
      this.props.handleOpenGroupRelation(true);
      this.handleNestedMenuOpen(status)
    }

    handleKeyPress = (event:any, label:string, isNested:boolean) => {
      if (event.keyCode === 13) {
        this.handleMenuClick(label, isNested);
      }
    };

    render() {
      const { groupRelationMenus, menuItems, openGroupRelation, groupRelationRadio } = this.props;
      const { openNestedMenu } = this.state;
      let group = customSort({ group: groupRelationMenus, menu:menuItems })
      group =  group.filter((item:any) => item.relation === groupRelationRadio)
      const updatedMenuItems = menuItems.map((menuItem) => {
        const matchingGroupIndex = group
          ? group.findIndex(
              (groupItem: any) => groupItem.employe_status === menuItem.label
            )
          : -1;
      
        return {
          ...menuItem,
          selected: matchingGroupIndex !== -1
            ? {
                label: menuItem.label,
                value: group[matchingGroupIndex].status_option,
              }
            : undefined,
        };
      });
      return (
        <>
          {!openGroupRelation && !!group && group?.map((item: any) => (
            <StyledButton key={item?.employe_status} onClick={()=>this.handleClickButton(item?.employe_status)}>
              {item?.employe_status}
              {item?.status_option && Array.isArray(item.status_option) && item.status_option.length > 0 &&
              <>
                <ChevronRight style={{ margin: "0px 7px" }}/>
                <span>
                  {item.status_option.length > 1 ? item.status_option.join(', ') : String(item.status_option[0] ?? [])}
                </span>
              </>
              }
            </StyledButton>
          ))}
          <CustomMainPopper open={openGroupRelation} transition disablePortal style={{ position: "relative" }}>
            <ClickAwayListener onClickAway={() => this.handleCloseAll()} >
              <Box className="mainWrapper">
                {updatedMenuItems.map((item: any, index: number) => {
                  const isNested = item.nested && Array.isArray(item.nested) && item.nested.length !== 0 
                  const isSelected = (!isNested && item?.selected?.label === item.label) || (openNestedMenu === item.label);
                  return (
                    <Box
                      key={`${index*3+1}_menu`}
                      style={{ position: 'relative' }}
                    >
                      <div
                         className="menuItem"
                         onClick={()=> this.handleMenuClick(item.label, isNested)}
                         onKeyPress={(event:any) => this.handleKeyPress(event, item.label, isNested)}
                         style={{
                           backgroundColor: isSelected ? "rgb(214, 238, 241)" : "transparent",
                         }}
                      >
                        <div>{item.label}</div>
                        {isNested ? <ChevronRight /> : ""}
                      </div>
                      
                      { isNested && (
                        <CustomMainPopper
                          open={isSelected}
                          transition
                          disablePortal
                          style={{
                            position: "absolute",
                            top: "120%",
                          }}
                        >
                          <Box className="mainWrapper nestedWrapper">
                            {item.nested.map((title: string, i: number) => (
                              <Box
                                key={`nest_menu_${i*2 + 1}`}
                                className="menuItem"
                                style={{
                                  backgroundColor: item?.selected?.value.includes(title) ? "rgb(214, 238, 241)" : "transparent",
                                }}
                                onClick={() => this.handleNestedMenuClick(item.label, title)}
                              >
                                <div>{title}</div>
                              </Box>
                            ))}
                          </Box>
                        </CustomMainPopper>
                      )}
                    </Box>
                  )
                })}
              </Box>
            </ClickAwayListener>
          </CustomMainPopper>
        </>
      );
    }
  }
  
export default GroupRelationMenu;

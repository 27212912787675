import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { MappingFilterNameForPayload } from "../../../components/src/MappingTableHeaderLabels";
import {
    convertResIntoLabelValueCheck, determineSelectedGroupRelation, parseFilterListObject, setCheckedToFalseDeep, setCheckedToTrueDeep,
} from "../../../components/src/Utils";
import { accordionDummyList } from "../../../components/src/WebAppLayout/config";
import { StatusEndPoints, StatusFieldKeys } from "./assets";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    open: boolean;
    handleClose: () => void;
    module: string;
    savedSearchList?: any;
    inputSaveSearchName?: any;
    handleGetFilter?: any
    savedFilters?: any
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    filterMenu: any[];
    filterList: any;
    selectedGroupRelation: string;
    isLoader: any;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class FilterDropDownController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getEduLevelApiCallId: string = "";
    getExperienceApiId: string = "";
    getEduDisciplineApiCallId: string = "";
    getAllLanguagesApiCallId: string = "";
    getPositionApiCallId: string = "";
    getGroupApiCallId: string = "";
    getCountriesApiCallId: string = "";
    getClientApiCallId: string = "";
    getSectorsCallId: string = "";
    getAllServicesApiCallId: string = "";
    getStatusApiCallId: string = "";
    getNationalitiesApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.SessionSaveMessage),
        ];

        this.state = {
            filterMenu: [],
            selectedGroupRelation: "",
            isLoader: true,
            filterList: {},
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start
        const module = this.props.module.toLowerCase();
        const list = accordionDummyList[module].slice(1);
        this.setState(
            {
                filterMenu: JSON.parse(JSON.stringify(list)),
            },
            () => this.handleAPICollection(module)
        );
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            this.handleAllGetApiResponses(apiRequestCallId, responseJson);
        }

        // Customizable Area End
    }

    // Customizable Area Start

    handleAPICollection = (label: string) => {
        switch (label.toLowerCase()) {
            case "people":
                this.getEduLevelApiCallId = this.filterGetApiCall(
                    configJSON.getLevelEducationEndPoint
                );
                break;
            case "projects":
            case "tenders":
            case "suppliers":
            case "partners":
                this.getGroupApiCallId = this.filterGetApiCall(
                    configJSON.getGroupCompanyEndPoint
                );
                break;
            default:
                break;
        }
    };

    handleAllGetApiResponses = (apiRequestCallId: string, responseJson: any) => {
        const successCallbackMap = {
            [this.getEduLevelApiCallId]: this.getLevelResponse,
            [this.getEduDisciplineApiCallId]: this.getDisciplineResponse,
            [this.getPositionApiCallId]: this.getCurrentPositionResponse,
            [this.getAllLanguagesApiCallId]: this.getLanguagesResponse,
            [this.getExperienceApiId]: this.getExperienceRes,
            [this.getStatusApiCallId]: this.getStatusResponse,
            [this.getGroupApiCallId]: this.getGroupCompanyResponse,
            [this.getClientApiCallId]: this.getClientResponse,
            [this.getSectorsCallId]: this.getSectorsResponse,
            [this.getCountriesApiCallId]: this.getCountryResponse,
            [this.getAllServicesApiCallId]: this.getServicesResponse,
            [this.getNationalitiesApiCallId]: this.getNationalitiesResponse
        };

        if (apiRequestCallId) {
            const successCallback: (responseJson: any) => void =
                successCallbackMap[apiRequestCallId];
            !!successCallback && successCallback(responseJson);
        }
    };

    getLevelResponse = (levelRes: any) => {
        if (this.handleGetResponsesError(levelRes)) {
            return;
        }
        const levelResponse = convertResIntoLabelValueCheck(
            this.state.filterMenu,
            1,
            "education_degree",
            levelRes
        );
        this.setState({
            filterMenu: levelResponse,
        }, () => this.getEduDisciplineApiCallId = this.filterGetApiCall(
            configJSON.getDisciplineEducatioEndpoint
        ));
    };

    getDisciplineResponse = (disRes: any) => {
        if (this.handleGetResponsesError(disRes)) {
            return;
        }
        const resDisc = convertResIntoLabelValueCheck(
            this.state.filterMenu,
            1,
            "education_discipline",
            disRes,
            "stream_name"
        );
        this.setState({
            filterMenu: resDisc,
        }, () =>  this.getExperienceApiId = this.filterGetApiCall(
            configJSON.getExperienceEndPoint
        ));
    };

    getExperienceRes = (expRes: any) => {
        if (this.handleGetResponsesError(expRes)) {
            return;
        }
        const resPri = convertResIntoLabelValueCheck(
            this.state.filterMenu,
            2,
            "primary",
            expRes,
            "sector_name"
        );
        const resExo = convertResIntoLabelValueCheck(
            resPri,
            2,
            "secondary",
            expRes,
            "sector_name"
        );
        this.setState({
            filterMenu: resExo,
        }, () =>  this.getNationalitiesApiCallId = this.filterGetApiCall(
            configJSON.getNationalityApiCallId
        ));
    };

    getCurrentPositionResponse = (cpRes: any) => {
        if (this.handleGetResponsesError(cpRes)) {
            return;
        }
        const resCP = convertResIntoLabelValueCheck(
            this.state.filterMenu,
            3,
            "current_position",
            cpRes,
            "position_name"
        );
        this.setState({
            filterMenu: resCP,
        }, () => this.showFilterOption());
    };

    getNationalitiesResponse = (natlRes: any) => {
        if (this.handleGetResponsesError(natlRes)) {
          this.showFilterOption()
          return;
        }
        const convertDatas = natlRes.map((obj: string)=>{
          return {name: obj}
        })
        const resNatl = convertResIntoLabelValueCheck(
          this.state.filterMenu,
          4,
          "nationality",
          convertDatas
        );
        this.setState({
          filterMenu: resNatl,
        },() =>  this.getAllLanguagesApiCallId = this.filterGetApiCall(
          configJSON.getLanguagesEndPoint
        ));
      }

    getLanguagesResponse = (lanRes: any) => {
        if (this.handleGetResponsesError(lanRes)) {
            return;
        }
        const resLang = convertResIntoLabelValueCheck(
            this.state.filterMenu,
            5,
            "language",
            lanRes
        );
        this.setState({
            filterMenu: resLang,
        }, () => this.getPositionApiCallId = this.filterGetApiCall(
            configJSON.getCurrentPositionEndPoint
        ));
    };

    getGroupCompanyResponse = (grpRes: any) => {
        if (this.handleGetResponsesError(grpRes)) {
            return;
        }
        const resGrp = convertResIntoLabelValueCheck(
            this.state.filterMenu,
            1,
            "group_company",
            grpRes
        );
        this.setState({
            filterMenu: resGrp,
        }, () => this.getClientApiCallId = this.filterGetApiCall(
            configJSON.getClientsEndPoint
        ));
    };

    getClientResponse = (cliRes: any) => {
        if (this.handleGetResponsesError(cliRes)) {
            return;
        }
        const resClient = convertResIntoLabelValueCheck(
            this.state.filterMenu,
            2,
            "client",
            cliRes
        );
        this.setState({
            filterMenu: resClient,
        },() => this.getCountriesApiCallId = this.filterGetApiCall(
            configJSON.getCountryEndPoint
        ));
    };

    getCountryResponse = (cntRes: any) => {
        if (this.handleGetResponsesError(cntRes)) {
            return;
        }
        const resCnt = convertResIntoLabelValueCheck(
            this.state.filterMenu,
            3,
            "country",
            cntRes
        );

        this.setState({
            filterMenu: [...resCnt],
        }, () =>  this.getSectorsCallId = this.filterGetApiCall(
            configJSON.getSectorsEndPoint
        ));
    };

    getSectorsResponse = (sectorRes: any) => {
        if (this.handleGetResponsesError(sectorRes)) {
            return;
        }
        const resSector = convertResIntoLabelValueCheck(
            this.state.filterMenu,
            5,
            "sector",
            sectorRes
        );
        this.setState({
            filterMenu: resSector,
        }, () => this.getAllServicesApiCallId = this.filterGetApiCall(
            configJSON.getServicesEndPoint
        ));
    };

    getServicesResponse = (srvRes: any) => {
        if (this.handleGetResponsesError(srvRes)) {
            return;
        }
        const resSrv = convertResIntoLabelValueCheck(
            this.state.filterMenu,
            4,
            "services",
            srvRes
        );
        this.setState({
            filterMenu: resSrv,
        }, () =>  this.getStatusApiCallId = this.filterGetApiCall(
            StatusEndPoints[this.props.module.toLowerCase() as keyof typeof StatusEndPoints]
        ));
    };

    getStatusResponse = (statusRes: any) => {
        if (this.handleGetResponsesError(statusRes)) {
            return;
        }
        const FilStatus = StatusFieldKeys[this.props.module.toLowerCase() as keyof typeof StatusFieldKeys]
        const resStatus = convertResIntoLabelValueCheck(
            this.state.filterMenu,
            7,
            FilStatus,
            statusRes,
            "status"
        );

        this.setState({
            filterMenu: resStatus,
        }, () => this.showFilterOption());
    };

    handleGetResponsesError = (errRes: any) => {
        if (errRes.errors && errRes.errors.length > 0) {
            let errorMsg = "";
            errRes.errors.forEach((err: any) => {
                errorMsg += Object.values(err)[0] + "\n";
            });
            return true;
        } else if (errRes.error) {
            return true;
        } else {
            return false;
        }
    };

    handleAllFilters(changedList: { [key: string]: string[] }) {
        
        this.setState((prevState: S) => {
            const list = { ...prevState.filterList, ...changedList };            
            return { filterList: list };
        });
    }

    handleCheckBoxChange(
        checkedItems: string[],
        subitem: any,
        parentItem: any,
        selectedGroup?: string
    ) {
        const handleCheckBoxChange = (key: string, value: string[]) => ({
            [key]: value,
        });

        const filterNameForPayload = MappingFilterNameForPayload;
        let filterChangeObject: { [key: string]: string[] } = {};

        if (selectedGroup) {
            const key = `${selectedGroup}${filterNameForPayload(subitem)}`;
            filterChangeObject = handleCheckBoxChange(key, checkedItems);
        } else {
            const key = subitem === "" ? parentItem.link : subitem;
            filterChangeObject = handleCheckBoxChange(key, checkedItems);
        }

        this.handleAllFilters(filterChangeObject);
    }

    filterGetApiCall = (url: string) => {
        const filterMenuHeader = {
            "Content-Type": "application/json",
            token:
                typeof window !== "undefined"
                    ? localStorage.getItem("authToken")
                    : null,
        };

        const apiRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        apiRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            url
        );

        apiRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(filterMenuHeader)
        );

        apiRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
        return apiRequestMessage.messageId;
    };

    toggleMainLabel(i: number) {
        this.setState((prevState: S) => {
          prevState.filterMenu[i].show = !prevState.filterMenu[i].show;
          return { filterMenu: [...prevState.filterMenu] };
        });
      }
    
    handleRadioChange(e: any, value: string) {
        const keyR = value === "unrelated" ? "related" : "unrelated";
        const updatedFilters =  Object.fromEntries(
            Object.entries(this.state.filterList).filter(([key]) => !key.includes(value === "unrelated" ? "related[" : "unrelated"))
        );
        const updatedRlist = this.state.filterMenu.map((item) => {          
            if (item?.filterOptionForRadio && item?.radioOption) {
            const filRadOptions = item.filterOptionForRadio?.[keyR];
            const updatedFilRadOptions = setCheckedToFalseDeep(filRadOptions);
            const radOptions = setCheckedToFalseDeep(item.radioOption);
            item.radioOption = radOptions
            item.filterOptionForRadio[keyR] = updatedFilRadOptions;
            }
            return item;
        });
        this.setState({
            filterMenu: updatedRlist,
            filterList: updatedFilters,
            selectedGroupRelation: value,
        });
        this.setState({filterList: updatedFilters})
    }

    toggleInnerRadioAcrd(
        i: number,
        selectedGrpRelation: string,
        subItem: string
    ) {
        this.setState((prevState: S) => {
            prevState.filterMenu[i].filterOptionForRadio[selectedGrpRelation][
            subItem
            ].show = !prevState.filterMenu[i].filterOptionForRadio[
            selectedGrpRelation
            ][subItem].show;
            return { filterMenu: [...prevState.filterMenu] };
        });
    }

    showFilterOption = () => {
        const { savedFilters } = this.props;
        const newFilters = parseFilterListObject(savedFilters);
        const relation = determineSelectedGroupRelation(newFilters);
        const updatedProject = setCheckedToTrueDeep(this.state.filterMenu, newFilters)
        this.setState({ filterMenu: updatedProject, filterList: newFilters, selectedGroupRelation: relation, isLoader: false});
    }
    
    handleResetFilters = () => {
        this.handleAllFilters({})
        const updatedList = setCheckedToFalseDeep(this.state.filterMenu);
        const newList = setCheckedToFalseDeep(updatedList, {selectedKey: "show", selectedValue: false});
        this.setState({
            filterMenu: newList,
            filterList: {},
            selectedGroupRelation: "",
        });
        this.props.handleGetFilter({})
    }

    handleSaveFilter = () => {
        const newList = parseFilterListObject(this.state.filterList);
        this.props.handleGetFilter(newList);
        this.setState({
            filterMenu: [],
            filterList: {},
        })
        this.props.handleClose()
    }

    clickedCloseIcon = () => {
        this.setState({
            filterMenu: [],
            filterList: {},
        })
        this.props.handleClose();
    }
    // Customizable Area End
}

// Customizable Area Start
import React, { Component } from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import { createStyles, withStyles } from "@material-ui/styles";
import { ISupplierData, S } from './SupplierModuleController.web';
import { editPageButtonSvg, projectModuleCommonStyle, requestIcon } from './assets';
import CustomTypography from '../../../components/src/CustomTypography.web';
import CustomButton from '../../../components/src/CustomButton.web';
import { AccessControl } from "../../../components/src/Permission"
// Customizable Area End

// Customizable Area Start
type Props = {
	supplierDetails: ISupplierData;
    handleSwitchMode: (section: string, mode: 'update' | 'read' | 'post') => void;
    onChange: (objectKey: keyof S, property: any, value: any | null) => void;
	supplierRequest?: () => void;
	classes: Record<string, string>;
	module: "People" | "Project" | "Tender" | "Supplier" | "Partner";
};

type State = {};
// Customizable Area End

class ReadSupplierDetails extends Component<Props, State> {
	// Customizable Area Start
	render() {
		const {
			email,
			phoneNumber,
			countryCode,
			companyname,
			website,
			groupCompany,
			country,
			services,
			sectors,
			internalRating
		} = this.props.supplierDetails;
		const webStylePersonal = projectModuleCommonStyle;
		const { classes, module } = this.props;
		return (
			<Box
			    className={classes.supplierReadMainBox}
              >
				<Box>
					<RowBox marginTop={3}>
						<Grid container style={{ flex: 24 }}>
							<Grid item xs={12} sm={12}>
								<Box className={classes.viewComponent} style={{marginBottom: '32px'}}>
									<CustomTypography isLabel style={{ marginBottom: "7px" }} >Company Name</CustomTypography>
									<CustomTypography isContent data-testId="firstNameText">{companyname}</CustomTypography>
								</Box>
							</Grid>
						</Grid>
						<AccessControl feature='update' module={module}>
							<CustomButton
								colorVariant="noBackground"
								style={ { flex: 3 }}
								startIcon={<img src={editPageButtonSvg} alt="Edit Page" />}
								id="edit-btn-personal-data"
								onClick={() => {
									this.props.handleSwitchMode('supplierDetails', 'update')
									this.props.onChange('supplierDetails', 'isCancelButtonEnabled', true)
								}
								}
							>
								Edit
							</CustomButton>
						</AccessControl>
					</RowBox>
				</Box>
				<HeaderBox>
					<CustomTypography
						isHeader
						data-testid={'PeopleModule-ReadPersonalData-Personal'}>
						Contact details
					</CustomTypography>
				</HeaderBox>
				<RowBox>
					<Grid container>
						<Grid item xs={12} sm={4}>
							<Box className={classes.viewComponent}>
								<CustomTypography isLabel style={{ marginBottom: "7px" }}>Email address </CustomTypography>
								<CustomTypography isContent>{email}</CustomTypography>
							</Box>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Box className={classes.viewComponent}>
								<CustomTypography isLabel style={{ marginBottom: "7px" }}>Phone number</CustomTypography>
								<CustomTypography isContent>
									{countryCode} {phoneNumber}
								</CustomTypography>
							</Box>
						</Grid>
					</Grid>
				</RowBox>
				<RowBox>
					<Grid item xs={12} sm={4}>
						<Box className={classes.viewComponent}>
							<CustomTypography isLabel style={{ marginBottom: "7px" }}>Website</CustomTypography>
							<CustomTypography isContent>{website}</CustomTypography>
						</Box>
					</Grid>
				</RowBox>
				<Divider />
				<RowBox marginTop={3}>
					<Grid container>
						<Grid item xs={12} sm={4}>
							<Box className={classes.viewComponent}>
								<CustomTypography isLabel style={{ marginBottom: "7px" }}>Group company </CustomTypography>
								<CustomTypography isContent>{groupCompany}</CustomTypography>
							</Box>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Box className={classes.viewComponent}>
								<CustomTypography isLabel style={{ marginBottom: "7px" }}>Country</CustomTypography>
								<CustomTypography isContent>
									{country}
								</CustomTypography>
							</Box>
							
						</Grid>
					</Grid>
				</RowBox>
				<RowBox>
					<Grid container>
						<Grid item xs={12} sm={4}>
							<Box className={classes.viewComponent}>
								<CustomTypography isLabel style={{ marginBottom: "7px" }}>Services</CustomTypography>
								<CustomTypography isContent>{services}</CustomTypography>
							</Box>
						</Grid>

						<Grid item xs={12} sm={4}>
							<Box className={classes.viewComponent}>
								<CustomTypography isLabel style={{ marginBottom: "7px" }}>Sector</CustomTypography>
								<CustomTypography isContent>{sectors}</CustomTypography>
							</Box>
							
						</Grid>
					</Grid>
				</RowBox>
				<RowBox>
					<Grid container>
						<Grid item xs={12} sm={4}>
							<Box className={classes.viewComponent}>
								<CustomTypography isLabel style={{ marginBottom: "7px" }}>Internal rating</CustomTypography>
								<CustomTypography isContent>{internalRating}</CustomTypography>
		
							</Box>
						</Grid>
					</Grid>
				</RowBox>  
				   <Box sx={webStylePersonal.requestButtonTender} id="personal-reqest">
					   <CustomButton colorVariant="projectRequest"
						         startIcon = {<img src={requestIcon} alt="personal-warn" />}
					             onClick={this.props.supplierRequest}
							>
						    Request
					     </CustomButton>
				    </Box>
			      </Box>
		       );
	        }
	      // Customizable Area End
         }

     // Customizable Area Start
	 const HeaderBox = withStyles({
		 root: {
			 width: '100%',
			 display: 'flex',
			 alignItems: 'center',
			 justifyContent: 'space-between',
			 flexDirection: 'row',
			 marginBottom: '24px',
			 fontSize:"22px"
		    },
		  })(Box);
     const RowBox = withStyles({
	     root: {
		    display: 'flex',
		    alignItems: 'flex-start',
		    flexDirection: 'row',
	     },
        })(Box);

const styles = 
  createStyles({
	supplierReadMainBox:{
	  display: 'flex',
	  flexDirection: 'column',
	  marginBottom: '52px'
	},

	supplierEditBtn:{
		color: '#0094A7',
		boxShadow: 'none',
		border: 'none',
		textDecoration: 'underline',
		backgroundColor: 'transparent',
		overflow: 'hidden',
		textAlign: 'center',
		boxSizing: 'border-box'
	},

	supplierRowBox:{
		marginBottom: '7px'
	},
	viewComponent: {
		marginBottom: "32px",
	},

  })
// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(ReadSupplierDetails);
export { ReadSupplierDetails };
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start

export type CommonType = string | number | null

export interface AllBrands {
  id: string;
  name?: string;
  type?: string;
  attributes: Brand;
  checked?: boolean;
}

export interface Brand {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  currency?: string;
  checked?: boolean;
}

export interface SubCategory {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  parent_id: CommonType;
  rank: CommonType;
}

export interface Reviews {
  id: number;
  catalogue_id: number;
  comment: string;
  rating: number;
  created_at: string;
  updated_at: string;
}

export interface CatalogueVariants {
  id: string;
  type: string | null;
  attributes: {
    id: number;
    catalogue_id: number | null;
    catalogue_variant_color_id: number | null;
    catalogue_variant_size_id: number | null;
    price: string;
    stock_qty: CommonType;
    on_sale: boolean | null;
    sale_price: CommonType;
    discount_price: CommonType;
    length: CommonType;
    breadth: CommonType;
    height: CommonType;
    created_at: string;
    updated_at: string;
    images: string[] | null;
  };
}

export interface AllCategory {
  id: string;
  type: string | null;
  checked?: boolean;
  attributes: {
    sub_category?: SubCategory | SubCategory[] | null;
    brand?: Brand | Brand[] | null;
    tags?: string[] | null;
    reviews?: Reviews[] | null;
    name: string | null;
    sku?: string | null;
    description?: string | null;
    manufacture_date?: string | null;
    length?: number | null;
    breadth?: number | null;
    height?: number | null;
    stock_qty?: number | null;
    availability?: string | null;
    weight?: string | null;
    price?: number | number[] | null;
    images?:
      | {
          url: string;
        }[]
      | null;
    recommended?: boolean | null;
    on_sale?: boolean | null;
    sale_price?: CommonType;
    discount?: CommonType;
    category: Category;
    average_rating?: number | null;
    catalogue_variants?: CatalogueVariants[] | null;
  };
}

export interface Category {
  id: string;
  type: string | null;
  name?: string | null;
  attributes: {
    id?: number;
    name: string | null;
    dark_icon?: string | null;
    dark_icon_active?: string | null;
    dark_icon_inactive?: string | null;
    light_icon?: string | null;
    light_icon_active?: string | null;
    light_icon_inactive?: string | null;
    rank?: number | string | null;
    created_at?: string | null;
    updated_at?: string | null;
    selected_sub_categories?: [] | string | null;
  };
}
// Customizable Area End

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  outOfStock: boolean;
  pricerange: boolean;
  brand: boolean;
  category: boolean;
  tag: boolean;
  checkedStock: boolean;
  checkedDiscounted: boolean;
  rangeLow: string | null;
  rangeHigh: string | null;
  value: number;
  token: string;
  data: AllCategory[];
  checkedCategory: boolean;
  checkedTag: boolean;
  GetAllBrand: AllBrands[];
  BrandList: AllBrands[];
  selectedItems: AllBrands[];
  selectedCategory: AllCategory[];
  scrollEnabled: boolean;
  minValue: number;
  maxValue: number;
  priceMin: number;
  priceMax: number;
  arrayHolder: AllCategory[];
  categoryArray: AllCategory[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FilteroptionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId: string = "";
  getBrandApiCallId: string = "";
  applyAllApiCallId: string = "";

  minimumValue =
    this.props.navigation.state.params && this.props.navigation.state.params.min
      ? this.props.navigation.state.params.min
      : 0;
  maximumValue =
    this.props.navigation.state.params && this.props.navigation.state.params.max
      ? this.props.navigation.state.params.max
      : 100;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      outOfStock: false,
      pricerange: true,
      brand: false,
      category: false,
      tag: false,
      checkedStock: false,
      checkedDiscounted: false,
      rangeLow: "",
      rangeHigh: "",
      value: 10,
      token: "",
      data: [],
      checkedCategory: false,
      checkedTag: false,
      GetAllBrand: [],
      BrandList: [],
      selectedItems: [],
      selectedCategory: [],
      scrollEnabled: false,
      minValue: 10,
      maxValue: 200,
      priceMin: 0,
      priceMax: 0,
      arrayHolder: [],
      categoryArray: []
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  openFilter(value: string) {
    if (value === "stock") {
      this.setState({
        tag: false,
        outOfStock: true,
        pricerange: false,
        brand: false,
        category: false
      });
    }
    if (value === "pricerange") {
      this.setState({
        tag: false,
        outOfStock: false,
        pricerange: true,
        brand: false,
        category: false
      });
    }
    if (value === "brand") {
      this.setState({
        tag: false,
        outOfStock: false,
        pricerange: false,
        brand: true,
        category: false
      });
    }
    if (value === "category") {
      this.setState({
        tag: false,
        outOfStock: false,
        pricerange: false,
        brand: false,
        category: true
      });
    }
    if (value === "tags") {
      this.setState({
        tag: true,
        outOfStock: false,
        pricerange: false,
        brand: false,
        category: false
      });
    }
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    if (!this.isPlatformWeb()) {
      let params = this.props.navigation.state.params;
      const nextMinValue = params && params.min ? params.min : 0;
      const nextMaxValue = params && params.max ? params.max : 100;
      if (
        params.priceSelectedMin != undefined &&
        params.priceSelectedMax != undefined
      ) {
        this.setState({
          minValue: params.priceSelectedMin,
          maxValue: params.priceSelectedMax
        });
      } else {
        this.setState({
          minValue: nextMinValue,
          maxValue: nextMaxValue
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getToken = () => {
    const messageValue: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(messageValue);
  };
  getListRequest = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBrandList = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBrandApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.brandAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  applyAllfilters = () => {
    const { token } = this.state;
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const urlValue = configJSON.cataloguesAPiEndPoint;
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    this.applyAllApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlValue
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    switch (message.id) {
      case getName(MessageEnum.SessionResponseMessage):
          this.handleSessionResponse(message);
          break;
      case getName(MessageEnum.RestAPIResponceMessage):
          this.handleRestApiResponse(message);
          break;
      default:
          break;
    }
    // Customizable Area End
  }

  // Customizable Area Start
  onCheckBrand = (item: AllBrands, itemIndex: number) => {
    let items: AllBrands[] = [];
    items = this.state.GetAllBrand;
    items[itemIndex].checked = items[itemIndex].checked ? !items[itemIndex].checked : true;
    if (items) {
      items.forEach(itemI => {
        if (item.id === itemI.id) {
          if (itemI.checked) {
            this.state.selectedItems.push(itemI);
          } else {
            this.setState({ selectedItems: [] });
          }
        }
      });
    }

    this.setState({ GetAllBrand: items });
  };

  onCheckCategory = (item: AllCategory, itemIndex: number) => {
    let items = [];
    items = this.state.arrayHolder;
    items[itemIndex].checked = items[itemIndex].checked ? !items[itemIndex].checked : true;
    if (items) {
      items.forEach(itemI => {
        if (item.id === itemI.id) {
          if (itemI.checked) {
            this.state.selectedCategory.push(itemI);
          } else {
            this.setState({ selectedCategory: [] });
          }
        }
      });
    }

    this.setState({ data: items });
  };

  searchFilterFunction = (text: string) => {
    if (this.state.brand) {
      if (text === "") {
        this.setState({ GetAllBrand: this.state.BrandList });
      } else {
        let Name = this.state.BrandList;
        const textData = Name.filter(item =>
          item.attributes.name
            .toLocaleLowerCase()
            .startsWith(text.toLowerCase())
        );
        this.setState({ GetAllBrand: textData });
      }
    } else if (this.state.category) {
      if (text === "") {
        this.setState({ arrayHolder: this.state.categoryArray });
      } else {
        let Name = this.state.categoryArray;
        const textData = Name.filter(item =>
          (
            item.attributes.category.name ||
            item.attributes.category.attributes.name
          )
            ?.toLocaleLowerCase()
            .startsWith(text.toLowerCase())
        );
        this.setState({ arrayHolder: textData });
      }
    }
  };

  numberValuesChange = (value: number[]) => {
    this.setState({
      priceMin: value[0],
      priceMax: value[1]
    });
  };

  valueChangeFinish = () => {
    this.setState({
      minValue: this.state.priceMin,
      maxValue: this.state.priceMax
    });
  };

  goBack = () => {
    this.props.navigation.goBack();
  };

  handleSessionResponse(message: Message) {
    let token = message.getData(getName(MessageEnum.SessionResponseToken));
    this.setState({ token: token });
    this.getListRequest(token);
    this.getBrandList(token);
  }

  handleRestApiResponse(message: Message) {
      const responseData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (!responseData.data || responseData.errors) {
          this.parseApiCatchErrorResponse(errorMessage);
          return;
      }

      switch (message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
          case this.applyAllApiCallId:
              this.handleApplyAllApiResponse(responseData.data);
              break;
          case this.getBrandApiCallId:
              this.handleGetBrandApiResponse(responseData.data);
              break;
          case this.getProductApiCallId:
              this.handleGetProductApiResponse(responseData.data);
              break;
          default:
              break;
      }
  }

  handleApplyAllApiResponse(data: []) {
      this.setState({ data: data });
      if (data.length > 0) {
          this.props.navigation.push("Filteritems", { data: data });
      } else {
          this.showAlert("Error", "Data not found!!!");
      }
  }

  handleGetBrandApiResponse(data: []) {
      this.setState({ GetAllBrand: data, BrandList: data });
  }

  handleGetProductApiResponse(data: []) {
      this.setState({ arrayHolder: data, categoryArray: data });
  }
  // Customizable Area End
}

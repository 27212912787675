import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { downloadFromLink } from "../../../components/src/Utils"
import { getStoredDefaultRole } from "../../../components/src/ContextAPI/AdvancedFilterContext.web";

export const configJSON = require("./config");

export type ModuleType = 'people' | 'project' | 'tender' | 'supplier' | 'partner'

export interface PartnerPdfData {
    link : string
}

export interface ValidResponseType {
    data: object;
    errors: Array<ErrorPayloadType>;
  }
  export interface ErrorPayloadType {
    key: string;
    message: string;
  }
  export interface ErrorResponseType {
    errors: string;
  }
  export interface InvalidResponseType {
    errors: Array<ErrorPayloadType>;

  }
export interface Props {
    targetId: string;
    module: ModuleType
}

interface S {
    loading: boolean
}

interface SS {
    id: any;
}
export default class ProfertSaveAsPdfController extends BlockComponent<Props, S, SS> {
    getPeoplePDFFileCallId: string = ""
    getProjectPDFFileCallId: string = ""
    getTenderPDFFileCallId:string = ""
    getSupplierPDFFileCallId: string = ""
    getPartnerPDFFileCallId: string = ""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            loading: false,
        };
        
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.isValidResponseController(responseJson)) {
                this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
            }
        }

    }

    isValidResponseController = (responseJson: ValidResponseType) => {
        return responseJson && !responseJson.errors;
    };

    apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: PartnerPdfData) => {
        if (apiRequestCallId === this.getPeoplePDFFileCallId) {
            this.handleGetPDFPeopleSuccess(responseJson)
        }
        if (apiRequestCallId === this.getProjectPDFFileCallId) {
            this.handleGetPDFProjectSuccess(responseJson)
        }
        if (apiRequestCallId === this.getTenderPDFFileCallId) {
            this.handleGetPDFTenderSuccess(responseJson)
        }
        if (apiRequestCallId === this.getSupplierPDFFileCallId) {
            this.handleGetPDFSupplierSuccess(responseJson)
        }
        if (apiRequestCallId === this.getPartnerPDFFileCallId) {
            this.handleGetPDFPartnerSuccess(responseJson)
        }
    };

    requestGetMessageCall = async (valueData: {
        typeContent?: string;
        apiType?: string;
        method?: string;
        endPoint?: string;
        type?: string;
    }) => {
        let { typeContent, method, endPoint } = valueData;
        const authToken = (await localStorage.getItem("authToken")) || "";
        let headerAuth = {
            "Content-Type": typeContent,
            token: authToken,
        };
        let messageAPI = new Message(getName(MessageEnum.RestAPIRequestMessage));
        
        messageAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        messageAPI.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        messageAPI.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headerAuth)
        );
        runEngine.sendMessage(messageAPI.id, messageAPI);
        return messageAPI.messageId;
    };

    requestGetPDFPeople = async () => {
        this.setState({
            loading: true
        })

        const { module } = this.props;

        if (module == 'people') {
            this.getPeoplePDFFileCallId = await this.requestGetMessageCall({
                method: configJSON.validationApiMethodType,
                endPoint: configJSON.peopleSaveEndpoint + `?format=pdf&id=${this.props.targetId}&role=${getStoredDefaultRole()}`,
                typeContent: configJSON.validationApiContentType,
            })
        } else if (module == 'tender') {
            this.getTenderPDFFileCallId = await this.requestGetMessageCall({
                method: configJSON.validationApiMethodType,
                endPoint: configJSON.tenderSaveEndpoint + `?format=pdf&id=${this.props.targetId}&role=${getStoredDefaultRole()}`,
                typeContent: configJSON.validationApiContentType,
            })
        } else if (module == 'supplier' ) {
            this.getSupplierPDFFileCallId = await this.requestGetMessageCall({
                method: configJSON.validationApiMethodType,
                endPoint: configJSON.supplierSaveEndpoint + `?format=pdf&id=${this.props.targetId}&role=${getStoredDefaultRole()}`,
                typeContent: configJSON.validationApiContentType,
            })
        } else if (module == 'partner' ) {
            this.getPartnerPDFFileCallId = await this.requestGetMessageCall({
                method: configJSON.validationApiMethodType,
                endPoint: configJSON.partnerSaveEndpoint + `?format=pdf&module_type=partner&id=${this.props.targetId}&role=${getStoredDefaultRole()}`,
                typeContent: configJSON.validationApiContentType,
            })
        } else {
            this.getProjectPDFFileCallId = await this.requestGetMessageCall({
                method: configJSON.validationApiMethodType,
                endPoint: configJSON.projectSaveEndpoint + `?format=pdf&id=${this.props.targetId}&role=${getStoredDefaultRole()}`,
                typeContent: configJSON.validationApiContentType,
            })
        }
        
    }

    handleGetPDFPeopleSuccess = (responseJson: any) => {
        if (responseJson?.link) {
            const linkDownload = responseJson.link as string;
            downloadFromLink("", linkDownload)

            this.setState({
                loading: false
            })
        }
    }
    handleGetPDFTenderSuccess = (responseJson: any) => {
        if (responseJson?.link) {
            const linkDownload = responseJson.link as string;
            downloadFromLink("", linkDownload)

            this.setState({
                loading: false
            })
        }
    }

    handleGetPDFProjectSuccess = (responseJson: any) => {
        if (responseJson?.link) {
            const linkDownload = responseJson.link as string;
            downloadFromLink("", linkDownload)

            this.setState({
                loading: false
            })
        }
    }

    handleGetPDFSupplierSuccess = (responseJson: any) => {
        if (responseJson?.link) {
            const linkDownload = responseJson.link as string;
            downloadFromLink("", linkDownload)

            this.setState({ loading: false })
        }
    }

    handleGetPDFPartnerSuccess = (responseJson: PartnerPdfData ) => {
        if (responseJson?.link) {
            const linkDownload = responseJson.link;
            downloadFromLink("", linkDownload)

            this.setState({ loading: false })
        }
    }

}

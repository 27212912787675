// Customizable Area Start
import {
  Theme,
  Box,
  createTheme,
  MuiThemeProvider,
  createStyles,
  withStyles,
  debounce,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import CustomTypographyWeb from "../../../components/src/CustomTypography.web";
import CustomButton from "../../../components/src/CustomButton.web";
import ConnectionController, { Props } from "./ConnectionController.web";
import Tabs from "../../../components/src/Tabs";
import { ConnectionModal } from "./Components/ConnectionModal.web";
import LoadAutocompleteSelect from "../../../components/src/CustomFields/LoadAutocompleteSelect.web";
import ConnectionTable from "./Components/ConnectionTable.web";
import { RowColumn, requestIcon } from "./assets";
import CustomSecondaryButton from "../../../components/src/CustomSecondaryButton.web";
import { CustomTextField } from "../../multipageforms/src/assets";
import {
  AccessControl,
  ActionControlProps,
} from "../../../components/src/Permission";
import { capitalizeFirstLetter } from "../../../components/src/Utils";
import {
  CustomAlertSuccess,
  CustomAlertWarning,
  CustomAlertError,
} from "../../../components/src/CustomAlert";

const searchIcon = require("../assets/search_icon.svg");
const editPageButtonSvg = require("../assets/page_edit.svg");

const THEME = createTheme({
  typography: {
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontFamily: "Source Sans Pro",
    fontSize: 14,
    fontWeightLight: 300,
  },
});
// Customizable Area End

export class Connection extends ConnectionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderModal() {
    const { targets } = this.props;
    const { activeTab } = this.state;
    const activeCategory = targets[activeTab];

    switch (activeCategory) {
      case "people": {
        return (
          <ConnectionModal
            title="Peoples"
            data-test-id="modal_1"
            isOpen={this.state.isOpenAddModal}
            onSubmit={this.handleTemporaryAdd}
            onClose={this.closeModal}
          >
            <LoadAutocompleteSelect
              data-test-id="autoCompleteSearch"
              placeholder="Search people to add"
              loading={this.state.loadingOption}
              startAdornment={
                <img
                  style={{ marginRight: "16px", marginLeft: "16px" }}
                  src={searchIcon}
                  alt="Search people"
                />
              }
              onSearch={debounce(this.handleSearchChange, 1000)}
              onChange={this.handleSelect}
              helperText={this.state.hasSearchError}
              options={this.state.dataOptions}
              value={this.state.searchQuery}
              error={!!this.state.hasSearchError}
            />
          </ConnectionModal>
        );
      }

      case "project":
        return (
          <ConnectionModal
            title="Projects"
            isOpen={this.state.isOpenAddModal}
            onSubmit={this.handleTemporaryAdd}
            data-test-id="modal_2"
            onClose={this.closeModal}
          >
            <LoadAutocompleteSelect
              data-test-id="autoCompleteSearch"
              loading={this.state.loadingOption}
              placeholder="Search project to add"
              startAdornment={
                <img
                  style={{ marginRight: "16px", marginLeft: "16px" }}
                  src={searchIcon}
                  alt="Search project"
                />
              }
              onSearch={debounce(this.handleSearchChange, 1000)}
              options={this.state.dataOptions}
              value={this.state.searchQuery}
              onChange={this.handleSelect}
              error={!!this.state.hasSearchError}
              helperText={this.state.hasSearchError}
            />
          </ConnectionModal>
        );
      case "tender":
        return (
          <ConnectionModal
            isOpen={this.state.isOpenAddModal}
            onClose={this.closeModal}
            data-test-id="modal_3"
            title="Tenders"
            onSubmit={this.handleTemporaryAdd}
          >
            <LoadAutocompleteSelect
              placeholder="Search tender to add"
              error={!!this.state.hasSearchError}
              data-test-id="autoCompleteSearch"
              startAdornment={
                <img
                  style={{ marginRight: "16px", marginLeft: "16px" }}
                  src={searchIcon}
                  alt="Search tender"
                />
              }
              helperText={this.state.hasSearchError}
              value={this.state.searchQuery}
              onChange={this.handleSelect}
              options={this.state.dataOptions}
              onSearch={debounce(this.handleSearchChange, 1000)}
              loading={this.state.loadingOption}
            />
          </ConnectionModal>
        );
      case "supplier":
        return (
          <ConnectionModal
            data-test-id="modal_4"
            onSubmit={this.handleTemporaryAdd}
            title="Suppliers"
            isOpen={this.state.isOpenAddModal}
            onClose={this.closeModal}
          >
            <LoadAutocompleteSelect
              data-test-id="autoCompleteSearch"
              loading={this.state.loadingOption}
              placeholder="Search partner to add"
              onSearch={debounce(this.handleSearchChange, 1000)}
              onChange={this.handleSelect}
              options={this.state.dataOptions}
              value={this.state.searchQuery}
              startAdornment={
                <img
                  style={{ marginRight: "16px", marginLeft: "16px" }}
                  src={searchIcon}
                  alt="Search Supplier"
                />
              }
              error={!!this.state.hasSearchError}
              helperText={this.state.hasSearchError}
            />
          </ConnectionModal>
        );
      case "partner":
        return (
          <ConnectionModal
            isOpen={this.state.isOpenAddModal}
            title="Partners"
            onClose={this.closeModal}
            onSubmit={this.handleTemporaryAdd}
            data-test-id="modal_5"
          >
            <LoadAutocompleteSelect
              startAdornment={
                <img
                src={searchIcon}
                style={{ marginRight: "16px", marginLeft: "16px" }}
                alt="Search Partner"
                />
              }
              onSearch={debounce(this.handleSearchChange, 1000)}
              onChange={this.handleSelect}
              options={this.state.dataOptions}
              data-test-id="autoCompleteSearch"
              placeholder="Search partner to add"
              loading={this.state.loadingOption}
              value={this.state.searchQuery}
              error={!!this.state.hasSearchError}
              helperText={this.state.hasSearchError}
            />

            <Box
              mt={3}
              display={this.props.source == "project" ? "block" : "none"}
            >
              <CustomTypographyWeb isHeader style={{ marginBottom: "12px" }}>
                {" "}
                JV Share(%){" "}
              </CustomTypographyWeb>
              <CustomTextField
                variant="outlined"
                disabled={this.props.disableJVShare}
                placeholder="JVShare percentage"
                data-testId="jvShare"
                type="number"
                onChange={this.handleJVChange}
                inputProps={{
                  maxLength: 3,
                }}
                error={!!this.state.jvShareError}
                helperText={this.state.jvShareError}
              />
            </Box>
          </ConnectionModal>
        );

      default:
        return null;
    }
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { targets, schema } = this.props;
    const { activeTab } = this.state;
    const activeCategory = targets[activeTab];

    return (
      <>
        <MuiThemeProvider theme={THEME}>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="end"
            marginBottom={4}
            style={ { marginLeft: -20 }}
            visibility={!this.state.editMode ? "visible" : "none"}
          >
            {!this.state.editMode && (
              <AccessControl
                feature="update"
                module={
                  capitalizeFirstLetter(
                    this.props.source
                  ) as ActionControlProps["module"]
                }
              >
                <CustomSecondaryButton
                  label="Edit"
                  startIcon={<img src={editPageButtonSvg} alt="Edit Page" />}
                  style={{
                    color: "#0094A7",
                    boxShadow: "none",
                    border: "none",
                    textDecoration: "underline",
                    backgroundColor: "transparent",
                  }}
                  data-testId="editBtn"
                  onClick={this.toggleEditMode}
                />
              </AccessControl>
            )}
          </Box>

          <Tabs
            activeIndex={activeTab}
            tabs={this.props.tabLabels}
            data-testId="connectionTab"
            onChangeTab={this.handleTabChange}
          />

          <Box marginTop={8}>
            {this.state.loading ? (
              <Box textAlign="center">
                <CircularProgress
                  color="inherit"
                  size={"4rem"}
                  style={{ color: "black", display: "inline-block" }}
                />
              </Box>
            ) : (
              this.state.tableData.length > 0 && (
                <ConnectionTable
                  data={this.state.tableData}
                  editMode={this.state.editMode}
                  data-testId="connectionTable"
                  schema={schema[activeCategory] as RowColumn[]}
                  onDeleteRow={this.handleDeleteItem}
                />
              )
            )}
          </Box>

          {this.renderModal()}

          <CustomAlertWarning
            data-testId="confirmYes"
            open={this.state.showConfirmationAlert}
            autoHideDuration={6000}
            onClose={this.handleDeleteDismiss}
            style={{ bottom: 170 }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            message="Are you sure want to delete?"
            onConfirmClose={this.handleDeleteDismiss}
            onConfirmYes={this.handleSaveChanges}
          />

          <CustomAlertSuccess
            open={this.state.showSucessAlert}
            autoHideDuration={3000}
            style={{ bottom: 40 }}
            message="Saved Successfully"
            onClose={this.handleClose}
            onConfirmClose={this.handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          />

          <CustomAlertError
            open={!!this.state.showErrorAlert}
            autoHideDuration={3000}
            style={{ bottom: 40 }}
            onClose={this.handleClose}
            message={this.state.showErrorAlert}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          />

          {this.state.editMode && (
            <Box mt={2}>
              <CustomButton
                colorVariant="special"
                data-testId="addMoreBtn"
                onClick={this.handleAdd}
              >
                {this.state.tableData?.length == 0 ? "Add" : "Add More"}
              </CustomButton>
            </Box>
          )}

          {this.state.editMode ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              style={{
                gap: "20px",
                marginTop: "194px",
              }}
            >
              <CustomButton
                onClick={this.toggleEditMode}
                colorVariant="special"
              >
                Cancel
              </CustomButton>

              <CustomButton
                colorVariant="default"
                data-testId="deleteBtn"
                onClick={this.showConfirmation}
              >
                Save
              </CustomButton>
            </Box>
          ) : (
            <Box
              justifyContent="flex-end"
              display="flex"
              alignItems="center"
              style={{
                marginTop: "20px",
                gap: "20px",
              }}
            >
              <CustomButton
                colorVariant="projectRequest"
                data-test-id="requestNavigator"
                startIcon={<img src={requestIcon} alt="Request" />}
                onClick={this.props.handleRequestNavigation}
              >
                Request
              </CustomButton>
            </Box>
          )}
        </MuiThemeProvider>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    customPosition: {},
  });

export default withStyles(styles)(Connection);
// Customizable Area End

import {
  currentPositionIcon,
  educationIcon,
  experienceIcon,
  favouriteIcon,
  groupRelationIcon,
  keywordIcon,
  languageIcon,
  nationalityIcon,
  savedSearchIcon,
  groupCompanyIcon,
  clientsIcon,
  countryIcon,
  servicesIcon,
  sectorIcon,
  valueOfWorkIcon,
  statusIcon,
} from "../FilterView/assests";
import { AccordianItem, AccordianRequestItem } from "../Types";
import { allTimeZoneList } from "../Utils";
// currentPositionIcon
// educationIcon
// experienceIcon
// favouriteIcon
// groupRelationIcon
// keywordIcon
// languageIcon
// nationalityIcon
// savedSearchIcon

const countrylist = JSON.parse(JSON.stringify(allTimeZoneList));
export const NavbarSections = ['Dashboard', 'People', 'Projects', 'Tenders', 'Suppliers' ,'Partners']

function handleFilterListIcon(key: string): string {
  const iconMapping: { [key: string]: string } = {
    Education: educationIcon,
    Experience: experienceIcon,
    CurrentPosition: currentPositionIcon,
    Favorite: favouriteIcon,
    GroupRelation: groupRelationIcon,
    Keyword: keywordIcon,
    Language: languageIcon,
    Nationality: nationalityIcon,
    SavedSearch: savedSearchIcon,
    GroupCompanyIcon: groupCompanyIcon,
    ClientsIcon: clientsIcon,
    CountryIcon: countryIcon,
    ServicesIcon: servicesIcon,
    SectorIcon: sectorIcon,
    ValueOfWorkIcon: valueOfWorkIcon,
    StatusIcon: statusIcon,
    // Add more mappings as needed
  };

  return iconMapping[key] || "";
}

export const ACCORDIAN_LIST: AccordianItem[] = [
  {
    id: 0,
    icon: handleFilterListIcon("SavedSearch"),
    placeHolder:"",
    text: "SAVED SEARCH",
    link: "SAVED SEARCH",
    show: false,
    filterOption: {},
    showSearchInput:true,
    showResetButton:true,
  },
  {
    id: 0,
    icon: handleFilterListIcon("Education"),
    text: "EDUCATION",
    link: "EDUCATION",
    placeHolder:"Education",
    show: false,
    filterOption: {
      Level: [
        {
          label: "All",
          value: "All",
          isChecked: false,
        },
        {
          label: "Ph.D",
          value: "PHD",
          isChecked: false,
        },
        {
          label: "Master",
          value: "Master",
          isChecked: false,
        },
        {
          label: "Bachelor",
          value: "Bachelor",
          isChecked: false,
        },
        {
          label: "Diploma",
          value: "Diploma",
          isChecked: false,
        },
      ],
      Discipline: [
        {
          label: "All",
          value: "All",
          isChecked: false,
        },
        {
          label: "Business Administration",
          value: "Business Administration",
          isChecked: false,
        },
        {
          label: "Chemical Engineering",
          value: "Chemical Engineering",
          isChecked: false,
        },
        {
          label: "Electrical Engineering",
          value: "Electrical Engineering",
          isChecked: false,
        },
        {
          label: "Mechanical Engineering",
          value: "Mechanical Engineering",
          isChecked: false,
        },
        {
          label: "Instrumentation",
          value: "Instrumentation",
          isChecked: false,
        },
        {
          label: "Agronomy",
          value: "Agronomy",
          isChecked: false,
        },
        {
          label: "Industrial Engineering",
          value: "Industrial Engineering",
          isChecked: false,
        },
        {
          label: "Draughtsman / CAD Operator",
          value: "Draughtsman / CAD Operator",
          isChecked: false,
        },
        {
          label: "Quantity Surveying",
          value: "Quantity Surveying",
          isChecked: false,
        },
        {
          label: "Survey Engineering",
          value: "Survey Engineering",
          isChecked: false,
        },
        {
          label: "Geology",
          value: "Geology",
          isChecked: false,
        },
        {
          label: "Geotechnical",
          value: "Geotechnical",
          isChecked: false,
        },
        {
          label: "Economy",
          value: "Economy",
          isChecked: false,
        },
        {
          label: "Urban Planner",
          value: "Urban Planner",
          isChecked: false,
        },
        {
          label: "Other Engineering",
          value: "Other Engineering",
          isChecked: false,
        },
        {
          label: "Other Discipline",
          value: "Other Discipline",
          isChecked: false,
        },
      ],
    },
    showSearchInput:true,
    showResetButton:true,
  },
  {
    id: 1,
    icon: handleFilterListIcon("Experience"),
    text: "EXPERIENCE",
    link: "EXPERIENCE",
    placeHolder:"Experience",
    show: false,
    filterOption: {
      Primary: [
        {
          label: "All",
          value: "All",
          isChecked: false,
        },
        {
          label: "Administration / Accounting / Financing",
          value: "Administration / Accounting / Financing",
          isChecked: false,
        },
        {
          label: "Agriculture / Irrigation",
          value: "Agriculture / Irrigation",
          isChecked: false,
        },
        {
          label: "Airports",
          value: "Airports",
          isChecked: false,
        },
        {
          label: "Architectural / Landscaping",
          value: "Architectural / Landscaping",
          isChecked: false,
        },
        {
          label: "Buildings",
          value: "Buildings",
          isChecked: false,
        },
        {
          label: "Contracts Expert / Procurement",
          value: "Contracts Expert / Procurement",
          isChecked: false,
        },
        {
          label: "Dams / Hydro-electric",
          value: "Dams / Hydro-electric",
          isChecked: false,
        },
        {
          label: "Drainage / Hydrology",
          value: "Drainage / Hydrology",
          isChecked: false,
        },
        {
          label: "Environment / TIA",
          value: "Environment / TIA",
          isChecked: false,
        },
        {
          label: "Geology / Geotechnics",
          value: "Geology / Geotechnics",
          isChecked: false,
        },
        {
          label: "Harbours and Marine engineering",
          value: "Harbours and Marine engineering",
          isChecked: false,
        },
        {
          label: "Health & Safety",
          value: "Health & Safety",
          isChecked: false,
        },
        {
          label: "Highways & Roads",
          value: "Highways & Roads",
          isChecked: false,
        },
        {
          label: "Industrial / Energy / Oil & Gas",
          value: "Industrial / Energy / Oil & Gas",
          isChecked: false,
        },
        {
          label: "IT",
          value: "IT",
          isChecked: false,
        },
        {
          label: "Urban Planning",
          value: "Urban Planning",
          isChecked: false,
        },
        {
          label: "Power Transmission / Distribution / Generation",
          value: "Power Transmission / Distribution / Generation",
          isChecked: false,
        },
        {
          label: "Railways",
          value: "Railways",
          isChecked: false,
        },
        {
          label: "Socio-Economic",
          value: "Socio-Economic",
          isChecked: false,
        },
        {
          label: "Structures & Bridges",
          value: "Structures & Bridges",
          isChecked: false,
        },
        {
          label: "Topography",
          value: "Topography",
          isChecked: false,
        },
        {
          label: "Transporter Planner / Traffic Analysis",
          value: "Transporter Planner / Traffic Analysis",
          isChecked: false,
        },
        {
          label: "Tunnels",
          value: "Tunnels",
          isChecked: false,
        },
        {
          label: "Water Supply / Sewerage / Waste Management",
          value: "Water Supply / Sewerage / Waste Management",
          isChecked: false,
        },
        {
          label: "Oil and Gas",
          value: "Oil and Gas",
          isChecked: false,
        },
        {
          label: "Other",
          value: "Other",
          isChecked: false,
        },
      ],
      Secondary: [
        {
          label: "All",
          value: "All",
          isChecked: false,
        },
        {
          label: "Administration / Accounting / Financing",
          value: "Administration / Accounting / Financing",
          isChecked: false,
        },
        {
          label: "Agriculture / Irrigation",
          value: "Agriculture / Irrigation",
          isChecked: false,
        },
        {
          label: "Airports",
          value: "Airports",
          isChecked: false,
        },
        {
          label: "Architectural / Landscaping",
          value: "Architectural / Landscaping",
          isChecked: false,
        },
        {
          label: "Buildings",
          value: "Buildings",
          isChecked: false,
        },
        {
          label: "Contracts Expert / Procurement",
          value: "Contracts Expert / Procurement",
          isChecked: false,
        },
        {
          label: "Dams / Hydro-electric",
          value: "Dams / Hydro-electric",
          isChecked: false,
        },
        {
          label: "Drainage / Hydrology",
          value: "Drainage / Hydrology",
          isChecked: false,
        },
        {
          label: "Environment / TIA",
          value: "Environment / TIA",
          isChecked: false,
        },
        {
          label: "Geology / Geotechnics",
          value: "Geology / Geotechnics",
          isChecked: false,
        },
        {
          label: "Harbours and Marine engineering",
          value: "Harbours and Marine engineering",
          isChecked: false,
        },
        {
          label: "Health & Safety",
          value: "Health & Safety",
          isChecked: false,
        },
        {
          label: "Highways & Roads",
          value: "Highways & Roads",
          isChecked: false,
        },
        {
          label: "Industrial / Energy / Oil & Gas",
          value: "Industrial / Energy / Oil & Gas",
          isChecked: false,
        },
        {
          label: "IT",
          value: "IT",
          isChecked: false,
        },
        {
          label: "Urban Planning",
          value: "Urban Planning",
          isChecked: false,
        },
        {
          label: "Power Transmission / Distribution / Generation",
          value: "Power Transmission / Distribution / Generation",
          isChecked: false,
        },
        {
          label: "Railways",
          value: "Railways",
          isChecked: false,
        },
        {
          label: "Socio-Economic",
          value: "Socio-Economic",
          isChecked: false,
        },
        {
          label: "Structures & Bridges",
          value: "Structures & Bridges",
          isChecked: false,
        },
        {
          label: "Topography",
          value: "Topography",
          isChecked: false,
        },
        {
          label: "Transporter Planner / Traffic Analysis",
          value: "Transporter Planner / Traffic Analysis",
          isChecked: false,
        },
        {
          label: "Tunnels",
          value: "Tunnels",
          isChecked: false,
        },
        {
          label: "Water Supply / Sewerage / Waste Management",
          value: "Water Supply / Sewerage / Waste Management",
          isChecked: false,
        },
        {
          label: "Oil and Gas",
          value: "Oil and Gas",
          isChecked: false,
        },
        {
          label: "Other",
          value: "Other",
          isChecked: false,
        },
      ],
    },
    showSearchInput:true,
    showResetButton:true,
  },
  {
    id: 2,
    icon: handleFilterListIcon("CurrentPosition"),
    text: "CURRENT POSITION",
    link: "CURRENT POSITION",
    placeHolder:"Current Position",
    show: false,
    filterOption: {
      Position: [
        {
          label: "Design",
          value: "design",
          isChecked: false,
        },
        {
          label: "Supervision",
          value: "supervision",
          isChecked: false,
        },
        {
          label: "Manager",
          value: "Manager",
          isChecked: false,
        },
        {
          label: "Project Manager / Team Leader",
          value: "Project Manager / Team Leader",
          isChecked: false,
        },
        {
          label: "Designer / Design Engineer",
          value: "Designer / Design Engineer",
          isChecked: false,
        },
        {
          label: "Quantity Surveyor",
          value: "Quantity Surveyor",
          isChecked: false,
        },
        {
          label: "Land Surveyor",
          value: "Land Surveyor",
          isChecked: false,
        },
        {
          label: "CAD Technician / Draftsman",
          value: "CAD Technician / Draftsman",
          isChecked: false,
        },
        {
          label: "Administrative Assistant / Document Controller",
          value: "Administrative Assistant / Document Controller",
          isChecked: false,
        },
        {
          label: "Resident Engineer / Construction Manager",
          value: "Resident Engineer / Construction Manager",
          isChecked: false,
        },
        {
          label: "Civil Engineer / Asistant Resident Engineer",
          value: "Civil Engineer / Asistant Resident Engineer",
          isChecked: false,
        },
        {
          label: "Materials Inspector",
          value: "Materials Inspector",
          isChecked: false,
        },
        {
          label: "Site Inspector",
          value: "Site Inspector",
          isChecked: false,
        },
        {
          label: "Land Surveyor",
          value: "Land Surveyor",
          isChecked: false,
        },
        {
          label: "Material Engineer / Quality Controller/QA/QC",
          value: "Material Engineer / Quality Controller/QA/QC",
          isChecked: false,
        },
        {
          label: "Planning Engineer",
          value: "Planning Engineer",
          isChecked: false,
        },
        {
          label: "HSE Specialist",
          value: "HSE Specialist",
          isChecked: false,
        },
        {
          label: "Other",
          value: "Other",
          isChecked: false,
        },
      ],
    },
    showSearchInput:true,
    showResetButton:true,
  },
  {
    id: 3,
    icon: handleFilterListIcon("Nationality"),
    text: "NATIONALITY",
    link: "NATIONALITY",
    placeHolder:"Nationality",
    show: false,
    filterOption: {
      "": countrylist.map((item: any) => ({label: item.name, value: item.name, isChecked: false}))
    },
    showSearchInput:true,
    showResetButton:true,
  },
  {
    id: 4,
    icon: handleFilterListIcon("Language"),
    text: "LANGUAGE",
    link: "LANGUAGE",
    placeHolder:"Language",
    show: false,
    filterOption: {
      "": [
        {
          label: "English",
          value: "English",
          isChecked: false,
        },
        {
          label: "Arabic",
          value: "Arabic",
          isChecked: false,
        },
        {
          label: "Italian",
          value: "Italian",
          isChecked: false,
        },
        {
          label: "French",
          value: "French",
          isChecked: false,
        },
        {
          label: "Spanish",
          value: "Spanish",
          isChecked: false,
        },
      ],
    },
    showSearchInput:true,
    showResetButton:true,
  },
  {
    id: 5,
    icon: handleFilterListIcon("GroupRelation"),
    text: "GROUP RELATION",
    link: "GROUP RELATION",
    placeHolder:"",
    show: false,
    showResetButton:true,
    filterOption: {},
    filterOptionForRadio: {
      related: {
        "Currently Employed": {
          show: false,
          optionsList: [
            {
              label: "Renardet Oman",
              value: "Renardet Oman",
              isChecked: false,
            },
            {
              label: "Bonifica",
              value: "Bonifica",
              isChecked: false,
            },
            {
              label: "Renardet SA",
              value: "Renardet SA",
              isChecked: false,
            },
            {
              label: "Profert",
              value: "Profert",
              isChecked: false,
            },
          ],
        },

        "Past Employed": {
          show: false,
          optionsList: [
            {
              label: "Renardet Oman",
              value: "Renardet Oman",
              isChecked: false,
            },
            {
              label: "Bonifica",
              value: "Bonifica",
              isChecked: false,
            },
            {
              label: "Renardet SA",
              value: "Renardet SA",
              isChecked: false,
            },
            {
              label: "Profert",
              value: "Profert",
              isChecked: false,
            },
          ],
        },
        "Submitted for Tender": {
          show: false,
          optionsList: [
            {
              label: "Yes",
              value: "Yes",
              isChecked: false,
            },
            {
              label: "No",
              value: "No",
              isChecked: false,
            },
          ],
        },
      },
      unrelated: {
        "": {
          show: false,
          optionsList: [
            {
              label: "Interviewed or Recommended",
              value: "Interviewed or Recommended",
              isChecked: false,
            },
            {
              label: "Not interviewed",
              value: "Not interviewed",
              isChecked: false,
            },
          ],
        },
      },
    },

    radioOption: [
      {
        label: "Related",
        value: "related",
        isChecked: false,
      },
      {
        label: "Unrelated",
        value: "unrelated",
        isChecked: false,
      },
    ],
    showSearchInput:false,
  },
  {
    id: 6,
    icon: handleFilterListIcon("Favorite"),
    text: "FAVOURITES",
    link: "FAVOURITES",
    placeHolder:"",
    show: false,
    filterOption: {
      "": [
        {
          label: "Show favourites in top list",
          value: "favourites",
          isChecked: false,
        },
      ],
    },
  },
];

export const accordionDummyList: { [key:string]: AccordianItem[]} = {
  people : [
    {
      id: 0,
      icon: handleFilterListIcon("SavedSearch"),
      placeHolder: "",
      text: "SAVED SEARCH",
      link: "SAVED SEARCH",
      show: false,
      filterOption: {},
      savedSearchList: [],
    },
    {
      id: 1,
      icon: handleFilterListIcon("Education"),
      text: "EDUCATION",
      link: "EDUCATION",
      isSubLabel: true,
      placeHolder: "Education",
      show: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 2,
      icon: handleFilterListIcon("Experience"),
      text: "EXPERIENCE",
      link: "EXPERIENCE",
      isSubLabel: true,
      placeHolder: "Experience",
      show: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 3,
      icon: handleFilterListIcon("CurrentPosition"),
      text: "CURRENT POSITION",
      link: "CURRENT POSITION",
      isSubLabel: true,
      placeHolder: "Current Position",
      show: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 4,
      icon: handleFilterListIcon("Nationality"),
      text: "NATIONALITY",
      link: "NATIONALITY",
      isSubLabel: false,
      placeHolder: "Nationality",
      show: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 5,
      icon: handleFilterListIcon("Language"),
      text: "LANGUAGE",
      link: "LANGUAGE",
      isSubLabel: false,
      placeHolder: "Language",
      show: false,
      filterOption: {
        "language": [
          {
            label: "English",
            value: "English",
            isChecked: false,
          },
          {
            label: "French",
            value: "French",
            isChecked: false,
          },
          {
            label: "Arabic",
            value: "Arabic",
            isChecked: false,
          },
          {
            label: "Spanish",
            value: "Spanish",
            isChecked: false,
          },
          {
            label: "Italian",
            value: "Italian",
            isChecked: false,
          },
        ]
      },
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 6,
      icon: handleFilterListIcon("GroupRelation"),
      text: "GROUP RELATION",
      link: "GROUP RELATION",
      placeHolder: "",
      show: false,
      showResetButton: true,
      filterOption: {},
      filterOptionForRadio: {
        related: {
          "Currently Employed": {
            show: false,
            render: "related[Currently Employed]",
            optionsList: [
              {
                label: "Renardet Oman",
                value: "Renardet Oman",
                isChecked: false,
              },
              {
                label: "Bonifica",
                value: "Bonifica",
                isChecked: false,
              },
              {
                label: "Renardet SA",
                value: "Renardet SA",
                isChecked: false,
              },
              {
                label: "Profert",
                value: "Profert",
                isChecked: false,
              },
            ],
          },
          "Past Employed": {
            show: false,
            render: "related[Past Employed]",
            optionsList: [
              {
                label: "Renardet Oman",
                value: "Renardet Oman",
                isChecked: false,
              },
              {
                label: "Bonifica",
                value: "Bonifica",
                isChecked: false,
              },
              {
                label: "Renardet SA",
                value: "Renardet SA",
                isChecked: false,
              },
              {
                label: "Profert",
                value: "Profert",
                isChecked: false,
              },
            ],
          },
          "Submitted for Tender": {
            show: false,
            render: "related[Submitted for tender]",
            optionsList: [
              {
                label: "Yes",
                value: "Yes",
                isChecked: false,
              },
              {
                label: "No",
                value: "No",
                isChecked: false,
              },
            ],
          },
        },
        unrelated: {
          "": {
            render: "unrelated",
            show: false,
            optionsList: [
              {
                label: "Interviewed or Recommended",
                value: "Interviewed or Recommended",
                isChecked: false,
              },
              {
                label: "Not interviewed",
                value: "Not interviewed",
                isChecked: false,
              },
            ],
          },
        },
      },
      radioOption: [
        {
          label: "Related",
          value: "related",
          isChecked: false,
        },
        {
          label: "Unrelated",
          value: "unrelated",
          isChecked: false,
        },
      ],
      showSearchInput: false,
    },
    {
      id: 7,
      icon: handleFilterListIcon("Favorite"),
      text: "FAVOURITES",
      link: "FAVOURITES",
      placeHolder: "",
      isFavourite: true,
      isSubLabel: false,
      show: false,
      filterOption: {
        "favourite": [
          {
            label: "Show favourites in top list",
            value: "favourites",
            isChecked: false,
          },
        ],
      },
      showResetButton: true,
    },
  ],
  projects: [
    {
      id: 0,
      icon: handleFilterListIcon("SavedSearch"),
      placeHolder: "",
      text: "SAVED SEARCH",
      link: "SAVED SEARCH",
      show: false,
      isSubLabel: false,
      filterOption: {},
      savedSearchList: []
    },
    {
      id: 1,
      icon: handleFilterListIcon("GroupCompanyIcon"),
      text: "GROUP COMPANY",
      link: "group_company",
      placeHolder: "Group company",
      show: false,
      filterOption: {},
      isSubLabel: false,
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 2,
      icon: handleFilterListIcon("ClientsIcon"),
      text: "CLIENTS",
      link: "client",
      placeHolder: "clients",
      show: false,
      filterOption: {},
      isSubLabel: false,
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 3,
      icon: handleFilterListIcon("CountryIcon"),
      text: "COUNTRY",
      link: "country",
      placeHolder: "country",
      show: false,
      isSubLabel: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 4,
      icon: handleFilterListIcon("ServicesIcon"),
      text: "SERVICES",
      link: "services",
      isSubLabel: false,
      placeHolder: "services",
      show: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 5,
      icon: handleFilterListIcon("SectorIcon"),
      text: "SECTOR",
      link: "sector",
      placeHolder: "sectors",
      show: false,
      isSubLabel: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 6,
      icon: handleFilterListIcon("ValueOfWorkIcon"),
      text: "VALUE OF WORK RANGE",
      link: "value_of_work",
      placeHolder: "value of work",
      show: false,
      isSubLabel: false,
      filterOption: {
        "value_of_work_range" : [
          {
            label: "0-1 MLN USD",
            value: "0-1 MLN USD",
            isChecked: false,
          },
          {
            label: "1-3 MLN USD",
            value: "1-3 MLN USD",
            isChecked: false,
          },
          {
            label: "3-7 MLN USD",
            value: "3-7 MLN USD",
            isChecked: false,
          },
          {
            label: "7-15 MLN USD",
            value: "7-15 MLN USD",
            isChecked: false,
          },
          {
            label: "15-... MLN USD",
            value: "15-... MLN USD",
            isChecked: false,
          },
        ]
      },
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 7,
      icon: handleFilterListIcon("StatusIcon"),
      text: "STATUS",
      link: "project_status",
      placeHolder: "status",
      show: false,
      isSubLabel: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 8,
      icon: handleFilterListIcon("Favorite"),
      text: "FAVOURITES",
      link: "favourites",
      placeHolder: "",
      isSubLabel: false,
      show: false,
      filterOption: {
        "favourites": [
          {
            label: "Show favourites in top list",
            value: "favourites",
            isChecked: false,
          },
        ],
      },
    },
  ],
  tenders: [
    {
      id: 0,
      icon: handleFilterListIcon("SavedSearch"),
      placeHolder: "",
      text: "SAVED SEARCH",
      link: "SAVED SEARCH",
      show: false,
      isSubLabel: false,
      filterOption: {},
      savedSearchList: []
    },
    {
      id: 1,
      icon: handleFilterListIcon("GroupCompanyIcon"),
      text: "GROUP COMPANY",
      link: "group_company",
      placeHolder: "Group company",
      show: false,
      filterOption: {},
      isSubLabel: false,
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 2,
      icon: handleFilterListIcon("ClientsIcon"),
      text: "CLIENTS",
      link: "client",
      placeHolder: "clients",
      show: false,
      filterOption: {},
      isSubLabel: false,
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 3,
      icon: handleFilterListIcon("CountryIcon"),
      text: "COUNTRY",
      link: "country",
      placeHolder: "country",
      show: false,
      isSubLabel: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 4,
      icon: handleFilterListIcon("ServicesIcon"),
      text: "SERVICES",
      link: "services",
      isSubLabel: false,
      placeHolder: "services",
      show: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 5,
      icon: handleFilterListIcon("SectorIcon"),
      text: "SECTOR",
      link: "sector",
      placeHolder: "sectors",
      show: false,
      isSubLabel: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 6,
      icon: handleFilterListIcon("ValueOfWorkIcon"),
      text: "VALUE OF WORK RANGE",
      link: "value_of_work",
      placeHolder: "value of work",
      show: false,
      isSubLabel: false,
      filterOption: {
        "value_of_work_range" : [
          {
            label: "0-1 MLN USD",
            value: "0-1 MLN USD",
            isChecked: false,
          },
          {
            label: "1-3 MLN USD",
            value: "1-3 MLN USD",
            isChecked: false,
          },
          {
            label: "3-7 MLN USD",
            value: "3-7 MLN USD",
            isChecked: false,
          },
          {
            label: "7-15 MLN USD",
            value: "7-15 MLN USD",
            isChecked: false,
          },
          {
            label: "15-... MLN USD",
            value: "15-... MLN USD",
            isChecked: false,
          },
        ]
      },
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 7,
      icon: handleFilterListIcon("StatusIcon"),
      text: "STATUS",
      link: "tender_status",
      placeHolder: "status",
      show: false,
      isSubLabel: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 8,
      icon: handleFilterListIcon("Favorite"),
      text: "FAVOURITES",
      link: "favourites",
      placeHolder: "",
      isSubLabel: false,
      show: false,
      filterOption: {
        "favourites": [
          {
            label: "Show favourites in top list",
            value: "favourites",
            isChecked: false,
          },
        ],
      },
    },
  ],
  suppliers: [
    {
      id: 0,
      icon: handleFilterListIcon("SavedSearch"),
      placeHolder: "",
      text: "SAVED SEARCH",
      link: "SAVED SEARCH",
      show: false,
      isSubLabel: false,
      filterOption: {},
      savedSearchList: []
    },
    {
      id: 1,
      icon: handleFilterListIcon("GroupCompanyIcon"),
      text: "GROUP COMPANY",
      link: "group_company",
      placeHolder: "Group company",
      show: false,
      filterOption: {},
      isSubLabel: false,
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 3,
      icon: handleFilterListIcon("CountryIcon"),
      text: "COUNTRY",
      link: "country",
      placeHolder: "country",
      show: false,
      isSubLabel: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 4,
      icon: handleFilterListIcon("ServicesIcon"),
      text: "SERVICES",
      link: "services",
      isSubLabel: false,
      placeHolder: "services",
      show: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 5,
      icon: handleFilterListIcon("SectorIcon"),
      text: "SECTOR",
      link: "sector",
      placeHolder: "sectors",
      show: false,
      isSubLabel: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 8,
      icon: handleFilterListIcon("Favorite"),
      text: "FAVOURITES",
      link: "favourites",
      placeHolder: "",
      isSubLabel: false,
      show: false,
      filterOption: {
        "favourites": [
          {
            label: "Show favourites in top list",
            value: "favourites",
            isChecked: false,
          },
        ],
      },
    },
  ],
  partners: [
    {
      id: 0,
      icon: handleFilterListIcon("SavedSearch"),
      placeHolder: "",
      text: "SAVED SEARCH",
      link: "SAVED SEARCH",
      show: false,
      isSubLabel: false,
      filterOption: {},
      savedSearchList: []
    },
    {
      id: 1,
      icon: handleFilterListIcon("GroupCompanyIcon"),
      text: "GROUP COMPANY",
      link: "group_company",
      placeHolder: "Group company",
      show: false,
      filterOption: {},
      isSubLabel: false,
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 3,
      icon: handleFilterListIcon("CountryIcon"),
      text: "COUNTRY",
      link: "country",
      placeHolder: "country",
      show: false,
      isSubLabel: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 4,
      icon: handleFilterListIcon("ServicesIcon"),
      text: "SERVICES",
      link: "services",
      isSubLabel: false,
      placeHolder: "services",
      show: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 5,
      icon: handleFilterListIcon("SectorIcon"),
      text: "SECTOR",
      link: "sector",
      placeHolder: "sectors",
      show: false,
      isSubLabel: false,
      filterOption: {},
      showSearchInput: true,
      showResetButton: true,
    },
    {
      id: 8,
      icon: handleFilterListIcon("Favorite"),
      text: "FAVOURITES",
      link: "favourites",
      placeHolder: "",
      isSubLabel: false,
      show: false,
      filterOption: {
        "favourites": [
          {
            label: "Show favourites in top list",
            value: "favourites",
            isChecked: false,
          },
        ],
      },
    },
  ]
}

export const filterRequestOptions: AccordianRequestItem[]= [
  {
    id: 0,
    label: "Modules",
    render: "module_name",
    filterOption: {
      module_name: [
        {
          label: "People",
          value: "People",
          isChecked: false,
        },
        {
          label: "Project",
          value: "Project",
          isChecked: false,
        },
        {
          label: "Tenders",
          value: "Tender",
          isChecked: false,
        },
        {
          label: "Suppliers",
          value: "Supplier",
          isChecked: false,
        },
        {
          label: "Partners",
          value: "Partner",
          isChecked: false,
        },
      ],
    },
  },
  {
    id: 1,
    label: "Status",
    render: "status",
    filterOption: {
      status: [
        {
          label: "Open",
          value: "open",
          isChecked: false,
        },
        {
          label: "Pending",
          value: "pending",
          isChecked: false,
        },
        {
          label: "Closed",
          value: "closed",
          isChecked: false,
        },
      ],
    },
  },
  {
    id: 3,
    label: "Actioned date",
    render: "actioned_date",
    type: "datePickerRange",
    dateRangeOptions: {
      actioned_date: [
        {
          label: "From",
          value: null,
        },
        {
          label: "To",
          value: null,
        },
      ],
    },
    filterOption:{}
  },
  {
    id: 4,
    label: "Requested date",
    render: "requested_date",
    type: "datePickerRange",
    dateRangeOptions: {
      requested_date: [
        {
          label: "From",
          value: null,
        },
        {
          label: "To",
          value: null,
        },
      ],
    },
    filterOption:{}
  },
];

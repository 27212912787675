import React, { createContext, useEffect, useState } from 'react';
import { baseURL } from '../../../blocks/bulkuploading/src/BulkUploadingController';
import { accordionDummyList } from '../WebAppLayout/config';
const BASEURL = require("../../../framework/src/config.js");

export interface User {
  defaultRole: string;
  firstTimeLogin: boolean;
  fullName: string;
  id: number | string;
  profilePhoto?: string;
  roles: any[]
}

export interface Permission {
  create: boolean;
  update: boolean;
  import: boolean;
  export: boolean;
  view: boolean;
  moduleName: string;
  role: string;
}

export interface AdvanceFilterState {
  advancedFilterOptions: any[],
  currentUser: User,
  permissions: Permission[]
}
export interface AdvanceFilterContextType {
  globalContext: AdvanceFilterState
  setGlobalContext: any
}

export const AdvancedFilterContext = createContext<AdvanceFilterContextType | {}>({});

const getRemoteUser = async () => {
  const token = await localStorage.getItem("authToken");
  if (token) {
    const headers = new Headers({
      'Token': token,
      'Content-Type': 'application/json'
    });
    return (await fetch(baseURL + "/account_block/accounts", { headers })).json()
  }

  throw new Error("Token not found!")
}

export const getPermission = async () => {
  const token = await localStorage.getItem("authToken");
  if (token) {
    const headers = new Headers({
      'Token': token,
      'Content-Type': 'application/json'
    });
    return (await fetch(baseURL + "/bx_block_roles_permissions/permissions", { headers })).json()
  }

  throw new Error("Token not found!")
}

export const getStoredDefaultRole = () => {
  try {
    const userRaw = localStorage.getItem("currentUser");
    if (userRaw) {
      const userData = JSON.parse(userRaw);

      return userData.defaultRole;
   } else {
    return null;
   }
  } catch(err) {
    return null
  }
  

}

export const AdvancedFilterContextProvider = ({ children }: any) => {
  const [globalContext, setGlobalContext] = useState<{
    advancedFilterOptions: any,
    currentUser: User | {},
    permissions: Permission | {}
  }>({
    advancedFilterOptions: [],
    currentUser: {},
    permissions: {}
  });

  const authUser = globalContext?.currentUser as User;

  useEffect(() => {
    getRemoteUser()
    .then((responseUser: any) => {
      const authUser = responseUser.data;
      const authUserAttributes = authUser.attributes.account;
      const storedRole = getStoredDefaultRole();

      const userInfo = {
        firstTimeLogin: authUserAttributes?.first_time_login,
        fullName: authUserAttributes?.full_name,
        id: authUser?.id,
        profilePhoto: authUserAttributes?.profile,
        roles: authUserAttributes.role
      };

      setGlobalContext((prev) => ({
        ...prev,
        currentUser: {
          ...userInfo,
          defaultRole: storedRole || (prev.currentUser as User)?.defaultRole || authUserAttributes.role[0],
        }
      }))

      return getPermission()
    })
    .then((responsePermission: any) => {
      const permissionData = responsePermission?.data || [];

      const mapPermisionList = permissionData?.map((permission: any) => {
        const attributes = permission.attributes
        return {
          create: attributes?.Create,
          update: attributes?.Update,
          view: attributes?.View,
          import: attributes?.Import,
          export: attributes?.Export,
          moduleName: attributes?.module_name,
          role: attributes.role

        } as Permission
      })
      .reduce((acc: { [key: string]: Permission[] }, current: Permission) => {

        const isExist = acc.hasOwnProperty(current.role);
        const key = current.role;
        const listPermission = Array.from(isExist ? acc[key]: []) as Permission[]
        
        if (isExist) {
          return {
            ...acc,
            [key]: [
              ...listPermission,
              current
            ]
          }
        } else {
          return {
            ...acc,
            [key]: [current]
          }
        }
      }, {})

      setGlobalContext((prev) => ({
        ...prev,
        permissions: mapPermisionList
      }))

    })
    .catch((err) => {
      console.log(err);
    })
  }, [authUser?.id])

  useEffect(() => {
    if (window.location.pathname.includes('/Modules')) {
      const fetchAndSetPermissions = async () => {
        try {
          const responsePermission = await getPermission();
          const permissionData = responsePermission?.data || [];
    
          const mapPermissionList = permissionData.map((permission: any) => {
            const attributes = permission.attributes;
            return {
              create: attributes?.Create,
              update: attributes?.Update,
              view: attributes?.View,
              import: attributes?.Import,
              export: attributes?.Export,
              moduleName: attributes?.module_name,
              role: attributes.role,
            } as Permission;
          }).reduce((acc: { [key: string]: Permission[] }, current: Permission) => {
            const key = current.role;
            const listPermission = acc[key] || [];
    
            return {
              ...acc,
              [key]: [...listPermission, current],
            };
          }, {});
    
          setGlobalContext((prev) => ({
            ...prev,
            permissions: mapPermissionList,
          }));
        } catch (error) {
          console.log(error);
        }
      };
    
      fetchAndSetPermissions();
    }
  
    return () => {
      // Cleanup logic here if needed
      console.log('Cleanup: This will run when the component unmounts or when the path changes.');
    };
  }, [window.location.pathname]);
  
  return (
    <AdvancedFilterContext.Provider value={{ globalContext, setGlobalContext }}>
      {children}
    </AdvancedFilterContext.Provider>
  );  
};

import React, { Component, FC } from "react";
import {
  Box as MuiBox,
  List,
  ListItem,
  ListItemText,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Props } from "react-select";
import CustomTypographyWeb from './CustomTypography.web';

const styles = {
  customBox: {
    // your custom box styles here
    padding: "0px 16px",
    background: '#FFF'
  },
  listItem: {
    borderRadius: "8px",
    "&.active": {
      background: "#C2E5EA",
    },
    "&.Mui-disabled": {
      opacity: 1,
    },
  },
};

interface CustomListProps extends WithStyles<typeof styles> {
  // testID: string;p
  sections: string[];
  activeSection: string;
  handleListItemClick: (section: string) => void;
  disabled?: boolean
}

class CustomList extends Component<CustomListProps> {
  render() {
    const {
      // testID,
      sections,
      activeSection,
      handleListItemClick,
      classes,
    } = this.props;

    return (
      <MuiBox className={classes.customBox}>
        <List component="nav">
          {sections.map((section: any, index: number) => (
            <ListItem
              button
              key={index}
              selected={activeSection === section}
              onClick={() => handleListItemClick(section)}
              className={
                activeSection === section
                  ? classes.listItem + " active"
                  : classes.listItem
              }
              disabled={this.props?.disabled}
            >
              <ListItemText
                disableTypography={this.props?.disabled}
                primary={<CustomTypographyWeb isSideBar isActiveSideBar={activeSection === section}>{section}</CustomTypographyWeb>}
              />
            </ListItem>
          ))}
        </List>
      </MuiBox>
    );
  }
}

export default withStyles(styles)(CustomList);



import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  Typography,
  Divider,
  InputLabel,
  Grid,
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import ImageComponent from "./imageComponent.web";
import ToggleEyeInput from "../../../components/src/ToggleEyeInput.web"
import { successIcon, srcTick, getFontColor } from "./assets";
// Customizable Area End

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController.web";
import { loginBackground } from "../../login-block/src/assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

interface WebStyle {
  [key: string]: {
    [key: string]: string | number | Record<string, string | number>;
  };
}

export class NewPassword1 extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  // Customizable Area Start

  renderValidationMessages = () => {
    const { loginValidation, newPassword } = this.state;
    const { classes } = this.props;

    const requirements = [
      {
        text: "At least one uppercase letter (A-Z)",
        validation: loginValidation.upperCase
      },
      {
        text: "At least one lowercase letter (a-z)",
        validation: loginValidation.lowerCase
      },
      {
        text: "At least one numeric number (0-9)",
        validation: loginValidation.numeric
      },
      {
        text: "At least one special character",
        validation: loginValidation.specialCharacter
      },
      {
        text: "Minimum character length is 8 characters",
        validation: loginValidation.minimumCharacter
      }
    ];

    return (
    <Box>
      <Grid
        container
        direction="column"
        spacing={1}
        style={{ marginBottom: "30px" }}
      >
        <Grid item direction="row" spacing={2}>
          {loginValidation.upperCase &&
            loginValidation.lowerCase &&
            loginValidation.numeric &&
            loginValidation.minimumCharacter && (
              <div
                style={{
                  color: "#0094A7",
                  fontFamily: "Source Sans Pro",
                  fontSize: "16px",
                  fontWeight: "lighter",
                  lineHeight: "24px",
                  letterSpacing: "0px",
                  textAlign: "left"
                }}
              >
                You are all set!
              </div>
            )}

          {!newPassword.length ? (
            <div
              style={{
                color: "#0094A7",
                fontFamily: "Source Sans Pro",
                fontSize: "16px",
                fontWeight: "lighter",
                lineHeight: "24px",
                letterSpacing: "0px",
                textAlign: "left"
              }}
            >
              Your password must contain...
            </div>
          ) : (
            <></>
          )}
        </Grid>

        {requirements.map((requirement, index) => {
          const src = srcTick(newPassword.length, requirement.validation)
          return (
            <Grid item key={index} 
              style={{ 
                display: "flex",
                flexDirection: "row",
                columnGap: "6px",
                flexWrap: "nowrap"
              }}
            >
              <img src={src} alt={`tick-${index*2}`} />
              <Typography
                style={{
                  color: getFontColor(newPassword.length, requirement.validation)
                }}
                className={classes.content}
              >
                {requirement.text}
              </Typography>
            </Grid>
          )
        })}
      </Grid>
    </Box>
    )
  }
  // Customizable Area End



  render() {
    // Customizable Area Start
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.mainBox}>
          <ImageComponent navigation={undefined} />
          {this.state.successPage === false ? (
            <Box className={classes.mainBox6}>
              <Box className={classes.mainBox7}>
                <Typography className="loginText">
                  New Password
                </Typography>
                <Box className={classes.mainBox9}>
                  <InputLabel>
                    Set the new password for your account so you can login and
                    access all features.
                  </InputLabel>
                </Box>
                <Divider className={classes.dividerRoot} />
                <Box className={classes.mainBox9}>
                  <InputLabel>Enter new password</InputLabel>
                </Box>
                <Box className={classes.mainBox11}>
                  <ToggleEyeInput
                    data-testId="handleNewPassword"
                    placeholder="Enter your password"
                    onChange={eventN => {
                      this.handleNewPassword(eventN.target.value, "newPassword");
                    }}
                    style={{ borderColor: this.newPasswordBorderStyle() }}
                    className={classes.input}
                    value={this.state.newPassword}
                    type="password"
                    disableUnderline
                  />
                  {!!this.renderForgotError("newPassword") && <span
                    style={{ color: "#EE2020"}}
                    className={classes.content}
                  >
                    {this.renderForgotError("newPassword")}
                  </span>}
                </Box>

                {this.renderValidationMessages()}

                <Box className={classes.mainBox9}>
                  <InputLabel>Confirm password</InputLabel>
                </Box>
                <Box className={classes.mainBox11}>
                  <ToggleEyeInput
                    data-testId="handleConformPassword"
                    placeholder="Enter your password"
                    value={this.state.confirmPassword}
                    onChange={eventC => {
                      this.handleConfirmPassword(eventC.target.value);
                    }}
                    style={{ borderColor : !!this.renderForgotError("confirmPassword") ? "#EE2020" : "#0094A7" }}
                    className={classes.input}
                    type="password"
                    disableUnderline
                  />
                   {!!this.renderForgotError("confirmPassword") && <span
                    style={{ color: "#EE2020"}}
                    className={classes.content}
                  >
                    {this.renderForgotError("confirmPassword")}
                  </span>}
                </Box>
                {!!this.renderForgotError("token") && 
                  <span
                    style={{ color: "#EE2020", position: "relative", top:"-5px", left: "2px"}}
                    className={classes.content}
                  >
                    {this.renderForgotError("token")}
                  </span>}
                <Box>
                  <Button
                    data-testId="goToConfirmationAfterPasswordChange"
                    onClick={value =>
                      this.goToConfirmationAfterPasswordChange(value)
                    }
                    style={{ ...webStyle.mainBox17, textTransform: "none" }}
                  >
                    Continue
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box className={classes.mainBox6} style={{height: "auto !important"}}>
              <Box className={classes.mainBox7} style={{ height: "min-content"}}>
                <Box style={{ display: "flex", justifyContent: "center" }}>
                  <img src={successIcon} />
                </Box>
                <InputLabel className={classes.mainBox15}>
                  Your password has been successfully reset
                </InputLabel>
                <Box className={classes.mainBox16}>
                  <Button
                    data-testId="handleContinueLoginPage"
                    onClick={() => this.handleContinueLoginPage()}
                    style={{ ...webStyle.mainBox17, textTransform: "none" }}
                  >
                    Continue
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

const webStyle: any = {
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${loginBackground})`,
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    fontFamily: "Source Sans Pro",
  },
  mainBox6: {
    display: "flex",
    backgroundColor: "white",
    width: "50vw",
    alignItems: "center",
    borderRadius: "50px 0 0 50px",
    justifyContent: "center",
    '@media (max-width: 600px)': {
      borderRadius: "50px 50px 0 0px",
      top: "120px",
      width: "100vw",
      position: "absolute",
    },
  },
  mainBox7: { 
    width: "65%",
    height: "min-content",
    margin: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    "& .loginText":{
      fontFamily: "Source Sans Pro",
      marginTop: "20px",
      fontWeight: 700,
      '@media (max-width: 1280px)': {
        lineHeight: "32px",
        fontSize: 32
      },
      marginBottom: "15px",
      textAlign: "center",
      lineHeight: "40px",
      fontSize: 40,
      '@media (max-width: 600px)': {
        lineHeight: "28px",
        fontWeight: 600,
        fontSize: 28
      },
    },
    '@media (max-width: 600px)': {
      paddingBottom: "25px",
      width: "75%",
      marginTop: "120px",
    },
    '@media (max-height: 640px)': {
      overflowY: "scroll", 
      overflowX: "hidden",
      height: "100%",
    },
  },
  mainBox9: {
    marginBottom: "8px",
    "& .MuiFormLabel-root": {
      color: "#9F9F9F",
      fontFamily: "Source Sans Pro",
      fontSize: 18
    }
  },
  mainBox11: { marginBottom: "25px" },
  mainBox14: { width: "20%", height: "20%", fill: "#0094A7" },
  mainBox15: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
    color: "#9F9F9F",
    fontFamily: "Source Sans Pro",
    fontSize: 18
  },
  mainBox16: { display: "flex", justifyContent: "center", marginTop: "60px" },
  mainBox17: {
    color: "white",
    width: "100%",
    backgroundColor: "#0094A7",
    height: "3rem",
    marginBottom: "20px",
    fontWeight: 600,
    borderRadius: 8,
    fontSize: 18,
    fontFamily: "Source Sans Pro"
  },
  input: {
    border: "1px solid #0094A7",
    borderRadius: 6,
    width: "100%",
    padding: "1.5vh 1.5vw",
    fontSize: 18,
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    color: "#494949",
    "& .MuiInputBase-input": {
      fontFamily: "Source Sans Pro",
      fontSize: 18
    }
  },
  dividerRoot: {
    backgroundColor: "E8E8E8",
    margin: "20px 0 30px"
  },
  content: {
    fontFamily: "Source Sans Pro",
    fontSize: "0.8rem",
    fontWeight: "400",
    lineHeight: "18px",
    letterSpacing: "0px",
    textAlign: "left"
  }
};

export default withStyles(webStyle)(NewPassword1);

import React, { Component } from "react";
import { Box, Typography, Divider, Grid } from "@material-ui/core";
import CustomInput from "../../../../../components/src/CustomInput.web";
import CustomButton from "../../../../../components/src/CustomButton.web";
import CustomSelectMenu from "../../../../../components/src/CustomSelectMenu.web";
import { IIdentification } from "../../PeopleModuleController.web";
import AutocompleteSelect from '../../../../../components/src/CustomFields/AutocompleteSelectMenu.web';
import CustomDatePicker from "../../../../../components/src/CustomFields/CustomDatePicker.web";

type Props = {
  isPersonalDataDone: boolean;
  identification: IIdentification;
  handleChangeValue: (val: string, key: string) => void;
  handlePostIdentificationData: () => void;
  handleUpdateIdentificationData: () => void;
  handleSwitchMode: (section: string, mode: "update" | "read" | "post", onCancel?:boolean) => void;
};

type State = {};


export default class UpdateIdentification extends Component<Props, State> {

  renderIdentificationError = (errorKey: string) => {
		const { errors } = this.props.identification;
		const errorP = errors.some((error) => error.hasOwnProperty(errorKey))
		const textP = errors.find((x) => x.hasOwnProperty(errorKey))?.[errorKey] ?? undefined
		return {error: errorP, text : textP }
	}

  render() {
    const maxDate = new Date(); // Today's date
    maxDate.setDate(maxDate.getDate()); // Set the maximum date to today
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 150);
    const {
      diploma,
      driving_license,
      end_date,
      joining_date,
      local_expiry_date,
      local_id,
      passport_expiry_date,
      passport_number,
      visa_designation,
      year,
      mode,
      yearOptions,
      isCancelButtonEnabled,
      diplomaList,
    } = this.props.identification;

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "52px",
        }}
      >
        {/* TOP PART */}
        <Box
          style={{
            marginBottom: "15px",
          }}
        >
          <Typography
            style={{
              color: "#1F1F1F",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 700,
              lineHeight: "16px",
              marginBottom: "32px",
            }}
          >
            Identification
          </Typography>

          {/* Visa designation */}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <CustomInput
                fullWidth
                variant="outlined"
                value={visa_designation}
                onChangeValue={(val) =>
                  this.props.handleChangeValue(val as string, "visa_designation")
                }
                data-testId="inputVisa"
                label="Visa designation"
                placeholder="Visa designation"
                type="text"
                marginBottom="32px"
                error={this.renderIdentificationError("visa_designation").error}
                helperText={this.renderIdentificationError("visa_designation").text}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
                <CustomDatePicker
									name="joining_date"
									dateFormat='DD/MM/YYYY'
									value={joining_date}
                  data-testId="dateJoining"
                  marginBottom="32px"
									onChange={(val) =>
										this.props.handleChangeValue(val, 'joining_date')
									}
									maxDate={new Date()}
                  minDate={new Date("01/01/1900")}
									label="Joining date"
									placeholder='dd/mm/yyyy'
									error={this.renderIdentificationError("joining_date").error}
									helperText={this.renderIdentificationError("joining_date").text}
								/>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
                <CustomDatePicker
									name="end_date"
									dateFormat='DD/MM/YYYY'
									value={end_date}
                  data-testId="dateEnd"
									onChange={(val) =>
										this.props.handleChangeValue(val, 'end_date')
									}
									minDate={new Date()}
									label="End date"
									placeholder='dd/mm/yyyy'
                  marginBottom="32px"
									error={this.renderIdentificationError("end_date").error}
									helperText={this.renderIdentificationError("end_date").text}
								/>
            </Grid>
          </Grid>

          {/* Diploma & Year */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <CustomSelectMenu
                value={diploma}
                onChangeValue={(e: any) =>
                  this.props.handleChangeValue(e, "diploma")
                }
                options={diplomaList}
                data-testId="selectDiploma"
                label="Diploma"
                placeholder="Diploma"
                error={this.renderIdentificationError("diploma").error}
                helperText={this.renderIdentificationError("diploma").text }
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2}>
              <Box>
                <Typography
                  style={{
                    fontSize: '16px',
                    fontWeight: 600,
                    fontStyle: 'normal',
                    lineHeight: '16px',
                    marginBottom: '7px',
                    fontFamily: 'Source Sans Pro',
                  }}>
                  Year
                </Typography>
                <AutocompleteSelect
                  value={year as string}
                  onChange={(e: any) =>
                    this.props.handleChangeValue(e, "year")
                  }
                  options={yearOptions}
                  data-testId="selectYear"
                  placeholder="Year"
                  error={this.renderIdentificationError("year").error}
                  helperText={this.renderIdentificationError("year").text}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Divider />

        <Box style={{ marginTop: "16px" }}>
          {/* Passport number */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <CustomInput
                fullWidth
                variant="outlined"
                value={passport_number}
                onChangeValue={(val) =>
                  this.props.handleChangeValue(val as string, "passport_number")
                }
                data-testId="inputPassport"
                label="Passport number"
                placeholder="Passport number"
                type="text"
                marginBottom="32px"
                error={this.renderIdentificationError("passport_number").error}
                helperText={this.renderIdentificationError("passport_number").text}
              />
            </Grid>
            {/* Passport Expiry */}
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <CustomDatePicker
                name="passport_expiry_date"
                dateFormat='DD/MM/YYYY'
                value={passport_expiry_date}
                data-testId="datePassportExpiry"
                onChange={(val) =>
                  this.props.handleChangeValue(val, 'passport_expiry_date')
                }
                minDate={new Date()}
                label="Expiry date"
                placeholder='dd/mm/yyyy'
                marginBottom="32px"
                error={this.renderIdentificationError("passport_expiry_date").error}
                helperText={this.renderIdentificationError("passport_expiry_date").text}
							/> 
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {/* Local ID Input */}
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <CustomInput
                fullWidth
                variant="outlined"
                value={local_id}
                onChangeValue={(val) =>
                  this.props.handleChangeValue(val as string, "local_id")
                }
                data-testId="inputLocalId"
                label="Local ID"
                placeholder="Local ID"
                type="text"
                marginBottom="32px"
                error={this.renderIdentificationError("local_id").error}
                helperText={this.renderIdentificationError("local_id").text}
              />
            </Grid>
            {/* Local ID - Expiry Date */}
            <Grid item xs={12} sm={6} md={6} lg={4}>
            <CustomDatePicker
                name="local_expiry_date"
                dateFormat='DD/MM/YYYY'
                value={local_expiry_date}
                data-testId="inputLocalExiryDate"
                onChange={(val) =>
                  this.props.handleChangeValue(val, 'local_expiry_date')
                }
                minDate={new Date()}
                label="Expiry date"
                marginBottom="32px"
                error={this.renderIdentificationError("local_expiry_date").error}
                helperText={this.renderIdentificationError("local_expiry_date").text}
                placeholder='dd/mm/yyyy'
							/> 
            </Grid>
          </Grid>
        </Box>

        <Divider />

        {/* Driving License*/}
        <Box style={{ marginTop: "16px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <CustomInput
                fullWidth
                variant="outlined"
                value={driving_license}
                onChangeValue={(e: any) =>
                  this.props.handleChangeValue(e, "driving_license")
                }
                data-testId="inputLisence"
                label="Driving license"
                placeholder="Driving license"
                type="text"
                error={this.renderIdentificationError("driving_license").error }
                helperText={this.renderIdentificationError("driving_license").text}
              />
            </Grid>
          </Grid>
        </Box>

        {/* BUTTONS */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              style={{
                gap: "20px",
                marginTop: "20px",
              }}
            >
              {isCancelButtonEnabled && (
                <CustomButton
                  onClick={() =>
                    this.props.handleSwitchMode("identification", "read", true)
                  }
                  disabled={!isCancelButtonEnabled}
                  data-testId="btnIdentificationCancel"
                  colorVariant="special"
                >
                  Cancel
                </CustomButton>
              )}
              <CustomButton
                colorVariant="default"
                disabled={!this.props.isPersonalDataDone}
                data-testId="btnIdentificationSave"
                onClick={
                  mode === "post"
                    ? this.props.handlePostIdentificationData
                    : this.props.handleUpdateIdentificationData
                }
              >
                Save
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

import React, { Component } from 'react';
import ReadPersonalData from './ReadPersonalData.web';
import UpdatePersonalData from './UpdatePersonalData.web';
import { IPersonalData, S } from '../../PeopleModuleController.web';

type Props = {
	personalData: IPersonalData;
	handleChangeValue: (val: string, key: string) => void;
	handlePostPersonalData: () => void;
	handleUpdatePersonalData: () => void;
	handlePeopleRequestNavigation?: () => void;
	handleSwitchMode: (section: string, mode: 'update' | 'read' | 'post', onCancel?:boolean) => void;
  onChange: (objectKey: keyof S, property: any, value: any | null) => void;
	nationalityArray: any;
};

type State = {};

export default class PersonalData extends Component<Props, State> {
	render() {
		const { mode } = this.props.personalData;

		if (mode === 'read') {
			return (
				<ReadPersonalData
					personalData={this.props.personalData}
					handleSwitchMode={this.props.handleSwitchMode}
          			onChange={this.props.onChange}
					handlePeopleRequestNavigation = {this.props.handlePeopleRequestNavigation}
				/>
			);
		} else {
			return (
				<UpdatePersonalData
					personalData={this.props.personalData}
					handleChangeValue={this.props.handleChangeValue}
					handleUpdatePersonalData={this.props.handleUpdatePersonalData}
					handlePostPersonalData={this.props.handlePostPersonalData}
					handleSwitchMode={this.props.handleSwitchMode}
					nationalityArray={this.props.nationalityArray}
				/>
			);
		}
	}
}

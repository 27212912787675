import React from "react";
// Customizable Area Start
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Typography,
  Divider,
  Hidden
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import {
  accordionCollapseIcon,
  collapseFilterIcon,
  expandFilterIcon,
  filterIcon,
  truncateText,
} from "./assets";
import GenericFilterInputs from "../../../components/src/GenericFilterInputs.web";
// Customizable Area End

import AllFilterController, { Props } from "./AllFilterController.web";
import CustomRadioButton from "../../../components/src/CustomRadioButton.web";
import SaveSearchAll from "../../savesearch/src/SaveSearchAll.web";
import { mappingFilterSubItemToLabel } from "../../../components/src/MappingTableHeaderLabels";
import Savesearch from "../../savesearch/src/Savesearch.web";
import { LightTooltip } from "../../../components/src/LightToolTip";
import { countCheckedTrue } from "../../../components/src/Utils";

export class AllFilter extends AllFilterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderSummary = (item: any, index: number) => {
    const { classes, open } = this.props;
    const { show, text, filterOption, icon, filterOptionForRadio } = item;

    const handleAccordionToggle = () => {
      this.toggleAcordion(index);
    };

    const totalOptions = Object.keys(filterOption).length > 0  ? filterOption : filterOptionForRadio

    return (
      <AccordionSummary
        data-testid="accordion-summary"
        onClick={handleAccordionToggle}
        style={{ height: "40px", minHeight: "40px" }}
        expandIcon={
          open && (
            <img
              style={{ transform: show ? "rotate(180deg)" : "rotate(270deg)" }}
              src={accordionCollapseIcon}
              alt="collapse"
            />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >  
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4px",
          fontWeight: 600,
          lineHeight: "16px",
          fontFamily: 'Source Sans Pro',
        }}
      >
        <LightTooltip title={text} placement="right">
          <img src={icon} alt={`${text} icon`} />
        </LightTooltip>
        
        {open && (
          <>
            <span
              style={{ paddingLeft: "7px", width: "max-content"}}
              data-testid="filter-title-text"
            >
              {text}
            </span>

            { countCheckedTrue(totalOptions) > 0 && (
              <span className={classes.filterCountBadge}>
                {countCheckedTrue(totalOptions)}
              </span>
            )
            }
          </>
        )}
      </div>
      </AccordionSummary>
    );
  };

  renderReset = (item: any, i: number) => {
    const { classes } = this.props;
    const searchWord = this.state.inputSearchFields[item.text]?.toLowerCase()
    const isDisabled = !!searchWord || countCheckedTrue(item.filterOption) > 0 || countCheckedTrue(item.filterOptionForRadio) > 0; 
    return (
      item.showResetButton && (
        <Button
          variant="outlined"
          data-testid="reset-filter"
          color="primary"
          className={classes.resetbutton}
          onClick={() => this.onResetButtonClick(item.text, item, i)}
          disabled={!isDisabled}
        >
          Reset
        </Button>
      )
    );
  };

  renderSearchInput = (item: any, i: number) => {
    const { classes } = this.props;
    const { inputSearchFields } = this.state;

    return (
      item.showSearchInput && (
        <input
          data-testid="search-input"
          className={classes.searchInputFilter}
          placeholder={`Search ${item.placeHolder}`}
          value={inputSearchFields[item.text]}
          onChange={(e) => {
            this.setSearchInput(e.target.value, item);
          }}
        />
      )
    );
  };

  renderRadioGroups = (item: any, i: number) => {
    const { classes } = this.props;
    const { selectedGroupRelation } = this.state;
    const { filterOptionForRadio, radioOption } = item;
    return (
      <>
        {filterOptionForRadio && (
          <>
            {radioOption.length > 0 && (
              <>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    data-testid="radioGroup"
                    className={classes.radioContainer}
                    onChange={this.handleRadioChange.bind(this)}
                  >
                    {radioOption.map((radioItem: any) => (
                      <FormControlLabel
                        key={radioItem.label}
                        data-testid="radioLabel"
                        value={radioItem.value}
                        control={<Radio className={classes.radioBtn} />}
                        label={radioItem.label}
                        checked={radioItem.value === selectedGroupRelation}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <hr />
              </>
            )}

            {Object.keys(filterOptionForRadio[selectedGroupRelation] || {}).map(
              (subItem, index) => (
                <React.Fragment key={subItem}>
                  {subItem !== "" ? (
                    <Accordion
                      className={classes.radioAccordionContainer}
                      expanded={
                        filterOptionForRadio[selectedGroupRelation][subItem]
                          .show
                      }
                    >
                      <AccordionSummary
                        onClick={() =>
                          this.toggleInnerRadioAcordion(
                            i,
                            selectedGroupRelation,
                            subItem
                          )
                        }
                        expandIcon={
                          <img
                            style={{
                              transform: filterOptionForRadio[
                                selectedGroupRelation
                              ][subItem].show
                                ? "rotate(180deg)"
                                : "rotate(270deg)",
                            }}
                            src={accordionCollapseIcon}
                            alt="expand"
                          />
                        }
                        style={{ alignItems: "center" }}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            fontWeight: 600,
                            lineHeight: "16px",
                          }}
                        >
                          <Typography className={classes.heading}>
                            {subItem}
                          </Typography>
                          { countCheckedTrue(filterOptionForRadio[selectedGroupRelation][subItem]) > 0 && (
                            <span className={classes.filterCountBadge} style={{ marginLeft: "2px"}}>
                              {countCheckedTrue(filterOptionForRadio[selectedGroupRelation][subItem])}
                            </span>)
                          }
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <GenericFilterInputs
                            key={`${index * 2 + 3}`}
                            id=""
                            checkBoxList={
                              filterOptionForRadio[selectedGroupRelation][
                                subItem
                              ].optionsList || []
                            }
                            options={item}
                            radioOptionList={[]}
                            onCheckBoxListChange={(checkedList) =>
                              this.handleCheckBoxListChange(
                                checkedList,
                                subItem,
                                item,
                                selectedGroupRelation
                              )
                            }
                          />
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <GenericFilterInputs
                        key={`${index * 2 + 3}`}
                        id=""
                        checkBoxList={
                          filterOptionForRadio[selectedGroupRelation][subItem]
                            .optionsList || []
                        }
                        options={item}
                        radioOptionList={[]}
                        onCheckBoxListChange={(checkedList) =>
                          this.handleCheckBoxListChange(
                            checkedList,
                            subItem,
                            item,
                            selectedGroupRelation
                          )
                        }
                      />
                    </div>
                  )}
                </React.Fragment>
              )
            )}
          </>
        )}
      </>
    );
  };

  renderSaveSearchList = (item: any, i: number) => {
    const { classes, module} = this.props;
    const { savedSearchList } = item;
    const  { selectedSaveSearchId, openAll, isEditSaveArrowOpen, editSaveSearchId, isEditSuccess} = this.state
    const isList = !!savedSearchList && savedSearchList.length > 0
    return (
      <>
      {isList && (
        <React.Fragment>
          <div style={{ marginTop: "4px"}} data-testid="saveSearchList">
            {savedSearchList.slice(0, 5).map((obj: any, index: number) => {
              return (
                <div
                  key={`savedSearchItem-${index*2+1}`}
                  data-test-id={`savedSearchItem-${index*2+1}`}
                  style={{
                    marginBottom: "8px",
                    padding: "0 1px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      key={`saveSearchRadio-${index*2+1}`}
                      control={
                        <CustomRadioButton
                          data-testid="saveSearchRadioControl"
                          checked={selectedSaveSearchId === obj.id}
                        />
                      }
                      value={obj.search_name}
                      label={
                        <span
                          data-testid={`saveSearchRadioLabel`}
                          className={classes.savedSearchLabel}
                        >
                          {truncateText(obj.search_name, 32)}
                        </span>
                      }
                      className={classes.label}
                      data-testid={`saveSearchRadio`}
                      onClick={() => this.handleSelectSearch(obj)}
                    />
                    <div
                      data-testid="saveSearchRightArrow"
                      className={classes.expandSavedSearchIcon}
                      onClick={() => this.handleSelectSearchArrow(true,obj)}
                    >
                      <img src={expandFilterIcon} style={{ cursor: "pointer" }} alt="arrow"/>
                    </div>
                  </div>
                  {isEditSaveArrowOpen && editSaveSearchId === obj.id && <Savesearch data-testid="editSaveSearchPopup" module={this.props.module.toLowerCase()} isFilterOpen={true} open={isEditSaveArrowOpen && editSaveSearchId === obj.id} savedObj={obj} handleOpenEditPopup={this.handleSelectSearchArrow} applyEditSaveSearch={this.applyEditSaveSearch}/>}
                  <Divider />
                </div>
              );
            })}
          </div>
            <Button
              data-testid="SeeAllSavedSearchButton"
              variant="outlined"
              color="primary"
              className={classes.resetbutton}
              style={{ marginTop: "14px"}}
              onClick={() => this.handleSeeAllSaveSearch(true)}
            >
              See all
            </Button>
            <SaveSearchAll 
              data-testid="saveSearchAllPopup"
              module={module} 
              open={openAll} 
              isSuccessUpdate={isEditSuccess}
              savedSearchList={savedSearchList} 
              selectedSaveSearchId={selectedSaveSearchId}
              submitSaveSearchList={this.handleSelectSearch}
              handleSaveSearchAPI={this.handleSaveSearchAPI}
              handleSeeAllSaveSearch={this.handleSeeAllSaveSearch}
            />
        </React.Fragment>
      ) }
      {savedSearchList && savedSearchList.length == 0 && <div className={classes.noRecordFound}> No record found </div>}
      </>
    );
  };

  renderDetails = (item: any, i: number) => {
    const { classes } = this.props;
    const { showSearchInput, showResetButton } = item;
    return (
      <AccordionDetails style={{ padding: "0px 16px 16px" }}>
        <div className={classes.scrollBar}>
          {(showSearchInput || showResetButton) && !item.isFavourite && (
            <div style={{ margin: "5px 0 16px" }}>
              {this.renderSearchInput(item, i)}
              {this.renderReset(item, i)}
            </div>
          )}
          {this.renderRadioGroups(item, i)}
          {this.renderSaveSearchList(item, i)}
          {Object.keys(item.filterOption || {}).map((subItem, index) => {
            const searchWord =
              this.state.inputSearchFields[item.text]?.toLowerCase() || "";              
            const checkBoxList = item.filterOption[subItem]
            const isLabel = item.isSubLabel

            return (
              <div key={`filter-${i}-${subItem}-${index * 2}`}>
                <span
                  style={{
                    fontWeight: 700,
                    fontSize: "14px !important",
                  }}
                >
                  {isLabel ? mappingFilterSubItemToLabel(subItem) : ""}
                </span>
                {checkBoxList.length === 0 && (
                  <div className={classes.noRecordFound}>No record found</div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "5px 0 7px",
                  }}
                >
                  <GenericFilterInputs
                    data-testid="optionList"
                    key={`field-${i}-${subItem}-${index * 2}`}
                    id=""
                    checkBoxList={checkBoxList}
                    options={item}
                    searchWord={searchWord}
                    radioOptionList={[]}
                    onCheckBoxListChange={(checkedList: string[]) =>
                      this.handleCheckBoxListChange(checkedList, subItem, item)
                    }
                  />
                </div>
              </div>
            );
          })}
          {Object.keys(item.filterOption || {}).length === 0 && !item.savedSearchList && !item.filterOptionForRadio &&(
            <div className={classes.noRecordFound}>No record found</div>
          )}
          {item.isFavourite && showResetButton && (
            <div style={{ marginBottom: "17px" }}>
              {this.renderReset(item, i)}
            </div>
          )}
        </div>
      </AccordionDetails>
    );
  };

  renderAccordion = (item: any, index: number) => {
    const { open, classes } = this.props;
    return (
      <Accordion
        data-testid="main-accordion"
        onClick={this.handleClickIconTool}
        style={{ backgroundColor: open ? "#F3F3F3" : "transparent" }}
        className={classes.accordionContainer}
        key={`accordion-${index}`}
        expanded={item.show}
      >
        {this.renderSummary(item, index)}
        {this.renderDetails(item, index)}
      </Accordion>
    );
  };

  renderResetButton = () => {
    const { open, classes } = this.props;
    const isEnabled = countCheckedTrue(this.state.accordionList) > 0 || this.state.selectedSaveSearchId !== -1;
    if (!open) return null;

    return (
      <div
        className={classes.filterResetBox}
      >
        <Button
          variant="outlined"
          data-testid="reset-all-filters"
          className={classes.secondaryButtonStyle}
          onClick={this.clearAllFilters}
          disabled={!isEnabled}
        >
          Reset All
        </Button>
      </div>
    );
  };
  // Customizable Area End

  render() {
    const { accordionList } = this.state;
    const { classes, open } = this.props;
    // Customizable Area Start
    return (
      <div className={open ? classes.sidenav : classes.sidenavClosed}>
        <div>
          <Accordion className={classes.headerAccordionContainer}>
            <AccordionSummary
              style={{ pointerEvents: "none" }}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <img src={filterIcon} alt="filter" />
              {open ? (
                <span className={classes.filterHeader}>Filters</span>
              ) : null}
            </AccordionSummary>
          </Accordion>
        </div>
        <div className={classes.filterHR}>
          <hr color="#9F9F9F" style={{ backgroundColor: "#C3C3C3" }} />
        </div>
        <Hidden smDown>
          <button
            data-testid="expand-filters"
            className={!open ? classes.menuBtn : classes.menuBtnExpand}
            onClick={this.allToggleOpen}
          >
            <img
              src={!open ? expandFilterIcon : collapseFilterIcon}
              alt="expand"
            />
          </button>
        </Hidden>
    
        <div style={{ display: "flex", flexDirection: "column" }}>
          {accordionList.length > 0 && accordionList.map(this.renderAccordion)}
        </div>
        {this.renderResetButton()}
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const styles: any = {
  sidenav: {
    overflowY: 'scroll',
    width: "100%",
    transition: "width 0.3s ease-in-out",
    backgroundColor: "white",
    overflowX: 'hidden',
    height: "calc(100vh - 65px)",
    scrollbarColor: "transparent transparent",
    scrollbarWidth: "thin",
    ".MuiPaper-root [aria-expanded='false']": {
      margin: "10px",
    },
    "& .MuiPaper-root": {
      backgroundColor: "red",
      margin: "8px",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "@media (max-width: 960px)": {
      height: "100vh",
      overflow: "scroll"
    },
  },
  sidenavClosed: {
    height: "calc(100vh - 65px)",
    composes: "sidenav",
    overflowX: 'hidden',
    transition: "width 0.3s ease-in-out",
    backgroundColor: "white",
    overflowY: 'scroll',
    scrollbarColor: "transparent transparent",
    scrollbarWidth: "thin",
    width: "60px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  sideitem: {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
    cursor: "pointer",
    color: "#b2bac2",
    textDecoration: "none",
    overflow: "hidden",
  },
  linkText: {
    paddingLeft: "16px",
  },

  "& .sideitem:hover": {
    backgroundColor: "#244f7d1c",
  },
  menuBtn: {
    alignSelf: "center",
    justifySelf: "flex-end",
    color: "#b2bac2",
    cursor: "pointer",
    position: "absolute",
    top: "15.7rem",
    left: "2.9rem",
    transition: "width 0.3s ease-in-out",
    zIndex: 999,
    background: "white",
    border: "1px solid #E8E8E8",
    borderRadius: "2px",
    padding: "10px",
    boxShadow: "-2px 4px 16px 0px #00000021",
    "@media (max-width: 960px)": {
      display : "none"
    },
  },
  menuBtnExpand: {
    alignSelf: "center",
    justifySelf: "flex-end",
    color: "#b2bac2",
    cursor: "pointer",
    zIndex: 100,
    position: "absolute",
    top: "13rem",
    right: "-5px",
    transition: "width 0.3s ease-in-out",
    background: "white",
    border: "1px solid #E8E8E8",
    borderRadius: "2px",
    padding: "10px",
    boxShadow: "-2px 4px 16px 0px #00000021",
    "@media (max-width: 960px)": {
      display : "none"
    },
  },
  headerAccordionContainer: {
    margin: "10px",
    boxShadow: "none",
    backgroundColor: "white !important",
    "&::before": {
      display: "none",
    },
    "&.Mui-expanded": {
      margin: "10px",
    },
  },
  accordionContainer: {
    fontSize: "14px",
    margin: "10px",
    boxShadow: "none",
    "&::before": {
      display: "none",
    },
    "&.Mui-expanded:first-child": {
      marginTop: "8px !important",
    },
  },
  filterHeader: {
    letterSpacing: "0em",
    fontWeight: 700,
    fontSize: "18px",
    marginLeft: "10px",
    fontFamily: "Source Sans Pro",
  },
  buttonFontStyle: {
    fontFamily: "",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
  },
  primaryButtonStyle: {
    backgroundColor: "#0094A7",
    color: "#FFF",
    borderRadius: "8px",
    textTransform: "none",
    padding: "10px",
  },
  secondaryButtonStyle: {
    width: "100%",
    color: "#0094A7",
    backgroundColor: "#FFF",
    borderRadius: "8px",
    fontSize: "14px",
    padding: "10px",
    fontWeight: 600,
    border: "1px solid #0094A7",
    textTransform: "none",
  },
  filterCountBadge: {
    backgroundColor: "#3695A7",
    borderRadius: "35px",
    padding: "0px 8px",
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "white",
  },
  radioContainer: {
    display: "flex",
    flexDirection: "row !important",
    flexWrap: "nowrap !important",
  },
  radioBtn: {
    color: "#3695A7 !important",
  },
  radioAccordionContainer: {
    margin: "10px",
    borderRadius: "4px",
    backgroundColor: "white !important",
    boxShadow: "none",
    border: "0.4px solid #9F9F9F",
    "&::before": {
      display: "none",
    },
    "&.Mui-expanded": {
      margin: "10px",
    },
  },
  filterHR: {
    padding: "0px 16px",
  },
  resetbutton: {
    width: "100%",
    marginTop: "10px",
    borderColor: " #0094A7",
    height: "32px",
    color: "#0094A7",
    textTransform: "capitalize",
  },
  searchInputFilter: {
    width: "100%",
    minHeight: "32px",
    borderRadius: " 4px",
    border: "1px solid #9F9F9F",
    background: "#fff",
    padding: "8px 11px",
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
    "&:focus": {
      outline: "none",
    },
  },
  scrollBar: {
    maxHeight: "400px",
    overflowY: "auto",
    scrollbarWidth: "thin",
    scrollbarColor: "transparent transparent",
    overflowX: "hidden",
    width: "100%",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  noRecordFound: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#9F9F9F",
    margin: "10px 0",
    fontFamily: "Source Sans Pro , sans-serif",
    display: "flex",
    justifyContent: "center",
  },
  label: {
    fontSize: "14.5px !important",
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    lineHeight: "32px",
    "& span":{
      lineHeight: "32px",
      fontSize: "14.5px !important",
      fontWeight: 600,
    }
  },
  expandSavedSearchIcon: {
    marginRight: "-6px",
    height: '24px',
    display: 'flex',
    cursor: 'pointer',
    width: '24px', 
    justifyContent: 'center',
    overflow: 'hidden',
    alignItems: 'center',
  },

  filterResetBox:{
    textAlign: "center",
    marginTop: "2.3rem",
    padding: "8px",
    marginBottom: "1rem",
    "@media (max-width: 960px)": {
      marginBottom: "6rem",
    },
  }
};

export default withStyles(styles)(AllFilter);

// Customizable Area End

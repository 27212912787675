import * as Yup from "yup";
import moment from "moment";

const generalLicensePattern = /^[A-Za-z0-9\-][A-Za-z0-9\- ]*$/;
const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/

const emptyStringToNull = (
  value: string | null,
  originalValue: string | null
) => (originalValue === null || originalValue.trim() == "" ? null : value);

const isAfter1900 = (value: string) =>
  moment(value, "DD/MM/YYYY").isAfter(moment("01/01/1900"), "day");

const isTodayOrPastDate = (value: string) =>
  moment(value, "DD/MM/YYYY").isBefore(moment().add(1, "day"), "day");

const isTodayOrFutureDate = (value: string) =>
  moment(value, "DD/MM/YYYY").isSame(moment(), "day") || moment(value,  "DD/MM/YYYY").isAfter(moment(), "day");

export const loginValidationSchema = Yup.object().shape({
  failed_login: Yup.string().trim().required("Email is required").email("Please enter valid email")
  .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, 'Please enter valid email'),
  invalid_password: Yup.string().required("Password is required"),
})

export const forgotPassValidationSchema = Yup.object().shape({
  otp: Yup.string().trim().required("Email is required").email("Please enter valid email")
  .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, 'Please enter valid email!'),
})

export const otpPassValidationSchema = Yup.object().shape({
  otp: Yup.string().trim().required("OTP code is required")
})

export const passValidationSchema = Yup.object().shape({
  password: Yup.string().trim().required("Password is required")
})


export const newPassValidationSchema = Yup.object().shape({
  newPassword: Yup.string().trim().required("New password is required")
  .matches(passwordRegex, 'Please enter valid password!'),
  confirmPassword: Yup.string().trim().required("Confirm password is required")
  .test("match", "Confirm password should match with New password", function(value){
    return value && this.parent.newPassword && this.parent.newPassword === value
  }),
})

export const peoplePersonalValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  date_of_birth: Yup.string()
    .required("Date of birth is required")
    .test("is-date", "Date of birth must be a valid date", (value) =>
      moment(value, "DD/MM/YYYY").isValid()
    )
    .test(
      "is-not-future-date",
      "Date of birth can't be in the future",
      isTodayOrPastDate
    )
    .test("is-after-1900", "Year should start from 1900.", isAfter1900),
  country_code: Yup.string().required("Area code is required"),
  nationality: Yup.string().required("Nationality is required"),
  email: Yup.string()
    .email("Enter a valid email!")
    .max(100, "Email should not be more than 100 characters long")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, 'Enter a valid email!')
    .required("Email is required"),
  phone_number: Yup.string()
    .required("Phone number is required")
    .matches(
      /^\d{7,15}$/,
      "Only numeric values allowed and phone number must contain 7 to 15 digits."
    ),
});

export const peopleIdentificationValidationSchema = Yup.object().shape({
  visa_designation: Yup.string()
    .transform(emptyStringToNull)
    .nullable(),
  joining_date: Yup.string()
    .transform(emptyStringToNull)
    .nullable()
    .test("is-after-1900", "Year should start from 1900.", function(value) {
      if (!this.parent.joining_date) return true;
      return isAfter1900(value);
    })
    .test(
      "is-today-or-past-date",
      "You can select today or a past date.",
      function(value) {
        if (!this.parent.joining_date) return true;
        return isTodayOrPastDate(value);
      }
    ),
  end_date: Yup.string()
    .transform(emptyStringToNull)
    .nullable()
    .test("is-after-1900", "Year should start from 1900.", function(value) {
      if (!this.parent.end_date) return true;
      return isAfter1900(value);
    })
    .test(
      "is-today-or-future-date",
      "You can select today or a future date.",
      function(value) {
        if (!this.parent.end_date) return true;
        return isTodayOrFutureDate(value);
      }
    ),
  diploma: Yup.string().required("Diploma is required"),
  year: Yup.string().required("Year is required"),
  passport_number: Yup.string()
    .transform(emptyStringToNull)
    .nullable()
    .test("alphanumeric", "Passport number must be alphanumeric", function(
      value
    ) {
      if (!this.parent.passport_number) return true;
      return /^[a-zA-Z0-9]*$/.test(value || "");
    })
    .test("length", "Should be 6 to 15 characters in length", function(value) {
      if (!this.parent.passport_number) return true;
      return value ? value.length >= 6 && value.length <= 15 : false;
    }),
  passport_expiry_date: Yup.string()
    .transform(emptyStringToNull)
    .nullable()
    .test("is-after-1900", "Year should start from 1900.", function(value) {
      if (!this.parent.passport_expiry_date) return true;
      return isAfter1900(value);
    })
    .test(
      "is-today-or-future-date",
      "You can select today or a future date.",
      function(value) {
        if (!this.parent.passport_expiry_date) return true;
        return isTodayOrFutureDate(value);
      }
    ),
  local_id: Yup.string()
    .transform(emptyStringToNull)
    .nullable(),
  local_expiry_date: Yup.string()
    .transform(emptyStringToNull)
    .nullable()
    .test("is-after-1900", "Year should start from 1900.", function(value) {
      if (!this.parent.local_expiry_date) return true;
      return isAfter1900(value);
    })
    .test(
      "is-today-or-future-date",
      "You can select today or a future date.",
      function(value) {
        if (!this.parent.local_expiry_date) return true;
        return isTodayOrFutureDate(value);
      }
    ),
  driving_license: Yup.string()
    .transform(emptyStringToNull)
    .test(
      "starts-with-alphanumeric",
      "should start with alphanumeric characters",
      function(value) {
        if (!this.parent.driving_license) return true;
        return /^[a-zA-Z0-9]/.test(value);
      }
    )
    .nullable()
    .matches(
      generalLicensePattern,
      "Should be alphanumeric with hyphens and spaces"
    ),
});

export const peopleWorkValidationSchema = Yup.object().shape({
  education_degree: Yup.string().required("Highest level is required"),
  education_stream: Yup.string().required("Discipline is required"),
  primary_experience: Yup.string().required("Primary experience is required"),
  primary_year: Yup.string().required("Primary year is required"),
  secondary_experience: Yup.string().required("Secondary experience is required"),
  secondary_year: Yup.string().required("Secondary year is required"),
  region_of_experience: Yup.string().required("Country is required"),
  design: Yup.string().oneOf(['Yes', 'No']).required("Design is required"),
  supervision: Yup.string().oneOf(['Yes', 'No']).required("Supervision is required"),
  current_position: Yup.string().required("Current position is required"),
  languages: Yup.array().of(Yup.string()).min(1, "At least one language should be present"),
  group_relation: Yup.array().of(Yup.string()).min(1, "Please add the group relation and employee status"),
  internal_rate: Yup.mixed().required("Internal rate is required")
  .test('is-valid-internal-rate', 'Internal rate is required', function(value) {
    if (typeof value === 'string') {
      return value.trim() !== '';
    } else if (typeof value === 'object' && value !== null) {
      return value.value && value.value.trim() !== '';
    }
    return false;
  }),
  currentOtherPosition: Yup.string()
  .test("otherPosition", "Others current position is required", function(
    value
  ) {
    if (this.parent.current_position === "Others" && value === "") {
      return false
    } else {
      return true;
    }
  }),
});


export const projectDetailsValidationSchema = Yup.object().shape({
  project_name: Yup.string().trim().required("Project name is required"),
  joint_venture_share: Yup.number().transform((value, originalValue) => {
    return originalValue === "" ? null : value;
  }).nullable().notRequired()
  .test(
    "range",
    "Please type only numbers between 0.01 - 100.00.", function(value) {
      if(!this.parent.joint_venture_share) return true
      return value >= 0.01 && value <= 100.00;
    }
  ),
  start_date: Yup.string()
    .transform(emptyStringToNull)
    .nullable()
    .test("is-after-1900", "Year should start from 1900.", function(value) {
      if (!this.parent.start_date) return true;
      return isAfter1900(value);
    }),
  end_date: Yup.string()
    .transform(emptyStringToNull)
    .nullable()
    .test("is-after-1900", "Year should start from 1900.", function(value) {
      if (!this.parent.end_date) return true;
      return isAfter1900(value);
    })
})
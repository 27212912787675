import React from "react";
import { Box, ClickAwayListener, Paper, Popper, Typography, styled } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TruncatedTextWithTooltipWeb from "./TruncatedTextWithTooltip.web";

interface Options {
    label: string;
    value: string;
    values?: { [key: string]: number | string };
}

interface AuditTrailFilterDropdownProps {
    name: string;
    filterOptions: Array<Options>,
    linkList: {name: string, link: string}[],
    value: string,
    open: boolean,
    onOpen: (value: boolean) => void,
    onChange: (value: string) => void,
    position?: "left" | "right",
}

interface S {
    isShowPdf: boolean
}

class AuditTrailFilterDropdown extends React.Component<AuditTrailFilterDropdownProps, S> {

    constructor(props: AuditTrailFilterDropdownProps) {
        super(props);
        this.state = {
            isShowPdf: false,
        };
    }

    handleShowPdf = (value: boolean) => {
        this.setState({ isShowPdf: value });
    }

    renderLinkDialog = () => {
        const { position, linkList } = this.props;
        const { isShowPdf } = this.state;
        const positionKey = position || "right"
        return (
            <Popper
                open={isShowPdf}
                data-test-id="linkPopper"
                placement="bottom-start"
                className="linkPopper"
                style={{
                    zIndex: 1000000, position: "absolute",
                    top:  window.innerWidth < 760 ? '118px' : '79px',
                    left: positionKey === 'right' ? window.innerWidth < 760 ? "calc(-100% - 14px)" : "calc(100% + 4px)" : "unset",
                    right: positionKey === 'left' ? window.innerWidth < 760 ? "unset" : "calc(100% + 4px)" : 'unset'
                }}
                // @ts-ignore
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transition
                disablePortal
            >
                <ClickAwayListener touchEvent={false} onClickAway={() => this.handleShowPdf(false)}>
                    <Paper className="linkPaper">
                        <LinkContainer>
                            {  linkList.length > 0 ? 
                                linkList.map((item: any) => {
                                    return (
                                        <Typography key={item.name} href={item.link} target="_blank" className="link" component="a">
                                            <TruncatedTextWithTooltipWeb text={item.name} maxWidth="180px" />
                                        </Typography>
                                    )
                                })

                                : 
                                <span className="link">
                                    <p style={{ color: "grey", margin: '0'}}>No PDF found</p> 
                                </span>
                            }
                        </LinkContainer>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        )
    }

    render() {
        const { filterOptions, value, open, name } = this.props;
        const title = filterOptions.find((option) => option.value === value)?.label
        return (
            <CustomeDropDown>
                <Box className="mainButton" data-test-id="auditDropdown" onClick={() => this.props.onOpen(true)}>
                    <Box className="title" data-test-id="auditDropdownTitle">{title}</Box>
                    <ExpandMoreIcon style={{ color: "#0094A7", transform: open ? "rotate(180deg)" : "rotate(0deg)" }} />
                </Box>
                <Popper
                    open={open}
                    placement="bottom-start"
                    data-test-id="auditPopper"
                    className="customPopper"
                    style={{ zIndex: 1000000, position: "absolute", top: "calc(100% + 4px)" }}
                    // @ts-ignore
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transition
                    disablePortal
                >
                    <ClickAwayListener touchEvent={false} onClickAway={() => {this.props.onOpen(false); this.handleShowPdf(false)}}>
                        <Paper className="customPaper">
                            {filterOptions.map(option => {
                                return (
                                    <Box
                                        data-test-id="filterOptions"
                                        key={`${option.label}-quarter`}
                                        className="list"
                                        onClick={() => this.props.onChange(option.value)}
                                        style={{ backgroundColor: value === option.value ? "#C2E5EA" : "white" }}
                                    >
                                        {option.label}
                                    </Box>
                                )
                            })}
                            <Box style={{paddingTop: "5px"}} className="list" data-test-id="quarterCustomOption" onClick={() => this.handleShowPdf(true)}>
                                <Box className="title">Show PDF</Box>
                                <ExpandMoreIcon style={{ color: "#0094A7", transform: this.state.isShowPdf ? "rotate(180deg)" : "rotate(0deg)" }} />
                            </Box>
                            {this.renderLinkDialog()}
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </CustomeDropDown>
        )
    }
}

export const LinkContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "10px",
    "& .link": {
        color: "#0094A7",
        cursor: "pointer",
        "& p": {
            fontFamily: "Source Sans Pro",
            color: "#0094A7",
            fontSize: "15px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "16px",
        }
    },
});

const CustomeDropDown = styled(Box)({
    position: "relative",
    width: "155px",
    height: "34px",
    "& .mainButton": {
        height: "100%",
        cursor: "pointer",
        position: "relative",
        padding: "0px 10px 0px 15px",
        borderRadius: "4px",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        border: "1px solid #0094A7",
        justifyContent: "space-between",
        gap: "3px",
        "& .title": {
            color: '#0094A7',
            fontSize: "14px",
            fontFamily: 'Source Sans Pro',
            fontWeight: '600',
            lineHeight: "16px",
            wordWrap: 'break-word'
        }
    },
    "& .customPopper": {
        width: "100%",
        borderRadius: "4px",
        border: "1px solid #E0E0E0",
        "& .customPaper": {
            padding: "5px 0 0",
            background: "#FFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            "& .list": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 14px 10px 15px",
                color: "#000",
                fontSize: "16px",
                fontStyle: "normal",
                fontFamily: "Source Sans Pro",
                fontWeight: 600,
                lineHeight: "16px",
                cursor: "pointer",
                "&:hover": {
                    background: "#EEF1F1"
                },
            },
            "& .linkPopper": {
                border: "1px solid #E0E0E0",
                borderRadius: "4px",
                "& .linkPaper": {
                    padding: "10px",
                    background: "#FFF",
                    width: "max-content",
                    minWidth: "100px",
                    maxWidth: "180px",
                    maxHeight: "160px",
                    overflowY: "auto",
                    overflowX: "hidden",
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#E8E8E8",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        borderRadius: "22px",
                        backgroundColor: "#0094A7",
                    },
                    "&::-webkit-scrollbar": {
                        width: "5px",
                        backgroundColor: "#E8E8E8",
                        height: "5px",
                        borderRadius: "15px",
                    },
                }
            }
        }
    }
})


export default AuditTrailFilterDropdown;

import React, { Component } from 'react';
import {
	Box,
	Typography,
	Divider,
	Grid,
	withStyles,
	FormControl,
	RadioGroup,
	FormControlLabel,
} from '@material-ui/core';
import CustomSecondaryButton from '../../../../../components/src/CustomSecondaryButton.web';
import { IWork } from '../../PeopleModuleController.web';
import CustomRadioButton from '../../../../../components/src/CustomRadioButton.web';
import { editPageButtonSvg, projectModuleCommonStyle, relatedGroupRelation, requestIcon } from '../../assets';
import { ChevronRight } from '@material-ui/icons';
import CustomTypographyWeb from '../../../../../components/src/CustomTypography.web';
import { customSort } from '../../../../../components/src/Utils';
import CustomButton from '../../../../../components/src/CustomButton.web';
import { AccessControl } from "../../../../../components/src/Permission"
import { DisableEditButton } from '../../../../../components/src/DisableEditButton';

type Props = {
	work: IWork;
	handleSwitchMode: (section: string, mode: 'update' | 'read' | 'post') => void;
  handlePeopleRequestNavigation: () => void;
};

type State = {};

export default class ReadWork extends Component<Props, State> {
	render() {
		const {
			design,
			education_stream,
			groupRelationRadio,
			education_degree,
			internal_rate,
			languages,
			primary_experience,
			primary_year,
			region_of_experience,
			secondary_experience,
			secondary_year,
			supervision,
			current_position,
			currentOtherPosition,
			groupRelationMenus
		} = this.props.work;
    const webStyleWork = projectModuleCommonStyle;

    const groupRelations = groupRelationRadio === "related" ? customSort({group: groupRelationMenus, menu:relatedGroupRelation}) : groupRelationMenus

		const sequenceLanguages = languages.map(lang => {
			let order = 0;
			switch(lang.name){
				case 'English':
					order = 1; 
					break;
				case 'French':
					order = 2; 
					break;
				case 'Arabic':
					order = 3; 
					break;
				case 'Spanish':
					order = 4; 
					break;
				case 'Italian':
					order = 5; 
					break;
				case 'Italian':
					order = 6; 
					break;
				default:
					order = 7;
					break;
			}

			return {
				...lang,
				order
			}
				
		})
		.sort((orderLangPre, orderLangNext) => orderLangPre.order - orderLangNext.order);

		return (
      <Box
        sx={{ display: "flex", flexDirection: "column", marginBottom: "52px" }}
      >
        {/* FIRST PART */}
        <SectionBox sx={webStyle.mainWrapper}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <CustomTypographyWeb isHeader style={{ marginBottom: "24px" }}>
              Education
            </CustomTypographyWeb>

            <AccessControl feature='update' module='People'>
              <CustomSecondaryButton
                label="Edit"
                startIcon={<img src={editPageButtonSvg} alt="Edit Page" />}
                style={{
                  color: "#0094A7",
                  boxShadow: "none",
                  border: "none",
                  textDecoration: "underline",
                  backgroundColor: "transparent",
                }}
                data-testId="edit-btn-personal-data"
                onClick={() => this.props.handleSwitchMode("work", "update")}
              />
            </AccessControl>
            
          </Box>
          {/* LEVEL - DISCIPLINE */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box
                style={{
                  marginBottom: "15px",
                }}
              >
                <Box>
                  <CustomTypographyWeb isLabel style={{ marginBottom: "7px" }}>
                    Highest level:
                  </CustomTypographyWeb>
                  <CustomTypographyWeb isContent>
                    {education_degree}
                  </CustomTypographyWeb>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box
                style={{
                  marginBottom: "15px",
                }}
              >
                <Box>
                  <CustomTypographyWeb isLabel style={{ marginBottom: "7px" }}>
                    Discipline:
                  </CustomTypographyWeb>
                  <CustomTypographyWeb
                    isContent
                    data-testId="disciplineContent"
                  >
                    {education_stream}
                  </CustomTypographyWeb>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </SectionBox>
        <Divider />
        <SectionBox
          sx={{
            marginTop: "24px",
            marginBottom: "32px",
          }}
        >
          {/* SECOND PART */}
          <CustomTypographyWeb isHeader style={{ marginBottom: "24px" }}>
            Experience
          </CustomTypographyWeb>
          {/* PRIMARY - YEARS */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box
                style={{
                  marginBottom: "32px",
                }}
              >
                <Box>
                  <CustomTypographyWeb
                    isLabel
                    data-testid={"PeopleModule-ReadPersonalData-Work"}
                    style={{ marginBottom: "7px" }}
                  >
                    Primary experience:
                  </CustomTypographyWeb>
                  <CustomTypographyWeb isContent>
                    {primary_experience}
                  </CustomTypographyWeb>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box
                style={{
                  marginBottom: "32px",
                }}
              >
                <Box>
                  <CustomTypographyWeb isLabel style={{ marginBottom: "7px" }}>
                    Primary years:
                  </CustomTypographyWeb>
                  <CustomTypographyWeb isContent>
                    {primary_year}
                  </CustomTypographyWeb>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* SECONDARY - YEARS */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box
                style={{
                  marginBottom: "32px",
                }}
              >
                <Box>
                  <CustomTypographyWeb isLabel style={{ marginBottom: "7px" }}>
                    Secondary experience:
                  </CustomTypographyWeb>
                  <CustomTypographyWeb isContent>
                    {secondary_experience}
                  </CustomTypographyWeb>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box
                style={{
                  marginBottom: "32px",
                }}
              >
                <Box>
                  <CustomTypographyWeb isLabel style={{ marginBottom: "7px" }}>
                    Secondary years:
                  </CustomTypographyWeb>
                  <CustomTypographyWeb isContent>
                    {secondary_year}
                  </CustomTypographyWeb>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {/* region_of_experience */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box
                style={{
                  marginBottom: "32px",
                }}
              >
                <Box>
                  <CustomTypographyWeb isLabel style={{ marginBottom: "7px" }}>
                    Country:
                  </CustomTypographyWeb>
                  <CustomTypographyWeb isContent data-testId="countryText">
                    {region_of_experience}
                  </CustomTypographyWeb>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* DESIGN - SUPERVISION */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box>
                  <CustomTypographyWeb isLabel style={{ marginBottom: "7px" }}>
                    Design:
                  </CustomTypographyWeb>
                  <CustomTypographyWeb isContent>{design}</CustomTypographyWeb>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box>
                  <CustomTypographyWeb isLabel style={{ marginBottom: "7px" }}>
                    Supervision:
                  </CustomTypographyWeb>
                  <CustomTypographyWeb isContent>
                    {supervision}
                  </CustomTypographyWeb>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </SectionBox>
        <Divider />
        {/* 3RD PART */}
        {/* CURRENT POSITION */}
        <SectionBox sx={webStyle.mainWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Box
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                }}
              >
                <Box>
                  <CustomTypographyWeb isLabel style={{ marginBottom: "7px" }}>
                    Current position:
                  </CustomTypographyWeb>
                  <CustomTypographyWeb isContent>
                    {currentOtherPosition
                      ? currentOtherPosition
                      : current_position}
                  </CustomTypographyWeb>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Divider />

          {/* 4TH PART */}
          <SectionBox
            sx={{
              marginTop: "24px",
            }}
          >
            {/* GROUP RELATION */}
            <CustomTypographyWeb isLabel style={{ marginTop: "11px" }}>
              Group Relation
            </CustomTypographyWeb>
            <Grid container>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup row>
                    <FormControlLabel
                      value="Related"
                      control={
                        <CustomRadioButton
                          checked={groupRelationRadio === "related"}
                          disabled
                        />
                      }
                      label={
                        <CustomTypographyWeb isContent>
                          Related
                        </CustomTypographyWeb>
                      }
                    />
                    <FormControlLabel
                      value="Unrelated"
                      control={
                        <CustomRadioButton
                          checked={groupRelationRadio === "unrelated"}
                          disabled
                        />
                      }
                      label={
                        <CustomTypographyWeb isContent>
                          Unrelated
                        </CustomTypographyWeb>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: "10px" }}>
                {Array.isArray(groupRelations) &&
                  groupRelations.length > 0 &&
                  groupRelations.map((obj: any) => {
                    return (
                      <Box
                        display="flex"
                        style={{ margin: "10px 0px" }}
                        alignItems="center"
                        key={obj.employe_status}
                      >
                        <Typography
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "#2B3132",
                          }}
                        >
                          {obj.employe_status ?? ""}{" "}
                        </Typography>
                        {obj?.status_option?.length > 0 && (
                          <ChevronRight style={{ margin: "0px 7px" }} />
                        )}
                        <Typography
                          style={{
                            fontFamily: "Source Sans Pro",
                            fontSize: "16px",
                            fontWeight: 400,
                            color: "#2B3132",
                          }}
                        >
                          {(Array.isArray(obj?.status_option) && obj.status_option.length > 1) ? obj.status_option.join(', ') : String(obj.status_option?.[0] ?? [""])}
                        </Typography>
                      </Box>
                    );
                  })}
              </Grid>
            </Grid>
          </SectionBox>
          <Divider />
          {/* LAST PART */}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            sx={{
              marginTop: "24px",
              marginBottom: "32px",
            }}
          >
            <CustomTypographyWeb isHeader style={{ marginBottom: "24px" }}>
              Language
            </CustomTypographyWeb>
            {/* Language Selection */}
            <Grid container spacing={2}>
              {sequenceLanguages?.map((lang, index) => (
                <Grid key={index} item xs={12} sm={6}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <CustomTypographyWeb
                      isLabel
                      style={{ marginBottom: "7px" }}
                    >
                      {lang.name}
                    </CustomTypographyWeb>
                    <FormControl component="fieldset">
                      <RadioGroup row>
                        <FormControlLabel
                          value="Beginner"
                          control={
                            <CustomRadioButton
                              checked={lang.level === "Beginner"}
                              disabled
                            />
                          }
                          label={
                            <span
                              style={{
                                color: "#2B3132",
                                fontFamily: "Source Sans Pro",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "16px",
                              }}
                            >
                              Beginner
                            </span>
                          }
                        />
                        <FormControlLabel
                          value="Intermediate"
                          control={
                            <CustomRadioButton
                              checked={lang.level === "Intermediate"}
                              disabled
                            />
                          }
                          label={
                            <span
                              style={{
                                color: "#2B3132",
                                fontFamily: "Source Sans Pro",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "16px",
                              }}
                            >
                              Intermediate
                            </span>
                          }
                        />
                        <FormControlLabel
                          value="Advance"
                          control={
                            <CustomRadioButton
                              checked={lang.level === "Advance"}
                              disabled
                            />
                          }
                          label={
                            <span
                              style={{
                                color: "#2B3132",
                                fontFamily: "Source Sans Pro",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "16px",
                              }}
                            >
                              Advance
                            </span>
                          }
                        />
                        <FormControlLabel
                          value="Native"
                          control={
                            <CustomRadioButton
                              checked={lang.level === "Native"}
                              disabled
                            />
                          }
                          label={
                            <span
                              style={{
                                color: "#2B3132",
                                fontFamily: "Source Sans Pro",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "16px",
                              }}
                            >
                              Native
                            </span>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Divider />

          <SectionBox
            sx={{
              marginTop: "24px",
              marginBottom: "32px",
            }}
          >
            <CustomTypographyWeb isHeader style={{ marginBottom: "24px" }}>
              Internal rating
            </CustomTypographyWeb>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Box
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  <Box>
                    <CustomTypographyWeb
                      isContent
                      style={{ textTransform: "capitalize" }}
                    >
                      {typeof internal_rate === "string"
                        ? internal_rate
                        : internal_rate?.label}
                    </CustomTypographyWeb>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </SectionBox>
        </SectionBox>
        <Box sx={webStyleWork.requestButtonTender} id="work-reqest">
					<CustomButton
						colorVariant="projectRequest"
						startIcon={<img src={requestIcon} alt="work-warn" />}
            onClick={this.props.handlePeopleRequestNavigation}
					>
						Request
					</CustomButton>
				</Box>
      </Box>
    );
	}
}

const webStyle = {
	mainWrapper: {
	},
};



const SectionBox = withStyles({
	// root: {
	//   padding: "43px 32px",
	// },
})(Box);

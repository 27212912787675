import React, {PropsWithChildren} from "react";
import {
    Theme,
    Box,
    createStyles,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
    Table,
    TableContainer,
    withStyles,
    styled
} from "@material-ui/core";
import CustomButton from "../../../../components/src/CustomButton.web";
import { RowColumn, MapValueFunc } from "../assets";

const deleteButtonSvg = require('../../assets/delete_button.svg')

interface Props {
  data: any;
  editMode: boolean;
  schema: RowColumn[];
  onDeleteRow: (deleteRow: any) => void;
}

interface State {

}

export class ConnectionTable extends React.Component<PropsWithChildren<Props>, State> {

  render(): React.ReactNode {
    const labels = this.props.schema.map(schema => schema.label);
    const keyAndTypes = this.props.schema.map(schema => [schema.dataKey, schema?.type, schema?.mapValue]);
    const data = this.props.data;
    
    return (
      <TableContainer style={{ overflow: "auto" }}>
          <CssCustomTable>
            <TableHead>
              <TableRow>
                { labels.map((label) => (
                  <CssCustomTableHeader key={label}>{label}</CssCustomTableHeader>
                ))}
                { this.props.editMode && 
                  <CssCustomTableActionCell />
                }
               
              </TableRow>
            </TableHead>
            <TableBody>
                {  data.map((rowData: any, index: number) => {
                  return (
                    <CssCustomTableRow
                      data-testId="documentRow"
                      key={`row_${index}`}
                    >
                        {keyAndTypes.map(([keyRow, type, mapValue]) => {

                          if (type == 'link') {
                            return (
                              <CssCustomTableCell data-testId="link">
                                { mapValue ? (mapValue as MapValueFunc)(rowData, index) : ( rowData?.[keyRow as string] || '---') }
                              </CssCustomTableCell>
                            )
                          } else {
                            return (
                              <CssCustomTableCell data-testId="value">
                                <div>
                                  { mapValue ? (mapValue as MapValueFunc)(rowData, index) : ( rowData?.[keyRow as string] || '---') }
                                </div>
                              </CssCustomTableCell>
                            )
                          }

                        })}
                        { this.props.editMode && 
                          <CssCustomTableActionCell>
                            <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CustomButton
                                  colorVariant="tableRowDelete"
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                  }}
                                  onClick={() => this.props.onDeleteRow(rowData)}
                                  data-testId="tableRowDelete"
                                  
                                >
                                  <img src={deleteButtonSvg} alt="delete" />
                              </CustomButton>
                            </Box>
                          </CssCustomTableActionCell>
                        }
                        
                    </CssCustomTableRow>
                  )
                })}
                
            </TableBody>
          </CssCustomTable>
        </TableContainer>
    )
  }

}


const CssCustomTable = withStyles({
  root: {
    borderCollapse: "separate",
    borderSpacing: "0 15px",
    overflow: "hidden",
  },
})(Table);

const CssCustomTableRow = styled(TableRow)(({ theme }) => ({
  '&&': {
    background: '#e9e9e9',
    borderRadius: '8px',
  }

}));

const CssCustomTableCell = withStyles({
  root: {
    color: "#000",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    width: '200px',
    padding: 0,
    borderBottom: 'none',
    height: '40px',
    "& div": {
      width: '200px',
      whiteSpace: 'nowrap',
      paddingTop: '10px',
      paddingBottom: '10px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    "&:first-child": {
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
      paddingLeft: '12px'
    },

    "&:last-child": {
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
    }
  },
  
})(TableCell);


const CssCustomTableHeader = withStyles({
  root: {
    color: "#1F1F1F",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    borderBottom: 'none',
    padding: 0
  },
})(TableCell);

const CssCustomTableActionCell = withStyles({
  root: {
    width: "100px",
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    padding: 0,
    borderBottom: "none"
  },
})(TableCell);


export const styles = (theme: Theme) =>
createStyles({
  
});
export default withStyles(styles)(ConnectionTable)

import React from "react";
// Customizable Area Start
import {
  AppBar,
  Box,
  Button,
  Modal,
  Toolbar,
  createStyles,
  withStyles,
  Avatar,
  Popper,
  ClickAwayListener,
  Paper,
  MenuList,
  MenuItem
} from "@material-ui/core";
import { AccountCircle, KeyboardArrowDown, KeyboardArrowUp, Mms, PersonOutline } from "@material-ui/icons";
import CustomTypographyWeb from "../CustomTypography.web";
import CustomButton from "../CustomButton.web";
import { Link } from "react-router-dom";
import { Message } from "../../../framework/src/Message";
import { AdvancedFilterContext, Permission, getPermission } from "../ContextAPI/AdvancedFilterContext.web";
import { CheckedIcon, LogoutIcon, ProfileIcon, RequestIcon, RoleIcon, SwitchAccIcon } from "./assets"
import { convertExactRoles, logoutUser } from "../Utils";
import { defaultRoute, TypeDefaultRole } from "./assets";


// Customizable Area End
// Customizable Area Start
type State = {
  isDropdownOpen: boolean;
  isModalOpen: boolean;
  hoveredItem: string;
  isSwitchRoleOpen: boolean;
};

type Props = {
  navigation: any;
  classes: Record<string, string>;
};


const styles = createStyles({
  menuItem: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    padding: 15,
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: '#F0F0F0',
      color: '#2B3132',
      fontWeight: 700
    }
  },
  divider: { 
    margin: '0 15px', 
    border: 'none',
    borderBottom: '1px solid #E8E8E8',
  },
  modal: {
    fontFamily: "Source Sans Pro",
    display: 'flex',
    alignItems: 'center',
    backdropFilter: 'blur(4px)'
  },
  modalContent: {
    background: '#fff',
    borderRadius: 16,
    width: 487,
    height: 173,
    margin: 'auto',
  },
  modalTitle: {
    fontFamily: 'Source Sans Pro',
    fontSize: 20,
    fontWeight: 600,
    width: '100%',
    margin: '30px 0',
    textAlign: 'center'
  },
  modalButtonBox: {
    margin: "0 18%",
    display: 'flex',
    justifyContent: 'space-between'
  },
  modalButton: {
    width: 140,
    height: 50,
    borderRadius: 8,
    border: '1.5px solid #0094A7',
    color: '#0094A7',
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'none'
  },
  menuItemLink: {
    textDecoration: "none",
    color: '#0094A7',
  },
  switchPopper: {
    top: "50% !important",
    right: "240px !important",
    left: "auto !important",
    "& .papper": {
      borderRadius: "4px",
      padding: "5px 0px",
      border: "1px solid #9F9F9F",
      maxWidth: "320px",
      minWidth: "180px",
      background: "#FFF",
      boxShadow: "-4px 4px 24px 0px rgba(0, 0, 0, 0.16)",
      "& .menuList": {
        padding: "0px",
        "& li": {
          display: 'flex',
          flexDirection: 'row',
          gap: 12,
          alignItems: 'center',
          padding: "11px 48px",
          cursor: 'pointer',
          color: '#0094A7',
          fontSize: "1rem",
          fontFamily: 'Source Sans Pro',
          boxSizing: 'border-box', 
          fontWeight: '600',
          "&:hover": {
            backgroundColor: '#F0F0F0',
            color: '#2B3132',
          },
          "& p": {
            margin: "0",
          },
        },
        "& .selectedMenu":{
          backgroundColor: "rgb(214, 238, 241) !important",
          cursor: "default",
          "&:hover": {
            color: '#0094A7',
            fontWeight: '600',
          },
          "& .icon": {
            position: "absolute",
            width: "24px",
            height: "24px",
            left: "12px",
          },
        }
      },
    },
  },
})
// Customizable Area End

class TopBar extends React.Component<Props, State> {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      isModalOpen: false,
      hoveredItem: '',
      isSwitchRoleOpen: false,
    };
  }


  receive(from: string, message: Message): void {
    console.log("from: ", from, message)
  }

  handleMenuClose = () => {
    this.setState({
      isDropdownOpen: false,
    })
  }

  handleMenu = () => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });
  };

  handleItemHover = (item: string) => {
    this.setState({ hoveredItem: item })
  }

  handleModalClose = () => {
    this.handleCloseSwitchRole();
    this.setState({ isModalOpen: false })
  }

  handleToggelSwitchRole = (event: React.MouseEvent<HTMLDivElement>) => {
    const { currentTarget } = event;
    this.setState((prevState) => ({
      isSwitchRoleOpen: !prevState.isSwitchRoleOpen,
    }));
  };

  handleCloseSwitchRole = () => {
    this.setState({
      isSwitchRoleOpen: false,
    })
  }

  handleRequest = () => {
    this.props.navigation.navigate("RequestReport")
  }

  handleSwitchRole = (role: string) => {

    getPermission().then((responsePermission: any) => {
      const permissionData = responsePermission?.data || [];

      const mapPermisionList = permissionData?.map((permission: any) => {
        const attributes = permission.attributes
        return {
          create: attributes?.Create,
          update: attributes?.Update,
          view: attributes?.View,
          import: attributes?.Import,
          export: attributes?.Export,
          moduleName: attributes?.module_name,
          role: attributes.role

        } as Permission
      })
      .reduce((acc: { [key: string]: Permission[] }, current: Permission) => {

        const isExist = acc.hasOwnProperty(current.role);
        const key = current.role;
        const listPermission = Array.from(isExist ? acc[key]: []) as Permission[]
        
        if (isExist) {
          return {
            ...acc,
            [key]: [
              ...listPermission,
              current
            ]
          }
        } else {
          return {
            ...acc,
            [key]: [current]
          }
        }
      }, {})

      const user = { ...this.context?.globalContext.currentUser, defaultRole: role }
      localStorage.removeItem("currentUser")
      this.context?.setGlobalContext((prev: any) => ({
        ...prev,
        permissions: mapPermisionList,
        currentUser: user,
      }))
      localStorage.setItem("currentUser", JSON.stringify(user))
    })
    .catch((err) => {
      console.log(err);
    })

    const currentModule = this.props.navigation?.module as TypeDefaultRole || 'People';
    this.handleMenuClose();
    this.props.navigation.navigate(defaultRoute[currentModule])
  }

  render() {
    const { isDropdownOpen, isModalOpen, hoveredItem, isSwitchRoleOpen } = this.state;
    const { classes } = this.props;
    const {currentUser, permissions} = this.context?.globalContext;
    const roles = convertExactRoles(currentUser.roles);

    return (
      <div>
        <AppBar position="fixed" style={{ backgroundColor: "#0094A7", zIndex: 999 }}>
          <Toolbar style={{ padding: '0 60px' }}>
            <div style={{ marginLeft: "auto" }}>
              <div onMouseLeave={this.handleMenuClose}>
                <CustomButton
                  aria-controls="dropdown-menu"
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  style={{
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                    paddingRight: 0
                  }}
                  endIcon={isDropdownOpen ? <KeyboardArrowUp style={{ fontSize: 30 }} /> : <KeyboardArrowDown style={{ fontSize: 30 }} />}
                >
                  {currentUser?.profilePhoto ? <Avatar alt={currentUser?.fullName || ""} src={currentUser.profilePhoto} style={{width: 30, height: 30}} /> : <AccountCircle />}
                  <CustomTypographyWeb
                    variant="subtitle1"
                    style={{ paddingLeft: "0.5em", fontWeight: 700, fontSize: '1.1rem' }}
                  >
                    {currentUser?.fullName || "" }
                  </CustomTypographyWeb>
                </CustomButton>
                <div
                  style={{
                    display: isDropdownOpen
                      ? "flex"
                      : "none",
                    right: 60,
                    flexDirection: 'column',
                    position: 'absolute',
                    width: '240px',
                    borderRadius: 4,
                    fontFamily: 'Source Sans Pro',
                    fontSize: '1rem',
                    zIndex: 999,
                    fontWeight: 600,
                    border: '1px solidblue',
                    background: '#fff',
                    boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.24)',
                    color: '#0094A7'
                  }}

                >
                  <div
                    className={classes.menuItem}
                    onClick={() => {
                      console.log('Role')
                    }}
                    onMouseEnter={() => this.handleItemHover('role')}
                    onMouseLeave={() => this.handleItemHover('')}
                  >
                    <img src={RoleIcon} />
                    {currentUser?.defaultRole}
                  </div>

                  <hr
                    className={classes.divider}
                    style={{ borderBottomColor: hoveredItem === 'role' ? '#9F9F9F' : '#E8E8E8' }}
                  />
                  <Link to='/UserProfile' className={classes.menuItemLink}>
                    <div
                      className={classes.menuItem}
                      onClick={() => {
                        console.log('Profile')
                      }}
                      onMouseEnter={() => this.handleItemHover('profile')}
                      onMouseLeave={() => this.handleItemHover('')}
                    >
                      <img src={ProfileIcon} />
                      Profile
                    </div>
                  </Link>

                  <hr
                    className={classes.divider}
                    style={{ borderBottomColor: hoveredItem === 'profile' ? '#9F9F9F' : '#E8E8E8' }}
                  />

                  <div
                    className={classes.menuItem}
                    onClick={this.handleToggelSwitchRole}
                    onMouseEnter={() => this.handleItemHover('switch')}
                    onMouseLeave={() => this.handleItemHover('')}
                  >
                    <img src={SwitchAccIcon} />
                    Switch Account
                  </div>

                  <Popper
                    open={isSwitchRoleOpen}
                    className={classes.switchPopper}
                    transition
                    disablePortal
                    placeholder="bottom"
                    style={{ position: 'absolute'}}
                  >
                    <ClickAwayListener onClickAway={() => this.handleCloseSwitchRole()}>
                      <Paper className="papper">
                        <MenuList
                          autoFocusItem={isSwitchRoleOpen}
                          id="menu-list-grow"
                          className="menuList"
                        >
                          {roles?.map((item: any, index: number) => {
                            const isMatchRole = currentUser?.defaultRole === item.role_name 
                            return <MenuItem 
                            key={`${item.role_name}-${index}`} 
                            className={`${isMatchRole ? "selectedMenu" : ""}`}
                            onClick={() => !isMatchRole ? this.handleSwitchRole(item.role_name) : {}}
                            >
                              {isMatchRole && <img src={CheckedIcon} className="icon" />}
                              <p>{item.role_name}</p>
                            </MenuItem>
                          })}
                        </MenuList>
                      </Paper>
                    </ClickAwayListener>
                  </Popper>

                  <hr
                    className={classes.divider}
                    style={{ borderBottomColor: hoveredItem === 'switch' ? '#9F9F9F' : '#E8E8E8' }}
                  />

                  <div
                    className={classes.menuItem}
                    onClick={() => this.handleRequest()}
                    onMouseEnter={() => this.handleItemHover('request')}
                    onMouseLeave={() => this.handleItemHover('')}
                  >
                    <img src={RequestIcon} />
                    Request
                  </div>

                  <hr
                    className={classes.divider}
                    style={{ borderBottomColor: hoveredItem === 'request' ? '#9F9F9F' : '#E8E8E8' }}
                  />

                  <div
                    className={classes.menuItem}
                    onClick={() => this.setState({ isModalOpen: true })}
                  >
                    <img src={LogoutIcon} />
                    Log Out
                  </div>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Modal open={isModalOpen} className={classes.modal} onClose={this.handleModalClose}>
          <Box className={classes.modalContent}>
            <CustomTypographyWeb className={classes.modalTitle}>Are you sure you want to logout this account?</CustomTypographyWeb>
            <Box className={classes.modalButtonBox}>
              <Button
                className={classes.modalButton}
                onClick={this.handleModalClose}
              >
                Cancel
              </Button>
              <Button
                className={classes.modalButton}
                style={{ background: '#0094A7', color: '#fff' }}
                onClick={async () => await logoutUser(this.props)}
              >
                Logout
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default withStyles(styles)(TopBar);

TopBar.contextType = AdvancedFilterContext;
// Customizable Area End

// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Hidden,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  TextField,
  Link as NavigationTag
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {createStyles, withStyles } from "@material-ui/styles";
import RequestViewDetailsController, {
  Props,
  RequestCommentData,
  RequestableSearch
} from "./RequestViewDetailsController.web";
import { KeyboardBackspace } from "@material-ui/icons";
import { uploadIconSvg } from "./assets";
import ResponsiveTopBar from "../../../components/src/TopBar/ResponsiveTopBar.web";
import CustomTypography from '../../../components/src/CustomTypography.web';
import CustomButton from '../../../components/src/CustomButton.web';
import CustomRadioButton from "../../../components/src/CustomRadioButton.web";
import CustomSelectMenu from '../../../components/src/CustomSelectMenu.web';
// Customizable Area End

class RequestAddEdit extends RequestViewDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  uploadFileRef = React.createRef<HTMLInputElement>();
  requestGoBackEdit = () =>{
    const { classes } = this.props;
     return (<>
         <Box className={classes.containerEdit}>
           <Box className={classes.topHeaderEdit}>
             <Box className={classes.requestTitleEdit}>
               <KeyboardBackspace onClick={this.handleBackButton} data-testId="requestBackbtnTestId"/>
               <Typography className={classes.titleEdit} data-testId="requestBacklabelTestId">
                    Raise a request
               </Typography>
             </Box>
           </Box>
         </Box>
     </>)
  }

  requestDetailsDataAdd = () =>{
    const { classes } = this.props;
    const requestData = this.state.requestData
    const searchDataValue = this.state.existingSearchList.find(option => option.record === requestData?.existingRecord) || null
     return (<>
       <Box className={classes.containerMiddleEdit}>
        <RowBoxEdit>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Box className={classes.viewComponentEdit}>
                <CustomTypography isLabel className={classes.reqeustLabelTop} data-testId="companyNameLabelTestID" >Company name </CustomTypography>
                 <CustomSelectMenu
                  data-testId= "companyNameTestID"
                  options={this.state.companyOptions}
                  placeholder='Select Company'
                  label=""
                  error={this.state.hasNameError}
                  value={requestData?.companyName} 
                  onChangeValue={this.handleCompanyName}
                  helperText="Company name is required."
                  className={classes.statusInput}
                />               
                 </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box className={classes.viewComponentEdit}>
                <CustomTypography isLabel className={classes.reqeustLabelTop} data-testId = "roleLabelTestID" >Role</CustomTypography>
                  <CustomSelectMenu
                  data-testId= "roleTestID"
                  options={this.state.roleOptions}
                  placeholder='Select Role'
                  label=""
                  error={this.state.hasRoleError}
                  value={requestData?.role} 
                  onChangeValue={this.handleRoleValue}
                  helperText="Role is required."
                  className={classes.statusInput}
                />  
              </Box>
            </Grid>
          </Grid>
        </RowBoxEdit>
        <RowBoxEdit>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} >
            <Box className={classes.viewComponentEdit}>
                <CustomTypography isLabel className={classes.reqeustLabelTop} data-testId="moduleLabelTestID">Choose Module</CustomTypography>
                  <CustomSelectMenu
                  data-testId= "moduleNameTestID"
                  options={this.state.moduleOption}
                  placeholder='Select Module'
                  label=""
                  error={this.state.hasModuleError}
                  value={requestData?.moduleName} 
                  onChangeValue={this.handleModuleName}
                  helperText="Module name is required."
                  className={classes.statusInput}
                />
              </Box>
            </Grid>
            </Grid>
        </RowBoxEdit>
        <Divider />

         <RowBoxEdit>
           <Grid item xs={12} sm={7}>
             <Box className={classes.viewComponentEdit}>
               <CustomTypography isLabel className={classes.reqeustLabelTop} data-testId="recordlabelTestId" >Record Type</CustomTypography>
             </Box>
             <Box className={classes.existingBoxEdit}>
               <FormControl component="fieldset">
                 <RadioGroup>
                   <Box className={classes.existingBox}>
                     <FormControlLabel
                       data-testId="existingRadioBtnTestId"
                       control={
                         <CustomRadioButton
                           checked={this.state.isExisting}
                         />
                       }
                       onChange={this.handleRadioExistingRecord}
                       label="Existing"
                     />
                     <FormControlLabel
                       data-testId="newRadioBtnTestId"
                       control={
                         <CustomRadioButton
                           checked={!this.state.isExisting}
                         />
                       }
                       onChange={this.handleRadioNewRecord}
                       label="New"
                     />
                   </Box>
                 </RadioGroup>
               </FormControl>
               {!this.state.isExisting ? 
               <Box className={classes.existingInputBoxAdd}>
                 <textarea className={classes.commentInputNew} data-testId="newRecordTestId" value={requestData?.newRecord} onChange={this.handleRecordData} placeholder="Type a record details"/>
                 {this.state.hasRecordError && <Typography className={classes.requestErrorText}>Record is required.</Typography>}
               </Box>
               :
               <Box data-testId="requetExistingRecordTestId" onClick={this.handleExisitngRecord}>
              {this.state.hasExistingRecord ? 
              <Box className={classes.existingInputBoxAE}>
              <Typography className={classes.existingInputTextAE}>{requestData?.existingRecord}</Typography>
              </Box>
              : 
               <Box className={classes.existingInputBoxEdit}>
                     <Autocomplete
                       data-testId="searchRcordTestId" 
                       id="combo-box-demo"
                       className={classes.existingInputAutocomplete}
                       options={this.state.existingSearchList }
                       getOptionLabel={(option) => option?.record }
                       onChange={(event, value: RequestableSearch | null) => this.handleSearchSelected(value as RequestableSearch)}
                       value={searchDataValue}
                       renderInput={(params) => (
                         <TextField
                           {...params}
                           data-testId="searchRcordTestId"
                           onChange={this.handleExistingSearchData}
                           placeholder={`Search ${this.state.requestData.moduleName}`}
                           className={classes.existingInputTextEdit}
                           variant="outlined"
                         />
                       )}
                     />
                    {this.state.hasRecordError && <Typography className={classes.requestErrorText}>Record is required.</Typography>}
               </Box>
            }
              </Box>
              }
             </Box>
           </Grid>
         </RowBoxEdit>

         <Box>          
         <Divider />
        <RowBoxEdit>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7} className={classes.commentInputBox} >
            {this.requestCommentBox()}
            </Grid>

          </Grid>
        </RowBoxEdit>
        </Box>
         <Box>
           <Grid container spacing={2}>
             <Grid item xs={11}>
               <Box
                 className={classes.updateSaveCancelBtnEdit}
               >
                 <CustomButton
                   colorVariant="special"
                   onClick={this.handleRequestCancelBtn}
                   data-testId="cancelbtnTestId"
                   >
                   Cancel
                 </CustomButton>
                 <CustomButton
                   data-testId="createReqeustTestId"
                   colorVariant="default"
                   onClick={this.handlePostRequestData}
                 >
                   Submit
                 </CustomButton>
               </Box>
             </Grid>
           </Grid>
         </Box>

       </Box>
     </>)
  }

  requestCommentBox = () =>{
    const { classes } = this.props;
    const { hasFileError } = this.state;
    const  getFile =  this.state.selectedFile ? this.state.selectedFile.name : "Attach file"
    const fileName = this.state.fileName ? this.state.fileName : getFile;
      return(<>
        <Box className= {classes.commentBoxEdit}>
        {this.state.requestId && <CustomTypography isLabel className={classes.reqeustLabelTop} >Comment </CustomTypography>}
        <textarea data-testId="requestCommentTestId" disabled={this.state.staticRequestData.status === "Closed"} className= {classes.commentInputEdit} value={this.state.comment} onChange={this.handleCommentData} placeholder="Enter your comment"/>
        {this.state.hasCommentError && <Typography className={classes.requestErrorText}>Comment is required.</Typography>}
        </Box>
        <Box className= {classes.uploadFileEdit} data-testId="requestFileUploadTestId" onClick={()=>this.handleUploadButtonClick(this.uploadFileRef)}>
            <img src={uploadIconSvg} alt="upload" />
            <input data-testid="import-data-input"
              ref={this.uploadFileRef}
              type="file"
              hidden
              accept=".pdf,image/jpeg"
              name="ImportData"
              data-testId="requestPdfSelectTestId"
              onChange={this.handleFileChange}
            />
            <Typography className={classes.attachFileTextEdit}>
              {fileName}
            </Typography>
          </Box>
          {
            hasFileError && <Typography style={{ marginLeft: '10px'}} className={classes.requestErrorText}>{hasFileError}</Typography>
          }
      </>)
  }

  requestDetailsDataEdit = () => {
    const { classes } = this.props;
    const viewData = this.state.requestData
    const requestComment = viewData.commentData;
     return (<>
       <Box className={classes.containerMiddleAE}>
        <RowBoxEdit>
					<Grid container className={classes.gridGapEdit}>
						<Grid item xs={12} sm={4}>
							<Box className={classes.viewComponentAE}>
								<CustomTypography isLabel className={classes.reqeustLabelTop} >Company name </CustomTypography>
								<CustomTypography isContent>{viewData?.companyName}</CustomTypography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Box className={classes.viewComponentAE}>
								<CustomTypography isLabel className={classes.reqeustLabelTop} >Role</CustomTypography>
								<CustomTypography isContent>
                   {viewData?.role}
								</CustomTypography>
							</Box>
						</Grid>
					</Grid>
				</RowBoxEdit>
				<RowBoxEdit>
					<Grid item xs={12} sm={4}>
						<Box className={classes.viewComponentAE}>
							<CustomTypography isLabel className={classes.reqeustLabelTop} >Choose Module</CustomTypography>
							<CustomTypography isContent>{viewData?.moduleName}</CustomTypography>
						</Box>
					</Grid>
				</RowBoxEdit>
        <Divider />

        <RowBoxEdit>
					<Grid item xs={12} sm={7} className={classes.gridNew}>
						<Box className={classes.viewComponentAE}>
							<CustomTypography isLabel className={classes.reqeustLabelTop} data-testId="recordReadTestId">Record Type</CustomTypography>
						</Box>
            <Box className={classes.existingBoxEdit}>
             {viewData?.existingRecord ?
            <>
             <Typography className={classes.existingHeadingAE}>Existing</Typography>
              <Box className={classes.existingInputBoxAE} 
                data-testId="exisitLinkTestId"
               onClick={this.handleExistRecordNavigation}
               >
              <Typography className={classes.existingRecordLink}>{viewData?.existingRecord}</Typography>
              <NavigationTag id={"anchorID"} style={{display: "none"}} ></NavigationTag>
              </Box>
            </>
            :
            <>
             <Typography className={classes.existingHeadingAE}>New</Typography>
              <Box className={classes.existingInputBoxAE}>
              <Typography className={classes.existingInputTextAE} dangerouslySetInnerHTML={{__html: viewData?.newRecord.replace(/\n/g, "<br />")}} />
              </Box>
            </> 
            }
             
            {requestComment?.map((commentData: RequestCommentData )=>{
                const comment = commentData?.attributes
              return(
                <Box>
                <Box className={classes.commentReadBoxAE} style={{ width: "95%" }}>
                  <Typography className={classes.commentReadContentAE} dangerouslySetInnerHTML={{__html: comment?.comment.replace(/\n/g, "<br />") }}/>
                </Box>
                <Button className={classes.commentFileBtnAE} data-testId="commentFileTestId" onClick={()=>this.handleCommentFile(comment?.file?.link)}>{comment?.file?.file_name}</Button>
                </Box>
              )
            })}            
            </Box>
					</Grid>
				</RowBoxEdit>

           <Divider />
           <RowBoxEdit>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={7}>
              {this.requestCommentBox()}
             </Grid>
             <Grid item xs={8} sm={3}>
             <Box className={classes.viewComponentAE}>
               <CustomTypography isLabel className={classes.reqeustLabelTop} data-testId="statusLabelTestID">Status</CustomTypography>
                 <CustomSelectMenu
                  data-testId= "statusTestID"
                  options={this.state.status}
                  placeholder='Status'
                  label=""
                  error={this.state.hasStatusError}
                  value={this.state.requestData.status} 
                  onChangeValue={this.handleStatusData}
                  disabled ={this.state.staticRequestData.status === "Closed"}
                  helperText="Status is required."
                  className={classes.statusInput}
                />
             </Box>
           </Grid>
          </Grid>
        </RowBoxEdit>
         <Box>
           <Grid container spacing={2}>
             <Grid item xs={11}>
               <Box
                 className={classes.updateSaveCancelBtnEdit}
               >
                 <CustomButton
                   data-testId="cancelBtnTestId"
                   colorVariant="special"
                   onClick={this.handleRequestCancelBtn}
                   >
                   Cancel
                 </CustomButton>
                 <CustomButton
                    data-testId="updateReqeustTestId"
                   colorVariant="default"
                   onClick={this.handleUpdateRequestData}
                 >
                   Submit
                 </CustomButton>
               </Box>
             </Grid>
           </Grid>
         </Box>
       </Box>  
     </>)
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
     const { classes } = this.props;
    return (<>
    <Box className={classes.MainBoxEdit}>
      <Hidden mdUp>
          <ResponsiveTopBar navigation={this.props.navigation} title={"Menu"} />
      </Hidden>
    <Box className={classes.viewMainContainerEdit}>
       {this.requestGoBackEdit()}
    </Box>
      <Box className={classes.viewMainContainerEdit}>
      <Box className={classes.requestMiddleContainerEdit}> 
        {this.state.requestId ? this.requestDetailsDataEdit():        
        this.requestDetailsDataAdd()}
      </Box>
      </Box>
    </Box>
    </>)    
    // Customizable Area End
  }
}
// Customizable Area Start
const RowBoxEdit = withStyles({
  root: {
   display: 'flex',
   alignItems: 'flex-start',
   flexDirection: 'row',
   fontFamily: "Source Sans Pro",
   fontSize: "18px",
   fontStyle: "normal",
   fontWeight: 700,
   lineHeight: "24px",
   padding: "0 35px"
  },
   })(Box);

const styles = createStyles({
  MainBoxEdit : {
    backgroundColor: "#F3F3F3",
    overflowY: "scroll",
    scrollbarWidth: "none",
    overflowX: "hidden",
    height: "calc(100vh - 65px)",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    width: "100%", 
    "@media (max-width: 960px)": {
      height : "100vh",
      paddingBottom : "50px"
    },   
  },

  gridGapEdit : {
    "@media (max-width: 600px)": {
      gap : "20px"
    },
  },

  viewMainContainerEdit : {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#F3F3F3",
    width: "100%",
  },

  containerEdit : {
    display: "flex",
    flexDirection: "column",
    padding: "20px 0",
    gap: "16px",
    "@media (max-width: 960px)": {
      width: "90% !important",
    },
    width: "80%",
    backgroundColor: "#F3F3F3",
  },

  reqeustLabelTop : {
      marginBottom : "7px",
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      fontWeight: 600
  },

  topHeaderEdit : {
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    height: "100%",
    display: "flex",
    maxHeight: "51px",
    flexDirection: "row",
    padding: "16px 0",
    gap: "16px",
    alignItems: "center",
    borderRadius: "8px",
  },

  requestTitleEdit : {
    display: "flex",
    marginLeft: "30px",
    flexDirection: "row",
    gap: "15px",
    "& .MuiSvgIcon-root": {
      height: "24px",
      cursor: "pointer",
      width: "24px",
  }
 },

 requestErrorText: {
  fontFamily : "Source Sans Pro",
  fontSize : "12px",
  fontWeight : 400,
  color: "#f44336",
  marginTop: "5px"
 },

 titleEdit : {
  textAlign: "center",
  fontFamily: "Source Sans Pro",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
 }, 

  requestMiddleContainerEdit: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    scrollbarWidth: "none",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "@media (max-width: 960px)": {
      width: "90% !important",
    },
  },

 containerMiddleEdit : {
  display: "flex",
  flexDirection: "column",
  padding: "20px 0",
  gap: "16px",
  width: "100% !important",
  backgroundColor: "#FFF",
  borderRadius: "8px",
},

existingBoxEdit : {
  padding: "0 10px",
  "@media (max-width: 600px)": {
    padding: "0",
  },
},

existingHeadingEdit : {
 fontFamily : "Source Sans Pro",
 fontSize : "16px",
 fontWeight : 400,
 color: "#2B3132"
},

existingInputBoxEdit: {
  width : "100%",
  borderRadius : "8px",
  display: "flex",
  justifyContent: "start",
  padding: "15px 0px",
  flexDirection: "column",
},
existingInputBoxAdd: {
  borderRadius : "8px",
  display: "flex",
  justifyContent: "start",
  padding: "0 16px",
  flexDirection: "column",
},

existingInputTextEdit: {
  fontFamily : "Source Sans Pro",
 fontSize : "16px",
 fontWeight : 400,
 color: "#2B3132",
 borderRadius: "8px",
},

existingInputAutocomplete: {
   "& .MuiAutocomplete-endAdornment":{
     display : "none",
     border : "none",
   },
   "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#F3F3F3",

   }
},

commentReadBoxEdit: {
  width: "100%",
  border: "1px #9F9F9F solid",
  borderRadius: "8px",
  padding: "15px 20px",
  marginTop: "10px",
  "@media (max-width: 960px)": {
    width: "80% !important",
  },
},

commentReadContentEdit: {
  fontFamily : "Source Sans Pro",
  fontSize : "14px",
  fontWeight : 400,
  color: "#2B3132",
  opacity: "56%"
},

commentFileBtnEdit: {
  border: "none",
  background: "none",
  textDecoration: "underline",
  color: "#0094A7",
  fontFamily : "Source Sans Pro",
  fontSize : "16px",
  fontWeight : 400,
  marginTop : "7px",
  textTransform : "capitalize",
  marginLeft: "-5px"
},

commentInputEdit: {
  width : "100%",
  height : "125px",
  border : "1px #9F9F9F solid",
  borderRadius : "8px",
  padding : "10px",
  fontFamily : "Source Sans Pro",
  fontSize : "14px",
  fontWeight : 400,
  color: "#2B3132",
  backgroundColor: "#F3F3F3"
},

commentBoxAdd: {
   marginTop : "20px",
   marginLeft: "10px"
},

commentBoxEdit: {
  marginLeft: "10px",
  padding: "0 17px",
  "@media (max-width: 600px)": {
    marginLeft: "0px",
    padding: "0 8px"
  },
},

commentInputNew: {
  width : "100%",
  height : "85px",
  border : "1px #9F9F9F solid",
  borderRadius : "8px",
  padding : "10px",
  fontFamily : "Source Sans Pro",
  fontSize : "14px",
  fontWeight : 400,
  color: "#2B3132",
},

updateSaveCancelBtnEdit: {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: '20px',
  marginTop: '20px',
  "@media (max-width: 400px)": {
    gap: '5px',
  },
},

uploadFileEdit : {
 display : "flex",
 color: "#0094A7",
 alignItems: "center",
 marginTop: "10px",
 cursor: "pointer",
 marginLeft: "10px",
 "@media (max-width: 600px)": {
  marginLeft: "0px",
},
},

attachFileTextEdit: {
  marginLeft : "10px",
  fontFamily : "Source Sans Pro",
  fontSize : "16px",
  fontWeight : 400,
},

existingBox : {
  width: "100%",
  display: "flex"
},

containerMiddleAE : {
display: "flex",
flexDirection: "column",
padding: "20px 0",
gap: "16px",
"@media (max-width: 960px)": {
  width: "100% !important",
},
backgroundColor: "#FFF",
borderRadius: "8px",
marginBottom: "50px"
},

existingHeadingAE : {
fontFamily : "Source Sans Pro",
fontSize : "16px",
fontWeight : 400,
color: "#2B3132"
},

existingInputBoxAE: {
width : "100%",
borderRadius : "8px",
display: "flex",
justifyContent: "start",
alignItems: "centre",
padding: "15px",
backgroundColor: "#F3F3F3",
marginTop: "10px"
},

existingInputTextAE: {
fontFamily : "Source Sans Pro",
fontSize : "16px",
fontWeight : 400,
color: "#2B3132"
},

existingRecordLink: {
  fontFamily : "Source Sans Pro",
  fontSize : "16px",
  fontWeight : 400,
  color: "#0094A7",
  cursor: "pointer",
  textDecoration: "underline"
  },

commentReadBoxAE: {
width: "80%",
minHeight: "86px",
border: "1px #9F9F9F solid",
borderRadius: "8px",
padding: "15px 20px",
marginTop: "10px",
"@media (max-width: 960px)": {
  width: "80% !important",
},
},

commentReadContentAE: {
fontFamily : "Source Sans Pro",
fontSize : "14px",
fontWeight : 400,
color: "#2B3132",
opacity: "56%"
},

commentFileBtnAE: {
border: "none",
background: "none",
textDecoration: "underline",
color: "#0094A7",
fontFamily : "Source Sans Pro",
fontSize : "16px",
fontWeight : 400,
marginTop : "7px",
textTransform : "capitalize",
marginLeft: "-5px"
},

commentInputAE: {
width : "100%",
height : "125px",
border : "1px #9F9F9F solid",
borderRadius : "8px",
padding : "10px",
fontFamily : "Source Sans Pro",
fontSize : "14px",
fontWeight : 400,
color: "#2B3132",
},

updateSaveCancelBtnAE: {
display: "flex",
alignItems: "center",
justifyContent: "flex-end",
gap: '20px',
marginTop: '20px',
},

uploadFileAE : {
display : "flex",
color: "#0094A7",
alignItems: "center",
marginTop: "10px"
},

attachFileTextAE: {
marginLeft : "10px",
fontFamily : "Source Sans Pro",
fontSize : "16px",
fontWeight : 400,
},

statusInput : {
fontFamily : "Source Sans Pro",
fontSize : "16px",
borderRadius: "8px !important"
},

commentInputBox : {
  marginTop : "20px",
},
gridNew: {
  padding: "16px",
  "@media (max-width: 960px)": {
    width: "0",
  },
}

});
export default withStyles(styles)(RequestAddEdit);
export { RequestAddEdit };
// Customizable Area End

import React from "react";
import { Box, Grid, Snackbar, Hidden } from "@material-ui/core";
import PeopleModuleController, { Props,configJSON } from "./PeopleModuleController.web";
import Identification from "./Components/PeopleModule/Identification.web";
import Audit from '../../audittrail2/src/Audittrail.web';
import PersonalData from "./Components/PeopleModule/PersonalData";
import WorkStep from "./Components/PeopleModule/WorkStep.web";
import CustomList from "../../../components/src/CustomList.web";
import Documentation from "./Components/PeopleModule/Documentation.web";
import PeopleModuleHeader from "./Components/PeopleModule/PeopeModuleHeader.web";
import Navbar from "../../../components/src/NavBar.web";
import Loading from "../../../components/src/Loading/Loading";
import { withAccess } from "../../../components/src/Permission";
import CustomTypographyWeb from "../../../components/src/CustomTypography.web";
import ResponsiveTopBar from "../../../components/src/TopBar/ResponsiveTopBar.web";
import ConnectionWeb from "../../DeepLinking/src/Connection.web";
import { bindingCloseEvent } from "../../../components/src/Utils";
import { MainDiv, ScrollDiv } from "./assets";

export class PeopleModule extends PeopleModuleController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <MainDiv>
      <Grid
        container
        style={{
          backgroundColor: "#FFF",
          overflow: "hidden",
          height: "100%",
        }}
      >
       <Hidden smDown>
        <Grid item xs={12} sm={2}
          style={{
            background: "white",
            height: "100%",
          }}
        >
          <Box
            sx={{
              marginTop: "80px",
              width: "100%",
            }}
          >
            <CustomList
              activeSection={this.state.activeSection}
              sections={this.sections}
              data-testId="list-menu"
              handleListItemClick={this.handleListItemClick}
              disabled={this.state.PeopleId === ''}
            />
          </Box>
        </Grid>
      </Hidden>
        <Hidden mdUp>
          <Grid xs={12}>
            <ResponsiveTopBar navigation={this.props.navigation} title={"People"}>
              { ( { closeDrawer  } ) => 
                <CustomList
                  activeSection={this.state.activeSection}
                  sections={this.sections}
                  data-testId="list-menu"
                  handleListItemClick={bindingCloseEvent(this.handleListItemClick, closeDrawer)}
                  disabled={this.state.PeopleId === ''}
                />
              }
              
            </ResponsiveTopBar>
          </Grid>
        </Hidden>

        <Grid item xs={12} sm={12} md={10} style={{ backgroundColor:"#f3f3f3", height: "100%" }}>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "12px 15px 0px",
              backgroundColor: "#f3f3f3",
              gap: '60px',
              height: "100%",
            }}
          >
           <Hidden smDown>
            <Navbar navigation={this.props.navigation} selectedTab={1}/>
            </Hidden>
            {this.state.isLoading ? <Loading height="70vh" background="white" /> :  <Box style={{ position: "relative", backgroundColor: "#FFF", flex:1}}>
              <PeopleModuleHeader
                peopleId={this.state.PeopleId} 
                navigation={this.props.navigation} 
                saveAsPDF={this.state.PeopleId != ""} 
                handleGoBack={this.handleGoBack}
              />
              <ScrollDiv>
               {this.state.activeSection === configJSON.peopleModuleSections[0] && <Box id="Personal Data">
                  <PersonalData
                    personalData={this.state.personalData}
                    handlePostPersonalData={this.handlePostPersonalData}
                    handleUpdatePersonalData={this.handleUpdatePersonalData}
                    handleSwitchMode={this.handleSwitchMode}
                    handleChangeValue={(val: string, key: string) =>
                      this.handleChangePersonalData(val, key)
                    }
                    onChange={this.handleChange}
                    nationalityArray={this.state.nationalityArray}
                    handlePeopleRequestNavigation={this.handlePeopleRequestNavigation}
                    data-testId="peopleRequestTestID"
                  />
                </Box>}
                {(this.state.activeSection === configJSON.peopleModuleSections[1]) && (
                 this.props.currentUser.defaultRole == "HR" ?
                  <Box id="Identification">
                    <Identification
                      isPersonalDataDone={
                        this.state.personalData.mode === "read"
                      }
                      identification={this.state.identification}
                      handleSwitchMode={this.handleSwitchMode}
                      handleChangeValue={(val: string, key: string) =>
                        this.handleChangeIdentification(val, key)
                      }
                      handlePostIdentificationData={
                        this.handlePostIdentificationData
                      }
                      handleUpdateIdentificationData={
                        this.handleUpdateIdentificationData
                      }
                      handlePeopleRequestNavigation={this.handlePeopleRequestNavigation}
                    />
                  </Box>
                  :
                  <CustomTypographyWeb variant="h6">You don't have permission to access</CustomTypographyWeb>
                )}

                {(this.state.activeSection === configJSON.peopleModuleSections[2]) && (
                  <Box id="Work">
                    <WorkStep
                      isPersonalDataDone={
                        this.state.personalData.mode === "read"
                      }
                      work={this.state.work}
                      handleSwitchMode={this.handleSwitchMode}
                      handleChangeValue={(val: any, key: string) =>
                        this.handleChangeWork(val, key)
                      }
                      handlePostWorkData={this.handlePostWorkData}
                      handleUpdateWorkData={this.handleUpdateWorkData}
                      handleSelectLang={this.handleSelectLang}
                      handleChangeGroupRelation={this.handleChangeGroupRelation}
                      handleShowOtherCurrentPositions={
                        this.handleGetOtherCurrentPositions
                      }
                      handleOpenGroupRelation={this.handleOpenGroupRelation}
                      handlePeopleRequestNavigation={this.handlePeopleRequestNavigation}
                      currentPositionData={this.state.currentPositionData}
                      renderErrors={this.renderErrors}
                      data-testId="currentOtherPositionTestId"
                    />
                  </Box>
                )}

                {(this.state.activeSection === configJSON.peopleModuleSections[3]) && (
                  <Box id="Documents">
                    <Documentation
                      testid={"people-module-documentation"}
                      loggedInUserFullName={this.props.currentUser.fullName}
                      profile_photo={this.state.profile_photo}
                      documents={this.state.documents}
                      ref={this.documentRef}
                      handleSubmitResume={this.handleSubmitResume}
                      onChange={this.handleChange}
                      handleCancelDocumentation={this.handleCancelDocumentation}
                      handleError={this.handleError}
                      handleEditDocument={this.handleEditDocument}
                      editDocumentState={this.state.editIndex}
                      editedName={this.state.editedName}
                      handleEditDocumentName={this.handleEditDocumentName}
                      handleEditDocumentNameApi={this.handleEditDocumentNameApi}
                      handlePeopleRequestNavigation={this.handlePeopleRequestNavigation}
                    />
                  </Box>
                )}
                {this.state.PeopleId && this.state.activeSection === configJSON.peopleModuleSections[4] &&
                  (
                    <ConnectionWeb 
                      id={this.state.PeopleId} 
                      source="people"
                      targets={["project", "tender"]}
                      tabLabels={["Projects", "Tenders"]}
                      schema={{
                        tender: [
                          {
                            dataKey: 'name',
                            label: 'Name',
                            type: 'link',
                            mapValue: (rowData) => (
                              <a
                                  href={`/Tender/${rowData.tender_id}`}
                                  target="_blank"
                                  style={{
                                    fontFamily: "Source Sans Pro",
                                    color: "#0094A7",
                                    textDecoration: "underline",
                                    fontWeight: 400,
                                    width: '200px',
                                    lineHeight: "16px",
                                    whiteSpace: 'nowrap',
                                    fontStyle: "normal",
                                    display: 'inline-block',
                                    fontSize: "16px",
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {rowData.name}
                                </a>
                            )
                          },
                          {
                            dataKey: 'services',
                            label: 'Services',
                          },
                          {
                            dataKey: 'country',
                            label: 'Country',
                          },
                          {
                            label: 'Group company',
                            dataKey: 'group_company'
                          },
                          {
                            label: 'Client',
                            dataKey: 'client'
                          }
                        ],
                        project: [
                          {
                            label: 'Type',
                            dataKey: 'type'
                          },
                          {
                            type: 'link',
                            dataKey: 'name',
                            label: 'Project name',
                            mapValue: (rowData) => (
                              <a
                              target="_blank"
                              style={{
                                fontFamily: "Source Sans Pro",
                                color: "#0094A7",
                                fontWeight: 400,
                                width: '200px',
                                overflow: 'hidden',
                                display: 'inline-block',
                                fontSize: "16px",
                                lineHeight: "16px",
                                textDecoration: "underline",
                                fontStyle: "normal",
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                              }}
                              href={`/Projects/${rowData.project_id}`}
                              >
                                  {rowData.name}
                                </a>
                            )
                          },
                          {
                            dataKey: 'group_company',
                            label: 'Group company',
                          },
                          {
                            dataKey: 'country',
                            label: 'Country',
                          },
                          {
                            label: 'Client',
                            dataKey: 'client',
                          }
                        ],
                        
                      }}
                      navigation={this.props.navigation} 
                      editMode={this.state.documentEdit} 
                      onSuccessSave={this.handleCBSubmitDocument}
                      handleRequestNavigation={this.handlePeopleRequestNavigation}
                      testid="connection" />
                  )
                }

                {this.state.PeopleId && this.state.activeSection === configJSON.peopleModuleSections[5] &&(
                  <Box id="Audit trail">
                    <Audit isAuditTrail={this.state.isAuditTrail} navigation={undefined} id={""} peopleId={this.state.PeopleId} itemType={"people"} />
                  </Box>
                )}
              </ScrollDiv>
              {this.state.hasError && typeof this.state.hasError === 'string' && (
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={!!this.state.hasError}
                  onClose={() => {
                    this.setState({
                      hasError: "",
                    });
                  }}
                  message={this.state.hasError}
                  key="error-message"
                />
              )}
            </Box>}

          </Box>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.deleteError}
          onClose={()=>this.setState({deleteError: false})}
          message="At least one language should be present"
        />
      </Grid>
      </MainDiv>
    );
  }
}

export default withAccess(PeopleModule)
import React from "react";
// Customizable Area Start
import { Box, CircularProgress } from "@material-ui/core";
import { advancedSearchStyling } from "./assets";
import TruncatedTextWithTooltipWeb from "../../../components/src/Chart/TruncatedTextWithTooltip.web";
import { truncateText } from "../../../components/src/Utils";
import {
  CustomAutoCompleteTextField,
  closeIconSvg,
  highlightMatchingCharacters,
  searchIconSvg
} from "../../visualanalytics/src/assets";
// Customizable Area End

import AdvancedSearchController, {
  Props,
  configJSON
} from "./AdvancedSearchController";

export default class ProjectModuleSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAutoCompleteItems = () => {
    const {
      autoCompleteOptions,
      autoCompleteOptionsLength,
      autoCompleteSelectedId
    } = this.state;
    let autoComplete = autoCompleteOptions.slice(0, autoCompleteOptionsLength);

    if (autoCompleteOptions.length > 5 && autoCompleteOptionsLength === 5) {
      autoComplete.push({
        id: configJSON.showMoreResultsId,
        project_name: "Show more"
      });
    }

    if (autoComplete.length > 0) {
      return (
        <Box
          key={configJSON.projectModuleAutoCompleteBoxTestId}
          data-test-id={configJSON.projectModuleAutoCompleteBoxTestId}
          sx={{
            position: "absolute",
            ...advancedSearchStyling.autoCompleteInputBox
          }}
        >
          {autoComplete.map((record: any, index: number) => (
            <div
              key={record.id}
              data-test-id={`${
                configJSON.projectModuleAutoCompleteItemTestId}`}
              style={{
                padding: "10px",
                cursor: "pointer",
                backgroundColor:
                  autoCompleteSelectedId === index ? "#F0F0F0" : "transparent",
                fontSize: '16px',
              }}
              onMouseEnter={() =>
                this.setState({ autoCompleteSelectedId: index })
              }
              onMouseLeave={() => this.setState({ autoCompleteSelectedId: -1 })}
              onClick={() => this.handleSearchById(record.id)}
            >
              <TruncatedTextWithTooltipWeb
                text={highlightMatchingCharacters(
                  this.state.searchQuery,
                  truncateText(record.project_name, 300)
                )}
                maxWidth="95%"
              />
            </div>
          ))}
        </Box>
      );
    }
  };

  autoCompleteEndAdornment = () => {
    const { searchQuery, isLoadingAutoComplete } = this.state;
    if (searchQuery) {
      return (
        <div
          data-test-id={configJSON.clearProjectModuleAutoCompleteResultsTestId}
          style={{
            background: "none",
            boxShadow: "none",
            display:"flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 16px"
          }}
          onClick={this.handleClearAutoCompleteResults}
        >
          {isLoadingAutoComplete ? (
            <CircularProgress
              color="inherit"
              size={"20px"}
              style={{ color: "black" }}
            />
          ) : (
            <img src={closeIconSvg} alt="close" style={{ cursor: "pointer"}} />
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  handleFocus = () => {
    this.setState({ hasFocusOnAutoComplete: true });
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div
        ref={this.state.autocompleteRef}
        style={{
          position: "relative",
          ...advancedSearchStyling.projectModuleAutoCompleteWrapper
        }}
      >
        {/* Customizable Area Start */}
        <CustomAutoCompleteTextField
          data-test-id={configJSON.projectModuleAutoCompleteTextFieldTextId}
          variant="outlined"
          placeholder = {`Search ${this.props.type}`}
          value={this.state.searchQuery}
          onChange={this.handleSearchItem}
          onFocus={this.handleFocus}
          onKeyDown={this.handleActionsOnAutoSearchBar}
          InputProps={{
            startAdornment: this.state.searchQuery === "" && (
              <img src={searchIconSvg} alt="Search Icon" />
            ),
            endAdornment: this.autoCompleteEndAdornment()
          }}
        />
        {this.state.hasFocusOnAutoComplete && this.renderAutoCompleteItems()}
        {/* Customizable End Start */}
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End

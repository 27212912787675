import { Button, ButtonProps, Typography } from '@material-ui/core';
import React, { Component } from 'react';

interface Props extends Omit<ButtonProps, 'onChange'> {
	label: string;
}

type MyProps = Props;
type MyState = {};

export default class CustomSecondaryButton extends Component<MyProps, MyState> {
	// static propTypes = {
	//   // testID: PropTypes.string,
	//   label: PropTypes.string.isRequired,
	//   disabled: PropTypes.bool,
	//   handleClick: PropTypes.func.isRequired,
	// };

	constructor(props: any) {
		super(props);
	}

	render() {
		// const { testID } = this.props;

		return (
			<Button
				variant="contained"
				size="small"
				// id={testID}
				disabled={this.props.disabled}
				style={{
					display: 'flex',
					padding: '10px',
					backgroundColor: 'white',
					border: '1px solid #0094A7',
					margin: '10px',
				}}
				{...this.props}>
				<Typography
					style={{
						color: this.props.disabled ? '#8c8c8c' : '#0094A7' ,
						fontSize: '16px',
						fontWeight: 600,
						lineHeight: '16px',
						textTransform: 'none',
            fontFamily: "Source Sans Pro",
					}}>
					{this.props.label}
				</Typography>
			</Button>
		);
	}
}

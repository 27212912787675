import React from "react";

import {
  Button,
  CircularProgress
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import ProfertSaveAsPdfController, { Props, configJSON } from "./ProfertSaveAsPdfController";

export default class PeopleSaveAsPdf extends ProfertSaveAsPdfController {
  constructor(props: Props) {
    super(props);
    
  }

  

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Button
          data-testId="savePDFBtn"
          variant="outlined"
          style={{
            color: "#FFF",
            border: "1px solid #FFF",
            borderRadius: "8px",
            padding: "8px 20px",
            fontWeight: 600,
            textTransform: 'none'
          }}
          endIcon = {this.state.loading && <CircularProgress size={20} />}
          onClick={() => this.requestGetPDFPeople()}
          
        >
          {configJSON.labelSaveButton}
        </Button>
      </ThemeProvider>
    );
  }
}


import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  Theme,
  Typography,
  createStyles,
  styled,
  withStyles,
} from "@material-ui/core";
import {
  ErrorRounded,
  KeyboardArrowDown,
} from "@material-ui/icons";
import React, { Component } from "react";
import CustomTypographyWeb from "./CustomTypography.web";

interface CustomInputProps extends Omit<SelectProps, "onChange"> {
  testID?: string;
  options: { value: string; label: string }[];
  label: string;
  placeholder: string;
  error?: boolean;
  helperText?: string;
  onChangeValue: (value: string) => void;
  disabled?: boolean;
  width?: string;
  marginBottom?: string;
  size?: "small" | "medium";
  value?: string;
}

type Props = CustomInputProps;
type MyState = {
  isOpen: boolean;
};

export default class CustomSelectMenu extends Component<Props, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    document.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  }
  
  render() {
    const {
      label,
      onChangeValue,
      options,
      placeholder,
      size,
      helperText,
      error,
      value,
    } = this.props;

		return (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					flexDirection: 'column',
					width: '100%',
					minWidth: '120px'
				}}>
				{label && (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'row',
							marginBottom: '7px',
						}}>
						<Typography
							style={{
								color: '#1F1F1F',
								fontStyle: 'normal',
								fontFamily: 'Source Sans Pro',
								fontSize: '16px',
								fontWeight: 600,
								lineHeight: '16px',
							}}>
							{label}
						</Typography>
						{error && (
							<ErrorRounded
								color="error"
								fill="#DC2626"
								style={{
									width: '16px',
									height: '16px',
									marginLeft: "6px"
								}}
							/>
						)}
					</Box>
				)}
				<FormControl error size={size} fullWidth variant='outlined' hiddenLabel >
					<InputLabel shrink={false} hidden disableAnimation disabled style={{ marginTop: "-5px", display: value ? "none" : "block", color: "#9F9F9F" }}>
						<CustomTypographyWeb color='inherit'>
							{placeholder}
						</CustomTypographyWeb>
					</InputLabel>
					<CustomSelect
						fullWidth
						IconComponent={KeyboardArrowDown}
						onChange={(e: React.ChangeEvent<any>) =>
							onChangeValue(e.target.value)
						}
            onOpen={()=>this.setState({isOpen:true})}
            onClose={()=>this.setState({isOpen:false})}
						MenuProps={{
              disableScrollLock: true,
              style: {
                maxHeight: 300,
                display: this.state.isOpen ? "block" : "none",
                width: "50%",
              },
              TransitionProps: {
                timeout: 0,
              },
            }}
            input={<BootstrapInput placeholder="" />}
            inputProps={{
              style: {
                border: error ? "1px solid #DC2626" : "1px solid #9F9F9F",
                width: "100%",
                backgroundColor: "white",
                fontSize: "16px",
                padding: 10,
                fontStyle: "normal",
                fontFamily: "Source Sans Pro",
                fontWeight: 400,
                lineHeight: "16px",
                borderWidth: "1px",
                borderRadius: "4px",
              },
            }}
            {...this.props}
          >
            <MenuItem disabled value="">
              <em>{placeholder}</em>
            </MenuItem>
            {options.map((option, index) => (
              <MenuItem
                key={index}
                value={option.value}
                style={{
                  color: "#2B3132",
                  fontStyle: "normal",
                  fontFamily: "Source Sans Pro",
                  fontSize: "16px",
                  fontWeight: 400,
                  whiteSpace: "pre-wrap",
                  lineHeight: "16px",
                }}
              >
                {this.state.isOpen ? option.label : value}
              </MenuItem>
            ))}
          </CustomSelect>
        </FormControl>
        {error && helperText && (
          <p
            style={{
              fontFamily: "Source Sans Pro",
              margin: "3px 14px 0px 1px",
              textAlign: "left",
              fontWeight: 400,
              lineHeight: 1.66,
              letterSpacing: "0.03em",
              fontSize: "0.75rem",
              color: "#f44336",
            }}
          >
            {helperText}
          </p>
        )}
      </Box>
    );
  }
}

const CustomSelect = styled(Select)({
  height: "45px",
  borderRadius: "8px",
  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0094A7",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#9f9f9f",
  },
});

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    overrides: {
      PrivateNotchedOutline: {
        legend: {
          width: "265px !important",
        },
      },
    },
    input: {
      width: "100%",
      backgroundColor: "white",
      fontSize: "16px",
      padding: 10,
      fontStyle: "normal",
      fontFamily: "Source Sans Pro",
      fontWeight: 400,
      lineHeight: "16px",
      borderWidth: "1px",
      borderRadius: "4px",
      "&:focus": {
        borderRadius: 2,
      },
    },
  })
)(OutlinedInput);

// Customizable Area Start
import React, { Component } from "react";
import { withStyles } from "@material-ui/styles";
import { Button, Typography } from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import SupplierSaveAsPdf from "../../../../saveaspdf/src/ProfertSaveAsPdf.web"
import {styles} from "../../SupplierModuleHeader.web";
// Customizable Area End

// Customizable Area Start
interface Props {
  navigation?: any;
  handleGoBack?: any;
  handleSavePdfClick?: () => void;
  saveAsPDF?: boolean;
  targetId?: string;
  classes: Record<string, string>;
}
// Customizable Area End


class PartnerModuleHeader extends Component<Props> {
  constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End

	}

    // Customizable Area Start
    // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div className={this.props.classes.supplierMainBox}>
        <Button
          onClick={this.props.handleGoBack}
          data-testId="btnBackToPartner"
          className={this.props.classes.headerGoBack}     
        >
          <KeyboardBackspace className={this.props.classes.headerGoBackBtnIcon} id="headerGoBackBtnIconPartner" />
          <Typography  className={this.props.classes.headerGoBackBtnText}>Back</Typography>
        </Button>
        {this.props.saveAsPDF && (
          <SupplierSaveAsPdf module="partner" targetId={this.props.targetId || ''} />
        )}
      </div>
     )
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(PartnerModuleHeader);
export { PartnerModuleHeader };
// Customizable Area End

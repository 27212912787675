Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DeepLinking";
exports.labelBodyText = "DeepLinking Body";
exports.btnExampleTitle = "CLICK ME";
exports.deepLink = "bx_block_deeplinking/deeplinks/dl?id=";

exports.endpoint = "bx_block_deeplinking/connections"

exports.dataSearchSources = {
  'people': '/bx_block_people/search_people',
  'project': '/bx_block_profile/projects/advance_search',
  'tender': '/bx_block_profile/tenders',
  'supplier': '/bx_block_multipageforms2/suppliers',
  'partner': '/bx_block_multipageforms2/partners'
}

exports.mapParams = {
  'people': 'q={{ q }}',
  'project': 'q={{ q }}',
  'tender': 'advance_search={{ q }}',
  'supplier': 'advance_search={{ q }}',
  'partner': 'advance_search={{ q }}'
}

exports.postParams = {
  'people': 'people_id',
  'project': 'project_id',
  'tender': 'tender_id',
  'supplier': 'supplier_id',
  'partner': 'partner_id'
}

// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { getProgressCSVDownload, downloadCSVContent, getProgressPDFDownload, downloadPDFContent, importProjectStatus } from "../../../components/src/Utils"
import { DownloadManager } from "../../../components/src/PollProgressBar/DownloadManager"
import { getStoredDefaultRole } from "../../../components/src/ContextAPI/AdvancedFilterContext.web";

export const configJSON = require("./config");


export interface ValidResponseType {
  data: object;
  errors: Array<ErrorPayloadType>;
}
export interface ErrorPayloadType {
  key: string;
  message: string;
}
export interface ErrorResponseType {
  errors: string;
}
export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}

export interface PeopleExportData {
  message: string,
  export_token: string
}

export interface PeopleExportStatusData {
  status: string,
  progress: number,
  file_type: string
}

export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}

export interface PeopleExportStatusData {
  status: string,
  progress: number,
  file_type: string
}

export interface Props {
  testid: string;
  classes: Record<string, string>;
  searchIds?: number[];
  navigation: any;
  id: string;
  count?: number;
}

interface S {
  open: boolean
  loadingCSV: boolean;
  loadingJson: boolean;
  token: string;
  selectedFile: File | null;
  hasError: string
  isSkipped: boolean
  isFileImported: boolean
  reportMetaData: ReportMetaData | null,
  isShowReport: boolean,
  isLoading: boolean;
  customPaperRef:  React.RefObject<HTMLInputElement>;
  hasImportExportButtonClicked: boolean;
  peopleExportPdfToken: string;
  peopleExportCSVToken: string;
  peopleImportToken: string;
  hasSuccessError: HasSuccessErrorType;
}

interface RecordErrorReport {
  row: number;
  fieldName: string;
  value: null | string;
  error: string
}

interface ReportMetaData {
  lastProcessedRows: number;
  skippedRows: number;
  overriddenRows: number;
  totalRecords: number;
  summary: {
    summaryMessage: string,
    createdRecords: string,
    oveririddenRecords: string
  },
  recordErrors: RecordErrorReport[],
  skippedErrors: RecordErrorReport[],
  importedMessage: string;
  skippedMessage: string;
  notImportMessage: string;
}


interface SS {
  id: any;
}

export default class ProjectImportDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  exportSampleDataCallId: string = "";
  jsonDataApiCallId: string = "";
  importDataCSVCallId: string = "";
  getExportPdfTokenApiCallId : string | null = null;
  getExportCSVTokenApiCallId : string | null = null;
  getImportReportApiCallId: string | null = null;
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess)
    ];

    this.state = {
      selectedFile: null,
      hasError: '',
      open: true,
      isSkipped: true,
      loadingCSV: false,
      loadingJson: false,
      hasImportExportButtonClicked: false,
      isFileImported: false,
      customPaperRef: React.createRef(),
      peopleExportPdfToken:"",
      peopleExportCSVToken:"",
      peopleImportToken: "",
      token: "",
      isLoading: false,
      reportMetaData: null,
      isShowReport: false,
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    super.componentDidMount();
    const { status, importToken } = this.props.navigation.history.location?.state || {};

    if (status == 'success') {
      this.setState({
        isShowReport: true
      }, () => {
        this.getImportLogReport(importToken)
      })
    }
    
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      if (this.importDataCSVCallId !== "" && this.importDataCSVCallId === apiRequestCallId) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.handleImportDataCSVResponse(responseJson)
      }

    } 
     
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (this.isValidResponseController(responseJson)) {
          this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
        }
      } 
    }
  }


  toggleOpen = (isOpen: boolean) => {
    this.setState({ open: isOpen });
  };

  projectBodyItem = (projectCheckData: Array<string>, filterType: string) => {
    
  };

  handleGoBack = () => {
    this.props.navigation.navigate("ProjectModuleTable")
  };


  handleError = (error: string) => {
    this.setState({
      hasError: error
    })
  }

  showFavourites = (checked: boolean, filterType: string) => {
    
  };

  handleImportFile = (file: File, isSkip: boolean) => {
    this.setState({
      isLoading: true
    })
    const formData = new FormData();
    const method = isSkip ? 'skip' : 'override'

    if (file instanceof File) {
      formData.append('file', file)
    } else {
      this.setState({
        hasError: 'File not selected!'
      })
      return false;
    }

    formData.append("activity_type", "project");
    formData.append("method", method);

    const headeRequest = {
      token:
        typeof window !== 'undefined'
          ? localStorage.getItem('authToken')
          : null
    };

    const apiRequestImport = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.importDataCSVCallId = apiRequestImport.messageId;

    apiRequestImport.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_data_import_export/export/import_csv?role=${getStoredDefaultRole()}`,
    );

    apiRequestImport.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headeRequest)
    );

    apiRequestImport.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    apiRequestImport.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(apiRequestImport.id, apiRequestImport);
    return true;
  }

  isValidResponseController = (responseJson: ValidResponseType) => {
    return responseJson && !responseJson.errors;
  };

  apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: PeopleExportData & PeopleExportStatusData) => {
    if (apiRequestCallId === this.getExportPdfTokenApiCallId) {
      this.exportPdfTokenSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getExportCSVTokenApiCallId) {
      this.exportCSVTokenSuccessCallBack(responseJson)
    }

    if (apiRequestCallId == this.getImportReportApiCallId) {
      this.importProjectSuccessCallBack(responseJson)
    }
  };


  handleImportDataCSVResponse = async (responseJson: any) => {

    if (!responseJson?.import_token) {
      this.setState({
        hasError: responseJson?.message || responseJson.error || "Unexpected error"
      })
      return;
    }

    const importToken = responseJson.import_token;
    const userToken = (await localStorage.getItem("authToken")) || "";

    const mapProgressDownload = async (exportToken: string, userToken: string) => {
      const progessResponse = await importProjectStatus(exportToken, userToken, "project");
  
      if (progessResponse?.progress == 100) {
        this.setState({
          isShowReport: true
        }, () => {
          this.getImportLogReport(importToken)
        })
      }

      return progessResponse?.progress
  
    };

    this.setState({peopleImportToken: importToken},()=>{
      DownloadManager.setup(importToken, {
        trackingToken: importToken,
        exportMessage: "Importing project data",
        ignoreShownPathname: "/ProjectImportData",
        outputFileConfig: {
          filename: '',
          contentType: ''
        },
        onDownloadSucess: (importToken: string) => this.navigateImportSuccessPage(importToken),
        trackingDownloadProgressSync: (token) => mapProgressDownload(importToken, userToken),
        finishTrackingDownloadSync: null
      })

      DownloadManager.startDownload(importToken)
    })
  }

  apiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    let { contentType, method, endPoint, body } = valueData;
    const token = (await localStorage.getItem("authToken")) || "";
    let headerAuth = {
      "Content-Type": contentType,
      token,
    };
    let requestMessageAPI = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageAPI.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageAPI.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessageAPI.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessageAPI.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerAuth)
    );
    runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
    return requestMessageAPI.messageId;
  };

  getImportLogReport = async (importToken: string) => {
    this.getImportReportApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.importPeopleCSVEndpoint + `?import_token=${importToken}`,
    });
  }

  handleImportExportButtonClick = (event:any) => {
     this.setState({hasImportExportButtonClicked : !this.state.hasImportExportButtonClicked})
  };

  navigateImportPage = (state: any) => {
    this.props.navigation.navigate("ProjectImportData");
  };

  navigateImportSuccessPage = (importToken: string) => {
    const projectPathName = "/ProjectImportData";

    if (location.pathname == projectPathName) {
      // set state instead
      this.setState({
        isShowReport: true
      }, () => {
        this.getImportLogReport(importToken)
      })
    }  else {
      this.props.navigation.history.push({
        pathname: projectPathName,
        state: { status: 'success', importToken }
      })
    }
    
  }


  handleExportPdfToken = async () =>{
    const searchIds = this.props.searchIds || [];
    const idsQuery = searchIds.map(id => `ids[]=${id}`).join('&');
    const exportQuery = searchIds?.length > 0 ? `?activity_type=project&${idsQuery}` : "?activity_type=project"
    
    this.getExportPdfTokenApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.exportPdfTokenEndpoint + exportQuery,
    });
  }

  exportPdfTokenSuccessCallBack = async (responseJson : PeopleExportData) =>{
    const exportToken = responseJson.export_token;
    const { count } = this.props;
    if(!!exportToken && count && count > 100){
      this.setState({
        hasError: configJSON.pdfEmailMessage
      })
      return
    }

    const userToken = (await localStorage.getItem("authToken")) || "";

    const mapProgressDownload = async (exportToken: string, userToken: string) => {
      const progessResponse = await getProgressPDFDownload(exportToken, userToken, "project");
      
      return progessResponse?.progress
  
    };

    const mapLinkDownload = async (exportToken: string, userToken: string) => {
      const downloadResonse = await downloadPDFContent(exportToken, userToken)

      return downloadResonse?.link
    }

    this.setState({peopleExportPdfToken: exportToken},()=>{
      DownloadManager.setup(exportToken, {
        trackingToken: exportToken,
        exportMessage: "Exporting project as pdf",
        ignoreShownPathname: "/ProjectImportData",
        outputFileConfig: {
          filename: 'exportproject.pdf',
          contentType: 'application/pdf;charset=utf-8'
        },
        trackingDownloadProgressSync: (token) => mapProgressDownload(exportToken, userToken),
        finishTrackingDownloadSync: (token) => mapLinkDownload(exportToken, userToken)
      })
      DownloadManager.startDownload(exportToken);

    })
  }


  handleExportCSVToken = async () =>{
    const searchIds = this.props.searchIds || [];
    const idsQuery = searchIds.map(id => `ids[]=${id}`).join('&');
    const exportQuery = searchIds?.length > 0 ? `?activity_type=project&${idsQuery}` : "?activity_type=project"

    this.getExportCSVTokenApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.exportCSVTokenEndpoint  + exportQuery,
    });
  }

  exportCSVTokenSuccessCallBack = async (responseJson : PeopleExportData) =>{
    const exportToken = responseJson.export_token;
    const userToken = (await localStorage.getItem("authToken")) || "";
    
    const mapProgressDownload = async (exportToken: string, userToken: string) => {
      const progessResponse = await getProgressCSVDownload(exportToken, userToken);
  
      return progessResponse?.progress
  
    };

    const mapLinkDownload = async (exportToken: string, userToken: string) => {
      const downloadResonse = await downloadCSVContent(exportToken, userToken, "project")
      return downloadResonse?.link
    }

    this.setState({peopleExportCSVToken: exportToken}, ()=>{
      DownloadManager.setup(exportToken, {
        trackingToken: exportToken,
        exportMessage: "Exporting project as csv",
        ignoreShownPathname: "/ProjectImportData",
        outputFileConfig: {
          filename: 'exportcsv.csv',
          contentType: 'text/csv;charset=utf-8'
        },
        trackingDownloadProgressSync: (token) => mapProgressDownload(exportToken, userToken),
        finishTrackingDownloadSync: (token) => mapLinkDownload(exportToken, userToken)
      })
      DownloadManager.startDownload(exportToken);
    })
  }

  importProjectSuccessCallBack = (responseJson: any) => {
    try {
      const metaData = responseJson?.data.attributes; 

      this.setState({
        reportMetaData: {
          totalRecords: metaData.total_record,
          lastProcessedRows: metaData.last_processed_rows,
          skippedRows: metaData.skip_errors?.map((row: any) => ({
            row: row.row,
            fieldName: row.field_name,
            value: row.value,
            error: row.error
          })),
          overriddenRows: metaData.overridden_rows,
          skippedErrors: metaData.skip_errors?.map((row: any) => ({
            row: row.row,
            fieldName: row.value,
            value: row.value,
            error: row.error
          })),
          recordErrors: metaData.record_errors?.map((row: any) => ({
            row: row.row,
            fieldName: row.field_name,
            value: row.value,
            error: row.error
          })),
          summary: {
            summaryMessage: metaData.summary.summary_message,
            createdRecords: metaData.summary.created_record,
            oveririddenRecords: metaData.summary.overridden_record
          },
          importedMessage: metaData.imported_message,
          skippedMessage: metaData.skipped_message,
          notImportMessage: metaData.not_imported_message
        },
      })
    } catch(err) {
      console.log("parse error", err)
    }
    
  }

  resetImport () {
    this.setState({
      isShowReport: false,
      token: ''
    })
  }

  handleMouseLeave = () => {
    this.setState({
      hasImportExportButtonClicked: false
    })
  }

}

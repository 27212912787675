import React from "react";

import {
  Box,
  Button,
  Grid,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Snackbar,
  Link,
  // Customizable Area Start
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  withStyles,
  createStyles,
  TableBody,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Alert from '@material-ui/lab/Alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Customizable Area End

import ImportExportDataController, {
  PeopleImportLogs,
  PeopleSkipLogs,
  Props, configJSON,
} from "./ImportExportDataController.web";
import PeopleModuleHeader from "../../multipageforms/src/Components/PeopleModule/PeopeModuleHeader.web";
import { successImage, uploadIconSvg } from "./assets";
import CustomTypographyWeb from "../../../components/src/CustomTypography.web";
import { CloudUpload } from "@material-ui/icons";
import CustomRadioButton from "../../../components/src/CustomRadioButton.web";
import CustomButton from "../../../components/src/CustomButton.web";

class ImportExportData extends ImportExportDataController {
  fileInputRef = React.createRef<HTMLInputElement>();
  uploadBoxRef = React.createRef<HTMLInputElement>();
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleButtonClick = (fileInputRef: any) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  handleDragOver = (e: any, uploadBoxRef: any) => {
    e.preventDefault();
    if (uploadBoxRef.current) {
      uploadBoxRef.current.style.backgroundColor = "#E5E5E5";
    }
  };

  handleDragLeave = (e: any, uploadBoxRef: any) => {
    e.preventDefault();
    if (uploadBoxRef.current) {
      uploadBoxRef.current.style.backgroundColor = "#FFF";
    }
  };

  handleDrop = (e: any, uploadBoxRef: any) => {
    e.preventDefault();

    const file = e.dataTransfer.files[0];
    if (this.isValidFiles(file)) {
      this.setState({ selectedFile: file ,showValidImportFile: false});
    } else {
      this.setState({showValidImportFile: true})
    }

    if (uploadBoxRef.current) {
      uploadBoxRef.current.style.backgroundColor = "#FFF";
    }
  };
  // Customizable Area End

  // Customizable Area Start
  renderImportProgressBar() {
        const { classes } = this.props
        return (<>
           {this.state.hasProgressData.isOpen && (
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.hasProgressData.isOpen} onClose={this.handleCloseProgressSnack} >
                  <Alert icon={false} onClose={this.handleCloseProgressSnack} >
                    <Box className={classes.exportBox} >
                    <Box position="relative" display="inline-flex" className={classes.progressBox}>
                      <CircularProgress variant="determinate" color="inherit" value={this.state.hasProgressData.progressValue} />
                       <Box className = {classes.exportProgressBar}>
                      <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(this.state.hasProgressData.progressValue)}%`}</Typography>
                       </Box>
                       </Box>
                   <Box className = {classes.importStatus}> Importing people data </Box>
                    </Box> </Alert>
            </Snackbar>
          )}
        </>)}
  // Customizable Area End

  // Customizable Area Start
   renderPeopleSuccessImport = () => {
        const { classes } = this.props
         const { peopleRecordLogs, peopleSkipLogs, peopleSummary, peopleImportMessage, peopleSkipMessage, notImportMessage } = this.state
        return (
            <Box marginTop={2} className={classes.containerSucess} paddingBottom={2}>
          <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={10} md={8} lg={7}>
               <Box className={classes.sucessBox} marginTop={2}>
               <Alert severity="success" className={classes.alertBox}>
                { peopleSummary.summaryMessage }</Alert>
              <Box className={classes.listErrorBox} marginTop={2}>
                <Accordion key={`report_summary`} style={{width: '100%'}}  className={classes.customAccordion}>
                  <AccordionSummary
                    style={{borderBottom: "1px solid #f3f3f3"}}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant='body1' className={classes.headingReport}>{ peopleImportMessage }</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List dense={true} className={classes.listDetailReport}>
                        <ListItem key={`error_report_1`}>
                          <ListItemText primary={ peopleSummary.createdRecord } />
                        </ListItem>
                        <ListItem key={`error_report_2`}>
                          <ListItemText primary={ peopleSummary.overriddenRecord} />
                        </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>
                <Accordion key={`report_skip`} style={{width: '100%'}}  className={classes.customAccordion}>
                  <AccordionSummary
                    style={{borderBottom: "1px solid #f3f3f3"}}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant='body1' className={classes.headingReport}> { peopleSkipMessage } </Typography>
                  </AccordionSummary>
                    <AccordionDetails>
                    <TableContainer >
                      <Table className={classes.table} size="small" >
                       <TableHead>
                          <TableRow>
                            <TableCell>Row Number</TableCell>
                            <TableCell>People Name</TableCell>
                            <TableCell>Error Message</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                              {peopleSkipLogs.map((item: PeopleSkipLogs , index: number) => {
                                const { row: rowNum, value, error } = item;
                                return (
                                  <TableRow key={`row_${index}`}>
                                    <TableCell component="th">
                                      Row {rowNum}
                                    </TableCell>
                                    <TableCell>{value || "Nil"}</TableCell>
                                    <TableCell>{error}</TableCell>
                                  </TableRow>
                                )})}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
                <Accordion key={`report_override`} style={{width: '100%'}}  className={classes.customAccordion}>
                  <AccordionSummary
                    style={{borderBottom: "1px solid #f3f3f3"}}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant='body1' className={classes.headingReport}> {notImportMessage} </Typography>
                  </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer >
                        <Table className={classes.table} size="small" >
                        <TableHead>
                            <TableRow>
                              <TableCell>Row Number</TableCell>
                              <TableCell>People Name</TableCell>
                              <TableCell>Field Name</TableCell>
                              <TableCell>Error Message</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {peopleRecordLogs.map((item : PeopleImportLogs, index: number) =>{
                            const { row: rowNum, value, field_name, error } = item;
                              return(
                              <TableRow key={`row_${index}`}>
                                <TableCell component="th">
                                  Row {rowNum}
                                </TableCell>
                                <TableCell>{value || "Nil"}</TableCell>
                                <TableCell>{field_name}</TableCell>
                                <TableCell>{error}</TableCell>
                              </TableRow>
                              )}
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </Box>          
              <CustomButton style={{ marginTop: 16 }} data-testid="backButtonTestId" onClick={this.handleImportBack}>Add Another</CustomButton>
            </Box>
            </Grid>
            </Grid>
              </Box> )}
           // Customizable Area End

  // Customizable Area Start
     renderPeopleImport = () =>{
      const { classes } = this.props
      return (<>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={11} sm={8} md={6} lg={6}>
            <div
              data-testid="upload-box"
              onDragOver={(e) => {
                this.handleDragOver(e, this.uploadBoxRef);
              }}
              onDragLeave={(e) => {
                this.handleDragLeave(e, this.uploadBoxRef);
              }}
              onDrop={(e) => {
                this.handleDrop(e, this.uploadBoxRef);
              }}
              ref={this.uploadBoxRef}
            >
              <Box className={classes.uploadFileBox}>
                <Box
                  style={{
                    backgroundColor: "#FFF",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  sx={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                  }}
                >
                  <img src={uploadIconSvg} alt="upload" />
                </Box>

                <CustomTypographyWeb isLabel>
                  UPLOAD A FILE
                </CustomTypographyWeb>
                <input
                  data-testid="import-data-input"
                  ref={this.fileInputRef}
                  type="file"
                  hidden
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={this.handleFileChange}
                  name="ImportData"
                />
                <Button
                  data-testid="importdata-upload-button"
                  component="label"
                  variant="contained"
                  endIcon={<CloudUpload />}
                  href="#file-import-people"
                  style={{
                    backgroundColor: "#0094A7",
                    color: "white",
                    textTransform: "none",
                    padding: '8px 16px'
                  }}
                  onClick={() => {
                    this.handleButtonClick(this.fileInputRef);
                  }}
                  disabled={this.state.isLoading}
                >
                  {this.state.selectedFile &&
                    this.state.selectedFile instanceof File ? (
                    <CustomTypographyWeb
                      data-testid="import-data-imported-file-name"
                      style={{ textDecoration: "underline" }}
                    >
                      {this.truncateText(this.state.selectedFile.name, 32)}
                    </CustomTypographyWeb>
                  ) : (
                    <CustomTypographyWeb isLabel style={{
                      color: '#FFF',
                    }}>Choose file</CustomTypographyWeb>
                  )}
                </Button>

                {this.state.showValidImportFile &&
                  <Box className={classes.validImportmessage}>{configJSON.errorForBigValidFileUpload}</Box>
                }

                <Box className={classes.uploadFileBoxInfo}>
                  <CustomTypographyWeb
                    isContent
                    style={{
                      fontSize: "14px",
                      color: "#9F9F9F",
                      fontWeight: 600,
                    }}
                  >
                    Max file size 5 MB
                  </CustomTypographyWeb>
                  <div
                    style={{
                      width: "1px",
                      height: "20px",
                      background: "#9F9F9F",
                    }}
                  />
                  <CustomTypographyWeb
                    isContent
                    style={{
                      fontSize: "14px",
                      color: "#9F9F9F",
                      fontWeight: 600,
                    }}
                  >
                    File format CSV or XLSX
                  </CustomTypographyWeb>
                </Box>
              </Box>
            </div>
            <CustomTypographyWeb
              className={classes.uploadFileBoxInfoText}
            >
              Download a{" "}
              <span style={{ color: "#0094A7" }}>
                <Link data-test-id={configJSON.sampleCSVlinkTestId} onClick={this.exportSampleCSVData} color="inherit" style={{ cursor: 'pointer' }}>
                  sample CSV
                </Link>
              </span> file or <span style={{ color: "#0094A7" }}>
                <Link data-test-id={configJSON.sampleXLSlinkTestId} onClick={this.exportSampleXLSData} color="inherit" style={{ cursor: 'pointer' }}>
                  sample XLSX
                </Link>
              </span> file and complete it to your import file to ensure you have the file
              perfect for the import
            </CustomTypographyWeb>

            <Grid container style={{ marginTop: "30px" }} spacing={4}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <CustomTypographyWeb
                  style={{
                    color: "#DC2626",
                    fontSize: "18px",
                    lineHeight: "24px",
                    fontWeight: 600
                  }}
                >
                  Duplicate Handling:*
                </CustomTypographyWeb>
              </Grid>
              <Grid item xs={11} sm={8} md={8} lg={8}>
                <Box className={classes.importradioBtnbox} >
                  <FormControl component="fieldset">
                    <RadioGroup>
                      <FormControlLabel
                        control={
                          <CustomRadioButton
                            checked={this.state.isSkipped}
                            onChange={() => {
                              this.setState({
                                isSkipped: true,
                              });
                            }}
                          />
                        }
                        data-testid="importSkipButtonTestId"
                        onClick={this.handlePeopleImportSkip}
                        label="Skip"
                      />
                      <CustomTypographyWeb className={classes.importRadioBtnfirst}>
                       If an imported file includes a record with a duplicate entry based on a unique field (like ID or email),
                         the system will skip importing that record.
                      </CustomTypographyWeb>

                      <FormControlLabel
                        control={
                          <CustomRadioButton 
                            checked={!this.state.isSkipped}
                            onChange={() => {
                              this.setState({
                                isSkipped: false,
                              });
                            }}
                          />
                        }
                        data-testid="importOverrideButtonTestId"
                        onClick={this.handlePeopleImportOverride}
                        label="Override"
                      />
                      <CustomTypographyWeb className={classes.importRadioBtnSecond} >
                        If an imported file includes a record with a duplicate entry based on a unique field (like ID or email), 
                        the system will override the existing entry with the new imported record.
                      </CustomTypographyWeb>
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />

        <Box className={classes.importPeopleBtnBox} >
          <CustomButton
            data-testid="import-data-cancel-button"
            colorVariant="special"
            onClick={() => window.history.back()}
            disabled={this.state.isLoading}
          >
            Cancel
          </CustomButton>
          <CustomButton
            data-testid="importasCSVTestId"
            onClick={this.handlePeopleImportData}
            disabled={
              (this.state.selectedFile instanceof File ? false : true) || this.state.isLoading
            }
          >
            Import
          </CustomButton>
        </Box>                 

        {this.renderImportProgressBar()}
        {this.state.hasError && typeof this.state.hasError === "string" && (
          <Snackbar
            data-testid="import-data-error-snack"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={!!this.state.hasError}
            onClose={() => {
              this.setState({ hasError: "" });
            }}
            message={this.state.hasError}
            key="error-message"
          >
            <Alert data-testId="snack-error" severity="error">{ this.state.hasError }</Alert>
          </Snackbar>

          
        )}
      </>)
     }
  // Customizable Area End

  render() {
    const { classes } = this.props
    return (
      // Customizable Area Start
      <>
      <Box className={classes.container} data-testid={this.props.testid}>
         <PeopleModuleHeader saveAsPDF={false} peopleId="" handleGoBack = {this.handleGoBack}/>
        {this.state.showImportReport ? (
          this.renderPeopleSuccessImport()
         ) : (
          this.renderPeopleImport()
        )}  
      </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles =
  createStyles({
  container: {
    width: "100%",
    marginTop: "20px",
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "column",
    gap: "25px",
  },
  uploadFileBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    borderRadius: "8px",
    border: "1px solid rgba(0, 148, 167, 0.40)",
    background: "rgba(0, 148, 167, 0.08)",
    padding: "40px",
  },
  uploadFileBoxInfo: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    marginBottom: "20px",
    alignItems: "center",
  },
  uploadFileBoxInfoText: {
    fontFamily: "Source Sans Pro",
    color: "#6F6F70",
    fontSize: "16px",
    lineHeight: "24px",
    fontStyle: 'normal',
    fontWeight: 600,
    marginTop: "20px",
  },

  progressBox: {
    marginRight: "10px"
  },

  exportBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },

  exportProgressBar: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  importStatus: {
    textTransform: "capitalize"
  },

  importImageBox: {
    width : "88px",
    height : "88px"
  },

  importsuccesstext: {
    fontFamily: "Source Sans Pro",
    color: "#000",
    fontSize:"16px",
    fontWeight:400,
    lineHeight:"16px",
    letterSpacing:"0.5px"
  },

  accordianheading:{
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Source Sans Pro",
  },

  containerSucess: {
    backgroundColor: "#FFF",
    width: "100%"
  },

  sucessBox: {
    background: "rgba(0, 148, 167, 0.08)",
    padding: "40px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 24
  },

  reportText: {
    marginBottom: '24px',
    fontWeight: 600,
    color: '#0094a7',
    textTransform: 'uppercase'
  },

  peopleSkipBox: {
     width:"100%",
     padding: "12px",
     display:"flex",
     justifyContent: "space-around", 
  },

  reportSingleBox: {
    padding: '12px',
    width: '100%',
    margin: '8px 0',
  },

  rowErrorText: {
    textAlign: 'left',
    width: '100%',
    marginLeft: -20,
    color: "#4c4c4c",
    marginBottom: '10px'
  },
  
  listErrorBox: {
    width: '100%',
  },

  customAccordion: {
    width: "100%",
    '& .MuiAccordion-root.Mui-expanded': {
      margin: 0
    }
  },

  headingReport: {
    marginLeft: '8px',
    fontSize: 16,
    color: "#6a6e6f",
    "& span": {
      fontWeight: 600,
      color: "#f50057",
      marginLeft: 4
    }
  },

  listDetailReport: {
    "& .MuiTypography-body2": {
      color: "#6a6e6f",
    }
  },

  validImportmessage: {
    fontSize: "14px",
    color: "#f44336",
    fontFamily: "Source Sans Pro"
  },

  importradioBtnbox:{
    display: "flex",
    flexDirection: "column",
  },

  importRadioBtnfirst:{
    color: "#6F6F70",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    marginBottom: "28px"
  },

  importRadioBtnSecond:{
    color: "#6F6F70",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
  },

  importPeopleBtnBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    gap: "30px",
    marginBottom: "25px",
    marginRight: "40px",
    "@media (max-width: 767px)": {
      marginRight: "10px",
      gap: "15px",
    },
  }


})
// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(ImportExportData)
export { ImportExportData }
// Customizable Area End

// Customizable Area Start
import React, { Component } from "react";
import { Button, Typography } from "@material-ui/core";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import { createStyles, withStyles } from "@material-ui/styles";
import SupplierSaveAsPdf from "../../saveaspdf/src/ProfertSaveAsPdf.web"


interface HeaderProps {
  saveAsPDF?: boolean;
  navigation?: any;
  handleGoBack?: any;
  targetId?: string;
  handleSavePdfClick?: () => void;
  classes: Record<string, string>;
}
// Customizable Area End


class SupplierModuleHeader extends Component<HeaderProps> {
 // Customizable Area Start
 render() {
   return (
     <div className={this.props.classes.supplierMainBox}>
       <Button
         onClick={this.props.handleGoBack}
         data-testId="btnBackToPeople"
         className={this.props.classes.headerGoBack}     
       >
         <KeyboardBackspace className={this.props.classes.headerGoBackBtnIcon} />
         <Typography  className={this.props.classes.headerGoBackBtnText}>Back</Typography>
       </Button>
       {this.props.saveAsPDF && (
        <SupplierSaveAsPdf module="supplier" targetId={this.props.targetId || ''} />
       )}
     </div>
   );
 }
  // Customizable Area End
}

// Customizable Area Start
export const styles = 
  createStyles({
    supplierMainBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "16px 34px 16px 24px",
      backgroundColor: "#0094A7",
      color: "#FFF",
      zIndex: 1000,
    },

    headerGoBack: {
      alignItems: "center",
      display: "flex",
      color: "#FFF",
      gap: "3px",
    },
     
    headerGoBackBtnIcon:{
      height: "24px",
      width: "24px"
    },
    
    headerGoBackBtnText: {
      fontFamily: "Source Sans Pro",
      fontStyle: "normal",
      fontSize: "20px",
      textTransform: 'none',
      fontWeight: 600,
      lineHeight: "16px",
      marginLeft: '8px'
    },

    headerSaveasPdfBtn: {
      border: "1px solid #FFF",
      padding: "10px",
      color: "#FFF",
    }

  })
// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(SupplierModuleHeader);
export { SupplierModuleHeader };
// Customizable Area End


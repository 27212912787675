import React from "react";
import { Checkbox, TextField, createStyles, withStyles } from "@material-ui/core";


export const favEmpty = require('../assets/fav_empty.png');
export const favFilled = require('../assets/fav_filled.png');
export const closeIconSvg = require('../assets/close_icon.svg')
export const importExportIconSvg = require('../assets/import_export_icon.svg')
export const searchIconSvg = require('../assets/search_icon.svg')
export const checkboxIcon = require('../assets/checkbox_icon.png')
export const notifyCheckedIcon = require("../assets/NotifyCheckedIcon.svg");

export const projectModuleTableCommonStyle = {
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f3f3f3",
  },
  importExportButton: {
    width: "170px",
    backgroundColor: "#0094A7",
    color: "white",
    borderRadius: '8px',
  },
  autoCompleteInputBox:  { 
    marginTop: "8px", 
    width: "40%", 
    zIndex: 3, 
    backgroundColor: "#fff", 
    borderRadius: "8px", 
    boxShadow: "8px 8px 16px 0px rgba(0, 148, 167, 0.16)"
  },
  saveSearchBox: {
    width: '30vw',
    flexShrink: 0,
    borderRadius: '16px',
    background: '#FFF',
    color: '#000',
    zIndex: 1000,
    padding: '34px 24px',
    "@media (max-width: 960px)": {
      width: "auto",
    },
  }
}

const styles = createStyles({
  root: {
    position: "relative",
    borderRadius: '8px',
    backgroundColor: "white",
    fontSize: '16px',
    "& fieldset": {
      border: "1px solid #E8E8E8 !important"
    },
    "&:hover": {
      backgroundColor: "white",
    },
    marginLeft: 0,
    width: "40%",
    "@media (max-width: 960px)": {
      width: "100%",
    },
    "& .MuiOutlinedInput-adornedStart": {
      // Any specific styles if needed
    },
    "& .MuiOutlinedInput-input": {
      padding: '14px 0 14px 14px'
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px'
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: '0px'
    }
  }
});
export const CustomAutoCompleteTextField = withStyles(styles)(TextField)

export const highlightMatchingCharacters = (searchQuery:string, name: string) => {
  const parts = name.split(new RegExp(`(${searchQuery})`, "gi"));
  return parts.map((part, index) =>
    part.toLowerCase() === searchQuery.toLowerCase() ? (
      <span key={index} style={{ fontWeight: "bold" }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};


export const SaveSearchCheckBox = withStyles({
  root: {
    color: '#0094A7',
    '&$checked': {
      color: '#0094A7',
      cursor: "default",
    },
    '& :hover':{
      opacity: 0.16,
      backgroundColor: ' #0094A7'
    },
  },
  checked: {},
})(Checkbox);

export const saveSearchModal = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1000,
} as React.CSSProperties

export const overlayStyle: React.CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgba(31, 31, 31, 0.40)',
  backdropFilter: 'blur(8px)',
  zIndex: 9999999,
};

export const CustomSaveSearchInput = withStyles((theme: any) => ({
  root: {
    "& .MuiInputBase-input": {
      padding: "12px",
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#2B3132", // Ensure this color is visible against white background
      lineHeight: "16px",
      "&::placeholder": {
        color: "#9F9F9F", 
        opacity: 1 
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      "& fieldset": {
        border: "1px solid #9F9F9F",
      },
      "&:hover fieldset": {
        border: "1px solid #9F9F9F",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #0094A7",
      },
      "& .error": {
        border: "1px solid #DC2626",
      },
    },
    "& .MuiFormHelperText-root": {
      fontFamily: "Source Sans Pro",
      margin: "5px 0 0 5px",
      textAlign: "left",
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: "0.03em",
      fontSize: "0.75rem",
      color: "#f44336",
    },
  },
}))(TextField);
import React, { useState } from "react";
import { Card, CardMedia } from "@material-ui/core";
import CustomButton from "../CustomButton.web";

interface ImageCardProps {
  imageUrl: string;
  payload: (imageUrl: string) => void;
}

const ImageCard: React.FC<ImageCardProps> = ({
  imageUrl,
  payload,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <Card
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ position: "relative", maxWidth: "150px", borderRadius: "8px" }}
      >
        <CardMedia
          component="img"
          src={imageUrl}
          style={{
            height: "150px",
            width: "150px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            borderRadius: "8px",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0, 0, 0, 0.4)", // Semi-transparent background color
            opacity: isHovered ? 1 : 0, // Initially invisible
            transition: "opacity 0.3s ease-in-out", // Smooth transition for opacity
          }}
        >
          <CustomButton
            colorVariant="special"
            style={{
              color: "#FFF",
              background: "transparent",
              border: "1.5px solid #FFF",
            }}
            onClick={() => {
              payload(imageUrl);
            }}
          >
            View
          </CustomButton>
        </div>
      </Card>
    </>
  );
};

export default ImageCard;

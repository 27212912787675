import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import {
  buildUrlParams,
  prepareProjectModuleTableResult,
} from "../../../components/src/Utils";
import { favEmpty, favFilled } from "./assets";
import { NavbarSections } from "../../../components/src/WebAppLayout/config";
import { ProjectExportData } from "../../importexportdata/src/ProjectExportData.web";
import { FilterListType } from "./PeopleDataTableController";
export interface ErrorObject {
  [key: string]: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  filtersObj: { [key: string]: string[] | boolean | string };
  hasError: string | null;
  isSavingSearch: boolean;
  isProjectSearchSaved: boolean;
  onSaveSearchError: boolean;
  onSaveSearchErrorMessage: string;
  savingSearchProjectName: string;
  isLoading: boolean;
  searchQuery: string;
  tableInputs: {
    apiData: any;
    colSpanList: any;
    columsList: any;
    columnSep: any;
  };
  paginationDetails: {
    currentPage: number;
    rowsPerPage: number;
    totalLength: number;
    rowsPerPageOptions: number[];
  };
  open: boolean;
  sortOrder: string;
  currentSortColumn: string;
  paperRef: React.RefObject<HTMLInputElement>;
  hasImportExportClicked: boolean;
  hoveredImportExportItem: string;
  searchIds: number[];
  isShowProjectSaveSearchLabel: boolean | undefined;
  isResetProjectFilters: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ProjectModuleTableController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiForGetProjects: string = "";
  apiForFavouriteProject: string = "";
  apiForSaveProjectSearch: string = "";
  refExportBox = React.createRef<ProjectExportData>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      filtersObj: {},
      hasError: null,
      isSavingSearch: false,
      isProjectSearchSaved: false,
      onSaveSearchError: false,
      onSaveSearchErrorMessage: "",
      savingSearchProjectName: "",
      isLoading: true,
      searchQuery: "",
      tableInputs: {
        apiData: [],
        colSpanList: [],
        columsList: [],
        columnSep: [],
      },
      paginationDetails: {
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 75, 100],
        currentPage: 0,
        totalLength: 0,
      },
      open: true,
      sortOrder: "",
      currentSortColumn: "",
      paperRef: React.createRef(),
      hasImportExportClicked: false,
      hoveredImportExportItem: "",
      searchIds: [],
      isShowProjectSaveSearchLabel: false,
      isResetProjectFilters: false,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.getProjectsRequest();
    localStorage.removeItem("projects");
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (
        responseJson &&
        responseJson.errors &&
        Array.isArray(responseJson.errors) &&
        responseJson.errors.length > 0 &&
        responseJson.errors[0].token
      ) {
        localStorage.removeItem("authToken");
        this.props.navigation.navigate("LoginBlock");
        return;
      }

      if (apiRequestCallId === this.apiForGetProjects)
        this.getProjectsResponse(responseJson);
      if (apiRequestCallId === this.apiForFavouriteProject)
        this.favouriteProjectResponse(responseJson);
      if (apiRequestCallId === this.apiForSaveProjectSearch)
        this.saveProjectSearchResponse(responseJson);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      JSON.stringify(prevState.filtersObj) !==
      JSON.stringify(this.state.filtersObj)
    ) {
      this.setState(
        (prevState) => ({
          searchQuery: "",
        }),
        () => {
          this.getProjectsRequest();
        }
      );
    }
  }

  resetProjectDataTable = () => {
    this.setState({
      filtersObj: {},
      sortOrder: "",
      currentSortColumn: "",
      searchQuery: "",
      searchIds: [],
      isResetProjectFilters: true,
    }, () => this.getProjectsRequest());
  };

  handleProjectFilterObject = (
    value: FilterListType,
    isApplied?: boolean | undefined
  ) => {
    this.setState({
      filtersObj: value,
      isShowProjectSaveSearchLabel: isApplied,
      isProjectSearchSaved: !!isApplied,
    });
  };

  handleFavIconClicked(rowIndex: number) {
    const { tableInputs, filtersObj } = this.state;
    const project = tableInputs.apiData[rowIndex];
    const favouriteable_id = project.id;

    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let url: string = "";
    let method: string = "";
    if (project.favourite.is_favourite) {
      url = `${configJSON.apiEndPointForFavouriteProject}/${project.favourite.favourite_id}`;
      method = configJSON.apiDeleteMethod;
      const newTableInputs = tableInputs.apiData.map((record: any) => {
        if (record.id !== project.id) return record;
    
        const shouldClearRecord = !!filtersObj.favourites;
        if (shouldClearRecord) return {};
    
        return {
          ...record,
          favourite: {
            is_favourite: false,
            favourite_id: null,
          },
          iconValue: favEmpty,
        };
      });
      this.setState({
        tableInputs: {
          ...tableInputs,
          apiData: newTableInputs.filter((object: {id: string}) => !!object.id),
        },
      });
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            favouriteable_id,
            favouriteable_type: "BxBlockProfile::Project",
          },
        })
      );
      url = configJSON.apiEndPointForFavouriteProject;
      method = configJSON.apiPostMethod;
    }

    this.apiForFavouriteProject = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  favouriteProjectResponse = (responseJson: any) => {
    const { data } = responseJson;
    if (data && data.attributes && data.attributes.favouriteable_id) {
      const favProjectId = data.attributes.favouriteable_id;
      const { tableInputs } = this.state;
      const apiData = tableInputs.apiData.map((record: any) => {
        if (record.id === favProjectId) {
          return {
            ...record,
            favourite: {
              is_favourite: true,
              favourite_id: data.id,
            },
            iconValue: favFilled,
          };
        } else {
          return record;
        }
      });
      this.setState({
        tableInputs: {
          ...tableInputs,
          apiData,
        },
      });
    }
  };

  onSaveProjectSearch = () => {
    if (!this.state.isProjectSearchSaved)
      this.setState({
        isSavingSearch: true,
        onSaveSearchError: false,
      });
  };

  onChangeSavedSeachName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.handleChange("onSaveSearchError", false);
    this.handleChange("savingSearchProjectName", event.target.value);
  };

  saveProjectSearchRequest = () => {
    const {
      savingSearchProjectName,
      searchQuery,
      filtersObj,
      currentSortColumn,
      sortOrder,
    } = this.state;

    if (savingSearchProjectName === "") {
      this.setState({
        onSaveSearchError: true,
        onSaveSearchErrorMessage: configJSON.onSaveSearchErrorMessage,
      });
      return;
    }
    let anyFilter = Object.values(filtersObj).some(
      (array: any) => array.length > 0
    );
    if (!anyFilter) {
      this.setState({
        onSaveSearchErrorMessage: configJSON.onSaveSearchNoFilterMessage,
        onSaveSearchError: true,
      });
      return;
    }
    const body = {
      search_name: savingSearchProjectName,
      filters: {
        ...(searchQuery ? { project_name: searchQuery } : {}),
        ...filtersObj,
        ...(currentSortColumn
          ? {
              sort_key: currentSortColumn,
              sort_direction: sortOrder,
            }
          : {}),
      },
      type: "BxBlockProfile::ProjectSavedSearch",
    };
    const url = `${configJSON.apiEndPointForSaveProjectSearch}`;

    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForSaveProjectSearch = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  cancelSavingProjectSearch = () => {
    this.setState({
      savingSearchProjectName: "",
      isSavingSearch: false,
      isProjectSearchSaved: false,
    });
  };

  saveProjectSearchResponse = (responseJson: any) => {
    this.setState({
      savingSearchProjectName: "",
      isSavingSearch: false,
      isProjectSearchSaved: true,
    });
  };

  handleChangePage = (event: any, newPage: number) => {
    this.setState(
      (prevState, props) => ({
        paginationDetails: {
          ...prevState.paginationDetails,
          currentPage: newPage,
        },
      }),
      () => {
        this.getProjectsRequest(this.state.searchQuery);
      }
    );
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      (prevState, props) => ({
        paginationDetails: {
          ...prevState.paginationDetails,
          rowsPerPage: +event.target.value,
          currentPage: 0,
        },
      }),
      () => {
        this.getProjectsRequest(this.state.searchQuery);
      }
    );
  };

  handleSort(columnName: string, sortOrder: string) {
    const sort = sortOrder === "ASC" ? "DESC" : "ASC";
    const column = columnName === "service_type" ? "type" : columnName;
    this.setState(
      {
        sortOrder: sort,
        currentSortColumn: column,
        tableInputs: {
          ...this.state.tableInputs,
        },
      },
      () => this.getProjectsRequest(this.state.searchQuery)
    );
  }

  handleAddButton = () => {
    this.props.navigation.push("/Projects/add");
  };

  handleError = (errorMessage: string) => {
    this.setState({
      hasError: errorMessage,
    });
  };

  handleChange = (changedPropertyName: keyof S, changedValue: any | null) => {
    if (changedPropertyName) {
      this.setState((prevState: S) => {
        return {
          ...prevState,
          [changedPropertyName]: changedValue,
        };
      });
    }
  };

  formatColumnSortingOrder = () => {
    const { currentSortColumn, sortOrder } = this.state;
    const columnName =
      currentSortColumn === "type" ? "service_type" : currentSortColumn;
    return configJSON.projectModuleColumnListDefiniation.map((column: any) => ({
      ...column,
      sortOption:
        columnName === column.dataLabel && column.sortOption?.isSortable
          ? { ...column.sortOption, sortOrder }
          : column.sortOption,
    }));
  };

  getProjectsRequest = (searchQuery: string = "") => {
    // API CALL

    const { paginationDetails, filtersObj, currentSortColumn, sortOrder } =
      this.state;

    if (!currentSortColumn && !sortOrder) {
      this.handleChange("isLoading", true);
    }

    const { currentPage, rowsPerPage } = paginationDetails;
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForGetProjects = requestMessage.messageId;
    const queryParams = buildUrlParams(filtersObj);

    currentSortColumn && queryParams.append("sort_key", currentSortColumn);
    sortOrder && queryParams.append("sort_direction", sortOrder.toLowerCase());

    let endpoint: string = "";
    if (searchQuery.trim() !== "") {
      endpoint = `${
        configJSON.apiEndPointForAutoCompleteSearch
      }?q=${searchQuery}&${queryParams.toString()}&page=${
        currentPage + 1
      }&per_page=${rowsPerPage}`;
    } else
      endpoint = `${
        configJSON.apiEndPointForGetProjects
      }?${queryParams.toString()}&page=${
        currentPage + 1
      }&per_page=${rowsPerPage}`;

    this.setState({ searchQuery });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getProjectsResponse = (responseJson: any) => {
    const { errors, data: tempData, error, results } = responseJson;
    let data = tempData;
    if (
      error &&
      typeof error === "string" &&
      error === configJSON.projectModuleNoRecordsError
    ) {
      // handling internal error
      // this.handleError(error);
      const tableInputs = {
        apiData: [],
        colSpanList: configJSON.projectModuleColSpanListDefiniation,
        columsList: configJSON.projectModuleColumnListDefiniation,
        columnSep: [],
      };
      this.setState({
        tableInputs,
      });
    }
    if (errors && typeof errors === "string") {
      this.handleError(errors);
    }
    if (results && results.data) {
      data = results.data;
    }
    if (data && Array.isArray(data)) {
      const apiData: any[] = prepareProjectModuleTableResult(data);
      
      const tableInputs = {
        apiData,
        colSpanList: configJSON.projectModuleColSpanListDefiniation,
        columsList: this.formatColumnSortingOrder(),
        columnSep: [],
      };
      let totalLength: number = 0,
        currentPage: number = 1;
      if (
        responseJson.meta &&
        responseJson.meta.total_count &&
        responseJson.meta.current_page
      ) {
        totalLength = responseJson.meta.total_count;
        currentPage = responseJson.meta.current_page;
      }

      const searchIds = responseJson.meta?.search_ids || [];

      this.setState((prevState) => {
        return {
          paginationDetails: {
            rowsPerPageOptions: prevState.paginationDetails.rowsPerPageOptions, //[10, 20, 30],
            rowsPerPage: prevState.paginationDetails.rowsPerPage,
            totalLength: totalLength,
            currentPage: currentPage - 1,
          },
          isResetProjectFilters: false,
          searchIds,
          tableInputs,
        };
      });
    }

    this.handleChange("isLoading", false);
  };

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  findActiveTabForNavbar = () => {
    return NavbarSections.findIndex((section) => section === "Projects");
  };

  handleHoverOnMenu = (itemName: string) => {
    this.setState({ hoveredImportExportItem: itemName });
  };

  handleImportAsCSV = () => {
    this.props.navigation.navigate("ProjectImportData");
  };

  onClickOutside = (e: any) => {
    if (this.refExportBox.current?.state.hasImportExportButtonClicked) {
      this.refExportBox.current?.handleImportExportButtonClick({});
    }
  };

  // Customizable Area End
}

import React, { ReactNode } from "react";
import {
  Button,
  withStyles,
  createStyles,
  ButtonProps,
  useTheme,
  withTheme,
  Theme
} from "@material-ui/core";

const styles = createStyles({
  default: {
    backgroundColor: "#0094A7",
    color: "white",
    borderRadius: "8px",
    textTransform: "none",
    padding: "10px",
    minWidth: "120px",
    minHeight: "30px",
    "@media (max-width: 768px)": {
      "&.MuiButton-contained:hover": {
        backgroundColor: "#0094A7",
      
      }
    }
    
  },
  special: {
    backgroundColor: "#FFF",
    color: "#0094A7",
    borderRadius: "8px",
    border: "1.5px solid #0094A7",
    textTransform: "none",
    padding: "10px",
    minWidth: "120px",
    minHeight: "30px"
  },
  tableRowDelete: {
    minWidth: "32px",
    minHeight: "32px",
    padding: "0",
    margin: "8px",
    backgroundColor: "#FFF",
    color: "#0094A7",
    borderRadius: "50%",
    border: "1.5px solid #0094A7"
  },
  tableRowEdit: {
    minWidth: "32px",
    minHeight: "32px",
    padding: "0",
    margin: "8px",
    backgroundColor: "#0094A7",
    color: "white",
    borderRadius: "50%"
  },
  uploadDocumentButton: {
    margin: "16px",
    backgroundColor: "#FFF",
    color: "#0094A7",
    borderRadius: "8px",
    border: "2px dashed #0094A7",
    minWidth: "125px"
  },
  defaultHighlighted: {
    backgroundColor: "#0094A7",
    color: "white",
    borderRadius: "8px",
    textTransform: "none",
    padding: "10px",
    minWidth: "120px",
    minHeight: "30px"
  },
  noBackground: {
    minWidth: "8px",
    minHeight: "8px",
    padding: "0px",
    color: "#0094A7",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    textTransform: "none",
    boxShadow: "none",
    border: "none",
    textDecoration: "underline",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
      border: "none",
      textDecoration: "underline"
    }
  },
  projectRequest: {
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    backgroundColor: "#0094A7",
    color: "#FFF",
    borderRadius: "8px",
    textTransform: "none",
    minWidth: "150px",
    minHeight: "45px",
    boxShadow: "-8px 8px 24px 0px rgba(0, 148, 167, 0.24)",
    border: "1.5px solid #FFF",
    "&:hover": {
      color: "#0094A7"
    }
  },
  addNewComponentButton: {
    width: "100px",
    color: "#0094A7",
    textTransform: "none",
    borderRadius: "8px",
    border: "1.5px solid #0094A7",
    backgroundColor: "#FFF",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    padding: "10px",
    height: "37px",
    "&:hover": {
      backgroundColor: "#F9F9F9"
    }
  },
  hidden: {
    display: "none"
  },
  importExportButton: {
    width: "170px",
    backgroundColor: "#0094A7",
    color: "#F5FBFC",
    borderRadius: "8px",
    textTransform: "none",
    height: "37px",
    padding: "10px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    "@media (min-width: 960px)": {
      width: "140px",
      height: "38px",
      fontSize: 12
    }
  },
  dropdownButton: {
    width: "max-content",
    lineHeight: "normal !important",
    paddingRight: 24,
    paddingLeft: 24,
    backgroundColor: "#0094A7",
    color: "white",
    textTransform: "none",
    borderRadius: "8px",
    height: 45,
    "&:hover": {
      backgroundColor: "#0094A7"
    },
    
    "@media (max-width: 960px)": {
      padding:0,
      paddingRight: 12,
      paddingLeft: 12,
      // width: 137,
      fontSize: 12,
      height: 38,
      "& .MuiButton-startIcon":{
        marginLeft:0
      }
    },
    "@media (max-width: 400px)": {
      padding:0,
      paddingRight: 12,
      paddingLeft: 12,
      // width: 120,
      fontSize: 12,
      height: 38,
      "& .MuiButton-startIcon":{
        marginLeft:0
      }
    }
  },
  
  hoverMobile: {
    backgroundColor: "#0094A7!important",
    "@media (max-width: 768px)": {
      
    }
  }
  
});

export interface ColoredButtonProps extends ButtonProps {
  colorVariant?:
    | "default"
    | "special"
    | "tableRowDelete"
    | "tableRowEdit"
    | "uploadDocumentButton"
    | "defaultHighlighted"
    | "noBackground"
    | "projectRequest"
    | "addNewComponentButton"
    | "importExportButton"
    | "hidden"
    | "dropdownButton";
  width?: string | number;
  height?: string | number;
  truncateSM?: boolean;
  theme: Theme,
  children?: ReactNode;
}

type Props = ColoredButtonProps & { classes: Record<string, string> };

export const ColoredButton: React.FC<Props> = ({
  colorVariant = "default",
  children,
  classes,
  id,
  width,
  height,
  truncateSM,
  theme,
  style,
  ...buttonProps
}) => {
  const buttonClass = classes[colorVariant];
  const screenWitdh = window.screen.width;

  const dynamicStyle = {
    width,
    height,
    ...style
  };

  const truncateStyle = {
    ...style,
    width: '0!important',
  }

  return (
    <Button
      id={id}
      variant={colorVariant === "special" ? "outlined" : "contained"}
      className={buttonClass}
      {...buttonProps}
      style={truncateSM ? truncateStyle : dynamicStyle}
    >
      {children}
    </Button>
  );
};


const CustomButton = withStyles(styles)(withTheme(ColoredButton));
export default CustomButton;

import { MenuItem, Paper, Select, styled } from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface Option {
  value: any;
  label: string;
  values?: any;
}

interface SelectProps {
  options: Option[];
  value: any;
  onChange: (value: any) => void;
  placeholder?: string;
  maxWidth?: string;
  dialogContent?: React.ReactNode;
}

class CustomSelectField extends React.Component<SelectProps> {
  render() {
    const { options, value, onChange, placeholder, maxWidth, dialogContent } = this.props;
    return (
      <CustomSelect
        IconComponent={ExpandMoreIcon}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        variant="outlined"
        style={{ maxWidth: maxWidth ?? 'auto' }}
        MenuProps={{
          PaperProps: {
            component: CustomPaper // Custom paper component
          },
          anchorOrigin: {
            vertical: 'bottom', // Ensure menu renders below the select field
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {placeholder && <MenuItem value="" disabled>{placeholder}</MenuItem>}
        {!!dialogContent && dialogContent}
        {options.map((option, index) => (
          <MenuItem key={`${index * 2}`} value={option.value} >{option.label}</MenuItem>
        ))}
      </CustomSelect>
    );
  }
}

export const CustomSelect = styled(Select)({
  width: '100%',
  '& .MuiSelect-root': {
    padding: '8px 10px 8px 14px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    backgroundColor: '#fff',
    border: '1px solid #0094A7',
    color: "#0094A7",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&:focus': {
      borderColor: '#0094A7',
    },
  },
  "& fieldset": {
    display: "none",
  },
  '& .MuiSelect-icon': {
    color: '#0094A7',
    right: '2px',
    top: 'calc(50% - 12px)',
    position: 'absolute',
    pointerEvents: 'none',
  },
});

const CustomPaper = styled(Paper)({
  backgroundColor: '#ffffff',
  maxWidth: '116px',
  maxHeight: '220px',
  borderRadius: "4px",
  border: "1px solid #E0E0E0",
  background: "#FFF",
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#E8E8E8",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#0094A7",
    borderRadius: "22px",
  },
  "&::-webkit-scrollbar": {
    backgroundColor: "#E8E8E8",
    height: "5px",
    borderRadius: "15px",
    width: "5px",
  },
  "& ul": {
    "& li": {
      color: "#000",
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "16px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    "& .Mui-selected": {
      backgroundColor: 'rgb(214, 238, 241)',
      cursor: "default",
      "&:hover": {
        backgroundColor: 'rgb(214, 238, 241)',
      }
    }
  }
});



export default CustomSelectField;

import React from "react";

// Customizable Area Start
import { Box, Divider, Grid, Hidden, CircularProgress } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ChartCard from "../../../components/src/Chart/ChartCard.web";
import DateFilterDropdown from "../../../components/src/Chart/DateFilterDropdown.web";
import ServiceFilterDropdown from "../../../components/src/Chart/ServiceFilterDropdown.web";

import LegendList from "../../../components/src/Chart/LegendList.web";
import { ActivityList } from "../../../components/src/Chart/ActivityList";
import { Chart } from "react-google-charts";
import ResponsiveTopBar from "../../../components/src/TopBar/ResponsiveTopBar.web";
import Navbar from "../../../components/src/NavBar.web";
import CustomSelectField from "../../../components/src/CustomFields/CustomSelectField.web";
import RightLegendList from "../../../components/src/Chart/RightLegendList.web";
import NoRecordsFoundPage from "../../../components/src/Table/NoRecordsFoundPage.web";
import InfiniteScroll from "react-infinite-scroll-component";
// Customizable Area End

import DashboardController, {
  Props,
  configJSON,
} from "./DashboardController.web";
import { Typography } from "@material-ui/core";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPeopleChart = () => {
    const { candidate_data, employee_data } = this.state;
    const employeeChartColor = this.handleGetColors(employee_data);
    const candidateChartColor = this.handleGetColors(candidate_data);
    return (
      <PeopleChartSection>
        <Box className="chart">
          <ChartTitle>{configJSON.pieChartEmployeeTitle}</ChartTitle>
          <Chart
            chartType="PieChart"
            data={employee_data}
            options={{
              ...pieChartOptions,
              colors: employeeChartColor,
            }}
            width={"100%"}
            height={"100%"}
          />
          <LegendList data={employee_data.slice(1)} />
        </Box>
        <Box className="chart">
          <ChartTitle>{configJSON.pieChartCandidateTitle}</ChartTitle>
          <Chart
            chartType="PieChart"
            data={candidate_data}
            options={{
              ...pieChartOptions,
              colors: candidateChartColor,
            }}
            width={"100%"}
            height={"100%"}
          />
          <LegendList data={candidate_data.slice(1)} />
        </Box>
      </PeopleChartSection>
    );
  };

  renderActivity = () => {
    if (this.state.loadingActivity) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress
            color="inherit"
            size={"4rem"}
            style={{ color: "black", display: "inline-block" }}
          />
        </Box>
      )
    } else {
      return (
        <>
          { this.state.recentActivities.length > 0 ?
            <InfiniteScroll
              dataLength={this.state.recentActivities.length}
              next={this.fetchMoreData}
              hasMore={this.state.activityPage < this.state.maxActivityPage}
              data-testId="infinityScroll"
              style={{ overflow: 'hide' }}
              scrollableTarget="scrollableDiv"
              loader={
                <Box display="flex" justifyContent="center">
                  <CircularProgress
                      color="inherit"
                      size={"2rem"}
                      style={{ color: "black", display: "inline-block" }}
                    />
                </Box>
              }
            >
              <ActivityList navigation={this.props.navigation} activities={this.state.recentActivities} />
            </InfiniteScroll>
            : 
            <NoRecordsFoundPage 
              activeModule="dashboard"
              text="No Recent Activities" />
          }
        </>
      )
    }
    
  }

  renderDonutCharts = () => {
    const { yearOptions, yearValue, tenderYearChartData, tenderQuarterChartData, quarterOptions, quarterOpen, quarterValue, datePickerOpen, dateRange } = this.state;
    return (
      <TenderChartSection>
        <Box className="chart">
          <Box className="tenderHeader">
            <ChartTitle>{configJSON.donutFieldBasedTitle}</ChartTitle>
            <DateFilterDropdown
               data-test-id="tenderDateFilter"
               name={configJSON.tenderDropDownName}
               quarterOptions={quarterOptions} 
               quarterValue={quarterValue}
               quarterOpen={quarterOpen}
               datePickerOpen={datePickerOpen}
               dateRange={dateRange}
               handleQuarterOpen={this.handleQuarterOpen}
               handleDatePickerOpen={this.handleDatePickerOpen}
               handleQuarter={this.handleQuarter}
               handleDateRange={this.handleDateRange}
               handleResetDatePicker={this.handleResetDatePicker}
               handleApplyDatePicker={this.handleApplyDatePicker}
            />
          </Box>
          <Box className="tenderContainer svg_hover">
            <Chart
              chartType="PieChart"
              data-test-id="quarterChart"
              data={tenderQuarterChartData}
              options={{
                pieSliceTextStyle: {
                  color: "white",
                  fontFamily: "Source Sans Pro",
                  fontWeight: 600,
                  fontSize: this.state.fontSize,
                  lineHeight: 16, 
                },
                ...donutChartOptions,
                colors: this.handleGetColors(tenderQuarterChartData),
              }}
              width={"100%"}
              height={"100%"}
            />
            <RightLegendList data={tenderQuarterChartData.slice(1)} />
          </Box>
        </Box>
        <Hidden smDown>
          <Divider
            orientation="vertical"
            style={{
              margin: "15px 5px 0px",
              height: "96%",
              backgroundColor: "#0094A7",
            }}
          />
        </Hidden>
        <Box className="chart">
          <Box className="tenderHeader">
            <ChartTitle>{configJSON.donutYearlyBasedTitle}</ChartTitle>
            <CustomSelectField
              value={yearValue}
              maxWidth="80px"
              options={yearOptions}
              onChange={this.handleYearlyTender}
            />
          </Box>
          <Box className="tenderContainer svg_hover">
            <Chart
              chartType="PieChart"
              data={tenderYearChartData}
              options={{
                ...donutChartOptions,
                colors: this.handleGetColors(tenderYearChartData),
                pieSliceTextStyle: {
                  fontFamily: "Source Sans Pro",
                  color: "white",
                  fontWeight: 600,
                  fontSize: this.state.fontSize,
                  lineHeight: 16, 
                },
              }}
              width={"100%"}
              height={"100%"}
            />
            <RightLegendList data={tenderYearChartData.slice(1)} />
          </Box>
        </Box>
      </TenderChartSection>
    );
  };

  renderProjectCharts = () => {
    const { complete_project, on_going_projects, quarterOptions, 
      quarterCompletedOpen, quarterCompletedValue, datePickerCompletedOpen, dateRangeCompleted,
      quarterOngoingOpen, quarterOngoingValue, datePickerOngoingOpen, dateRangeOngoing, completedService, onGoingService, serviceOptions } = this.state;
    return (
      <TenderChartSection>
         <Box className="chart">
          <Box className="tenderHeader">
            <ChartTitle>{configJSON.barChartCompleteTitle}</ChartTitle>
            <Box className="dropdownContainer">
              <ServiceFilterDropdown
                data-test-id="completedService"
                name="completedService"
                value={completedService}
                options={serviceOptions}
                onChange={this.handleServiceChange}
              />
              <DateFilterDropdown
                data-test-id="tenderDateFilter"
                name={configJSON.completedDropdownName}
                quarterOptions={quarterOptions} 
                quarterValue={quarterCompletedValue}
                quarterOpen={quarterCompletedOpen}
                datePickerOpen={datePickerCompletedOpen}
                dateRange={dateRangeCompleted}
                handleApplyDatePicker={this.handleApplyDatePicker}
                handleResetDatePicker={this.handleResetDatePicker}
                handleQuarterOpen={this.handleQuarterOpen}
                handleDatePickerOpen={this.handleDatePickerOpen}
                handleQuarter={this.handleQuarter}
                handleDateRange={this.handleDateRange}
              />
            </Box>
          </Box>
          <Box className="tenderContainer">
          <Chart
            data-test-id="completed_project"
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={complete_project}
            options={{
              ...histogramChartOptions,
              vAxis:{
                ...histogramChartOptions.vAxis,
                viewWindow: this.checkViewWindowValues(complete_project),
              }
            }}
            width={"100%"}
            height={'350px'}
          />
          </Box>
        </Box>
        <Hidden smDown>
          <Divider
            style={{
              height: "95%",
              backgroundColor: "#0094A7",
              margin: "15px 5px 0px",
            }}
            orientation="vertical"
          />
        </Hidden>
        <Box className="chart">
          <Box className="tenderHeader">
            <ChartTitle>{configJSON.barChartOngoingTitle}</ChartTitle>
            <Box className="dropdownContainer">
              <ServiceFilterDropdown
                  data-test-id="onGoingService"
                  name="onGoingService"
                  value={onGoingService}
                  options={serviceOptions}
                  onChange={this.handleServiceChange}
                />
              <DateFilterDropdown
                data-test-id="tenderDateFilter"
                name={configJSON.onGoingDropdownName}
                quarterOptions={quarterOptions} 
                quarterValue={quarterOngoingValue}
                quarterOpen={quarterOngoingOpen}
                datePickerOpen={datePickerOngoingOpen}
                dateRange={dateRangeOngoing}
                handleQuarterOpen={this.handleQuarterOpen}
                handleDatePickerOpen={this.handleDatePickerOpen}
                handleApplyDatePicker={this.handleApplyDatePicker}
                handleResetDatePicker={this.handleResetDatePicker}
                handleQuarter={this.handleQuarter}
                handleDateRange={this.handleDateRange}
                position="left"
              />
            </Box>
          </Box>
          <Box className="tenderContainer">
          <Chart
            data-test-id="ongoing_project"
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={on_going_projects}
            options={{
              ...histogramChartOptions,
              vAxis:{
                ...histogramChartOptions.vAxis,
                viewWindow: this.checkViewWindowValues(on_going_projects),
              }
            }}
            width={"100%"}
            height={'350px'}
          />
          </Box>
        </Box>
      </TenderChartSection>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Hidden mdUp>
          <ResponsiveTopBar
            navigation={this.props.navigation}
            ActivityComponent={this.renderActivity()}
            title={"Dashboard"}
          />
        </Hidden>
        <MainContainer>
          <Box className="childContainer">
            <Hidden smDown>
              <Navbar
                navigation={this.props.navigation}
                selectedTab={this.findActiveTabForTopbar()}
              />
            </Hidden>
            <Box className="peopleTenderActivity">
              <Grid container direction="column" className="peopleTender">
                <Grid item style={{ marginBottom: "24px" }}>
                  <ChartCard
                    loading={false}
                    title={"People"}
                    maxwidgetheight={"auto"}
                    padding="17px 30px 25px"
                  >
                    {this.renderPeopleChart()}
                  </ChartCard>
                </Grid>
                <Grid item>
                  <ChartCard
                    loading={false}
                    title={"Tender"}
                    maxwidgetheight={"auto"}
                    padding="10px 12px 20px 30px"
                  >
                    {this.renderDonutCharts()}
                  </ChartCard>
                </Grid>
                
              </Grid>
              <Hidden smDown>
                  <Grid item>
                    <Box className="chartActivtiyWidget">
                      <ChartCard
                        loading={false}
                        title={"Recent Activities"}
                        maxwidgetheight={"742"}
                        padding="0px 8px"
                        scrollTarget="scrollableDiv"
                      >
                        { this.renderActivity() }
                      </ChartCard>
                    </Box>
                    
                </Grid>
                </Hidden>
            </Box>
            <ChartCard
              loading={false}
              title={"Project"}
              maxwidgetheight={"auto"}
              padding="15px 30px"
            >
              {this.renderProjectCharts()}
            </ChartCard>
            <Box style={{ color: "#F3F3F3" }}>.</Box>
          </Box>
        </MainContainer>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const pieChartOptions = {
  pieSliceBorderColor: "none",
  chartArea: {
    width: "60%",
    height: "60%",
    top: 10,
    bottom: 24,
    left: 0,
    right: 0,
  },
  legend: "none",
  pieSliceTextStyle: {
    color: "white",
    fontSize: 11,
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    lineHeight: 16,
  },
};

const donutChartOptions = {
  pieSliceBorderColor: "none",
  legend: "none",
  chartArea: {
    height: "150px",
    width: "150px",
    bottom: 1,
    left: 8,
    top: 1,
    right: 1,
  },
  pieHole: 0.4,
  is3D: false,
  
};

const histogramChartOptions = {
  chartArea: { width: '85%', height: '90%', bottom: 70, top: 30, right: 10},
  titleTextStyle:{
    color: "#2B3132",
    fill: "#2B3132",
    fontFamily: "Source Sans Pro",
    fontSize: 17,
    fontStyle: "normal",
    fontWeight: 600,
    textAlign: "center",
  },
  hAxis: {
    textStyle: {
      color: '#9F9F9F',
      fontSize: 14,
      fontFamily: 'Source Sans Pro',
      fontWeight: '600',
      lineHeight: 16,
      wordWrap: 'break-word'
    },
    minValue: 0,
  },
  vAxis:{
    textStyle: {
      color: '#9F9F9F',
      fontSize: 14,
      fontFamily: 'Source Sans Pro',
      fontWeight: '600',
      lineHeight: 16,
      wordWrap: 'break-word',
    }, 
    title: "No of Projects",
    titleTextStyle: {
      color: "rgb(152,152,152)",      
      fontSize: 14,
      fontFamily: 'Source Sans Pro',
      fontWeight: '600',
      italic: false,
    },
    format: '0',
  },
  bar: { alignment: 'center', justifyContent: 'flex-start' },
  legend: { position: 'bottom', maxLines: 5, alignment: 'center' },
}

const TenderChartSection = styled(Box)({
  flexDirection: "row",
  justifyContent: "center",
  display: "flex",
  rowGap: "30px",
  width: "100%",
  overflow: "hidden",
  alignItems: "flex-start",
  columnGap: "20px",
  "@media (max-width: 760px)": {
    flexWrap: "wrap",
  },
  "& .chart": {
    display: "flex",
    justifyContent: "space-between",
    "& .tenderHeader": {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      columnGap: "5px",
      alignItems: "flex-start",
      justifyContent: "space-between",
      "@media (max-width: 640px)": {
        justifyContent: "space-around",
      },
      "& .dropdownContainer":{
        display: "flex",
        gap: "10px",
        flexDirection: "row",
        alignItems: "flex-end",
        justifyContent: "space-between",
        "@media (max-width: 460px)": {
          flexDirection: "column",
        }
      }
    },
    "& .tenderContainer": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      columnGap: "30px",
      rowGap: "20px",
      flexDirection: "row",
    },
    "& .svg_hover":{
      "& svg": {
        "& g":{
          "& path":{
            stroke: "none !important",
          },
          "&:last-child":{
            pointerEvents: "none",
          }
        }
      }
    },
    flexDirection: "column",
    gap: '5px',
    width: "50%",
    alignItems: "flex-start",
    "@media (max-width: 760px)": {
      width: "100%",
    },
  },
});

const ChartTitle = styled(Typography)({
  color: "#2B3132",
  fontFamily: "Source Sans Pro",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "19px",
  textAlign: "center",
});

const MainContainer = styled(Box)(({ theme }) => ({
  justifyContent: "center",
  overflowY: "scroll",
  display: "flex",
  scrollbarWidth: "none",
  backgroundColor: "#F3F3F3",
  overflowX: "hidden",
  height: "calc(100vh - 65px)",
  "& .chartActivtiyWidget .bodyContainer": {
    display: "block",
    "@media (max-width: 1400px)": {
      height: 1102
    },
    "@media (max-width: 1062px)": {
      height: 1105
    }
  },

  "&::-webkit-scrollbar": {
    display: "none",
  },
  width: "100%",
  "& .childContainer": {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F3F3F3",
    width: "80%",
    padding: "20px 0",
    gap: "24px",
    "@media (max-width: 1320px)": {
      width: "85% !important",
    },
    "@media (max-width: 1200px)": {
      width: "90% !important",
    },
    "@media (max-width: 960px)": {
      padding: "20px 10px",
      width: "95% !important",
    },

    "& .peopleTenderActivity": {
      display: "grid",
      gridTemplateColumns: "65% 33%",
      gridGap: "21px",
      width: "100% !important",
      "@media (max-width: 960px)": {
        gridTemplateColumns: "1fr",
      },
      justifyContent: "space-between",
      "& .peopleTender": {
        "& .MuiGrid-root": {
          padding: 0,
        },
        "@media (max-width: 960px)": {
          width: "100%",
        },
      },
      "& .activity": {
        "@media (max-width: 960px)": {
          width: 0,
          display: "none",
        },
      },
    },
  },
}));

const PeopleChartSection = styled(Box)(({theme}) => ({
  flexDirection: "row",
  display: "flex",
  alignItems: "flex-start",
  overflow: "hidden",
  justifyContent: "center",
  width: "100%",
  columnGap: "20px",
  rowGap: "30px",
  "@media (max-width: 1400px)": {
    flexWrap: "wrap",
  },
  "& .chart": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "& svg": {
      "& g":{
        "& path":{
            stroke: "none !important",
        },
        "&:last-child":{
          pointerEvents: "none",
        }
      }
    },
    width: "50%",
    alignItems: "center",
   
    "@media (max-width: 640px)": {
      width: "100%",
    },
  },
}));

// Customizable Area End

import { Box, Grid, OutlinedTextFieldProps, TextField } from "@material-ui/core";
import React, { Component } from "react";
import CustomSelectMenu from "./CustomSelectMenu.web";
import CustomAutoComplete, {CustomInputOption} from "./CustomAutoComplete.web";
import { CustomTextField } from "../../blocks/multipageforms/src/assets";
import { allTimeZoneList, extractCountryCode } from "./Utils";

const countries = require("countries-list");

interface CustomPhoneInputProps
	extends Omit<OutlinedTextFieldProps, "onChange"> {
	testID?: string;
	countryCode: string;
	onChangeValue?: (value: string) => void;
	handleCountryCode: (val: string) => void;
	isPhoneInvalid?: boolean;
	countryError?: boolean;
	countryHelperText?: string;
}

type MyState = {};

export default class CustomPhoneInput extends Component<
	CustomPhoneInputProps,
	MyState
> {
	constructor(props: any) {
		super(props);
	}

	render() {
		const { testID } = this.props;


		const defaultValue = allTimeZoneList.filter(timezone => timezone.dial_code == this.props.countryCode)
								.map((x) => {
									return {
										value: x.dial_code,
										label: `${x.name} ( ${x.dial_code} )`,
									} as CustomInputOption;
								})

		return (
			<Box
				style={{
					display: "flex",
				}}>
				<Grid container spacing={1} direction="row">
					<Grid item >
						<CustomSelectMenu
							data-test-id={`${testID}-countryCode`}
							label={""}
							value={this.props.countryCode}
							defaultValue={defaultValue[0]}
							options={allTimeZoneList.map((x) => {
								return {
									value: x.dial_code,
									label: `${x.name} ( ${x.dial_code} )`,
								};
							})}
							onChangeValue={(val: string) => {
								this.props.handleCountryCode(val);
							}}
							// inputReadOnly={true}
							// displayFormatFn={(value) => extractCountryCode(value) || ""}
							placeholder="Area code"
							error={this.props.countryError || false}
							width="90px"
							helperText={this.props.countryHelperText}
						/>
					</Grid>
					<Grid item style={{ flex: '1', minWidth: "80px" }}>
						<CustomTextField
							fullWidth
							error={this.props.error}
							variant="outlined"
							type={this.props.type}
							placeholder={this.props.placeholder}
							helperText={this.props.helperText}
							data-test-id={testID}
							disabled={this.props.disabled}
							value={this.props.value}
							onChange={(e: any) =>
								this.handleValueChange(e.target.value.replace(/[^0-9]/g, ""))
							}
							inputProps={{
								minLength: 7,
								maxLength: 15,
								inputMode: "numeric",
							}}
						/>
					</Grid>
				</Grid>
			</Box>
		);
	}
	handleValueChange(value: string) {
		if (this.props.onChangeValue) {
			this.props.onChangeValue(value);
		}
	}
}

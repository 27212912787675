import React from "react";
import {
  MuiThemeProvider,
  Grid,
  Hidden,
} from "@material-ui/core";
import {
  createTheme, styled
} from "@material-ui/core/styles";


import Navbar from "../../../components/src/NavBar.web";
import { NavbarSections } from "./config";
import AllFilter from "../../../blocks/filteritems/src/AllFilter.web";
import ResponsiveTopBar from "../TopBar/ResponsiveTopBar.web";

// Customizable Area Start

interface Props {
    open: boolean;
    toggleOpen: () => void;
    navigation: any;
    children: any;
    handleFilterList: (value: any)=>void;
    isResetFilters?: boolean;
    module: string;
    isUpdateSaveSearch?:boolean,
    preFilter?: any;
}

const THEME = createTheme({
  typography: {
    fontFamily: "Source Sans Pro",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
  },
});
// Customizable Area End
export class TableLayout extends React.Component<Props, {}> {
 
  findActiveTabForNavbar = () => {
    return NavbarSections.findIndex((section) => section === this.props.module);
  };

  render() {
    // Customizable Area Start
    const {open, toggleOpen, navigation, children, isUpdateSaveSearch, handleFilterList, isResetFilters, preFilter} = this.props
    const module = this.props.module.toLowerCase();
    return (
      <MuiThemeProvider theme={THEME}>
        <Hidden mdUp> 
          <ResponsiveTopBar navigation={this.props.navigation} title={this.props.module}>
            { () => 
              <AllFilter navigation={navigation} module={module} isResetFilters={isResetFilters} isUpdateSaveSearch={isUpdateSaveSearch} open={open} toggleOpen={toggleOpen} handleFilterList={handleFilterList} preFilter={preFilter} />
            }
          </ResponsiveTopBar>
        </Hidden>
        <div style={{ fontFamily: 'Source Sans Pro', display: 'flex', backgroundColor: '#F3F3F3', width: '100%' , height: "calc(100vh - 65px)",
        overflow: "hidden"}}>
          <Grid container style={{ backgroundColor: "#F3F3F3 !important"}}>
            <Hidden smDown> 
              <Grid item xs={12} md={open ? 3 : 1}>
                {/* FilterView */}
                <div style={{ display: 'flex', flexDirection: 'column', position: 'relative'}}>
                  <AllFilter navigation={navigation} module={module} isResetFilters={isResetFilters} isUpdateSaveSearch={isUpdateSaveSearch} open={open} toggleOpen={toggleOpen} handleFilterList={handleFilterList} preFilter={preFilter} />
                </div>
              </Grid>
            </Hidden>
            <ChildContainer item xs={12} md={open ? 9 : 11}>
              <div style={{ display: 'flex', flexDirection: 'column', padding: '16px', marginBottom:"1rem"}}>
                <Hidden smDown>
                  <Navbar navigation={navigation} selectedTab={this.findActiveTabForNavbar()} />
                </Hidden>
                {children}
              </div>
            </ChildContainer>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
    // Customizable Area Start
  }
}


const ChildContainer = styled(Grid)({
  overflowY: 'scroll',
  overflowX: 'hidden',
  height: "calc(100vh - 65px)",
  "&::-webkit-scrollbar": {
    display: "none",
  },
})
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area 
  classes: Record<string, string>;
  peopleId: string;
  itemType: string;
  isAuditTrail: boolean;
  // Customizable Area End
}

// Customizable Area Start
export interface ValidResponseType {
  data: object;
  errors: Array<ErrorPayloadType>;
}
export interface ErrorPayloadType {
  key: string;
  message: string;
}
export interface ErrorResponseType {
  errors: string;
}
export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}
export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}
export interface AuditTrailGetlistType {
     id: number,
     item_type: string,
     item_id: number,
     actioned_by: string,
     event: string,
     created_at: string,
     message: Array<string>
}

export interface AuditTrailList {
  logs : Array<AuditTrailGetlistType>
  meta :{
    current_page : number,
    next_page: number,
    prev_page: number,
    total_pages: number,
    total_count: number
  }
 }

 type linkObject = {name: string, link: string}
// Customizable Area End

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  auditTrailList: Array<AuditTrailGetlistType>;
  hasSuccessError: HasSuccessErrorType;
  currentPage : number;
  nextPage : number;
  previousPage: number;
  totalPages: number;
  totalCount: number;
  perPage: number;
  isLoading: boolean;
  range: string;
  linkList: linkObject[];
  isFilterOpen: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Audittrail2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAuditTrailListApiCallId: string | null = null;
  getAuditTrailLinkApiCallId: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      auditTrailList: [],
      currentPage : 1,
      nextPage : 2,
      previousPage: 0,
      totalPages: 0,
      totalCount: 0,
      perPage: 5,
      isLoading: false,
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      range: "current 90 days",
      linkList: [],
      isFilterOpen: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
      this.handleGetAuditTrailList()
      this.handleGetAuditTrailLinks();
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleResponse = (message: Message) => {
    let apiId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const resp = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (resp && !resp.errors) {
      if (this.isValidResponseController(resp)) {
        this.apiSuccessCallBacksController(apiId, resp);
      }
    } else if (resp && resp.errors) {
      if (this.isInValidResponseController(resp)) {
        this.apiFailureCallBacksController(apiId, resp);
      }
    }
  }

  handleFilterOpen = (value: boolean) => {
    this.setState({ isFilterOpen: value})
  }

  handleFilterValue = (value: string) => {
    this.setState({ range: value, isFilterOpen: false },
      () => this.handleGetAuditTrailList()
    )
  }

  isValidResponseController = (responseJson: ValidResponseType) => {
    return responseJson && !responseJson.errors;
  };

  isInValidResponseController = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: AuditTrailList & {links: linkObject[]}) => {
    if (apiRequestCallId === this.getAuditTrailListApiCallId) {
      this.getAuditTrailSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getAuditTrailLinkApiCallId) {
      this.getAuditTrailLinkResponse(responseJson)
    }
  };

  apiFailureCallBacksController = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.getAuditTrailListApiCallId || apiRequestCallId === this.getAuditTrailLinkApiCallId) {
      this.getAuditTrailFailurCallBack(responseJson)
    }
  };
  // Customizable Area End
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleCloseSnack = () => {
    this.setState({
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      }
    })
  }

  apiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    let { contentType, method, endPoint, body } = valueData;
    const token = (await localStorage.getItem("authToken")) || "";
    let header = {
      "Content-Type": contentType,
      token,
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleGetAuditTrailList = async () => {
    this.setState({ isLoading: true })
    this.getAuditTrailListApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.GetAuditTrailEndPoint}/?per_page=${this.state.perPage}&page=${this.state.currentPage}&item_id=${this.props.peopleId}&item_type=${this.props.itemType}&range=${this.state.range}`,
    });
  };

  handleGetAuditTrailLinks = async () => {
    this.getAuditTrailLinkApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.GetAuditTrailLinkEndPoint,
      body: {
        item_id: this.props.peopleId,
        item_type: this.props.itemType
      }
    });
  }

  getAuditTrailLinkResponse = (linkResponse: {links: linkObject[]}) => {
    if(linkResponse?.links.length > 0) {
      this.setState({
        linkList: linkResponse.links,
      })
    }
  }

  getAuditTrailSuccessCallBack = (responseJson : AuditTrailList) =>{
    this.setState({
      auditTrailList: responseJson.logs,
      currentPage: responseJson.meta.current_page,
      nextPage: responseJson.meta.next_page,
      previousPage: responseJson.meta.prev_page,
      totalPages: responseJson.meta.total_pages,
      totalCount: responseJson.meta.total_count,
      isLoading: false
    })
  }

  getAuditTrailFailurCallBack = (responseJson: InvalidResponseType) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: responseJson.errors[0].message
      },
      auditTrailList: [],
      currentPage : 1,
      nextPage : 2,
      previousPage: 0,
      totalPages: 0,
      totalCount: 0,
      isLoading: false
    })
  }

  handlePreviousPage = () =>{
   this.setState({currentPage: this.state.currentPage - 1 },()=>this.handleGetAuditTrailList())   
  }
   
  handleNextPage = () =>{
  this.setState({ currentPage : this.state.currentPage + 1},()=>this.handleGetAuditTrailList())
  }
  // Customizable Area End
}

import {
    InputAdornment,
    IconButton,
    InputProps,
    withStyles,
    Input
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { ChangeEvent, Component } from "react";

interface ToggleEyeInputProps extends InputProps {

}

type Props = ToggleEyeInputProps;
type State = {
    enablePasswordField: boolean;
};
export default class ToggleEyeInput extends Component<Props, State> {


    constructor(props: Props) {
        super(props)
        this.state = {
            enablePasswordField: true
        }
    }

    handleClickShowPassword = () => {
        this.setState({
            enablePasswordField: !this.state.enablePasswordField,
        });
    };

    render() {
        
        return (
            <Input
                {...this.props}
                type={this.state.enablePasswordField ? "password" : "text"}
                endAdornment={
                <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleClickShowPassword}
                    edge="end"
                    >
                    {this.state.enablePasswordField ? (
                        <Visibility />
                    ) : (
                        <VisibilityOff />
                    )}
                    </IconButton>
                </InputAdornment>
                }
            />
        )
    }

}
// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/styles";
import PartnerModuleController, {
  Props,
  configJSON,
} from "./PartnerModuleController.web";
import { Box, Grid, Hidden, Snackbar } from "@material-ui/core";
import CustomList from "../../../components/src/CustomList.web";
import ResponsiveTopBar from "../../../components/src/TopBar/ResponsiveTopBar.web";
import Navbar from "../../../components/src/NavBar.web";
import Loading from "../../../components/src/Loading/Loading";
import PartnerModuleHeader from "./Components/PartnerModule/PartnerModuleHeader.web";
import SupplierDetails from "./SupplierDetails.web";
import { styles } from "./SupplierModule.web";
import SupplierBrochure from "./SupplierBrochure.web";
import CustomButton from "../../../components/src/CustomButton.web";
import SupplierNotes from "./SupplierNotes.web";
import Audit from "../../audittrail2/src/Audittrail.web";
import ConnectionWeb from "../../DeepLinking/src/Connection.web";
import { bindingCloseEvent } from "../../../components/src/Utils";

// Customizable Area End

class PartnerModule extends PartnerModuleController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderConnection() {
    const { mode } = this.state;
    return (
      <ConnectionWeb 
        id={this.state.partnerId} 
        source="partner"
        onSuccessSave={this.handleCBSubmitDocument}
        editMode={this.countableSection.get(this.state.activePartnerSection) == 0 && mode == 'post'}
        handleRequestNavigation={this.handlePartnerReqeust}
        targets={["supplier", "tender", "project", "people"]}
        tabLabels={["Suppliers", "Tenders", "Projects", "People"]}
        schema={{
          tender: [
            {
              label: 'Name',
              type: 'link',
              mapValue: (rowData) => (
                <a
                target="_blank"
                href={`/Tender/${rowData.tender_id}`}
                style={{
                  fontWeight: 400,
                  color: "#0094A7",
                  textDecoration: "underline",
                  lineHeight: "16px",
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '200px',
                  fontStyle: "normal",
                  fontFamily: "Source Sans Pro",
                  display: 'inline-block',
                  fontSize: "16px",
                }}
                >
                    {rowData.name}
                  </a>
              ),
              dataKey: 'name',
            },
            {
              label: 'Group company',
              dataKey: 'group_company'
            },
            {
              label: 'Country',
              dataKey: 'country',
            },
            {
              label: 'Client',
              dataKey: 'client',
            },
            {
              label: 'Services',
              dataKey: 'services',
            }
          ],
          
          people: [
          {
              dataKey: 'name',
              label: 'Name',
              type: 'link',
              mapValue: (rowData) => (
              <a
              target="_blank"
              href={`/People/${rowData.people_id}`}
                  style={{
                      whiteSpace: 'nowrap',
                      fontFamily: "Source Sans Pro",
                      fontWeight: 400,
                      textDecoration: "underline",
                      lineHeight: "16px",
                      display: 'inline-block',
                      overflow: 'hidden',
                      width: '200px',
                      fontSize: "16px",
                      textOverflow: 'ellipsis',
                      fontStyle: "normal",
                      color: "#0094A7",
                  }}
                  >
                  {rowData?.name}
                  </a>
              )
          },
          {
              dataKey: 'related_to',
              label: 'Related to',
              mapValue: (rowData) => {
              return rowData?.related_to?.map((related: any) => related.employe_status)?.join(",") || '---'
              }
          },
          {
              dataKey: 'unrelated',
              label: 'Unrelate',
              mapValue: (rowData) => {
                  return rowData?.unrelated?.map((related: any) => related.employe_status)?.join(",") || '---'
              }
          },
          ],
          project: [
              {
                  dataKey: 'type',
                  label: 'Type',
              },
              {
                  dataKey: 'name',
                  type: 'link',
                  mapValue: (rowData) => (
                    <a
                        href={`/Projects/${rowData.project_id}`}
                        target="_blank"
                        style={{
                          fontFamily: "Source Sans Pro",
                          textDecoration: "underline",
                          lineHeight: "16px",
                          whiteSpace: 'nowrap',
                          fontWeight: 400,
                          fontSize: "16px",
                          overflow: 'hidden',
                          display: 'inline-block',
                          textOverflow: 'ellipsis',
                          width: '200px',
                          fontStyle: "normal",
                          color: "#0094A7",
                        }}
                        >
                            {rowData.name}
                        </a>
                  ),
                  label: 'Project name',
                  
              },
              {
                  label: 'Group company',
                  dataKey: 'group_company'
              },
              {
                  label: 'Country',
                  dataKey: 'country'
              },
              {
                  label: 'Client',
                  dataKey: 'client'
              },
              {
                label: 'JV Shares(%)',
                dataKey: 'jv_share'
              }
          ],
          supplier: [
            {
              dataKey: 'company_name',
              mapValue: (rowData) => (
              <a
              target="_blank"
              href={`/Supplier/${rowData.supplier_id}`}
              style={{
                fontFamily: "Source Sans Pro",
                textDecoration: "underline",
                whiteSpace: 'nowrap',
                fontWeight: 400,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: "#0094A7",
                lineHeight: "16px",
                display: 'inline-block',
                width: '200px',
                fontSize: "16px",
                fontStyle: "normal",
              }}
                  >
                  {rowData.name}
                  </a>
              ),
              label: 'Name',
              type: 'link',
            },
            {
                dataKey: 'sector',
                label: 'Sector',
            },
            {
                dataKey: 'country',
                label: 'Country',
            },
            {
                dataKey: 'services',
                label: 'Services',
            },
            
            {
                dataKey: 'internal_rate',
                label: 'Internal Rate',
            }
        ],
            
        }}
        navigation={this.props.navigation}  
        testid="connection" />
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      activePartnerSection,
      partnerId,
      isAuditTrail,
      hasPartnerError,
      isPartnerLoading,
      groupPartnerResponse,
      countryPartnerResponse,
      servicesPartnerResponse,
      sectorPartnerResponse,
      internalRatePartnerResponse,
      mode,
      brochure,
      onBrochureUpload,
      brochureToUploadPartner,
      isCancelBrochureEnabledPartner,
      notesData,
      partnerNotesLoader
    } = this.state;
    return (
      <Grid
        container
        className={classes.supplierMainContainer}
        id="partnerMainContainer"
      >
        <Hidden smDown>
          <Grid
            item
            xs={12}
            sm={2}
            className={classes.supplierSideBox}
            id="partnerSideBox"
          >
            <Box
              className={classes.supplierSideInnerBox}
              id="partnerSideInnerBox"
            >
              <CustomList
                data-testid="changeTab"
                activeSection={activePartnerSection}
                sections={configJSON.PartnerModuleSections}
                data-testId="list-menu"
                handleListItemClick={this.handlePartnerListItemClick}
                disabled={partnerId === ""}
              />
            </Box>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid xs={12}>
            <ResponsiveTopBar
              navigation={this.props.navigation}
              title={"Partners"}
            >
              { ( { closeDrawer } ) => 
                <CustomList
                  activeSection={activePartnerSection}
                  sections={configJSON.PartnerModuleSections}
                  data-testId="list-menu"
                  handleListItemClick={bindingCloseEvent(this.handlePartnerListItemClick, closeDrawer)}
                  disabled={partnerId === ""}
                />
              }
              
            </ResponsiveTopBar>
          </Grid>
        </Hidden>
        <Grid
          item
          xs={12}
          sm={12}
          md={10}
          className={classes.supplierdetailsbox}
          id="partnerdetailsbox"
        >
          <Box
            className={classes.supplierdetailsinnerbox}
            id="supplierinnerbox"
          >
            <Hidden smDown>
              <Navbar navigation={this.props.navigation} selectedTab={5} />
            </Hidden>
            {isPartnerLoading ? (
              <Loading height="70vh" background="white" />
            ) : (
              <Box
                className={classes.supplierModuleHeader}
                id="partnerModuleHeader"
              >
                <PartnerModuleHeader
                  navigation={this.props.navigation}
                  saveAsPDF={partnerId != ""}
                  handleGoBack={this.handleGoBackPartner}
                  targetId={partnerId}
                />
                <div
                  ref={this.contentPartnerRef}
                  id="partnerDeatilsBox"
                  className={classes.supplierDeatilsBox}
                >
                  {activePartnerSection ===
                    configJSON.PartnerModuleSections[0] && (
                    <Box id="Partner Details">
                      <SupplierDetails
                        handleSwitchMode={this.handleSwitchPartnerMode}
                        supplierDetails={this.state.PartnerDetails}
                        handleChangeValue={(val: string, key: string) =>
                          this.handleChangePartnerData(val, key)
                        }
                        handlePostSupplierData={this.handlePostPartnerDetails}
                        SupplierErrors={false}
                        handleUpdateSupplierData={
                          this.handleUpdatePartnerDetails
                        }
                        onCancelUpload={this.handleCancelPartner}
                        data-test-id="partner_details"
                        groupResponse={groupPartnerResponse}
                        countryResponse={countryPartnerResponse}
                        sectorResponse={sectorPartnerResponse}
                        servicesResponse={servicesPartnerResponse}
                        internalRateResponse={internalRatePartnerResponse}
                        onChange={this.handlePartnerChange}
                        module="Partner"
                        supplierRequest={this.handlePartnerReqeust}
                        data-testid="partnerRequestTestID"
                      />
                    </Box>
                  )}

                  {activePartnerSection ===
                    configJSON.PartnerModuleSections[1] && (
                    <Box id="partnerBrochure">
                      <Grid item xs={12}>
                        <SupplierBrochure
                          testId={"partnerBrochure"}
                          mode={this.state.mode}
                          onChange={this.handleChangePartnerBrochure}
                          onBrochureUpload={onBrochureUpload}
                          onFileChange={this.handlePartnerFileChange}
                          brochure={brochure}
                          onDeleteBrochure={this.handleDeletePartnerBrochure}
                          openImage={this.openPartnerBrochureImage}
                          brochureToUpload={brochureToUploadPartner}
                          handleEditButtonClick={
                            this.handleEditBrochureButtonClick
                          }
                          removeStagedFile={this.removeStagedPartnerFile}
                          hasError={this.state.documentBrochureError}
                          module="Partner"
                          supplierRequest={this.handlePartnerReqeust}
                        />
                        <Box
                          className={
                            mode === configJSON.viewRecordMode
                              ? classes.formActionButtonNone
                              : classes.formActionButtonFlex
                          }
                        >
                          {isCancelBrochureEnabledPartner && (
                            <CustomButton
                              disabled={this.state.isPendingApi}
                              data-test-id={configJSON.cancelButtonTestId}
                              colorVariant="special"
                              onClick={this.handleCancelBrochureButton}
                            >
                              Cancel
                            </CustomButton>
                          )}
                          <CustomButton
                            disabled={this.state.isPendingApi}
                            data-testid="saveEditBrochure"
                            onClick={this.handleSaveBrochureButton}
                          >
                            Save
                          </CustomButton>
                        </Box>
                      </Grid>
                    </Box>
                  )}

                  {activePartnerSection ===
                    configJSON.PartnerModuleSections[2] && (
                    <Box id="Notes">
                      <Grid container>
                        <Grid item xs={12}>
                          <SupplierNotes
                            mode={this.state.mode}
                            data-testid="partnerNotesBtnTestId"
                            testId={"partnerNotesBtnTestId"}
                            notesData={notesData}
                            onChange={(value) =>
                              this.handleChangePartnerNotes(value)
                            }
                            handleEditButtonClick={
                              this.handleEditBrochureButtonClick
                            }
                            notesError={this.state.partnerNotesError}
                            supplierAddData={this.partnerAddData}
                            module="Partner"
                            supplierRequest={this.handlePartnerReqeust}
                            notesLoader={partnerNotesLoader}
                          />
                        </Grid>
                        <Grid item xs={12} sm={11}>
                          <Box
                            className={
                              mode === configJSON.viewRecordMode
                                ? classes.formActionButtonNone
                                : classes.formActionButtonFlex
                            }
                          >
                            {isCancelBrochureEnabledPartner && (
                              <CustomButton
                                colorVariant="special"
                                data-test-id={configJSON.cancelButtonTestId}
                                onClick={this.handlePartnerNotesCancelButton}
                              >
                                Cancel
                              </CustomButton>
                            )}
                            <CustomButton
                              onClick={this.handlePostPartnerNotes}
                              data-testid={
                                configJSON.supplierModuleSaveButtonTestId
                              }
                              disabled={!!this.state.partnerNotesError}
                            >
                              Save
                            </CustomButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                  {partnerId &&
                    activePartnerSection ===
                      configJSON.SupplierModuleSections[4] && (
                      <Box id="Audit trail">
                        <Audit
                          isAuditTrail={isAuditTrail}
                          id={""}
                          peopleId={partnerId}
                          navigation={this.props.navigation}
                          itemType={"partner"}
                        />
                      </Box>
                    )}
                  {activePartnerSection === configJSON.PartnerModuleSections[3] && 
                    this.renderConnection()
                  }
                </div>
                
                {hasPartnerError && typeof hasPartnerError === "string" && (
                  <Snackbar
                    open={!!hasPartnerError}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    message={hasPartnerError}
                    onClose={() => {
                      this.setState({ hasPartnerError: "" });
                    }}
                    key="error-message"
                    data-testid="moduleError"
                  />
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(PartnerModule);
export { PartnerModule };
// Customizable Area End

import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputLabel,
  Divider
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import OTPInput from "react-otp-input";
import ImageComponent from "./imageComponent.web";

// Customizable Area End

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController.web";
import { loginBackground } from "../../login-block/src/assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

export class ForgotPasswordOTP1 extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Box className={classes.mainBox}>
          <ImageComponent navigation={undefined} />
          <Box className={classes.mainBox410}>
            <Box className={classes.mainBox510}>
                <Typography className="loginText">
                  Enter OTP
                </Typography>
              <Box className={classes.mainBox710}>
                <InputLabel>
                  Enter your 4 digit code that you received on your email.
                </InputLabel>
              </Box>
              <Box>
                <Divider className={classes.dividerRoot} />
              </Box>
              <OTPInput
                data-testId="handleOTP"
                onChange={(otp: string) => {
                  this.handleOTP(otp);
                }}
                inputType="number"
                value={this.state.otp}
                containerStyle={webStyle.mainBox810}
                inputStyle={{
                  ...webStyle.mainBox910,
                  border: !!this.handleOtpError()
                    ? "1px solid #DC2626"
                    : "1px solid #0094A7",
                  "WebkitAppearance": "none",
                  "margin": 0,
                  "MozAppearance": "textfield"
                }}
                numInputs={4}
                renderInput={(props: any) => <input {...props} />}
              />
              <Box style={{ ...webStyle.mainBox1010, textAlign: "center" }}>
                {!!this.handleOtpError() && <Typography style={webStyle.mainBox1011}>{this.handleOtpError()}</Typography>}
              </Box>
              <Box style={webStyle.mainBox1011}>
                <Button
                  data-testId="goToChangePasswordAfterOtp"
                  onClick={values => this.goToChangePasswordAfterOtp(values)}
                  style={{
                    color: "white",
                    width: "100%",
                    maxWidth: "426px",
                    backgroundColor: "#0094A7",
                    height: "3rem",
                    fontWeight: 600,
                    borderRadius: 8,
                    textTransform: "none",
                    fontSize: 18,
                    fontFamily: "Source Sans Pro",
                    marginTop: "3rem"
                  }}
                >
                  Verify
                </Button>
              </Box>
              <Box className={classes.mainBox710}>
                <InputLabel>
                  If you didn't receive a code!
                  <Button
                    data-testId="resendOTPCode"
                    style={{
                      color: "#0094A7",
                      textDecoration: "underline",
                      textTransform: "none",
                      fontFamily: "Source Sans Pro",
                      fontSize: 18,
                      padding: "0 0 0 5px",
                      backgroundColor: 'transparent'
                    }}
                    onClick={value => this.goToOtpAfterEmailValidation(value)}
                  >
                    Resend
                  </Button>
                </InputLabel>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    backgroundSize: "100% 100%",
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${loginBackground})`,
    fontFamily: "Source Sans Pro"
  },
  mainBox410: {
    display: "flex",
    backgroundColor: "white",
    width: "50vw",
    borderRadius: "50px 0 0 50px",
    '@media (max-width: 600px)': {
      justifyContent: "center",
      width: "100vw",
      height: "100%",
      top: "140px",
      borderRadius: "50px 50px 0 0px",
      position: "absolute",
    },
  },
  mainBox510: { 
    margin: "auto", 
    width: "50%", 
    height: "min-content",
    "& .loginText":{
      fontFamily: "Source Sans Pro",
      lineHeight: "40px",
      textAlign: "center",
      '@media (max-width: 1280px)': {
        fontSize: 32,
        lineHeight: "32px",
      },
      fontSize: 40,
      '@media (max-width: 600px)': {
        fontWeight: 600,
        fontSize: 28,
        lineHeight: "28px",
      },
      fontWeight: 700,
    },
    '@media (max-width: 600px)': {
      width: "75%",
      margin: "150px 25px 25px",
      paddingBottom: "25px",
    },
    '@media (min-width: 600px) and (max-width: 1279px)': {
      width: "65%",
    },
  },
  mainBox610: { display: "flex", justifyContent: "center" },
  mainBox710: {
    marginTop: "15px",
    "& .MuiFormLabel-root": {
      color: "#9F9F9F",
      fontFamily: "Source Sans Pro",
      fontSize: 18
    }
  },
  mainBox810: {
    display: "flex",
    justifyContent: "space-between",
    gap: "15px",
    maxWidth: "426px",
    width: "100%",
  },
  mainBox910: {  
    width: "100%",
    maxWidth: "72px",
    maxHeight: "72px",
    border: "1px solid #0094A7",
    borderRadius: 8,
    height: "4rem",
    fontSize: "2.8rem",
    color: "#0094A7",
    fontFamily: "Source Sans Pro",
    '@media (max-width: 600px)': {
        height: "25px",
        width: "25px",
        fontSize: 18,
    },
    
  },
  mainBox1010: { color: "#DC2626" },
  mainBox1011: { margin: "1rem 0" },
  dividerRoot: {
    backgroundColor: "E8E8E8",
    margin: "35px 0"
  },
};

export default withStyles(webStyle)(ForgotPasswordOTP1);

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ImportExportData";
exports.labelBodyText = "ImportExportData Body";

exports.btnExampleTitle = "CLICK ME";

exports.exportSuccess = "Your file exported to path: ";
exports.exportCsvLabel = "Export CSV";
exports.exportJsonLabel = "Export JSON";
exports.loadingText = "Loading...";

exports.jsonApiContentType = "application/json";
exports.csvApiContentType = "text/csv";
exports.exportDataEndpoint = "data_import_export/export";
exports.exportDataMethod = "GET";
exports.errorMsg = "Something went wrong."
exports.errorForBigFileUpload = "File size exceeds 5MB or invalid format. Please choose another file"
exports.errorForBigValidFileUpload = "Please choose a file smaller than 5MB and in a valid file format"
exports.errorInvalidFormat = "File is invalid format. Please choose another file"

exports.sampleXLSlinkTestId = "export-sample-csv"
exports.sampleCSVlinkTestId = "export-sample-xls"

exports.invalidImportDataCSVResponse = {
  error: 'refers 500 internal error'
}

exports.validImportDataCSVResponse = {
  message: 'sample success message'
}

exports.exportSamplePeopleCSVData=
`First Name,Last Name,Email,DOB (dd/mm/yyyy),Country Code,Phone Number,Nationality,Highest Level,Discipline,Primary,Primary Years,Secondary,Secondary Years,Country,Design (Yes/No),Supervision (Yes/No),Current Position,Internal Rate,Language Name,Language Level,Relation,Employe Status,Status Option,Visa Designation,Visa Joining Date (dd/mm/yyyy),Visa End Date (dd/mm/yyyy),Diploma,Diploma Year,Passport Number,Passport Expiry Date (dd/mm/yyyy),Local Id,Local Id Expiry Date (dd/mm/yyyy),Driving License,Project Connection,Tender Connection
Jane,Smith,test1@gmail.com,11/02/2021,91,8978675647,india,master,industrial Engineering,tunnels,0 to 5yrs,tunnels,5 to 10yrs,india,no,yes,supervision,very Good,english,beginner,related,Currently Employed,Renardet Oman,AAA122333,11/02/2021,11/02/2025,master's Degree,2011,ASDE1246,11/02/2025,AASDER12357,11/02/2025,AAEER1135,"PRO111,Mrach 5","wwww,CAE"
Jane,Smith,test2@gmail.com,11/02/2021,91,8978675646,india,master,industrial Engineering,tunnels,0 to 5yrs,tunnels,5 to 10yrs,india,no,yes,supervision,very Good,english,beginner,related,Currently Employed,Renardet Oman,AAA122333,11/02/2021,11/02/2025,master's Degree,2011,ASDE1245,11/02/2025,AASDER12356,11/02/2025,AAEER1134,"PRO111,Mrach 5","wwww,CAE"
Jane,Smith,test1@gmail.com,11/02/2021,91,8978675648,india,master,industrial Engineering,Tunnels,0 to 5yrs,tunnels,5 to 10yrs,india,no,yes,supervision,very Good,english,beginner,related,Past Employed,Bonifica,AAA122333,11/02/2021,11/02/2025,Master's Degree,2011,ASDE1247,11/02/2025,AASDER12358,11/02/2025,AAEER1136,"PRO111,Mrach 5","wwww,CAE"
Jane,Smith,test1@gmail.com,11/02/2021,91,8978675648,india,master,industrial Engineering,Tunnels,0 to 5yrs,tunnels,5 to 10yrs,india,no,yes,supervision,very Good,english,beginner,related,Past Employed,Renardet Oman,AAA122333,11/02/2021,11/02/2025,Master's Degree,2011,ASDE1249,11/02/2025,AASDER12360,11/02/2025,AAEER1139,"PRO111,Mrach 5","wwww,CAE"
Jane,Smith,test2@gmail.com,11/02/2021,91,8978675646,india,master,industrial Engineering,tunnels,0 to 5yrs,tunnels,5 to 10yrs,india,no,yes,supervision,very Good,english,beginner,related,Currently Employed,Renardet Oman,AAA122333,11/02/2021,11/02/2025,master's Degree,2011,ASDE1245,11/02/2025,AASDER12356,11/02/2025,AAEER1111,"PRO111,Mrach 5","wwww,CAE"
Jane,Smith,test2@gmail.com,11/02/2021,91,89786752346,india,master,industrial Engineering,tunnels,0 to 5yrs,tunnels,5 to 10yrs,india,no,yes,supervision,very Good,english,beginner,unrelated,Interviewed or Recommended,Renardet Oman,AAA122333,11/02/2021,11/02/2025,master's Degree,2011,ASDE1124,11/02/2025,AASDER12100,11/02/2025,AAEER1200,"PRO111,Mrach 5","wwww,CAE"
`

exports.sampleXLSXPeopleData = [["First Name","Last Name","Email","Highest Level","DOB (dd/mm/yyyy)","Country Code","Phone Number","Discipline","Primary","Primary Years","Nationality","Secondary","Secondary Years","Country","Design (Yes/No)","Supervision (Yes/No)","Current Position","Relation","Employe Status","Status Option","Internal Rate","Language Name","Language Level","Visa Designation","Visa Joining Date (dd/mm/yyyy)","Visa End Date (dd/mm/yyyy)","Diploma","Diploma Year","Passport Number","Passport Expiry Date (dd/mm/yyyy)","Local Id","Local Id Expiry Date (dd/mm/yyyy)","Driving License","Project Connection","Tender Connection"],["John","Doe","test1@gmail.com","pHD","1/19/22","91","45678413","Economy","Tunnels","0 to 5yrs ","India","Tunnels","10 to 15yrs ","india","yes","no","Supervision","Related","Past Employed","Bonifica","Very Good","English","Beginner","AAA12232","10/11/22","10/11/24","Diploma in Nursing ","2011","ASDE123","1/11/25","AASDER1234","1/11/25","AAEER112","project1","tender1"],["Jane","Smith","mailid432@gmail.com","master","1/19/22","92","45678423","Economy","Tunnels","0 to 5yrs ","Iran","Tunnels","10 to 15yrs ","india","no","yes","Supervision","Related","Submitted for Tender","Yes","Good","kanada","beginner","AAA12233","10/11/22","10/11/24","Diploma in Nursing ","2011","ASDE125444","1/11/25","5.77778E+11","1/11/25","AAEER113","project1","tender1"]]

exports.exportSampleProjectCSVData=
`Project Name,Group Company,Country,Client,Client Name,Value Of Work(Local Currency),Project Status,Start Date (dd/mm/yyyy),End Date (dd/mm/yyyy),Project Title,Joint Venture (true/false),Project JV Share,Project Code,Technical Summary,Local Currency,Our Fee(Local Currency),Service Type,Sector,Services,People Connection,Tender Connection,Supplier Connection,Partner Connection,Partner JV Share
Project1,	Profert,India,	Private,John Doe,100000000,Completed,01/12/2014,03/04/2024,Front End,true,80.23,12345,A,AED,4.5,Service Type 1,Sector,Services,jane, tender1,Company Tech,"Company2 Tech, Company Tech,Super-man","22,34"
Project2,	Profert,India,	Private,John Doe,10000000,Pending,01-13-2014,03/11/2024,Front End,true,4.2,12346,B,AED,4.6,Service Type 2,Sector,Services
,	Profert,India,	Private,John Doe,1000000,Pending,01-14-2014,03/12/2024,Front End,true,4.3,12347,C,AED,4.7,Service Type 3,Sector,Services
Project4,	Profert,India,	Private,John Doe,10000,Pending,01-15-2014,03-02-2024,Front End,true,4.4,12348,D,AED,4.8,Service Type 4,Sector,Services
Project5,	Profert,India,Financial Institution,John Doe,10000000,Completed,01-16-2014,03-14-2024,Back End,false,4.5,12349,E,AED,4.9,Service Type 5,Sector,Services
Project6,	Profert,India,Financial Institution,John Doe,10000000,Completed,01-17-2014,03-02-2024,Back End,false,4.6,12355,F,AED,3.5,Service Type 6,Sector,Services
Project7,	Profert,India,Financial Institution,John Doe,10000000,Completed,01-18-2014,03-02-2024,Back End,false,4.7,12375,G,AED,3.6,Service Type 7,Sector,Services
Project8,	Profert,India,Financial Institution,John Doe,10000000,Pending,01-19-2014,03-02-2024,Back End,false,4.8,12375,H,AED,3.7,Service Type 8,Sector,Services
Project9,	Profert,India,Financial Institution,John Doe,10000000,Pending,01-20-2014,03-01-2012,Back End,false,4.9,23212,I,AED,3.8,Service Type 9,Sector,Services
`

exports.exportSampleTenderCSVData=
`Tender Name,Tender Code,Tender Status,Tender Type,Tender Date (dd/mm/yyyy),Group Company,Country,Client,Client Name,Local Currency,Value Of Work(Local Currency),Our Fee(Local Currency),Sector,Services,People Connection,Project Connection,Supplier Connection,Partner Connection
Tender1,TEND23,Started,Private,11/10/2025,Profertsale,India,Wong,Wong Joy,AED,1000000000,20000,Sector,service,people,project,supplier,partner
Tender1,TEND25,Started,Private,11/10/2025,Profertsale,India,Wong,Wong Joy,AED,1000000000,20000,Sector,Services,people,project,supplier,partner
Tender1,TEND23,Started,Private,11/10/2025,Profertsale,India,Wong,Wong Joy,AED,1000000000,20000,Sector,Services,people,project,supplier,partner
,TEND24,Started,Private,11/10/2025,Profertsale,India,Wong,Wong Joy,AED,1000000000,20000,Sector,Services,people,project,supplier,partner
Tender1,TEND23,Started,Private,11/10/2025,Profertsale,India,Wong,Wong Joy,AED,-4,-2,Sector,Services,people,project,supplier,partner`

exports.sampleXLSXTenderData = [["Tender Name","Tender Code","Tender Status","Tender Type","Tender Date (dd/mm/yyyy)","Group Company","Country","Client","Client Name","Local Currency","Value Of Work(Local Currency)","Our Fee(Local Currency)","Sector","Services","People Connection","Project Connection","Supplier Connection","Partner Connection"],["Tender11","TEND122","Started","Selective tendering","11/05/24","witmates","india","wong","Brokwee","aed","100000000","234324","electronic","1","people1","Project1","supplier1","partner1"],["Tender22","TEND123","     Started       ","    Selective tendering    ","12/05/24","witmates","india","wong","Brokwee","aed","100000001","234325","electronic","1","","","",""]]

exports.exportSampleSupplierCSVData=
`Company Name,Email Address,Country Code,Phone Number,Website,Internal Rating,Note,Group Company,Country,Sector,Services,People Connection,Project Connection,Tender Connection,Partner Connection
Company Tech,supplier@yopmail.com,91,89675645,google.com,Good,Hello this is first supplier,Witmates,India,Information Technology,Road Service
Company1 Tech,supplier1@yopmail.com,91,2332,http://google.com,Bad,Hello this is second supplier,Witmates,India,wrong
Company2 Tech,supplier2@yopmail.com,93,6724342364,http://google.com,Bad,Hello this is second supplier,Witmates,India,wrong,Road Service
`

exports.sampleXLSXSupplierData = [["Company Name","Email Address","Country Code","Phone Number","Website","Internal Rating","Note","Group Company","Country","Sector","Services","People Connection","Project Connection","Tender Connection","Partner Connection"],["Witmates Tech","supplier@yopmail.com","91","89675645","google.com","Good","Hello this is first supplier","Witmates","India","Information Technology","Road Service","Ram","Profert","Syam","RR"]]


exports.exportSamplePartnerCSVData=
`Company Name,Email Address,Country Code,Phone Number,Website,Internal Rating,Note,Group Company,Country,Sector,Services,People Connection,Project Connection,Supplier Connection,Tender Connection
Company Tech,supplier@yopmail.com,91,89675645,google.com,Good,Hello this is first supplier,Witmates,India,Information Technology,Road Service
Company1 Tech,supplier1@yopmail.com,91,2332,http://google.com,Bad,Hello this is second supplier,Witmates,India,wrong
Company2 Tech,supplier2@yopmail.com,93,6724342364,http://google.com,Bad,Hello this is second supplier,Witmates,India,wrong,Road Service`

exports.sampleXLSXPartnerData = [["Company Name","Email Address","Country Code","Phone Number","Website","Internal Rating","Note","Group Company","Country","Sector","Services","People Connection","Project Connection","Tender Connection","Supplier Connection"],["Witmates Tech","supplier@yopmail.com","91","89675645","google.com","Good","Hello this is first supplier","Witmates","India","Information Technology","Road Service","people1","project1","tender1","supplier1"]]


exports.sampleXLSXProjectData = [["Project Name","Project Code","Project Status","Project Title","Technical Summary","Joint Venture (true/false)","Project JV Share","Start Date (dd/mm/yyyy)","End Date (dd/mm/yyyy)","Group Company","Client Name","Country","Client","Service Type","Sector","Services","Local Currency","Value Of Work(Local Currency)","Our Fee(Local Currency)","People Connection","Tender Connection","Supplier Connection","Partner Connection","Partner JV Share"],["Project1","PP123","Pending","Proooo","Hi, Tech","TRUE","80","12/03/2021","12/05/2024","Witmates","Jack","India","Jack","Project","Electronic","IT Service","AED","10000000","10000","People1","tender1","supplier1","partner1","20"]]
exports.exportPdfTokenEndpoint = "bx_block_data_import_export/export/initiate_export_people_pdf";
exports.exportCSVTokenEndpoint = "bx_block_data_import_export/export/initiate_export_people_csv";
exports.exportPeopleStatusPdfEndpoint = "bx_block_data_import_export/export/get_people_pdf_status";
exports.exportPeopleStatusCSVEndpoint = "bx_block_data_import_export/export/get_people_csv_status";
exports.exportPeoplePdfEndpoint = "bx_block_data_import_export/export/exported_people_dashboard_pdf";
exports.exportPeopleCSVEndpoint = "bx_block_data_import_export/export/exported_people_csv";
exports.importPeopleTokenCSVEndpoint = "bx_block_data_import_export/export/import_csv";
exports.importPeopleStatusCSVEndpoint = "bx_block_data_import_export/export/import_status";
exports.importPeopleCSVEndpoint = "bx_block_data_import_export/csv_import_logs";

exports.pdfEmailMessage = "We will send the PDF to your email when it's ready"

// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  buildUrlParams,
  removeUndefinedPropertiesFromGivenObject,
  queryArray,
  checkLoadTimes
} from "../../../components/src/Utils";
import { favEmpty, favFilled } from "./assets";
import { PartnerExportData } from "../../importexportdata/src/PartnerExportData.web";
import React from "react";

export type FilterPartnerObjType = {
  [key: string]: any[] | string | boolean;
};

export interface PartnerData {
  data: Array<PartnerDataResponse>;
  meta: PartnerDataMeta;
}

export interface PartnerDataResponse {
  id: string;
  type: string;
  attributes: {
    id: number;
    company_name: string | null;
    email_address: string | null;
    phone_number: string | null;
    website: string | null;
    internal_rating: string | null;
    notes: string | null;
    favourite?: {
      is_favourite: boolean | null;
      favourite_id: number | string | undefined | null;
    };
    company_detail: {
      id: number;
      group_company: string;
      country: string;
      sector: string;
      services: {
        data: [
          {
            id: string;
            type: string;
            attributes: {
              id: number;
              name: string;
            };
          }
        ];
      };
    };
  };
}

export interface PartnerCompanyDetail {
  id: number,
  group_company: string,
  country: string,
  sector: string,
  services: {
    data: [
      {
        id: string,
        type: string,
        attributes: {
          id: number,
          name: string
        }
      }
    ]
  } 
}

export interface PartnerDataMeta {
  current_page: number;
  next_page: number;
  prev_page: number;
  total_pages: number;
  total_count: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isPartnerListLoading: boolean;
  isOpenPartnerFilter: boolean;
  isPartnerSearchSaved: boolean;
  isResetPartnerFilters: boolean;
  isShowSaveSearchPartnerLabel: boolean | undefined;
  currentPartnerSortColumn: string;
  sortPartnerOrder: string;
  searchPartnerQuery: string;
  hasPartnerError: string | null;
  partnerFilterObj: FilterPartnerObjType;
  partnerPaginationDetails: {
    currentPage: number;
    rowsPerPage: number;
    totalLength: number;
    rowsPerPageOptions: number[];
  };
  tablePartnerInputs: {
    apiData: PartnerDataResponse[];
    colSpanList: PartnerDataResponse[];
    columsList: PartnerDataResponse[];
    columnSep: PartnerDataResponse[];
  };
  searchQuery: string;
  isPartnerSavingSearch: boolean;
  savingSearchPartnerName: string;
  onSaveSearchErrorPartnerMessage: string;
  onSaveSearchErrorPartner: boolean;
  searchIds: number[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PartnerDataTableController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: any;
  getPartnerApiCallId: string = "";
  apiForFavouritePartner: string = "";
  apiForSavePartnerSearch: string = "";

  refExportBox = React.createRef<PartnerExportData>()
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isOpenPartnerFilter: true,
      isPartnerSearchSaved: false,
      isResetPartnerFilters: false,
      isShowSaveSearchPartnerLabel: false,
      isPartnerListLoading: false,
      hasPartnerError: null,
      partnerFilterObj: {},
      partnerPaginationDetails: {
        rowsPerPage: configJSON.defaultRawsPerPage,
        totalLength: 0,
        currentPage: 0,
        rowsPerPageOptions: configJSON.rowsPerPageOptions,
      },
      tablePartnerInputs: {
        columnSep: [],
        colSpanList: [],
        columsList: [],
        apiData: [],
      },
      sortPartnerOrder: "",
      searchPartnerQuery: "",
      currentPartnerSortColumn: "",
      searchQuery: "",
      isPartnerSavingSearch: false,
      savingSearchPartnerName: "",
      onSaveSearchErrorPartnerMessage: "",
      onSaveSearchErrorPartner: false,
      searchIds: []
    };
    
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiPartnerRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const partnerResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleAllPartnerApiResponses(
        apiPartnerRequestCallId,
        partnerResponseJson
      );
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    if (checkLoadTimes() > 1) {
      history.pushState(null, '', '/Modules/Partners')
    }
    this.handlePartnerData();
    localStorage.removeItem('partners');
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      JSON.stringify(prevState.partnerFilterObj) !==
      JSON.stringify(this.state.partnerFilterObj)
    ) {
      this.setState(
        {
          searchQuery: "",
        },
        () => {
          this.handlePartnerData();
        }
      );
    }
  }

  handleAllPartnerApiResponses = (
    apiRequestCallId: string,
    responseJson: any
  ) => {
    const successCallbackMap = {
      [this.getPartnerApiCallId]: this.handlePartnerDataResponse,
      [this.apiForFavouritePartner]: this.favouritePartnerResponse,
      [this.apiForSavePartnerSearch]: this.savePartnerSearchResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJson: any) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJson);
    }
  };

  togglePartnerFilterOpen = () => {
    this.setState({ isOpenPartnerFilter: !this.state.isOpenPartnerFilter });
  };

  handlepartnerFilterObj = (
    value: FilterPartnerObjType,
    isApplySaveSearch?: boolean | undefined
  ) => {
    this.setState({
      partnerFilterObj: value,
      isShowSaveSearchPartnerLabel: isApplySaveSearch,
      isPartnerSearchSaved: !!isApplySaveSearch,
    });
  };

  handleCheckedPartnerSaveSearch = () => {
    if (!this.state.isPartnerSearchSaved) {
      this.setState({
        isPartnerSavingSearch: true,
        onSaveSearchErrorPartner: false,
      });
    }
  };

  onChangePartnerSavedSeachName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      onSaveSearchErrorPartner: false,
      savingSearchPartnerName: event.target.value,
    });
  };

  cancelSavingPartnerSearch = () => {
    this.setState({
      savingSearchPartnerName: "",
      isPartnerSavingSearch: false,
      isPartnerSearchSaved: false,
    });
  };

  savePartnerSaveSearchRequest = () => {
    const {
      savingSearchPartnerName,
      searchQuery,
      sortPartnerOrder,
      partnerFilterObj,
      currentPartnerSortColumn,
    } = this.state;

    if (savingSearchPartnerName === "") {
      this.setState({
        onSaveSearchErrorPartner: true,
        onSaveSearchErrorPartnerMessage: configJSON.onSaveSearchErrorMessage,
      });
      return;
    }
    let isPartFilter = Object.values(partnerFilterObj).some(
      (array: any) => array.length > 0
    );
    if (!isPartFilter) {
      this.setState({
        onSaveSearchErrorPartnerMessage: configJSON.onSaveSearchNoFilterMessage,
        onSaveSearchErrorPartner: true,
      });
      return;
    }
    const partnerSaveBody = {
      search_name: savingSearchPartnerName,
      filters: {
        ...(searchQuery ? { project_name: searchQuery } : {}),
        ...partnerFilterObj,
        ...(currentPartnerSortColumn
          ? {
              sort_key: currentPartnerSortColumn,
              sort_direction: sortPartnerOrder,
            }
          : {}),
      },
      type: "BxBlockSavesearch::PartnerSavedSearch",
    };
    const partnerSaveUrl = `${configJSON.apiEndPointForSaveProjectSearch}`;

    const partnerSaveHeader = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForSavePartnerSearch = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(partnerSaveBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      partnerSaveUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(partnerSaveHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  savePartnerSearchResponse = (resp: any) => {
    if (this.handleGetPartnerResponsesError(resp)) {
      this.setState({
        isPartnerSavingSearch: false,
      });
      return;
    }
    if (resp.data) {
      this.setState({
        savingSearchPartnerName: "",
        isPartnerSavingSearch: false,
        isPartnerSearchSaved: true,
        hasPartnerError: null,
      });
    }
  };

  handlePartnerAddView = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "PartnerModuleAdd"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  };

  handlePartnerListChangePage = (
    event: React.ChangeEvent<HTMLInputElement>,
    newPage: number
  ) => {
    this.setState(
      (prevState, props) => ({
        partnerPaginationDetails: {
          ...prevState.partnerPaginationDetails,
          currentPage: newPage,
        },
      }),
      () => {
        this.handlePartnerData();
      }
    );
  };

  handlePartnerListChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState(
      (prevState, props) => ({
        partnerPaginationDetails: {
          ...prevState.partnerPaginationDetails,
          rowsPerPage: +event.target.value,
          currentPage: 0,
        },
      }),
      () => {
        this.handlePartnerData();
      }
    );
  };

  handleLoadPartnerBtn = async () => {
    this.setState(
      {
        searchPartnerQuery: "",
        isResetPartnerFilters: true,
        currentPartnerSortColumn: "",
        sortPartnerOrder: "",
        partnerFilterObj: {},
      },
      async () => {
        try {
          this.getPartnerApiCallId = await this.apiCallPartnerModule({
            contentTypePartner: configJSON.exampleApiContentType,
            methodPartner: configJSON.getApiMethodType,
            endPointPartner: `${configJSON.getPartnerDataEndpoint}?page=1&per_page=10`,
          });
          this.setState({ isPartnerListLoading: true });
        } catch (error) {
          console.error("Error in handleLoadPartnerBtn:", error);
        }
      }
    );
  };

  handlePartnerSort(columnName: string, sortOdr: string) {
    const sort = sortOdr === "ASC" ? "DESC" : "ASC";
    const column = columnName === "service" ? "services" : columnName;
    this.setState(
      {
        sortPartnerOrder: sort,
        currentPartnerSortColumn: column,
        tablePartnerInputs: {
          ...this.state.tablePartnerInputs,
        },
      },
      () => this.handlePartnerData()
    );
  }

  handleFavPartnerIconClicked = (rowIndex: number) => {
    const { tablePartnerInputs } = this.state;
    const partner: any = tablePartnerInputs.apiData[rowIndex];
    const favouriteable_id = partner.id;

    const partnerHeader = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let partnerUrl: string = "";
    let partnerMethod: string = "";
    if (partner?.favourite?.is_favourite) {
      partnerUrl = `${configJSON.apiEndPointForFavouritePartner}/${partner.favourite.favourite_id}`;
      partnerMethod = configJSON.apiDeleteMethod;
      const newtablePartnerInputs = tablePartnerInputs.apiData.map(
        (rowPt: PartnerDataResponse) => {
          if (rowPt.id !== partner.id) return rowPt;

          const shouldClearSuppliertd = !!this.state.partnerFilterObj.favourites;
          if (shouldClearSuppliertd) return {...rowPt, id: ""};
      
          return {
            ...rowPt,
            favourite: {
              is_favourite: false,
              favourite_id: null,
            },
            iconValue: favEmpty,
          };
        }
      );
      this.setState({
        tablePartnerInputs: {
          ...tablePartnerInputs,
          apiData: newtablePartnerInputs.filter((objectPT) => !!objectPT.id),
        },
      });
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({
          data: {
            favouriteable_id,
            favouriteable_type: "BxBlockMultipageforms2::Partner",
          },
        })
      );
      partnerUrl = configJSON.apiEndPointForFavouriteProject;
      partnerMethod = configJSON.apiPostMethod;
    }

    this.apiForFavouritePartner = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      partnerUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(partnerHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      partnerMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  favouritePartnerResponse = (responseJson: any) => {
    if (this.handleGetPartnerResponsesError(responseJson)) {
      return;
    }
    const { data } = responseJson;
    if (data && data.attributes && data.attributes.favouriteable_id) {
      const favPartnerId = data.attributes.favouriteable_id;
      const { tablePartnerInputs } = this.state;
      const apiData = tablePartnerInputs.apiData.map((row: any) => {
        if (row.id == favPartnerId) {
          return {
            ...row,
            favourite: {
              is_favourite: true,
              favourite_id: data.id,
            },
            iconValue: favFilled,
          };
        } else {
          return row;
        }
      });
      this.setState({
        tablePartnerInputs: {
          ...tablePartnerInputs,
          apiData,
        },
        hasPartnerError: null,
      });
    }
  };

  handlePartnerData = async (searchPartnerQuery?: string) => {
    const {
      partnerPaginationDetails,
      partnerFilterObj,
      currentPartnerSortColumn,
      sortPartnerOrder,
    } = this.state;
    const { currentPage, rowsPerPage } = partnerPaginationDetails;
    const partnerQueryParams = buildUrlParams(partnerFilterObj);

    let endpoint = "";

    if (!currentPartnerSortColumn && !sortPartnerOrder) {
      this.setState({ isPartnerListLoading: true });
    }
    currentPartnerSortColumn &&
      partnerQueryParams.append("sort_key", currentPartnerSortColumn);
    sortPartnerOrder &&
      partnerQueryParams.append(
        "sort_direction",
        sortPartnerOrder.toLocaleLowerCase()
      );

    if (!searchPartnerQuery) {
      endpoint = `${configJSON.getPartnerDataEndpoint}?page=${
        currentPage + 1
      }&per_page=${rowsPerPage}&${partnerQueryParams.toString()}&advance_search=${this.state.searchPartnerQuery}`;
    } else {
      this.setState({searchPartnerQuery: searchPartnerQuery.trim()});
      endpoint = `${
        configJSON.getPartnerDataEndpoint
      }?advance_search=${searchPartnerQuery}&page=${
        currentPage + 1
      }&per_page=${rowsPerPage}&${partnerQueryParams.toString()}`;
    }

    let filterIdsString = ""
    const targetIds = queryArray("ids", window.location.href);
    if (targetIds.length > 0) {
       filterIdsString = `&ids=[${targetIds.join(',')}]`;
       endpoint += filterIdsString 
    }

    this.getPartnerApiCallId = await this.apiCallPartnerModule({
      contentTypePartner: configJSON.exampleApiContentType,
      methodPartner: configJSON.getApiMethodType,
      endPointPartner: endpoint,
    });
  };

  handleGetPartnerResponsesError = (partnerError: any) => {
    if (partnerError.errors && partnerError.errors.length > 0) {
      let errorMsg = "";
      partnerError.errors.forEach((err: any) => {
        errorMsg += Object.values(err)[0] + "\n";
      });
      this.setState({
        hasPartnerError: errorMsg,
      });
      return true;
    } else if (partnerError.error) {
      this.setState({
        hasPartnerError: partnerError.error,
      });
      return true;
    } else {
      return false;
    }
  };

  handlePartnerDataResponse = (partnerRes: any) => {
    let data = partnerRes.data;
    const searchIds = partnerRes.meta?.search_ids || [];
    if (data && Array.isArray(data)) {
      const apiData: PartnerDataResponse[] =
        this.preparePartnerModuleTableResult(data);
      const tablePartnerInputs = {
        apiData,
        colSpanList: configJSON.partnerModuleColSpanListDefiniation,
        columsList: this.hasFormatPartnerColumnSortingOrder(),
        columnSep: [],
      };

      let totalLength: number = 0,
        currentPage: number = 1;

      if (partnerRes.meta?.total_count && partnerRes.meta?.current_page) {
        totalLength = partnerRes.meta.total_count;
        currentPage = partnerRes.meta.current_page;
      }

      const partnerPaginationDetails = {
        rowsPerPageOptions:
          this.state.partnerPaginationDetails.rowsPerPageOptions,
        rowsPerPage: this.state.partnerPaginationDetails.rowsPerPage,
        totalLength: totalLength,
        currentPage: currentPage - 1,
      };

      this.setState({
        partnerPaginationDetails,
        tablePartnerInputs,
      });
    } else {
      const tablePartnerInputs = {
        apiData: [],
        colSpanList: configJSON.partnerModuleColSpanListDefiniation,
        columsList: configJSON.partnerModuleColumnListDefiniation,
        columnSep: [],
      };

      this.setState({
        tablePartnerInputs,
      });
    }
    this.setState({
      isPartnerListLoading: false,
      isResetPartnerFilters: false,
      searchIds
    });
  };

  apiCallPartnerModule = async (valuePartnerData: {
    methodPartner?: string;
    contentTypePartner?: string;
    bodyPartner?: {};
    endPointPartner?: string;
    typePartner?: string;
    apiTypePartner?: string;
  }) => {
    let { contentTypePartner, methodPartner, endPointPartner, bodyPartner } =
      valuePartnerData;
    const token = (await localStorage.getItem("authToken")) || "";
    let headerPartner = {
      "Content-Type": contentTypePartner,
      token,
    };
    let requestMessagePartner = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessagePartner.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodPartner
    );
    requestMessagePartner.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointPartner
    );
    requestMessagePartner.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerPartner)
    );
    bodyPartner &&
      requestMessagePartner.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        bodyPartner
      );
    runEngine.sendMessage(requestMessagePartner.id, requestMessagePartner);
    return requestMessagePartner.messageId;
  };

  preparePartnerModuleTableResult = (data: PartnerDataResponse[]) => {
    return data.map((partner: any) => {
      let iconValue: string = favEmpty;
      let company_detail : any = {};
      const { attributes: partnerDetails } = partner;
      if (
        partnerDetails.company_detail &&
        partnerDetails.company_detail.id
      ) {
        
        company_detail = {
          id: partner.id,
          company_name: this.truncatePartnerText(partnerDetails.company_name,25),
          internal_rating: partnerDetails.internal_rating,
          favourite: partnerDetails.favourite,
          country: partnerDetails.company_detail.country,
          group_company: partnerDetails.company_detail.group_company,
          sector: partnerDetails.company_detail.sector,
          service:  partnerDetails.company_detail.service,
          ...partnerDetails.connections
        }
        if (partnerDetails.company_detail.sector && partnerDetails.company_detail.sector.id) {
          company_detail.sector = partnerDetails.company_detail.sector.name;
        }
        if (partnerDetails.company_detail.service && Array.isArray(partnerDetails.company_detail.service)) {
          const service = partnerDetails.company_detail.service
            .map((srv: any) => srv.name)
            .join(",");
          company_detail.service = this.truncatePartnerText(service, 27);
        }
      }

      if (partnerDetails.favourite && partnerDetails.favourite.is_favourite) {
        iconValue = favFilled;
      }
      const viewedProjectDetails = { ...company_detail, iconValue };
      return removeUndefinedPropertiesFromGivenObject(viewedProjectDetails);
    });
  };

  truncatePartnerText = (partnerTitle: string, partnerTitleLength: number) => {
    if (partnerTitle === undefined || partnerTitle === null) return "";
    if (partnerTitle.length > partnerTitleLength) {
      return `${partnerTitle.slice(0, partnerTitleLength)}...`;
    } else {
      return partnerTitle;
    }
  };

  hasFormatPartnerColumnSortingOrder = () => {
    const { currentPartnerSortColumn, sortPartnerOrder } = this.state;
    const updatedColumn = currentPartnerSortColumn === "services" ? "service" : currentPartnerSortColumn;
    return configJSON.partnerModuleColumnListDefiniation.map(
      (column: { dataLabel: string; sortOption: { isSortable: boolean } }) => ({
        ...column,
        sortOption:
        updatedColumn === column.dataLabel && column.sortOption?.isSortable
            ? { ...column.sortOption, sortOrder: sortPartnerOrder }
            : column.sortOption,
      })
    );
  };

  // Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { getProgressCSVDownload, downloadCSVContent, getProgressPDFDownload, downloadPDFContent, importProjectStatus } from "../../../components/src/Utils"
import { DownloadManager } from "../../../components/src/PollProgressBar/DownloadManager"
import { getStoredDefaultRole } from "../../../components/src/ContextAPI/AdvancedFilterContext.web";

export const configJSON = require("./config");


export interface ValidResponseType {
  errors: Array<ErrorPayloadType>;
  data: object;
}
export interface ErrorPayloadType {
  message: string;
  key: string;
}
export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}
export interface ErrorResponseType {
  errors: string;
}

export interface PeopleExportData {
  export_token: string
  message: string,
}

export interface PeopleExportStatusData {
  progress: number,
  status: string,
  file_type: string
}

export interface HasSuccessErrorType {
  isMessage: string;
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
}

export interface PeopleExportStatusData {
  file_type: string,
  status: string,
  progress: number,
}

export interface Props {
  classes: Record<string, string>;
  testid: string;
  id: string;
  searchIds?: number[];
  navigation: any;
  count?: number;
}

interface S {
  loadingCSV: boolean;
  open: boolean
  token: string;
  selectedFile: File | null;
  loadingJson: boolean;
  hasError: string
  isFileImported: boolean
  tenderReportMetaData: ReportMetaData | null,
  isSkipped: boolean
  isLoading: boolean;
  customPaperRef:  React.RefObject<HTMLInputElement>;
  isShowReport: boolean,
  hasImportExportButtonClicked: boolean;
  peopleExportCSVToken: string;
  peopleImportToken: string;
  hasSuccessError: HasSuccessErrorType;
  peopleExportPdfToken: string;
}

interface RecordErrorReport {
  fieldName: string;
  value: null | string;
  row: number;
  error: string
}

interface ReportMetaData {
  skippedRows: number;
  overriddenRows: number;
  totalRecords: number;
  lastProcessedRows: number;
  summary: {
    createdRecords: string,
    oveririddenRecords: string
    summaryMessage: string,
  },
  skippedErrors: RecordErrorReport[],
  importedMessage: string;
  notImportMessage: string;
  skippedMessage: string;
  recordErrors: RecordErrorReport[],
}


interface SS {
  id: any;
}

export default class TenderImportDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  jsonDataApiCallId: string = "";
  importDataCSVCallId: string = "";
  getImportReportApiCallId: string | null = null;
  getExportPdfTokenApiCallId : string | null = null;
  exportSampleDataCallId: string = "";
  getExportCSVTokenApiCallId : string | null = null;
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      hasError: '',
      selectedFile: null,
      open: true,
      loadingCSV: false,
      isSkipped: true,
      loadingJson: false,
      hasImportExportButtonClicked: false,
      customPaperRef: React.createRef(),
      isFileImported: false,
      peopleExportCSVToken:"",
      peopleExportPdfToken:"",
      isLoading: false,
      peopleImportToken: "",
      tenderReportMetaData: null,
      token: "",
      isShowReport: false,
      hasSuccessError: {
        isSeverity: "success",
        isMessage: "",
        isOpen: false,
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    super.componentDidMount();
    const { importToken, status } = this.props.navigation.history.location?.state || {};
    if (status == 'success') {
      this.setState({
        isShowReport: true
      }, () => {
        this.getImportLogReport(importToken)
      })
    }
    
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const requestCallIdApi = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      if (this.importDataCSVCallId !== "" && this.importDataCSVCallId === requestCallIdApi) {
        const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.handleImportDataCSVResponse(response)
      }

    } 
     
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (response && !response.errors) {
        if (this.isValidResponseController(response)) {
          this.apiSuccessCallBacksController(apiRequestCallId, response);
        }
      } 
    }
  }

  toggleOpen = (isOpen: boolean) => {
    this.setState({ open: isOpen });
  };
  
  handleError = (error: string) => {
    this.setState({
      hasError: error
    })
  }
  
  handleGoBack = () => {
    this.props.navigation.navigate("TenderModuleTable")
  };

  handleImportFile = (file: File, isSkip: boolean) => {
    this.setState({
      isLoading: true
    })
    const method = isSkip ? 'skip' : 'override'
    const formData = new FormData();

    if (file instanceof File) {
      formData.append('file', file)
    } else {
      this.setState({
        hasError: 'File not selected!'
      })
      return false;
    }

    formData.append("method", method);
    formData.append("activity_type", "tender");

    const headeRequest = {
      token:
        typeof window !== 'undefined'
          ? localStorage.getItem('authToken')
          : null
    };

    const apiRequestImport = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.importDataCSVCallId = apiRequestImport.messageId;

    apiRequestImport.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_data_import_export/export/import_csv?role=${getStoredDefaultRole()}`,
    );
    
    apiRequestImport.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    
    apiRequestImport.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
      );
      
      apiRequestImport.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headeRequest)
      );

    runEngine.sendMessage(apiRequestImport.id, apiRequestImport);
    return true;
  }

  
  apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: PeopleExportData & PeopleExportStatusData) => {
    if (apiRequestCallId == this.getImportReportApiCallId) {
      this.importProjectSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getExportPdfTokenApiCallId) {
      this.exportPdfTokenSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getExportCSVTokenApiCallId) {
      this.exportCSVTokenSuccessCallBack(responseJson)
    }
    
  };
  
  isValidResponseController = (responseJson: ValidResponseType) => {
    return responseJson && !responseJson.errors;
  };

  handleImportDataCSVResponse = async (responseJson: any) => {

    if (!responseJson?.import_token) {
      this.setState({
        hasError: responseJson?.message || responseJson.error || "Unexpected error"
      })
      return;
    }

    const userToken = (await localStorage.getItem("authToken")) || "";
    const importToken = responseJson.import_token;

    const mapProgressDownload = async (exportToken: string, userToken: string) => {
      const progessResponse = await importProjectStatus(exportToken, userToken, "tender");
  
      if (progessResponse?.progress == 100) {
        this.setState({
          isShowReport: true
        }, () => {
          this.getImportLogReport(importToken)
        })
      }

      return progessResponse?.progress
  
    };

    this.setState({peopleImportToken: importToken},()=>{
      DownloadManager.setup(importToken, {
        exportMessage: "Importing tender data",
        ignoreShownPathname: "/TenderImportData",
        trackingToken: importToken,
        outputFileConfig: {
          contentType: '',
          filename: '',
        },
        trackingDownloadProgressSync: (token) => mapProgressDownload(importToken, userToken),
        onDownloadSucess: (importToken: string) => this.navigateImportSuccessPage(importToken),
        finishTrackingDownloadSync: null
      })

      DownloadManager.startDownload(importToken)
    })
  }

  apiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    let { contentType, method, endPoint, body } = valueData;
    const token = (await localStorage.getItem("authToken")) || "";
    let headerAuth = {
      "Content-Type": contentType,
      token,
    };
    let apiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      apiRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    apiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerAuth)
    );
    runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
    return apiRequestMessage.messageId;
  };

  getImportLogReport = async (importToken: string) => {
    this.getImportReportApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.importPeopleCSVEndpoint + `?import_token=${importToken}`,
    });
  }
  navigateImportPage = (state: any) => {
    this.props.navigation.navigate("TenderImportData");
  };

  handleImportExportButtonClick = (event:any) => {
     this.setState({hasImportExportButtonClicked : !this.state.hasImportExportButtonClicked})
  };

  navigateImportSuccessPage = (importToken: string) => {
    const projectPathName = "/TenderImportData";

    if (location.pathname == projectPathName) {
      // set state instead
      this.setState({
        isShowReport: true
      }, () => {
        this.getImportLogReport(importToken)
      })
    }  else {
      this.props.navigation.history.push({
        pathname: projectPathName,
        state: { status: 'success', importToken }
      })
    }
    
  }



  exportPdfTokenSuccessCallBack = async (responseJson : PeopleExportData) =>{
    const { count } = this.props;
    const exportToken = responseJson.export_token;
    
    if(count && !!exportToken && count > 100){
      this.setState({ hasError: configJSON.pdfEmailMessage });
      return;
    }

    const userToken = await localStorage.getItem("authToken") || "";
    
    const mapProgressDownload = async (exportToken: string, userToken: string) => {
      const progessResponse = await getProgressPDFDownload(exportToken, userToken, "tender");
      
      return progessResponse?.progress
  
    };

    const mapLinkDownload = async (exportToken: string, userToken: string) => {
      const downloadResonse = await downloadPDFContent(exportToken, userToken)

      return downloadResonse?.link
    }

    this.setState({peopleExportPdfToken: exportToken},()=>{
      DownloadManager.setup(exportToken, {
        exportMessage: "Exporting tender as pdf",
        ignoreShownPathname: "/TenderImportData",
        trackingToken: exportToken,
        outputFileConfig: {
          contentType: 'application/pdf;charset=utf-8',
          filename: 'exporttender.pdf',
        },
        trackingDownloadProgressSync: (token) => mapProgressDownload(exportToken, userToken),
        finishTrackingDownloadSync: (token) => mapLinkDownload(exportToken, userToken)
      })
      DownloadManager.startDownload(exportToken);

    })
  }

  handleExportPdfToken = async () =>{
    const searchIds = this.props.searchIds || [];
    const idsQuery = searchIds.map(id => `ids[]=${id}`).join('&');
    const exportQuery = searchIds?.length > 0 ? `?activity_type=tender&${idsQuery}` : "?activity_type=tender"
    
    this.getExportPdfTokenApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.exportPdfTokenEndpoint + exportQuery,
    });
  }

  handleExportCSVToken = async () =>{
    const searchIds = this.props.searchIds || [];
    const idsQuery = searchIds.map(id => `ids[]=${id}`).join('&');
    const exportQuery = searchIds?.length > 0 ? `?activity_type=tender&${idsQuery}` : "?activity_type=tender"
    this.setState({loadingCSV: true})
    this.getExportCSVTokenApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.exportCSVTokenEndpoint  + exportQuery,
    });
  }

  exportCSVTokenSuccessCallBack = async (responseJson : PeopleExportData) =>{
    const userToken = (await localStorage.getItem("authToken")) || "";
    const exportToken = responseJson.export_token;
    
    const mapProgressDownload = async (exportToken: string, userToken: string) => {
      const progessResponse = await getProgressCSVDownload(exportToken, userToken);
      console.log(progessResponse,"progessResponse");
      
      return progessResponse?.progress
  
    };

    const mapLinkDownload = async (exportToken: string, userToken: string) => {
      const downloadResonse = await downloadCSVContent(exportToken, userToken, "tender")
      this.setState({loadingCSV: false})
      return downloadResonse?.link
    }

    this.setState({peopleExportCSVToken: exportToken}, ()=>{
      DownloadManager.setup(exportToken, {
        trackingToken: exportToken,
        ignoreShownPathname: "/TenderImportData",
        exportMessage: "Exporting tender as csv",
        outputFileConfig: {
          contentType: 'text/csv;charset=utf-8',
          filename: 'exportcsv.csv',
        },
        finishTrackingDownloadSync: (token) => mapLinkDownload(exportToken, userToken),
        trackingDownloadProgressSync: (token) => mapProgressDownload(exportToken, userToken),
      })
      DownloadManager.startDownload(exportToken);
    })
    
  }

  importProjectSuccessCallBack = (responseJson: any) => {
    try {
      const metaData = responseJson?.data.attributes; 

      this.setState({
        tenderReportMetaData: {
          lastProcessedRows: metaData.last_processed_rows,
          totalRecords: metaData.total_record,
          skippedRows: metaData.skip_errors?.map((row: any) => ({
            fieldName: row.field_name,
            error: row.error,
            row: row.row,
            value: row.value,
          })),
          overriddenRows: metaData.overridden_rows,
          skippedErrors: metaData.skip_errors?.map((row: any) => ({
            fieldName: row.value,
            row: row.row,
            error: row.error,
            value: row.value,
          })),
          recordErrors: metaData.record_errors?.map((row: any) => ({
            error: row.error,
            row: row.row,
            value: row.value,
            fieldName: row.field_name,
          })),
          summary: {
            summaryMessage: metaData.summary.summary_message,
            oveririddenRecords: metaData.summary.overridden_record,
            createdRecords: metaData.summary.created_record,
          },
          skippedMessage: metaData.skipped_message,
          importedMessage: metaData.imported_message,
          notImportMessage: metaData.not_imported_message
        },
      })
    } catch(err) {
      console.log("parse error", err)
    }
    
  }
  handleMouseLeave = () => {
    this.setState({
      hasImportExportButtonClicked: false
    })
  }
  resetImport () {
    this.setState({
      isShowReport: false,
      token: ''
    })
  }

}

import React from "react";
import {
  // Customizable Area Start
  Box,
  Modal,
  Backdrop,
  FormControlLabel,
  Divider,
  Paper,
  CircularProgress,
  Snackbar,
  // Customizable Area End
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

// Customizable Area Start
import { Close, Done, Warning } from "@material-ui/icons";
import CustomButton from "../../../components/src/CustomButton.web";
import {
  deleteButtonSvg,
  editDocButtonSvg,
} from "../../multipageforms/src/assets";
import CustomRadioButton from "../../../components/src/CustomRadioButton.web";
import {
  closeSavedSearchIcon,
  truncateText,
} from "../../filteritems/src/assets";

const CustomeModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  borderRadius: "16px",
  alignItems: "center",
  backdropFilter: "blur(4px)",
  "& .mainDiv": {
    width: "80%",
    maxWidth: "500px",
    background: "#FFF",
    color: "#000",
    position: "relative",
    borderRadius: "16px",
    height: "75%",
    maxHeight: "590px",
    display: "flex",
    flexDirection: "column",
    "& .topHeader": {
      backgroundColor: "#0094A714",
      color: "#2B3132",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "18px 60px",
      fontFamily: "Source Sans Pro",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "16px",
    },
    "& .listContainer": {
      overflowX: "hidden",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        backgroundColor: "#E8E8E8",
        height: "8px",
        borderRadius: "22px",
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#0094A7",
        borderRadius: "22px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#E8E8E8",
      },
    },
    "& .CloseSaveSeachButton": {
      position: "absolute",
      right: 0,
      top: -40,
      cursor: "pointer",
    },
    "& .noRecordFound": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
      height: "100%",
    },
    "& .lastContainer": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: "14px 50px 15px 0",
      marginTop: "auto",
      gap: "10px",
    },
    "& .snakDelete":{
      backgroundColor: "#F19D38",
      border: "1px solid #DC2626",
      borderLeftWidth: "5px",
      "@media (max-width: 600px)": {
        width: "auto"
      },
    },
      "& .snakSuccess":{
        border: "1px solid #2D97A5FC",
        backgroundColor: "#5D9E52",
        borderLeftWidth: "5px",
        "@media (max-width: 600px)": {
          width: "auto"
        },
      }
  },
});

const SearchItem = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "8px 30px",
  alignItems: "center",
  "& .radioTitle": {
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 420px)": {
      width: "100%"
    },
  },
  "& .editDeleteContainer": {
    display: "flex",
    "& .delete": {
      "&:disabled":{
        opacity: 0.5
      }
    }
  }
});

const SnackbarMsg = styled(Snackbar)({
  position: "absolute",
  bottom: "16px",
  borderRadius: "8px",
  width: "calc(100% - 68px)",
  "& .container": {
    width: "100%",
    gap: "16px",
    color: "#FFF",
    display: "flex",
    borderRadius: "8px",
    justifyContent: "space-between",
    zIndex: 5,
    alignItems: "center",
    padding: "18px",
    "& .message": {
      lineHeight: "17px",
      color: "white",
      fontSize: "15px",
      display: "flex",
      gap: "8px",
      alignItems: "center",
      fontWeight: 400,
      fontFamily: "Source Sans Pro",
      "@media (max-width: 650px)": {
        fontSize: "12px"
      },
    },
    "& .buttonContainer": {
      display: "flex",
      gap: "16px",
      "& button": {
        fontSize: "15px",
        fontFamily: "Source Sans Pro",
        textDecoration: "none",
        fontWeight: 700,
        color: "white",
        lineHeight: "17px",
      },
      "@media (max-width: 650px)": {
        "& button":{
          fontSize: "12px"
        }
      },
    },
  },
});

const CustomeDivider = styled(Divider)({
  margin: "0 30px",
  backgroundColor: "#00000038",
});

const CustomeLabel = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  "& .labelText": {
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "28px",
    color: "#0F172A",
  },
  "& .labelDate": {
    color: "#0094A7",
    fontSize: "14px",
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    lineHeight: "18px",
  },
});

// Customizable Area End

import SaveSearchAllController, {
  Props,
  configJSON,
} from "./SaveSearchAllController.web";
import Savesearch from "./Savesearch.web";

export default class SaveSearchAll extends SaveSearchAllController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    const { open, savedSearchList } = this.props;
    const { selectedId, isConfirmDelete, isLoadList, isSuccessUpdated, isEditOpen, editObj, innerWidth } =
      this.state;
    return (
      // Customizable Area Start
      <CustomeModal
        aria-labelledby="transition-modal-title"
        data-test-id="saveSearchAllPopup"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 400,
        }}
        onEscapeKeyDown={this.handleCloseAllPopup}
      >
        <Paper className="mainDIv">
          <Box
            data-test-id="close-saveSearchAllView"
            onClick={this.handleCloseAllPopup}
            className="CloseSaveSeachButton"
          >
            <img src={closeSavedSearchIcon} alt="close" />
          </Box>
          <div className="topHeader">
            <span>Title</span>
            <span>Action</span>
          </div>

          {savedSearchList.length === 0 && (
            <span data-test-id="noRecordFound" className="noRecordFound">
              No record found
            </span>
          )}

          <div className="listContainer">
            {isLoadList ? (
              <CircularProgress
                color="inherit"
                size={"4rem"}
                style={{
                  color: "black",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  translate: "-50% -50%",
                }}
              />
            ) : (
              savedSearchList.map((savedSearch: any, index: number) => {
                return (
                  <>
                    <SearchItem
                      key={`${index * 2 + 1}`}
                      data-test-id="searchItemView"
                    >
                      <div className="radioTitle">
                        <FormControlLabel
                          data-test-id={`checkBox-savedSearchListItem`}
                          onClick={() =>
                            this.handleRadioSavedSearchListItem(savedSearch.id)
                          }
                          checked={savedSearch.id === selectedId}
                          control={
                            <CustomRadioButton
                              name="savedSearch-projectmodule"
                              style={{ padding: "3px 6px !important" }}
                            />
                          }
                          label={
                            <CustomeLabel>
                              <span className="labelText">
                                {truncateText(savedSearch.search_name, (innerWidth < 600 ? 8 : 27))}
                              </span>
                              {savedSearch.created_at && (
                                <span>
                                  {truncateText(savedSearch.created_at, (innerWidth < 600 ? 8 : 27))}
                                </span>
                              )}
                            </CustomeLabel>
                          }
                          style={{ alignItems: "flex-start !important" }}
                        />
                      </div>
                      <div className="editDeleteContainer">
                        <CustomButton
                          data-test-id="editRightArrow"
                          onClick={() => this.clickEditSaveSearch(savedSearch)}
                          colorVariant="tableRowEdit"
                          disabled={isConfirmDelete}
                          style={{
                            width: "24px",
                            height: "24px",
                          }}
                        >
                          <img src={editDocButtonSvg} alt="edit" />
                        </CustomButton>

                        <CustomButton
                          data-test-id="deleteSaveSearchButton"
                          onClick={() =>
                            this.clickDeleteSaveSearch(savedSearch.id)
                          }
                          colorVariant="tableRowDelete"
                          disabled={isConfirmDelete}
                          style={{
                            width: "24px",
                            height: "24px",
                            opacity: isConfirmDelete ? 0.5 : 1
                          }}
                        >
                          <img src={deleteButtonSvg} alt="delete" />
                        </CustomButton>
                      </div>
                    </SearchItem>
                    <CustomeDivider />
                  </>
                );
              })
            )}
          </div>
          {<Savesearch data-test-id="editPopup" module={this.props.module.toLowerCase()} open={isEditOpen} savedObj={editObj} handleOpenEditPopup={this.handleOpenEditPopup} applyEditSaveSearch={this.applyEditSaveSearch} />}
          <SnackbarMsg
            data-test-id="deleteAlert"
            open={isConfirmDelete}
            className="snakDelete"
          >
            <div className="container">
              <span className="message" data-test-id="deleteAlertMsg">
                <Warning /> Are you sure you want to delete?
              </span>
              <div className="buttonContainer">
                <CustomButton
                  data-test-id="deleteSaveSearch"
                  colorVariant="noBackground"
                  onClick={() => this.deleteSavedSearchRequest()}
                >
                  DELETE
                </CustomButton>
                <CustomButton
                  data-test-id="dismissSaveSearch"
                  colorVariant="noBackground"
                  onClick={() => this.dismissDeleteSearch()}
                >
                  DISMISS
                </CustomButton>
              </div>
            </div>
          </SnackbarMsg>

          <SnackbarMsg
            open={!!isSuccessUpdated}
            onClose={() => this.setCloseSuccessUpdate()}
            autoHideDuration={2000}
            data-test-id={configJSON.successEditSavedSearchNotificationTestId}
            className="snakSuccess"
          >
            <div className="container">
              <span className="message">
                <Done />
                {isSuccessUpdated || "List has been updated successfully"}
              </span>
              <div className="buttonContainer">
                <CustomButton
                  data-test-id={configJSON.closeNotificationInSavedSearchTestId}
                  colorVariant="noBackground"
                  onClick={() => this.setCloseSuccessUpdate()}
                >
                  <Close />
                </CustomButton>
              </div>
            </div>
          </SnackbarMsg>

          <div className="lastContainer">
            <CustomButton
              data-test-id="resetButton"
              disabled={savedSearchList.length === 0 || selectedId === -1}
              onClick={() => this.resetSavedSearch()}
              colorVariant="special"
              style={{
                width: "90px",
                minWidth: "90px",
                height: "40px",
              }}
            >
              Reset
            </CustomButton>
            <CustomButton
              data-test-id="applyButton"
              disabled={savedSearchList.length === 0 || selectedId === -1}
              onClick={() => this.applySaveSearch()}
              style={{
                width: "90px",
                minWidth: "90px",
                height: "40px",
              }}
            >
              Apply
            </CustomButton>
          </div>
        </Paper>
      </CustomeModal>
      // Customizable Area End
    );
  }
}

import React from "react";
// Customizable Area Start
import {
  Box,
  Snackbar,
  Button,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core";
import {
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import { SaveRounded } from "@material-ui/icons";
import TenderModuleTableController, { Props, configJSON } from './VisualAnalyticsController';
import { projectModuleCommonStyle } from '../../multipageforms/src/assets';
import NoRecordsFoundPage from '../../../components/src/Table/NoRecordsFoundPage.web';
import TableData from "../../../components/src/Table/Table.web";
import { SaveSearchCheckBox, notifyCheckedIcon, projectModuleTableCommonStyle } from './assets';
import ProjectModuleSearch from "../../advancedsearch/src/ProjectModuleSearch.web";
import { TableLayout } from "../../../components/src/WebAppLayout/TableLayout.web";
import { AccessControl } from "../../../components/src/Permission";
import  TenderExportData  from "../../importexportdata/src/TenderExportData.web";
import { saveUncheckIcon } from "../../filteritems/src/assets";
import { CustomeSaveSearchPopper } from "../../../components/src/Popper/CustomeSaveSearchPopper.web";
// Customizable Area End

class TenderModuleTable extends TenderModuleTableController {
  constructor(props: Props) {
    super(props);
  }

// Customizable Area Start

renderNotifyMeTender= () => {
  const { isShowSaveSearchTenderLabel, isTenderSearchSaved } = this.state;
  return isTenderSearchSaved && isShowSaveSearchTenderLabel === undefined && 
    <FormControlLabel
      data-test-id={"notifyMeTender"}
      disabled={true}
      control={
        <SaveSearchCheckBox
          checkedIcon={<img src={notifyCheckedIcon} alt=""/>}
          data-test-id={'notifyMeTenderCheck'}
          value={true}
          name="notifyMe-tendermodule"
          checked={true}
        />
      }
      style={{margin: 0}}
      label={(<span style={{
        fontWeight: 600,
        color: '#2B3132',
        lineHeight: "16px",
        fontFamily: "Source Sans Pro",
        fontSize: "14px",
        fontStyle: "normal",
      }}>Notify me for new entries</span>)}
    />
}

renderTenderSaveSearchBox = () => {
  const { classes } = this.props
  const { tenderFilterObj, isTenderSearchSaved, isShowSaveSearchTenderLabel, isTenderSavingSearch, savingSearchTenderName, onSaveSearchErrorTenderMessage, onSaveSearchErrorTender } = this.state;
  const isAvailableTenderSaveSearch = Object.values(tenderFilterObj).some((array:any) => array.length > 0)
  return (
    <div className={classes.searchResultTenderContainer}>
      <span
        data-testid="searchResultsTenderText"
        className={classes.tenderSearchHeading}
      >
        {isShowSaveSearchTenderLabel === true ? "Saved Results" : "Search Results"}
      </span>
      {(isAvailableTenderSaveSearch && isShowSaveSearchTenderLabel === undefined) && (
        <FormControlLabel
          data-testid="SaveSearchTenderLable"
          className={classes.saveSearchLabel}
          control={
            <SaveSearchCheckBox
              icon={<img src={saveUncheckIcon} alt="" />}
              name="savedSearch-Tendermodule"
              value={isTenderSearchSaved}
              checkedIcon={<SaveRounded />}
              onChange={this.handleCheckedTenderSaveSearch}
              checked={isTenderSearchSaved}
              data-testid="SaveSearchTenderController"
            />
          }
          disabled={isTenderSearchSaved}
          label={
            <span
              style={{
                lineHeight: "16px",
                color: "#2B3132",
                fontStyle: "normal",
                fontFamily: "Source Sans Pro",
                fontWeight: 600,
                fontSize: "14px",
              }}
            >
              Save search
            </span>
          }
        />
      )}
      {this.renderNotifyMeTender()}
      <CustomeSaveSearchPopper 
        data-testid="tenderSaveSearchPopup"
        open={isTenderSavingSearch}
        value={savingSearchTenderName}
        onChange={this.onChangeTenderSavedSeachName}
        cancelSaveSearch={this.cancelSavingTenderSearch}
        submitSaveSearch={this.saveTenderSaveSearchRequest}
        error={onSaveSearchErrorTender}
        errorMsg={onSaveSearchErrorTenderMessage}
      />
    </div>)
}

renderTenderChild = () => {
  const { classes } = this.props;
  return (
    <Box
      data-testid={configJSON.tenderAddTestId}
      sx={projectModuleTableCommonStyle.mainWrapper}
    >
      <div>
        <ProjectModuleSearch 
        id={"AdvancedSearch"} 
        navigation={this.props.navigation}
        getProjectsResponse={this.getTenderDataSuccessCallBack}
        getProjectsRequest={this.handleTenderData}
        filterList={this.state.tenderFilterObj}
        type={"Tender"}
        data-testid="tenderAdvancedSearch"
        />
      </div>
      {this.state.isLoading ? <Box data-testId="tenderLoader" style={projectModuleCommonStyle.loadingSpinner}><CircularProgress color='inherit' size={'5rem'} /></Box> : <Box className={classes.tenderTableContainer}>
         <div style={{ display: this.state.isLoading ? 'none' : 'flex',
                      }}
            className={classes.tenderModuleBox}
          >
         <div className={classes.tenderSearchBoxActive} >
            {this.renderTenderSaveSearchBox()}
          </div>
          <div className={classes.tenderBtnBox}>
            <AccessControl 
              module="Tender" 
              feature="create"
            >
              <Button className={classes.tenderAddBtn} data-testid="tenderAddTestId" onClick={this.handleTenderAddView} >
                + Add
              </Button>
            </AccessControl>
            
            <div>
              <TenderExportData navigation={this.props.navigation} id={""} testid={"Tenderexport"} classes={{}} searchIds={this.state.searchIds} count={this.state.tenderPaginationDetails.totalLength} />
            </div>
          </div>

        </div>
        {this.state.tableTenderInputs.apiData.length > 0 ?
          <TableData
            navigation={this.props.navigation}
            tableLink='Tender'
            data-testid="containerTableTestId"
            pagination={this.state.tenderPaginationDetails}
            handlePageChange={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            onFavIconClick={(index: number) =>
              this.handleFavTenderIconClicked(index)
            }
            handleSort={(column, sortOrder) =>
              this.handleSort(column, sortOrder)}
            tableInputs={this.state.tableTenderInputs}
            id={""}
          />
            : <NoRecordsFoundPage data-testid="reloadBtn" activeModule='Tenders' loadData={this.handleLoadTenderBtn} text="No record found"/>} 
            {this.state.hasError &&  typeof this.state.hasError === 'string' && (
              <Snackbar
                  style={{ zIndex: 100000}}
                  anchorOrigin={{ vertical : "top", horizontal : "right" }}
                  open={!!this.state.hasError}
                  onClose={() => { this.setState({ hasError: ""})}}
                  message={this.state.hasError}
                  key="error-message"
                />
               )}
           </Box>}
          </Box>
      )
     }
   // Customizable Area End

   render() {
      // Customizable Area Start
       const { open, isTenderSearchSaved, isResetTenderFilters, tenderFilterObj } = this.state;
       return (
          <TableLayout
            data-testid="tender-tableLayout"
            module="Tenders" 
            open={open} 
            navigation={this.props.navigation} 
            isUpdateSaveSearch={isTenderSearchSaved}
            handleFilterList={this.handleTenderFilterObj} 
            isResetFilters={isResetTenderFilters}
            toggleOpen={this.toggleOpen}
            preFilter={tenderFilterObj}
          >
            {this.renderTenderChild()}
          </TableLayout>
    );
    // Customizable Area Start
  }
}

// Customizable Area Start
const styles = 
  createStyles({
    tenderMainContainer: {
      fontFamily: 'Source Sans Pro',
      display: 'flex',
      backgroundColor: '#F3F3F3',
      width: '100%'
    },

    tenderfirstbox:{
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      height:"100%"
    },

    tendersecondbox:{
      display: 'flex',
      flexDirection: 'column',
      padding: '16px',
      background: '#f3f3f3'
    },

    tenderTableContainer: {
      position: "relative",
      backgroundColor: "#FFF" 
    },

    tenderModuleBoxActive: {
      display: "none",
      justifyContent:"space-between",
      background: "white",
      flexDirection: "row",
      alignItems: "center",
      height: '58px'  
    },

    tenderModuleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FFF",
    height: "60px",
    padding: "0 15px",
    "@media (max-width: 573px)": {
      height: 'auto',
      flexWrap: "wrap",
    },
    "@media (max-width: 767px)": {
      padding: "0 5px",
    },
    "@media (max-width: 960px)": {
      padding: "0 10px",
    }  
    },
    tenderSearchBoxActive:{
     display : "flex",
     alignItems : "center" 
    },
    tenderSearchHeading: {
      fontFamily: "Source Sans Pro",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 600,
      width: "100%",
      maxWidth: "fit-content",
      lineHeight: "16px",
      color: '#000',
      "@media (max-width: 960px)": {
        fontSize: "17px",
      },
      "@media (max-width: 767px)": {
        fontSize: "14px",
      },
    },

    tenderBtnBox: {
      display: 'flex',
      gap: "16px",
      "@media (max-width: 573px)": {
        marginLeft: "auto",
        margin: "5px 0px 5px auto"
      },
      "@media (max-width: 960px)": {
        gap: "7px"
      }
    },

    tenderAddBtn: {
      width: "100px",
      border: "1.5px solid #01949A",
      color: "#01949A",
      textTransform: "none",
      borderRadius: '8px',
      height: 45,
      "@media (max-width: 960px)": {
        maxWidth: 50,
        minWidth: 50,
        fontSize: 12,
        height: 38,
      }
    },
    searchResultTenderContainer: {
      height: '42px',
      display: "flex",
      gap: "20px",
      alignItems: "center",
      marginRight: "14px",
      "@media (max-width: 573px)": {
        justifyContent: "space-between",
      },
      "@media (max-width: 513px)": {
        gap: "13px",
      },
      "@media (max-width: 482px)": {
        gap: "5px",
        marginRight: "10px",
      },
      "@media (max-width: 453px)": {
        gap: "0px",
      },
     
    },
    saveSearchLabel: {
      margin: 0,
    }
  
  });

// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(TenderModuleTable);
export {TenderModuleTable}
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { logoutUser, validateAll, validateAndUpdateErrors } from "../../../components/src/Utils";
import { newPassValidationSchema, passValidationSchema } from "../../../components/src/validationSchema";
// Customizable Area End

// Customizable Area Start
export interface ValidResponseType {
    message: object;
}

export interface InvalidResponseType {
    errors: string;
}

export interface ErrorPayloadType {
    key: string;
}

export interface InputEventType {
    target: {
        value: string;
    }
}
export interface HasSuccessErrorType {
    isOpen: boolean;
    isSeverity: 'success' | 'info' | 'warning' | 'error';
    isMessage: string;
}

export type ErrObject = {[errKey: string]: string}
// Customizable Area End

export const configJSON = require("./config");

export type Props = {
    // Customizable Area Start
    classes: Record<string, string>;
    navigation: any;
    // Customizable Area End
};

interface S {
    // Customizable Area Start
    changePassword: string;
    hideText: boolean;
    newPassword: string;
    confirmPassword: string;
    ChangePasswordNumber: number;
    hasSuccessError: HasSuccessErrorType;
    loginValidation: any;
    errors: ErrObject[]
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string;
    // Customizable Area End
}

export default class UserProfilePasswordChangeController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    postPasswordCheckApiCallId: string = "";
    postNewProfileChangePasswordApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            changePassword: "",
            hideText: false,
            newPassword: "",
            confirmPassword: "",
            ChangePasswordNumber: 1,
            hasSuccessError: {
                isOpen: false,
                isSeverity: "success",
                isMessage: ""
            },
            loginValidation: {},
            errors: []
            // Customizable Area End
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    // Customizable Area Start

    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            if (responseJson.status === 500) {
                return;
            }
            if (this.isChangePasswordValidResponse(responseJson)) {
                this.apiSuccessCallBacksChangePassword(apiRequestCallId, responseJson);
            } else if (this.isChangePasswordInValidResponse(responseJson)) {
                this.apiFailureCallBacksChangePassword(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    isChangePasswordValidResponse = (responseJson: ValidResponseType) => {
        return responseJson && responseJson.message;
    };

    isChangePasswordInValidResponse = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };
    // Customizable Area End

    // Customizable Area Start
    apiSuccessCallBacksChangePassword = (apiRequestCallId: string, responseJson: ValidResponseType) => {
        if (apiRequestCallId === this.postPasswordCheckApiCallId) {
            this.setState({
                ChangePasswordNumber: 2,
                errors: [],
            });
        }
        if (apiRequestCallId === this.postNewProfileChangePasswordApiCallId) {
            this.setState({
                ChangePasswordNumber: 3,
                errors: [],
            });
        }
    };

    renderProfileError = (profileKey: string) => {
        const { errors } = this.state;
		const errorProfileText = errors.find((xValue) => xValue.hasOwnProperty(profileKey))?.[profileKey]
		return errorProfileText
    }

    apiFailureCallBacksChangePassword = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.postPasswordCheckApiCallId) {
            this.setState({
                hideText: true,
                errors: [{ password: responseJson.errors}],
                ChangePasswordNumber: 1
            });
        }
        if (apiRequestCallId === this.postNewProfileChangePasswordApiCallId) {
            this.setState({
                ChangePasswordNumber: 2,
                errors: [],
                hasSuccessError: {
                    isOpen: true,
                    isSeverity: "error",
                    isMessage: responseJson.errors
                },
            });
        }
    };
    // Customizable Area End

    // Customizable Area Start
    userProfileChangePasswordApiCall = async (valueData: {
        contentType?: string;
        method?: string;
        endPoint?: string;
        body?: {};
        type?: string;
    }) => {
        let { contentType, method, endPoint, body } = valueData;
        let token = (await localStorage.getItem("authToken")) || "";
        let header = {
            "Content-Type": contentType,
            token,
        };
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    onGoBack = () => {
        if (this.state.ChangePasswordNumber === 2) {
            this.setState({
                ChangePasswordNumber: 1,
                changePassword: "",
                hideText: false,
                newPassword: "",
                confirmPassword: "",
            })
        } else {
            window.history.back();
        }
    };

    handleChangePassword = (event: InputEventType) => {
        this.setState({
            changePassword: event.target.value,
            errors: validateAndUpdateErrors(passValidationSchema, this.state.errors, "password", event.target.value)
        });
    };

    handleSubmitChangePassword = async (preventEvent: { preventDefault: () => void }) => {
        preventEvent.preventDefault();
        const validCrntPass = validateAll(passValidationSchema, {password: this.state.changePassword})
        if(validCrntPass.length > 0) {
            this.setState({
                errors: validCrntPass
            })
            return
        }
        let apiBody = {
            recent_password: this.state.changePassword.trim()
        };
        this.postPasswordCheckApiCallId = await this.userProfileChangePasswordApiCall({
            contentType: configJSON.searchApiContentType,
            method: configJSON.httpPostMethod,
            endPoint: configJSON.postPasswordCheckEndPoint,
            body: apiBody,
        });
    };

    handleNewPassword = (event: InputEventType) => {
        this.setState({
            newPassword: event.target.value,
            errors: validateAndUpdateErrors(newPassValidationSchema, this.state.errors, "newPassword", event.target.value),
        });
        this.handleValidatePassword(event.target.value)        
    };

    handleConfirmPassword = (event: InputEventType) => {
        this.setState({
            confirmPassword: event.target.value,
            errors: validateAll(newPassValidationSchema, {confirmPassword: event.target.value, newPassword: this.state.newPassword}),
        });
    };

    handleSubmitNewPassword = async (preventEvent: { preventDefault: () => void }) => {
        preventEvent.preventDefault();
        const {newPassword, confirmPassword} = this.state;
        const newPValidate = validateAll(newPassValidationSchema, {newPassword, confirmPassword});
        
        if(newPValidate.length > 0) {
            this.setState({ errors: newPValidate });
            return
        }

        let apiBody = {
            new_password: this.state.confirmPassword.trim()
        };
        this.postNewProfileChangePasswordApiCallId = await this.userProfileChangePasswordApiCall({
            contentType: configJSON.searchApiContentType,
            method: configJSON.httpPutMethod,
            endPoint: configJSON.postNewProfileChangePasswordEndPoint,
            body: apiBody,
        });
    };

    sucessfullyReset = async() => {
        await logoutUser(this.props)
    };

    handleCloseSnack = () => {
        this.setState({
            hasSuccessError: {
                isOpen: false,
                isSeverity: "success",
                isMessage: ""
            }
        })
    };
    handleValidatePassword(text: string) {
        if (text.includes(' ')) return
    
        const upperCase=/[A-Z]/.test(text)
        const lowerCase=/[a-z]/.test(text)
        const numeric=/[\d]/.test(text)
        const specialCharacter = /\W/.test(text);
        const minimumCharacter=text.length>=8
    
        this.setState(prev=>{
          return {
            loginValidation: {
                ...prev.loginValidation,
                upperCase,
                lowerCase,
                numeric,
                minimumCharacter,
                specialCharacter
            }
          }
        })
    }
    // Customizable Area End
}

import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";
import Alert from '@material-ui/lab/Alert';
import TenderImportDataController, {Props} from "./TenderImportDataController.web";
import {
    importExportIconSvg
} from "./assets";
import { AccessControl } from "../../../components/src/Permission"
import CustomButton from '../../../components/src/CustomButton.web';
import { Snackbar } from "@material-ui/core";

class TenderExportData extends TenderImportDataController {
    constructor(props: Props) {
        super(props);
    }

    renderImportExport() {
        const { classes } = this.props;
        return (<>
            <div ref={this.state.customPaperRef} style={{ position: 'relative' }}>
                <CustomButton
                    aria-haspopup="true"
                    aria-controls="dropdown-menu"
                    data-testid="exportimportBtn"
                    colorVariant="dropdownButton"
                    truncateSM = {true}
                    onClick={this.handleImportExportButtonClick}
                    startIcon={<img src={importExportIconSvg} alt='import/export' />}
                >
                    Import & Export
                </CustomButton>
                {this.state.hasImportExportButtonClicked &&
                    <div
                    className={classes.customPaper}
                    onMouseLeave={this.handleMouseLeave}
                    data-testId="export-menu"
                    >
                        <button
                            data-testid="exportAsPDF"
                            className={classes.importExportMenuItem}
                            onClick={this.handleExportPdfToken}
                        >
                            Export as PDF
                        </button>

                        <button
                            disabled={this.state.loadingCSV}
                            onClick={this.handleExportCSVToken}
                            className={classes.importExportMenuItem}
                            data-testid="exportAsCSV"
                            
                        >
                            Export as CSV
                        </button>

                        <AccessControl
                            feature="import"
                            module="Tender"
                        >
                            <button
                                onClick={this.navigateImportPage}
                                data-testid="importAsCSV"
                                className={classes.importExportMenuItem}
                            >
                                Import as CSV
                            </button>
                        </AccessControl>
                        
                    </div>
                }
            </div>
        </>);
    }

    render() {
        
        return (<>
            {this.renderImportExport()}
            {this.state.hasError && (
              <Snackbar
                data-testId="tenderSnackbar"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                style={{ border: '1px solid #4caf50', borderRadius: "4px" }}
                autoHideDuration={5000}
                open={!!this.state.hasError}
                onClose={()=>this.setState({ hasError: ""})}
              >
                <Alert data-testId="tenderSnackbarMsg" severity="success">
                  {this.state.hasError}
                </Alert>
              </Snackbar>
            )}
        </>);
    }
}

const styles = () =>
    createStyles({
        importExportMenuItem: {
            "&:disabled":{
                cursor: 'not-allowed',
                '&:hover': {
                    backgroundColor: "transparent",
                }
            },
            color: '#0094A7',
            fontSize: '14px',
            padding: '10px 20px',
            fontWeight: 400,
            fontFamily: 'Source Sans Pro',
            cursor: 'pointer',
            lineHeight: '16px',
            width: '100%',
            transition: "background-color 0.3s",
            backgroundColor:"transparent",
            border:"none",
            textAlign:"start",
            '&:hover': {
                backgroundColor: "#F0F0F0",
            }
        },

        customPaper: {
            borderRadius: '4px',
            marginTop: '4px',
            boxShadow: '-4px 4px 30px 0px rgba(0, 148, 167, 0.16)',
            backgroundColor: '#FFF',
            position: "absolute",
            border: '0.5px solid #0094A7',
            zIndex: 5,
            width:"131px",
            "@media (max-width: 600px)": {
                right: "0",
            },
        },

        progressBox:{
            marginRight: "10px"
        },
 
        exportBox: {
            justifyContent: "space-between",
            alignItems: "center",
             display: "flex",
        },
 
        exportProgressBar:{
            position:"absolute",
            bottom:0,
            left:0,
            right:0,
            top:0,
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
        }
    });


export default withStyles(styles)(TenderExportData);
export { TenderExportData };

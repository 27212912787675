import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import AccountGroupsController, {
  configJSON,
  Props,
} from "./AccountGroupsController";

export default class AccountGroups extends AccountGroupsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth="lg">
          <Box sx={webStyles.headerButtonViewStyle}>
            <Button
              data-test-id="btnAddGroupModal"
              variant="contained"
              color="primary"
            >
              {configJSON.textAddGroup}
            </Button>
            <Box sx={webStyles.secondButtonViewStyle}>
              <Button
                data-test-id="btnGetGroups"
                variant="contained"
                color="secondary"
              >
                {configJSON.textShowGroup}
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  headerButtonViewStyle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  secondButtonViewStyle: {
    marginLeft: 10,
  },
};
// Customizable Area End

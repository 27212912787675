import { Box } from '@material-ui/core';
import React, { Component } from 'react';
import CustomButton from '../CustomButton.web';
import { certificatesOptionsIcon } from '../../../blocks/multipageforms/src/assets';
import { deleteButtonSvg } from '../../../blocks/PeopleModule/src/assets';
import CustomTypographyWeb from '../CustomTypography.web';
import { uploadIconSvg } from '../../../blocks/importexportdata/src/assets';

interface Option {
  label: string,
  onClick: () => void,
  icon: any,
}

interface ClickedDropDownProps {
  options: Option[]
}

interface ClickedDropDownState {
  isOpen: boolean;
  options: Option[]
}

const cssStyles = {
  container: {
    position: 'relative',
  } as React.CSSProperties,
  dropMenu: {
    position: 'absolute',
    width: '150px',
    borderRadius: '8px',
    background: '#FFF',
    boxShadow: '2px 2px 24px 0px rgba(0, 0, 0, 0.16)',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '12px 12px 12px 0',
    "@media (max-width: 500px)": {
      width: "auto",
      padding: '5px 5px 5px 0',
    }
  } as React.CSSProperties,
  menuItem: {
    padding: '0 12px',
    justifyContent: 'flex-start'
  }
};

class ClickedDropDown extends Component<ClickedDropDownProps, ClickedDropDownState> {
  private clickDropDownRef: React.RefObject<HTMLInputElement>;

  constructor(props: ClickedDropDownProps) {
    super(props);
    this.clickDropDownRef = React.createRef();
    this.state = {
      isOpen: false,
      options: this.props.options
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: MouseEvent) => {
    const { isOpen } = this.state
    if (this.clickDropDownRef.current && !this.clickDropDownRef.current.contains(event.target as Node) && isOpen) {
      this.setState({ isOpen: false });
    }

  };

  render() {
    const { isOpen, options } = this.state;
    return (
      <div
        style={cssStyles.container}
        ref={this.clickDropDownRef}>
        <CustomButton colorVariant='noBackground' onClick={() => { this.setState({ isOpen: true }) }}>
          <img src={certificatesOptionsIcon} alt=':' height={19} width={4} />
        </CustomButton>
        {isOpen && (
          <Box sx={cssStyles.dropMenu}>
            {options.map((option: Option, index:number) => {
              return (
                <CustomButton key={index} colorVariant='noBackground' style={cssStyles.menuItem} onClick={() => { option.onClick(); this.setState({ isOpen: false }) }}>
                  <img src={option.icon} height={24} width={24} style={{ marginRight: '12px' }} />{option.label}
                </CustomButton>
              )
            })}
          </Box>
        )}
      </div>
    );
  }
}

export default ClickedDropDown;

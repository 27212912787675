import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { RowSchema, IModule } from "./assets";
import {
  capitalizeFirstLetter,
  mapConnectionTableField,
  parseErrorResponse,
} from "../../../components/src/Utils";
import { getStoredDefaultRole } from "../../../components/src/ContextAPI/AdvancedFilterContext.web";

export const configJSON = require("./config");

export interface DropDownItems {
  label: string;
  value: string;
}

// Customizable Area End

export interface Props {
  // Customizable Area Start
  testid: string;
  classes?: any;
  editMode?: boolean;
  onSuccessSave?: () => void;
  onEditCB?: () => void;
  schema: RowSchema;
  source: IModule;
  targets: IModule[];
  tabLabels: string[];
  navigation: any;
  disableJVShare?: boolean;
  maxJVShare?: number;
  id: string;
  handleRequestNavigation?: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeTab: number;
  loading: boolean;
  loadingOption: boolean;
  tableData: any[];
  orginalTableData: any[];
  deletedIds: number[];
  editMode: boolean;
  isOpenAddModal: boolean;
  addData: any;
  searchedOriginalData: any[];
  searchQuery: string;
  jvShare: number;
  hasSearchError: string;
  jvShareError: string;
  dataOptions: DropDownItems[];
  selectedTarget: string;
  showConfirmationAlert: boolean;
  showErrorAlert: string;
  showSucessAlert: boolean;
  allTableData: any[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProjectImportDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getConnectionsRequestCallId: string = "";
  postAddNewConnectionCallId: string = "";
  getSearchOptionsCallId: string = "";
  deleteConnectionsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      activeTab: 0,
      isOpenAddModal: false,
      searchQuery: "",
      dataOptions: [],
      searchedOriginalData: [],
      loading: false,
      loadingOption: false,
      tableData: [],
      orginalTableData: [],
      addData: [],
      deletedIds: [],
      editMode: false,
      selectedTarget: "",
      hasSearchError: "",
      jvShareError: "",
      jvShare: 0,
      showConfirmationAlert: false,
      showErrorAlert: "",
      showSucessAlert: false,
      allTableData: [],
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId == this.getConnectionsRequestCallId) {
        this.handleGetConnectionsSuccess(responseJson);
      }

      if (apiRequestCallId == this.getSearchOptionsCallId) {
        this.handleGetOptions(responseJson);
      }

      if (apiRequestCallId == this.postAddNewConnectionCallId) {
        this.handleAddNewSuccess(responseJson);
      }

      if (apiRequestCallId == this.deleteConnectionsCallId) {
        this.handleDeleteSuccess(responseJson);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.loadConnectionTab();

    if (this.props.editMode) {
      this.setState({
        editMode: this.props.editMode,
      });
    }
    // Customizable Area Start
  }

  // Customizable Area Start
  loadConnectionTab = async () => {
    const { source, targets, id } = this.props;
    const { activeTab } = this.state;
    const activeCategory = targets[activeTab];
    this.setState({
      loading: true,
      editMode: false,
    });

    this.getConnectionsRequestCallId = await this.apiCall({
      endPoint: `${configJSON.endpoint}?target=${activeCategory}&source=${source}&id=${id}`,
      method: "GET",
      typeContent: configJSON.validationApiContentType,
    });
  };

  handleSubmitDelete = async (deletedIds: number[]) => {
    const { source } = this.props;
    const queryDeleted = deletedIds.map((id) => `ids[]=${id}`).join("&");

    if (queryDeleted.length > 0) {
      this.deleteConnectionsCallId = await this.apiCall({
        endPoint: `${configJSON.endpoint}/${
          this.props.id
        }?${queryDeleted}&role=${getStoredDefaultRole()}&module_name=${capitalizeFirstLetter(
          source
        )}`,
        typeContent: configJSON.validationApiContentType,
        method: "DELETE",
      });
    } else {
      this.setState(
        {
          editMode: false,
        },
        () => {
          if (this.props.onSuccessSave) {
            this.props.onSuccessSave();
          }
        }
      );
    }
  };

  handleGetConnectionsSuccess = (responseJson: any) => {
    const { targets } = this.props;
    const { activeTab } = this.state;
    const activeCategory = targets[activeTab];

    if (responseJson && responseJson?.data) {
      const targetConnectionList = responseJson?.data?.map(
        (connection: any) => ({
          ...connection.attributes,
          id: connection.id,
        })
      );

      const extractConnections = targetConnectionList?.map(
        (targetConnection: any) => ({
          ...targetConnection[activeCategory],
          id: targetConnection.id,
        })
      );

      this.setState({
        tableData: extractConnections,
        orginalTableData: extractConnections,
        allTableData: extractConnections,
        loading: false,
      });
    }
  };

  handleGetOptions = (responseJson: any) => {
    const { targets } = this.props;
    const { activeTab } = this.state;
    const activeCategory = targets[activeTab];

    if (responseJson && (responseJson?.results || responseJson?.data)) {
      switch (activeCategory) {
        case "project": {
          const data = responseJson?.results?.data;
          const options = data?.map((project: any) => ({
            value: project.id,
            label: project.attributes.project_name,
          }));

          this.setState({
            dataOptions: options,
            searchedOriginalData: data,
          });
          break;
        }
        case "tender": {
          const data = responseJson?.data;
          const options = data?.map((project: any) => ({
            value: project.id,
            label: project.attributes.name,
          }));

          this.setState({
            dataOptions: options,
            searchedOriginalData: data,
          });
          break;
        }
        case "supplier": {
          const data = responseJson?.data;
          const options = data?.map((project: any) => ({
            value: project.id,
            label: project.supplier_name,
          }));

          this.setState({
            dataOptions: options,
            searchedOriginalData: data,
          });
          break;
        }
        case "partner": {
          const data = responseJson?.data;
          const options = data?.map((project: any) => ({
            value: project.id,
            label: project.attributes.company_name,
          }));

          this.setState({
            dataOptions: options,
            searchedOriginalData: data,
          });
          break;
        }
        case "people": {
          const data = responseJson?.results?.data;
          const options = data?.map((project: any) => ({
            value: project.id,
            label: project.attributes.people.name,
          }));

          this.setState({
            dataOptions: options,
            searchedOriginalData: data,
          });
          break;
        }

        default:
          return;
      }
    }

    this.setState({
      loadingOption: false,
    });
  };

  handleAddNewSuccess = (responseJson: any) => {
    if (responseJson.error) {
      this.setState({
        showErrorAlert: responseJson.error,
      });
      return;
    }

    if (responseJson.errors && responseJson.errors?.length > 0) {
      const errorText = parseErrorResponse(
        responseJson.errors,
        "joint_venture_share"
      );
      this.setState({
        jvShareError: errorText.text,
      });

      return;
    }

    if (responseJson) {
      this.setState(
        {
          isOpenAddModal: false,
          hasSearchError: "",
          jvShareError: "",
          showSucessAlert: true,
          addData: null,
        },
        () => {
          this.loadConnectionTab();
          if (this.props.onSuccessSave) {
            this.props.onSuccessSave();
          }
        }
      );
    }
  };

  handleDeleteSuccess = (responseJson: any) => {
    if (!responseJson?.error) {
      this.setState(
        {
          deletedIds: [],
          showSucessAlert: true,
          editMode: false,
        },
        () => {
          this.loadConnectionTab();
          if (this.props.onSuccessSave) this.props.onSuccessSave();
        }
      );
    } else {
      this.setState({
        showErrorAlert: responseJson.error,
      });
    }
  };

  apiCall = async (valueData: {
    typeContent?: string;
    apiType?: string;
    method?: string;
    endPoint?: string;
    type?: string;
    body?: any;
  }) => {
    let { typeContent, method, endPoint, body } = valueData;
    const authToken = (await localStorage.getItem("authToken")) || "";
    let headerAuth = {
      "Content-Type": typeContent,
      token: authToken,
    };
    let messageAPI = new Message(getName(MessageEnum.RestAPIRequestMessage));

    messageAPI.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    messageAPI.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    messageAPI.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerAuth)
    );

    body &&
      messageAPI.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    runEngine.sendMessage(messageAPI.id, messageAPI);
    return messageAPI.messageId;
  };

  handleAdd = () => {
    this.setState({
      isOpenAddModal: true,
      jvShareError: "",
      hasSearchError: "",
      jvShare: 0,
      selectedTarget: "",
    });
  };

  closeModal = () => {
    this.setState({
      isOpenAddModal: false,
    });
  };

  handleTabChange = (tab: number) => {
    this.setState(
      {
        activeTab: tab,
        isOpenAddModal: false,
        hasSearchError: "",
        jvShareError: "",
        jvShare: 0,
        dataOptions: [],
      },
      () => {
        this.loadConnectionTab();
      }
    );
  };

  handleDeleteDismiss = () => {
    this.handleClose();
    this.setState({
      tableData: this.state.allTableData,
      deletedIds: []
    });
  };

  handleDeleteItem = (targetRow: any) => {
    const { deletedIds, tableData, activeTab } = this.state;
    const { targets } = this.props;
    const activeCategory = targets[activeTab];
    const rowKeyId = configJSON.postParams[activeCategory];

    this.setState({
      deletedIds: [...deletedIds, Number.parseInt(targetRow.id)],
      tableData: tableData.filter(
        (row) =>
          row[configJSON.postParams[activeCategory]] != targetRow[rowKeyId]
      ),
    });
  };

  handleCancelDelete = () => {
    this.setState({
      deletedIds: [],
      tableData: this.state.orginalTableData,
    });
  };

  handleSearchChange = async (value: string) => {
    const { targets } = this.props;
    const { activeTab } = this.state;
    const activeCategory = targets[activeTab];

    const queryParam = configJSON.mapParams[activeCategory].replace(
      "{{ q }}",
      value
    );
    this.setState({
      loadingOption: true,
      hasSearchError: "",
    });

    this.getSearchOptionsCallId = await this.apiCall({
      endPoint: `${configJSON.dataSearchSources[activeCategory]}?${queryParam}`,
      method: "GET",
      typeContent: configJSON.validationApiContentType,
    });
  };

  showConfirmation = () => {
    const { deletedIds } = this.state;
    if (deletedIds.length > 0) {
      this.setState({
        showConfirmationAlert: true,
      });
    } else {
      this.handleSaveChanges();
    }
  };

  handleSaveChanges = () => {
    const { tableData, deletedIds } = this.state;
    const addIds = tableData.filter(
      (tableData: any) => Number.parseInt(tableData.id) < 0
    );

    const deletedIDs = deletedIds.filter((deletedId) => deletedId > 0);
    this.handleClose();

    if (addIds.length > 0) {
      this.handleSubmitAdd(addIds);
    }

    if (deletedIDs.length > 0) {
      this.handleSubmitDelete(deletedIDs);
    }

    if (addIds.length == 0 && deletedIDs.length == 0) {
      this.toggleEditMode();
    }
  };

  handleJVChange = (event: any) => {
    this.setState({
      jvShare: +event.target?.value,
      jvShareError: "",
    });
  };

  handleTemporaryAdd = () => {
    const { addData, tableData, activeTab, jvShare } = this.state;
    const { targets, maxJVShare } = this.props;
    const activeCategory = targets[activeTab];
    // handle validation
    const hasExistOnConnectionTable = tableData.find(
      (connectionData) =>
        connectionData[configJSON.postParams[activeCategory]] ==
        addData[configJSON.postParams[activeCategory]]
    );

    let errorCount = 0;

    if (hasExistOnConnectionTable) {
      errorCount++;
      this.setState({
        hasSearchError: "Record is existed, please choose another",
      });
    }
    const sumJVShare = tableData.reduce((acc: number, currentValue: any) => {
      return acc + Number.parseFloat(currentValue?.jv_share || 0);
    }, 0);

    const newSumJVShare = sumJVShare + (jvShare || 0);
    const safeMaxJVShare = maxJVShare || 0;

    if (newSumJVShare > safeMaxJVShare && jvShare) {
      errorCount++;
      this.setState({
        jvShareError:
          "Partners JV share should be less or equals to ProjectJV share",
      });
    }

    let addableRecord =
      typeof addData.services === "string"
        ? addData
        : {
            ...addData,
            services: addData.services
              ?.map((service: any) => service.name || service.attributes.name)
              .join(", "),
          };

    addableRecord =
      typeof addableRecord.sector === "string"
        ? addableRecord
        : {
            ...addableRecord,
            sector: addData?.sector?.name,
          };

    if (activeCategory == "partner") {
      addableRecord["jv_share"] = jvShare ? jvShare : null;
    }

    if (errorCount == 0) {
      this.setState({
        tableData: [...tableData, addableRecord],
        allTableData: [...tableData, addableRecord],
        isOpenAddModal: false,
      });
    }
  };

  handleSubmitAdd = async (addedData: any[]) => {
    const { source, targets, id } = this.props;
    const { activeTab } = this.state;
    const activeCategory = targets[activeTab];
    let body: any[] = [];
    switch (activeCategory) {
      case "tender":
      case "people":
      case "supplier":
      case "project":
        body = addedData.map((connection) => ({
          [configJSON.postParams[source]]: id,
          [configJSON.postParams[activeCategory]]:
            connection[configJSON.postParams[activeCategory]],
        }));

        break;
      case "partner":
        if (source == "project") {
          body = addedData.map((connection) => ({
            [configJSON.postParams[source]]: id,
            [configJSON.postParams[activeCategory]]:
              connection[configJSON.postParams[activeCategory]],
            joint_venture_share: connection.jv_share,
          }));
        } else {
          body = addedData.map((connection) => ({
            [configJSON.postParams[source]]: id,
            [configJSON.postParams[activeCategory]]:
              connection[configJSON.postParams[activeCategory]],
          }));
        }

        break;
      default:
        
    }
    this.postAddNewConnectionCallId = await this.apiCall({
      endPoint: `${
        configJSON.endpoint
      }?role=${getStoredDefaultRole()}&module_name=${capitalizeFirstLetter(
        source
      )}`,
      method: "POST",
      typeContent: configJSON.validationApiContentType,
      body: {
        connection: body,
      },
    });
  };

  handleClose = () => {
    this.setState({
      showConfirmationAlert: false,
      showErrorAlert: "",
      showSucessAlert: false,
    });
  };

  handleSelect = (targetId: string) => {
    const { searchedOriginalData, activeTab } = this.state;
    const { targets } = this.props;
    const activeCategory = targets[activeTab];
    const selectedData = searchedOriginalData?.find(
      (target) => target.id == targetId
    );

    let mappedAddConnection;

    switch (activeCategory) {
      case "partner":
        mappedAddConnection = mapConnectionTableField(selectedData, {
          name: "attributes.company_name",
          country: "attributes.company_detail.country",
          sector: "attributes.compnay_detail.sector",
          services: "attributes.company_detail.service",
          internal_rate: "attributes.internal_rating",
          [configJSON.postParams[activeCategory]]: "id",
        });

        break;
      case "people":
        mappedAddConnection = mapConnectionTableField(selectedData, {
          name: "attributes.people.name",
          related_to: "attributes.relation",
          unrelated: "attributes.relation",
          [configJSON.postParams[activeCategory]]: "id",
        });
        break;
      case "project":
        mappedAddConnection = mapConnectionTableField(selectedData, {
          name: "attributes.project_name",
          country: "attributes.company_detail.data.attributes.country",
          sector: "attributes.company_detail.data.attributes.sector",
          group_company:
            "attributes.company_detail.data.attributes.group_company",
          client: "attributes.company_detail.data.attributes.client",
          services: "attributes.company_detail.data.attributes.services.data",
          internal_rate:
            "attributes.company_detail.data.attributes.internal_rating",
          type: "attributes.service_type.data.attributes.name",
          jv_share: "attributes.joint_venture_share",
          [configJSON.postParams[activeCategory]]: "attributes.id",
        });
        break;
      case "supplier":
        mappedAddConnection = mapConnectionTableField(selectedData, {
          name: "supplier_name",
          sector: "sector",
          services: "services",
          internal_rate: "internal_rate",
          [configJSON.postParams[activeCategory]]: "id",
        });
        break;
      default:
        mappedAddConnection = mapConnectionTableField(selectedData, {
          name: "attributes.name",
          group_company:
            "attributes.company_detail.data.attributes.group_company",
          country: "attributes.company_detail.data.attributes.country",
          client: "attributes.company_detail.data.attributes.client",
          services: "attributes.company_detail.data.attributes.services.data",
          [configJSON.postParams[activeCategory]]: "attributes.id",
        });
        break;
    }

    mappedAddConnection = {
      id: -Date.now(),
      ...mappedAddConnection,
      ...(activeCategory == "people" &&
        mappedAddConnection.related_to && {
          related_to: mappedAddConnection.related_to.filter(
            (relate: any) => relate.relation == "related"
          ),
          unrelated: mappedAddConnection.related_to.filter(
            (relate: any) => relate.relation == "unrelated"
          ),
        }),
    };

    this.setState({
      selectedTarget: targetId,
      addData: mappedAddConnection,
      jvShareError: "",
      hasSearchError: "",
    });
  };

  toggleEditMode = () => {
    const { editMode } = this.state;

    this.setState(
      {
        editMode: !editMode,
      },
      () => {
        this.handleCancelDelete();
      }
    );
  };
  // Customizable Area End
}

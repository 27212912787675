import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Divider,
  FormControlLabel,
  Backdrop,
  Modal,
  Paper,
  CircularProgress,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import CustomTypographyWeb from "../../../components/src/CustomTypography.web";
import {
  CustomSaveSearchInput,
} from "../../visualanalytics/src/assets";
import CustomButton from "../../../components/src/CustomButton.web";
import { CheckboxStateEnum, NotifyCheckBox, notifyCheckedIcon, notifyUnheckedIcon } from "./assets";
import { closeSavedSearchIcon, filterDropdownIcon, saveFilterIcon } from "../../filteritems/src/assets";
import FilterDropDown from "../../filteritems/src/FilterDropDown.web";

const EditSaveSearchModal = styled(Modal)({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  backdropFilter: "blur(4px)",
  borderRadius: "16px",
  "& .mainContent": {
    width: "80%",
    maxWidth: "410px",
    background: "#FFF",
    color: "#000",
    position: "relative",
    borderRadius: "16px",
    maxHeight: "365px",
    display: "flex",
    flexDirection: "column",
    padding: "26px 45px 40px 45px",
    "@media (max-width: 960px)": {
      margin: " 0 40px",
    },
    "@media (max-width: 767px)": {
      padding: "26px"
    },
    "& .firstContainer": {
      display: "flex",
      flexDirection: "column",
      "& .appliedButton": {
        marginBottom: "8px",
        display: "flex",
        gap: "10px",
        justifyContent: "flex-end",
        alignItems: "center",
        "& .applied": {
          display: "flex",
          alignItems: "center",
          color: 'black',
          fontSize: "14PX",
          fontFamily: 'Source Sans Pro',
          fontWeight: 400,
          lineHeight: "16PX",
          "& .filterCountBadge": {
            borderRadius: "35px",
            padding: "0px 8px",
            color: "white",
            marginLeft: "4px",
            fontFamily: "Source Sans Pro",
            backgroundColor: "#3695A7",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0em",
            textAlign: "left",
          },
        },
      },
      "& .titleContainer": {
        display: "flex",
        gap: "12px",
        alignItems: "center",
        marginBottom: "20px",
        "& .required": {
          backgroundColor: "#EAFBFD",
          borderRadius: "16px",
          width: "67px",
          height: "26px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#0094A7",
          textAlign: "right",
          fontFamily: "Inter",
          fontSize: "12px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "18px",
        },
      },
      "& .input": {
        marginBottom: "28px",
        "& .MuiInputBase-root":{
          height: "52px",
          "& .PrivateNotchedOutline-root-67":{
            borderColor: "#C9CDCD"
          }
        }
      },
    },
    "& .CloseEditSaveSeachButton": {
      right: 0,
      cursor: "pointer",
      top: -40,
      position: "absolute",
    },
  },
})

export const EditFilterButton = styled(Button)({
  color: '#0094A729',
  backgroundColor: '#0094A729',
  width: '132px',
  height: '38px',
  borderRadius: '8px',
  textTransform: 'none',
  "&:hover":{
    backgroundColor: '#0094A729',
  },
  "& .text":{
    color: '#0094A7',
    fontFamily: 'Source Sans Pro',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '16px',
    opacity: 1,
  },
  "& .MuiButton-label":{
    display: "flex", 
    alignItems: 'center',
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  "& .MuiButton-endIcon": {
    marginRight: "6px",
    marginLeft: "auto",
    marginTop: "2px",
  },
  "& .MuiButton-startIcon": {
    marginRight: "11px",
    marginLeft: "3px",
  }
})

// Customizable Area End

import SavesearchController, {
  Props,
  configJSON,
} from "./SavesearchController";

export default class Savesearch extends SavesearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderFilters = () => {
    return (
      <span style={{ position: "relative"}}>
        <EditFilterButton
          data-test-id="filterButton"
          endIcon={<img src={filterDropdownIcon} style={{transform: this.state.isOpenFilter ? "rotate(180deg)" : "rotate(0deg)"}} alt="" />}
          startIcon={<img src={saveFilterIcon} style={{ height: "20px", width: "20px"}} alt=""/>}
          onClick={()=>this.setState({ isOpenFilter: true})}
        >
          <Typography className="text" >
            Filter
          </Typography>
        </EditFilterButton>
        {this.state.isOpenFilter &&
            <FilterDropDown 
              data-test-id="filterDropDownPopup"
              open={this.state.isOpenFilter} 
              handleClose={this.handleCloseEditFilter} 
              module={this.props.module}
              handleGetFilter={this.handleSetFilters} 
              savedFilters={this.state.filterList} 
            />
          }
      </span>
    )
  }


  // Customizable Area End

  render() {
    const { isEditError, editErrorMsg, inputField, filterCounter, filterList, notifyMe, isLoadUpdate } = this.state;
    const { open, savedObj} = this.props;
    const oldNotify = this.props.savedObj[CheckboxStateEnum[this.props.module as keyof typeof CheckboxStateEnum]];
    const isDisabled = inputField === savedObj.search_name && JSON.stringify(filterList) === JSON.stringify(savedObj.filters) && (oldNotify === notifyMe)
    return (
      // Customizable Area Start
      <EditSaveSearchModal
        aria-labelledby="transition-modal-title"
        data-test-id="saveSearchAllPopup"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 400,
        }}
        onEscapeKeyDown={this.handleCloseIcon}
      >
        <Paper className="mainContent">
          <Box
            data-test-id="closeEditSaveSearch"
            onClick={this.handleCloseIcon}
            className="CloseEditSaveSeachButton"
          >
            <img src={closeSavedSearchIcon} alt="close" />
          </Box>
          <div className="firstContainer">
            <div className="appliedButton">
              <div className="applied">
                Applied
                {
                  <span
                    className="filterCountBadge"
                    data-test-id="filterCounter"
                  >
                    {filterCounter}
                  </span>
                }
              </div>
              {this.renderFilters()}
            </div>
            <div className="titleContainer">
              <CustomTypographyWeb isHeader>Title</CustomTypographyWeb>
              <Typography className="required">Required</Typography>
            </div>
            <CustomSaveSearchInput
              data-test-id="inputField"
              fullWidth
              className="input"
              placeholder="Enter name to this search for future"
              onChange={this.handleChangeInputValue}
              value={inputField}
              variant="outlined"
              error={isEditError}
              helperText={isEditError && editErrorMsg}
            />
            <Divider style={{ backgroundColor: "#c9cdcd" }} />
            <div style={{ margin: "18px 0 40px 0" }}>
              <FormControlLabel
                data-test-id="emailNotify"
                control={
                  <NotifyCheckBox
                    icon={<img src={notifyUnheckedIcon} alt="unchecked" />}
                    checkedIcon={<img src={notifyCheckedIcon} alt="checked" />}
                    checked={notifyMe}
                    name="savedSearch-peoplemodule"
                    onChange={this.handleNotifyChange}
                  />
                }
                label={
                  <span
                    style={{
                      fontFamily: "Source Sans Pro",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "16px",
                      color: "#2B3132",
                    }}
                  >
                    Notify me for new entries
                  </span>
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "16px",
            }}
          >
            <CustomButton
              data-test-id=""
              colorVariant="special"
              onClick={this.handleCloseIcon}
            >
              Cancel
            </CustomButton>
            <CustomButton
              data-test-id="saveButton"
              onClick={this.editSavedSearchRequest}
              disabled={isDisabled}
            >
              Save
            </CustomButton>
          </div>
          {isLoadUpdate && (
            <CircularProgress
              color="inherit"
              size={"4rem"}
              style={{
                color: "black",
                position: "absolute",
                top: "50%",
                left: "50%",
                translate: "-50% -50%",
              }}
            />
          )}
        </Paper>
      </EditSaveSearchModal>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End

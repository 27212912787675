import React from "react";
// Customizable Area Start
import {
  Button,
  Box,
  CircularProgress,
  Typography,
  Grid,
  Snackbar
} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import Alert from '@material-ui/lab/Alert';
import { auditMessageIcon } from "./assets";
import moment from "moment";
import 'moment-timezone';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { changeHtmltoText } from '../../../components/src/Utils';
import AuditTrailFilterDropdown from "../../../components/src/Chart/AuditTrialFilterDropdown.web";
// Customizable Area End

import Audittrail2Controller, {
  Props,
  // Customizable Area Start
  AuditTrailGetlistType,
  configJSON,
  // Customizable Area End
} from "./Audittrail2Controller";

class AuditTrail extends Audittrail2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAuditTrail = () => {
    const { classes } = this.props;
    const { linkList, range, isFilterOpen } = this.state;
    return (<>
      <Box className={classes.atMainContainer}>
        <Box sx={{ display:"flex", flexDirection:"row", alignItems: "center", justifyContent:"space-between", marginBottom:"15px"}}>
          <Typography
            className={classes.auditheadingbox}
            data-testId="auditTrailTestId"
          >Audit trail</Typography>
          <AuditTrailFilterDropdown
            data-testId="audit_filter"
            name="audit_filter"
            open={isFilterOpen}
            onOpen={this.handleFilterOpen}
            value={range}
            linkList={linkList}
            filterOptions={configJSON.FilterOptions}
            onChange={this.handleFilterValue}
            position="left"
          />
        </Box>
        <Box>

          {this.state.auditTrailList.length > 0 ?
            <Box className={classes.atContainer}>
              {this.state.auditTrailList.map((item: AuditTrailGetlistType, index: number) => {
                return (
                  <Box key={index} className={classes.atInnerMainBox}>
                    <Grid container spacing={0} >
                      <Grid item xs={12} sm={11} md={10} lg={10} xl={10} className={classes.atGridFirst}>
                        <Box className={classes.atDateTimeBox}>
                          <Typography className={classes.atDate}>{`Last updated (${moment(item.created_at, "YYYY-MM-DD HH:mm:ss Z").tz('America/New_York').format("DD/MM/YYYY")})`}</Typography>
                          <Typography className={classes.atTime}>{moment(item.created_at, "YYYY-MM-DD HH:mm:ss Z").tz('America/New_York').format("hh:mm A")}</Typography>
                        </Box>
                        <Box className={classes.atSepratorBox}>
                          <Box className={classes.atSepratorLineBox}></Box>
                          <Box className={classes.atatSepratorImgBox}>
                            <img alt="" src={auditMessageIcon} className={classes.imgIcon} />
                          </Box>
                        </Box>
                        <Box className={classes.atContentBox}>
                          <Typography className={classes.atActionHeading}>{`Actioned by : ${item.actioned_by}`}</Typography>
                          <Box className={classes.atActionContentBox} >
                            <ul className={classes.ContentListBox}>
                              <Typography className={classes.atActionContentHeader}>{changeHtmltoText(item.message[0])}</Typography>
                              {item.message.slice(1).map((message, index) => {
                                return (
                                  <li key={index} className={classes.atActionContent} dangerouslySetInnerHTML={{__html: changeHtmltoText(message).replace(/\n/g, "<br />")}} />
                                )
                              })}
                            </ul>
                          </Box>
                          <Box className={classes.atTraigleLeft}></Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )
              })}
            </Box>
            :
            <Box className={classes.noFoundDataBox}>
              <Typography className={classes.noFoundContent}>No record found</Typography>
            </Box>
          }
        </Box>
        <Box className={classes.paginationbox}>
          {this.renderPagination()}
        </Box>
        {this.state.hasSuccessError.isOpen && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={this.state.hasSuccessError.isOpen}
            onClose={this.handleCloseSnack}
            autoHideDuration={6000}
          >
            <Alert data-testid="snackBarCloseTestId" onClose={this.handleCloseSnack} severity={this.state.hasSuccessError.isSeverity}>
              {this.state.hasSuccessError.isMessage}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </>)
  }
  // Customizable Area End

  // Customizable Area Start
  renderPagination = () => {
    const { classes } = this.props;
    return (<>
      <div className={classes.paginationContainer}>
        <div className={classes.paginationNavigation}>
          <div className={classes.paginationOfResults}>
            {this.state.currentPage} of{" "}
            {this.state.totalPages} Pages
          </div>
          <div className={classes.paginationButtonsContainer}>
            <Button
              data-testId="previousPaginationButton"
              className={`${classes.paginationButtons} ${this.state.previousPage === null
                ? ""
                : classes.onHoverBtn
                }`}
              onClick={this.handlePreviousPage}
              disabled={this.state.previousPage === null || this.state.isLoading}
              variant="contained"
            >
              <ArrowBackIosIcon
                className={`${classes.paginationBackArrow} ${this.state.previousPage === null
                  ? classes.paginationBtnDisabled
                  : classes.paginationBtnEnabled
                  }`}
              />
            </Button>
            <div>
              {"0" + this.state.currentPage}
            </div>
            <Button
              data-testId="nextPaginationButton"
              className={`${classes.paginationButtons} ${this.state.nextPage === null
                ? ""
                : classes.onHoverBtn
                }`}
              onClick={this.handleNextPage}
              disabled={
                this.state.nextPage === null || this.state.isLoading}
              variant="contained"
            >
              <ArrowForwardIosIcon
                className={`${this.state.nextPage === null
                  ? classes.paginationBtnDisabled
                  : classes.paginationBtnEnabled
                  }`}
              />
            </Button>
          </div>
        </div>
      </div>
    </>)
  }
  // Customizable Area End 

  render() {
    // Customizable Area Start
    return (<>
      
      { this.state.isLoading ?
      <Box display="flex" height="100%" justifyContent="center" alignItems="center">
        <CircularProgress
            color="inherit"
            size={"3rem"}
            style={{ color: "black", display: "inline-block" }}
          />
      </Box>
      :
      this.renderAuditTrail()
      }
    </>);
    // Customizable Area End
  }
}

// Customizable Area Start
const styles =
  createStyles({
    auditheadingbox: {
      color: '#1F1F1F',
      fontSize: '22px',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: "0.5px",
      marginBottom: '10px',
      fontFamily: 'Source Sans Pro , sans-serif'
    },

    atMainContainer: {
      marginTop: "30px",
      fontFamily: 'Source Sans Pro , sans-serif'
    },

    atContainer: {
      width: "100%",
    },

    atInnerMainBox: {
      marginBottom: "50px"
    },

    atGridFirst: {
      display: "flex",
      "@media (max-width: 575px)": {
        display: "block",
      },
    },

    atDateTimeBox: {
      color: "#858585",
      marginTop: "25px",
      textAlign: "right",
      "@media (max-width: 575px)": {
        marginTop: "0px",
        textAlign: "unset",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "5px"
      },
    },

    atDate: {
      width: "152px",
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: 400,
      letterSpacing: "0.3px",
      fontFamily: 'Source Sans Pro , sans-serif'
    },

    atTime: {
      fontSize: "12px",
      lineHeight: "16px",
      fontWeight: 400,
      letterSpacing: "0.3px",
      fontFamily: 'Source Sans Pro , sans-serif'
    },

    atSepratorBox: {
      display: "flex",
      marginLeft: "12px",
      position: "relative",
      "@media (max-width: 575px)": {
        display: "none",
      },
    },

    atSepratorLineBox: {
      width: "2px",
      height: "100%",
      backgroundColor: "#9F9F9F",
      position: 'relative',
      left: "18px",
      top: "8px",
      zIndex: 1,
      paddingBottom: "80px",
    },

    atatSepratorImgBox: {
      width: "36px",
      height: "36px",
      backgroundColor: "#F3F3F3",
      borderRadius: "50%",
      border: "2px",
      borderColor: "#E6E6E6",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 20,
      marginTop: "25px",
      boxShadow: "rgba(0, 0, 0, 0.12) 0px 2px 1px -1px, rgba(0, 0, 0, 0) 0px 1px 1px 0px, rgba(0, 0, 0, 0) 1px 1px 1px 1px"
    },

    imgIcon: {
      height: "25px",
      width: "25px",
    },

    atContentBox: {
      width: "100%",
      color: "#707070",
      position: "relative",
      marginLeft: "50px",
      "@media (max-width: 575px)": {
        marginLeft: "0px",
      },
    },

    atActionHeading: {
      fontSize: "12px",
      marginLeft: "2px",
      lineHeight: "16px",
      fontWeight: 600,
      letterSpacing: "0.3px",
      fontFamily: 'Source Sans Pro , sans-serif'
    },

    atActionContentBox: {
      padding: "20px",
      backgroundColor: "#F3F3F3",
      overflow: 'hidden',
      borderRadius: "5px",
      boxShadow: "rgba(0, 0, 0, 0.12) 0px 2px 1px -1px, rgba(0, 0, 0, 0) 0px 1px 1px 0px, rgba(0, 0, 0, 0) 1px 1px 1px 1px"
    },

    atTraigleLeft: {
      width: "0px",
      height: "0px",
      position: "absolute",
      top: "32px",
      left: "-25px",
      borderTop: "16px solid transparent",
      borderRight: "28px solid #F3F3F3",
      borderBottom: "16px solid transparent",
      borderRadius: "3px 0 0 3px",
    },

    ContentListBox: {
      marginLeft: "-25px",
      marginTop: "5px",
      overflow: 'hidden',
      marginBottom: "5px",
    },

    atActionContentHeader: {
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: 600,
      fontFamily: 'Source Sans Pro , sans-serif',
      marginBottom: "10px",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      wordBreak: "break-word",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 20,
      WebkitBoxOrient : "vertical"
    },

    atActionContent: {
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: 600,
      fontFamily: 'Source Sans Pro , sans-serif',
      marginLeft: "15px",
      marginBottom: "10px",
      overflowWrap: "break-word",
      wordWrap: "break-word",
      wordBreak: "break-word",
      "& p img": {
        width: '100%'
      }
    },

    noFoundDataBox: {
      width: "100%",
      height: "80vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    noFoundContent: {
      fontSize: "18px",
      fontWeight: 900,
      color: "#9F9F9F",
      fontFamily: 'Source Sans Pro , sans-serif'
    },

    loadingSpinner: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "calc(50vh)",
      color: "black",
    },

    paginationbox: {
      marginTop: "90px"
    },

    paginationContainer: {
      marginTop: "4px",
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      gap: "36px",
      fontSize: "12px",
      fontWeight: 600,
      "& .MuiSelect-iconOutlined": {
        right: "0 !important",
      },
      "& .MuiSelect-outlined.MuiSelect-outlined": {
        paddingLeft: "5px !important",
        paddingRight: "14px",
        paddingTop: "5px",
        paddingBottom: "5px",
      },
    },

    rowPerPageContainer: {
      "& .MuiSelect-iconOutlined-158": {
        right: "0 !important",
      },
      "& .MuiSelect-outlined-153.MuiSelect-outlined-153": {
        paddingLeft: "5px !important",
        paddingRight: "14px",
        paddingTop: "5px",
        paddingBottom: "5px",
      },
    },

    paginationNavigation: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      gap: "8px",
    },

    paginatinoButtons: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      gap: "4px",
    },

    paginationOfResults: {
      fontSize: "12px",
      fontWeight: 600,
    },

    paginationSelect: {
      fontSize: "12px",
    },

    select: {
      "& fieldset": {
        border: "0.5px solid #0094A7 !important",
      },
      "& div": {
        "&:focus": {
          background: "none",
        },
        paddingRight: "7px !important",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingLeft: "6px",
      },

      fontSize: "12px",
    },

    paginationButtons: {
      backgroundColor: "#3696A7",
      padding: "9px",
      color: "white",
      width: "24px",
      minWidth: "24px",
      maxWidth: "24px",
      height: "24px",
      minHeight: "24px",
      maxHeight: "24px",
      "& .MuiSvgIcon-root": {
        fontSize: "15px",
      },
      "&.Mui-disabled": {
        backgroundColor: "#B9DDE1"
      }
    },

    paginationBackArrow: {
      paddingLeft: "7px",
    },

    paginationBtnDisabled: {
      fontSize: "17px",
      color: "white",
      backgroundColor: "#B9DDE1",
      "&.Mui-disabled": {
        backgroundColor: "#B9DDE1"
      }
    },

    paginationBtnEnabled: {
      fontSize: "17px",
      color: "white",
      backgroundColor: "#3695A7",
    },

    textWrap: {
      textWrap: "nowrap",
    },

    onHoverBtn: {
      "&:hover": {
        backgroundColor: "#3695A7",
      },
    },

    paginationButtonsContainer: {
      display: "flex",
      alignItems: "center",
      gap: "16px",
    },

    tableContainer: {
      backgroundColor: "#fff",
      "&::-webkit-scrollbar": {
        backgroundColor: "#E8E8E8",
        borderRadius: "22px",
        height: "12px",
        width: "12px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#E8E8E8",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#0094A7",
        borderRadius: "22px",
      },
    },
  })
// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(AuditTrail);
export { AuditTrail };
// Customizable Area End
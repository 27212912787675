// Customizable Area Start
import React from "react";
import RequestDataTableController, {
  Props,
  configJSON,
} from "./RequestDataTableController.web";
import {
  Box,
  Button,
  CircularProgress,
  Hidden,
  Typography,
  styled,
} from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";

import RequestTable from "../../../components/src/Table/RequestTable.web";
import ResponsiveTopBar from "../../../components/src/TopBar/ResponsiveTopBar.web";
import NoRecordsFoundPage from "../../../components/src/Table/NoRecordsFoundPage.web";
import RequestFilterComponent from "./RequestFilterComponent.web";
// Customizable Area End

class RequestDataTable extends RequestDataTableController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderSwitchTab = () => {
    const { tabValue } = this.state;
    return (
      <Box className="switchTabs">
        <Button
          style={{
            color:
              tabValue === configJSON.requestRaised ? "white" : "#1F1F1F80",
            backgroundColor:
              tabValue === configJSON.requestRaised ? "0094A7" : "transparent",
          }}
          data-test-id="raisedTab"
          onClick={() => this.handleSwitchTab(configJSON.requestRaised)}
        >
          {configJSON.requestRaised}
        </Button>
        <Button
          data-test-id="receivedTab"
          onClick={() => this.handleSwitchTab(configJSON.requestReceived)}
          style={{
            backgroundColor:
              tabValue === configJSON.requestReceived
                ? "0094A7"
                : "transparent",
            color:
              tabValue === configJSON.requestReceived ? "white" : "#1F1F1F80",
          }}
        >
          {configJSON.requestReceived}
        </Button>
      </Box>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      requestTableInputs,
      requestPaginationDetails,
      isRequestListLoading,
      tabValue,
      filterReqAnchor,
      filterOptions,
      requestFilterObj,
      appliedCounter,
    } = this.state;
    return (
      <>
        <Hidden mdUp>
          <ResponsiveTopBar navigation={this.props.navigation} title={"Menu"} />
        </Hidden>
        <MainDiv>
          <Box className="container">
            <Box className="topHeader">
              <Box className="requestTitle">
                <KeyboardBackspace
                  data-test-id="requestBack"
                  onClick={this.handleBackButton}
                />
                <Typography className="title">
                  {configJSON.pageTitle}
                </Typography>
              </Box>
              <Button data-test-id="requestAdd" onClick={this.handleRequestAdd}>
                {configJSON.raiseRequest}
              </Button>
            </Box>
            <Box className="tableContainer">
              <Box className="header">
                {this.renderSwitchTab()}
                <div className="appliedBtn">
                  {appliedCounter > 0 && (
                    <div className="appliedText">
                      Applied
                      {
                        <span
                          className="appliedCountBadge"
                          data-test-id="filterCounter"
                        >
                          {appliedCounter}
                        </span>
                      }
                    </div>
                  )}
                  <RequestFilterComponent
                    data-test-id="filterComponent"
                    anchorEl={filterReqAnchor}
                    accordian={filterOptions}
                    requestFilterObj={requestFilterObj}
                    handleApplyFilters={this.handleApplyRequestFilters}
                    handleResetFilters={this.handleResetReqFilters}
                    handleOpenFilter={this.handleOpenReqFilter}
                    handleCloseFilter={this.handleCloseFilter}
                    handleDateRange={this.handleRequestDateRange}
                    handleCheckChange={this.handleReqCheckBoxChange}
                  />
                </div>
              </Box>
              {isRequestListLoading ? (
                <Box data-test-id="loader" className="requestLoader">
                  <CircularProgress color="inherit" size={"5rem"} />
                </Box>
              ) : (
                <>
                  {requestTableInputs.apiData.length > 0 ? (
                    <RequestTable
                      navigation={this.props.navigation}
                      data-test-id="containerTable"
                      pagination={requestPaginationDetails}
                      handlePageChange={this.handleChangePage}
                      tableInputs={requestTableInputs}
                      id="request-table"
                      handleMorevertMenuClick={this.handleRedirectViewEdit}
                      enabledTable={tabValue}
                    />
                  ) : (
                    <NoRecordsFoundPage
                      data-test-id="reloadBtn"
                      activeModule={tabValue}
                      loadData={this.handleLoadRequestBtn}
                      text="No record found"
                      style={{
                        minHeight: "60vh",
                      }}
                    />
                  )}
                </>
              )}
            </Box>
            <Box style={{ color: "#F3F3F3" }}>.</Box>
          </Box>
        </MainDiv>
      </>
    );
    // Customizable Area End
  }
}
// Customizable Area Start

const MainDiv = styled(Box)({
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#F3F3F3",
  overflowY: "scroll",
  scrollbarWidth: "none",
  overflowX: "hidden",
  height: "calc(100vh - 65px)",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  width: "100%",
  "& .container": {
    display: "flex",
    flexDirection: "column",
    padding: "20px 0",
    gap: "16px",
    "@media (max-width: 960px)": {
      width: "90% !important",
    },
    maxWidth: "1111px",
    width: "80%",
    backgroundColor: "#F3F3F3",
    "& .topHeader": {
      justifyContent: "space-between",
      backgroundColor: "#FFFFFF",
      height: "100%",
      display: "flex",
      maxHeight: "51px",
      flexDirection: "row",
      padding: "6px",
      gap: "16px",
      alignItems: "center",
      borderRadius: "8px",
      "& .requestTitle": {
        display: "flex",
        marginLeft: "30px",
        flexDirection: "row",
        gap: "15px",
        "& .MuiSvgIcon-root": {
          height: "24px",
          cursor: "pointer",
          width: "24px",
        },
        "& .title": {
          textAlign: "center",
          fontFamily: "Source Sans Pro",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "24px",
        },
      },
      "& button": {
        display: "flex",
        width: "140px",
        alignItems: "center",
        fontSize: "16px",
        lineHeight: "16px",
        fontFamily: "Source Sans Pro",
        color: "white",
        height: "50px",
        borderRadius: "8px",
        fontWeight: 600,
        padding: "10px",
        textTransform: "none",
        justifyContent: "center",
        gap: "10px",
        flexShrink: 0,
        background: "#0094A7",
      },
    },
    "& .tableContainer": {
      backgroundColor: "#FFFFFF",
      height: "auto",
      alignItems: "center",
      rowGap: "30px",
      display: "flex",
      flexDirection: "column",
      borderRadius: "8px",
      padding: "35px 50px",
      "@media (max-width: 960px)": {
        padding: "30px 40px",
      },
      "@media (max-width: 640px)": {
        padding: "25px",
      },
      "@media (max-width: 420px)": {
        padding: "20px",
      },
      "& .header": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        columnGap: "30px",
        rowGap: "18px",
        width: "100%",
        justifyContent: "space-between",
        "& .switchTabs": {
          width: "100%",
          maxWidth: "340px",
          alignItems: "center",
          height: "48px",
          display: "flex",
          flexDirection: "row",
          backgroundColor: "#0094A729",
          flexShrink: 0,
          borderRadius: "8px",
          "@media (max-width: 640px)": {
            maxWidth: "280px",
          },
          "& button": {
            alignItems: "center",
            borderRadius: "8px",
            fontSize: "16px",
            width: "100%",
            height: "100%",
            lineHeight: "16px",
            fontFamily: "Source Sans Pro",
            justifyContent: "center",
            fontWeight: 600,
            padding: "10px",
            display: "flex",
            textTransform: "none",
            "@media (max-width: 640px)": {
              maxWidth: "280px",
              fontSize: "14px",
            },
          },
        },
        "& .appliedBtn": {
          gap: "15px",
          display: "flex",
          justifyContent: "flex-end",
          marginLeft: "auto",
          alignItems: "center",
          "& .appliedText": {
            fontSize: "14PX",
            fontFamily: "Source Sans Pro",
            alignItems: "center",
            color: "black",
            fontWeight: 400,
            display: "flex",
            lineHeight: "16px",
            "& .appliedCountBadge": {
              padding: "0px 8px",
              color: "white",
              marginLeft: "4px",
              fontFamily: "Source Sans Pro",
              letterSpacing: "0em",
              fontSize: "12px",
              textAlign: "left",
              backgroundColor: "#3695A7",
              borderRadius: "35px",
              fontWeight: 400,
              lineHeight: "16px",
            },
          },
        },
      },
      "& .requestLoader": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "calc(40vh)",
        color: "black",
      },
    },
  },
});

export default RequestDataTable;
export { RequestDataTable };
// Customizable Area End

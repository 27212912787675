// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Hidden,
  Divider,
  Link as NavigationTag
} from '@material-ui/core';
import {createStyles, withStyles } from "@material-ui/styles";
import RequestViewDetailsController, {
  Props,
  configJSON,
  RequestCommentData
} from "./RequestViewDetailsController.web";
import { KeyboardBackspace } from "@material-ui/icons";
import ResponsiveTopBar from "../../../components/src/TopBar/ResponsiveTopBar.web";
import CustomTypography from '../../../components/src/CustomTypography.web';
// Customizable Area End

class RequestViewDetails extends RequestViewDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  uploadFileRef = React.createRef<HTMLInputElement>();

  requestGoBack = () =>{
    const { classes } = this.props;
     return (<>
         <Box className={classes.container}>
           <Box className={classes.topHeader}>
             <Box className={classes.requestTitle}>
               <KeyboardBackspace onClick={this.handleBackButton} />
               <Typography className={classes.title} data-testId= "requestViewTestId">
                 {configJSON.raiseRequestTitle}
               </Typography>
             </Box>
           </Box>
         </Box>
     </>)
  }

  requestDeatilsData = () =>{
    const { classes } = this.props;
    const viewData = this.state.requestData;
    const requestComment = viewData?.commentData
     return (<>
       <Box className={classes.containerMiddle}>
        <RowBox>
					<Grid container className={classes.gridGap}>
						<Grid item xs={12} sm={4}>
							<Box className={classes.viewComponent}>
								<CustomTypography isLabel className={classes.requestViewlabelTop} >Company name </CustomTypography>
								<CustomTypography isContent>{viewData?.companyName}</CustomTypography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={4}>
							<Box className={classes.viewComponent}>
								<CustomTypography isLabel className={classes.requestViewlabelTop} >Role</CustomTypography>
								<CustomTypography isContent>
                   {viewData?.role}
								</CustomTypography>
							</Box>
						</Grid>
					</Grid>
				</RowBox>
				<RowBox>
					<Grid item xs={12} sm={4}>
						<Box className={classes.viewComponent}>
							<CustomTypography isLabel className={classes.requestViewlabelTop} >Choose Module</CustomTypography>
							<CustomTypography isContent>{viewData?.moduleName}</CustomTypography>
						</Box>
					</Grid>
				</RowBox>
        <Divider />

        <RowBox>
					<Grid item xs={12} sm={10}>
						<Box className={classes.viewComponent}>
							<CustomTypography isLabel className={classes.requestViewlabelTop} data-testId="requestCommentlabelTestId" >Record Type</CustomTypography>
						</Box>
            <Box className={classes.existingBox}>
              {viewData?.existingRecord ? 
              <>
              <Typography className={classes.existingHeading}>Existing</Typography>
              <Box className={classes.existingInputBox}>
              <Box onClick={this.handleExistRecordNavigation}>
              <Typography className={classes.existingInputText}>{viewData?.existingRecord}</Typography>
              </Box>
              <NavigationTag id={"anchorID"} className={classes.exisitngViewLink} ></NavigationTag> 
              </Box>
              </>
              :
              <>
              <Typography className={classes.existingHeading}>New</Typography>
              <Box className={classes.existingInputBox}>
              <Typography className={classes.newInputText} dangerouslySetInnerHTML={{ __html: viewData?.newRecord.replace(/\n/g, "<br />")}} />
              </Box>
              </>
            }
            {requestComment?.map((commentData: RequestCommentData )=>{
                const comment = commentData?.attributes
              return(
                <Box>
                <Box className={classes.commentReadBox}>
                  <Typography className={classes.commentReadContent} data-testId="commentContent" dangerouslySetInnerHTML={{__html: comment?.comment.replace(/\n/g, "<br />")}} />
                </Box>
                <Button className={classes.commentFileBtn} data-testId="requestFileUploadTestId" onClick={()=>this.handleCommentFile(comment?.file?.link)}>{comment?.file?.file_name}</Button>
                </Box>
              )
            })}            
            </Box>
					</Grid>
				</RowBox>

       </Box>
     </>)
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    return <>
    <Box className={classes.MainBox}>
      <Hidden mdUp>
          <ResponsiveTopBar navigation={this.props.navigation} title={"Menu"} />
      </Hidden>
    <Box className={classes.viewMainContainer}>
       {this.requestGoBack()}
    </Box>
      <Box className={classes.viewMainContainer}>
      <Box className={classes.requestMiddleContainer}>
        {this.requestDeatilsData()}
      </Box>
      </Box>
    </Box>
    </>;
    // Customizable Area End
  }
}
// Customizable Area Start
const RowBox = withStyles({
  root: {
   display: 'flex',
   alignItems: 'flex-start',
   flexDirection: 'row',
   fontFamily: "Source Sans Pro",
   fontSize: "18px",
   fontStyle: "normal",
   fontWeight: 700,
   lineHeight: "24px",
   padding: "0 35px"
  },
   })(Box);

const styles = createStyles({
  MainBox : {
    backgroundColor: "#F3F3F3",
    overflowY: "scroll",
    scrollbarWidth: "none",
    overflowX: "hidden",
    height: "calc(100vh - 65px)",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    width: "100%",
    "@media (max-width: 960px)": {
      height : "100vh",
      paddingBottom : "50px"
    },      
  },

  viewMainContainer : {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#F3F3F3",
    width: "100%",
  },

  requestViewlabelTop :{
    marginBottom : "7px",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontWeight: 600
  },

  gridGap : {
    "@media (max-width: 600px)": {
      gap : "20px"
    },
  },

  container : {
    display: "flex",
    flexDirection: "column",
    padding: "20px 0",
    gap: "16px",
    "@media (max-width: 960px)": {
      width: "90% !important",
    },
    width: "80%",
    backgroundColor: "#F3F3F3",
  },

  topHeader : {
    justifyContent: "space-between",
    backgroundColor: "#FFFFFF",
    height: "100%",
    display: "flex",
    maxHeight: "51px",
    flexDirection: "row",
    padding: "16px 0",
    gap: "16px",
    alignItems: "center",
    borderRadius: "8px",
  },

  requestTitle : {
    display: "flex",
    marginLeft: "30px",
    flexDirection: "row",
    gap: "15px",
    "& .MuiSvgIcon-root": {
      height: "24px",
      cursor: "pointer",
      width: "24px",
  }
 },

 title : {
  textAlign: "center",
  fontFamily: "Source Sans Pro",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "24px",
 }, 

  requestMiddleContainer: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    scrollbarWidth: "none",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "@media (max-width: 960px)": {
      width: "90% !important",
    },
  },

 containerMiddle : {
  display: "flex",
  flexDirection: "column",
  padding: "20px 0",
  gap: "16px",
  "@media (max-width: 960px)": {
    width: "100% !important",
  },
  backgroundColor: "#FFF",
  borderRadius: "8px",
  marginBottom: "50px"
},

existingBox : {
  width: "100%",
  padding: "0 10px",
  "@media (max-width: 600px)": {
    padding: "0",
  },
},

existingHeading : {
 fontFamily : "Source Sans Pro",
 fontSize : "16px",
 fontWeight : 400,
 color: "#2B3132"
},

existingInputBox: {
  width : "80%",
  borderRadius : "8px",
  display: "flex",
  justifyContent: "start",
  alignItems: "centre",
  padding: "15px 20px",
  backgroundColor: "#F3F3F3",
  marginTop: "10px"
},

existingInputText: {
  fontFamily : "Source Sans Pro",
 fontSize : "16px",
 fontWeight : 400,
 color: "#0094A7",
 cursor: "pointer",
 textDecoration: "underline",
},

newInputText: {
  fontFamily : "Source Sans Pro",
 fontSize : "16px",
 fontWeight : 400,
 color: "#2B3132"
},

commentReadBox: {
  width: "80%",
  minHeight: "86px",
  border: "1px #9F9F9F solid",
  borderRadius: "8px",
  padding: "15px 20px",
  marginTop: "10px",
  "@media (max-width: 960px)": {
    width: "80% !important",
  },
},

commentReadContent: {
  fontFamily : "Source Sans Pro",
  fontSize : "14px",
  fontWeight : 400,
  color: "#2B3132",
  opacity: "56%"
},

commentFileBtn: {
  border: "none",
  background: "none",
  textDecoration: "underline",
  color: "#0094A7",
  fontFamily : "Source Sans Pro",
  fontSize : "16px",
  fontWeight : 400,
  marginTop : "7px",
  textTransform : "capitalize",
  marginLeft: "-5px"
},

commentInput: {
  width : "100%",
  height : "125px",
  border : "1px #9F9F9F solid",
  borderRadius : "8px",
  padding : "10px",
  fontFamily : "Source Sans Pro",
  fontSize : "14px",
  fontWeight : 400,
  color: "#2B3132",
},

updateSaveCancelBtn: {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: '20px',
  marginTop: '20px',
},

uploadFile : {
 display : "flex",
 color: "#0094A7",
 alignItems: "center",
 marginTop: "10px"
},

attachFileText: {
  marginLeft : "10px",
  fontFamily : "Source Sans Pro",
  fontSize : "16px",
  fontWeight : 400,
},

exisitngViewLink: {
  display : "none"
}

});
export default withStyles(styles)(RequestViewDetails);
export { RequestViewDetails };
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { getProgressCSVDownload, downloadCSVContent, getProgressPDFDownload, downloadPDFContent, importProjectStatus } from "../../../components/src/Utils"
import { DownloadManager } from "../../../components/src/PollProgressBar/DownloadManager"
import { getStoredDefaultRole } from "../../../components/src/ContextAPI/AdvancedFilterContext.web";

export const configJSON = require("./config");


export interface ValidResponseType {
  data: object;
  errors: Array<ErrorPayloadType>;
}
export interface ErrorPayloadType {
  key: string;
  message: string;
}

export interface PartnerExportData {
  message: string,
  export_token: string
}

export interface PartnerImportData {
  message: string,
  import_token: string,
  error?: string;
}

export interface PartnerExportStatusData {
  status: string,
  progress: number,
  file_type: string
}

export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}

export interface PartnerExportStatusData {
  status: string,
  progress: number,
  file_type: string
}

export interface Props {
  navigation: any;
  id: string;
  classes: Record<string, string>;
  searchIds?: number[];
  testid: string;
  count?: number;
}

interface S {
  selectedFile: File | null;
  loadingJson: boolean;
  open: boolean
  token: string;
  isSkipped: boolean
  loadingCSV: boolean;
  isFileImported: boolean
  isShowReport: boolean,
  isLoading: boolean;
  hasError: string
  hasImportExportButtonClicked: boolean;
  peopleExportPdfToken: string;
  partnerExportCSVToken: string;
  reportMetaData: ReportMetaData | null,
  partnerImportToken: string;
  hasSuccessError: HasSuccessErrorType;
  customPaperRef:  React.RefObject<HTMLInputElement>;
}

interface RecordErrorReport {
  fieldName: string;
  value: null | string;
  error: string
  row: number;
}

interface ReportMetaData {
  totalRecords: number;
  skippedRows: number;
  summary: {
    summaryMessage: string,
    createdRecords: string,
    oveririddenRecords: string
  },
  importedMessage: string;
  recordErrors: RecordErrorReport[],
  skippedErrors: RecordErrorReport[],
  lastProcessedRows: number;
  notImportMessage: string;
  overriddenRows: number;
  skippedMessage: string;
}


interface SS {
  id: any;
}

export default class PartnerImportDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  importPartnerDataCSVCallId: string = "";
  getExportPartnerPdfTokenApiCallId : string | null = null;
  getExportPartnerCSVTokenApiCallId : string | null = null;
  getImportPartnerReportApiCallId: string | null = null;
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess)
    ];

    this.state = {
      hasError: '',
      loadingCSV: false,
      isSkipped: true,
      open: true,
      hasImportExportButtonClicked: false,
      selectedFile: null,
      isFileImported: false,
      loadingJson: false,
      partnerImportToken: "",
      customPaperRef: React.createRef(),
      partnerExportCSVToken:"",
      token: "",
      peopleExportPdfToken:"",
      isLoading: false,
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      isShowReport: false,
      reportMetaData: null,
    };
    runEngine.attachBuildingBlock(
      this as IBlock, 
      this.subScribedMessages
    );
  }


  async componentDidMount() {
    super.componentDidMount();
    const { status: importStatus, importToken } = this.props.navigation.history.location?.state || {};

    if (importStatus == 'success') {
      this.setState({ isShowReport: true }, () => {
        this.getImportLogReport(importToken)
      })
    }
    
  }

  handleGoBack = () => {
    this.props.navigation.navigate("PartnersModuleTable")
  };


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      if (this.importPartnerDataCSVCallId !== "" && this.importPartnerDataCSVCallId === apiRequestCallId) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.handleImportPartnerCSVResponse(responseJson)
      }

    } 
     
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let requestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
     
      if (responseJson && !responseJson.errors) {
        if (this.isValidResponseController(responseJson)) {
          this.apiSuccessController(requestCallId, responseJson);
        }
      }
    }
  }

  handleError = (error: string) => {
    this.setState({
      hasError: error
    })
  }

  handleImportFile = (fileUpload: File, isSkip: boolean) => {
    this.setState({
      isLoading: true
    })
    const formUploadData = new FormData();
    const method = isSkip ? 'skip' : 'override'

    if (fileUpload instanceof File) {
      formUploadData.append('file', fileUpload)
    } else {
      this.setState({
        hasError: 'File not selected!'
      })
      return false;
    }

    formUploadData.append("activity_type", "partner");
    formUploadData.append("method", method);

    const headeRequest = {
      token:
        typeof window !== 'undefined'
          ? localStorage.getItem('authToken')
          : null
    };

    const apiRequestImport = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.importPartnerDataCSVCallId = apiRequestImport.messageId;

    apiRequestImport.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_data_import_export/export/import_csv?role=${getStoredDefaultRole()}`,
    );

    apiRequestImport.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headeRequest)
    );

    apiRequestImport.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    apiRequestImport.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formUploadData
    );

    runEngine.sendMessage(apiRequestImport.id, apiRequestImport);
    return true;
  }

  isValidResponseController = (responseJson: ValidResponseType) => {
    return responseJson && !responseJson.errors;
  };

  apiSuccessController = (apiRequestCallId: string, responseJson: PartnerExportData & PartnerExportStatusData) => {
    if (apiRequestCallId === this.getExportPartnerPdfTokenApiCallId) {
      this.exportPdfTokenCallBack(responseJson)
    }
    if (apiRequestCallId === this.getExportPartnerCSVTokenApiCallId) {
      this.exportCSVTokenCallBack(responseJson)
    }

    if (apiRequestCallId == this.getImportPartnerReportApiCallId) {
      this.importPartnerCallBack(responseJson)
    }
  };


  handleImportPartnerCSVResponse = async (response: PartnerImportData) => {

    if (!response?.import_token) {
      this.setState({
        hasError: response?.message || response.error || "Unexpected error"
      })
      return;
    }

    const importToken = response.import_token;
    const userToken = (await localStorage.getItem("authToken")) || "";

    const mapDownload = async (exportToken: string, userToken: string) => {
      const progessResponse = await importProjectStatus(exportToken, userToken, "partner");

      if (progessResponse?.progress == 100) {
        this.setState({ isShowReport: true }, () => {
          this.getImportLogReport(importToken)
        })
      }

      return progessResponse?.progress
  
    };

    this.setState({partnerImportToken: importToken},()=>{
      DownloadManager.setup(importToken, {
        ignoreShownPathname: "/PartnerImportData",
        trackingToken: importToken,
        exportMessage: "Importing partner data",
        outputFileConfig: {
          contentType: '',
          filename: '',
        },
        finishTrackingDownloadSync: null,
        trackingDownloadProgressSync: (token) => mapDownload(importToken, userToken),
        onDownloadSucess: (importToken: string) => this.navigateImportSuccess(importToken),
      })

      DownloadManager.startDownload(importToken)
    })
  }

  apiCall = async (valueData: {
    type?: string;
    endPoint?: string;
    method?: string;
    body?: {};
    contentType?: string;
    apiType?: string;
  }) => {
    let { endPoint, body, contentType, method } = valueData;
    const authToken = (await localStorage.getItem("authToken")) || "";
    let headerAuth = {
      "Content-Type": contentType,
      token: authToken,
    };
    let messageAPI = new Message(getName(MessageEnum.RestAPIRequestMessage));
    messageAPI.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
    messageAPI.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
    body &&
      messageAPI.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    messageAPI.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerAuth)
    );

    runEngine.sendMessage(messageAPI.id, messageAPI);
    return messageAPI.messageId;
  };

  getImportLogReport = async (token: string) => {
    this.getImportPartnerReportApiCallId = await this.apiCall({
      endPoint: configJSON.importPeopleCSVEndpoint + `?import_token=${token}`,
      method: 'GET',
      contentType: configJSON.validationApiContentType,
    });
  }

  handleExportButtonClick = () => {
     this.setState({hasImportExportButtonClicked : !this.state.hasImportExportButtonClicked})
  };

  navigateImport = () => {
    this.props.navigation.navigate("PartnerImportData");
  };

  navigateImportSuccess = (importToken: string) => {
    const pathName = "/PartnerImportData";

    if (location.pathname == pathName) {
      // set state instead
      this.setState({ isShowReport: true }, () => {
        this.getImportLogReport(importToken)
      })
    }  else {
      this.props.navigation.history.push({
        pathname: pathName,
        state: { 
          status: 'success', 
          importToken 
        }
      })
    }
    
  }


  exportPdfToken = async () =>{
    const ids = this.props.searchIds || [];
    const idsQuery = ids.map(id => `ids[]=${id}`).join('&');
    const exportQuery = ids?.length > 0 ? `?activity_type=partner&${idsQuery}` : "?activity_type=partner"
    
    this.getExportPartnerPdfTokenApiCallId = await this.apiCall({
      endPoint: configJSON.exportPdfTokenEndpoint + exportQuery,
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
    });
  }

  exportPdfTokenCallBack = async (response : PartnerExportData) =>{
    const exportToken = response.export_token;
    
    const { count } = this.props;
    if(!!exportToken && count && count > 100){
      this.setState({
        hasError: configJSON.pdfEmailMessage
      })
      return
    }
    
    const token = (await localStorage.getItem("authToken")) || "";

    const mapProgressDownload = async (exportToken: string, token: string) => {
      const progessResponse = await getProgressPDFDownload(exportToken, token, "partner");
      
      return progessResponse?.progress
  
    };

    const mapLinkDownload = async (exportToken: string, token: string) => {
      const downloadResonse = await downloadPDFContent(exportToken, token)

      return downloadResonse?.link
    }

    this.setState({peopleExportPdfToken: exportToken},()=>{
      DownloadManager.setup(exportToken, {
        exportMessage: "Exporting partner as pdf",
        finishTrackingDownloadSync: (_) => mapLinkDownload(exportToken, token),
        ignoreShownPathname: "/PartnerImportData",
        trackingToken: exportToken,
        trackingDownloadProgressSync: (_) => mapProgressDownload(exportToken, token),
        outputFileConfig: {
          filename: '',
          contentType: 'application/pdf;charset=utf-8'
        },
      })
      DownloadManager.startDownload(exportToken);

    })
  }


  exportCSVToken = async () =>{
    const ids = this.props.searchIds || [];
    const idsQuery = ids.map(id => `ids[]=${id}`).join('&');
    const exportQuery = ids?.length > 0 ? `?activity_type=partner&${idsQuery}` : "?activity_type=partner"

    this.getExportPartnerCSVTokenApiCallId = await this.apiCall({
      endPoint: configJSON.exportCSVTokenEndpoint  + exportQuery,
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
    });
  }

  exportCSVTokenCallBack = async (responseJson : PartnerExportData) =>{
    const exportToken = responseJson.export_token;
    const token = (await localStorage.getItem("authToken")) || "";
    
    const mapProgressDownload = async (exportToken: string, token: string) => {
      const response = await getProgressCSVDownload(exportToken, token);
  
      return response?.progress
  
    };

    const mapLinkDownload = async (exportToken: string, token: string) => {
      const response = await downloadCSVContent(exportToken, token, "partner")
      return response?.link
    }

    this.setState({partnerExportCSVToken: exportToken}, ()=>{
      DownloadManager.setup(exportToken, {
        finishTrackingDownloadSync: (_) => mapLinkDownload(exportToken, token),
        exportMessage: "Exporting partner as csv",
        trackingToken: exportToken,
        ignoreShownPathname: "/PartnerImportData",
        trackingDownloadProgressSync: (_) => mapProgressDownload(exportToken, token),
        outputFileConfig: {
          filename: '',
          contentType: 'text/csv;charset=utf-8'
        },
      })
      DownloadManager.startDownload(exportToken);
    })
  }

  importPartnerCallBack = (responseJson: any) => {
    try {
      const dataS = responseJson?.data.attributes; 

      this.setState({
        reportMetaData: {
          skippedRows: dataS.skip_errors?.map((row: any) => ({
            row: row.row,
            fieldName: row.field_name,
            value: row.value,
            error: row.error
          })),
          lastProcessedRows: dataS.last_processed_rows,
          totalRecords: dataS.total_record,
          skippedErrors: dataS.skip_errors?.map((row: any) => ({
            error: row.error,
            value: row.value,
            fieldName: row.value,
            row: row.row
          })),
          overriddenRows: dataS.overridden_rows,
          recordErrors: dataS.record_errors?.map((row: any) => ({
            error: row.error,
            value: row.value,
            fieldName: row.field_name,
            row: row.row,
          })),
          notImportMessage: dataS.not_imported_message,
          summary: {
            oveririddenRecords: dataS.summary.overridden_record,
            createdRecords: dataS.summary.created_record,
            summaryMessage: dataS.summary.summary_message
          },
          importedMessage: dataS.imported_message,
          skippedMessage: dataS.skipped_message,
        },
      })
    } catch(err) {
      console.error("parse error", err)
    }
    
  }

  resetImport () {
    this.setState({ isShowReport: false, token: ''  })
  }

  handleLeaveMouse = () => {
    this.setState({  hasImportExportButtonClicked: false })
  }

}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from "yup";
import { AdvancedFilterContext } from "../../../components/src/ContextAPI/AdvancedFilterContext.web";
import { validateAll, validateAndUpdateErrors } from "../../../components/src/Utils";
import { loginValidationSchema } from "../../../components/src/validationSchema";

type Errors = { [key: string]: string}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  checkedRememberMe: boolean;
  errors: Errors[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  labelTitle: string = "";
  contextType = AdvancedFilterContext;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      checkedRememberMe: false,
      errors: []
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    localStorage.getItem("ProfertEmail") &&
      this.setState({ email: localStorage.getItem("ProfertEmail")! });
    localStorage.getItem("ProfertPassword") &&
      this.setState({ password: localStorage.getItem("ProfertPassword")! });
    // Customizable Area End
  }

  // Customizable Area Start
  setInput = (text: string | boolean, value: any, errKey?:string) => {
    // if (typeof text === "string" && text.includes(" ")) return;
    this.setState({ [value]: text } as any);
    if(errKey){
      this.setState({
        errors: validateAndUpdateErrors(loginValidationSchema, this.state.errors, errKey, text)
      })
    }
  };

  checkErrorMessage = ( responseJson: {errors: Errors[]} ) => {
    const { errors } = responseJson;
    if(errors){
      this.setState({
          errors: errors
      });
    }
  }

  renderLoginError = (errorKey: string) => {
		const { errors } = this.state;
		const erroeLoginText = errors.find((xValue) => xValue.hasOwnProperty(errorKey))?.[errorKey]
		return erroeLoginText
	}

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiEmailLoginCallId) {
          if (responseJson && responseJson.meta && responseJson.meta.token) {
            if (this.state.checkedRememberMe) {
              localStorage.setItem("ProfertEmail", this.state.email);
              localStorage.setItem("ProfertPassword", this.state.password);
            } else {
              localStorage.removeItem("ProfertEmail");
              localStorage.removeItem("ProfertPassword");
            }
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
            this.saveLoggedInUserData(responseJson);
            this.sendLoginSuccessMessage();
            this.redirectToPeopleData();
          } else {
            //Check Error Response
            this.checkErrorMessage(responseJson);
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  redirectToPeopleData() {
    const toPeople = new Message(getName(MessageEnum.NavigationMessage));
    toPeople.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    toPeople.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toPeople);
  }

  sendLoginFailMessage() {
    const failedmsg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(failedmsg);
  }

  sendLoginSuccessMessage() {
    const successMsg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    successMsg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    successMsg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    successMsg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    successMsg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(successMsg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      localStorage.setItem("full_name", responseJson.meta.full_name);
      localStorage.setItem("profile_photo", responseJson.meta.profile_photo);
      const savedmsg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      savedmsg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      savedmsg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      const userInfo = {
        defaultRole: responseJson.meta.default_role,
        firstTimeLogin: responseJson.meta.first_time_login,
        fullName: responseJson.meta.full_name,
        id: responseJson.meta.id,
        profilePhoto: responseJson.meta.profile_photo,
        roles: responseJson.meta.roles
      };

      localStorage.setItem("currentUser", JSON.stringify(userInfo));

      this.context?.setGlobalContext({
        currentUser: userInfo
      })
      
      this.send(savedmsg);
    }
  }

  goToForgotPassword() {
    // Merge Engine - Navigation - btnForgotPassword - Start
    const formsg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    formsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    formsg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(formsg);
    // Merge Engine - Navigation - btnForgotPassword - End
  }

  async doEmailLogIn() {

    const { email, password} = this.state;
    const isValidate = validateAll(loginValidationSchema, { failed_login: email, invalid_password: password })
    if(isValidate.length > 0) {
      this.setState({errors: isValidate})
      return
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const emaildata = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: emaildata,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  // Customizable Area End
}


EmailAccountLoginController.contextType =  AdvancedFilterContext
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.txtInputPlaceholder = "Enter Text";
exports.labelTitleText = "OfflineWork";
exports.labelBodyText = "OfflineWork Body";
exports.ChooseProfilePicture = "Choose Profile Picture";
exports.UploadPicture = "Upload Picture"

exports.btnExampleTitle = "CLICK ME";

exports.apiEndPointSubmitImage = "bx_block_offlinework/attachments";
exports.apiEndPointForOfflineWork = "bx_block_offlinework/attachments"


exports.contentType = "application/json";
exports.apiGetMethod = "GET";
exports.apiPostMethod = "POST";
exports.offlineImage= "image in offline";
exports.uploadImageHere= "please upload an image here";
exports.serverImage = "image from server:";
exports.noServerImage= "no picture on the server";

  exports.attachmentResponse = [
      {
          "attachment_id": 14,
          "url": "https://2c0a-103-177-131-217.ngrok-free.app/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBIdz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f08e2030e8f9c83b69cb7261f91863dd0ce30534/vpn-config.png",
      }
  ]

// Customizable Area End
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { validateAll, validateAndUpdateErrors } from "../../../components/src/Utils";
import { forgotPassValidationSchema, newPassValidationSchema, otpPassValidationSchema } from "../../../components/src/validationSchema";

// Customizable Area Start
interface Error {
  [errKey: string]: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  classes?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  confirmPassword: string;
  newPassword: string;
  accountType: string;
  successPage: boolean;
  accountStatus: any;
  emailValue: any;
  phoneValue: any;
  token: any;
  otp: any;
  errors: Error[]
  isExpire: boolean;
  loginValidation: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;
  requestEmailOtpCallId: any;
  requestChangePasswordCallId: any;
  requestGoToConfirmationCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      confirmPassword: "",
      newPassword: "",
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      successPage: false,
      token: "",
      errors: [],
      isExpire: false,
      loginValidation:{upperCase:false,
        specialCharacter:false,
        lowerCase:false,
        numeric:false,
        minimunCharacter:false}
    };
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const email = localStorage.getItem('Email');
    const token = localStorage.getItem('Token');
    if(window.location.pathname.toLocaleLowerCase() !== 'ForgotPassword') {
      this.setState({ emailValue: email || "", token: token || ""});
      if(!email) {
        this.props.navigation.navigate("ForgotPassword")
      }
    } 
  }

  async receive(from: string, message: Message) {
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (responseJson && responseJson.errors) {
      this.setState({ errors : responseJson.errors });
      return;
    }

    try {
      if (this.isNavigationPayloadMessage(message)) {
        this.handleNavigationPayloadMessage(message);
      } else if (this.isRestApiResponseMessage(message)) {
        this.handleRestApiResponseMessage(message, responseJson);
      }
    } catch (eventE) {
      this.setState({ errors : [{otp: "Something went wrong"}] });
    }
  }

  isNavigationPayloadMessage(message: Message): boolean {
    return getName(MessageEnum.NavigationPayLoadMessage) === message.id;
  }

  handleNavigationPayloadMessage(message: Message) {
    const otpAuthTkn = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    const email = message.getData(getName(MessageEnum.AuthTokenEmailMessage));
    this.setState({ token: otpAuthTkn, emailValue: email });
  }

  isRestApiResponseMessage(message: Message): boolean {
    return getName(MessageEnum.RestAPIResponceMessage) === message.id;
  }

  handleRestApiResponseMessage(message: Message, responseJson: { meta: { email: string | null; token: string}}) {
    this.setState({ errors: []});
    if (this.requestEmailOtpCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleRequestEmailOtpCall(responseJson);
    } else if (this.requestGoToConfirmationCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.setState({
        accountStatus: "Confirmation",
        successPage: true
      });
      localStorage.removeItem("Email")
    } else if (this.requestChangePasswordCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleRequestChangePasswordCall(responseJson);
    }
  }

  handleRequestEmailOtpCall(responseJson: { meta: { token: string}}) {
    this.setState({ token: responseJson.meta.token, errors: [] });
    if (typeof window !== "undefined") {
      localStorage.setItem("Token", responseJson.meta.token);
      localStorage.setItem("Email", this.state.emailValue);
    }
    
    const otpmsg: Message = new Message(getName(MessageEnum.NavigationMobilePhoneOTPMessage));
    otpmsg.addData(getName(MessageEnum.AuthTokenDataMessage), this.state.token);
    otpmsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    otpmsg.addData(getName(MessageEnum.AuthTokenEmailMessage), this.state.emailValue);
    otpmsg.addData(getName(MessageEnum.EnterOTPAsForgotPasswordMessage), true);
    this.send(otpmsg);
  }

  handleRequestChangePasswordCall(responseJson: { meta: { email: string | null}}) {
    this.setState({ emailValue: responseJson.meta.email, errors: [] });

    const changemsg: Message = new Message(getName(MessageEnum.NavigationNewPasswordMessage));
    changemsg.addData(getName(MessageEnum.AuthTokenDataMessage), this.state.token);
    changemsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    changemsg.addData(getName(MessageEnum.AuthTokenEmailMessage), this.state.emailValue);
    this.send(changemsg);
  }

  startForgotPassword(accountType: string) {
    if (accountType.includes(' ')) return
    this.setState({
      emailValue: accountType,
      errors: validateAndUpdateErrors(forgotPassValidationSchema, this.state.errors, "otp", accountType),
      accountStatus: "EnterEmail"
    });
  }

  handleOTP(otpValue: string) {
    this.setState({ otp: otpValue,
      errors: validateAndUpdateErrors(otpPassValidationSchema, this.state.errors, "otp", otpValue),
    });
  }

  goToOtpAfterEmailValidation(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

      const forgotPassValidate = validateAll(forgotPassValidationSchema, { otp: this.state.emailValue})
      if(forgotPassValidate.length > 0) {
        this.setState({ errors: forgotPassValidate });
        return
      }
      this.setState({ otp: "" })
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.requestEmailOtpCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emailSendOtpEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        type: "email_account",
        attributes: {
          email: this.state.emailValue
        }
      };

      const httpBody = {
        data: data
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  goToChangePasswordAfterOtp(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const otpValidate = validateAll(otpPassValidationSchema, { otp: this.state.otp})
    if(otpValidate.length > 0) {
      this.setState({ errors: otpValidate });
      return
    }
    if(this.state.otp.length < 4) {
      this.setState({ errors: [{otp: "Please enter 4 digit code"}] });
      return
    }
      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.requestChangePasswordCallId = requestMessage.messageId;


      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emailOtpConfirmationEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        email: this.state.emailValue,
        token: this.state.token,
        otp_code: this.state.otp
      };

      const httpBody = {
        data: data
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleNewPassword(text: string, value: any) {
    if (text.includes(' ')) return
    this.setState({ [value]: text } as any);

    const upperCase=/[A-Z]/.test(text)
    const lowerCase=/[a-z]/.test(text)
    const numeric=/[\d]/.test(text)
    const specialCharacter = /\W/.test(text);
    const minimumCharacter=text.length>=8

    this.setState(prev=>{
      return{
        errors: validateAndUpdateErrors(newPassValidationSchema, this.state.errors, value, text),
        loginValidation:{...prev.loginValidation,upperCase,lowerCase,numeric,minimumCharacter,specialCharacter}
      }
    })

  }
  
  handleConfirmPassword(text: string) {
     this.setState({confirmPassword: text,
      errors: validateAll(newPassValidationSchema, {confirmPassword: text, newPassword: this.state.newPassword}),
     });
  }

  renderForgotError = (errorKey: string) => {
		const { errors } = this.state;
		const erroeLoginText = errors.find((xValue) => xValue.hasOwnProperty(errorKey))?.[errorKey]
		return erroeLoginText
	}

  newPasswordBorderStyle = () => {
    if (this.renderForgotError("newPassword")) {
      return "#EE2020";
    }
  
    if (!this.state.newPassword.length) {
      return "";
    }
  
    const { upperCase, lowerCase, numeric, minimumCharacter, specialCharacter } = this.state.loginValidation;
    if (upperCase && lowerCase && numeric && minimumCharacter && specialCharacter) {
      return "";
    } 
  };
 
  goToConfirmationAfterPasswordChange(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    const {newPassword, confirmPassword} = this.state;
    const newValidate = validateAll(newPassValidationSchema, {newPassword, confirmPassword});
    
    if(newValidate.length > 0) {
      this.setState({ errors: newValidate });
      return
    }

    const isValidCheck = Object.values(this.state.loginValidation).every(err => err === true)
    if(isValidCheck) {
      return
    }

      const header = {
        "Content-Type": configJSON.forgotPasswordAPiContentType
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.requestGoToConfirmationCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emailSetNewPasswordEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const token =
        typeof window !== "undefined" ? localStorage.getItem("Token") : null;

      const data = {
        email: this.state.emailValue,
        new_password: this.state.confirmPassword,
        token: token
      };

      const httpBody = {
        data: data
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleContinueLoginPage() {
    this.props.navigation.navigate("LoginBlock");
  }

  handleOtpError = () => {
    return this.renderForgotError("otp") || this.renderForgotError("pin")
  }

  // Customizable Area End

  // Customizable Area Start
  // Customizable Area End
}

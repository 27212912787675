import React from "react";
// Customizable Area Start
import { left_Arrow, shieldSecurity, gallery, edit, imageCloseIcon } from './assets'
import Alert from '@material-ui/lab/Alert';
import { Box, Button, Grid, Snackbar, TextField, Typography, Hidden } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import UserProfileController, { Props } from "./UserProfileController.web";
import { Link } from "react-router-dom";
import ResponsiveTopBar from "../../../components/src/TopBar/ResponsiveTopBar.web";
import { editPageButtonSvg } from "../../multipageforms/src/assets";
export const configJSON = require("./config");
// Customizable Area End

class UserProfile extends UserProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    renderProfileHeaderSection = (classes: Record<string, string>) => {
        return (
            <Box className={classes.ChangeProfilePasswordContainer}>
                <Box className={classes.BackToProfileContainer}>
                    <img
                        data-testid="profilePictureBackTestId"
                        onClick={this.onProfileBack}
                        src={left_Arrow}
                        alt="left_Arrow"
                        className={classes.ProfileLeftArrowImg}
                    />
                    <Typography className={classes.ProfileHeadingText}>
                        {configJSON.profile}
                    </Typography>
                </Box>
                <Link to='/UserProfilePasswordChange' className={classes.menuItemLink}>
                {
                    !this.state.showEditWindow &&
                    <Button className={classes.ProfileChangePasswordContainer}
                        variant="outlined"
                        startIcon={
                            <img
                                src={shieldSecurity}
                                alt="shieldSecurity"
                                className={classes.ProfileShieldSecurityImg}
                            />

                        }
                    >
                        {configJSON.changePassword}
                    </Button>
                }
                </Link>
            </Box>
        )
    }

    renderProfileUploadSection = (classes: Record<string, string>) => {
        const shouldRenderCloseIcon = this.state.showEditWindow && this.state.isCloseIcon
        return (
            <Grid item md={4} lg={3}>
                <Box className={classes.ProfileDetailsImageContainer}>
                    <img
                        data-testid="profilePictureShowTestId"
                        src={this.imageShowSrc()}
                        alt="profilePhoto"
                        className={classes.ProfilePic}
                    />
                    {shouldRenderCloseIcon &&
                        <div
                            data-testid={`remove-profile-icon`}
                            style={{
                                position: "absolute",
                                right: -17,
                                top: -17,
                                zIndex: 1,
                                backgroundImage: `url(${imageCloseIcon})`,
                                height: 40,
                                width: 40,
                                cursor: "pointer",
                            }}
                            onClick={this.handleRemoveProfilePic}
                        />
                    }
                </Box>
                <Hidden smDown>
                 {this.renderEditPicLink(classes)}
                </Hidden>
            </Grid>
        )
    }

    renderEditPicLink = (classes: any) => {
        return <Box
            className={
                this.state.showEditWindow
                    ? classes.ChangeProfilePicBox
                    : classes.HideContant
            }
        >
            <label
                htmlFor="contained-button-file"
                className={classes.ChangeProfileLabel}
            >
                <img
                    src={gallery}
                    alt="gallery"
                    className={classes.PicMargin}
                />
                <Typography className={classes.ChangeProfilePicTxt}>
                    {configJSON.changeProfilePicture}
                </Typography>
                <input
                    data-testid="profilePictureTestId"
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    className={classes.UploadInputProfilePicture}
                    onChange={this.handleUploadProfilePicture}
                />
            </label>
        </Box>
    }

    renderProfileFullNamePhoneNumberEditButton = (classes: Record<string, string>) => {
        return (
            <div className={classes.firstDiv}>
                <Hidden mdUp>
                {this.renderProfileUploadSection(classes)}
                </Hidden>
                <Grid container spacing={2} wrap="wrap" style={{ width: "100%"}} >
                    <Grid item xs={12} sm={6} md={5} lg={5}>
                    <Box>
                        <Typography
                            className={classes.ProfileDetailsHeadingsTxt}
                        >
                            {configJSON.fullName}
                        </Typography>
                        <Typography
                            className={
                                this.state.showEditWindow
                                    ? classes.HideContant
                                    : classes.ProfileDetailsTxt
                            }
                        >
                            {this.state.userName}
                        </Typography>
                        <Box
                            className={
                                this.state.showEditWindow
                                    ? classes.ShowContant
                                    : classes.HideContant
                            }
                        >
                            <TextField
                                data-testid="profileNameTestId"
                                error={this.state.userNameError}
                                helperText={
                                    this.state.userNameError
                                        ? `${configJSON.nameValidation}`
                                        : ""
                                }
                                className={classes.ProfileDetailsInputBox}
                                id="outlined-basic"
                                variant="outlined"
                                value={this.state.userName}
                                onChange={this.handleChangeProfileName}
                            />
                        </Box>
                    </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={5}>
                    <Box>
                        <Typography
                            className={classes.ProfileDetailsHeadingsTxt}
                        >
                            {configJSON.phoneNumber}
                        </Typography>
                        <Typography
                            className={
                                this.state.showEditWindow
                                    ? classes.HideContant
                                    : classes.ProfileDetailsTxt
                            }
                        >
                            {this.state.userPhoneNumber}
                        </Typography>
                        <Box
                            className={
                                this.state.showEditWindow
                                    ? classes.ShowContant
                                    : classes.HideContant
                            }
                        >
                            <TextField
                                data-testid="profilePhoneNumberTestId"
                                error={this.state.userPhoneNumberError}
                                helperText={this.state.numberErrorString}
                                className={classes.ProfileDetailsInputBox}
                                id="outlined-basic"
                                variant="outlined"
                                value={this.state.userPhoneNumber}
                                onChange={this.handleChangePhoneNumber}
                            />
                        </Box>
                    </Box>
                    </Grid>
                </Grid>
            </div>
        )
    }

    renderEditButton = (classes: Record<string, string>) => {
        return (
            <Button
            className={
                this.state.showEditWindow
                    ? classes.HideContant
                    : classes.ProfileEditButton
            }
            startIcon={
                <>
                    <Hidden smDown>
                    <img src={edit} alt="edit" />
                    </Hidden>
                    <Hidden mdUp>
                    <img src={editPageButtonSvg} alt="edit" />
                    </Hidden>
                </>
            }
            onClick={this.handleEditProfileDetails}
            data-testid="editButtonTestId"
            >

                <Typography className={classes.ProfileEditText}>
                    {configJSON.edit}
                </Typography>
            </Button>
        )
    }

    renderProfileEmailDesignation = (classes: Record<string, string>) => {
        return (
            <>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={5} lg={5}>
                            <Box>
                                <Typography
                                    className={classes.ProfileDetailsHeadingsTxt}
                                >
                                    {configJSON.emailAddress}
                                </Typography>
                                <Typography
                                    className={classes.ProfileDetailsMarginTxt}
                                >
                                    {this.state.userEmail}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={5} lg={5}>
                            <Box>
                                <Typography
                                    className={classes.ProfileDetailsHeadingsTxt}
                                >
                                    {configJSON.designation}
                                </Typography>
                                <Typography
                                    className={classes.ProfileDetailsMarginTxt}
                                >
                                    {this.state.userDesignation}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box className={classes.ProfileDetailsMargin}>
                    <Typography className={classes.ProfileDetailsHeadingsTxt}>
                        {configJSON.location}
                    </Typography>
                    <Typography className={classes.ProfileDetailsMarginTxt}>
                        {this.state.companyLocation}
                    </Typography>
                </Box>
                <Box className={classes.ProfileDetailsMargin}>
                    <Hidden smUp>
                        <hr style={{ background: "#9F9F9F", height: "1px",border: "none", margin: "-8px 0 15px"}}/>
                    </Hidden>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={6} md={5} lg={5}>
                            <Box>
                                <Typography
                                    className={classes.ProfileDetailsHeadingsTxt}
                                >
                                    {configJSON.role}
                                </Typography>
                                {this.state.userRole.map((item: string) => {
                                    return (
                                        <>
                                            <Typography
                                                className={classes.ProfileDetailsMarginTxt}
                                            >
                                                {item}
                                            </Typography>
                                        </>
                                    )
                                })}
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={5} lg={5}>
                            <Box>
                                <Typography
                                    className={classes.ProfileDetailsHeadingsTxt}
                                >
                                    {configJSON.company}
                                </Typography>
                                <Typography
                                    className={classes.ProfileDetailsMarginTxt}
                                >
                                    {this.state.companyName}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </>
        )
    }

    renderProfileCancelSubmitButton = (classes: Record<string, string>) => {
        return (
            <Box className={classes.SaveChangesContainer}>
                <Button
                    className={
                        this.state.showEditWindow ? classes.CancelProfileDetailsButton : classes.HideContant
                    }
                    onClick={this.handleCancelEditedProfileDetails}
                    data-testid="cancelButtonTestId"
                >
                    <Typography className={classes.CancelText}>{configJSON.cancel}</Typography>
                </Button>
                <Button
                    type="submit"
                    className={
                        this.state.showEditWindow ? classes.SaveChanges : classes.HideContant
                    }
                >
                    <Typography className={classes.SaveChangesText}>
                        {configJSON.saveChanges}
                    </Typography>
                </Button>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        return (
            <>
                <Hidden mdUp> 
                    <ResponsiveTopBar navigation={this.props.navigation} title={"Menu"}>
                    </ResponsiveTopBar>
                </Hidden>
                <Box className={classes.ProfileSelectorContainer}>
                    {this.renderProfileHeaderSection(classes)}
                    <form data-testid="userProfileUpdateId" autoComplete="off" onSubmit={this.handleSubmitEditableChanges}>
                        <Box className={classes.ProfileDetailsContainer}>
                            <Grid container spacing={3} alignContent="center" style={{ position: "relative"}}>
                                <Hidden smDown>
                                    {this.renderProfileUploadSection(classes)}
                                </Hidden>
                                <Grid item xs={12} sm={12} md={8} lg={7}>
                                    {this.renderProfileFullNamePhoneNumberEditButton(classes)} 
                                    <Hidden mdUp>
                                        {this.renderEditPicLink(classes)}
                                    </Hidden>  
                                    <hr className={classes.ProfileDetailsPartition} />
                                    {this.renderProfileEmailDesignation(classes)}
                                </Grid>
                                {this.renderEditButton(classes)}
                            </Grid>
                            {this.renderProfileCancelSubmitButton(classes)}
                        </Box>
                    </form>
                </Box>
                {this.state.hasSuccessError.isOpen && (
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        open={this.state.hasSuccessError.isOpen}
                        onClose={this.handleCloseSnack}
                        autoHideDuration={6000}
                    >
                        <Alert data-testid="snackBarCloseTestId" onClose={this.handleCloseSnack} severity={this.state.hasSuccessError.isSeverity}>
                            {this.state.hasSuccessError.isMessage}
                        </Alert>
                    </Snackbar>
                )}
            </>
        );
    }
    // Customizable Area End
}
// Customizable Area Start
const styles = () =>
    createStyles({
        firstDiv: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'nowrap',
          gap: "20px",
            "& .form":{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
            }
        },
        ProfileSelectorContainer: {
            padding: "0px 10% 10%",
            backgroundColor: "#F3F3F3",
            border: "1px solid transparent",
            "@media (max-width: 500px)": {
                padding: "0px 2% 10%",
            },
            "@media (max-width: 600px)": {
                padding: "0px 0px 10%",
            },
        },
        ChangeProfilePasswordContainer: {
            height: "63px",
            borderRadius: "8px",
            backgroundColor: "#FFF",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 12px 0 40px",
            marginTop: "18px",
            "@media (max-width: 500px)": {
                padding: "0px 2%",
            },
            "@media (max-width: 350px)": {
                display: "block",
                height: "auto",
                padding: "20px 2%",
            },
        },
        BackToProfileContainer: {
            display: "flex",
            alignItems: "center",
        },
        ProfileLeftArrowImg: {
            borderRadius:"8px",
            marginRight: "16px",
            cursor: "pointer",
        },
        ProfileShieldSecurityImg: {
            width:"24px",
            height:"24px"
        },
        ProfileHeadingText: {
            color: "#2B3132",
            fontFamily: "Source Sans Pro",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 700,
        },
        ProfileChangePasswordContainer: {
            width:"fit-content",
            padding: "8px 16px",
            borderRadius: "8px",
            color: "#0094A7",
            fontFamily: "Source Sans Pro",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            textTransform: "capitalize",
            border: "1px solid #0094A7",
            "@media (max-width: 350px)": {
                marginTop: "10px",
            },
            "&:hover":{
                backgroundColor: "transparent",
            }
        },
        ProfileDetailsContainer: {
            marginTop: "16px",
            padding: "30px 4% 8% 40px",
            borderRadius: "8px",
            backgroundColor: "#FFF",
            "@media (max-width: 600px)": {
                padding: "24px",
            },
        },
        ProfileDetailsImageContainer: {
            position: 'relative',
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "200px",
            "@media (max-width: 960px)": {
                width: "130px",
            },
        },
        ProfileDetailsHeadingsTxt: {
            color: "#1F1F1F",
            fontFamily: "Source Sans Pro",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 600,
            "@media (max-width: 600px)": {
                // marginTop: "20px",
            },
        },
        ProfileDetailsTxt: {
            color: "#2B3132",
            fontFamily: "Source Sans Pro",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            marginTop: "9px",
        },
        ProfileDetailsMarginTxt: {
            color: "#2B3132",
            fontFamily: "Source Sans Pro",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            marginTop: "8px",
            wordWrap:"break-word",
        },
        ProfileEditButton: {
            position: "absolute",
            right: 0,
            top: 0,
            width: "min-content",
            padding: "12px 36px",
            borderRadius: "8px",
            background: "#0094A7",
            textTransform: "capitalize",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: "#0094A7",
            },
            "@media (max-width: 960px)": {
                backgroundColor: "transparent",
                padding: "10px",
                marginRight: "10px",
            },
            "& .MuiButton-startIcon": {
                marginLeft: "0",
            },
        },
        ProfileEditText: {
            "@media (max-width: 960px)": {
                color: "#0094A7",
            },
            color: "#FFF",
            fontFamily: "Source Sans Pro",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            textDecorationLine: "underline",
        },
        ProfileDetailsPartition: {
            margin: "40px 0",
            background: "#9F9F9F",
            height: "1px",
            border: "none",
            "@media (max-width: 960px)": {
                margin: "30px 0",
            },
            "@media (max-width: 600px)": {
                margin: "24px 0",
            },
            "@media (max-width: 460px)": {
                margin: "18px 0",
            },
        },
        ProfileDetailsMargin: {
            marginTop: "36px",
        },
        ProfileDetailsInputBox: {
            width: "100% !important",
            "& .MuiInputBase-input": {
                height: "45px !important",
                width: "100% !important",
                padding: "0 10px !important",
            },
            "& .PrivateNotchedOutline-root-36": {
                borderRadius: "8px !important",
                border: "1px solid #9F9F9F !important",
            },
            "& input[type=number]": {
                "-moz-appearance": "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
            },
            "& input[type=number]::-webkit-inner-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
            },
            "& .MuiFormHelperText-contained": {
                marginLeft: "1px",
                marginRight: "10px",
            },
        },
        SaveChangesContainer: {
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "end",
            marginTop: "40px",
            "@media (max-width: 380px)": {
                flexWrap: "wrap",
                "& button":{
                    margin: "0 auto",
                }
            },
        },
        SaveChanges: {
            width: "140px",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
            background: "#0094A7",
            cursor: "pointer",
            textTransform: "unset",
            "&:hover": {
                backgroundColor: "#0094A7",
            },
            "@media (max-width: 380px)": {
                marginTop: "20px",
            },
        },
        SaveChangesText: {
            color: "#FFF",
            fontFamily: "Source Sans Pro",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
        },
        CancelProfileDetailsButton: {
            width: "140px",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
            border: "1.5px solid #0094A7",
            marginRight: "16px",
            cursor: "pointer",
            textTransform: "unset",
        },
        CancelText: {
            color: "#0094A7",
            fontFamily: "Source Sans Pro",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 600,
        },
        HideContant: {
            display: "none",
        },
        ShowContant: {
            display: "block",
        },
        ChangeProfilePicBox: {
            marginTop: "25px",
            width: "200px",
            "@media (max-width: 600px)": {
                marginTop: "16px",
            },
        },
        ChangeProfileLabel: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        PicMargin: {
            width: "24px",
            height: "24px",
            marginRight: "8px",
            cursor: "pointer",
        },
        ChangeProfilePicTxt: {
            color: "#0094A7",
            fontFamily: "Source Sans Pro",
            fontSize: "16px",
            fontWeight: 600,
            textDecorationLine: "underline",
            cursor: "pointer",
        },
        UploadInputProfilePicture: {
            display: "none",
        },
        ProfilePic: {
            width: "200px",
            height: "200px",
            borderRadius: "8px",
            margin:"0 auto",
            "@media (max-width: 960px)": {
                width: "130px",
                height: "130px",
            },
        },
    });
// Customizable Area End
// Customizable Area Start
export default withStyles(styles)(UserProfile);
export { UserProfile }
// Customizable Area End
import { Box, Grid, Typography, styled } from "@material-ui/core";
import React from "react";
import TruncatedTextWithTooltip from "./TruncatedTextWithTooltip.web";


interface LegendListProps {
  data: any[];
}

class LegendList extends React.Component<LegendListProps> {
  render() {
    const { data } = this.props;
    return (
      <LegendContainer wrap="nowrap">
        {data.map((entry: any, i: number) => {
          return (
            <Grid item className={`chartTile`} key={`${i+1}-legend`}>
              <Box className="tileColor" style={{ background: entry[2] }} />
              <Box className="tileContent">
                <TruncatedTextWithTooltip text={entry[0]} maxWidth="120px" classNames="tileLabel"/>
              </Box>
            </Grid>
          );
        })}
      </LegendContainer>
    );
  }
}

export const LegendContainer = styled(Grid)({
    display: 'grid',
    width: 'fit-content',
    gridTemplateColumns: '1fr 1fr',
    gridGap: "8px 30px",
  "& .chartTile": {
    position: "relative",
    cursor: "default",
    transition: "background-color 0.3s ease",
    display: "flex",
    alignItems: "center",
    "& .tileColor": {
      width: "16px !important",
      height: "16px !important",
      display: "inline-block",
      marginRight: "10px",
    },
    "& .tileContent": {
      display: "inline-block",
      verticalAlign: "top",
      "& .tileLabel": {
        color: 'black',
        fontSize: 12,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
        lineHeight: "16px",
        wordWrap: 'break-word',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
    },
  },
})


export default LegendList;

import React, { Component } from 'react';
import {
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TablePagination,
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
  CheckboxProps,
  RadioGroup,
  FormControl,
  FormLabel,
  Radio,
  // Customizable Area Start
  // Customizable Area End
} from '@material-ui/core';

// Customizable Area Start
import { createTheme } from '@material-ui/core/styles';
import { BlockComponent } from '../../framework/src/BlockComponent';
import { RadioOption } from './Types';
import { checkboxCheckedIcon, checkboxUncheckedIcon } from './FilterView/assests';

const theme: any = createTheme({
  TextField: {
    padding: '10.5px 14px',
  },
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: '20px 0px',
    },
  },
} as any);

interface Props {
  id: any;
  classes: any;
  checkBoxList: { label: string; value: string; isChecked: boolean }[];
  radioOptionList: RadioOption[];
  options: any;
  onCheckBoxListChange: (checkedList: string[]) => void;
  searchWord?:string;
  // parentItem: string;
  // toggleFilterItemsCheckbox: (
  //   item: any,
  //   subItem: string,
  //   parentItem: string
  // ) => void;
}

interface S {
  checkBoxList: { label: string; value: string; isChecked: boolean }[];
  radioOptionList: RadioOption[];
  options: any
}

interface SS { }

const THEME = createMuiTheme({
  typography: {
    fontFamily: "Source Sans Pro",
    fontSize: 12.25,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
  },
});

const styles: any = (theme: any) => ({
  label: {
    color: '#2B3132',
    fontFamily: 'Source Sans Pro , sans-serif',
    fontSize: '14px !important',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '32px',
    "& span": {
      fontSize: "14px !important"
    }
  },
  checkbox: {
    color: "#3695A7 !important"
  },
  formControl: {
    fontSize: '14px !important'
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: 'green !important'
    }
  }
});

// Customizable Area End

class GenericFilterInputsWithoutStyle extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    let checkList 
    // Customizable Area End
    this.state = {
      checkBoxList: this.props.checkBoxList,
      radioOptionList: this.props.radioOptionList,
      options: this.props.options
    };

  }

  // Customizable Area Start
  // Customizable Area End
  onRadioChange(e: any, val: any) {
    this.setState({ checkBoxList: this.props.options.filterOptionForRadio[val].currentlyEmployed })

  }
  handleChange(item: { label: string; value: string; isChecked: boolean }, i: number) {
    console.log(item,"checkedList");

    this.setState((prevState: S) => {
      prevState.checkBoxList[i].isChecked = !prevState.checkBoxList[i]
        .isChecked;
      let checkedList = prevState.checkBoxList
        .filter((item) => item.isChecked)
        .map((item) => item.value);
        console.log(checkedList,"checkedList");
        
      this.props.onCheckBoxListChange(checkedList);      
      return { checkBoxList: [...prevState.checkBoxList] };
    });
  }

  componentWillUpdate(nextProps:any, nextState:any) {
    if(this.props.checkBoxList !== nextProps.checkBoxList){
     this.setState({checkBoxList:nextProps.checkBoxList})
    }
    return this.props.checkBoxList !== nextProps.checkBoxList;
  }

  

  render() {
    const { classes, searchWord } = this.props;
    return (
      <MuiThemeProvider theme={THEME}>

        {this.state.checkBoxList.map(
          (
            item: any,
            i: number
          ) => (
              <FormControlLabel
                key={`${i*4+1}`}
                control={
                  <Checkbox
                    icon={<img style={{marginLeft: "1px"}} src={checkboxUncheckedIcon} />}
                    checkedIcon={<img style={{marginLeft: "1px"}} src={checkboxCheckedIcon} />}
                    className={classes.checkbox}
                    checked={item.isChecked}
                  />
                }
                style={{display:  item.label.toLowerCase().includes(searchWord) ? "flex" : (searchWord === "" || searchWord === undefined) ? "flex" : 'none'}}
                value={item.value}
                label={item.label}
                className={classes.label}
                onChange={() => this.handleChange(item, i)}
              />
          )
        )}
      </MuiThemeProvider>
    );
  }
}
const GenericFilterInputs = withStyles(styles)(GenericFilterInputsWithoutStyle);
export default GenericFilterInputs;
// Customizable Area Start
// CustomCheckbox Component
interface CustomCheckboxProps extends CheckboxProps { }
type MyState = {};
class CustomCheckbox extends Component<CustomCheckboxProps, MyState> {
  constructor(props: any) {
    super(props);
  }
  render() {
    return <CssCheckbox {...this.props} />;
  }
}
const CssCheckbox = withStyles({
  root: {
    '&.Mui-checked': {
      color: '#0094A7',
    },
  },
})(Checkbox);
// Customizable Area End

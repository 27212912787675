import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  imgSource: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
interface ApiData {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: FormData;
  type?: string;
}
 // Customizable Area End
 
export default class OfflineWorkController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  submitImg: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      imgSource: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.submitImg = "";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiSuccessResponse(message);
    }
    // Customizable Area End
  }
  // Customizable Area Start

  apiCall = async (data: ApiData) => {
    const { contentType, method, endPoint, body, type } = data
    let token: string | null = localStorage.getItem("LOGIN_TOKEN")

    const header = {

      token,
      'Content-Type': contentType
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  getApiCommonResponseDetail = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    )
    return { apiRequestCallId, responseJson, errorReponse }
  }

  handleApiSuccessResponse = (message: Message) => {
    const { apiRequestCallId, responseJson, errorReponse } = this.getApiCommonResponseDetail(message);
    if (responseJson && !responseJson.errors && !errorReponse) {

      if (apiRequestCallId === this.submitImg) {
        this.setState({ imgSource: ""})
      }
    }
  }

  async componentDidMount() {
    const body  = new FormData();
    body.append("attachment", this.state.imgSource);
    this.submitImg = await this.apiCall({
      method: configJSON.apiPostMethod,
      endPoint: configJSON.apiEndPointSubmitImage,
      body,
      type: 'formData'
    })
    
  }

  // Customizable Area End
}

import {
  Box,
  OutlinedTextFieldProps,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { ErrorRounded } from "@material-ui/icons";
import React, { ChangeEvent, Component } from "react";

interface CustomInputProps extends Omit<OutlinedTextFieldProps, "onChange"> {
  marginBottom?: string | number;
  testId?: string;
  onChangeValue?: (value: string | number) => void;
  isValid?: boolean;
  past?: boolean;
}

type Props = CustomInputProps;
type State = {
  date: string;
};
export default class CustomInput extends Component<Props, State> {

  constructor(props: any) {
    super(props);
    this.state = {
      date: "",
    };
  }

  render() {
    return (
      <Box
        id={"custom-input-box"}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          p: 0,
          width: "100%",
          marginRight: "16px",
          marginBottom: this.props.marginBottom,

        }}
      >
        {this.props.label && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              marginBottom: "7px",
            }}
          >
            <StyledLabel>{this.props.label}</StyledLabel>
            {this.props.error && (
              <ErrorRounded
                color="error"
                fill="#DC2626"
                style={{
                  width: "16px",
                  height: "16px",
                }}
              />
            )}
          </Box>
        )}
        <Box>
          <CssTextField
            {
            ...this.props
            }
            variant="outlined"
            label={undefined}
            onChange={(
              e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => this.props.onChangeValue?.(e.target.value)}
            color={"primary"}
            onCopy={undefined}
          />
        </Box>
      </Box>
    );
  }

}

const StyledLabel = withStyles({
  root: {
    color: "#1F1F1F",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: 0,
    marginRight: "6px",
  },
})(Typography);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#0094A7",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& .MuiInputBase-input": {
      width: "100%",
      padding: "13px 14px",
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#2B3132",
      lineHeight: "16px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      "& fieldset": {
        border: "1px solid #9F9F9F",
      },
      "&:hover fieldset": {
        border: "1px solid #9F9F9F",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #0094A7",
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #DC2626 !important",
      },
      "&.error": {
        border: "1px solid #DC2626",
        borderRadius: "8px",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "1px",
    }
  },
})(TextField);

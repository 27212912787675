import React from "react";
export const greyTick = require('./greyTick.svg')
export const greenTick = require('./greenTick.svg')
export const redTick = require('./redTick.svg')


interface Props {
    textLength: number;
    validation: boolean;
};

export const Tick: React.FC<Props> = ({
    textLength,
    validation
}) => {
    return (
        <img src={textLength ? (validation ? greenTick : redTick) : greyTick} />
    );
};
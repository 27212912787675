import React, { Component } from 'react';
import { ITenderData, S, DropDownItems } from '../../TenderModuleController.web';
import ReadTenderDetails from './ReadTenderDetails.web';
import UpdateTenderDetails from './UpdateTenderDetails.web';

type Props = {
	tenderDetails: ITenderData;
	TenderErrors: boolean;
	handleChangeValue: (val: string, key: string) => void;
	handlePosTenderDetails: () => void;
	handleUpdateTenderDetails: () => void;
	projectRequestNavigation?: ()=> void;
	handleSwitchMode: (section: string, mode: 'update' | 'read' | 'post', onCancel?: boolean) => void;
	onChange: (objectKey: keyof S, property: any, value: any | null) => void;
	groupResponse: DropDownItems[]
	countryresponse: DropDownItems[]
	clientResponse: DropDownItems[]
	servicesResponse: DropDownItems[]
	sectorsResponse: DropDownItems[]
	ValueOfWorksResponse: DropDownItems[]
	statusResponse: DropDownItems[]
	tenderTypeResponse: DropDownItems[],
	typeCurrencyResponse: DropDownItems[]
};

type State = {};

export default class TenderDetails extends Component<Props, State> {
	render() {
		const { mode } = this.props.tenderDetails;
		const {
			groupResponse,
			countryresponse,
			clientResponse,
			servicesResponse,
			sectorsResponse,
			ValueOfWorksResponse,
			statusResponse,
			tenderTypeResponse,
			typeCurrencyResponse
		} = this.props

		if (mode === 'read') {
			return (
				<ReadTenderDetails
					tenderDetails={this.props.tenderDetails}
					handleSwitchMode={this.props.handleSwitchMode}
					onChange={this.props.onChange}
					projectRequestNavigation={this.props.projectRequestNavigation}
				/>
			);
		} else {
			return (
				<UpdateTenderDetails
					tenderDetails={this.props.tenderDetails}
					TenderErrors={this.props.TenderErrors}
					handleChangeValue={this.props.handleChangeValue}
					handleUpdateTenderDetails={this.props.handleUpdateTenderDetails}
					handlePosTenderDetails={this.props.handlePosTenderDetails}
					handleSwitchMode={this.props.handleSwitchMode}
					groupResponse={groupResponse}
					countryresponse={countryresponse}
					tenderTypeResponse={tenderTypeResponse}
					clientResponse={clientResponse}
					servicesResponse={servicesResponse}
					typeCurrencyResponse={typeCurrencyResponse}
					sectorsResponse={sectorsResponse}
					ValueOfWorksResponse={ValueOfWorksResponse}
					statusResponse={statusResponse}
				/>
			);
		}
	}
}

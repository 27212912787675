import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { profilePhoto } from './assets';
import { ChangeEvent } from "react";
import { AdvancedFilterContext } from "../../../components/src/ContextAPI/AdvancedFilterContext.web";
import { compareSVG, fetchBlobData } from "../../../components/src/Utils";
// Customizable Area End

// Customizable Area Start
export interface ValidResponseType {
  data: object;
}

export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}

export interface ErrorPayloadType {
  key: string;
  full_phone_number: string;
  token: string;
  profile: string;
}

export interface GetUserProfileResponseType {
  data: {
    attributes: {
      account: {
        full_name: string;
        phone_number: string;
        email: string;
        designation: string;
        location: string;
        role: Array<string>;
        company: string;
        profile: string;
      }
    }
  }
}

export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}

export interface InputEventType {
  target: {
    value: string;
  }
}
// Customizable Area End

export const configJSON = require("./config");

export type Props = {
  // Customizable Area Start
  classes: Record<string, string>;
  navigation: any;
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  userNameError: boolean;
  userPhoneNumberError: boolean;
  numberErrorString: string;
  showEditWindow: boolean;
  userEmail: string;
  userDesignation: string;
  companyLocation: string;
  userRole: Array<string>;
  companyName: string;
  userName: string;
  editUserName: string;
  userPhoneNumber: string;
  editUserPhoneNumber: string;
  userProfilePicture: string;
  profilePictureForApi: string | Blob;
  hasSuccessError: HasSuccessErrorType;
  isCloseIcon: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class UserProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  static contextType = AdvancedFilterContext;
  getProfileDataApiCallId: string = "";
  updateProfileAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showEditWindow: false,
      userNameError: false,
      userPhoneNumberError: false,
      numberErrorString: "",
      userName: "",
      editUserName: "",
      userPhoneNumber: "",
      userEmail: "",
      userDesignation: "",
      companyLocation: "",
      userRole: [],
      companyName: "",
      editUserPhoneNumber: "",
      userProfilePicture: "",
      profilePictureForApi: "",
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      isCloseIcon: true,
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getProfileDataDataShow();
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson.status === 500) {
        return;
      }
      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        return
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson && responseJson.data;
  };

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: GetUserProfileResponseType) => {
    if (apiRequestCallId === this.getProfileDataApiCallId) {
      this.getProfileDataSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.updateProfileAPICallId) {
      this.putProfileDataSuccessCallBack(responseJson);
    }
  };

  apiFailureCallBacks = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.getProfileDataApiCallId) {
      this.getProfileDataFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.updateProfileAPICallId) {
      this.putProfileDataFailurCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  userProfileApiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    type?: string;
  }) => {
    const { contentType, method, endPoint, body, type } = valueData;
    const token = (await localStorage.getItem("authToken")) || "";
    const header = {
      "Content-Type": contentType,
      token,
    };
    let apiBody = body;
    if (type === "") {
      apiBody = JSON.stringify(body);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiBody
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getProfileDataDataShow = async () => {
    this.getProfileDataApiCallId = await this.userProfileApiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.getUserProfileEndPoint,
      type: ""
    });
  };

  getProfileDataSuccessCallBack = (res: GetUserProfileResponseType) => {
    this.handleCompareSVGs(profilePhoto, res.data.attributes.account.profile)
    this.setState({
      userName: res.data.attributes.account.full_name,
      editUserName: res.data.attributes.account.full_name,
      userPhoneNumber: res.data.attributes.account.phone_number,
      editUserPhoneNumber: res.data.attributes.account.phone_number,
      userEmail: res.data.attributes.account.email,
      userDesignation: res.data.attributes.account.designation,
      companyLocation: res.data.attributes.account.location,
      userRole: res.data.attributes.account.role,
      companyName: res.data.attributes.account.company,
      userProfilePicture: res.data.attributes.account.profile
    })
  }

  handleCompareSVGs = async (svgPath: any, urlPath: string) => {
    const isMatchSvg = await compareSVG(svgPath, urlPath);
    if(isMatchSvg){
      this.setState({
        isCloseIcon: false
      })
    } else {
      this.setState({
        isCloseIcon: true
      })
    }
  }


  getProfileDataFailureCallBack = (errors: InvalidResponseType) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: errors.errors[0].token
      }
    })
  }

  putProfileDataSuccessCallBack = (response: GetUserProfileResponseType) => {
    this.getProfileDataDataShow()
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "success",
        isMessage: "Profile updated succesfully"
      },
      showEditWindow: false
    })
    const userInfo = {
      ...this.context.globalContext.currentUser,
      fullName: response.data.attributes.account.full_name,
      profilePhoto: response.data.attributes.account.profile
    };

    localStorage.setItem("currentUser", JSON.stringify(userInfo));

    this.context?.setGlobalContext({
      currentUser: userInfo
    })
    
  }

  putProfileDataFailurCallBack = (errors: InvalidResponseType) => {
    if (errors.errors[0].full_phone_number) {
      this.setState({
        hasSuccessError: {
          isOpen: true,
          isSeverity: "error",
          isMessage: errors.errors[0].full_phone_number
        },
        showEditWindow: true
      })
    } else if (errors.errors[0].profile) {
      this.setState({
        hasSuccessError: {
          isOpen: true,
          isSeverity: "error",
          isMessage: errors.errors[0].profile
        },
        showEditWindow: true
      })
    }
  }

  handleSubmitEditableChanges = async (preventEvent: { preventDefault: () => void }) => {
    preventEvent.preventDefault()
    if (this.state.userPhoneNumberError === false && this.state.userNameError === false) {
      this.setState({
        userName: this.state.userName,
        userPhoneNumber: this.state.userPhoneNumber
      }, async () => {
        const formData = new FormData();
        formData.append("full_name", this.state.userName.trim())
        formData.append("full_phone_number", this.state.userPhoneNumber.trim())
        if (this.state.profilePictureForApi)
          formData.append("profile", this.state.profilePictureForApi)
        this.updateProfileAPICallId = await this.userProfileApiCall({
          method: configJSON.httpPutMethod,
          endPoint: configJSON.putUserProfileEndPoint,
          body: formData,
          type: "formData"
        });

      });
    }
  };

  handleEditProfileDetails = () => {
    this.setState({
      showEditWindow: true,
      editUserPhoneNumber: this.state.userPhoneNumber,
      editUserName: this.state.userName,
    });
  };

  handleCancelEditedProfileDetails = () => {  
    this.handleCompareSVGs(profilePhoto, this.state.userProfilePicture)
    this.setState({
      showEditWindow: false,
      userPhoneNumber: this.state.editUserPhoneNumber,
      userName: this.state.editUserName,
      profilePictureForApi: this.state.userProfilePicture,
      userNameError: false,
      userPhoneNumberError: false
    });
  };

  handleChangePhoneNumber = (numberEvent: InputEventType) => {
    const numberReg = /^[0-9\b]+$/;

    if (numberReg.test(numberEvent.target.value) || numberEvent.target.value === "") {
      if (numberEvent.target.value.length < 7 || numberEvent.target.value.length > 15) {
        this.setState({
          userPhoneNumber: numberEvent?.target.value,
          userPhoneNumberError: true,
          numberErrorString: configJSON.phoneNumberValidation
        });
      } else {
        this.setState({
          userPhoneNumberError: false,
          userPhoneNumber: numberEvent.target.value,
          numberErrorString: ""
        });
      }
    }
    if (numberEvent.target.value.length === 0) {
      this.setState({
        userPhoneNumberError: false,
        numberErrorString: ""
      });
    } 
  };

  handleChangeProfileName = (nameEvent: InputEventType) => {
    this.setState({
      userName: nameEvent?.target.value
    });
    if (nameEvent?.target.value === "") {
      this.setState({
        userNameError: true
      });
    } else {
      this.setState({
        userNameError: false
      });
    }
  };

  handleUploadProfilePicture = (fileEvent: ChangeEvent<HTMLInputElement>) => {
    const fileInput = fileEvent.target;
    if (fileEvent.target.files && fileEvent.target.files[0]) {
      this.setState({
        profilePictureForApi: fileEvent.target.files[0],
        isCloseIcon: true,
      });
    }
    fileInput.value = '';
  };

  handleRemoveProfilePic = async () => {
    this.setState({
      isCloseIcon: false,
      profilePictureForApi: await fetchBlobData(profilePhoto),
    });
  }

  imageShowSrc = () => {
    const { profilePictureForApi, userProfilePicture } = this.state;
    if (profilePictureForApi && profilePictureForApi instanceof Blob) {
      return URL.createObjectURL(profilePictureForApi);
    } else if (userProfilePicture) {
      return userProfilePicture
    } else {
      return profilePhoto;
    }
  }

  handleCloseSnack = () => {
    this.setState({
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      }
    })
  }
  onProfileBack = () => {
    window.history.back();
  }
  // Customizable Area End
}

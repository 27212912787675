import {
	Box,
	FormControl,
	FormControlLabel,
	RadioGroup,
	Typography,
	withStyles,
} from '@material-ui/core';
import React, { Component } from 'react';
import CustomRadioButton from './CustomRadioButton.web';

interface LanguageLevelRadioGroupProps {
	testId?: string;
	language: string;
	languageArray?:any;
	isLabelHidden?: boolean;
	selectedLevel: 'Advance' | 'Beginner' | 'Intermediate' | 'Native' | string | undefined;
	handleSelectLang: (
		language: string,
		level: 'Advance' | 'Beginner' | 'Intermediate' | 'Native' | string | undefined,
		langObj?:any
	) => void;
}

type Props = LanguageLevelRadioGroupProps;
type State = {};

export default class LanguageLevelRadioGroup extends Component<Props, State> {
	handleToggle = (selectedValue: string) => {
		const { handleSelectLang, language, selectedLevel } = this.props;
		let langObj = this.props.languageArray?.find((item:any)=> item.name === language)
		if (selectedValue === selectedLevel) {
		  handleSelectLang(language,"",langObj);
		} else {
		  handleSelectLang(language, selectedValue,langObj);
		}
	  };
	render() {
		const {
			language,
			testId,
			selectedLevel,
			isLabelHidden,
		} = this.props;

		return (
			<Box
				id={testId}
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}>
				{!isLabelHidden && <LanguageHeader>{language}</LanguageHeader>}
				<FormControl component='fieldset'>
					<RadioGroup row>
						
						<LanguageForm
							value='Beginner'
							control={
								<CustomRadioButton
								checked={selectedLevel === 'Beginner'}	
								onClick={() => {this.handleToggle(selectedLevel==='Beginner'?(''):('Beginner'))}}
								/>
							}
							label='Beginner'
						/>

						<LanguageForm
							value='Intermediate'
							control={
								<CustomRadioButton
									checked={selectedLevel === 'Intermediate'}
									onClick={() => {this.handleToggle(selectedLevel==='Intermediate'?(''):('Intermediate'))}}
								/>
							}
							label='Intermediate'
						/>

						<LanguageForm
							value='Advance'
							control={
								<CustomRadioButton
									checked={selectedLevel === 'Advance'}
									onClick={() => {{this.handleToggle(selectedLevel==='Advance'?(''):('Advance'))}}}
								/>
							}
							label='Advance'
						/>

						<LanguageForm
							value='Native'
							control={
								<CustomRadioButton
									checked={selectedLevel === 'Native'}
									onClick={() => {this.handleToggle(selectedLevel==='Native'?(''):('Native'))}}
								/>
							}
							label='Native'
						/>
					</RadioGroup>
				</FormControl>
			</Box>
		);
	}
}

const LanguageHeader = withStyles({
	root: {
		fontSize: '16px',
		fontWeight: 600,
		lineHeight: '16px',
		letterSpacing: 0,
		fontFamily: 'Source Sans Pro',
		color: '#2B3132'
	},
})(Typography);

const LanguageForm = withStyles({
	root: {
		marginRight: 24
	},
	label: {
		color: '#2B3132',
		fontFamily: 'Source Sans Pro',
	}
})(FormControlLabel)

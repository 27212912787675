import { Box, Card, CircularProgress, Typography, styled } from '@material-ui/core';
import React, { ReactElement } from 'react';

interface ChartCardCmpProps {
  loading: boolean | undefined;
  title: string | undefined;
  children: ReactElement;
  maxwidgetheight: string;
  padding?: string;
  scrollTarget?: string;
}

interface ChartCardProps {
    maxwidgetheight?: string;
    padding?: string;
}

class ChartCard extends React.Component<ChartCardCmpProps> {
  render() {
    const { loading, title, children, maxwidgetheight, padding, scrollTarget } = this.props;
    return (
      <CustomeCard
        maxwidgetheight={maxwidgetheight}
        padding={padding}
      >
        <Box className='headerContainer'>
            <Typography className='title'>{title}</Typography>
        </Box>
        <Card className='bodyContainer' id={scrollTarget}>
            {loading ? <CircularProgress/> : children}
        </Card>
        
      </CustomeCard>
    );
  }
}

export const CustomeCard = styled(Box)(({ maxwidgetheight, padding }:ChartCardProps) => ({
    padding: 0,
    gap: "15px",
    display: "flex",
    backgroundColor: "transparent",
    flexDirection: "column",
    "& .bodyContainer": {
      "@media (max-width: 640px)": {
        padding: "17px 5px !important",
      },
        overflow: maxwidgetheight ? 'auto' : 'unset',
        height: maxwidgetheight ? `${maxwidgetheight}px` : 'auto',
        boxShadow: `0px 2px 5px rgba(0, 0, 0, 0.05)`,
        borderRadius: "8px",
        background: "#ffffff",
        minHeight: "150px",
        padding: padding ?? "10px",
        display: "flex", flexDirection:"row", gap: "10px", justifyContent:"space-around"
    },
    "& .headerContainer": {
      display: 'flex',
      flexDirection: "row",
      gap: "10px",
      justifyContent: "space-between",
      "& .title": {
        fontFamily: "Source Sans Pro",
        color: "#2B3132",
        fontSize: "23px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "16px",
      }
    }
}));

export default ChartCard;

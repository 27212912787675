import React from "react";
import {
  createStyles,
  MuiThemeProvider,
  withStyles,
  createTheme,
  Theme,
  Box
} from "@material-ui/core"

const THEME = createTheme({
  typography: {
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontFamily: "Source Sans Pro",
    fontSize: 14,
    fontWeightLight: 300,
  },
});

interface Props {
  classes?: any,
  tabs: string[],
  onChangeTab: (tab: number) => void
  activeIndex: number
}

export const Tabs: React.FC<Props> = ({
  classes,
  tabs,
  onChangeTab,
  activeIndex
}) => {

  return (
      <MuiThemeProvider theme={THEME}>
        <Box>
          <table className={classes.tabbar} style={{ width: tabs.length * 180 }}>
            <tr>
                { 
                  tabs.map((tab, index) =>  {
                    const activeStyle = index == activeIndex ? { background: '#0094A7', color: 'white' } : {}
                    return (  
                      <th  key={tab} onClick={() => onChangeTab(index)}>
                        <div className={classes.tabItem} style={activeStyle}>{tab}</div>
                      </th>
                    )
                  }  
                )}
            </tr>
          </table>
        </Box>
      </MuiThemeProvider>
  )
}



export const styles = (theme: Theme) =>
  createStyles({
    tabbar: {
      backgroundColor: "#dbedf0",
      borderRadius: "8px",
      tableLayout: "fixed",
      width: "80%",
      margin: "auto",
      fontFamily: "Source Sans Pro",
      fontSize: "18px", 
      [theme.breakpoints.down("sm")]: {
        width: "100%!important",
        "& th": {
          fontSize: '12px'
        }
      },
      
      "& th": {
        backgroundColor: "#dbedf0",
        borderRadius: "8px",
        tableLayout: "fixed",
        width: "80%",
        maxWidth: '235px',
        margin: "auto",
        color: "#9F9F9F",

        "&:hover": {
          cursor: 'pointer'
        }
      }
      
    },

    tabItem: { 
      padding: "14px 0px", 
      borderRadius: "4px", 
      lineHeight: "16px" 
    }
  });


export default withStyles(styles)(Tabs)
// Customizable Area Start
import React from "react";
import { Box, Grid, createMuiTheme, Hidden } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
// import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
// import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
// import WebAppLayoutController, { Props } from "./WebAppLayoutController.web";
// import WebHeader from "./WebHeader.web";
// import WebSidebar from "./WebSidebar.web";
// import { customThemeStyles } from "./CustomThemeStyles.web";
// import { combineStyles } from "./CombineStyles.web";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import GenericTabs from "../GenericTabs/GenericTabs.web";
import FilterView from "../FilterView/FilterView.web";
import { ACCORDIAN_LIST, NavbarSections } from "./config";
import { AccordianItem } from "../Types";
import LayoutContext from "./layout-context";
import Navbar from '../NavBar.web';
import TopBar from "../TopBar/TopBar.web";
import  AllFilter  from "../../../blocks/filteritems/src/AllFilter.web";
import ResponsiveTopBar from '../../../components/src/TopBar/ResponsiveTopBar.web';
// Customizable Area End

// Customizable Area Start
interface Props {
  classes: any;
  children: any;
  navigation: any;
}

interface S {
  open: boolean;
  accordionList: AccordianItem[];
  selectedTabIndex: number;
  filterList: { [key: string]: string[] };
}

interface SS {
}

const styles = (theme: Theme) =>
  createStyles({
    mainContainer: {
      position: "relative",
      display: "flex",
    },
    mainChildWrapper: {
      marginTop: "85px",
      transition: "width 0.4s ease-out",
      minHeight: "100vh",
    },
    expandSidebarIconBox: {
      position: "fixed",
      top: "127px",
      transition: "left 0.4s ease-out",
      width: "20px",
      height: "20px",
      borderRadius: "8px",
      padding: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgb(253, 215, 47)",
      zIndex: 1,
      cursor: "pointer",
    },
    sidenav: {
      width: "250px",
      transition: "width 0.3s ease-in-out",
      height: "100vh",
      backgroundColor: "rgb(10, 25, 41)",
      paddingTop: "28px",
    },
    sidenavClosed: {
      composes: "sidenav",
      transition: "width 0.3s ease-in-out",
      width: "60px",
    },
    sideitem: {
      display: "flex",
      alignItems: "center",
      padding: "10px 20px",
      cursor: "pointer",
      color: "#b2bac2",
      textDecoration: "none",
      overflow: "hidden",
    },
    linkText: {
      paddingLeft: "16px",
    },

    "& .sideitem:hover": {
      backgroundColor: "#244f7d1c",
    },
    menuBtn: {
      alignSelf: "center",
      // alignSelf: "flex-start",
      justifySelf: "flex-end",
      color: "#b2bac2",
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      paddingLeft: "20px",
      position: "relative",
      top: "124px",
      left: "40px",
      zIndex: 1000000,
      boxShadow: "3px",
      borderRadius: "10px",
      // backgroundColor: "red",
      transition: "width 0.3s ease-in-out",
    },
    menuBtnExpand: {
      alignSelf: "center",
      // alignSelf: "flex-start",
      justifySelf: "flex-end",
      color: "#b2bac2",
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      paddingLeft: "20px",
      position: "relative",
      top: "124px",
      left: "230px",
      transition: "width 0.3s ease-in-out",
      zIndex: 1000000,
    },
  });

const drawerWidth = 200;
const drawerCloseWidth = 55;

const THEME = createMuiTheme({
  typography: {
    fontFamily: "Source Sans Pro",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
  },
});

// Customizable Area End

export class WebAppLayout extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.state = {
      open: true,
      accordionList: JSON.parse(JSON.stringify(ACCORDIAN_LIST)),
      filterList: {},
      selectedTabIndex: 1,
    }
  }
  // Customizable Area End

  toggleOpen() {
    this.setState({ open: !this.state.open });
    this.setState({
      accordionList: this.state.accordionList.map((acc: AccordianItem) => {
        return { ...acc, show: false }
      })
    })
    // this.setState((prevState: S) => {
    //     return{
    //     open: !prevState.open
    //     }
    // }
    // )
  }

  clearFilters = () => {
    // We need to assigned to new allocated memory for reset.
    // The react diffing algorithm work seem wrong when we clear twice
    this.setState({
      accordionList: JSON.parse(JSON.stringify(ACCORDIAN_LIST)),
    });
    this.setFilterList({});
  };

  toggleFilterItemsCheckbox = (
    item: { name: string; value: boolean },
    subItem: string,
    parentItem: string
  ) => {
    this.setState((prevState: any) => {
      const updatedAccordionList = prevState.accordionList.map(
        (accordionItem: any) => {
          if (accordionItem.text === parentItem) {
            const updatedFilterOption = {
              ...accordionItem.filterOption,
              [subItem]: accordionItem.filterOption[subItem].map(
                (filter: any) => {
                  if (filter.label === item.name) {
                    return {
                      ...filter,
                      isChecked: !item.value,
                    };
                  }
                  return filter;
                }
              ),
            };

            return {
              ...accordionItem,
              filterOption: updatedFilterOption,
            };
          }
          return accordionItem;
        }
      );

      return {
        accordionList: updatedAccordionList,
      };
    });
  };

  setFilterList = (filterList: any) => {
    this.setState({ filterList: filterList });
  };

  findActiveTabForNavbar = () => {
    const activeModule = this.props.navigation.module

    return NavbarSections.findIndex(section => section === activeModule)
  }

  render() {

    // Customizable Area Start
    const { children } = this.props;
    const { open } = this.state
    return (
      <LayoutContext.Provider value={{ filterList: this.state.filterList, setFilterList: this.setFilterList }}>
        <MuiThemeProvider theme={THEME}>
          <div style={{ fontFamily: 'Source Sans Pro', display: 'flex', backgroundColor: '#F3F3F3', width: '100%', height:"calc(100vh - 64px)" }}>
            <Grid container justifyContent='center' style={{backgroundColor:"#F3F3F3", height:"fit-content"}}>
              <div style={{ display: 'flex', flexDirection: 'column', padding: '20px 20px 30px', background: '#f3f3f3', maxWidth: "100%"}}>
                <Hidden mdUp>
                  <ResponsiveTopBar navigation={this.props.navigation} title={NavbarSections[this.findActiveTabForNavbar()]} />
                </Hidden>
                <Hidden smDown>
                <Navbar navigation={this.props.navigation} selectedTab={this.findActiveTabForNavbar()} />
                </Hidden>
                {children}
              </div>
            </Grid>
          </div>
        </MuiThemeProvider>
      </LayoutContext.Provider>
    );
    // Customizable Area End
  }

  toggleAcordion(i: number) {
    console.log("asdfasdf: ", i)
    this.setState((prevState: S) => {
      prevState.accordionList[i].show = !prevState.accordionList[i].show;
      return { accordionList: [...prevState.accordionList] }
    })
    // setAccordionList((prev) => {
    //     prev[i].show = !prev[i].show
    //     return [...prev]
    // })
  };

  toggleInnerRadioAcordion(i: number, selectedGrpRelation: string, subItem: string) {
    console.log("asdfasdf: ", i)
    this.setState((prevState: S) => {
      prevState.accordionList[i].filterOptionForRadio[selectedGrpRelation][subItem].show = !prevState.accordionList[i].filterOptionForRadio[selectedGrpRelation][subItem].show;
      return { accordionList: [...prevState.accordionList] }
    })
    // setAccordionList((prev) => {
    //     prev[i].show = !prev[i].show
    //     return [...prev]
    // })
  };

  handleFilterChange(changedList: { [key: string]: string[] }) {
    console.log("changedList: ", changedList);
    this.setState((prevState: S) => {
      // this.setFilterList({ ...prevState.filterList, ...changedList })
      return { filterList: { ...prevState.filterList, ...changedList } }
    })
  }

  onReset(i: number) {
    this.setState((prevState: S) => {
      switch (i) {
        case 1:
          prevState.accordionList[i].filterOption.Level.map((element: any) => {
            element.isChecked = false
          })
          prevState.accordionList[i].filterOption.Discipline.map((element: any) => {
            element.isChecked = false
          })
          break;
        case 2:
          prevState.accordionList[i].filterOption.Primary.map((element: any) => {
            element.isChecked = false
          })
          prevState.accordionList[i].filterOption.Secondary.map((element: any) => {
            element.isChecked = false
          })
          break;
        case 3:
          prevState.accordionList[i].filterOption.Position.map((element: any) => {
            element.isChecked = false
          })
          prevState.accordionList[i].filterOption.Position.map((element: any) => {
            element.isChecked = false
          })
          break;
        case 6://group
          prevState.accordionList[i].filterOptionForRadio.related["Currently Employed"].optionsList.map((element: any) => {
            element.isChecked = false
          })

          prevState.accordionList[i].filterOptionForRadio.related["Past Employed"].optionsList.map((element: any) => {
            element.isChecked = false
          })

          prevState.accordionList[i].filterOptionForRadio.related["Submitted for Tender"].optionsList.map((element: any) => {
            element.isChecked = false
          })

          prevState.accordionList[i].filterOptionForRadio.unrelated[""].optionsList.map((element: any) => {
            element.isChecked = false
          })

          prevState.accordionList[i].radioOption.map((element: any) => {
            element.isChecked = false
          })
          break;
        default:
          prevState.accordionList[i].filterOption[""].map((element: any) => {
            element.isChecked = false
          })
          break;
      }
      return { accordionList: [...prevState.accordionList] }
    })
  }
}

// Customizable Area Start

// const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(styles)(WebAppLayout);
// Customizable Area End

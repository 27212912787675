import React, { Component } from 'react';
import ReadIdentification from './ReadIdentification.web';
import UpdateIdentification from './UpdateIdentification.web';
import { IIdentification } from '../../PeopleModuleController.web';

type Props = {
	isPersonalDataDone: boolean;
	identification: IIdentification;
	handleChangeValue: (val: string, key: string) => void;
	handlePostIdentificationData: () => void;
	handleUpdateIdentificationData: () => void;
	handleSwitchMode: (section: string, mode: 'update' | 'read' | 'post', onCancel?:boolean) => void;
	handlePeopleRequestNavigation: () => void;
};

type State = {};

export default class Identification extends Component<Props, State> {
	render() {
		const { mode } = this.props.identification;

		if (mode === 'read') {
			return (
				<ReadIdentification
					identification={this.props.identification}
					handleSwitchMode={this.props.handleSwitchMode}
					handlePeopleRequestNavigation={this.props.handlePeopleRequestNavigation}
				/>
			);
		} else {
			return (
				<UpdateIdentification
					isPersonalDataDone={this.props.isPersonalDataDone}
					identification={this.props.identification}
					handlePostIdentificationData={this.props.handlePostIdentificationData}
					handleChangeValue={this.props.handleChangeValue}
					handleSwitchMode={this.props.handleSwitchMode}
					handleUpdateIdentificationData={
						this.props.handleUpdateIdentificationData
					}
				/>
			);
		}
	}
}

export const filterIcon = require("./assets/FilterIcon.svg");
export const currentPositionIcon = require("./assets/CurrentPosition.svg");
export const educationIcon = require("./assets/Education.svg");
export const experienceIcon = require("./assets/Experience.svg");
export const favouriteIcon = require("./assets/Favourite.svg");
export const groupRelationIcon = require("./assets/GroupRelation.svg");
export const keywordIcon = require("./assets/Keyword.svg");
export const languageIcon = require("./assets/Language.svg");
export const nationalityIcon = require("./assets/Nationality.svg");
export const savedSearchIcon = require("./assets/SavedSearch.svg");
export const expandFilterIcon = require("./assets/expandFilter.svg");
export const collapseFilterIcon = require("./assets/collapseFilter.svg");
export const accordionExpandIcon = require("./assets/AccordionExpand.svg");
export const accordionCollapseIcon = require("./assets/AccordionCollapse.svg");
export const paginationDropdownIcon = require("./assets/PaginationDropdown.svg");
export const checkboxCheckedIcon = require("./assets/Checkedbox.svg");
export const checkboxUncheckedIcon = require("./assets/UncheckedBox.svg");
export const groupCompanyIcon = require("./assets/groupcompany.svg");
export const clientsIcon = require("./assets/clients.svg");
export const countryIcon = require("./assets/country.svg");
export const servicesIcon = require("./assets/services.svg");
export const sectorIcon = require("./assets/sector.svg");
export const valueOfWorkIcon = require("./assets/valueofwork.svg");
export const statusIcon = require("./assets/status.svg");
export const calendar = require("../assets/calendar.svg");
export const uploadIconSvg = require('../assets/upload_icon.svg')
export const searchIconSvg = require('../assets/search_icon.svg')

export enum SearchApiEndpoint {
    People = "/bx_block_people/search_people?q=",
    Project =  "/bx_block_profile/projects/advance_search?q=",
    Tender = "/bx_block_profile/tenders?advance_search=",
    Supplier = "/bx_block_multipageforms2/suppliers?advance_search=",
    Partner = "/bx_block_multipageforms2/partners?advance_search=",
  }

// Customizable Area Start
import React, { Component } from 'react';
import ReadSupplierDetails from './ReadSupplierDetails.web';
import UpdateSupplierDetails from './UpdateSupplierDetails.web';
import { ISupplierData, S, DropDownItems } from './SupplierModuleController.web';
import { Snackbar } from '@material-ui/core';
// Customizable Area End

// Customizable Area Start
type Props = {
	supplierDetails: ISupplierData;
	handleChangeValue: (val: string, key: string) => void;
	handleSwitchMode: (section: string, mode: 'update' | 'read' | 'post', onCancel?: boolean) => void;
	onChange: (objectKey: keyof S, property: any, value: any | null) => void;
	SupplierErrors: boolean;
	handlePostSupplierData: () => void;
	handleUpdateSupplierData: () => void;
	supplierRequest?: () => void;
	onCancelUpload: () => void;
	groupResponse: DropDownItems[];
	countryResponse: DropDownItems[];
	servicesResponse: DropDownItems[];
	sectorResponse: DropDownItems[];
	internalRateResponse: DropDownItems[];
	module: "People" | "Project" | "Tender" | "Supplier" | "Partner";
};

type State = {};
// Customizable Area End


export default class SupplierDetails extends Component<Props, State> {

	// Customizable Area start
	renderReadSupplier = () => {
		return(
			<ReadSupplierDetails
				supplierDetails={this.props.supplierDetails}
				handleSwitchMode={this.props.handleSwitchMode}
				onChange={this.props.onChange}
				module={this.props.module}
				supplierRequest={this.props.supplierRequest}
			/>
		)
	}

	renderUpdateSupplierDetails = () => {
		const {
			groupResponse,
			countryResponse,
			servicesResponse,
			sectorResponse,
			internalRateResponse
		} = this.props
		return (

			<UpdateSupplierDetails
				supplierDetails={this.props.supplierDetails}
				handleChangeValue={this.props.handleChangeValue}
				handleSwitchMode={this.props.handleSwitchMode}
				emailErrors={false}
				phoneNumberError={false}
				handlePostSupplierData={this.props.handlePostSupplierData}
				handleUpdateSupplierData={this.props.handleUpdateSupplierData}
				handleCancelUpdate={this.props.onCancelUpload}
				isPhoneInvalid={false}
				groupResponse={groupResponse}
				countryResponse={countryResponse}
				servicesResponse={servicesResponse}
				sectorResponse={sectorResponse}
				internalRateResponse={internalRateResponse}
			/>
		)

	}
	// Customizable Area End
    
	// Customizable Area Start
	render() {
		const { mode } = this.props.supplierDetails; 
	return (
		<>{
			mode === 'read' ? this.renderReadSupplier():this.renderUpdateSupplierDetails()
		}
		<Snackbar
		
		/>
		</>
	)
	}
	// Customizable Area End
}

import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  Typography,
  Divider,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { loginBackground } from "./assets";
import ToggleEyeInput from "../../../components/src/ToggleEyeInput.web";

// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./AccountLoginController.web";
import ImageComponent from "../../forgot-password/src/imageComponent.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export class EmailAccountLoginBlock extends EmailAccountLoginController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    const { classes } = this.props;
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Box className={classes.mainRoot}>
            <ImageComponent />
          <Box className={classes.rootbox}>
            <Box className={classes.boxRoot}>
              <Box className="loginText">
                Login
              </Box>
              <Box>
                <Divider className={classes.dividerRoot} />
              </Box>
              <Box className={classes.input}>
                <InputLabel>Email address</InputLabel>
              </Box>
              <Box>
                <Input
                  data-testId="setEmail"
                  name="email"
                  value={this.state.email}
                  onChange={(event) => this.setInput(event.target.value, "email", "failed_login")}
                  className={classes.innerInput}
                  type="text"
                  autoComplete="email"
                  style={{
                    borderColor: !!this.renderLoginError("failed_login") ? "#DC2626" : "#0094A7"
                  }}
                />
                {!!this.renderLoginError("failed_login") && (
                  <span style={{ position: "relative"}} className={classes.helperText}>
                    {this.renderLoginError("failed_login")}
                  </span>
                )}
              </Box>
              <Box className={classes.input}>
                <InputLabel>Password</InputLabel>
              </Box>
              <Box>
                <ToggleEyeInput
                  data-testId="setPassword"
                  onChange={(event) => this.setInput(event.target.value, "password", "invalid_password")}
                  value={this.state.password}
                  className={classes.innerInput}
                  type="password"
                  style={{
                    borderColor: !!this.renderLoginError("invalid_password") ? "#DC2626" : "#0094A7"
                  }}
                />
                {this.renderLoginError("invalid_password") && (
                  <span style={{ position: "relative"}} className={classes.helperText}>
                    {this.renderLoginError("invalid_password")}
                  </span>
                )}
                {}
              </Box>
              <Box className={classes.checkboxroot}>
                <FormControlLabel
                  label={
                    <Typography
                      style={{
                        lineHeight: "16px",
                        fontFamily: "Source Sans Pro",
                        fontSize: 16,
                      }}
                    >
                      Remember me
                    </Typography>
                  }
                  control={
                    <Checkbox
                      data-testId="setRememberMe"
                      onClick={() =>
                        this.setInput(
                          !this.state.checkedRememberMe,
                          "checkedRememberMe"
                        )
                      }
                      checked={this.state.checkedRememberMe}
                      style={{ color: "#188c9f", paddingLeft: 0 }}
                      disableRipple
                    />
                  }
                  style={{ marginLeft: 0 }}
                />
              </Box>
              <Box className={classes.buttonroot}>
                <Box style={{ width: "100%" }}>
                  <Button
                    data-testId="btnSocialLogin"
                    onClick={() => this.doEmailLogIn()}
                    style={{ ...styles.loginbuttonroot, textTransform: "none" }}
                  >
                    Login
                  </Button>
                </Box>
              </Box>
              <Box
                data-testId="btnForgotPassword"
                onClick={() => this.goToForgotPassword()}
                style={{
                  ...styles.forgotpasswordroot,
                  textDecorationSkipInk: "none",
                }}
              >
                Forgot Password?
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const styles = {
  mainBox2: {
    width: "60%",
    height: "20%",
    borderRadius: "5px",
    backgroundColor: "white",
  },
  mainRoot: {
    display: "flex",
    justifyContent: "space-between",
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${loginBackground})`,
    backgroundSize: "100% 100%",
    fontFamily: "Source Sans Pro",
  },
  dividerRoot: {
    backgroundColor: "E8E8E8",
    margin: "15px 0 0",
  },
  boxRoot: {
    margin: "auto",
    width: "60%",
    height: "min-content",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    "& .loginText":{
      '@media (max-width: 1280px)': {
        fontSize: 32,
        lineHeight: "32px",
      },
      display: "flex",
      justifyContent: "center",
      fontSize: 40,
      fontWeight: 700,
      fontFamily: "Source Sans Pro",
      '@media (max-width: 600px)': {
        fontWeight: 600,
        lineHeight: "28px",
        fontSize: 28
      },
    },
    '@media (min-width: 600px) and (max-width: 1279px)': {
      width: "70%",
    },
    '@media (max-height: 500px)': {
      overflowY: "scroll", 
      overflowX: "hidden",
      height: "100%",
    },
    '@media (max-width: 600px)': {
      margin: "140px 25px 25px",
      paddingBottom: "25px",
      width: "75%",
    },
  },
  innerInput: {
    border: "1px solid #0094A7",
    borderRadius: 6,
    width: "100%",
    padding: "1.5vh 1.5vw",
    fontSize: 18,
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    color: "#494949",
    "&::before":{
      borderBottom: "none",
    },
    "&::after":{
      borderBottom: "none",
    }
  },
  helperText:{
    top: "8px",
    color: "#DC2626",
    fontSize: "16px",
    fontFamily: "Source Sans Pro",
    fontWeight: 400,
    lineHeight: "16px",
  },
  input: {
    display: "flex",
    margin: "30px 0 10px",
    "& .MuiFormLabel-root": {
      color: "#9F9F9F",
      fontFamily: "Source Sans Pro",
      fontSize: 18,
    },
  },
  checkboxroot: {
    display: "flex",
    marginTop: "10px",
  },
  buttonroot: {
    display: "flex",
    marginTop: "32px",
  },
  loginbuttonroot: {
    color: "white",
    width: "100%",
    backgroundColor: "#0094A7",
    height: "3rem",
    fontWeight: 600,
    borderRadius: 8,
    fontFamily: "Source Sans Pro",
    fontSize: 18,
  },
  forgotpasswordroot: {
    cursor: "pointer",
    display: "flex",
    marginTop: "25px",
    color: "#0094A7",
    textDecoration: "underline",
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
  },
  rootbox: {
    height:"100%",
    display: "flex",
    backgroundColor: "white",
    width: "50vw",
    borderRadius: "50px 0 0 50px",
    '@media (max-width: 600px)': {
      justifyContent: "center",
      top: "120px",
      width: "100vw",
      borderRadius: "50px 50px 0 0px",
      position: "absolute",
    },
  },
};

export default withStyles(styles)(EmailAccountLoginBlock);
// Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "savesearch";
exports.labelBodyText = "savesearch Body";
exports.deletApiMethod = "DELETE";
exports.patchApiMethod = "PATCH";

exports.notifyMePeopleEndPoint = "bx_block_people/people/notify_people";
exports.notifyMeProjectEndPoint = "bx_block_profile/projects/notify_project";
exports.notifyMeTenderEndPoint = "bx_block_profile/tenders/notify_tender";
exports.notifyMeSupplierEndPoint = "bx_block_multipageforms2/suppliers/notify_supplier";
exports.notifyMePartnerEndPoint = "bx_block_multipageforms2/suppliers/notify_supplier?type=partner";

exports.deleteSaveSearchEndPoint = "bx_block_saved_search/saved_searches";
exports.saveSearchEndPoint = "bx_block_saved_search/saved_searches";
exports.getDestroyMsg = "Saved search destroyed successfully";
exports.onEditSearchErrorMsg = "Search name is required";
exports.onEditNoFilterErrorMsg = "At least one filter is required";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
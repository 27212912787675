Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

// ** PEOPLE MODULE **
exports.peopleModuleGoBackParam = "PeopleModuleTable";
exports.peopleModuleTestId = "people-module";
exports.peopleBackButtonTestId = "people-module-back-button";
exports.getOtherCurrentPositionsEndPoint = "bx_block_people/current_positions";
exports.getDisciplineOptionsEndPoint = "bx_block_people/education_streams";
exports.getExperienceOptionsEndPoint = "bx_block_people/experiences";
exports.getYearOptionsEndPoint = "bx_block_people/years";
exports.createUpdatePersonalDataEndPoint = "bx_block_people/people";
exports.peopleModuleSections = [
  "Personal Data",
  "Identification",
  "Work",
  "Documents",
  "Connections",
  "Audit trail",
];
exports.TenderModuleSections = [
  'Tender Details',
	'Award Letter',
	'Connections',
	'Audit trail',
];
exports.SupplierModuleSections = [
  'Supplier Details',
	'Brochure',
  'Notes',
	'Connections',
	'Audit trail',
];
exports.PartnerModuleSections = [
  'Partner Details',
	'Brochure',
  'Notes',
	'Connections',
	'Audit trail',
];

exports.personalDataTestId = "people-module-personal-data";
exports.firstname_testId = "people-module-input-firstname";
exports.lastName_testId = "people-module-input-lastname";
exports.dateOfBirth_testId = "people-module-input-dateOfBirth";
exports.nationality_testId = "people-module-input-nationality";
exports.email_testId = "people-module-input-email";
exports.phoneNumber_testId = "people-module-input-phoneNumber";
exports.personalDataCancelButtonTestId =
  "people-module-personal-data-cancel-button";
exports.personalDataSaveButtonTestId =
  "people-module-personal-data-save-button";
exports.identificationTestId = "people-module-identification";
exports.visadesignation_testId = "people-module-input-visaDesignation";
exports.joiningdate_testId = "people-module-input-joiningDate";
exports.enddate_testId = "people-module-input-endDate";
exports.identification_diploma_testId = "people-module-input-diploma";
exports.identification_year_testId = "people-module-input-year";
exports.identification_passport_testId = "people-module-input-passport";
exports.identification_localId_testId = "people-module-input-localId";
exports.identification_driving_licence_testId =
  "people-module-input-drivingLicence";
exports.identification_passport_expiry_date =
  "people-module-input-passport-expiryDate";
exports.identification_localId_expiry_date =
  "people-module-input-localId-expiryDate";
exports.identificationSaveButtonTestId =
  "people-module-identification-save-button";
exports.workTestId = "people-module-work";
exports.highestLevel_testId = "people-module-input-highestLevel";
exports.discipline_testId = "people-module-input-discipline";
exports.primaryExperience_testId = "people-module-input-primaryExperience";
exports.primaryYears_testId = "people-module-input-primaryYear";
exports.secondaryExperience_testId = "people-module-input-secondaryExperience";
exports.secondaryYears_testId = "people-module-input-secondaryYear";
exports.region_testId = "people-module-input-regionOfExperience";
exports.work_design_testId = "people-module-input-design";
exports.work_supervision_testId = "people-module-input-supervision";
exports.currentPosition_testId = "people-module-input-currentPosition";
exports.internalRate_testId = "people-module-input-internalRate";
exports.otherLanguage_testId = "people-module-input-otherLanguage";
exports.workSaveButtonTestId = "people-module-work-save-button";
exports.workCancelButtonTestId = "people-module-work-cancel-button";
exports.work_design_yes_testId = "people-module-input-design-yes";
exports.work_design_no_testId = "people-module-input-design-no";
exports.documentsTestId = "people-module-documents";

exports.apiPutMethod = "PUT";

exports.examplePersonalDataDetails = {
  id: "1",
  type: "people",
  attributes: {
    people: {
      first_name: "First name",
      last_name: "Last name",
      date_of_birth: "30-10-2023",
      nationality: "Albania",
    },
    contact_information: {
      email: "example@email.com",
      phone_number: "+358-1242141241",
    },
    resume: {},
    documents: {},
  },
};

exports.exampleIdentificationDetails = {
  id: 2,
  visa_designation: "VisaDesignition",
  joining_date: "2023-11-01",
  end_date: "2023-11-17",
  diploma: "Structure",
  year: 1901,
  passport_number: "T11111111",
  passport_expiry_date: "2023-11-03",
  local_id: "TestLocalId",
  local_expiry_date: "2023-11-17",
  driving_license: "TestDrivingLicence",
  people_id: 1,
  created_at: "2023-11-02T15:19:01.082-04:00",
  updated_at: "2023-11-02T15:19:01.082-04:00",
};
exports.exampleWorkDetails = {
  work: {
    education_degree: "Master",
    education_stream: "Architecture",
    primary_experience: "Airports",
    primary_year: "5 to 10yrs",
    secondary_experience: "Agriculture / Irrigation",
    secondary_year: "10 to 15yrs",
    region_of_experience: "America",
    design: "Yes",
    supervision: "No",
    current_position: "design",
    internal_rate: "good",
  },
  group_relation: {
    relation: "related",
    employe_status: "Currently  Employed",
    status_option: "Renardet Oman",
  },
  languages: [
    {
      name: "English",
      level: "Advance",
    },
    {
      name: "Arabic",
      level: "Beginner",
    },
  ],
};

exports.exampleApiResponseForGettingPeopleDetails = {
  id: "1",
  type: "people",
  attributes: {
    people: {
      first_name: "John",
      last_name: "Doe",
      date_of_birth: "18-10-2023",
      nationality: "British Indian Ocean Territory",
    },
    contact_information: {
      email: "example@gmail.com",
      phone_number: "+672-313939394",
    },
    resume: {},
    documents: {},
  },
};

exports.exampleErrorApiResponse = {
  errors: [
    {
      email: "can't be blank",
    },
    {
      email: "email must be valid.",
    },
    {
      first_name: "can't be blank",
    },
    {
      last_name: "can't be blank",
    },
    {
      nationality: "can't be blank",
    },
    {
      date_of_birth: "can't be blank",
    },
    {
      phone_number:
        "Only numeric values and phone number must contain 7 to 15 digits.",
    },
  ],
};
// ** PEOPLE MODULE **

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultipageForms";
exports.labelBodyText = "MultipageForms Body";

exports.btnExampleTitle = "CLICK ME";

exports.successMessage = "UserDetails inserted successfully";
exports.errorsDataMsg = "Internal Server error";
exports.multiPageFormSubmitEndPoint = "/bx_block_multipageforms/user_profiles";

exports.getSectorsEndPoint = '/bx_block_company/sectors'
exports.getServicesEndPoint = '/bx_block_company/services'
exports.getClientsEndPoint = '/bx_block_company/clients'
exports.getGroupCompaniesEndPoint = '/bx_block_company/group_companies'
exports.getTypesOfCurrencyEndPoint = '/bx_block_company/types_of_currency'
exports.getValuesOfWorkEndPoint = '/bx_block_company/values_of_work'
exports.getServiceTypesEndPoint = '/bx_block_company/service_types'
exports.getProjectStatusOptionsEndPoint = "/bx_block_company/project_statuses";
exports.getTenderStatusOptionsEndPoint = "/bx_block_company/tender_status";
exports.getTenderTypeOptionsEndPoint = "bx_block_company/tender_type";
exports.getCountriesEndPoint = '/bx_block_company/countries'
exports.getInternalRateEndPoint = '/bx_block_company/internal_rate'
exports.getProjectDetailsEndPoint = '/bx_block_profile/projects'
exports.deleteCertificateEndPoint = '/bx_block_profile/projects'
exports.deleteAwardLetterEndPoint = '/bx_block_profile/tenders'
exports.brochureAPIEndPoint= 'bx_block_multipageforms2/suppliers'
exports.supplierDetailsEndPoint = '/bx_block_multipageforms2/suppliers';
exports.supplierNotesAPIEndPoint = 'bx_block_multipageforms2/suppliers'
exports.PartnerDetailsEndPoint = 'bx_block_multipageforms2/partners';
exports.apiGetMethod = 'GET'
exports.apiPostMethod = 'POST'
exports.apiPatchMethod = 'PATCH'
exports.apiDeleteMethod = 'DELETE'
exports.apiFormDataContentType ="multipart/form-data";


exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"

exports.exampleErrorMessage = "Happy Error!";
exports.bigFileErrorMessage = 'File size exceeds 10MB. Please choose a smaller file.'
exports.fileErrorMessage = 'File is not supported. Please choose another file'
exports.jointVentureShareInputError = "Please type only numbers between 0.01 - 100.00."
exports.enterValueInputError = "Please type only numbers (min: 0.01)"
exports.goBackParam = "ProjectModuleTable"
exports.valueFormater = /\B(?=(\d{2})+(?!\d))/g
exports.actualValue = /[^0-9.]/g

exports.headerPadding = 75
exports.errorMessageDuration = 5000
exports.sections = ["Project details", "Technical summary", "Certificate", "Gallery", "Connections", "Audit trail"]

exports.testId = "project-module"
exports.sideBarTestId = "project-module-sidebar"
exports.backButtonTestId = "project-module-back-button"
exports.editButtonTestId = "project-module-view-edit"
exports.cancelButtonTestId = "project-module-cancel-button"
exports.containerTestId = "project-module-container"
exports.projectModuleSaveButtonTestId = "project-module-save-button"
exports.tenderModuleSaveButtonTestId = "tender-module-save-button"
exports.projectModuleHeaderTestId="project-module-header"
exports.projectDetailsTestId = "project-module-project-details"
exports.project_nameTestId = "project-module-input-projectName"
exports.project_codeTestId = "project-module-input-projectCode"
exports.project_titleTestId = "project-module-input-projectTitle"
exports.joint_ventureTestId = "project-module-input-jointVenture"
exports.projectModuleJointVentureErrorTestId = 'project-module-input-jointVenture-error'
exports.joint_venture_shareTestId = "project-module-input-jvshare"
exports.group_companyTestId = "project-module-input-groupcompany"
exports.countryTestId = "project-module-combo-country"
exports.clientTestId = "project-module-input-project-client"
exports.client_nameTestId = "project-module-input-project-client-name"
exports.servicesTestId = "project-module-input-project-services"
exports.sectorTestId = "project-module-input-project-sector"
exports.inputTypeOfCurrencyTestId = "project-module-input-project-currency-type"
exports.currency_valueTestId = "project-module-input-project-currency-enter-value"
exports.value_of_workTestId = "project-module-input-project-value-of-work"
exports.feeTestId = "project-module-input-project-our-fee"
exports.service_typeTestId = "project-module-input-project-service-type"
exports.start_dateTestId = "project-module-input-project-startDate"
exports.end_dateTestId = "project-module-input-project-endDate"

exports.technical_summaryTestId = "project-module-input-technical-summary"
exports.projectCertificatesTestId = "project-module-certificates"
exports.projectCerticateTestId = "project-module-certificates-certificate-item"
exports.tenderAwardLetterTestId = "tender-module-award-item"
exports.projectGalleryViewTestId = "project-module-gallery-view"
exports.projectGalleryItemTestId = "project-module-gallery-item"

exports.projectCertificatesUploadFileInputTestId = "project-module-certificates-file-upload-input"
exports.tenderAwardLetterUploadFileInputTestId = "tender-module-award-file-upload-input"
exports.projectCertificatesUploadButtonTestId = "project-module-certificates-file-upload-button"
exports.tenderAwardLetterUploadButtonTestId = "tender-module-award-file-upload-button"
exports.certificatesToUploadTestId="project-module-certificates-uploaded-item"
exports.awardLetterToUploadTestId="tender-module-award-uploaded-item"
exports.removeUploadedFileButtonTestId = "project-module-remove-uploaded-file-button"
exports.projectCertificatesAddMoreButtonTestId = "project-module-certificates-add-more"
exports.tenderAwardLetterAddMoreButtonTestId = "tender-module-award-add-more"
exports.projectCertificateDocumentName = "project-module-certificates-certificate-name"
exports.tenderAwardLetterDocumentName = "tender-module-award-name"

exports.projectGalleryAddMoreButtonTestId = "project-module-gallery-add-more"
exports.projectGalleryImagesToUploadTestId="project-module-images-uploaded-item"
exports.projectGalleryInputFileButtonTestId = "project-module-gallery-image-upload-input"
exports.removeUploadedImageButtonTestId = "project-module-gallery-remove-uploaded-image-button"
exports.projectGalleryUploadButtonTestId = "project-module-gallery-image-upload-button"
exports.errorInvalidFormat = "File is invalid format. Please choose another file"


exports.newRecordMode = 'post'
exports.updateRecordMode = 'put'
exports.viewRecordMode = 'get'
exports.addParam = 'add'

exports.jointVentureTrue = true
exports.jointVentureFalse = false
exports.fixedJVfloating = 2

exports.ourFeeCurrencyType = "USD"


exports.exampleProjectDetails = {
  project_id: 'testId',
  project_name: "Sample Project",
  project_code: "ABC123",
  project_title: "Project Title Sample",
  joint_venture: false,
  joint_venture_share: "50",
  company_detail_attributes: {
    group_company: "Renardet SA",
    country: "Poland",
    client: "Private",
    client_name: "John Doe",
    services: ["Concept Design"],
    sector: "Railway",
    type_of_currency: "EUR",
    currency_value: "25.2",
    fee: "10",
    value_of_work: "26000",
    service_type: "Supervision",
    start_date: "2023-10-18T01:32:45.733-04:00",
    end_date: "2023-10-18T01:32:45.733-04:00",
  }
}

exports.exampleTechnicalSummary = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vestibulum, quam id posuere ultricies, justo velit pretium sapien, vel ultrices massa tortor ac augue."

exports.exampleCertificates = [
  {
    certificate_link:
      "https://minio.b323019.dev.eastus.az.svc.builder.cafe/sbucket/9sun6mej3zkolvh1z378gxnzmnyj",
    certificate_name: "chairzxczxczxczxczxczxczxczxczxczxczxczxc.pdf",
    upload_date: "2023-10-24",
    certificate_id: 950,
  },{
    certificate_link:
      "https://minio.b323019.dev.eastus.az.svc.builder.cafe/sbucket/9sun6mej3zkolvh1z378gxnzmnyj",
    certificate_name: "chair.jpg",
    upload_date: "2023-10-24",
    certificate_id: 950,
  },
]

exports.exampleAwardLetters = [
  {
    award_letter_url:
      "https://minio.b323019.dev.eastus.az.svc.builder.cafe/sbucket/9sun6mej3zkolvh1z378gxnzmnyj",
    file_name: "chairzxczxczxczxczxczxczxczxczxczxczxczxc.pdf",
    upload_date: "2023-10-24",
    award_letter_id: 950,
  },{
    award_letter_url:
      "https://minio.b323019.dev.eastus.az.svc.builder.cafe/sbucket/9sun6mej3zkolvh1z378gxnzmnyj",
    file_name: "chair.jpg",
    upload_date: "2023-10-24",
    award_letter_id: 951,
  },
]

exports.exampleBrochure = [
  {
    file_link:
      "https://minio.b323019.dev.eastus.az.svc.builder.cafe/sbucket/9sun6mej3zkolvh1z378gxnzmnyj",
    file_name: "chairzxczxczxczxczxczxczxczxczxczxczxczxc.pdf",
    upload_date: "2023-10-24",
    file_id: 950
  },{
    file_link:
      "https://minio.b323019.dev.eastus.az.svc.builder.cafe/sbucket/9sun6mej3zkolvh1z378gxnzmnyj",
    file_name: "chair.jpg",
    upload_date: "2023-10-24",
    file_id: 951,
  },
]

exports.exampleGallery = [
  {
    image_link:
      "https://minio.b323019.dev.eastus.az.svc.builder.cafe/sbucket/9sun6mej3zkolvh1z378gxnzmnyj",
    image_name: "chairzxczxczxczxczxczxczxczxczxczxczxczxc.pdf",
    upload_date: "2023-10-24",
    certificate_id: 950,
  },{
    image_link:
      "https://minio.b323019.dev.eastus.az.svc.builder.cafe/sbucket/9sun6mej3zkolvh1z378gxnzmnyj",
    image_name: "chair.jpg",
    upload_date: "2023-10-24",
    certificate_id: 950,
  },
]

exports.exampleApiResponseForGettingProjectDetails = {
  "data": {
    id: 3,
    "type": "project",
    "attributes": {
      "id": 3,
      "project_name": "Sample Project",
      "start_date": "2023-09-15",
      "end_date": "2023-12-31",
      "title": "Project Title Sample",
      "code": "ABC123",
      "joint_venture": true,
      "joint_venture_share": "50.0",
      "profile_id": 107640
    },
    "relationships": {
      "company_detail": {
        "data": {
          id: 3,
          "type": "company_detail"
        }
      }
    }
  },
  "included": [
    {
      id: 3,
      "type": "company_detail",
      "attributes": {
        "id": 3,
        "group_company": "Renardet SA",
        "country": "Poland",
        "client": "Private",
        "client_name": "John Doe",
        "sector": {
          "id": 1,
          "name": "IT",
          "created_at": "2023-10-17T07:39:57.315-04:00",
          "updated_at": "2023-10-17T07:39:57.315-04:00"
        },
        "services": [],
        "type_of_currency": "EUR",
        "currency_value": "25.2",
        "value_of_work": "3 - 7 MLN",
        "fee": "10"
      }
    }
  ]
}

// NEW MIGRATION
exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

exports.deactivatedUserError = "Your account has been deactivated"
exports.expiredAuthTokenError = "Your session has expired. Please, log in again"
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.MenuItem1 = "Master";
exports.MenuItem2 = "Bachelor";
exports.MenuItem3 = "Diploma";
exports.MenuItem4 = "Computer";
exports.MenuItem5 = "Mecanical";
exports.MenuItem6 = "Civil";
exports.MenuItem7 = "Geology";
exports.MenuItem8 = "Biology";
exports.MenuItem9 = "Maths";
exports.MenuItem10 = "Geology";
exports.MenuItem11= "Biology";
exports.MenuItem12 = "Maths";
exports.MenuItem13 = "0 to 5yrs";
exports.MenuItem14= "1 to 10yrs";
exports.MenuItem15 = "10 to 15yrs";
exports.MenuItem16 = "Indian";
exports.MenuItem17= "Dubai";
exports.MenuItem18 = "U.k";
exports.MenuItem19 = "U.S";
exports.MenuItem20= "Canada";
exports.MenuItem21 = "Turkey";
exports.MenuItem22 = "design";
exports.MenuItem23 = "supervision";
exports.MenuItem24 = "other";
exports.MenuItem25 = "Gujrati";
exports.MenuItem26 = "Marathi";
exports.MenuItem27 = "Urdu";
exports.MenuItem28 = "Hindi";
exports.MenuItem29 = "very_good";
exports.MenuItem30 = "good";
exports.MenuItem31 = "acceptable";
exports.MenuItem32 = "bad";

exports.TabLabel = "Personal Data";
exports.TabLabel1 = "Identification";
exports.TabLabel2 = "Work";
exports.TabLabel3 = "Documentation";
exports.TabLabel4 = "communication";
exports.TabLabel5 = "Audit trail";
exports.TabLabel6 = "Dashboard";
exports.TabLabel7 = "Peoples";
exports.TabLabel8 = "Projects";
exports.TabLabel9 = "Tenders";
exports.TabLabel10 = "Suppliers";
exports.TabLabel11 = "Parteners";

exports.isEmailValidation =  /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
exports.isPhoneNumberValidation = /^(?!(\d)\1{6,14}$)(?!01234567890|1234567890|1234567|12345678|123456789|2345678901|3456789012|4567890123|5678901234|6789012345|7890123456|8901234567|9012345678|0987654321|9876543210|0987654|09876543|098765432|0987654321|9876543|98765432|987654321)\d{7,15}$/i
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.isDialCode = /\+\d{1,4}/
exports.isValidValue = /^\d*$/
exports.isValidLink = /^(ftp|http|https):\/\/[^ "]+$/
exports.isLinkData= "https://example.com/404-page-not-found"

exports.dateOfBirthErrorMesage = "Date of birth can't be in the future"

exports.supplierModuleSaveButtonTestId = "notesSaveButton"
exports.errorsKey = ["project_name","joint_venture_share", "start_date", "end_date"]
exports.minDate = new Date(1900, 0, 1).toISOString().substring(0, 10)
// Customizable Area End
import React, { Component } from "react";
import { Box, Grid } from "@material-ui/core";
import CustomTypographyWeb from "../../../../../components/src/CustomTypography.web";
import { ErrorObject, S, configJSON } from "../../ProjectModuleController.web";
import { CustomTextField, projectModuleCommonStyle } from "../../assets";

interface Props {
  mode: string;
  technicalSummary: string;
  errors: ErrorObject[];
  testId: string;
  onChange: (changedPropName: keyof S, value: any) => void;
}

export default class TechnicalSummaryView extends Component<Props> {
  render() {
    const { mode, technicalSummary, errors, testId } = this.props;
    const webStyle = projectModuleCommonStyle;
    // console.log(this.props.projectDetails)
    return (
      <Box sx={webStyle.containerWrapper} data-test-id={testId}>
        <Grid container style={webStyle.gridContainer}>
          <Grid item xs={12} md={12}>
            <Box>
              <CustomTypographyWeb isHeader style={webStyle.isLabelStyle}>
                Technical summary
              </CustomTypographyWeb>
              {mode === configJSON.viewRecordMode ? (
                <Grid item sm={12}>
                  <CustomTypographyWeb
                    isContent
                    data-test-id={configJSON.technical_summaryTestId}
                    style={{ marginLeft: "21px", marginTop: "24px" }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: technicalSummary.replace(/\n/g, "<br />"),
                      }}
                    />
                  </CustomTypographyWeb>
                </Grid>
              ) : (
                <Grid item sm={12}>
                  <CustomTextField
                    style={{
                      marginTop: "7px",
                    }}
                    variant="outlined"
                    data-test-id={configJSON.technical_summaryTestId}
                    minRows={15}
                    fullWidth
                    multiline
                    value={technicalSummary}
                    onChange={(event: any) =>
                      this.props.onChange(
                        "technical_summary",
                        event.target.value
                      )
                    }
                    error={
                      errors.find((error) =>
                        error.hasOwnProperty("technical_summary")
                      )
                        ? true
                        : false
                    }
                    helperText={
                      errors.find((error) =>
                        error.hasOwnProperty("technical_summary")
                      )?.technical_summary
                    }
                  />
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

import { Box, TextField, styled, withStyles } from '@material-ui/core';

export const requestIcon = require("../assets/image_requeat.png");
export const exampleCertificateImg = require("../assets/image_certificate.png");
export const certificatesOptionsIcon = require("../assets/image_certificate_options.png");
export const uploadIcon = require("../assets/image_upload.svg");
export const deleteIcon = require("../assets/image_delete.png");
export const imageCloseIcon = require("../assets/image_close.png");
export const imageGallery = require("../assets/image_gallery.png");
export const editPageButtonSvg = require("../assets/page_edit.svg");
export const reUpload = require("../assets/reUpload.svg")

export const favEmpty = require('../assets/fav_empty.svg');
export const favFilled = require('../assets/fav_filled.svg');

export const editDocButtonSvg = require('../assets/doc_edit.svg')
export const deleteButtonSvg = require('../assets/delete_button.svg')
export const personIconSvg = require('../assets/person_icon.svg')
export const closeIconSvg = require('../assets/close_icon.svg')
export const importExportIconSvg = require('../assets/import_export_icon.svg')
export const searchIconSvg = require('../assets/search_icon.svg')

export const projectCurrency = "USD";

export const projectStatusOptions = [
  {
    label: "Started",
    value: "Started",
  },
  {
    label: "Completed",
    value: "Completed",
  },
];

export const levelOptions = [
	{ label: "All", value: "All" },
	{ label: "PHD", value: "PHD" },
	{ label: "Master", value: "Master" },
	{ label: "Bachelor", value: "Bachelor" },
	{ label: "Diploma", value: "Diploma" },
];

export const yearsOptions = [
	{ label: "0 to 5yrs", value: "0 to 5yrs" },
	{ label: "5 to 10yrs", value: "5 to 10yrs" },
	{ label: "10 to 15yrs", value: "10 to 15yrs" },
	{ label: "more than 15yrs", value: "more than 15yrs" },
];

export const regionOptions = [
	{ label: "America", value: "America" },
	{ label: "Europe", value: "Europe" },
	{ label: "Africa", value: "Africa" },
	{ label: "Middle East", value: "Middle East" },
	{ label: "Asia", value: "Asia" },
	{ label: "Oceania", value: "Oceania" },
];

export const diplomaOptions = [
	{ label: "High School Diploma", value: "High School Diploma" },
	{
		label: "GED (General Educational Development)",
		value: "GED (General Educational Development)",
	},
	{ label: "Associate's Degree", value: "Associate's Degree" },
	{ label: "Bachelor's Degree", value: "Bachelor's Degree" },
	{ label: "Master's Degree", value: "Master's Degree" },
	{ label: "Doctorate (Ph.D.)", value: "Doctorate (Ph.D.)" },
	{ label: "Postgraduate Diploma", value: "Postgraduate Diploma" },
	{ label: "Advanced Diploma", value: "Advanced Diploma" },
	{
		label: "Diploma in Business Administration",
		value: "Diploma in Business Administration",
	},
	{
		label: "Diploma in Information Technology",
		value: "Diploma in Information Technology",
	},
	{ label: "Diploma in Nursing", value: "Diploma in Nursing" },
	{ label: "Diploma in Accounting", value: "Diploma in Accounting" },
	{ label: "Diploma in Marketing", value: "Diploma in Marketing" },
	{ label: "Diploma in Engineering", value: "Diploma in Engineering" },
	{ label: "Diploma in Graphic Design", value: "Diploma in Graphic Design" },
	{ label: "Diploma in Culinary Arts", value: "Diploma in Culinary Arts" },
	{
		label: "Diploma in Digital Marketing",
		value: "Diploma in Digital Marketing",
	},
	{ label: "Diploma in Human Resources", value: "Diploma in Human Resources" },
	{
		label: "Diploma in Project Management",
		value: "Diploma in Project Management",
	},
	{
		label: "Diploma in Early Childhood Education",
		value: "Diploma in Early Childhood Education",
	},
	{ label: "Others", value: "Others" },
];

export const mainLanguages = [
	{ label: "English", value: "English" },
	{ label: "Arabic", value: "Arabic" },
	{ label: "Italian", value: "Italian" },
	{ label: "French", value: "French" },
	{ label: "Spanish", value: "Spanish" },
];

export const languageLevels = [
	{ label: "Beginner", value: "Beginner" },
	{ label: "Intermediate", value: "Intermediate" },
	{ label: "Advance", value: "Advance" },
	{ label: "Native", value: "Native" },
];

export interface INestedMenuParentItem {
	label: string;
	nested: string[];
}

export const relatedGroupRelation: INestedMenuParentItem[] = [
	{
		label: "Currently Employed",
		nested: ["Renardet Oman", "Bonifica", "Renardet SA", "Profert"],
	},
	{
		label: "Past Employed",
		nested: ["Renardet Oman", "Bonifica", "Renardet SA", "Profert"],
	},
	{
		label: "Submitted for Tender",
		nested: ["Yes", "No"],
	},
];

export const unrelatedGroupRelation: INestedMenuParentItem[] = [
	{
		label: "Interviewed or Recommended",
		nested: [],
	},
	{
		label: "Not interviewed",
		nested: [],
	},
];

export const positionOptions = [
	{ label: "Design", value: "design" },
	{ label: "Supervision", value: "supervision" },
	{ label: "Others", value: "others" },
];

export const internalRateOptions = [
	{ label: "Very good", value: "very_good" },
	{ label: "Good", value: "good" },
	{ label: "Acceptable", value: "acceptable" },
	{ label: "Bad", value: "bad" },
	{ label: "Not Applicable", value: "n/a" },
];

export const languages_list = [
	{ code: "ab", name: "Abkhazian" },
	{ code: "aa", name: "Afar" },
	{ code: "af", name: "Afrikaans" },
	{ code: "ak", name: "Akan" },
	{ code: "sq", name: "Albanian" },
	{ code: "am", name: "Amharic" },
	{ code: "ar", name: "Arabic" },
	{ code: "an", name: "Aragonese" },
	{ code: "hy", name: "Armenian" },
	{ code: "as", name: "Assamese" },
	{ code: "av", name: "Avaric" },
	{ code: "ae", name: "Avestan" },
	{ code: "ay", name: "Aymara" },
	{ code: "az", name: "Azerbaijani" },
	{ code: "bm", name: "Bambara" },
	{ code: "ba", name: "Bashkir" },
	{ code: "eu", name: "Basque" },
	{ code: "be", name: "Belarusian" },
	{ code: "bn", name: "Bengali" },
	{ code: "bh", name: "Bihari languages" },
	{ code: "bi", name: "Bislama" },
	{ code: "bs", name: "Bosnian" },
	{ code: "br", name: "Breton" },
	{ code: "bg", name: "Bulgarian" },
	{ code: "my", name: "Burmese" },
	{ code: "ca", name: "Catalan, Valencian" },
	{ code: "km", name: "Central Khmer" },
	{ code: "ch", name: "Chamorro" },
	{ code: "ce", name: "Chechen" },
	{ code: "ny", name: "Chichewa, Chewa, Nyanja" },
	{ code: "zh", name: "Chinese" },
	{ code: "cu", name: "Church Slavonic, Old Bulgarian, Old Church Slavonic" },
	{ code: "cv", name: "Chuvash" },
	{ code: "kw", name: "Cornish" },
	{ code: "co", name: "Corsican" },
	{ code: "cr", name: "Cree" },
	{ code: "hr", name: "Croatian" },
	{ code: "cs", name: "Czech" },
	{ code: "da", name: "Danish" },
	{ code: "dv", name: "Divehi, Dhivehi, Maldivian" },
	{ code: "nl", name: "Dutch, Flemish" },
	{ code: "dz", name: "Dzongkha" },
	{ code: "en", name: "English" },
	{ code: "eo", name: "Esperanto" },
	{ code: "et", name: "Estonian" },
	{ code: "ee", name: "Ewe" },
	{ code: "fo", name: "Faroese" },
	{ code: "fj", name: "Fijian" },
	{ code: "fi", name: "Finnish" },
	{ code: "fr", name: "French" },
	{ code: "ff", name: "Fulah" },
	{ code: "gd", name: "Gaelic, Scottish Gaelic" },
	{ code: "gl", name: "Galician" },
	{ code: "lg", name: "Ganda" },
	{ code: "ka", name: "Georgian" },
	{ code: "de", name: "German" },
	{ code: "ki", name: "Gikuyu, Kikuyu" },
	{ code: "el", name: "Greek (Modern)" },
	{ code: "kl", name: "Greenlandic, Kalaallisut" },
	{ code: "gn", name: "Guarani" },
	{ code: "gu", name: "Gujarati" },
	{ code: "ht", name: "Haitian, Haitian Creole" },
	{ code: "ha", name: "Hausa" },
	{ code: "he", name: "Hebrew" },
	{ code: "hz", name: "Herero" },
	{ code: "hi", name: "Hindi" },
	{ code: "ho", name: "Hiri Motu" },
	{ code: "hu", name: "Hungarian" },
	{ code: "is", name: "Icelandic" },
	{ code: "io", name: "Ido" },
	{ code: "ig", name: "Igbo" },
	{ code: "id", name: "Indonesian" },
	{
		code: "ia",
		name: "Interlingua (International Auxiliary Language Association)",
	},
	{ code: "ie", name: "Interlingue" },
	{ code: "iu", name: "Inuktitut" },
	{ code: "ik", name: "Inupiaq" },
	{ code: "ga", name: "Irish" },
	{ code: "it", name: "Italian" },
	{ code: "ja", name: "Japanese" },
	{ code: "jv", name: "Javanese" },
	{ code: "kn", name: "Kannada" },
	{ code: "kr", name: "Kanuri" },
	{ code: "ks", name: "Kashmiri" },
	{ code: "kk", name: "Kazakh" },
	{ code: "rw", name: "Kinyarwanda" },
	{ code: "kv", name: "Komi" },
	{ code: "kg", name: "Kongo" },
	{ code: "ko", name: "Korean" },
	{ code: "kj", name: "Kwanyama, Kuanyama" },
	{ code: "ku", name: "Kurdish" },
	{ code: "ky", name: "Kyrgyz" },
	{ code: "lo", name: "Lao" },
	{ code: "la", name: "Latin" },
	{ code: "lv", name: "Latvian" },
	{ code: "lb", name: "Letzeburgesch, Luxembourgish" },
	{ code: "li", name: "Limburgish, Limburgan, Limburger" },
	{ code: "ln", name: "Lingala" },
	{ code: "lt", name: "Lithuanian" },
	{ code: "lu", name: "Luba-Katanga" },
	{ code: "mk", name: "Macedonian" },
	{ code: "mg", name: "Malagasy" },
	{ code: "ms", name: "Malay" },
	{ code: "ml", name: "Malayalam" },
	{ code: "mt", name: "Maltese" },
	{ code: "gv", name: "Manx" },
	{ code: "mi", name: "Maori" },
	{ code: "mr", name: "Marathi" },
	{ code: "mh", name: "Marshallese" },
	{ code: "ro", name: "Moldovan, Moldavian, Romanian" },
	{ code: "mn", name: "Mongolian" },
	{ code: "na", name: "Nauru" },
	{ code: "nv", name: "Navajo, Navaho" },
	{ code: "nd", name: "Northern Ndebele" },
	{ code: "ng", name: "Ndonga" },
	{ code: "ne", name: "Nepali" },
	{ code: "se", name: "Northern Sami" },
	{ code: "no", name: "Norwegian" },
	{ code: "nb", name: "Norwegian Bokm�l" },
	{ code: "nn", name: "Norwegian Nynorsk" },
	{ code: "ii", name: "Nuosu, Sichuan Yi" },
	{ code: "oc", name: "Occitan (post 1500)" },
	{ code: "oj", name: "Ojibwa" },
	{ code: "or", name: "Oriya" },
	{ code: "om", name: "Oromo" },
	{ code: "os", name: "Ossetian, Ossetic" },
	{ code: "pi", name: "Pali" },
	{ code: "pa", name: "Panjabi, Punjabi" },
	{ code: "ps", name: "Pashto, Pushto" },
	{ code: "fa", name: "Persian" },
	{ code: "pl", name: "Polish" },
	{ code: "pt", name: "Portuguese" },
	{ code: "qu", name: "Quechua" },
	{ code: "rm", name: "Romansh" },
	{ code: "rn", name: "Rundi" },
	{ code: "ru", name: "Russian" },
	{ code: "sm", name: "Samoan" },
	{ code: "sg", name: "Sango" },
	{ code: "sa", name: "Sanskrit" },
	{ code: "sc", name: "Sardinian" },
	{ code: "sr", name: "Serbian" },
	{ code: "sn", name: "Shona" },
	{ code: "sd", name: "Sindhi" },
	{ code: "si", name: "Sinhala, Sinhalese" },
	{ code: "sk", name: "Slovak" },
	{ code: "sl", name: "Slovenian" },
	{ code: "so", name: "Somali" },
	{ code: "st", name: "Sotho, Southern" },
	{ code: "nr", name: "South Ndebele" },
	{ code: "es", name: "Spanish, Castilian" },
	{ code: "su", name: "Sundanese" },
	{ code: "sw", name: "Swahili" },
	{ code: "ss", name: "Swati" },
	{ code: "sv", name: "Swedish" },
	{ code: "tl", name: "Tagalog" },
	{ code: "ty", name: "Tahitian" },
	{ code: "tg", name: "Tajik" },
	{ code: "ta", name: "Tamil" },
	{ code: "tt", name: "Tatar" },
	{ code: "te", name: "Telugu" },
	{ code: "th", name: "Thai" },
	{ code: "bo", name: "Tibetan" },
	{ code: "ti", name: "Tigrinya" },
	{ code: "to", name: "Tonga (Tonga Islands)" },
	{ code: "ts", name: "Tsonga" },
	{ code: "tn", name: "Tswana" },
	{ code: "tr", name: "Turkish" },
	{ code: "tk", name: "Turkmen" },
	{ code: "tw", name: "Twi" },
	{ code: "ug", name: "Uighur, Uyghur" },
	{ code: "uk", name: "Ukrainian" },
	{ code: "ur", name: "Urdu" },
	{ code: "uz", name: "Uzbek" },
	{ code: "ve", name: "Venda" },
	{ code: "vi", name: "Vietnamese" },
	{ code: "vo", name: "Volap_k" },
	{ code: "wa", name: "Walloon" },
	{ code: "cy", name: "Welsh" },
	{ code: "fy", name: "Western Frisian" },
	{ code: "wo", name: "Wolof" },
	{ code: "xh", name: "Xhosa" },
	{ code: "yi", name: "Yiddish" },
	{ code: "yo", name: "Yoruba" },
	{ code: "za", name: "Zhuang, Chuang" },
	{ code: "zu", name: "Zulu" },
];

export const projectModuleCommonStyle = {
  certificateCard: {
    border: "1px solid #9F9F9F",
    borderRadius: "8px",
    background: "#FFF",
    boxShadow: "2px 2px 24px 0px rgba(0, 0, 0, 0.16)",
    height: "162px",
    width: "268px",
    display: "flex",
    flexDirection: "column",
  },
  imageWithBackground: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    flexGrow: 1,
    borderRadius: "7px 7px 0 0",
    margin: "6px 6px 0 6px",
    cursor: "pointer",
  },
  certificateCardLabel: {
    borderRadius: "0px 0px 7px 7px",
    background: "#F3F3F3",
    display: "flex",
    padding: "12px",
    height: "20px",
  },
  uploadedImageStyle: {
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
  },
  galleryContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "25px",
  },
  imageUpload: {
    display: "inline-flex",
    padding: "14px 24px",
    borderRadius: "8px",
    border: "2px dashed #0094A7",
    background: "#FFF",
    color: "#0094A7",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    height: "45px",
    width: "120px",
  },
  imageDocumentInfo: {
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#9F9F9F",
    marginTop: "8px",
  },
  loadingSpinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "calc(50vh)",
    color: "black",
  },
  mainWrapper: {
    padding: "32px",
  },
  grid1: {
    height: "calc(100vh - 65px)",
    backgroundColor: "#FFF",
	"@media (max-width: 960px)": {
		height: "100vh",
	},
  },
  box1: {
    display: "flex",
    flexDirection: "column",
    padding: "12px 22px",
    backgroundColor: "#f3f3f3",
    gap: "60px",
	maxHeight:'100%'
  },
  projectModuleContainer: {
    minHeight: "calc(100vh - 300px)",
  },
  rightSideOfContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "100%",
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "100%",
	marginTop: "18px"
  },
  gridContainer: {
    marginBottom: '8px'
  },
  gridContainer1: {
    marginTop: '24px',
    marginBottom: '24px'
  },
  viewComponent: {
    marginBottom: "6px",
  },
  viewComponent1: {
    marginBottom: "12px",
  },
  containerWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  changeEditMode: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "17px",
    top: 100,
  },
  changeEditAwardLetterMode: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "sticky",
    top: 100,
  },
  requestButton: {
    position: "sticky",
    alignSelf: "flex-end",
    bottom: 10,
    margin: "10px",
    width: '150px',
	"@media (max-width: 600px)": {
		position: "absolute",
		bottom: 0,
	  }
  },
  requestAwardLetterButton: {
    position: "sticky",
    alignSelf: "flex-end",
    bottom: 10,
    width: '150px'
  },
  requestButtonTender: {
	display: 'flex',
	justifyContent: "flex-end",
    position: "sticky",
    alignSelf: "flex-end",
    bottom: 10,
    margin: "10px",
    width: '100%'
  },
  sideBar: {
    marginTop: "100px",
  },
  checkBoxLabelStyle: {
    color: "#000",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
  },
  disabledViewContent: {
    color: "#2B3132",
    opacity: 0.4,
  },
  jointVentureInput: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "6px",
  },
  formDateInput: {
    cursor: "pointer",
    padding: "12px",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#2B3132",
    lineHeight: "16px",
    borderRadius: "8px",
  },
  formInputRadioGroup: {
    gap: "24px",
  },
  formActionButtonStyles: {
    display: "flex",
    justifyContent: "end",
    gap: "20px",
    margin: "25px 0",
  },
  isLabelStyle: {
    marginBottom: "7px",
  },
  addMoreButton: {
    width: "115px",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    color: "#0094A7",
    marginTop: "25px",
  },
  commonText: {
    fontFamily: "Source Sans Pro",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
  },
  headerText: {
    fontSize: '20px'
  },
  headerButtonText: {
    color: "#FFF",
    border: "1px solid #FFF",
    padding: "10px",
    width: "156px",
    height: "45px",
    fontSize: "16px",
  }
};

export const CustomTextField = withStyles((theme: any) => ({
  root: {
    "& .MuiInputBase-input": {
      padding: "12px",
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#2B3132",
      lineHeight: "16px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
	  "& input:disabled": {
		background: "#f6f6f6"
	  },
      "& fieldset": {
        border: "1px solid #9F9F9F",
      },
      "&:hover fieldset": {
        border: "1px solid #9F9F9F",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #0094A7",
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #DC2626 !important",
      },
      "&.error": {
        border: "1px solid #DC2626",
        borderRadius: "8px",
      },
    },
    "& .MuiFormHelperText-root": {
      fontFamily: "Source Sans Pro",
      margin: "0 0 0 5px",
      textAlign: "left",
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: "0.03em",
      fontSize: "0.75rem",
      color: "#f44336",
    },
  },
}))(TextField);

export const NumericTextField = withStyles((theme: any) => ({
	root: {
	  "& .MuiInputBase-input": {
		padding: "12px",
		paddingLeft: '5px',
		fontFamily: "Source Sans Pro",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: 400,
		color: "#2B3132",
		lineHeight: "16px",
	  },
	  "& .MuiOutlinedInput-root": {
		borderRadius: "8px",
		paddingLeft: '7px',
		"& fieldset": {
		  border: "1px solid #9F9F9F",
		},
		"&:hover fieldset": {
		  border: "1px solid #9F9F9F",
		},
		"&.Mui-focused fieldset": {
		  border: "1px solid #0094A7",
		},
		"& .error": {
		  border: "1px solid #DC2626",
		},
	  },
	  "& .MuiFormHelperText-root": {
		fontFamily: "Source Sans Pro",
		margin: "0 0 0 5px",
		textAlign: "left",
		fontWeight: 400,
		lineHeight: 1.66,
		letterSpacing: "0.03em",
		fontSize: "0.75rem",
		color: "#f44336",
	  },
	},
  }))(TextField);

export const DisabledTextField = withStyles((theme: any) => ({
	root: {
		"& .MuiInputBase-input": {
		  padding: "12px",
		  fontFamily: "Source Sans Pro",
		  fontSize: "16px",
		  fontStyle: "normal",
		  fontWeight: 400,
		  color: "#2B3132",
		  lineHeight: "16px",
		  backgroundColor: "#f1f4f9",
		  borderRadius: "8px",
		},
		"& .MuiOutlinedInput-root": {
		  borderRadius: "8px",
		  backgroundColor: "#f1f4f9",
		  border: "none",
		  "& fieldset": {
			border: "none",
		  },
		},
		"& .MuiInputBase-root.Mui-disabled": {
			color: "black"
		},
		"& .MuiFormHelperText-root": {
		  fontFamily: "Source Sans Pro",
		  margin: "0 0 0 5px",
		  textAlign: "left",
		  fontWeight: 400,
		  lineHeight: 1.66,
		  letterSpacing: "0.03em",
		  fontSize: "0.75rem",
		  color: "#f44336",
		},
	  },	  
  }))(TextField);

export const ScrollDiv = styled(Box)({
	"&::-webkit-scrollbar-track": {
	  backgroundColor: "#E8E8E8",
	},
	padding: "32px 32px 40px", 
	backgroundColor: '#fff', 
	height: "58vh", 
	overflowY: "scroll",
	"&::-webkit-scrollbar": {
	  backgroundColor: "#E8E8E8",
	  width: "7px",
	  borderRadius: "14px",
	  height: "7px",
	},
	"@media (max-width: 960px)": {
		height: "calc(100vh - 205px)",
	},
	"@media (max-height: 720px)": {
		height: "50vh",
	},
	"@media (max-height: 620px)": {
		height: "42vh",
	},
	"&::-webkit-scrollbar-thumb": {
		backgroundColor: "#0094A7",
		borderRadius: "14px",
	},
})

export const MainDiv = styled(Box)({
	backgroundColor: "#F3F3F3",
    height: "calc(100vh - 65px)",
	"@media (max-width: 960px)": {
		height: "100vh",
	},
})
  

export const availableDiplomas = [
	{ label: 'High School Diploma', value: 'High School Diploma' },
	{ label: 'GED (General Educational Development)', value: 'GED (General Educational Development)' },
	{ label: "Associate's Degree", value: "Associate's Degree" },
	{ label: "Bachelor's Degree", value: "Bachelor's Degree" },
	{ label: "Master's Degree", value: "Master's Degree" },
	{ label: 'Doctorate (Ph.D.)', value: 'Doctorate (Ph.D.)' },
	{ label: 'Postgraduate Diploma', value: 'Postgraduate Diploma' },
	{ label: 'Advanced Diploma', value: 'Advanced Diploma' },
	{ label: 'Diploma in Business Administration', value: 'Diploma in Business Administration' },
	{ label: 'Diploma in Information Technology', value: 'Diploma in Information Technology' },
	{ label: 'Diploma in Nursing', value: 'Diploma in Nursing' },
	{ label: 'Diploma in Accounting', value: 'Diploma in Accounting' },
	{ label: 'Diploma in Marketing', value: 'Diploma in Marketing' },
	{ label: 'Diploma in Graphic Design', value: 'Diploma in Graphic Design' },
	{ label: 'Diploma in Culinary Arts', value: 'Diploma in Culinary Arts' },
	{ label: 'Diploma in Digital Marketing', value: 'Diploma in Digital Marketing' },
	{ label: 'Diploma in Human Resources', value: 'Diploma in Human Resources' },
	{ label: 'Diploma in Project Management', value: 'Diploma in Project Management' },
	{ label: 'Diploma in Early Childhood Education', value: 'Diploma in Early Childhood Education' },
	{ label: 'Others', value: 'Others' }
]
   
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { MappingFilterNameForPayload } from "../../../components/src/MappingTableHeaderLabels";
import {
  convertResIntoLabelValueCheck,
  determineSelectedGroupRelation,
  listFirstSelectedSaveSearch,
  parseFilterListObject,
  parseSaveSearchResponse,
  setCheckedToFalseDeep,
  setCheckedToTrueDeep,
} from "../../../components/src/Utils";
import { accordionDummyList } from "../../../components/src/WebAppLayout/config";
import { ListSaveSearchType, StatusEndPoints, StatusFieldKeys } from "./assets";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation:any;
  classes: any;
  open: boolean;
  toggleOpen: () => void;
  handleFilterList: (value: any, flag?:boolean) => void;
  module: string;
  isUpdateSaveSearch?:boolean;
  preFilter?:any;
  isResetFilters?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accordionList: any[];
  selectedTabIndex: number;
  filterList: { [key: string]: string[] };
  inputSearchFields: any;
  selectedGroupRelation: string;
  selectedSaveSearchId: any;
  isEditSaveArrowOpen: boolean;
  editSaveSearchId: string | number;
  openAll: boolean;
  isEditSuccess: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class AllFilterController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getLevelEducationApiCallId: string = "";
  getDisciplineEducationApiCallId: string = "";
  getExperienceApiCallId: string = "";
  getCurrentPositionApiCallId: string = "";
  getNationalityApiCallId: string = "";
  getLanguagesApiCallId: string = "";
  getSaveSearchApiCallId: string = "";

  getGroupCompanyApiCallId: string = "";
  getClientsApiCallId: string = "";
  getCountryApiCallId: string = "";
  getServicesApiCallId: string = "";
  getSectorsApiCallId: string = "";
  getStatusApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      accordionList: [],
      filterList: {},
      inputSearchFields: {},
      selectedGroupRelation: "",
      selectedTabIndex: 1,
      selectedSaveSearchId: -1,
      isEditSaveArrowOpen: false,
      editSaveSearchId: -1,
      isEditSuccess: false,
      openAll: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    const module = this.props.module.toLowerCase();
    const storedFilters = localStorage.getItem(module);
    if (storedFilters && JSON.parse(storedFilters) ) {
        const { accordionList, filterList, selectedSaveSearchId} = JSON.parse(storedFilters);
        this.setState({ accordionList, filterList, selectedSaveSearchId });
    } else {
      this.setState(
        {
          accordionList: JSON.parse(JSON.stringify(accordionDummyList[module])),
        },
        () => this.handleAPICollection(module)
      );
    }
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    // Customizable Area End
  }

  showFilterOption = (menuList: any) => {
    const { preFilter } = this.props;
    if(menuList && preFilter){
      const newFilters = parseFilterListObject(preFilter);
      const updatedProject = setCheckedToTrueDeep(menuList, newFilters)        
      this.setState({ accordionList: menuList});
      this.setLocalStorage(updatedProject, newFilters, this.state.selectedSaveSearchId)
    }
}

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleAllApiResponses(apiRequestCallId, responseJson);
    }

    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevProps.isUpdateSaveSearch !== this.props.isUpdateSaveSearch){
      this.handleSaveSearchAPI()
    }
    if(prevProps.isResetFilters !== this.props.isResetFilters){
      if(this.props.isResetFilters){
        this.clearAllFilters()
      }
    }
  }

  async componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  handleBeforeUnload = () => {
    localStorage.removeItem(this.props.module.toLowerCase());
  }

  setLocalStorage = (accordian: any[], filterList:any, id: any) =>{
    localStorage.setItem(this.props.module.toLowerCase(), JSON.stringify({ accordionList: accordian, filterList: filterList, selectedSaveSearchId: id }));
  }

  handleAPICollection = (label: string) => {
    switch (label.toLowerCase()) {
      case "people":
        this.getSaveSearchApiCallId = this.filterGetApiCall(
          `${configJSON.getSavedProjectSearchEndPoint}?type=BxBlockPeople::PeopleSavedSearch`
        );
        this.getLevelEducationApiCallId = this.filterGetApiCall(
          configJSON.getLevelEducationEndPoint
        );
        this.getDisciplineEducationApiCallId = this.filterGetApiCall(
          configJSON.getDisciplineEducatioEndpoint
        );
        this.getExperienceApiCallId = this.filterGetApiCall(
          configJSON.getExperienceEndPoint
        );
        this.getNationalityApiCallId = this.filterGetApiCall(
          configJSON.getNationalityApiCallId
        )
        this.getCurrentPositionApiCallId = this.filterGetApiCall(
          configJSON.getCurrentPositionEndPoint
        );
        this.getLanguagesApiCallId = this.filterGetApiCall(
          configJSON.getLanguagesEndPoint
        );
        break;
      case "projects":
        this.getSaveSearchApiCallId = this.filterGetApiCall(
          `${configJSON.getSavedProjectSearchEndPoint}?type=BxBlockProfile::ProjectSavedSearch`
        );
        this.handleProjectTenderFilterAPI();
        break;
      case "tenders":
        this.getSaveSearchApiCallId = this.filterGetApiCall(
          `${configJSON.getSavedProjectSearchEndPoint}?type=BxBlockProfile::TenderSavedSearch`
        );
        this.handleProjectTenderFilterAPI();
        break;
      case "suppliers":
        this.getSaveSearchApiCallId = this.filterGetApiCall(
          `${configJSON.getSavedProjectSearchEndPoint}?type=BxBlockSavesearch::SupplierSavedSearch`
        );
        this.handleProjectTenderFilterAPI();
        break;
      case "partners":
        this.getSaveSearchApiCallId = this.filterGetApiCall(
          `${configJSON.getSavedProjectSearchEndPoint}?type=BxBlockSavesearch::PartnerSavedSearch`
        );
        this.handleProjectTenderFilterAPI();
        break;
      default:
        break;
    }
  };

  handleProjectTenderFilterAPI = () => {
    this.getGroupCompanyApiCallId = this.filterGetApiCall(
      configJSON.getGroupCompanyEndPoint
    );
    this.getClientsApiCallId = this.filterGetApiCall(
      configJSON.getClientsEndPoint
    );
    this.getCountryApiCallId = this.filterGetApiCall(
      configJSON.getCountryEndPoint
    );
    this.getServicesApiCallId = this.filterGetApiCall(
      configJSON.getServicesEndPoint
    );
    this.getSectorsApiCallId = this.filterGetApiCall(
      configJSON.getSectorsEndPoint
    );
    this.getStatusApiCallId = this.filterGetApiCall(
      StatusEndPoints[this.props.module.toLowerCase() as keyof typeof StatusEndPoints]
    );
  }

  handleSaveSearchAPI = () => {
    const title = this.props.module.toLowerCase()
    const type = ListSaveSearchType[title as keyof typeof ListSaveSearchType]
    this.getSaveSearchApiCallId = this.filterGetApiCall(
      `${configJSON.getSavedProjectSearchEndPoint}?type=${type}`
    );
  }

  handleAllApiResponses = (apiRequestCallId: string, responseJson: any) => {
    const successCallbackMap = {
      [this.getLevelEducationApiCallId]: this.getLevelEducationResponse,
      [this.getDisciplineEducationApiCallId]: this
        .getDisciplineEducationResponse,
      [this.getExperienceApiCallId]: this.getExperienceResponse,
      [this.getCurrentPositionApiCallId]: this.getCurrentPositionResponse,
      [this.getLanguagesApiCallId]: this.getLanguagesResponse,
      [this.getGroupCompanyApiCallId]: this.getGroupCompanyResponse,
      [this.getClientsApiCallId]: this.getClientResponse,
      [this.getCountryApiCallId]: this.getCountryResponse,
      [this.getServicesApiCallId]: this.getServicesResponse,
      [this.getSectorsApiCallId]: this.getSectorsResponse,
      [this.getStatusApiCallId]: this.getStatusResponse,
      [this.getSaveSearchApiCallId] : this.getSaveSearchResponse,
      [this.getNationalityApiCallId]: this.getNationalityResponse,
    };

    if (apiRequestCallId) {
      const successCallback: (responseJson: any) => void =
        successCallbackMap[apiRequestCallId];
      !!successCallback && successCallback(responseJson);
    }
  };

  getSaveSearchResponse = (saveRes:any) => {
    if (this.handleGetResponsesError(saveRes)) {
      return;
    }
    const {accordionList} = this.state

    const list = parseSaveSearchResponse(saveRes.data, this.state.selectedSaveSearchId) 
    const resSave = accordionList.map((item:any) => {
      if (item.id === 0) {
        return {
          ...item,
          savedSearchList: list
        };
      }
      return item;
    });
    this.setState({
      accordionList: resSave
    })
  }

  getLevelEducationResponse = (levelRes: any) => {
    if (this.handleGetResponsesError(levelRes)) {
      return;
    }
    const resLevel = convertResIntoLabelValueCheck(
      this.state.accordionList,
      1,
      "education_degree",
      levelRes
    );
    this.setState({
      accordionList: resLevel,
    });
  };

  getDisciplineEducationResponse = (disRes: any) => {
    if (this.handleGetResponsesError(disRes)) {
      return;
    }
    const resDisc = convertResIntoLabelValueCheck(
      this.state.accordionList,
      1,
      "education_discipline",
      disRes,
      "stream_name"
    );
    this.setState({
      accordionList: resDisc,
    });
  };

  getExperienceResponse = (expRes: any) => {
    if (this.handleGetResponsesError(expRes)) {
      return;
    }
    const resPri = convertResIntoLabelValueCheck(
      this.state.accordionList,
      2,
      "primary",
      expRes,
      "sector_name"
    );
    const resExo = convertResIntoLabelValueCheck(
      resPri,
      2,
      "secondary",
      expRes,
      "sector_name"
    );
    this.setState({
      accordionList: resExo,
    });
  };

  getCurrentPositionResponse = (cpRes: any) => {
    if (this.handleGetResponsesError(cpRes)) {
      return;
    }
    const resCP = convertResIntoLabelValueCheck(
      this.state.accordionList,
      3,
      "current_position",
      cpRes,
      "position_name"
    );
    this.setState({
      accordionList: resCP,
    });
  };

  getNationalityResponse = (natRes: any) => {
    if (this.handleGetResponsesError(natRes)) {
      this.showFilterOption(this.state.accordionList)
      return;
    }
    const convertData = natRes.map((o: string)=>{
      return {name: o}
    })
    const resNat = convertResIntoLabelValueCheck(
      this.state.accordionList,
      4,
      "nationality",
      convertData
    );
    this.setState({
      accordionList: resNat,
    },() =>  this.getLanguagesApiCallId = this.filterGetApiCall(
      configJSON.getLanguagesEndPoint
    ));
  }

  getLanguagesResponse = (lanRes: any) => {
    if (this.handleGetResponsesError(lanRes)) {
      return;
    }
    const resLang = convertResIntoLabelValueCheck(
      this.state.accordionList,
      5,
      "language",
      lanRes
    );
    this.setState({
      accordionList: resLang,
    },()=>{this.showFilterOption(resLang)});
  };

  getGroupCompanyResponse = (grpRes: any) => {
    if (this.handleGetResponsesError(grpRes)) {
      return;
    }
    const resGrp = convertResIntoLabelValueCheck(
      this.state.accordionList,
      1,
      "group_company",
      grpRes
    );
    this.setState({
      accordionList: resGrp
    });
  };

  getClientResponse = (cliRes: any) => {
    if (this.handleGetResponsesError(cliRes)) {
      return;
    }
    const resClient = convertResIntoLabelValueCheck(
      this.state.accordionList,
      2,
      "client",
      cliRes
    );
    this.setState({
      accordionList: resClient
    });
  };

  getCountryResponse = (cntRes: any) => {
    if (this.handleGetResponsesError(cntRes)) {
      return;
    }
    const resCnt = convertResIntoLabelValueCheck(
      this.state.accordionList,
      3,
      "country",
      cntRes
    );
    this.setState({
      accordionList: resCnt
    });
  };

  getServicesResponse = (srvRes: any) => {
    if (this.handleGetResponsesError(srvRes)) {
      return;
    }
    const resSrv = convertResIntoLabelValueCheck(
      this.state.accordionList,
      4,
      "services",
      srvRes
    );
    this.setState({
      accordionList: resSrv
    });
  };

  getSectorsResponse = (sectorRes: any) => {
    if (this.handleGetResponsesError(sectorRes)) {
      return;
    }
    const resSector = convertResIntoLabelValueCheck(
      this.state.accordionList,
      5,
      "sector",
      sectorRes
    );
    this.setState({
      accordionList: resSector
    });
  };

  getStatusResponse = (statusRes: any) => {
    if (this.handleGetResponsesError(statusRes)) {
      return;
    }
    const status = StatusFieldKeys[this.props.module.toLowerCase() as keyof typeof StatusFieldKeys]
    const resStatus = convertResIntoLabelValueCheck(
      this.state.accordionList,
      7,
      status,
      statusRes,
      "status"
    );
    this.setState({
      accordionList: resStatus
    },()=>{this.showFilterOption(resStatus)});
  };

  handleGetResponsesError = (errRes: any) => {
    if (errRes.errors && errRes.errors.length > 0) {
      let errorMsg = "";
      errRes.errors.forEach((err: any) => {
        errorMsg += Object.values(err)[0] + "\n";
      });
      return true;
    } else if (errRes.error) {
      return true;
    } else {
      return false;
    }
  };

  handleRadioChange(e: any, val: string) {
    const key = val === "unrelated" ? "related" : "unrelated";
    const filters =  Object.fromEntries(
      Object.entries(this.state.filterList).filter(([key]) => !key.includes(val === "unrelated" ? "related[" : "unrelated"))
    );
    const list = this.state.accordionList.map((item) => {          
      if (item?.filterOptionForRadio && item?.radioOption) {
        const filRadioOptions = item.filterOptionForRadio?.[key];
        const updatedFilRadioOptions = setCheckedToFalseDeep(filRadioOptions);
        const radioOptions = setCheckedToFalseDeep(item.radioOption);
        item.radioOption = radioOptions
        item.filterOptionForRadio[key] = updatedFilRadioOptions;
      }
      return item;
    });
    this.setState({
      accordionList: list,
      filterList: filters,
      selectedGroupRelation: val,
      selectedSaveSearchId: -1,
    });
    this.props.handleFilterList(filters)
  }

  toggleAcordion(i: number) {
    this.setState((prevState: S) => {
      prevState.accordionList[i].show = !prevState.accordionList[i].show;
      return { accordionList: [...prevState.accordionList] };
    });
    if(this.props.open === false){
      this.props.toggleOpen()
    }
  }

  allToggleOpen = () => {
    this.props.toggleOpen();
    this.setState({
      accordionList: this.state.accordionList.map((acc: any) => {
        return { ...acc, show: false };
      }),
    });
  };

  toggleInnerRadioAcordion(
    i: number,
    selectedGrpRelation: string,
    subItem: string
  ) {
    this.setState((prevState: S) => {
      prevState.accordionList[i].filterOptionForRadio[selectedGrpRelation][
        subItem
      ].show = !prevState.accordionList[i].filterOptionForRadio[
        selectedGrpRelation
      ][subItem].show;
      return { accordionList: [...prevState.accordionList] };
    });
  }

  setSearchInput(value: string, item: any) {
    let obj = { ...this.state.inputSearchFields };
    obj[item.text] = value;
    this.setState({ inputSearchFields: obj });
  }

  handleAllFilterChange(changedList: { [key: string]: string[] }) {
    this.setState((prevState: S) => {
      const list = { ...prevState.filterList, ...changedList };
      this.setLocalStorage(prevState.accordionList, list, -1)
      this.props.handleFilterList(list);
      return { filterList: list, selectedSaveSearchId: -1};
    });
  }

  handleCheckBoxListChange(
    checkedItems: string[],
    subitem: any,
    parentItem: any,
    selectedGroup?: string
  ) {
    const createFilterChangeObject = (key: string, value: string[]) => ({
      [key]: value,
    });

    const filterNameForPayload = MappingFilterNameForPayload;
    let filterChangeObject: { [key: string]: string[] } = {};

    if (selectedGroup) {
      const key = `${selectedGroup}${filterNameForPayload(subitem)}`;
      filterChangeObject = createFilterChangeObject(key, checkedItems);
    } else {
      const key =
        subitem === "" ? parentItem.link : subitem;
      filterChangeObject = createFilterChangeObject(key, checkedItems);
    }

    this.handleAllFilterChange(filterChangeObject);
  }

  onResetButtonClick = (filter: string, item: any, index: number): void => {
    const {
      inputSearchFields,
      selectedGroupRelation,
      accordionList,
    } = this.state;
    const updatedInputSearchFields = {
      ...inputSearchFields,
      [filter]: "",
    };

    this.setState({
      inputSearchFields: updatedInputSearchFields,
    });

    const resetCheckBoxes = (options: Record<string, any>, group?: string) => {
      Object.keys(options).forEach((option) => {
        this.handleCheckBoxListChange([], option, item, group);
      });
    };

    if (item.filterOptionForRadio) {
      resetCheckBoxes(
        item.filterOptionForRadio?.[selectedGroupRelation] || {},
        selectedGroupRelation
      );
      this.setState({ selectedGroupRelation: "" });
    } else {
      resetCheckBoxes(item.filterOption || {});
    }
    const updatedList = accordionList.map((item, i) =>
      i === index ? setCheckedToFalseDeep(item) : { ...item }
    );
    this.setState({
      accordionList: updatedList,
    });
  };

  handleClickIconTool = () => {
    const { open } = this.props;
     
    if (!open) {
      this.props.toggleOpen()
    }
  }

  handleSelectSearch = (selectedSaveSearch: any, isListUpdate?: boolean) => {
    const { id, filters } = selectedSaveSearch;
    const { accordionList } = this.state;
    const { handleFilterList } = this.props;

    const SELECTED_SEARCH_ID_NONE = -1;
    const EMPTY_CHECKED_LIST = setCheckedToFalseDeep(accordionList);

    const relation = determineSelectedGroupRelation(filters);
    this.setState({ selectedGroupRelation: relation });

    if(id === this.state.selectedSaveSearchId || id === SELECTED_SEARCH_ID_NONE) {
      this.setState({ selectedSaveSearchId: SELECTED_SEARCH_ID_NONE, accordionList: EMPTY_CHECKED_LIST, filterList: {} }, () =>{
        this.setLocalStorage(EMPTY_CHECKED_LIST, {}, SELECTED_SEARCH_ID_NONE)
        handleFilterList({})
      }
      );
    } else {
      const parsedFilter = parseFilterListObject(filters);
      const newList = !!isListUpdate
        ? EMPTY_CHECKED_LIST.map((item: any) =>
            item.savedSearchList ? { ...item, savedSearchList: listFirstSelectedSaveSearch(item.savedSearchList, id) } : item
          )
        : EMPTY_CHECKED_LIST;
      const list = setCheckedToTrueDeep(newList, parsedFilter)
      this.setState({ selectedSaveSearchId: id, accordionList: list, filterList: parsedFilter, isEditSuccess: false });
      this.setLocalStorage(list, parsedFilter, id)
      handleFilterList(parsedFilter, true);
    }
  };

  handleSelectSearchArrow = (val:boolean, obj?:any) => {
    this.setState({ isEditSaveArrowOpen: val, isEditSuccess: false})
    if(obj?.id) {
      this.setState({ editSaveSearchId: this.state.editSaveSearchId === obj.id ? -1 : obj.id })
    } else {
      this.setState({ editSaveSearchId: -1 })
    }
  }

  applyEditSaveSearch = () => {
    this.handleSaveSearchAPI();
    this.setState({isEditSuccess: true});    
    this.handleSeeAllSaveSearch(true);
  }

  handleSeeAllSaveSearch = (value:boolean) => {
    this.setState({ openAll : value})
  }

  clearAllFilters = () => {
    const updatedList = setCheckedToFalseDeep(this.state.accordionList);
    const newList = setCheckedToFalseDeep(updatedList, {selectedKey: "show", selectedValue: false});
    this.setState({
      accordionList: newList,
      filterList: {},
      selectedSaveSearchId: -1,
      selectedGroupRelation: "",
    });
    this.handleBeforeUnload();
    this.props.handleFilterList({});
  };

  filterGetApiCall = (url: string) => {
    const filterHeader = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(filterHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End
}
import React from "react";
import { Box, Grid, Snackbar,Hidden } from "@material-ui/core";
import TenderModuleController, { Props, configJSON } from "./TenderModuleController.web";
import Audit from '../../audittrail2/src/Audittrail.web';
import CustomList from "../../../components/src/CustomList.web";
import Navbar from "../../../components/src/NavBar.web";
import Loading from "../../../components/src/Loading/Loading";
import TenderModuleHeader from "./Components/TenderModule/TenderModuleHeader.web"
import TenderDetails from "./Components/TenderModule/TenderDetails.web"
import AwardLetter from "./Components/TenderModule/AwardLetter.web"
import CustomButton from "../../../components/src/CustomButton.web";
import { MainDiv, ScrollDiv, projectModuleCommonStyle } from "./assets";
import ResponsiveTopBar from "../../../components/src/TopBar/ResponsiveTopBar.web";
import Connection from "../../DeepLinking/src/Connection.web";
import { bindingCloseEvent } from "../../../components/src/Utils";


export default class TenderModule extends TenderModuleController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const webStyle = projectModuleCommonStyle;
        const {
            groupResponse,
            countryresponse,
            clientResponse,
            servicesResponse,
            sectorsResponse,
            ValueOfWorksResponse,
            statusResponse,
            tenderTypeResponse,
            typeCurrencyResponse,
            AwardLetters,
            onAwardLetterUpload,
            awardLettersToUpload,
            mode,
            isCancelAwardEnabled
        } = this.state;

        return (
            <MainDiv>
            <Grid
                container
                style={{
                    backgroundColor: "#f3f3f3",
                    height: "100%",
                    overflow: "hidden",
                }}
            >
            <Hidden smDown>
                <Grid item xs={12} sm={2}
                    style={{
                        background: "white",
                    }}
                >
                    <Box
                        sx={{
                            marginTop: "80px",
                            width: "100%",
                        }}
                        
                    >
                        <CustomList
                            activeSection={this.state.activeSection}
                            // @ts-ignore
                            sections={configJSON.TenderModuleSections}
                            data-testId="list-menu"
                            handleListItemClick={this.handleListItemClick}
                            disabled={this.state.tenderId === ''}
                        />
                    </Box>
                </Grid>
            </Hidden>
                <Hidden mdUp>
                    <Grid xs={12}>
                        <ResponsiveTopBar navigation={this.props.navigation} title={"Tenders"}>
                            {  ( { closeDrawer } ) => 
                                <CustomList
                                    activeSection={this.state.activeSection}
                                    // @ts-ignore
                                    sections={this.sections}
                                    data-testId="list-menu"
                                    handleListItemClick={bindingCloseEvent(this.handleListItemClick, closeDrawer)}
                                    disabled={this.state.tenderId === ''}
                                />
                            }
                        </ResponsiveTopBar>
                    </Grid>
                </Hidden>
                <Grid item xs={12} sm={12} md={10}  style={{backgroundColor: "#f3f3f3", height: "100%"}} >
                    <Box
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "12px 15px 0px",
                            backgroundColor: "#f3f3f3",
                            gap: '60px',
                            height: "100%",
                        }}
                    >
                        <Hidden smDown>
                            <Navbar navigation={this.props.navigation} selectedTab={3} />
                        </Hidden>
                        {this.state.isLoading ? <Loading height="70vh" background="white" /> : <Box style={{ position: "relative", backgroundColor: "#FFF", flex: 1 }}>
                            <TenderModuleHeader navigation={this.props.navigation} saveAsPDF={this.state.tenderId !== ""} handleGoBack={this.handleGoBack} tenderId={this.state.tenderId}/>
                            <ScrollDiv style={{ padding: "10px 32px 38px 32px" }}>
                                {this.state.activeSection === configJSON.TenderModuleSections[0] && <Box id="Tender Details">
                                    <TenderDetails
                                        tenderDetails={this.state.tenderDetails}
                                        TenderErrors={this.state.TenderError}
                                        handlePosTenderDetails={this.handlePosTenderDetails}
                                        handleUpdateTenderDetails={this.handleUpdateTenderDetails}
                                        handleSwitchMode={this.handleSwitchMode}
                                        handleChangeValue={(val: string, key: string) =>
                                            this.handleChangeTenderData(val, key)
                                        }
                                        groupResponse={groupResponse}
                                        typeCurrencyResponse={typeCurrencyResponse}
                                        countryresponse={countryresponse}
                                        clientResponse={clientResponse}
                                        servicesResponse={servicesResponse}
                                        sectorsResponse={sectorsResponse}
                                        ValueOfWorksResponse={ValueOfWorksResponse}
                                        statusResponse={statusResponse}
                                        tenderTypeResponse={tenderTypeResponse}
                                        onChange={this.handleChange}
                                        projectRequestNavigation={this.handleTenderRequestNavigation}
                                        data-testId= "tenderRequestTestID"
                                    />
                                </Box>}
                                {(this.state.activeSection === configJSON.TenderModuleSections[1]) && (
                                    <Box id="AwardLetter">
                                        <Grid item xs={12}>
                                            <AwardLetter
                                                mode={this.state.mode}
                                                testId={"AwardLetter"}
                                                AwardLetters={AwardLetters}
                                                onChange={this.handleChangeAward}
                                                onDeleteAwardLetter={this.handleDeleteAwardLetter}
                                                onAwardLetterUpload={onAwardLetterUpload}
                                                onFileChange={this.handleFileChange}
                                                openImage={this.openImage}
                                                awardLettersToUpload={awardLettersToUpload}
                                                handleEditButtonClick={this.handleEditButtonClick}
                                                hasError={this.state.documentError}
                                                removeStagedFile={this.removeStagedFile}
                                                projectRequestNavigation={this.handleTenderRequestNavigation}
                                            />
                                            <Box
                                                style={{
                                                    ...webStyle.formActionButtonStyles,
                                                    display:
                                                        mode === configJSON.viewRecordMode
                                                            ? "none"
                                                            : "flex",
                                                }}
                                            >
                                                {isCancelAwardEnabled && <CustomButton
                                                    data-test-id={configJSON.cancelButtonTestId}
                                                    colorVariant='special'
                                                    disabled = {this.state.isPendingApi}
                                                    onClick={this.handleCancelButton}
                                                >
                                                    Cancel
                                                </CustomButton>}
                                                <CustomButton
                                                    disabled = {this.state.isPendingApi}
                                                    data-test-id={configJSON.tenderModuleSaveButtonTestId}
                                                    onClick={this.handleSaveButton}>
                                                    Save
                                                </CustomButton>
                                            </Box>
                                        </Grid>
                                        
                                    </Box>
                                )}
                                {(this.state.activeSection === configJSON.TenderModuleSections[2]) && (
                                    <Box id="Connection">
                                        <Connection
                                            id={this.state.tenderId}
                                            source="tender"
                                            targets={["supplier", "partner", "project", "people"]}
                                            tabLabels={["Suppliers", "Partners", "Projects", "People"]}
                                            onSuccessSave={this.handleCBSubmitDocument}
                                            navigation={this.props.navigation}
                                            editMode={this.countableSection.get(this.state.activeSection) == 0 && mode == 'post'}
                                            testid="connection"
                                            schema={{ 
                                                
                                                partner: [
                                                {
                                                    label: 'Name',
                                                    dataKey: 'company_name',
                                                    type: 'link',
                                                    mapValue: (rowData) => (
                                                    <a
                                                        href={`/Partner/${rowData.partner_id}`}
                                                        style={{
                                                            fontFamily: "Source Sans Pro",
                                                            textDecoration: "underline",
                                                            fontWeight: 400,
                                                            color: "#0094A7",
                                                            display: 'inline-block',
                                                            width: '200px',
                                                            lineHeight: "16px",
                                                            whiteSpace: 'nowrap',
                                                            fontSize: "16px",
                                                            fontStyle: "normal",
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                        }}
                                                        target="_blank"
                                                        >
                                                        {rowData.name}
                                                        </a>
                                                    )
                                                },
                                                {
                                                    label: 'Sector',
                                                    dataKey: 'sector',
                                                },
                                                {
                                                    label: 'Country',
                                                    dataKey: 'country',
                                                },
                                                {
                                                    label: 'Services',
                                                    dataKey: 'services',
                                                },
                                                
                                                {
                                                    label: 'Internal Rate',
                                                    dataKey: 'internal_rate',
                                                }
                                                ],
                                                supplier: [
                                                    {
                                                        type: 'link',
                                                        dataKey: 'company_name',
                                                        label: 'Name',
                                                        mapValue: (rowData) => (
                                                        <a
                                                        target="_blank"
                                                        href={`/Supplier/${rowData.supplier_id}`}
                                                        style={{
                                                            color: "#0094A7",
                                                            fontFamily: "Source Sans Pro",
                                                            fontWeight: 400,
                                                            textDecoration: "underline",
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            display: 'inline-block',
                                                            textOverflow: 'ellipsis',
                                                            fontStyle: "normal",
                                                            lineHeight: "16px",
                                                            width: '200px',
                                                            fontSize: "16px",
                                                        }}
                                                            >
                                                            {rowData.name}
                                                            </a>
                                                        )
                                                    },
                                                    {
                                                        dataKey: 'sector',
                                                        label: 'Sector',
                                                    },
                                                    {
                                                        dataKey: 'country',
                                                        label: 'Country',
                                                    },
                                                    {
                                                        dataKey: 'services',
                                                        label: 'Services',
                                                    },
                                                    
                                                    {
                                                        dataKey: 'internal_rate',
                                                        label: 'Internal Rate',
                                                    }
                                                ],
                                                
                                                people: [
                                                {
                                                    type: 'link',
                                                    label: 'Name',
                                                    dataKey: 'name',
                                                    mapValue: (rowData) => (
                                                    <a
                                                    
                                                        style={{
                                                            textOverflow: 'ellipsis',
                                                            fontFamily: "Source Sans Pro",
                                                            textDecoration: "underline",
                                                            lineHeight: "16px",
                                                            display: 'inline-block',
                                                            fontWeight: 400,
                                                            color: "#0094A7",
                                                            overflow: 'hidden',
                                                            fontSize: "16px",
                                                            width: '200px',
                                                            fontStyle: "normal",
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                        target="_blank"
                                                        href={`/People/${rowData.people_id}`}
                                                        > {rowData?.name} </a>
                                                    )
                                                },
                                                {
                                                    label: 'Related to',
                                                    mapValue: (rowData) => {
                                                    return rowData?.related_to?.map((related: any) => related.employe_status)?.join(",") || '---'
                                                    },
                                                    dataKey: 'related_to',
                                                },
                                                {
                                                    dataKey: 'unrelated',
                                                    mapValue: (rowData) => {
                                                        return rowData?.unrelated?.map((related: any) => related.employe_status)?.join(",") || '---'
                                                    },
                                                    label: 'Unrelate',
                                                },
                                                ],
                                                project: [
                                                    {
                                                        dataKey: 'type',
                                                        label: 'Type',
                                                    },
                                                    {
                                                        dataKey: 'name',
                                                        label: 'Project name',
                                                        type: 'link',
                                                        mapValue: (rowData) => (
                                                            <a
                                                            target="_blank"
                                                            style={{
                                                                color: "#0094A7",
                                                                fontWeight: 400,
                                                                overflow: 'hidden',
                                                                lineHeight: "16px",
                                                                width: '200px',
                                                                fontStyle: "normal",
                                                                fontSize: "16px",
                                                                display: 'inline-block',
                                                                textDecoration: "underline",
                                                                fontFamily: "Source Sans Pro",
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                            }}
                                                                href={`/Projects/${rowData.project_id}`}
                                                            >
                                                                {rowData.name}
                                                            </a>
                                                        )
                                                    },
                                                    {
                                                        label: 'Group company',
                                                        dataKey: 'group_company'
                                                    },
                                                    {
                                                      dataKey: 'country',
                                                      label: 'Country'
                                                    },
                                                    {
                                                        dataKey: 'client',
                                                        label: 'Client',
                                                    }
                                                ],
                                            }}
                                            handleRequestNavigation={this.handleTenderRequestNavigation}
                                        />

                                    </Box>
                                    
                                )}
                                {this.state.tenderId && this.state.activeSection === configJSON.TenderModuleSections[3] && (
                                    <Box id="Audit trail">
                                        <Audit isAuditTrail={this.state.isAuditTrail} id={""} peopleId={this.state.tenderId} navigation={this.props.navigation} itemType={"tender"} />
                                    </Box>
                                )}
                            </ScrollDiv>
                            {this.state.hasError && typeof this.state.hasError === 'string' && (
                                <Snackbar
                                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                                open={!!this.state.hasError}
                                message={this.state.hasError}
                                key="error-message"
                                    onClose={() => {
                                        this.setState({
                                            hasError: "",
                                        });
                                    }}
                                />
                            )}
                        </Box>}

                    </Box>
                </Grid>
                <Snackbar
                    message="At least one language should be present"
                    open={this.state.deleteError}
                    onClose={() => this.setState({ deleteError: false })}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                />
            </Grid>
            </MainDiv>
        );
    }
}
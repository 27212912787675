Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TextDetector";
exports.labelBodyText = "TextDetector Body";

exports.btnExampleTitle = "CLICK ME";

exports.requestApiContentType = "application/json";
exports.getReceivedRequestApiEndpoint = "request_management/requests/received";
exports.getReceivedRequestApiMethod = "GET";

exports.getSendRequestApiEndpoint = "request_management/requests/sent";
exports.getSendRequestApiMethod = "GET";

exports.getAllReceivedRequestApiEndpoint = "request_management/requests";
exports.getAllReceivedRequestApiMethod = "GET";

exports.createSendRequestApiEndpoint = "request_management/requests";
exports.createSendRequestApiMethod = "POST";

exports.emptyGroupIdAlert = "Please select group id";
exports.emptyRejectReasonAlert = "Please enter reason";
exports.acceptedMsgText = "Request has been accepted.";
exports.rejectedMsgText = "Request has been rejected.";
exports.deletedMsgText = "Request has been deleted.";
exports.requestSentSuccessMsg = "Request has been sent."
exports.requestUpdateSuccessMsg = "Request has been updated."

exports.updateRequestApiEndpointStart = "request_management/requests/";
exports.updateRequestApiEndpointEnd = "/review";
exports.updateRequestApiMethod= "PUT";

exports.deleteRequestApiEndpoint = "request_management/requests/";
exports.deleteRequestApiMethod= "DELETE";

exports.getGroupsApiEndpoint = "account_groups/groups";
exports.getGroupsApiMethod= "GET";

exports.getSentRequestApiEndpoint = "request_management/requests/sent";
exports.getSentRequestApiMethod= "GET";

exports.updateRequestTextApiEndpoint = "request_management/requests/";
exports.updateRequestTextApiMethod= "PUT";


exports.titleText = "Request Management";
exports.sendRequestBtnLabel = "Send Request";
exports.receiveRequestText = "Received Requests";
exports.nameLabelText = "Name";
exports.requestLabelText = "Request Text";
exports.rejectReasonLabelText = "Reject Reason";
exports.statusLabelText = "Status";
exports.actionLabelText = "Action";
exports.acceptBtnLabel = "Accept";
exports.rejectBtnLabel = "Reject";
exports.deleteBtnLabel = "Delete";
exports.sendRequestsText = "Sent Requests";
exports.updateBtnLabel = "Update";
exports.viewBtnLabel = "View";
exports.requestIdLabel = "Request ID";
exports.sendRequestDialogTitle = "Send Request";
exports.updateRequestDialogTitle = "Update Request";
exports.rejectRequestDialogTitle = "Request reject";
exports.cancelBtnLabel = "Cancel";
exports.sendBtnLabel = "Send";
exports.rejectReasonInputLabel = "Please enter reason";
exports.requestTextInputLabel = "Please enter request text";
exports.selectGroupPlaceholder = "Please select group";
exports.rejectTextFieldIsRequired = "Please enter reject reason";
exports.loginAlertMessage = "Please login first.";
exports.filterInputLabel = "Enter request id to filter";

exports.pageTitle = "Requests";
exports.raiseRequest = "Raise Request";
exports.requestRaised = "Request raised";
exports.requestReceived = "Request received";
exports.dateFormat = "dd/MM/yyyy";
exports.raiseRequestTitle = "Raise a request";

exports.getRequestApiEndPoint = "bx_block_request_management/requests"
exports.apiPatchMethod = 'PATCH';

exports.defaultRawsPerPage = 10;


exports.requestColumnListDefiniation = [
  { dataLabel: '', displayLabel: '', hideLabel: true, type: 'value', width: '50px', textAlign: 'center', },
  { dataLabel: 'module_name', displayLabel: 'Modules', hideLabel: false, type: 'value', width: '100px',},
  { dataLabel: 'requested_by', displayLabel: 'Requested by', hideLabel: false, type: 'value', width: '170px',},
  { dataLabel: 'updated_at', displayLabel: 'Actioned date', hideLabel: false, type: 'value', width: '130px',},
  { dataLabel: 'created_at', displayLabel: 'Requested date', hideLabel: false, type: 'value', width: '130px',},
  { dataLabel: 'status', displayLabel: 'Status', hideLabel: false, type: 'status', width: '100px',  filterOptions: [
    { id: 1, value: "closed", label: "Closed", textColor:"#059669", bgColor: "#D1FAE5" },
    { id: 2, value: "pending", label: "Pending", textColor: "#DC2626", bgColor:"#FEE2E2" },
    { id: 3, value: "open", label: "Open", textColor: "#D97706", bgColor:"#FEF3C7" },
  ],},
  { dataLabel: 'id', displayLabel: '', hideLabel: true, type: 'morevert', width: '36px', textAlign: 'center', },
  { dataLabel: '', displayLabel: '', hideLabel: true, type: 'value', width: '1px', textAlign: 'center', },

]

exports.mappingPaths = {
  "People": "People",
  "Project": "Projects",
  "Tender": "Tender",
  "Supplier": "Supplier",
  "Partner": "Partner"
}
// Customizable Area End
import React, { Component } from 'react';
import {
  Box,
  Typography,
  Divider,
  Grid,
  withStyles,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import CustomButton from '../../../../../components/src/CustomButton.web';
import CustomSelectMenu from '../../../../../components/src/CustomSelectMenu.web';
import {
  relatedGroupRelation,
  unrelatedGroupRelation,
} from '../../assets';
import CustomRadioButton from '../../../../../components/src/CustomRadioButton.web';
import { IWork } from '../../PeopleModuleController.web';
import LanguageLevelRadioGroup from '../../../../../components/src/LanguageLevelRadioGroup.web';
import { ErrorRounded } from '@material-ui/icons';
import GroupRelationMenu from '../../../../../components/src/GroupRelationMenu.web';
import { mergeUpdateRecordRelation, checkValueInList } from '../../../../../components/src/Utils';

type Props = {
  isPersonalDataDone: boolean;
  work: IWork;
  handleChangeValue: (val: string, key: string) => void;
  handlePostWorkData: () => void;
  handleUpdateWorkData: () => void;
  handleSelectLang: (
    language: string,
    level: 'Beginner' | 'Advance' | string | undefined,
    langObj?:any
  ) => void;
  handleChangeGroupRelation: (
    value: "related" | "unrelated",
		groupRelationMenus: any,
  ) => void;
  handleShowOtherCurrentPositions: () => void;
  handleSwitchMode: (section: string, mode: 'update' | 'read' | 'post', onCancel?:boolean) => void;
  handleOpenGroupRelation: (value: boolean) => void;
  renderErrors: (errkey: string, errors: { [errorKey: string]: string }[]) => {error: boolean, text: string | undefined};
  currentPositionData?: string; 
};

type State = {
  otherLanguage: string;
};
let cacheWork: any;

export default class UpdateWork extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      otherLanguage: '',
    };
  }

  async componentDidMount() {
    const { work } = this.props;
    cacheWork = { ...work};
    if (work.languages.length > 0) {
      const otherLang = work.languages.find(
        (x) =>
          x.name !== 'English' &&
          x.name !== 'French' &&
          x.name !== 'Arabic' &&
          x.name !== 'Spanish' &&
          x.name !== 'Italian'
      );

      if (otherLang) {
        this.setState({ otherLanguage: otherLang.name });
        this.props.handleSelectLang(otherLang.name, otherLang.level);
      }
    }
  }

  renderLanguagesComponent = () => {
    const languageNames = [['English', 'French'], ['Arabic', 'Spanish'], ['Italian']];
    const { languages, otherLangList } = this.props.work;
    return languageNames.map((lan, i) => {
      const isOtherLanguage = i === languageNames.length - 1
      return (
        <Grid container spacing={2} style={{ marginBottom: 25 }} key={`update_work_grid_${i}`} >
          {lan.map((language, index) => {
            return <React.Fragment key={`update_work_grid_language_${i}`}><Grid item xs={12} sm={4} key={index} >
              <LanguageLevelRadioGroup
                selectedLevel={languages.find((x) => x.name === language)?.level ?? undefined}
                testId={`language-level-radio-group-${language.toLowerCase()}`}
                language={language}
                languageArray={languages}
                handleSelectLang={this.props.handleSelectLang}
              />
            </Grid>
              {isOtherLanguage && (
                <Grid item xs={12} sm={4}>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <LanguageHeader>Other language:</LanguageHeader>
                      <CustomSelectMenu
                        label=""
                        fullWidth
                        size="small"
                        testID="other-language-select-menu"
                        value={this.state.otherLanguage}
                        placeholder="Other language"
                        options={otherLangList}
                        error={false}
                        onChangeValue={(value) =>
                          this.setState({ otherLanguage: value })
                        }
                      />
                    </Box>
                    {this.state.otherLanguage.length > 0 && (
                      <LanguageLevelRadioGroup
                        selectedLevel={
                          languages.find((x) => x.name === this.state.otherLanguage)
                            ?.level ?? undefined
                        }
                        testId="language-level-radio-group"
                        isLabelHidden={true}
                        language={this.state.otherLanguage}
                        languageArray={languages}
                        handleSelectLang={this.props.handleSelectLang}
                      />
                    )}
                  </Box>
                </Grid>
              )}
            </React.Fragment>
          })}
        </Grid>
      )
    })
  }

  renderSaveCancelButton = () => {
    const { isCancelButtonEnabled, mode } = this.props.work;
    return <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          style={{
            gap: '20px',
            marginTop: '20px',
          }}>
          {isCancelButtonEnabled && (
            <CustomButton
              onClick={() => this.props.handleSwitchMode('work', 'read', true)}
              disabled={!isCancelButtonEnabled}
              data-testId="btnCancelWork"
              colorVariant="special">
              Cancel
            </CustomButton>
          )}

          <CustomButton
            colorVariant="default"
            data-testId="btnSaveWork"
            disabled={!this.props.isPersonalDataDone}
            onClick={
              mode === 'post'
                ? this.props.handlePostWorkData
                : this.props.handleUpdateWorkData
            }>
            Save
          </CustomButton>
        </Box>
      </Grid>
    </Grid>
  }

  prepareUpdateDataForRelation = (type: 'related' | 'unrelated', groupValue: any) => {
    const relationGroup = cacheWork?.groupRelationMenus || [];

    if (groupValue) {
        const resultRelationGroup = mergeUpdateRecordRelation(relationGroup, type, groupValue);
        this.props.handleChangeGroupRelation(type, resultRelationGroup);
    } else {
      this.props.handleChangeGroupRelation(type, null);
    }

  }


  getHelperText = (errorKey:string) => {
    const { errors } = this.props.work;
    return errors.find((x) => x.hasOwnProperty(errorKey))?.[errorKey] ?? undefined;
  };

  render() {
    const {
      design,
      education_stream,
      disciplineOptions,
      groupRelationRadio,
      openGroupRelation,
      groupRelationMenus,
      education_degree,
      internal_rate,
      primary_experience,
      experienceOptions,
      primary_year,
      region_of_experience,
      regionList,
      secondary_experience,
      secondary_year,
      supervision,
      current_position,
      currentPositionList,
      currentOtherPosition,
      currentOtherPositionOptions,
      errors,
      levelList,
      internalRateList,
      expYearsList,
    } = this.props.work;
    const { renderErrors } = this.props;

    const safeCurrentPosition = checkValueInList(currentPositionList, "value", current_position, this.props.currentPositionData);
    const safeOtherCurrentPosition = checkValueInList(currentOtherPositionOptions, "value", currentOtherPosition || current_position, "Others");

    return (
      <SectionContainer>
        {/* FIRST PART */}
        <SectionBox>
          <SectionHeader>Education</SectionHeader>
          {/* LEVEL - DISCIPLINE */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4} style={{ marginBottom: '15px' }}>
              <CustomSelectMenu
                value={education_degree}
                onChangeValue={(val: any) =>
                  this.props.handleChangeValue(val, 'education_degree')
                }
                options={levelList}
                testID="highest-level-select-menu"
                label="Highest level"
                placeholder="Highest level"
                error={renderErrors('education_degree', errors).error}
                helperText={renderErrors('education_degree', errors).text}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} style={{ marginBottom: '15px' }}>
              <CustomSelectMenu
                value={education_stream}
                onChangeValue={(val: any) =>
                  this.props.handleChangeValue(val, 'education_stream')
                }
                options={disciplineOptions}
                testID="discipline-select-menu"
                label="Discipline"
                placeholder="Discipline"
                error={renderErrors('education_stream', errors).error}
                helperText={renderErrors('education_stream', errors).text}
              />
            </Grid>
          </Grid>
        </SectionBox>
        <Divider />
        {/* SECOND PART */}
        <SectionBox>
          <SectionHeader>Experience</SectionHeader>
          {/* PRIMARY - YEARS */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4} style={{ marginBottom: '15px' }}>
              <CustomSelectMenu
                value={primary_experience}
                onChangeValue={(val: any) =>
                  this.props.handleChangeValue(val, 'primary_experience')
                }
                options={experienceOptions}
                testID="primary-experience-select-menu"
                label="Primary"
                placeholder="Primary"
                error={renderErrors('primary_experience', errors).error}
                helperText={renderErrors('primary_experience', errors).text}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} style={{ marginBottom: '15px' }}>
              <CustomSelectMenu
                value={primary_year}
                onChangeValue={(val: any) =>
                  this.props.handleChangeValue(val, 'primary_year')
                }
                options={expYearsList}
                testID="primary-years-select-menu"
                label="Years"
                placeholder="Years"
                error={renderErrors('primary_year', errors).error}
                helperText={renderErrors('primary_year', errors).text}
              />
            </Grid>
          </Grid>
          {/* SECONDARY - YEARS */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4} style={{ marginBottom: '15px' }}>
              <CustomSelectMenu
                value={secondary_experience}
                onChangeValue={(val: any) =>
                  this.props.handleChangeValue(val, 'secondary_experience')
                }
                options={experienceOptions}
                testID="secondary-experience-select-menu"
                label="Secondary"
                placeholder="Secondary"
                error={renderErrors('secondary_experience', errors).error}
                helperText={renderErrors('secondary_experience', errors).text}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4} style={{ marginBottom: '15px' }}>
              <CustomSelectMenu
                value={secondary_year}
                onChangeValue={(val: any) =>
                  this.props.handleChangeValue(val, 'secondary_year')
                }
                options={expYearsList}
                testID="secondary-years-select-menu"
                label="Years"
                placeholder="Years"
                error={renderErrors('secondary_year', errors).error}
                helperText={renderErrors('secondary_year', errors).text}
              />
            </Grid>
          </Grid>
          {/* REGION */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4} style={{ marginBottom: '15px' }}>
              <CustomSelectMenu
                value={region_of_experience}
                onChangeValue={(val: any) =>
                  this.props.handleChangeValue(val, 'region_of_experience')
                }
                options={regionList}
                testID="region-of-experience-select-menu"
                label="Country"
                placeholder="Country"
                error={renderErrors('region_of_experience', errors).error}
                helperText={renderErrors('region_of_experience', errors).text}
              />
            </Grid>
          </Grid>

          {/* DESIGN - SUPERVISION */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4} style={{ marginBottom: '15px' }}>
              <Box
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  flexWrap: 'wrap'
                }}>
                  <LabelHeader>Design</LabelHeader>
                  <FormControl
                    error={errors.some((error) => error.hasOwnProperty('design'))}
                    component="fieldset">
                    <RadioGroup row>
                      <FormControlLabel
                        data-testid="formControlDesignYes"
                        control={
                          <CustomRadioButton
                            data-testID = "DesignPickYes"
                            id="pick_yes"
                            checked={design === 'Yes'}
                            onChange={() =>
                              this.props.handleChangeValue('Yes', 'design')
                            }
                          />
                        }
                        label="Yes"
                      />
                      <FormControlLabel
                        data-testid="formControlDesignNo"
                        control={
                          <CustomRadioButton
                            id="pick_no"
                            data-testID = "DesignPickNo"
                            checked={design === 'No'}
                            onChange={() =>
                              this.props.handleChangeValue('No', 'design')
                            }
                          />
                        }
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  {renderErrors("design", errors).error && 
                     <ErrorRounded
                        fill="#DC2626"
                        color="error"
                        style={{
                          height: '16px',
                          width: '16px',
                        }}
                      />
                  }
                </Box>
                {renderErrors("design", errors).error && (
                  <p style={{
                    textAlign: 'left',
                    fontSize: '0.75rem',
                    fontFamily: "Source Sans Pro",
                    fontWeight: 400,
                    lineHeight: 1.66,
                    color: '#f44336',
                    margin: "3px 14px 0px 1px",
                    letterSpacing: '0.03em',
                  }}>Please choose one option</p>
                )}
            </Grid>
            <Grid item xs={12} sm={6} lg={4} style={{ marginBottom: '15px' }}>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}>
                <LabelHeader>Supervision</LabelHeader>
                <FormControl
                  error={errors.some((error) =>
                    error.hasOwnProperty('supervision')
                  )}
                  component="fieldset">
                  <RadioGroup row>
                    <FormControlLabel
                      data-testId="formControlSupervisionYes"
                      value="Yes"
                      control={
                        <CustomRadioButton
                          checked={supervision === 'Yes'}
                          data-testID = "supervision"
                          onChange={() =>
                            this.props.handleChangeValue('Yes', 'supervision')
                          }
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      data-testId="formControlSupervisionNo"
                      value="No"
                      control={
                        <CustomRadioButton
                          checked={supervision === 'No'}
                          onChange={() =>
                            this.props.handleChangeValue('No', 'supervision')
                          }
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
               {renderErrors("supervision", errors).error && <ErrorRounded
                  color="error"
                  fill="#DC2626"
                  style={{
                    width: '16px',
                    height: '16px',
                  }}
                  />}
              </Box>
              {renderErrors("supervision", errors).error && (
                  <p style={{
                    fontFamily: "Source Sans Pro",
                    margin: "3px 14px 0px 1px",
                    textAlign: 'left',
                    fontWeight: 400,
                    lineHeight: 1.66,
                    letterSpacing: '0.03em',
                    fontSize: '0.75rem',
                    color: '#f44336'
                  }}>Please choose one option</p>
                )}
            </Grid>
          </Grid>
        </SectionBox>
        <Divider />
        {/* 3RD PART */}
        {/* CURRENT POSITION */}
        <SectionBox>
          <SectionHeader>Current position</SectionHeader>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4} style={{ marginBottom: '15px' }}>
              <CustomSelectMenu
                value={safeCurrentPosition}
                onChangeValue={(val: any) =>
                  this.props.handleChangeValue(val, 'current_position')
                }
                options={currentPositionList}
                testID="current-position-select-menu"
                label="Current position"
                placeholder="Current position"
                style={{ marginRight: '20px' }}
                error={renderErrors('current_position', errors).error}
                helperText={renderErrors('current_position', errors).text}
              />
            </Grid>
            {safeCurrentPosition === 'Others' && (
              <Grid item xs={12} sm={6} lg={4} style={{ marginBottom: '15px' }}>
                <CustomSelectMenu
                  value={safeOtherCurrentPosition}
                  onChangeValue={(val: any) =>
                    this.props.handleChangeValue(val, 'currentOtherPosition')
                  }
                  options={currentOtherPositionOptions}
                  testID="current-other-position-select-menu"
                  label=""
                  placeholder="Other"
                  style={{ marginRight: '20px', marginTop: '23px' }}
                  error={renderErrors('currentOtherPosition', errors).error}
                  helperText={renderErrors('currentOtherPosition', errors).text}
                />
              </Grid>
            )}
          </Grid>
        </SectionBox>
        <Divider />
        {/* 4TH PART */}
        {/* GROUP RELATION */}
        <SectionBox>
          <SectionHeader>Group Relation</SectionHeader>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginBottom: 25,
                }}>
                <FormControl component="fieldset">
                  <RadioGroup row>
                    <StyledFormControlLabel
                      data-testId="relateControl"
                      value="related"
                      control={
                        <CustomRadioButton
                          checked={groupRelationRadio === "related"}
                          data-testId="groupRelation"
                          onChange={() => this.prepareUpdateDataForRelation("related", null)}
                        />
                        }
                      onClick={() => this.prepareUpdateDataForRelation("related", null)}
                      label="Related"
                    />
                    <StyledFormControlLabel
                      value="unrelated"
                      data-testId="unrelateControl"
                      control={
                        <CustomRadioButton
                          checked={groupRelationRadio === "unrelated"}
                          data-testId="unGroupRelation"
                          onChange={() => this.prepareUpdateDataForRelation("unrelated", null)}
                        />
                        }
                      onClick={() => this.prepareUpdateDataForRelation("unrelated", null)}
                      label="Unrelated"
                    />
                  </RadioGroup>
                </FormControl>
                {groupRelationRadio === "related" && (
                  <GroupRelationMenu
                    menuItems={relatedGroupRelation}
                    groupRelationRadio={"related"}
                    groupRelationMenus={groupRelationMenus ?? []}
                    handleChangeGroupRelation={
                      this.prepareUpdateDataForRelation
                    }
                    handleOpenGroupRelation={this.props.handleOpenGroupRelation}
                    openGroupRelation={openGroupRelation}
                    data-testId="group-related-popper"
                  />
                )}
                {groupRelationRadio === "unrelated" && (
                  <GroupRelationMenu
                    menuItems={unrelatedGroupRelation}
                    groupRelationRadio={"unrelated"}
                    groupRelationMenus={groupRelationMenus ?? []}
                    pickOne = {true}
                    handleChangeGroupRelation={
                      this.prepareUpdateDataForRelation
                    }
                    handleOpenGroupRelation={this.props.handleOpenGroupRelation}
                    openGroupRelation={openGroupRelation}
                    data-testId="group-unrelated-popper"
                  />
                )}
                <ErrorMessage>{this.getHelperText("group_relation")}</ErrorMessage>
              </Box>
            </Grid>
          </Grid>
        </SectionBox>
        <Divider />
        {/* LANGUAGES */}
        <SectionBox className='sectionLanguages'>
          <SectionHeader>Language</SectionHeader>
          {/* Language Selection */}
          {this.renderLanguagesComponent()}
          <ErrorMessage style={{ marginTop: "-15px"}}>{renderErrors("languages", errors).text}</ErrorMessage>
        </SectionBox>
        <Divider />
        {/* INTERNAL RATE */}
        <SectionBox>
          <SectionHeader>Internal rate</SectionHeader>
          {/* INTERNAL RATE */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4} style={{ marginBottom: '15px' }}>
              <CustomSelectMenu
                value={typeof internal_rate === 'string' ? internal_rate : internal_rate?.value}
                onChangeValue={(val: any) =>
                  this.props.handleChangeValue(val, 'internal_rate')
                }
                options={internalRateList}
                testID="internal-rate-select-menu"
                label=""
                placeholder="Internal rate"
                error={renderErrors('internal_rate', errors).error}
                helperText={renderErrors('internal_rate', errors).text}
              />
            </Grid>
          </Grid>
        </SectionBox>
        {/* BUTTONS */}
        {this.renderSaveCancelButton()}
      </SectionContainer>
    );
  }
}

const LabelHeader = withStyles({
  root: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: 0,
    marginRight: '24px',
  },
})(Typography);

const LanguageHeader = withStyles({
  root: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: 0,
    fontFamily: 'Source Sans Pro',
    color: '#2B3132',
    whiteSpace: 'nowrap',
    marginRight: 15
  },
})(Typography);

const SectionHeader = withStyles({
  root: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '16px',
    marginTop: '15px',
    marginBottom: '24px',
  },
})(Typography);

const SectionContainer = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '52px',
  },
})(Box);

const SectionBox = withStyles({
  root: {
    marginBottom: '15px',
    "&.sectionLanguages": {
      "& .MuiOutlinedInput-root .MuiSelect-iconOutlined": {
        background: 'white',
        zIndex: 2,
      }
    }
  },
  
})(Box);

const StyledFormControlLabel = withStyles({
  label: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '16px',
  },
})(FormControlLabel);

const ErrorMessage = withStyles({
  root:{
    fontFamily: "Source Sans Pro",
    margin: "5px 14px 0px 0px",
    textAlign: "left",
    fontWeight: 400,
    lineHeight: "1.66",
    letterSpacing: "0.03em",
    fontSize: "0.75rem",
    color: "rgb(244, 67, 54)",
  }
})(Typography);

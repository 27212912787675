import { Box, Grid, styled } from "@material-ui/core";
import React from "react";
import TruncatedTextWithTooltip from "./TruncatedTextWithTooltip.web";
import { Pagination } from "@material-ui/lab";


interface RightLegendListProps {
    data: any[];
    rowsPerPage?: number;
}

interface S {
    page: number;
}

class RightLegendList extends React.Component<RightLegendListProps, S> {
    constructor(props: RightLegendListProps) {
        super(props);
        this.state = {
            page: 1,
        };
    }

    handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
        this.setState({ page: newPage });
    };

    render() {
        const { data, rowsPerPage } = this.props;
        const { page } = this.state;
        const perPage = rowsPerPage || 5;
        const count = Math.ceil((data?.length / perPage) || 1);
        return (
            <RightLegendContainer wrap="nowrap">
                {data.length > 0 && data?.slice(
                    (page - 1) * perPage,
                    (page - 1) * perPage + perPage,
                )?.map((entry: any, i: number) => {
                    return (
                        <Grid item className={`chartLabel`} key={`${i + 1}-legend`}>
                            <Box className="labelBox" style={{ background: entry[2] }} />
                            <Box className="labelContent">
                                <TruncatedTextWithTooltip text={entry[0]} maxWidth="120px" classNames="label" />
                            </Box>
                        </Grid>
                    );
                })}
                {count > 1 &&
                    <ListPagination
                        count={count}
                        page={this.state.page}
                        onChange={this.handleChangePage}
                        variant="outlined"
                        shape="rounded"
                        disabled={!count}
                        size="small"
                    />}
            </RightLegendContainer>
        );
    }
}

export const RightLegendContainer = styled(Grid)({
    display: 'grid',
    gridTemplateColumns: '1fr',
    width: "100%",
    gridGap: "8px 30px",
    "& .chartLabel": {
        cursor: "default",
        transition: "background-color 0.3s ease",
        position: "relative",
        display: "flex",
        alignItems: "center",
        "& .labelBox": {
            height: "16px !important",
            display: "inline-block",
            marginRight: "10px",
            width: "16px !important",
        },
        "& .labelContent": {
            verticalAlign: "top",
            display: "inline-block",
            "& .label": {
                fontFamily: 'Source Sans Pro',
                fontWeight: 600,
                lineHeight: "16px",
                wordWrap: 'break-word',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: 'black',
                fontSize: 12,
            },
        },
    },
});

export const ListPagination = styled(Pagination)({
    flexWrap: "nowrap",
    "& li:last-child": {
        "& button": {
            color: "black"
        },
    },
    "& li:first-child": {
        "& button:disabled": {
            display: "none"
        },
        "& button": {
            color: "black"
        },
    },
    "& li": {
        "& .MuiPaginationItem-page.Mui-selected": {
            background: 'transparent',
            color: "black",
            cursor: "default",
        },
        "& .MuiPaginationItem-outlined": {
            border: 'none',
            color: '#00000066',
            height: "32px",
            fontFamily: "Source Sans Pro",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "16px"
        },
        "& button":{ 
            height: "16px !important",
        },
        padding: 0,
    },
    "@media (max-width: 600px)": {
        "& li": {
            "& .MuiPaginationItem-root": {
                margin: "4px",
            },
        },
        flexWrap: "wrap",
    }
})


export default RightLegendList;

import React, { Component } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import CustomTypographyWeb from "../../../../../components/src/CustomTypography.web";
import { Image, S, configJSON } from "../../ProjectModuleController.web";
import { imageCloseIcon, projectModuleCommonStyle } from "../../assets";
import CustomButton from "../../../../../components/src/CustomButton.web";
import { ArrowUpward } from "@material-ui/icons";
import ImageCard from "../../../../../components/src/CustomFields/ImageCard.web";
import CloseIcon from "@material-ui/icons/Close";

interface Props {
  mode: string;
  gallery: Image[];
  testId: string;
  onChange: (changedPropName: keyof S, value: any) => void;
  onDeleteCertificate: (id: number, type: string) => void;
  onImageUpload: boolean;
  onFileChange: (event: any) => void;
  openImage: (imageUrl: string) => void;
  imagesToUpload: File[];
  removeStagedFile: (fileIndex: number) => void;
}

export default class ProjectGalleryView extends Component<Props> {
  truncateText = (str: string, length: number = 15) => {
    if (str.length >= length) return str.slice(0, length) + "...";
    return str;
  };
  render() {
    const { mode, gallery, onImageUpload, testId, imagesToUpload } = this.props;
    const webStyle = projectModuleCommonStyle;

    const showButton = onImageUpload || gallery.length === 0 || imagesToUpload.length > 0;

    const uploadCertRef: React.RefObject<HTMLInputElement> = React.createRef();

    return (
      <Box sx={webStyle.containerWrapper} data-test-id={testId}>
        <Grid container style={webStyle.gridContainer}>
          <Grid item xs={12}>
            <Box sx={projectModuleCommonStyle.galleryContainer}>
              <CustomTypographyWeb isHeader style={{marginBottom: '8px'}}>
                Gallery
              </CustomTypographyWeb>
              {mode !== configJSON.viewRecordMode && showButton ? (
                <>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                    }}
                  >
                    {imagesToUpload.length > 0 &&
                      imagesToUpload.map(
                        (image: File, index: number) => (
                          <Box
                            key={index}
                            data-test-id={`${configJSON.projectGalleryImagesToUploadTestId}-${index}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "8px",
                            }}
                          >
                            <Typography
                              data-test-id={`label-${configJSON.projectGalleryImagesToUploadTestId}-${index}`}
                              style={{
                                color: "#0094A7",
                                fontFamily: "Source Sans Pro",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "16px",
                              }}
                            >
                              {this.truncateText(image.name, 30)}
                            </Typography>
                            <Button
                              data-test-id={`removebutton-${configJSON.projectGalleryImagesToUploadTestId}-${index}`}
                              style={{ marginLeft: "8px" }}
                              onClick={() => this.props.removeStagedFile(index)}
                              size="small"
                            >
                              <CloseIcon />
                            </Button>
                          </Box>
                        )
                      )}
                  </Box>
                  <input
                    data-test-id={
                      configJSON.projectGalleryInputFileButtonTestId
                    }
                    type="file"
                    hidden
                    onChange={this.props.onFileChange}
                    ref={uploadCertRef}
                    accept=".jpg, .jpeg, .png"
                  />
                  <CustomButton
                    data-test-id={configJSON.projectGalleryUploadButtonTestId}
                    startIcon={<ArrowUpward />}
                    style={projectModuleCommonStyle.imageUpload}
                    onClick={() => uploadCertRef.current?.click()}
                  >
                    Upload
                  </CustomButton>
                  <Typography
                    style={projectModuleCommonStyle.imageDocumentInfo}
                  >
                    Note: <br />
                    Supported files: .JPG, JPEG, PNG <br />
                    Max size: 10MB
                  </Typography>
                </>
              ) : (
                <Grid container>
                  <Grid item xs={10}>
                    <Box
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "22px",
                      }}
                    >
                      {gallery.length > 0 &&
                        gallery.map((galleryImage: Image, index: number) => {
                          return (
                            <Box
                              data-test-id={`${configJSON.projectGalleryItemTestId}-image-${index}`}
                              key={index}
                              sx={{
                                position: "relative",
                              }}
                            >
                              {mode !== configJSON.viewRecordMode && (
                                <div
                                  data-test-id={`${configJSON.projectGalleryItemTestId}-delete-${index}`}
                                  style={{
                                    position: "absolute",
                                    right: -20,
                                    top: -20,
                                    zIndex: 1,
                                    backgroundImage: `url(${imageCloseIcon})`,
                                    height: 40,
                                    width: 40,
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.props.onDeleteCertificate(
                                      galleryImage.certificate_id,
                                      "image"
                                    )
                                  }
                                />
                              )}
                              <ImageCard
                                key={index}
                                data-test-id={`${configJSON.projectGalleryItemTestId}-${index}`}
                                imageUrl={galleryImage.image_link}
                                payload={this.props.openImage}
                              />
                            </Box>
                          );
                        })}
                    </Box>
                    {mode !== configJSON.viewRecordMode &&
                      gallery.length !== 0 && (
                        <CustomButton
                          data-test-id={
                            configJSON.projectGalleryAddMoreButtonTestId
                          }
                          colorVariant="special"
                          style={projectModuleCommonStyle.addMoreButton}
                          onClick={() =>
                            this.props.onChange("onImageUpload", true)
                          }
                        >
                          Add more
                        </CustomButton>
                      )}
                  </Grid>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export const advancedSearchStyling = {
  autoCompleteInputBox: {
    maxHeight: "350px",
    overflowY: "auto",
    marginTop: "8px",
    width: "40%",
    overflowX: "hidden",
    zIndex: 3,
    backgroundColor: "#fff",
    borderRadius: "8px",
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#E8E8E8",
    },
    boxShadow: "8px 8px 16px 0px rgba(0, 148, 167, 0.16)",
    "@media (max-width: 960px)": {
      width: "100% !important",
    },
    "&::-webkit-scrollbar": {
      height: "6px",
      backgroundColor: "#E8E8E8",
      width: "6px",
      borderRadius: "14px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "14px",
      backgroundColor: "#0094A7",
    },
  },
  projectModuleAutoCompleteWrapper: {
    margin: "20px 0px"
  }
};

export enum NavigateToView {
  Project = "Projects",
  Tender = "Tender",
  Supplier = "Supplier",
  Partner = "Partner",
}

export enum SearchApiEndpoint {
  Project =  "/bx_block_profile/projects/advance_search?q=",
  Tender = "/bx_block_profile/tenders?advance_search=",
  Supplier = "/bx_block_multipageforms2/suppliers?advance_search=",
  Partner = "/bx_block_multipageforms2/partners?advance_search=",
}

import React from 'react';
import {User, Permission, AdvancedFilterContext} from "../../src/ContextAPI/AdvancedFilterContext.web"

export interface WithAuthenticatedProps {
    currentUser: User,
    permissions: { [key: string]: Permission[] }
}

export function withAccess<T extends {navigation?: any} & WithAuthenticatedProps>(
        WrappedComponent: React.ComponentType<T>
    ): React.ComponentType<Omit<T, keyof WithAuthenticatedProps>> {

    const HocComponent = class extends React.Component<Omit<T, keyof WithAuthenticatedProps>, {}> {
        static contextType?: React.Context<any>  = AdvancedFilterContext;
    
        render() {
            const { currentUser, permissions } = this.context.globalContext 
            const additionProps = {
                currentUser,
                permissions
            } as WithAuthenticatedProps;

            return (
                <WrappedComponent {...(this.props as T)} { ...additionProps } /> 
            )
        }
    };

    HocComponent.contextType = AdvancedFilterContext;

    return HocComponent;
}

import React from "react";
import { Box, Popover, ClickAwayListener, Paper, MenuList, MenuItem, styled, Popper, PopperPlacementType  } from "@material-ui/core"
import CustomButton from "../../CustomButton.web";

export interface MapKey {
  nameKey: string, 
  idKey: string,
  path: string
}
interface Props {
  connections: any[],
  mapField: MapKey
  navigation: any
}

const CustomPopper = styled(Popper)({
    "& .papper": {
      borderRadius: "4px",
      padding: "5px 0px",
      border: "1px solid #9F9F9F",
      maxWidth: "300px",
      minWidth: "200px",
      background: "#FFF",
      boxShadow: "-4px 4px 24px 0px rgba(0, 0, 0, 0.16)",
      "& .menuList": {
        padding: "0px",
        "& li": {
          display: 'flex',
          flexDirection: 'row',
          gap: 12,
          alignItems: 'start',
          cursor: 'pointer',
          color: '#0094A7',
          fontSize: "1rem",
          fontFamily: 'Source Sans Pro',
          boxSizing: 'border-box', 
          paddingTop: '12px',
          paddingBottom: '12px',
          fontWeight: '600',
          "&:hover": {
            backgroundColor: '#F0F0F0',
            color: '#2B3132',
          },
          "& a": {
            margin: "0",
            textAlign: 'start',
            textDecoration: "underline", 
            color: "#0094A7",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            lineClamp: 2,
            display: "inlineBlock",
            textDecorationColor: "#0094A7"
          },
        },
        "& .selectedMenu":{
          cursor: "default",
          "&:hover": {
            color: '#0094A7',
            fontWeight: '600',
          },
          "& .icon": {
            position: "absolute",
            width: "24px",
            height: "24px",
            left: "12px",
          },
        }
      },
    },
})


export const ConnectionCell: React.FC<Props> = ({
    connections,
    mapField, 
    navigation
}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = React.useState(false);
    const [placement, setPlacement] = React.useState<PopperPlacementType>();
  
    const handleClick =
      (newPlacement: PopperPlacementType) =>
      (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const lastOne = connections?.[connections?.length - 1 || 0]

    const clickAway = () => {
        setOpen(false);
    }
    
    const getName = (data: any) => {
      const nameKey = mapField.nameKey;
      return data[nameKey]
    };

    const getId = (data: any) => {
      const idKey = mapField.idKey;
      return data[idKey]
    };

    const getPath = (data: any) => {
      const path = mapField.path;
      return path
    }

    return (
        <Box display="flex" alignItems="center" width={270} onMouseLeave={() => setOpen(false)}>
            { lastOne &&
                <>
                
                <a 
                    style={{
                        cursor: "pointer", 
                        textDecoration: "underline", 
                        color: "#0094A7",
                        display: 'block',
                        flex: 8,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        lineClamp: 2
                    }}
                        {...lastOne["id"] ? { target: "_blank", href: `${getPath(lastOne)}/${getId(lastOne)}` } : {}}
                >
                        {getName(lastOne)}
                </a>

                <CustomButton
                    // height={28}
                    size="small"
                    style={{  height: 28, padding: "0 4px", flex: 2, minWidth: 'unset' }}
                    aria-haspopup="true"
                    onClick={handleClick('bottom')}
                >
                 View All
                </CustomButton>

                <CustomPopper
                     style={{ zIndex: 1200 }}
                     open={open}
                     anchorEl={anchorEl}
                     placement={placement}
                     transition
                  >
                    <ClickAwayListener onClickAway={clickAway}>
                      <Paper className="papper">
                        <MenuList
                          autoFocusItem={false}
                          id="menu-list-grow"
                          className="menuList"
                        >
                          { connections?.map(record => 
                            <MenuItem 
                            key={`dropdown_item_${getId(record)}`} 
                            style={{ fontSize: '14px'}}
                            className="selectedMenu"
                            >
                              <a href={`${getPath(record)}/${getId(record)}`} target="_blank">{ getName(record) }</a>
                            </MenuItem>
                          )}
                          
                        </MenuList>
                      </Paper>
                    </ClickAwayListener>
                </CustomPopper>
                </>
            } 
            
            
            
        </Box>
    )
}

export default ConnectionCell
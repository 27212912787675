import React, { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { StyledReportSnackBar } from "./ReportSnackBar"
import { StyledProgressBar } from "./ProgressBar";
import {downloadFromLink} from "../Utils"
import { DownloadManager, ProgressBarProps } from "./DownloadManager"

export const PollProgressBar: React.FC<{}> = () => {
    const [{
        trackingToken,
        outputFileConfig,
        exportMessage,
        ignoreShownPathname,
        trackingDownloadProgressSync,
        finishTrackingDownloadSync,
        onDownloadSucess
    }, setDownloadPrams] = useState<ProgressBarProps>({
        trackingToken: '',
        exportMessage: '',
        ignoreShownPathname: '',
        outputFileConfig: {
            filename: '',
            contentType: ''
        },
        trackingDownloadProgressSync: null,
        finishTrackingDownloadSync: null,
    })

    const [visible, setVisible] = useState<boolean>(false);
    const [progressPercent, setProgress] = useState<number>(0);
    const [isModalSuccess, setModalSuccess] = useState<boolean>(false);

    let downloadIntevel: any;

    useEffect(() => {
        return () => {
            clearInterval(downloadIntevel)
        }
    }, [])

    useEffect(() => {
        if (trackingToken) {
            downloadIntevel = handleIntervelDownload();
        }
    }, [trackingToken])


    const cancelDownload = () => {
        setVisible(false)
        setProgress(0);
        clearInterval(downloadIntevel)
    }

    const handleIntervelDownload = () => {
        const intervel = setInterval(async () => {
            if (trackingToken) {
                const percentDownload = await trackingDownloadProgressSync?.(trackingToken);
                const roundPercent = Math.round(percentDownload || 0);
                setProgress(roundPercent)

                if (roundPercent == 100) {
                    handleDownloadFinish()
                    setVisible(false)
                    clearInterval(intervel)
                }

            }
            
        }, 1000)

        return intervel
    }
    const maxRetry = 200;
    let timeRetry = 0;

    const handleDownloadFinish = async () => {
        const downloadContent = await finishTrackingDownloadSync?.(trackingToken as string);
        const pathname = location.pathname;
        if (onDownloadSucess) {
            if (pathname != ignoreShownPathname) {
                setModalSuccess(true);
            }
            
        } else {
            timeRetry += 1;
            if (timeRetry > maxRetry) {
                return
            };

            setVisible(true)

            if (downloadContent) {
                downloadFromLink(outputFileConfig.filename, downloadContent || "");
                setVisible(false)
            } else {
                handleDownloadFinish()
            }
        }
        
        clearInterval(downloadIntevel);
    }

    const startDownload = (params: ProgressBarProps) => {
        setVisible(true);
        setProgress(0);
        setDownloadPrams(params);
    }

    const handleClickView  = () => {
        setModalSuccess(false)
        onDownloadSucess?.(trackingToken)
    }

    DownloadManager.setupInternal('default', {
        visible,
        progressPercent,
        startDownload,
        cancelDownload,
        setDownloadPrams
    })

    
    return (
        <>       
            <StyledProgressBar
                progressPercent={progressPercent}
                visible={visible}
                message={exportMessage}
                onClose={cancelDownload}
            />
            <StyledReportSnackBar
                visible = {isModalSuccess}
                message="Import sheets successfull"
                onClickResult={handleClickView}
            />
        </>

    )
}

export default PollProgressBar
import React from "react";

// Customizable Area Start
import { Container, Box } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CustomTypographyWeb from "../../../components/src/CustomTypography.web";
import CustomButton from "../../../components/src/CustomButton.web";
import EmailConsentController, { Props } from "./EmailConsentController.web";
export const configJSON = require("./config");

// Customizable Area End


export default class EmailConsent extends EmailConsentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"} style={{ marginTop: 40 }}>
            { !this.state.showReponse ? 
              (
                <>
                <CustomTypographyWeb 
                  variant="h4" 
                  data-testId="reportConfirm"
                  style={{ textAlign: 'center', lineHeight: 1.23 }}
                >
                {configJSON["confirmation_" + this.state.lang]}
                </CustomTypographyWeb>
                <Box
                style={{
                    ...webStyle.formActionButtonStyles,
                    display: 'flex',
                    
                }}
                >
                    <CustomButton
                        colorVariant='special'
                        data-testId="abortBtn"
                        onClick={this.handleCancel}
                    >
                        
                        {configJSON["consentButton_" + this.state.lang]}
                    </CustomButton>
                    
                    <CustomButton 
                        data-testId="confirmBtn"
                        onClick={this.handleSubmit}
                    >
                        {configJSON["refuseConsentButton_" + this.state.lang]}
                    </CustomButton>
                </Box>
                </>
              )
              : 
              <CustomTypographyWeb 
                variant="h4"
                data-testId="reportConfirm"
                style={{ textAlign: 'center', lineHeight: 1.23 }}
                >
                  { this.state.responseMessage || "Thanks for your response"}
              </CustomTypographyWeb>
            }
            
        </Container>
      </ThemeProvider>
    );
  }
  // Customizable Area End

}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
    formActionButtonStyles: {
        display: "flex",
        justifyContent: "center",
        gap: "20px",
        margin: "24px 0",
    },
};
// Customizable Area End

// Customizable Area Start
import React from "react";
import { Box, Grid, Snackbar, Hidden } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import Audit from '../../audittrail2/src/Audittrail.web';
import CustomList from "../../../components/src/CustomList.web";
import Navbar from "../../../components/src/NavBar.web";
import Loading from "../../../components/src/Loading/Loading";
import SupplierModuleHeader from "./SupplierModuleHeader.web";
import SupplierDetails from "./SupplierDetails.web";
import SupplierBrochure from "./SupplierBrochure.web";
import CustomButton from "../../../components/src/CustomButton.web";
import ResponsiveTopBar from "../../../components/src/TopBar/ResponsiveTopBar.web";
import SupplierModuleController, { Props, configJSON } from "./SupplierModuleController.web";
import SupplierNotes from "./SupplierNotes.web";
import ConnectionWeb from "../../DeepLinking/src/Connection.web";
import { bindingCloseEvent } from "../../../components/src/Utils";

// Customizable Area End

class SupplierModule extends SupplierModuleController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    renderError() {
        if (this.state.hasError && typeof this.state.hasError === 'string') {
            return (
                <Snackbar
                   anchorOrigin = {{ vertical: "top", horizontal: "right" }}
                   open = {!!this.state.hasError}
                   message = {this.state.hasError}
                   data-testid="moduleError"
                   key = "error-message"
                   onClose={() => { this.setState({ hasError: "" }) }}
               />
            )
        }
    }

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            mode,
            SupplierError,
            groupResponse,
            countryResponse,
            servicesResponse,
            sectorResponse,
            internalRateResponse,
            isCancelBrochureEnabled,
            brochureToUpload,
            brochure,
            onBrochureUpload
        } = this.state

        return (
            <Grid
                container
                className={classes.supplierMainContainer}
            >
                <Hidden smDown>
                    <Grid item xs={12} sm={2}
                        className={classes.supplierSideBox}
                    >
                        <Box
                            className={classes.supplierSideInnerBox}
                        >
                            <CustomList
                                activeSection={this.state.activeSection}
                                sections={configJSON.SupplierModuleSections}
                                data-testId="list-menu"
                                handleListItemClick={this.handleListItemClick}
                                disabled={this.state.supplierId === ''}
                            />
                        </Box>
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <Grid xs={12}>
                        <ResponsiveTopBar navigation={this.props.navigation} title={"Suppliers"}>
                            { ( { closeDrawer } ) => 
                                <CustomList
                                    activeSection={this.state.activeSection}
                                    sections={configJSON.SupplierModuleSections}
                                    data-testId="list-menu"
                                    handleListItemClick={bindingCloseEvent(this.handleListItemClick, closeDrawer)}
                                    disabled={this.state.supplierId === ''}
                                />
                            }
                            
                        </ResponsiveTopBar>
                    </Grid>
                </Hidden>
                <Grid item xs={12} sm={12} md={10} className={classes.supplierdetailsbox} >
                    <Box
                        className={classes.supplierdetailsinnerbox}
                    >
                        <Hidden smDown>
                            <Navbar navigation={this.props.navigation} selectedTab={4} />
                        </Hidden>
                        {this.state.isLoading ? <Loading height="70vh" background="white" /> : <Box className={classes.supplierModuleHeader}>
                            <SupplierModuleHeader navigation={this.props.navigation} saveAsPDF={this.state.supplierId != ""} handleGoBack={this.handleGoBack} targetId={this.state.supplierId} />
                            <div
                                ref={this.contentRef}
                                className={classes.supplierDeatilsBox}
                            >
                                {this.state.activeSection === configJSON.SupplierModuleSections[0] && <Box id="Supplier Details">
                                   <SupplierDetails
                                    supplierDetails={this.state.supplierDetails}
                                    handleSwitchMode={this.handleSwitchMode}
                                    handleChangeValue={(val: string, key: string) =>
                                        this.handleChangeSupplierData(val, key)
                                    }
                                    SupplierErrors = {SupplierError}
                                    handlePostSupplierData = {this.handlePostsupplierDetails}
                                    handleUpdateSupplierData = {this.handleUpdatesupplierDetails}
                                    onCancelUpload={this.handleCancelSupplier}
                                    groupResponse = {groupResponse}
                                    countryResponse = {countryResponse}
                                    servicesResponse = {servicesResponse}
                                    sectorResponse = {sectorResponse}
                                    internalRateResponse = {internalRateResponse}
                                    onChange={this.handleChange}
                                    data-testid = "btnSavePerson"
                                    module="Supplier"
                                    supplierRequest={this.handleSupplierRequest}
                                   />
                                </Box>}
                                {(this.state.activeSection === configJSON.SupplierModuleSections[1]) && (
                                    <Box id="Brochure">
                                        <Grid item xs={12}>                                            
                                        <SupplierBrochure
                                            mode={this.state.mode}
                                            testId={"Brochure"}
                                            brochure={brochure}
                                            onChange={this.handleChangeBrochure}
                                            onDeleteBrochure={this.handleDeleteBrochure}
                                            onBrochureUpload={onBrochureUpload}
                                            onFileChange={this.handleFileChange}
                                            openImage={this.openImage}
                                            brochureToUpload={brochureToUpload}
                                            handleEditButtonClick={this.handleEditButtonClick}
                                            hasError={this.state.documentError}
                                            removeStagedFile={this.removeStagedFile}
                                            module="Supplier"
                                            supplierRequest={this.handleSupplierRequest}
                                        />
                                            <Box
                                                 className={mode === configJSON.viewRecordMode ? classes.formActionButtonNone : classes.formActionButtonFlex}
                                            >
                                                {isCancelBrochureEnabled && <CustomButton
                                                    data-test-id={configJSON.cancelButtonTestId}
                                                    disabled={this.state.isPendingApi}
                                                    colorVariant='special'
                                                    onClick={this.handleCancelButton}
                                                >
                                                    Cancel
                                                </CustomButton>}
                                                <CustomButton
                                                    disabled={this.state.isPendingApi}
                                                    data-test-id={configJSON.tenderModuleSaveButtonTestId}
                                                    onClick={this.handleSaveButton}>
                                                    Save
                                                </CustomButton>
                                            </Box>
                                        </Grid>

                                    </Box>
                                )}
                                {(this.state.activeSection === configJSON.SupplierModuleSections[2]) && (
                                    <Box id="Notes">
                                        <Grid container>
                                        <Grid item xs={12}>
                                            <SupplierNotes mode={this.state.mode} notesLoader={this.state.supplierNotesLoader} supplierRequest={this.handleSupplierRequest} testId={"supplierNotesBtnTestId"} notesData={this.state.notesData} onChange={(value) => this.handleChangeNotes(value)} handleEditButtonClick={this.handleEditButtonClick} supplierAddData={this.supplierAddData} notesError={this.state.notesError} module={"Supplier"}/>
                                          </Grid>
                                          <Grid item xs={12} sm={11}> 
                                            <Box
                                                 className={mode === configJSON.viewRecordMode ? classes.formActionButtonNone : classes.formActionButtonFlex}
                                            >
                                                {isCancelBrochureEnabled && <CustomButton
                                                    data-test-id={configJSON.cancelButtonTestId}
                                                    colorVariant='special'
                                                    onClick={this.handleNotesCancelButton}
                                                >
                                                    Cancel
                                                </CustomButton>}
                                                <CustomButton
                                                    data-testid={configJSON.supplierModuleSaveButtonTestId}
                                                    onClick={this.handlePostsupplierNotes}
                                                    disabled={!!this.state.notesError}
                                                    >
                                                    Save
                                                </CustomButton>
                                            </Box>
                                            </Grid>
                                        </Grid>

                                    </Box>
                                )}
                            {(this.state.activeSection === configJSON.SupplierModuleSections[3]) && (
                                <Box id="Connections">
                                    <ConnectionWeb 
                                        tabLabels={["Tenders", "Partners", "Projects", "People"]}
                                        source="supplier"
                                        onSuccessSave={this.handleCBSubmitDocument}
                                        editMode={this.countableSection.get(this.state.activeSection) == 0 && mode == 'post'}
                                        id={this.state.supplierId} 
                                        targets={["tender", "partner", "project", "people"]}
                                        schema={{
                                            project: [
                                                {
                                                    dataKey: 'type',
                                                    label: 'Type',
                                                },
                                                {
                                                    dataKey: 'name',
                                                    label: 'Project name',
                                                    type: 'link',
                                                    mapValue: (rowData) => (
                                                    <a
                                                        href={`/Projects/${rowData.project_id}`}
                                                        target="_blank"
                                                        style={{
                                                            fontFamily: "Source Sans Pro",
                                                            fontWeight: 400,
                                                            color: "#0094A7",
                                                            lineHeight: "16px",
                                                            textDecoration: "underline",
                                                            overflow: 'hidden',
                                                            display: 'inline-block',
                                                            textOverflow: 'ellipsis',
                                                            width: '200px',
                                                            fontStyle: "normal",
                                                            fontSize: "16px",
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                        >
                                                            {rowData.name}
                                                        </a>
                                                    )
                                                },
                                                {
                                                    dataKey: 'group_company',
                                                    label: 'Group company',
                                                },
                                                {
                                                    label: 'Country',
                                                    dataKey: 'country'
                                                },
                                                {
                                                    dataKey: 'client',
                                                    label: 'Client',
                                                }
                                            ],
                                            partner: [
                                                {
                                                    label: 'Name',
                                                    dataKey: 'company_name',
                                                    type: 'link',
                                                    mapValue: (rowData) => (
                                                    <a
                                                        href={`/Partner/${rowData.partner_id}`}
                                                        target="_blank"
                                                    style={{
                                                        fontWeight: 400,
                                                        color: "#0094A7",
                                                        width: '200px',
                                                        textDecoration: "underline",
                                                        whiteSpace: 'nowrap',
                                                        fontStyle: "normal",
                                                        lineHeight: "16px",
                                                        fontFamily: "Source Sans Pro",
                                                        overflow: 'hidden',
                                                        display: 'inline-block',
                                                        fontSize: "16px",
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                    >{rowData.name}
                                                    </a>
                                                    )
                                                },
                                                {
                                                    label: 'Sector',
                                                    dataKey: 'sector',
                                                },
                                                {
                                                    label: 'Country',
                                                    dataKey: 'country',
                                                },
                                                {
                                                    label: 'Services',
                                                    dataKey: 'services',
                                                },
                                                
                                                {
                                                    label: 'Internal Rate',
                                                    dataKey: 'internal_rate',
                                                }
                                            ],
                                            tender: [
                                                {
                                                    mapValue: (rowData) => (
                                                        <a
                                                            href={`/Tender/${rowData.tender_id}`}
                                                            target="_blank"
                                                            style={{
                                                              fontFamily: "Source Sans Pro",
                                                              color: "#0094A7",
                                                              textDecoration: "underline",
                                                              fontWeight: 400,
                                                              width: '200px',
                                                              lineHeight: "16px",
                                                              whiteSpace: 'nowrap',
                                                              fontStyle: "normal",
                                                              display: 'inline-block',
                                                              fontSize: "16px",
                                                              overflow: 'hidden',
                                                              textOverflow: 'ellipsis',
                                                            }}
                                                          >
                                                            {rowData.name}
                                                          </a>
                                                      ),
                                                    label: 'Name',
                                                    type: 'link',
                                                    dataKey: 'name',
                                                  
                                                },
                                                {
                                                    label: 'Services',
                                                    dataKey: 'services',
                                                },
                                                {
                                                    label: 'Country',
                                                    dataKey: 'country',
                                                },
                                                {
                                                    dataKey: 'group_company',
                                                    label: 'Group company',
                                                },
                                                {
                                                    dataKey: 'client',
                                                    label: 'Client',
                                                }
                                            ],
                                            
                                            people: [
                                            {
                                                mapValue: (rowData) => (
                                                    <a
                                                    target="_blank"
                                                    href={`/People/${rowData.people_id}`}
                                                        style={{
                                                            whiteSpace: 'nowrap',
                                                            fontFamily: "Source Sans Pro",
                                                            fontWeight: 400,
                                                            textDecoration: "underline",
                                                            lineHeight: "16px",
                                                            display: 'inline-block',
                                                            overflow: 'hidden',
                                                            width: '200px',
                                                            fontSize: "16px",
                                                            textOverflow: 'ellipsis',
                                                            fontStyle: "normal",
                                                            color: "#0094A7",
                                                        }}
                                                        >
                                                        {rowData?.name}
                                                        </a>
                                                ),
                                                type: 'link',
                                                label: 'Name',
                                                dataKey: 'name',
                                                
                                            },
                                            {
                                                mapValue: (rowData) => {
                                                    return rowData?.related_to?.map((related: any) => related.employe_status)?.join(",") || '---'
                                                },
                                                label: 'Related to',
                                                dataKey: 'related_to',
                                                
                                            },
                                            {
                                                mapValue: (rowData) => {
                                                    return rowData?.unrelated?.map((related: any) => related.employe_status)?.join(",") || '---'
                                                },
                                                label: 'Unrelate',
                                                dataKey: 'unrelated',
                                                
                                            },
                                            ]
                                            
                                        }}
                                        handleRequestNavigation={this.handleSupplierRequest}
                                        navigation={this.props.navigation}  
                                        testid="connection" />
                                </Box>
                            )}
                            {this.state.supplierId && this.state.activeSection === configJSON.SupplierModuleSections[4] && (
                                <Box id = "Audit trail">
                                        <Audit isAuditTrail={this.state.isAuditTrail} id={""} peopleId={this.state.supplierId} navigation={this.props.navigation} itemType={"supplier"} />
                                </Box>
                            )}
                              </div>
                              {this.renderError()}
                             </Box> }
                           </Box>
                        </Grid>
                     <Snackbar
                        message = "At least one language should be present"
                        open = {this.state.deleteError}
                        onClose = {() => this.setState({ deleteError: false })}
                        anchorOrigin = {{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                       />
                       </Grid>
                   );
                 // Customizable Area End
             }
         } 

  // Customizable Area Start
   export const styles =
    createStyles({
        supplierMainContainer: {
            backgroundColor: "#f3f3f3",
            height: "calc(100vh - 65px)"
        },

        supplierSideBox: {
            background: "white",
        },

        supplierSideInnerBox: {
            marginTop: "80px",
            width: "100%",
        },

        supplierdetailsbox: {
            backgroundColor: "#f3f3f3",
            height: "100%",
            overflow: "hidden"
        },

        supplierdetailsinnerbox: {
            display: "flex",
            flexDirection: "column",
            padding: "12px 15px",
            backgroundColor: "#f3f3f3",
            gap: '60px',
            height: "100%",
        },

        supplierModuleHeader: {
            position: "relative",
            backgroundColor: "#FFF",
            marginBottom: '20px',
            overflow: "visible",
            flex: 1,
            height: "100%"
        },

        supplierDeatilsBox: {
            padding: "10px 32px 38px 32px",
            backgroundColor: '#fff',
            overflowY: "scroll",
            height: "58vh",

            "&::-webkit-scrollbar": {
                backgroundColor: "#E8E8E8",
                borderRadius: "14px",
                height: "7px",
                width: "7px",
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "#E8E8E8",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#0094A7",
                borderRadius: "14px",
            },

            "@media (max-width: 960px)": {
                height: "calc(100% - 135px)",
            },

            "@media (max-height: 720px)": {
                height: "50vh",
            },
            "@media (max-height: 620px)": {
                height: "45vh",
            },
        },

        formActionButtonFlex: {
            display: "flex",
            justifyContent: "end",
            gap: "20px",
            margin: "25px 0",
        },

        formActionButtonNone: {
            display: "none",
            justifyContent: "end",
            gap: "20px",
            margin: "25px 0",
            padding: 0,
            textAlign: 'center'
        },

    })
// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(SupplierModule);
export { SupplierModule };
// Customizable Area End
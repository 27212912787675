import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { NavigationProp } from "@react-navigation/native";
// Customizable Area End

export const configJSON = require("./config");
export const configJSONMain = require("../../../framework/src/config");

export interface Props {
  navigation: NavigationProp<any> | null;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
}

export default class DeepLinkingController extends BlockComponent<
  Props,
  S,
  SS
> {
  setState: any;
  state: { txtSavedValue: string; enableField: boolean; };
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.SessionResponseMessage), getName(MessageEnum.RestAPIRequestMessage)];

    this.state = {
      txtSavedValue: "A",
      enableField: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

      let { data } = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );

      const deepLinkUrl = this.createDeepLinkUrl(data);
      
    // Customizable Area End
  }

  // Customizable Area Start
  createDeepLinkUrl = (data: { videoId: number }) => {
    const deepLinkUrl = `${configJSONMain.baseURL}${configJSON.deepLink}${
      data.videoId
    }`;
    this.setState({
      txtSavedValue: deepLinkUrl,
    });
    return deepLinkUrl;
  };

  sendDeepLinkUrl = (link: string) => {
    const sendDeepLinkUrlMsg: Message = new Message(getName(MessageEnum.SessionResponseToken));
    sendDeepLinkUrlMsg.addData(getName(MessageEnum.SessionResponseToken), link);
    runEngine.sendMessage(sendDeepLinkUrlMsg.id, sendDeepLinkUrlMsg);
    return link;
  };
  // Customizable Area End
}

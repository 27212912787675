

export type DownloadedTrackingFnc = (downloadToken: string) => Promise<number>;
export type DownloadedContentFnc = (downloadToken: string) => Promise<string>;

export type OutputFile = {
    contentType: string,
    filename: string
}

export interface ProgressBarProps {
    trackingToken: string,
    outputFileConfig: OutputFile,
    exportMessage: string,
    ignoreShownPathname?: string,
    trackingDownloadProgressSync: DownloadedTrackingFnc | null,
    finishTrackingDownloadSync: DownloadedContentFnc | null,
    onDownloadSucess?: (token: string) => void | null
}

export interface ShareFunc {
    startDownload: (params: ProgressBarProps) => void
    cancelDownload: () => void,
    setDownloadParams: (params: ProgressBarProps) => void
}

export class DownloadManager {
    static instanceManager: Map<string, ProgressBarProps> = new Map();
    static internalFunction: Map<string, ShareFunc> = new Map();
    static activeDownloadToken: string | null;

    static setup(key: string, obj: ProgressBarProps) {
        this.instanceManager.set(key, obj);
    }

    static setupInternal(key: string, obj: any) {
        this.internalFunction.set(key, obj);
    }

    static startDownload(key: string) {
        const params = this.instanceManager.get(key);
        const triggerFunction = this.internalFunction.get('default');
        
        if (params) {
            triggerFunction?.startDownload(params);
            this.activeDownloadToken = key;
        }
    }

    static cancelDownload(key: string) {
        const params = this.instanceManager.get(key);
        const triggerFunction = this.internalFunction.get('default');
        this.activeDownloadToken = null;
        if (params) {
            triggerFunction?.cancelDownload()
        }
    }

    static getDownloadToken() {
        return this.activeDownloadToken;
    }

}
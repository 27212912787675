import React from "react";
import { Box, CircularProgress, Grid, Hidden, Snackbar } from "@material-ui/core";
import ProjectModuleController, {
  Props,
  configJSON,
} from "./ProjectModuleController.web";
import CustomList from "../../../components/src/CustomList.web";
import ProjectModuleHeader from "./Components/ProjectModule/ProjectModuleHeader.web";
import Navbar from "../../../components/src/NavBar.web";
import ProjectDetailsView from "./Components/ProjectModule/ProjectDetails.web";
import TechnicalSummaryView from "./Components/ProjectModule/TechnicalSummary.web";
import Certificates from "./Components/ProjectModule/Certificates.web";
import CustomButton from "../../../components/src/CustomButton.web";
import { editPageButtonSvg } from "../../PeopleModule/src/assets";
import { ScrollDiv, projectModuleCommonStyle, requestIcon } from "./assets";
import ProjectGalleryView from "./Components/ProjectModule/ProjectGallery.web";
import Audit from "../../audittrail2/src/Audittrail.web";
import ResponsiveTopBar from "../../../components/src/TopBar/ResponsiveTopBar.web";
import { AccessControl } from "../../../components/src/Permission";
import { DisableEditButton } from "../../../components/src/DisableEditButton";
import Connection from "../../DeepLinking/src/Connection.web";
import { bindingCloseEvent } from "../../../components/src/Utils";


export default class ProjectModule extends ProjectModuleController {
  constructor(props: Props) {
    super(props);
  }
   handleHeight = () => {
    return (this.state.activeSection !== configJSON.sections[0] && this.state.activeSection !== configJSON.sections[5]) ? "100vh" : "auto"
  }

  listComponents = () => {
    const {
      isPendingApi,
      isProfileLoading,
      activeSection,
      hasError,
      projectSectors,
      projectServices,
      projectClients,
      projectCountries,
      projectGroupCompanies,
      projectServiceTypes,
      projectTypesOfCurrency,
      projectValuesOfWork,
      errors,
      projectId,
      mode,
      project_name,
      project_code,
      project_title,
      joint_venture,
      joint_venture_share,
      group_company,
      country,
      client,
      client_name,
      services,
      sector,
      type_of_currency,
      currency_value,
      fee,
      value_of_work,
      service_type,
      project_status,
      start_date,
      end_date,
      technical_summary,
      certificates,
      onCertificateUpload,
      onImageUpload,
      gallery,
      certificatesToUpload,
      imagesToUpload,
      projectStatusOptions,
      value_of_work_range,
      value_of_work_usd
    } = this.state;

    const projectDetails = {
      project_name,
      project_code,
      project_title,
      joint_venture,
      joint_venture_share,
      group_company,
      country,
      client,
      client_name,
      services,
      sector,
      type_of_currency,
      currency_value,
      fee,
      value_of_work,
      value_of_work_range,
      value_of_work_usd,
      service_type,
      project_status,
      start_date,
      end_date,
    };

    switch (activeSection) {
      case configJSON.sections[0]: {
        return (
          <ProjectDetailsView
            mode={this.state.mode}
            testId={configJSON.projectDetailsTestId}
            projectDetails={projectDetails}
            projectSectors={projectSectors}
            projectServices={projectServices}
            projectClients={projectClients}
            projectCountries={projectCountries}
            projectGroupCompanies={projectGroupCompanies}
            projectServiceTypes={projectServiceTypes}
            projectTypesOfCurrency={projectTypesOfCurrency}
            projectValuesOfWork={projectValuesOfWork}
            projectStatusOptions={projectStatusOptions}
            errors={errors}
            onChange={this.handleChange}
            onRadioChange={this.handleRadioButtonChange}
          />
        );
      }
      case configJSON.sections[1]: {
        return (
          <TechnicalSummaryView
            mode={this.state.mode}
            errors={errors}
            testId={configJSON.technical_summaryTestId}
            technicalSummary={technical_summary}
            onChange={this.handleChange}
          />
        );
      }
      case configJSON.sections[2]: {
        return (
          <Certificates
            mode={this.state.mode}
            testId={configJSON.certificatesViewTestId}
            certificates={certificates}
            onChange={this.handleChange}
            onDeleteCertificate={this.handleDeleteCertificate}
            onCertificateUpload={onCertificateUpload}
            onFileChange={this.handleFileChange}
            openImage={this.openImage}
            certificatesToUpload={certificatesToUpload}
            removeStagedFile={this.removeStagedFile}
          />
        );
      }
      case configJSON.sections[3]: {
        return (
          <ProjectGalleryView
            mode={this.state.mode}
            testId={configJSON.projectGalleryViewTestId}
            gallery={gallery}
            onChange={this.handleChange}
            onDeleteCertificate={this.handleDeleteCertificate}
            onImageUpload={onImageUpload}
            onFileChange={this.handleFileChange}
            openImage={this.openImage}
            imagesToUpload={imagesToUpload}
            removeStagedFile={this.removeStagedFile}
          />
        );
      }
      case configJSON.sections[4]: {
        return this.renderConnection();
          
      }
      case configJSON.sections[5]: {
        return (
          <Audit
          isAuditTrail={this.state.isAuditTrail}
          navigation={undefined}
          id={""}
          peopleId={this.state.projectId}
          itemType={"project"}
        />
        );
      }
    }
  };


  renderConnection = () => {
    const {mode} = this.state;
    const projectId = this.props.navigation.getParam('project_id');                                    
    return (
      <Connection 
        schema={{
          partner: [
            {
              type: 'link',
              dataKey: 'company_name',
              label: 'Name',
              mapValue: (rowData) => (
                <a
                  target="_blank"
                  href={`/Partner/${rowData.partner_id}`}
                    style={{
                      lineHeight: "16px",
                      textDecoration: "underline",
                      fontWeight: 400,
                      display: 'inline-block',
                      fontFamily: "Source Sans Pro",
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      fontSize: "16px",
                      fontStyle: "normal",
                      color: "#0094A7",
                      width: '200px',
                    }}
                  >
                    {rowData.name}
                  </a>
              )
            },
            {
              dataKey: 'sector',
              label: 'Sector',
            },
            {
              dataKey: 'country',
              label: 'Country',
            },
            {
              label: 'Services',
              dataKey: 'services'
            },
            
            {
              label: 'Internal Rate',
              dataKey: 'internal_rate'
            },
            {
              label: 'JV Shares(%)',
              dataKey: 'jv_share'
            },
          ],
          supplier: [
            {
              dataKey: 'company_name',
              label: 'Name',
              type: 'link',
              mapValue: (rowData) => (
                <a
                    href={`/Supplier/${rowData.supplier_id}`}
                    target="_blank"
                    style={{
                      fontWeight: 400,
                      fontStyle: "normal",
                      color: "#0094A7",
                      whiteSpace: 'nowrap',
                      textDecoration: "underline",
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontFamily: "Source Sans Pro",
                      width: '200px',
                      fontSize: "16px",
                      lineHeight: "16px",
                      display: 'inline-block',
                    }}
                  >
                    {rowData.name}
                  </a>
              )
            },
            {
              label: 'Sector',
              dataKey: 'sector'
            },
            {
              label: 'Country',
              dataKey: 'country'
            },
            {
              label: 'Services',
              dataKey: 'services'
            },
            
            {
              label: 'Internal Rate',
              dataKey: 'internal_rate'
            }
          ],
          people: [
            {
              type: 'link',
              label: 'Name',
              dataKey: 'name',
              mapValue: (rowData) => (
                <a
                    href={`/People/${rowData.people_id}`}
                    target="_blank"
                    style={{
                      textDecoration: "underline",
                      fontFamily: "Source Sans Pro",
                      color: "#0094A7",
                      fontWeight: 400,
                      lineHeight: "16px",
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '200px',
                      display: 'inline-block',
                      textOverflow: 'ellipsis',
                      fontSize: "16px",
                      fontStyle: "normal",
                    }}
                  >
                    {rowData?.name}
                  </a>
              )
            },
            {
              label: 'Related to',
              dataKey: 'related_to',
              mapValue: (rowData) => {
                return rowData?.related_to?.map((related: any) => related.employe_status)?.join(",") || '---'
              }
            },
            {
              label: 'Unrelate',
              dataKey: 'unrelated',
              mapValue: (rowData) => {
                return rowData?.unrelated?.map((related: any) => related.employe_status)?.join(",") || '---'
              }
            },
          ],
          tender: [
            {
              
              mapValue: (rowData) => (
                <a
                  target="_blank"
                  href={`/Tender/${rowData.tender_id}`}
                    style={{
                      width: '200px',
                      color: "#0094A7",
                      textDecoration: "underline",
                      fontWeight: 400,
                      lineHeight: "16px",
                      display: 'inline-block',
                      fontSize: "16px",
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      fontFamily: "Source Sans Pro",
                      overflow: 'hidden',
                      fontStyle: "normal",
                    }}
                  >
                    {rowData.name}
                  </a>
              ),
              dataKey: 'name',
              type: 'link',
              label: 'Name'
            },
            {
              dataKey: 'group_company',
              label: 'Group company',
            },
            {
              label: 'Country',
              dataKey: 'country',
            },
            {
              dataKey: 'client',
              label: 'Client',
            },
            {
              dataKey: 'services',
              label: 'Services',
            }
          ],
        }}
        id={this.state.projectId}
        source="project"
        editMode = {projectId == 'add' && mode == 'post' ? true : false}
        targets={["supplier", "partner", "tender", "people"]}
        tabLabels={["Suppliers", "Partners", "Tenders", "People"]}
        disableJVShare={!this.state.joint_venture}
        maxJVShare={this.state.joint_venture_share || undefined}
        navigation={this.props.navigation}
        onSuccessSave={this.handleCBSubmitDocument}
        onEditCB={this.handleEditCB}
        testid="connection"
        handleRequestNavigation={this.handleRequestNavigation}
      />
    )
  }

  renderError = () => {
    const { hasError } = this.state
    if (hasError && typeof hasError === "string") {
        return (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={true}
            onClose={() => {
              this.handleError("");
            }}
            message={hasError}
            autoHideDuration={configJSON.errorMessageDuration}
            key="error-message"
          />
        )
    }
    return null
  }

  renderActionButton() {
    const {
      activeSection,
      mode,
    } = this.state;
    const webStyle = projectModuleCommonStyle;

    return (
      <Box
          style={{
            ...webStyle.formActionButtonStyles,
            display:
              mode === configJSON.viewRecordMode || activeSection == configJSON.sections[4]
                ? "none"
                : "flex",
          }}
        >
          {this.props.navigation.getParam("project_id") !== configJSON.addParam && <CustomButton
            data-test-id={configJSON.cancelButtonTestId}
            colorVariant='special'
            onClick={this.handleCancelButton}
          >
            Cancel
          </CustomButton>}
          <CustomButton 
          disabled={this.state.isPendingApi}
          data-test-id={configJSON.projectModuleSaveButtonTestId}
          onClick={this.handleSaveButton}>
            Save
          </CustomButton>
        </Box>
    )
  }
   

  render() {
    const {
      isPendingApi,
      isProfileLoading,
      activeSection,
      projectId,
      mode,
    } = this.state;
    const webStyle = projectModuleCommonStyle;
  
    return (
      <Grid container style={webStyle.grid1}>
        <Hidden smDown>
        <Grid item xs={12} sm={2}>
          <Box style={webStyle.sideBar}>
            <CustomList
              data-test-id={configJSON.sideBarTestId}
              activeSection={this.state.activeSection}
              sections={this.sections}
              handleListItemClick={this.handleListItemClick}
              disabled={projectId === "" || projectId === "add"}
            />
          </Box>
        </Grid>
        </Hidden>
          <Hidden mdUp>
        <Grid xs={12}>
          <ResponsiveTopBar navigation={this.props.navigation} title={"Projects"}>
              { ({ closeDrawer }) => 
               <CustomList
                  data-test-id={configJSON.sideBarTestId}
                  activeSection={this.state.activeSection}
                  sections={configJSON.sections}
                  handleListItemClick={bindingCloseEvent(this.handleListItemClick, closeDrawer)}
                  disabled={projectId === "" || projectId === "add"}
                />

              }
          </ResponsiveTopBar>
        </Grid>
          </Hidden>
        <Grid item xs={12} md={10} style={{ height: "100%", overflow: "hidden"}}>
          <Box style={{
            display: "flex",
            flexDirection: "column",
            padding: "12px 22px 0px",
            backgroundColor: "#f3f3f3",
            gap: "60px",
            height:'100%',
            minHeight: this.handleHeight()
          }}>
            <Hidden smDown>
              <Navbar navigation={this.props.navigation} selectedTab={2} />
            </Hidden>
            <Box style={{ position: "relative", backgroundColor: "#FFF" }}>
              <ProjectModuleHeader
                projectId={this.state.projectId}
                activeSection={this.state.activeSection}
                data-test-id={configJSON.projectModuleHeaderTestId}
                saveAsPDF={projectId !== ""}
                handleBackClick={this.handleGoBack}
              />

              {(isPendingApi || isProfileLoading) ? (
                <Box style={webStyle.loadingSpinner}>
                  <CircularProgress
                    color="inherit"
                    style={{ color: "black" }}
                    size={"5rem"}
                  />
                </Box>
              ) : (
                <ScrollDiv
                  data-test-id={configJSON.containerTestId}
                >
                  <Grid container style={webStyle.projectModuleContainer}>
                  {activeSection === "Audit trail" ? 
                        <Grid item xs={12}>
                       {this.listComponents()}
                        </Grid> : <>
                    <Grid item sm={activeSection != configJSON.sections[4] ? 11 : 12} xs={this.state.mode == 'get' && this.state.activeSection != "Connections" ? 9 : 12}>
                      {this.listComponents()}
                      {this.renderActionButton()}
                    </Grid>
                    {  activeSection != configJSON.sections[4] && 
                      <Grid item sm={1} style={{clear: 'both', overflow: 'visible'}} xs={3}>
                        <Box sx={webStyle.rightSideOfContainer}>
                          {mode == configJSON.viewRecordMode && (
                            <>
                              <Box sx={webStyle.changeEditMode}>
                                <AccessControl feature='update' module='Project'>
                                  <CustomButton
                                    data-test-id={configJSON.editButtonTestId}
                                    colorVariant="noBackground"
                                    startIcon={
                                      <img
                                        src={editPageButtonSvg}
                                        alt="Edit Page"
                                      />
                                    }
                                    onClick={this.handleEditButtonClick}
                                  >
                                    Edit
                                  </CustomButton>
                                </AccessControl>
                              </Box>
                              <Box sx={webStyle.requestButton} 
                                data-testid="projectRequestTestID"
                              onClick={this.handleRequestNavigation}
                              >
                                <CustomButton
                                  colorVariant="projectRequest"
                                  startIcon={<img src={requestIcon} alt="warn" 
                                  data-testid="projectRequestLabelTestID"
                                  />
                                }
                                >
                                Request
                                </CustomButton>
                              </Box>
                            </>
                          )}
                        </Box>
                      </Grid>
                    }
                    
                        </>}
                  </Grid>
                </ScrollDiv>
              )}

              {this.renderError()}
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

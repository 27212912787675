import {
  Box,
  Radio,
  RadioProps,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { Component } from "react";

interface CssRadioButtonProps extends RadioProps { }

type MyState = {};

export default class CustomRadioButton extends Component<
  CssRadioButtonProps,
  MyState
> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return <CssRadioButton {...this.props} />;
  }
}

const CssRadioButton = withStyles({
  root: {
    padding: 6,
    marginLeft: 3,
    '&.Mui-checked': {
      color: '#0094A7'
    },
    "&.MuiRadio-colorSecondary.Mui-checked": {
      color: "#0094A7",
    },
  },
})(Radio);

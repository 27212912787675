import React, { Component } from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import CustomSecondaryButton from '../../../../../components/src/CustomSecondaryButton.web';
import { IIdentification } from '../../PeopleModuleController.web';
import { editPageButtonSvg, projectModuleCommonStyle, requestIcon } from '../../assets';
import CustomTypographyWeb from '../../../../../components/src/CustomTypography.web';
import CustomButton from '../../../../../components/src/CustomButton.web';
import { AccessControl } from "../../../../../components/src/Permission"
import { DisableEditButton } from '../../../../../components/src/DisableEditButton';

type Props = {
	identification: IIdentification;
	handleSwitchMode: (section: string, mode: 'update' | 'read' | 'post') => void;
	handlePeopleRequestNavigation?: () => void;
};

type State = {};

export default class ReadIdentification extends Component<Props, State> {
	render() {
		const {
			diploma,
			driving_license,
			end_date,
			joining_date,
			local_expiry_date,
			local_id,
			passport_expiry_date,
			passport_number,
			visa_designation,
			year,
		} = this.props.identification;
		const webStyleIdentification = projectModuleCommonStyle;

		return (
			<Box
				sx={{ display: 'flex', flexDirection: 'column', marginBottom: '52px' }}>
				{/* TOP PART */}
				<Box
					style={{
						marginBottom: '15px',
					}}>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							flexDirection: 'row',
						}}>
						<CustomTypographyWeb
              isHeader
							data-testid={'PeopleModule-ReadPersonalData-Identification'}
							style={{
								marginBottom: '32px',
							}}>
							Identification
						</CustomTypographyWeb>
						<AccessControl feature='update' module='People'>
							<CustomSecondaryButton
								label="Edit"
								startIcon={<img src={editPageButtonSvg} alt="Edit Page" />}
								style={{
									color: '#0094A7',
									boxShadow: 'none',
									border: 'none',
									textDecoration: 'underline',
									backgroundColor: 'transparent',
								}}
								data-testId="btnSwitchToEdit"
								onClick={() =>
									this.props.handleSwitchMode('identification', 'update')
								}
							/>
						</AccessControl>
						
					</Box>

					{/* Visa designation */}

					<Grid container spacing={2} style={{ marginBottom: '32px' }}>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Box>
								<CustomTypographyWeb isLabel style={{marginBottom: '7px'}}>Visa designation:</CustomTypographyWeb>
								<CustomTypographyWeb isContent data-testId="visa-content">{visa_designation}</CustomTypographyWeb>
							</Box>
						</Grid>
					</Grid>
					{/* Join & End Date */}
					<Grid container spacing={2} style={{ marginBottom: '32px' }}>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Box>
								<CustomTypographyWeb isLabel style={{marginBottom: '7px'}}>Joining date:</CustomTypographyWeb>
								<CustomTypographyWeb isContent>{joining_date}</CustomTypographyWeb>
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Box>
								<CustomTypographyWeb isLabel style={{marginBottom: '7px'}}>End date:</CustomTypographyWeb>
								<CustomTypographyWeb isContent>{end_date}</CustomTypographyWeb>
							</Box>
						</Grid>
					</Grid>

					{/* Diploma & Year */}
					<Grid container spacing={2} style={{ marginBottom: '32px' }}>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Box>
								<CustomTypographyWeb isLabel style={{marginBottom: '7px'}}>Diploma:</CustomTypographyWeb>
								<CustomTypographyWeb isContent>{diploma}</CustomTypographyWeb>
							</Box>
						</Grid>
						<Grid item xs={12} sm={4} md={2} lg={1}>
							<Box>
								<CustomTypographyWeb isLabel style={{marginBottom: '7px'}}>Year:</CustomTypographyWeb>
								<CustomTypographyWeb isContent>{year}</CustomTypographyWeb>
							</Box>
						</Grid>
					</Grid>
				</Box>

				<Divider />

				<Box style={{ marginTop: '16px' }}>
					{/* Passport number */}
					<Grid container spacing={2} style={{ marginBottom: '32px' }}>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Box>
								<CustomTypographyWeb isLabel style={{marginBottom: '7px'}}>Passport number:</CustomTypographyWeb>
								<CustomTypographyWeb isContent data-testId="passportText">{passport_number}</CustomTypographyWeb>
							</Box>
						</Grid>
						{/* Passport Expiry */}
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Box>
								<CustomTypographyWeb isLabel style={{marginBottom: '7px'}}>Passport expiry date:</CustomTypographyWeb>
								<CustomTypographyWeb isContent>{passport_expiry_date}</CustomTypographyWeb>
							</Box>
						</Grid>
					</Grid>
					<Grid container spacing={2} style={{ marginBottom: '32px' }}>
						{/* Local ID Input */}
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Box>
								<CustomTypographyWeb isLabel style={{marginBottom: '7px'}}>Local ID:</CustomTypographyWeb>
								<CustomTypographyWeb isContent>{local_id}</CustomTypographyWeb>
							</Box>
						</Grid>
						{/* Local ID - Expiry Date */}
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Box>
								<CustomTypographyWeb isLabel style={{marginBottom: '7px'}}>Expiry date:</CustomTypographyWeb>
								<CustomTypographyWeb isContent>{local_expiry_date}</CustomTypographyWeb>
							</Box>
						</Grid>
					</Grid>
				</Box>

				<Divider />

				{/* Driving License*/}
				<Box style={{ marginTop: '16px' }}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<Box>
								<CustomTypographyWeb isLabel style={{marginBottom:'7px'}}>Driving license:</CustomTypographyWeb>
								<CustomTypographyWeb isContent>{driving_license}</CustomTypographyWeb>
							</Box>
						</Grid>
					</Grid>
				</Box>

				<Box sx={webStyleIdentification.requestButtonTender} id="identification-reqest">
					<CustomButton
						colorVariant="projectRequest"
						startIcon={<img src={requestIcon} alt="identification-warn" />}
						onClick={this.props.handlePeopleRequestNavigation}
					>
						Request
					</CustomButton>
				</Box>
			</Box>
		);
	}
}
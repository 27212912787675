import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";
import Alert from '@material-ui/lab/Alert';
import {
    importExportIconSvg
} from "./assets";
import CustomButton from '../../../components/src/CustomButton.web';
import PartnerImportDataController, {Props} from "./PartnerImportDataController";
import { AccessControl } from "../../../components/src/Permission"
import { Snackbar } from "@material-ui/core";

class PartnerExportData extends PartnerImportDataController {
    constructor(props: Props) {
        super(props);
    }

    renderExportView() {
        const { classes } = this.props;
        return (<>
            <div ref={this.state.customPaperRef} style={{ position: 'relative' }}>
                <CustomButton
                    aria-haspopup="true"
                    colorVariant="dropdownButton"
                    truncateSM = {true}
                    startIcon={<img src={importExportIconSvg} alt='import/export' />}
                    aria-controls="dropdown-menu"
                    data-testid="exportimportBtn"
                    onClick={this.handleExportButtonClick}
                >
                    Import & Export
                </CustomButton>
                {this.state.hasImportExportButtonClicked &&
                    <div
                        data-testId="export-menu"
                        onMouseLeave={this.handleLeaveMouse}
                        className={classes.customPaper}
                    >
                        <div
                            className={classes.importExportMenuItem}
                            data-testid="exportAsPDF"
                            onClick={this.exportPdfToken}
                        >
                            Export as PDF
                        </div>

                        <div
                            className={classes.importExportMenuItem}
                            data-testid="exportAsCSV"
                            onClick={this.exportCSVToken}
                        >
                            Export as CSV
                        </div>

                        <AccessControl
                            feature="import"
                            module="Partner"
                        >
                            <div
                                onClick={this.navigateImport}
                                data-testid="importAsCSV"
                                className={classes.importExportMenuItem}
                            >
                                Import as CSV
                            </div>
                        </AccessControl>
                        
                    </div>
                }
            </div>
        </>);
    }

    render() {
        return (<>
            {this.renderExportView()}
            {this.state.hasError && (
              <Snackbar
                data-testId="partnerSnackbar"
                open={!!this.state.hasError}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={5000}
                onClose={()=>this.setState({ hasError: ""})}
                style={{ border: '1px solid #4caf50', borderRadius: "4px" }}
              >
                <Alert data-testId="partnerSnackbarMsg" severity="success">
                  {this.state.hasError}
                </Alert>
              </Snackbar>
            )}
        </>);
    }
}

const styles = () =>
    createStyles({
        importExportMenuItem: {
            fontSize: '14px',
            lineHeight: '16px',
            color: '#0094A7',
            fontFamily: 'Source Sans Pro',
            width: '110px',
            cursor: 'pointer',
            transition: "background-color 0.3s",
            padding: '10px 20px',
            '&:hover': {
                backgroundColor: "#F0F0F0",
            },
            fontWeight: 400,
        },

        customPaper: {
            border: '0.5px solid #0094A7',
            borderRadius: '4px',
            marginTop: '4px',
            boxShadow: '-4px 4px 30px 0px rgba(0, 148, 167, 0.16)',
            zIndex: 5,
            position: "absolute",
            "@media (max-width: 600px)": {
                right: "0",
            },
            backgroundColor: '#FFF'
        },
 
        exportBox: {
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
        },
        progressBox:{
            marginRight: "10px"
        },
 
        exportProgressBar:{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            position:"absolute",
            left:0,
            bottom:0,
            top:0,
            right:0
        }
    });


export default withStyles(styles)(PartnerExportData);
export { PartnerExportData };

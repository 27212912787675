import React, { Component } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import CustomTypographyWeb from "../../../components/src/CustomTypography.web";
import { Brochure, S, configJSON } from "./SupplierModuleController.web";
import { deleteIcon, editPageButtonSvg, exampleCertificateImg, projectModuleCommonStyle, requestIcon } from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";
import ClickedDropDown from "../../../components/src/CustomFields/DropDownOptions.web";
import { ArrowUpward } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { AccessControl } from "../../../components/src/Permission";

interface Props {
  mode: string;
  brochure: Brochure[];
  testId: string;
  onChange: (changedPropName: keyof S, value: any) => void;
  onDeleteBrochure: (id: number, type: string) => void;
  onBrochureUpload: boolean;
  onFileChange: (event: any) => void;
  handleEditButtonClick: () => void;
  openImage: (imageUrl: string) => void;
  brochureToUpload: File[];
  removeStagedFile: (fileIndex: number) => void;
  module: "People" | "Project" | "Tender" | "Supplier" | "Partner";
  hasError?: string;
  supplierRequest?: () => void;
}

export default class SupplierBrochure extends Component<Props> {
  truncateText = (str: string, length: number = 15) => {
    if (str.length >= length) return str.slice(0, length) + "...";
    return str;
  };
  render() {
    const {
      mode,
      brochure,
      onBrochureUpload,
      brochureToUpload,
      testId,
      hasError,
      handleEditButtonClick
    } = this.props;
    const webStyle = projectModuleCommonStyle;

    const showButton =
      onBrochureUpload ||
      brochure.length === 0 ||
      brochureToUpload.length > 0;

    const uploadCertRef: React.RefObject<HTMLInputElement> = React.createRef();
    return (
      <Box sx={webStyle.containerWrapper} data-test-id={testId}>
        <Grid container style={webStyle.gridContainer}>
          <Grid item xs={12}>
            <Box sx={projectModuleCommonStyle.galleryContainer}>
              <Box sx={webStyle.topContainer}>
                   <Box>
                    <CustomTypographyWeb isHeader>
                             Brochure
                     </CustomTypographyWeb>
                    </Box>
                   {mode === configJSON.viewRecordMode && (
                       <>
                        <Box sx={webStyle.changeEditAwardLetterMode} style={{ right: 40 }}>
                          <AccessControl module={this.props.module} feature='update'> 
                          <CustomButton
                             data-test-id = {configJSON.editButtonTestId}
                             colorVariant = "noBackground"
                             startIcon = {  <img src={editPageButtonSvg} alt="Edit Page" />
                              }
                              onClick={handleEditButtonClick}
                            >
                              Edit
                            </CustomButton>
                          </AccessControl>
                          </Box>
                         </> 
                            )}
                     </Box>
                    {mode !== configJSON.viewRecordMode && showButton ? (
                       <>
                      <Box
                        style = {{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                    }}
                  >
                    {brochureToUpload.length > 0 &&
                      brochureToUpload.map(
                        (Brochure: File, index: number) => (
                          <Box
                            key={index}
                            data-test-id={`${configJSON.awardLetterToUploadTestId}-${index}`}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "8px",
                            }}
                          >
                            <Typography
                              data-test-id={`label-${configJSON.awardLetterToUploadTestId}-${index}`}
                              style={{
                                color: "#0094A7",
                                fontFamily: "Source Sans Pro",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "16px",
                              }}
                            >
                                 {this.truncateText(Brochure.name, 30)}
                                  </Typography>
                                <Button
                                   data-test-id = {`removebutton-${configJSON.awardLetterToUploadTestId}-${index}`}
                                   style = {{ marginLeft: "8px" }}
                                   onClick = {() => this.props.removeStagedFile(index)}
                                   size = "small"
                                  >
                                    <CloseIcon />
                                 </Button>
                               </Box>
                              ))}
                           </Box>
                            <input
                              data-test-id = {
                                  configJSON.tenderAwardLetterUploadFileInputTestId
                                 }
                              type="file"
                              hidden
                              onChange={this.props.onFileChange}
                              ref={uploadCertRef}
                              accept=".jpg, .jpeg, .pdf"
                             />
                           <CustomButton
                             data-test-id={
                              configJSON.tenderAwardLetterUploadButtonTestId
                                 }
                            startIcon = {<ArrowUpward />}
                            style={projectModuleCommonStyle.imageUpload}
                            onClick={() => uploadCertRef.current?.click()}
                               >
                               Upload
                              </CustomButton>
                             
                              { hasError &&
                                <Typography style={{fontSize:12, color: "red", marginTop: 5}}>{hasError}</Typography>
                              }
                             <Typography
                               style={projectModuleCommonStyle.imageDocumentInfo}
                              >
                              Note: <br />
                              Supported files: .PDF, JPG, JPEG <br />
                              Max size: 10MB
                            </Typography>
                           </>
                         ) : (
                       <Grid container>
                       <Grid item xs = {10}>
                          <Box style={{ display: "flex" , flexWrap: "wrap",  gap: "25px"  }}
                            >
                         {brochure.length > 0 &&
                           brochure.map(
                              (Brochure: Brochure, index: number) => {
                               return (
                              <Box
                                data-test-id={`${configJSON.tenderAwardLetterTestId}-${index}`}
                                key={Brochure.file_id}
                                sx={projectModuleCommonStyle.certificateCard}
                              >
                                <Box
                                  data-test-id={`AwardLetters-${configJSON.tenderAwardLetterTestId}-${index}`}
                                  style={{
                                    ...projectModuleCommonStyle.imageWithBackground,
                                    backgroundImage: `url(${Brochure.file_name.includes(
                                      "pdf"
                                    )
                                      ? exampleCertificateImg
                                      : Brochure.file_link
                                      })`,
                                  }}
                                  onClick={() => {
                                    this.props.openImage(
                                      Brochure.file_link
                                    );
                                  }}
                                />
                                <Box
                                  style={
                                    projectModuleCommonStyle.certificateCardLabel
                                  }
                                >
                                  <Box
                                    style={{
                                      flexGrow: 1,
                                      textAlign: "center",
                                    }}
                                  >
                                    <CustomTypographyWeb
                                      isLabel
                                      data-test-id={`${configJSON.tenderAwardLetterDocumentName}-${index}`}
                                    >
                                      {this.truncateText(
                                        Brochure.file_name
                                      )}
                                    </CustomTypographyWeb>
                                  </Box>
                                {mode !== configJSON.viewRecordMode && (
                                        <ClickedDropDown
                                          data-test-id={`AwardLetters-remove-${configJSON.tenderAwardLetterTestId}-${index}`}
                                           options={[
                                             {
                                              label: "Delete",
                                              icon: deleteIcon,
                                              onClick: () => {
                                                this.props.onDeleteBrochure(
                                                  Brochure.id,
                                                  "brochure"
                                                 );
                                              },
                                            }
                                          ]}
                                        />
                                       )}
                                    </Box>
                                  </Box>
                                 );
                              }
                            )}
                           </Box>
                         {mode !== configJSON.viewRecordMode &&
                             brochure.length !== 0 && (
                              <CustomButton
                              data-test-id={
                               configJSON.tenderAwardLetterAddMoreButtonTestId
                              }
                              colorVariant = "special"
                              style={projectModuleCommonStyle.addMoreButton}
                              onClick={() =>
                              this.props.onChange("onBrochureUpload", true)
                               }
                              >
                              Add more
                              </CustomButton>
                            )}   
                         </Grid>
                      </Grid>
                       )}
                      <Box sx={webStyle.rightSideOfContainer}>
                     {mode === configJSON.viewRecordMode && (
                          <Box sx={webStyle.requestAwardLetterButton}>
                        <CustomButton
                         colorVariant="projectRequest"
                          startIcon={<img src={requestIcon} alt="warn" />}
                          onClick={this.props.supplierRequest}
                         >
                           Request
                         </CustomButton>
                        </Box>
                       )}
                       </Box>
                     </Box>
                 </Grid>
               </Grid>
             </Box>
            );
          }
         }

import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import DocumentPicker from 'react-native-document-picker';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  SelectNumber: string,
  Data: (string | number)[],
  pages: number,
  totalPage: number[],
  PriviousPage: number,
  NextPage: number,
  CurrentPage: number,
  TotalItem: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}


export default class PaginationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  PushdocumentID: string = "";
  GetdocumentId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceDataMessage),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      SelectNumber: '',
      Data: [],
      pages: 1,
      totalPage: [],
      PriviousPage: 0,
      NextPage: 10,
      CurrentPage: 0,
      TotalItem: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.PushdocumentID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      this.createNotesSuccessCallBack(apiResponse)
    }
    if (this.GetdocumentId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      this.getDocSuccess(apiResponse);
    }
    // Customizable Area End
  }

  async componentDidMount(): Promise<void> {
    this.GetDocument(this.state.pages)
  }



  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;


  handleDocumentProp = {
    onPress: () => {
      this.handleDocumentSelection()
    }
  }

  textInputNumberValue = {
    onChangeText: (text: string) => {
      this.setState({ SelectNumber: text })
    }
  }

  HandlePerviousPage = {
    onPress: () => {
      this.GetDocument(this.state.PriviousPage)
    }
  }

  handleNextPage = {
    onPress: () => {
      this.GetDocument(this.state.NextPage)
    }
  }


  SubmitDocBtn = {
    onPress: () => {
      this.setState({ enableField: false }, () => {
        this.GetDocument(this.state.pages)
      })
    }
  }

  HandleModalProps = {
    onPress: () => {
      this.setState({ enableField: true })
    }
  }

  handleDocumentSelection = async () => {
    try {
      const response = await DocumentPicker.pick({
        presentationStyle: 'fullScreen',
        type: [DocumentPicker.types.allFiles],
        allowMultiSelection: true
      });
      const Result = { ...response[0], name: response[0].name || "no-name-document" };
      this.Upload_Document(Result)
    } catch (error) {

    }
  };



  Upload_Document(result: { uri: string; name: string; copyError?: string; fileCopyUri?: string | null; size?: number | null; }) {
    const header = {
      "Content-Type": "multipart/form-data",
      token: "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NCwiZXhwIjoxNjc1NjA1NzIzfQ.bOycOneJ13Y9SliH6mvwlRJtZuUQQ1ryuEUg_UbCs16iZc1KZhf40g6_A66dk_6_F2opqIjtPqW8soi-lNDd4Q",
    };
    const CreatPostCallID = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PushdocumentID = CreatPostCallID.messageId;
    CreatPostCallID.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_pagination/bx_block_pagination_products",
    );
    CreatPostCallID.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    CreatPostCallID.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    let Name: string = result?.name
    let link: string = result?.uri
    let formdata = new FormData();
 
    let file = {
      uri: link,
      type: 'image/jpg',
      name: Name
    }
    formdata.append("[data][title]", Name);
    formdata.append("[data][image]", JSON.stringify(file));

    CreatPostCallID.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );    
    runEngine.sendMessage(CreatPostCallID.id, CreatPostCallID);
  }


  createNotesSuccessCallBack = async (result: {}) => {
    this.GetDocument(this.state.pages)
  };


  GetDocument(page: number) {
    const headers = {
      token: "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NCwiZXhwIjoxNjc1NjA1NzIzfQ.bOycOneJ13Y9SliH6mvwlRJtZuUQQ1ryuEUg_UbCs16iZc1KZhf40g6_A66dk_6_F2opqIjtPqW8soi-lNDd4Q",
    };
    const GetDocumentID = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetdocumentId = GetDocumentID.messageId;
    GetDocumentID.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_pagination/bx_block_pagination_products?page=${page == null ? this.state.pages : page}&number_of_items=${this.state.SelectNumber}`
    );
    GetDocumentID.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    GetDocumentID.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(GetDocumentID.id, GetDocumentID);
  }

  getDocSuccess = async (data: {
    total_items: string; total_page: number; data: { data: (string | number)[]; }; previous_page: number; next_page: number; current_page: number;
  }) => {
    let tempData = []
    for (let item = 1; item <= data?.total_page; item++) {
      tempData.push(item)
    }
    this.setState({
      Data: data?.data.data,
      totalPage: tempData,
      PriviousPage: data?.previous_page,
      NextPage: data?.next_page,
      CurrentPage: data?.current_page,
      TotalItem: data?.total_items
    })
  }


  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };


  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  // Customizable Area End
}

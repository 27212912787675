import { Route, Switch, Redirect } from "react-router-dom";
import { ModalRoute } from "react-router-modal";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "react-router-modal/css/react-router-modal.css";
import WebAppLayout from "../WebAppLayout/WebAppLayout.web";
import { AdvancedFilterContextProvider } from '../ContextAPI/AdvancedFilterContext.web'
import TopBar from "../TopBar/TopBar.web";
import ResponsiveTopBar from "../TopBar/ResponsiveTopBar.web";
import { logoutUser } from "../Utils";
import { Hidden, styled } from "@material-ui/core";
import { SessionGate  } from "./SessionGate"

const Root = styled('main')(({ theme }) => ({
  paddingTop: 65,
  [theme.breakpoints.down('sm')]: {
    paddingTop: "0!important",
  },
}));

function Wrapper({ element, history, match, routeMap, closeModal, module }) {
  const navigate = (to, params) => {
    let url = routeMap[to].path;
    // replace params ids in the url with actual values
    if (params && Object.keys(params).length > 0) {
      Object.keys(params).forEach(param => {
        const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
        url = url.replace(re, escape(params[param]));
      });
    }
    // removing empty params from url - every string between /: and ?
    url = url.replace(/\/:(.*?)(?=\/|$)/g, "");
    // if the route is not a modal
    if (!routeMap[to].modal) {
      history.push(url);
      // if the route is a modal
    } else {
      // checking if the url ends with a slash or not
      const slash = /\/$/.test(match.url) ? "" : "/";
      // current url in the browser + slash + modal url with parameters
      url = match.url + slash + url;
      // removing the */ from the url
      url = url.replace(/\*\/?/g, "");
      history.push(url);
    }
  };

  const getParam = (param, alternative) => {
    return match.params[param] || alternative;
  };

  const goBack = () => {
    history.goBack();
  };

  const push = (path) => {
    history.push(path)
  }

  const navigation = {
    navigate, getParam, goBack, push, history, module
  }

  useEffect(() => {
    const handleLogout = (event) => {
      if (event.key === 'authToken' && event.newValue === null) {
        logoutUser({ navigation })
      }
    }

    window.addEventListener('storage', handleLogout);

    return () => window.removeEventListener('storage', handleLogout)
  })

  const isLoggedIn = window !== 'undefined'
    ? localStorage.getItem('authToken')
    : true;

  const rententionPaths = [
    "/CV_retention"
  ]
  
 
  console.log("navigation: ", match.path)
  // path: /Module/<<activeModule>>
  // const module = match.path.split('/')[2]

  if (match.path === '/ImportData' || 
      match.path === "/ProjectImportData" || 
      match.path === "/TenderImportData"  ||
      match.path === "/SuppliersImportData" || 
      match.path === "/PartnerImportData"
    ) {
    return (
      <Root>
        <Hidden smDown>
          <TopBar navigation={navigation} />
        </Hidden>
        <WebAppLayout navigation={{ navigate, getParam, goBack, push, module }}>
          {React.cloneElement(element, {
            navigation: navigation,
            closeModal
          })
          }
        </WebAppLayout>
      </Root>
    )
  }
  
  return (
    <Root style={{ paddingTop: isLoggedIn && !rententionPaths.includes(match.path)  ? '65px' : 0 }}>
      {isLoggedIn && !rententionPaths.includes(match.path) && <Hidden smDown><TopBar navigation={navigation}/></Hidden>}
      {React.cloneElement(element, {
        navigation: navigation,
        closeModal
      })}
    </Root>
  )
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object
};

const WebRoutesGenerator = ({ loginRoutes, loggedRoutes, }) => {
  const routeMap = { ...loginRoutes, ...loggedRoutes }

  const isLoggedIn = window !== 'undefined'
    ? localStorage.getItem('authToken')
    : true

  const routes = isLoggedIn ? loggedRoutes : loginRoutes

  const defaultRoute = routes[Object.keys(routes)[0]]

  return (
    <>
      <Switch>
        {Object.keys(routes).map(route => {
          const currentRoute = routes[route];
          const Component = currentRoute.component;
          const module = currentRoute?.module || null
          if (!currentRoute.modal) {
            return (
              <Route
                key={currentRoute.path}
                path={currentRoute.path}
                exact={currentRoute.exact}
                render={props => (
                  <SessionGate isLoggedIn = {isLoggedIn} navigation={props}>
                    <Wrapper element={<Component />} {...props} routeMap={routeMap} module={module}/>
                  </SessionGate>
                )}
              />
            );
          }
        })}
        <Redirect
          from='*' to={defaultRoute.path}
        />
      </Switch>
      {Object.keys(routes).map(route => {
        const currentRoute = routes[route];
        const Component = currentRoute.component;
        if (currentRoute.modal) {
          return (
            <ModalRoute
              key={currentRoute.path}
              path={currentRoute.path}
              component={props => (
                <SessionGate isLoggedIn = {isLoggedIn} navigation={props}>
                  <Wrapper element={<Component />} {...props} routeMap={routeMap} />
                </SessionGate> 
              )}
            />
          );
        }
      })}
    </>
  )
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object
};

export default WebRoutesGenerator;

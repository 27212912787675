import React, { Component } from "react";
import { Typography, TextField, Box, Grid } from "@material-ui/core";
import { ArrowUpward } from "@material-ui/icons";
import CustomFileUploadButton from "../../../../../components/src/CustomFileUploadButton.web";
import { Documents, S } from "../../PeopleModuleController.web";
import CustomButton from "../../../../../components/src/CustomButton.web";

type State = {
  selectedFile: File | null;
  documentError: string;
  // label: string;
};

type Props = {
  documents: Documents;
  onChange?: (objectKey: keyof S, property: any, value: any | null) => void;
  state?: keyof S;
  fileProp?: keyof Documents;
  handleBackButton?: () => void;
  handleSubmitButton?: () => void;
  handleInputChange?: (event: any) => void;
  handleFileChange: (documentType: string, file: File | null) =>void;
  showButton?: boolean;
  handleError?: (errorMessage:string) => void;
  label: string;
};

export default class OtherDocuments extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedFile: null,
      documentError: ""
    };
  }
  
  handleErrorOtherDocs = (errorMsg: string) => {
    this.setState({documentError: errorMsg})

  }

  render() {
    const disableUpload = this.props.documents.otherDocumentFileName?.length == 0;
    return (
      <Grid container>
      <Grid item xs={12} sm={12} md={10}>
      <Box marginBottom={6}>
        <Typography
          style={{
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "16px",
            fontFamily: "Source Sans Pro",
            fontSize: "16px",
          }}
          data-testid={"PeopleModule-OtherDocuments"}
        >
          {this.props.label}
        </Typography>

        <Box
          style={{
            marginTop: "8px",
          }}
        >
          {(this.props.documents.addDocument || this.props.documents.documents) && (
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "16px",
                  }}
                >
                  <TextField
                    label="Enter Document Name"
                    InputLabelProps={{
                      style: {
                        textOverflow: 'ellipsis',
                        width: '90%'
                      }
                    }}
                    value={this.props.documents.otherDocumentFileName}
                    onChange={this.props.handleInputChange}
                    data-testId="inputOtherFileName"
                    variant="outlined"
                    style={{
                      marginTop: '10px',
                    }}
                  />
                  <CustomFileUploadButton
                    borderDashed
                    state={"documents"}
                    disabled={disableUpload}
                    fileProp={"otherDocumentUpload"}
                    documentType='other'
                    onFileChange={this.props.handleFileChange}
                    file={this.props.documents.otherDocumentUpload}
                    otherDocumentUpload={
                      !!this.props.documents.otherDocumentUpload
                    }
                    label="Upload"
                    handleErrorOtherDocs={this.handleErrorOtherDocs}
                    labelStyle={{
                      color: "#0094A7",
                      fontWeight: 600,
                      lineHeight: "16px",
                      fontFamily: "Source Sans Pro",
                      fontSize: "16px",
                      fontStyle: "normal",
                    }}
                    handleError={this.props.handleError}
                  >
                    <ArrowUpward />
                    Upload
                  </CustomFileUploadButton>
                </Box>
              </Grid>
              {this.state.documentError && <Typography style={{fontSize:12, color: "red", marginTop: 5}}>{this.state.documentError}</Typography>}
            </Grid>
          )}
        </Box>
        <Typography
          style={{
            fontFamily: "Source Sans Pro",
            fontSize: "14px",
            lineHeight: "22px",
            fontStyle: "normal",
            fontWeight: 400,
            color: "#9F9F9F",
            marginTop: '18px'
          }}
        >
          Note: <br />
          Supported files: PDF, CSV <br />
          Max size: 10MB
        </Typography>

        {this.props.showButton && (
          <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          style={{
            gap: "20px",
            marginTop: "72px",
          }}
        >
          <CustomButton
            colorVariant="special"
            onClick={() => {
              this.props.handleBackButton?.();
            }}
          >
            Cancel
          </CustomButton>
          <CustomButton 
            data-testId="otherSaveBtn"
            onClick={this.props.handleSubmitButton}
            disabled = {!this.props.documents.otherDocumentFileName || !this.props.documents.otherDocumentUpload}
            >
            Save
          </CustomButton>
        </Box>
        )}
      </Box>
      </Grid>
      </Grid>
    );
  }
}

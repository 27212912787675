import React from "react";

// Customizable Area Start
import {
  Box,
  Snackbar,
  Button,
  FormControlLabel,
  CircularProgress,
  MuiThemeProvider,
  Grid,
  Hidden,
} from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  createTheme,
} from "@material-ui/core/styles";
import ProjectModuleTableController, {
  Props,
  configJSON,
} from "./ProjectModuleTableController.web";
import { projectModuleCommonStyle } from "../../multipageforms/src/assets";
import NoRecordsFoundPage from "../../../components/src/Table/NoRecordsFoundPage.web";
import TableData from "../../../components/src/Table/Table.web";
import CustomButton from "../../../components/src/CustomButton.web";
import {
  CustomSaveSearchInput,
  SaveSearchCheckBox,
  notifyCheckedIcon,
  overlayStyle,
  projectModuleTableCommonStyle,
  saveSearchModal,
} from "./assets";
import Navbar from "../../../components/src/NavBar.web";
import ProjectModuleSearch from "../../advancedsearch/src/ProjectModuleSearch.web";
import { SaveRounded } from "@material-ui/icons";
import CustomTypographyWeb from "../../../components/src/CustomTypography.web";
import ProjectExportData from "../../importexportdata/src/ProjectExportData.web";
import { saveUncheckIcon } from "../../filteritems/src/assets";
import ResponsiveTopBar from "../../../components/src/TopBar/ResponsiveTopBar.web";
import AllFilter from "../../filteritems/src/AllFilter.web";
import { AccessControl } from "../../../components/src/Permission";

const styles = (theme: Theme) =>
  createStyles({
    earch: {
      position: "relative",
      borderRadius: "8px",
      backgroundColor: "white",
      "& fieldset": {
        border: "1px solid #E8E8E8 !important",
      },
      "&:hover": {
        backgroundColor: "white",
      },
      marginLeft: 0,
      width: "40%",
      "& .MuiOutlinedInput-adornedStart": {},
      "& .MuiOutlinedInput-input": {
        padding: "14px",
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "8px",
      },
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: "0px",
      },
    },
    searchText: {
      maxWidth: "fit-content",
      fontFamily: "Source Sans Pro",
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "16px",
      color: "#000",
      [theme.breakpoints.down("sm")]: {
        fontSize: "17px",
      },
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
    addButton: {
      width: "100px",
      border: "1.5px solid #01949A",
      color: "#01949A",
      textTransform: "none",
      borderRadius: "8px",
      height: 45,
      "@media (max-width: 960px)": {
        maxWidth: 50,
        minWidth: 50,
        fontSize: 12,
        height: 38,
      },
    },
    buttonContainer: {
      gap: "16px",
      "@media (max-width: 573px)": {
        margin: "5px 0px 5px auto",
      },
      display: "flex",
      "@media (max-width: 960px)": {
        gap: "7px",
      },
    },
    buttonMainContiner: {
      padding: "0 20px",
      "@media (max-width: 573px)": {
        height: "auto",
        flexWrap: "wrap",
      },
      height: "60px",
      "@media (max-width: 960px)": {
        padding: "0 7px",
      },
    },
    mainContainer: {
      position: "relative",
      display: "flex",
    },
    mainChildWrapper: {
      marginTop: "85px",
      transition: "width 0.4s ease-out",
      minHeight: "100vh",
    },
    expandSidebarIconBox: {
      position: "fixed",
      top: "127px",
      transition: "left 0.4s ease-out",
      width: "20px",
      height: "20px",
      borderRadius: "8px",
      padding: "5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgb(253, 215, 47)",
      zIndex: 1,
      cursor: "pointer",
    },
    sidenav: {
      width: "250px",
      transition: "width 0.3s ease-in-out",
      backgroundColor: "rgb(10, 25, 41)",
      paddingTop: "28px",
    },
    sidenavClosed: {
      composes: "sidenav",
      transition: "width 0.3s ease-in-out",
      width: "60px",
    },
    sideitem: {
      display: "flex",
      alignItems: "center",
      padding: "10px 20px",
      cursor: "pointer",
      color: "#b2bac2",
      textDecoration: "none",
      overflow: "hidden",
    },
    linkText: {
      paddingLeft: "16px",
    },

    "& .sideitem:hover": {
      backgroundColor: "#244f7d1c",
    },
    menuBtn: {
      alignSelf: "center",
      justifySelf: "flex-end",
      color: "#b2bac2",
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      paddingLeft: "20px",
      position: "relative",
      top: "124px",
      left: "40px",
      zIndex: 1000000,
      boxShadow: "3px",
      borderRadius: "10px",
      transition: "width 0.3s ease-in-out",
    },
    menuBtnExpand: {
      alignSelf: "center",
      justifySelf: "flex-end",
      color: "#b2bac2",
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      paddingLeft: "20px",
      position: "relative",
      top: "124px",
      left: "230px",
      transition: "width 0.3s ease-in-out",
      zIndex: 1000000,
    },
    importExportMenuItem: {
      padding: "10px 20px",
      color: "#0094A7",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "16px",
      fontFamily: "Source Sans Pro",
      cursor: "pointer",
      transition: "background-color 0.3s",
      width: "110px",
    },
    customPaper: {
      marginTop: "4px",
      borderRadius: "4px",
      border: "0.5px solid #0094A7",
      boxShadow: "-4px 4px 30px 0px rgba(0, 148, 167, 0.16)",
      backgroundColor: "#FFF",
      position: "absolute",
      zIndex: 5,
    },
    projectfirstgridbox: {
      display: "flex",
      flexDirection: "column",
      position: "relative",
      height: "100%",
    },
    lastChildContainer: {},
    projectmodulebox: {
      fontFamily: "Source Sans Pro",
      display: "flex",
      backgroundColor: "#F3F3F3",
      width: "100%",
      height: "calc(100vh - 65px)",
      overflow: "hidden",
      "& .MuiGrid-container": {
        "& .MuiGrid-item:last-child": {
          overflowX: "hidden",
          height: "calc(100vh - 65px)",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        },
      },
    },
    searchResultPeopleContainer: {
      "@media (max-width: 573px)": {
        justifyContent: "space-between",
      },
      display: "flex",
      "@media (max-width: 513px)": {
        gap: "13px",
      },
      gap: "20px",
      "@media (max-width: 482px)": {
        gap: "5px",
        marginRight: "0px",
      },
      alignItems: "center",
      marginRight: "14px",
      "@media (max-width: 453px)": {
        gap: "0px",
      },
    },
  });

const THEME = createTheme({
  typography: {
    fontFamily: "Source Sans Pro",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
  },
});
// Customizable Area End
class ProjectModuleTable extends ProjectModuleTableController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderNotifyMeCheckBox = () => {
    const { isProjectSearchSaved, isShowProjectSaveSearchLabel } = this.state;
    return (
      <>
        {isProjectSearchSaved && isShowProjectSaveSearchLabel === undefined && (
          <FormControlLabel
            data-test-id={"notifyMe"}
            style={{ margin: 0 }}
            control={
              <SaveSearchCheckBox
                data-test-id={"notifyMe"}
                icon={<img src={saveUncheckIcon} alt="" />}
                checkedIcon={<img src={notifyCheckedIcon} alt="" />}
                name="notifyMe-projectmodule"
                checked={true}
                value={true}
              />
            }
            disabled={true}
            label={
              <span
                style={{
                  fontFamily: "Source Sans Pro",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "16px",
                  color: "#2B3132",
                }}
              >
                Notify me for new entries
              </span>
            }
          />
        )}
      </>
    );
  };

  renderSaveSearchCheckBox = () => {
    const { filtersObj, isProjectSearchSaved, isShowProjectSaveSearchLabel } =
      this.state;
    const availableProjectSaveSearch = Object.values(filtersObj).some(
      (array: any) => array.length > 0
    );
    return (
      <>
        {availableProjectSaveSearch &&
          isShowProjectSaveSearchLabel === undefined && (
            <FormControlLabel
              data-test-id={configJSON.projectModuleSaveSearchFormTestId}
              control={
                <SaveSearchCheckBox
                  data-test-id={configJSON.projectModuleSaveSearchCheckBox}
                  onChange={this.onSaveProjectSearch}
                  icon={<img src={saveUncheckIcon} alt="" />}
                  checkedIcon={<SaveRounded />}
                  name="savedSearch-projectmodule"
                  checked={isProjectSearchSaved}
                  value={isProjectSearchSaved}
                />
              }
              style={{ margin: 0 }}
              disabled={isProjectSearchSaved}
              label={
                <span
                  style={{
                    fontFamily: "Source Sans Pro",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "16px",
                    color: "#2B3132",
                  }}
                >
                  Save search
                </span>
              }
            />
          )}
        {this.renderNotifyMeCheckBox()}
      </>
    );
  };

  renderProjectChild = () => {
    const { filtersObj, isShowProjectSaveSearchLabel } = this.state;
    const { classes } = this.props;
    return (
      <Box
        data-test-id={configJSON.projectModuleTableMainViewTestId}
        sx={projectModuleTableCommonStyle.mainWrapper}
      >
        <div>
          <ProjectModuleSearch
            id={"AdvancedSearch"}
            navigation={this.props.navigation}
            getProjectsResponse={this.getProjectsResponse}
            getProjectsRequest={this.getProjectsRequest}
            filterList={filtersObj}
            type={"Project"}
          />
        </div>
        {this.state.isLoading ? (
          <Box style={projectModuleCommonStyle.loadingSpinner}>
            <CircularProgress color="inherit" size={"5rem"} />
          </Box>
        ) : (
          <Box style={{ position: "relative", backgroundColor: "#FFF" }}>
            <div
              style={{
                display: this.state.isLoading ? "none" : "flex",
                background: "white",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className={this.props.classes.buttonMainContiner}
            >
              <div className={classes.searchResultPeopleContainer}>
                <span
                  data-test-id="searchResultsText"
                  className={this.props.classes.searchText}
                >
                  {isShowProjectSaveSearchLabel === true
                    ? "Saved Results"
                    : "Search Results"}
                </span>
                {this.renderSaveSearchCheckBox()}
                {this.state.isSavingSearch && (
                  <div style={overlayStyle}>
                    <div style={saveSearchModal}>
                      <Box
                        sx={projectModuleTableCommonStyle.saveSearchBox}
                        data-test-id={
                          configJSON.projectModuleSaveSearchBoxTestId
                        }
                      >
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px",
                          }}
                        >
                          <CustomTypographyWeb
                            style={{
                              fontSize: "20px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "16px",
                            }}
                          >
                            Save this search result
                          </CustomTypographyWeb>

                          <CustomSaveSearchInput
                            data-test-id={
                              configJSON.projectModuleSaveSearchInputTestId
                            }
                            fullWidth
                            placeholder="Enter name to this search for future"
                            onChange={this.onChangeSavedSeachName}
                            value={this.state.savingSearchProjectName}
                            variant="outlined"
                            error={this.state.onSaveSearchError}
                            helperText={
                              this.state.onSaveSearchError
                                ? this.state.onSaveSearchErrorMessage
                                : null
                            }
                          />
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              gap: "12px",
                            }}
                          >
                            <CustomButton
                              data-test-id={
                                configJSON.projectModuleSaveSearchCancelButtonTestId
                              }
                              colorVariant="special"
                              width={107}
                              height={45}
                              onClick={() => {
                                this.cancelSavingProjectSearch();
                              }}
                            >
                              Cancel
                            </CustomButton>
                            <CustomButton
                              data-test-id={
                                configJSON.projectModuleSaveSearchSubmitButtonTestId
                              }
                              width={107}
                              height={45}
                              onClick={() => {
                                this.saveProjectSearchRequest();
                              }}
                            >
                              Save
                            </CustomButton>
                          </Box>
                        </Box>
                      </Box>
                    </div>
                  </div>
                )}
              </div>
              <div className={this.props.classes.buttonContainer}>
                <AccessControl module="Project" feature="create">
                  <Button
                    onClick={this.handleAddButton}
                    className={this.props.classes.addButton}
                  >
                    + Add
                  </Button>
                </AccessControl>

                <div>
                  <ProjectExportData
                    innerRef={this.refExportBox}
                    navigation={this.props.navigation}
                    id={""}
                    testid={"projectexport"}
                    classes={{}}
                    count={this.state.paginationDetails.totalLength}
                    searchIds={this.state.searchIds}
                  />
                </div>
              </div>
            </div>
            {this.state.tableInputs.apiData.length > 0 ? (
              <TableData
                navigation={this.props.navigation}
                tableLink="Projects"
                data-testid="containerTable"
                pagination={this.state.paginationDetails}
                handlePageChange={this.handleChangePage}
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                onFavIconClick={(index: number) =>
                  this.handleFavIconClicked(index)
                }
                handleSort={(column, sortOrder) =>
                  this.handleSort(column, sortOrder)
                }
                tableInputs={this.state.tableInputs}
                id={""}
              />
            ) : (
              <NoRecordsFoundPage
                data-test-id="no_records"
                activeModule="projects"
                loadData={() => this.resetProjectDataTable()}
                text="No record found"
              />
            )}
          </Box>
        )}
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, isProjectSearchSaved, isResetProjectFilters } = this.state;
    return (
      <MuiThemeProvider theme={THEME}>
        <Hidden mdUp>
          <ResponsiveTopBar
            navigation={this.props.navigation}
            title={"Project"}
          >
            {() => (
              <AllFilter
                data-test-id="filterOptionTestId"
                navigation={this.props.navigation}
                module={"Projects"}
                open={true}
                isUpdateSaveSearch={isProjectSearchSaved}
                toggleOpen={this.toggleOpen}
                handleFilterList={this.handleProjectFilterObject}
                preFilter={this.state.filtersObj}
              />
            )}
          </ResponsiveTopBar>
        </Hidden>
        <div
          data-testId="outsideContainer"
          onClick={this.onClickOutside}
          className={this.props.classes.projectmodulebox}
        >
          <Grid container>
            <Hidden smDown>
              <Grid item xs={12} md={this.state.open ? 3 : 1}>
                {/* FilterView */}
                <div className={this.props.classes.projectfirstgridbox}>
                  <AllFilter
                    data-test-id="filterOptionTestId"
                    isResetFilters={isResetProjectFilters}
                    navigation={this.props.navigation}
                    module={"Projects"}
                    open={open}
                    isUpdateSaveSearch={isProjectSearchSaved}
                    toggleOpen={this.toggleOpen}
                    handleFilterList={this.handleProjectFilterObject}
                    preFilter={this.state.filtersObj}
                  />
                </div>
              </Grid>
            </Hidden>
            <Grid item xs={12} md={this.state.open ? 9 : 11}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px",
                  backgroundColor: "#f3f3f3",
                }}
              >
                <Hidden smDown>
                  <Navbar
                    navigation={this.props.navigation}
                    selectedTab={this.findActiveTabForNavbar()}
                  />
                </Hidden>
                {this.renderProjectChild()}
                {this.state.hasError &&
                  typeof this.state.hasError === "string" && (
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      open={!!this.state.hasError}
                      onClose={() => {
                        this.setState({
                          hasError: "",
                        });
                      }}
                      message={this.state.hasError}
                      style={{ zIndex: 100000 }}
                      key="error-message"
                      data-test-id="errorSnackbar"
                    />
                  )}
              </div>
            </Grid>
          </Grid>
        </div>
      </MuiThemeProvider>
    );
    // Customizable Area Start
  }
}

// Customizable Area Start
export default withStyles(styles)(ProjectModuleTable);
export { ProjectModuleTable };
// Customizable Area End

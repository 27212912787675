import React from "react";
// Customizable Area Start
import { done, left_Arrow } from './assets'
import { Box, Button, Snackbar, Typography, Grid, Hidden } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import UserProfilePasswordChangeController, { Props } from "./UserProfilePasswordChangeController.web";
import Alert from "@material-ui/lab/Alert";
import ToggleEyeTextField from "../../../components/src/ToggleEyeTextField.web";
import { Tick } from "../../../components/src/Tick.web";
import ResponsiveTopBar from "../../../components/src/TopBar/ResponsiveTopBar.web";
export const configJSON = require("./config");
// Customizable Area End

class UserProfilePasswordChange extends UserProfilePasswordChangeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderChangePassword = (styles: Record<string, string>) => {
        return (
            <Box className={styles.changePasswordDiv}>
                <Box className={styles.backToProfileContainer}>
                    <img
                        data-testid="changePasswordBackTestId"
                        src={left_Arrow}
                        alt="left_Arrow"
                        onClick={this.onGoBack}
                        className={styles.profleLeftArrowImg}
                    />
                    <Typography className={styles.profleHeadText}>
                        {configJSON.changePassword}
                    </Typography>
                </Box>
            </Box>
        )
    }

    renderCurrentPassword = (styles: Record<string, string>) => {
        return (
            <>
                <Box className={styles.profileDetailsContainer}>
                <Box className={styles.formPasswordContainer}>
                    <form
                        data-testid="changePasswordTestId"
                        autoComplete="off"
                        onSubmit={this.handleSubmitChangePassword}
                        className={styles.changePasswordFormContainer}
                    >
                        <Typography
                            variant="h5"
                            className={styles.currentPasswordHeading}
                        >
                            {configJSON.currentPassword}
                        </Typography>
                        <Box className={styles.inputBoxCenter}>
                            <Typography className={this.state.hideText ? styles.hideContent : styles.currentPasswordParagraph}>
                                {configJSON.toResetPassword}
                            </Typography>
                        </Box>
                        <Box className={styles.inputBoxCenter}>
                            <ToggleEyeTextField
                                data-testid="currentPasswordTestId"
                                type="password"
                                variant="outlined"
                                name="password"
                                value={this.state.changePassword}
                                onChange={this.handleChangePassword}
                                className={styles.ProfileDetailsInputPassword}
                                fullWidth
                                placeholder="Enter password"
                                error={!!this.renderProfileError("password")}
                                helperText={this.renderProfileError("password")}
                            />
                        </Box>
                        <Box className={styles.inputBoxCenter}>
                            <Button
                                className={styles.changePasswordContinueButton}
                                type="submit"
                            >
                                {configJSON.continue}
                            </Button>
                        </Box>
                    </form>
                    </Box>
                </Box>
            </>
        )
    }

    renderValidateTicks = (styles: Record<string, string>) => {
        return (
            <Box>
                <Grid
                    container
                    direction="column"
                    spacing={1}
                    style={{ margin: "18px 0"}}
                >
                    <Grid item direction="row" spacing={2}>
                        {this.state.loginValidation.upperCase &&
                        this.state.loginValidation.lowerCase &&
                        this.state.loginValidation.numeric &&
                        this.state.loginValidation.specialCharacter &&
                        this.state.loginValidation.minimumCharacter && (
                            <div
                            style={{
                                color: "#0094A7",
                                fontFamily: "Source Sans Pro",
                                fontSize: "16px",
                                fontWeight: "lighter",
                                lineHeight: "24px",
                                letterSpacing: "0px",
                                textAlign: "left"
                            }}
                            >
                            You are all set!
                            </div>
                        )}

                        {!this.state.newPassword.length ? (
                        <div
                            style={{
                            color: "#0094A7",
                            fontFamily: "Source Sans Pro",
                            fontSize: "16px",
                            fontWeight: "lighter",
                            lineHeight: "24px",
                            letterSpacing: "0px",
                            textAlign: "left"
                            }}
                        >
                            Your password must contain...
                        </div>
                        ) : (
                        <></>
                        )}
                    </Grid>
                    
                    <Grid item direction="row" spacing={2}>
                        <Tick
                            textLength={this.state.newPassword.length}
                            validation={this.state.loginValidation.upperCase}
                        />
                        <span
                            style={{
                            color: this.state.newPassword.length
                            ? this.state.loginValidation.upperCase
                                ? ""
                                : "#EE2020"
                            : ""
                            }}
                            className={styles.content}
                        >
                        {" "}
                            At least one uppercase letter (A-Z)
                        </span>
                    </Grid>
                    <Grid item direction="row" spacing={2}>
                        <Tick
                            textLength={this.state.newPassword.length}
                            validation={this.state.loginValidation.lowerCase}
                        />
                        <span
                            style={{
                                color: this.state.newPassword.length
                                ? this.state.loginValidation.lowerCase
                                    ? ""
                                    : "#EE2020"
                                : ""
                            }}
                            className={styles.content}
                        >
                            {" "}
                            At least one lowercase letter (a-z)
                        </span>
                    </Grid>
                    <Grid item direction="row" spacing={2}>
                        <Tick
                            textLength={this.state.newPassword.length}
                            validation={this.state.loginValidation.numeric}
                        />
                        <span
                            style={{
                                color: this.state.newPassword.length
                                ? this.state.loginValidation.numeric
                                    ? ""
                                    : "#EE2020"
                                : ""
                            }}
                            className={styles.content}
                        >
                            {" "}
                            At least one numeric number (0-9)
                        </span>
                    </Grid>
                    <Grid item direction="row" spacing={2}>
                        <Tick
                            textLength={this.state.newPassword.length}
                            validation={this.state.loginValidation.specialCharacter}
                        />
                        <span
                            style={{
                                color: this.state.newPassword.length
                                ? this.state.loginValidation.specialCharacter
                                    ? ""
                                    : "#EE2020"
                                : ""
                            }}
                            className={styles.content}
                        >
                            {" "}
                            At least one special character
                        </span>
                    </Grid>
                    <Grid item direction="row" spacing={2}>
                        <Tick
                            textLength={this.state.newPassword.length}
                            validation={this.state.loginValidation.minimumCharacter}
                        />
                        <span
                            style={{
                                color: this.state.newPassword.length
                                ? this.state.loginValidation.minimumCharacter
                                    ? ""
                                    : "#EE2020"
                                : ""
                            }}
                            className={styles.content}
                        >
                            {" "}
                            Minimum character length is 8 characters
                        </span>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    renderNewPasswordScreen = (styles: Record<string, string>) => {
        const newPasswordBorderStyle = () => {
            if (!this.state.newPassword.length) {
                return "";
            } else {
                if (
                this.state.loginValidation.upperCase &&
                this.state.loginValidation.lowerCase &&
                this.state.loginValidation.numeric &&
                this.state.loginValidation.minimumCharacter &&
                this.state.loginValidation.specialCharacter
                ) {
                return "";
                }
                return "#EE2020";
            }
        };

        return (
            <>
                <Box className={styles.profileDetailsContainer} style={{ paddingTop: "70px"}}>
                    <Box className={styles.formPasswordContainer}>
                        <form
                            data-testid="saveNewPasswordTestId"
                            autoComplete="off"
                            onSubmit={this.handleSubmitNewPassword}
                            className={styles.changePasswordFormContainer}
                        >
                            <Typography
                                variant="h5"
                                className={styles.currentPasswordHeading}
                            >
                                {configJSON.newPassword}
                            </Typography>

                            <Box style={{marginTop: "6px"}}>

                                <Typography className={styles.currentPasswordParagraph} style={{ marginBottom: "10px"}}>
                                    {configJSON.enterNewPassword}
                                </Typography>

                                <Box className={styles.inputBoxCenter}>
                                    <ToggleEyeTextField
                                        data-testid="newPasswordTestId"
                                        type="password"
                                        variant="outlined"
                                        name="newPassword"
                                        value={this.state.newPassword}
                                        onChange={this.handleNewPassword}
                                        style={{ borderColor: newPasswordBorderStyle() }}
                                        className={styles.ProfileDetailsInputPassword}
                                        fullWidth
                                        placeholder="8 character at least"
                                        error={!!this.renderProfileError("newPassword")}
                                        helperText={this.renderProfileError("newPassword")}
                                    />
                                </Box>

                                {this.renderValidateTicks(styles)}

                                <Typography className={styles.currentPasswordParagraph} style={{ marginBottom: "10px"}}>
                                    {configJSON.confirmPassword}
                                </Typography>

                                <Box className={styles.inputBoxCenter}>
                                    <ToggleEyeTextField
                                        data-testid="confirmPasswordTestId"
                                        type="password"
                                        variant="outlined"
                                        name="confirmPassword"
                                        value={this.state.confirmPassword}
                                        onChange={this.handleConfirmPassword}
                                        className={styles.ProfileDetailsInputPassword}
                                        fullWidth
                                        placeholder="8 character at least"
                                        error={!!this.renderProfileError("confirmPassword")}
                                        helperText={this.renderProfileError("confirmPassword")}
                                    />
                                </Box>
                                <Box className={styles.inputBoxCenter} style={{ marginTop: "4px"}}>
                                    <Button
                                        className={styles.changePasswordContinueButton}
                                        type="submit"
                                    >
                                        {configJSON.continue}
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </>
        )
    }

    renderPasswordResetSucess = (styles: Record<string, string>) => {
        return (
            <>
                <Box className={styles.passwordSucessContainer}>
                <Box className={styles.changePasswordFormContainer}>
                    <Box className={styles.inputBoxCenter}>
                        <img src={done} alt="confirm" className={styles.sucessIcon} />
                    </Box>
                    <Box className={styles.inputBoxCenter}>
                        <Typography className={styles.currentPasswordParagraph}>
                            {configJSON.SucessfullyReset}
                        </Typography>
                    </Box>
                    <Box className={styles.inputBoxCenter}>
                        <Button
                            data-testid="sucessfulContinueButton"
                            onClick={this.sucessfullyReset}
                            className={styles.changePasswordContinueButton}
                        >
                            {configJSON.continue}
                        </Button>
                    </Box>
                    </Box>
                </Box>
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const styles = this.props.classes;
        return (
            <div className={styles.mainClass}>
                <Hidden mdUp> 
                    <ResponsiveTopBar navigation={this.props.navigation} title={"Menu"}>
                    </ResponsiveTopBar>
                </Hidden>
                <Box className={styles.profileSelectorContainer}>
                    {this.renderChangePassword(styles)}
                    {this.state.ChangePasswordNumber === 1 && this.renderCurrentPassword(styles)}
                    {this.state.ChangePasswordNumber === 2 && this.renderNewPasswordScreen(styles)}
                    {this.state.ChangePasswordNumber === 3 && this.renderPasswordResetSucess(styles)}
                    <Snackbar
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        open={this.state.hasSuccessError.isOpen}
                        onClose={this.handleCloseSnack}
                        autoHideDuration={6000}
                    >
                        <Alert data-testid="snackBarCloseTestId" onClose={this.handleCloseSnack} severity={this.state.hasSuccessError.isSeverity}>
                            {this.state.hasSuccessError.isMessage}
                        </Alert>
                    </Snackbar>
                </Box>
            </div>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const styles = () =>
    createStyles({
        mainClass : {
          height: "100vh",
          background: "#F3F3F3",
        },
        profileSelectorContainer: {
            padding: "0px 10% 10%",
            backgroundColor: "#F3F3F3",
            border: "1px solid transparent",
            "@media (max-width: 500px)": {
                padding: "0px 2% 10%",
            },
        },
        changePasswordDiv: {
            height: "63px",
            borderRadius: "8px",
            backgroundColor: "#FFF",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 12px 0 40px",
            marginTop: "18px",
            "@media (max-width: 500px)": {
                padding: "0px 2%",
            },
            "@media (max-width: 350px)": {
                display: "block",
                height: "auto",
                padding: "20px 2%",
            },
        },
        backToProfileContainer: {
            display: "flex",
            alignItems: "center",
        },
        profleLeftArrowImg: {
            marginRight: "16px",
            cursor: "pointer",
        },
        profleHeadText: {
            color: "#2B3132",
            fontFamily: "Source Sans Pro",
            fontSize: "18px",
            lineHeight: "16px",
            fontStyle: "normal",
            fontWeight: 700,
        },
        profileDetailsContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "30px",
            alignItems: "center",
            marginTop: "16px",
            padding: "65px 50px 150px",
            borderRadius: "8px",
            backgroundColor: "#FFF",
            "@media (max-width: 460px)": {
                padding: "40px 40px 80px",
            }
        },
        currentPasswordHeading: {
            textAlign: "center",
            fontWeight: 700,
            fontFamily: 'Source Sans Pro',
            fontSize: '28px',
            lineHeight: "18px",
            fontStyle: 'normal',
            "@media (max-width: 650px)": {
                fontSize: '22px',
            },
            "@media (max-width: 450px)": {
                fontSize: '18px',
            },
        },
        currentPasswordParagraph: {
            marginTop: "0 !important",
            color: "#9F9F9F",
            fontFamily: 'Source Sans Pro',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: "16px",
            "@media(max-width:650px)": {
                fontSize: "14px",
            },
            "@media(max-width:450px)": {
                fontSize: "12px",
            },
        },
        changePasswordFormContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "100% !important",
        },
        changePasswordContinueButton: {
            color: "#FFF",
            width: '426px',
            height: "55px",
            borderRadius: "8px",
            background: "#0094A7",
            textTransform: "capitalize",
            cursor: "pointer",
            marginTop: "25px",
            fontFamily: 'Source Sans Pro',
            fontSize: '18px',
            fontStyle: 'normal',
            "&:hover": {
                backgroundColor: "#0094A7",
            },
            "@media (max-width: 650px)": {
                width: '100% !important',
            },
        },
        ProfileDetailsInputPassword: {
            width: '426px',
            height: '100%',
            "& .MuiInputBase-root ": {
                height: '64px',
            },
            "& .MuiOutlinedInput-root legend": {
                display: "none"
            },
            "& .MuiInputBase-fullWidth": {
                height: "100%"
            },
            "& .MuiOutlinedInput-input": {
                padding: "18.5px 20px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#0094A7",
            },

            "& label.Mui-focused": {
                // color: "#0094A7",
            },
            "& .MuiInput-underline:after": {
                borderBottomColor: "#0094A7",
            },
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    border: "1px solid #9F9F9F",
                },
                "&:hover fieldset": {
                    border: "1px solid #9F9F9F",
                },
                "&.Mui-focused fieldset": {
                    border: "1px solid #0094A7",
                },
                "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #DC2626 !important",
                },
                "&.error": {
                    border: "1px solid #DC2626",
                    borderRadius: "8px",
                },
            },
            "& .MuiFormHelperText-contained": {
                marginLeft: "0px",
                fontWeight: 600,
                fontSize: "14px",
            },
            "@media (max-width: 650px)": {
                width: '100% !important',
            },
        },
        inputBoxCenter: {
            display: "flex",
            justifyContent: "center",
        },
        hideContent: {
            display: "none",
        },
        formPasswordContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
        passwordSucessContainer: {
            display: "flex",
            flexDirection: "column",
            marginTop: "16px",
            padding: "84px 50px",
            alignItems: "center",
            justifyContent: "center",
            gap:"33px",
            borderRadius: "8px",
            backgroundColor: "#FFF",
            "@media (max-width: 650px)": {
                padding: "40px 50px 80px",
                gap: "23px",
            },
        },
        sucessIcon: {
            width: '123px',
            height: '123px',
            "@media (max-width: 350px)": {
                width: '80px',
                height: '80px',
            },
        },
        content: {
            fontFamily: "Source Sans Pro",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "18px",
            letterSpacing: "0px",
            textAlign: "left"
        }

    });
// Customizable Area End
// Customizable Area Start
export default withStyles(styles)(UserProfilePasswordChange);
export { UserProfilePasswordChange }
// Customizable Area End
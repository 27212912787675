import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import 'moment-timezone';
import { getStoredDefaultRole } from "../../../components/src/ContextAPI/AdvancedFilterContext.web";
import { filterRequestOptions } from "../../../components/src/WebAppLayout/config";
import { buildReqFiltersURLParams, countRequestFilters, setCheckedToFalseDeep } from "../../../components/src/Utils";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  requestPaginationDetails: {
    currentPage: number;
    rowsPerPage: number;
    totalLength: number;
  };
  requestTableInputs: {
    apiData: any;
    columsList: any;
  };
  isRequestListLoading: boolean;
  tabValue: string;
  requestFilterObj: any;
  filterOptions: any;
  filterReqAnchor:  HTMLElement | null;
  appliedCounter: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class RequestDataTableController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRequestTableDataApi: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      requestPaginationDetails: {
        rowsPerPage: configJSON.defaultRawsPerPage,
        totalLength: 0,
        currentPage: 1,
      },
      appliedCounter: 0,
      isRequestListLoading: true,
      requestTableInputs: {
        apiData: [],
        columsList: [],
      },
      tabValue: configJSON.requestRaised,
      filterOptions: {},
      filterReqAnchor: null,
      requestFilterObj: {},
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.setState({
      filterOptions: filterRequestOptions
    }, () => this.hitApiForRequestTableData())
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apireqTableRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const reqTableResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.getRequestTableDataApi === apireqTableRequestCallId) {
        this.getRequestTableResponse(reqTableResponseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.requestPaginationDetails.currentPage !==
        this.state.requestPaginationDetails.currentPage
    ) {
      this.setState(
        {
          isRequestListLoading: true,
        },
        () => {
          this.hitApiForRequestTableData();
        }
      );
    }
  }

  handleBackButton = () => {
    const toBack = new Message(getName(MessageEnum.NavigationMessage));
    toBack.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "Dashboard"
    );
    toBack.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toBack);
  };

  handleRequestAdd = () => {
    const toAdd = new Message(getName(MessageEnum.NavigationMessage));
    toAdd.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "RequestAdd"
    );
    toAdd.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(toAdd);
  }

  handleRedirectViewEdit = (value: string | number, path: string) => {
    const screen = path === "View Details" ? "RequestView" : "RequestEdit";
    this.props.navigation.navigate(screen,  { id: value });
  }

  handleCloseFilter = () => {
    this.setState({
      filterReqAnchor: null,
    })
  }

  handleRequestFilters = (changedList: { [key: string]: any }) => {
    this.setState((prevState: S) => {
      const list = { ...prevState.requestFilterObj, ...changedList };            
        return { requestFilterObj: list };
    });
  }

  handleReqCheckBoxChange = (
    checkedItems: string[],
    subitem: any,
    parentItem: any,
  ) => {
      const key = subitem === "" ? parentItem.render : subitem;
      const filterChangeObject = { [key]: checkedItems };
      this.handleRequestFilters(filterChangeObject);
  }

  handleRequestDateRange = (ev: any, parentKey: string, childKey: string ) => {
      const { requestFilterObj } = this.state
      const list = {
        [parentKey]: {
          ...requestFilterObj[parentKey],
          [childKey]: ev
        }
      }
      this.handleRequestFilters(list)
  }

  handleResetReqFilters = () => {
    const updatedList = setCheckedToFalseDeep(filterRequestOptions);
    this.setState({
      requestFilterObj: {},
      filterOptions: updatedList,
      requestPaginationDetails: {
        ...this.state.requestPaginationDetails,
        currentPage: 1,
      }
    }, async () =>{
      this.setState({ isRequestListLoading: true });
      this.hitApiForRequestTableData();
    })
  }

  handleApplyRequestFilters = () => {
    this.setState({ 
      filterReqAnchor: null,
      isRequestListLoading: true,
      requestPaginationDetails: {
        ...this.state.requestPaginationDetails,
        currentPage: 1,
      },
    }, () => {
        this.setState({ isRequestListLoading: true });
        this.hitApiForRequestTableData();
    });
  }

  handleSwitchTab = (value: string) => {
    this.setState(
      {
        tabValue: value,
        filterOptions: setCheckedToFalseDeep(filterRequestOptions),
        requestFilterObj: {},
        requestPaginationDetails: {
          rowsPerPage: configJSON.defaultRawsPerPage,
          totalLength: 0,
          currentPage: 1,
        },
      },
      () => {
        this.setState({ isRequestListLoading: true });
        this.hitApiForRequestTableData();
      }
    );
  };

  handleOpenReqFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    if( event.currentTarget) {
      this.setState({ filterReqAnchor: this.state.filterReqAnchor ? null : event.currentTarget });
    }
  }

  handleChangePage = (event: any, newPage: any) => {
    window.scrollTo(0, 0);
    this.setState({
      requestPaginationDetails: {
        ...this.state.requestPaginationDetails,
        currentPage: newPage,
      },
      filterReqAnchor: null,
    });
  };

  handleLoadRequestBtn = async () => {
    this.setState(
      {
        requestFilterObj: {},
        filterOptions: setCheckedToFalseDeep(filterRequestOptions),
        filterReqAnchor: null,
        requestPaginationDetails: {
          rowsPerPage: configJSON.defaultRawsPerPage,
          totalLength: 0,
          currentPage: 1,
        },
      },
      async () => {
        try {         
          this.setState({ isRequestListLoading: true });
          this.hitApiForRequestTableData()
        } catch (error) {
          console.error("Error in handleLoadPartnerBtn:", error);
        }
      }
    );
  };

  hitApiForRequestTableData = async () => {
    const { tabValue, requestPaginationDetails, requestFilterObj} = this.state;
    const count = countRequestFilters(requestFilterObj);
    this.setState({ appliedCounter: count})
    let tableParams = buildReqFiltersURLParams(requestFilterObj);   
    tabValue === configJSON.requestReceived &&
      tableParams.append("role", getStoredDefaultRole());

    this.getRequestTableDataApi = await this.apiCallRequestTable({
      methodReqTable: configJSON.getReceivedRequestApiMethod,
      contentTypeReqTable: configJSON.exampleApiContentType,
      endPointReqTable: `${configJSON.getRequestApiEndPoint}?${tableParams}&page=${requestPaginationDetails.currentPage}&per_page=10`,
    });
  };

  getRequestTableResponse = (reqResponse: any) => {
    let data = reqResponse.data;

    if (data && Array.isArray(data)) {
      const apiData: any = data.map((d: any) => {
        return {
          id: d.id,
          ...d.attributes,
          created_at: moment(d.attributes.created_at, "YYYY-MM-DD HH:mm:ss Z").tz('America/New_York').format("DD/MM/YYYY"),
          updated_at: moment(d.attributes.updated_at, "YYYY-MM-DD HH:mm:ss Z").tz('America/New_York').format("DD/MM/YYYY"),
          requested_by: this.truncateRequestText(d.attributes.requested_by, 21),
        };
      });
      const requestTableInputs = {
        apiData,
        columsList: configJSON.requestColumnListDefiniation,
      };

      let totalLength: number = 0,
        currentPage: number = 1;

      if (reqResponse.meta?.total_count && reqResponse.meta?.current_page) {
        totalLength = reqResponse.meta.total_count;
        currentPage = reqResponse.meta.current_page;
      }

      const requestPaginationDetails = {
        rowsPerPage: this.state.requestPaginationDetails.rowsPerPage,
        totalLength: totalLength,
        currentPage: currentPage,
      };

      this.setState({
        requestPaginationDetails,
        requestTableInputs,
      });
    } else {
      const requestTableInputs = {
        apiData: [],
        columsList: configJSON.requestColumnListDefiniation,
      };

      this.setState({
        requestTableInputs,
      });
    }
    this.setState({
      isRequestListLoading: false,
    });
  };

  truncateRequestText = (requestTitle: string, requestTitleLength: number) => {
    if (requestTitle === undefined || requestTitle === null) return "";
    if (requestTitle.length > requestTitleLength) {
      return `${requestTitle.slice(0, requestTitleLength)}...`;
    } else {
      return requestTitle;
    }
  };

  apiCallRequestTable = async (valueReqTableData: {
    methodReqTable?: string;
    contentTypeReqTable?: string;
    bodyReqTable?: {};
    endPointReqTable?: string;
  }) => {
    let {
      contentTypeReqTable,
      methodReqTable,
      endPointReqTable,
      bodyReqTable,
    } = valueReqTableData;
    const token = (await localStorage.getItem("authToken")) ?? "";
    let headerReqTable = {
      "Content-Type": contentTypeReqTable,
      token,
    };
    let requestMessageReqTable = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageReqTable.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodReqTable
    );
    requestMessageReqTable.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointReqTable
    );
    requestMessageReqTable.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerReqTable)
    );
    bodyReqTable &&
      requestMessageReqTable.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        bodyReqTable
      );
    runEngine.sendMessage(requestMessageReqTable.id, requestMessageReqTable);
    return requestMessageReqTable.messageId;
  };

  // Customizable Area End
}

import Alert from '@material-ui/lab/Alert';
import {
  Grid,
  Theme,
  Box,
  createTheme,
  MuiThemeProvider,
  createStyles,
  Typography,
  Accordion,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  Paper,
  AccordionDetails,
  AccordionSummary,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  withStyles,
  Hidden,
} from "@material-ui/core";
import React from "react";

import ProjectModuleHeader from "../../multipageforms/src/Components/ProjectModule/ProjectModuleHeader.web";
import ProjectImportDataController, { Props } from "./ProjectImportDataController";
import {
  configJSON 
} from "./ImportExportDataController.web";
import ImportView from "./Components/ImportView.web";
import CustomTypographyWeb from "../../../components/src/CustomTypography.web";
import CustomButton from "../../../components/src/CustomButton.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ResponsiveTopBar from '../../../components/src/TopBar/ResponsiveTopBar.web';


const THEME = createTheme({
  typography: {
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontFamily: "Source Sans Pro",
    fontSize: 14,
    fontWeightLight: 300,
  },
});

export class ProjectImportData extends ProjectImportDataController {

  constructor(props: Props) {
    super(props);
  }

  renderImportView() {
    const classes = this.props.classes;

    return (
      <Box marginTop={2} className={classes.containerSucess}>
        <ProjectModuleHeader data-testId="projectModuleHeader" saveAsPDF={false} handleBackClick={this.handleGoBack}/>
        <ImportView
          data-testId="importView"
          navigation={this.props.navigation}
          sampleCSVFormat={configJSON.exportSampleProjectCSVData}
          sampleXLSFile={configJSON.sampleXLSXProjectData}
          onSubmitImport = {this.handleImportFile}
          module={"projects"}
        />
      </Box>
    );
  }

  renderSuccessImport() {
    const classes = this.props.classes;
    const { reportMetaData } = this.state
    return (
      <Box marginTop={2} className={classes.containerSucess} paddingBottom={2}>
        <ProjectModuleHeader data-testId="projectModuleHeader" saveAsPDF={false} handleBackClick={this.handleGoBack}/>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={10} md={8} lg={7}>
            <Box className={classes.sucessBox} marginTop={2}>
              <Alert data-testId="summary" severity="success" className={classes.alertBox}>{ reportMetaData?.summary.summaryMessage  }</Alert>

              <Box className={classes.listErrorBox}>

                <Accordion key={`report_summary`} style={{width: '100%'}}  className={classes.customAccordion}>
                  <AccordionSummary
                    style={{borderBottom: "1px solid #f3f3f3"}}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant='body1' className={classes.headingReport}>{ reportMetaData?.importedMessage }</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List dense={true} className={classes.listDetailReport}>
                        <ListItem key={`error_report_1`}>
                          <ListItemText primary={ reportMetaData?.summary.createdRecords } />
                        </ListItem>

                        <ListItem key={`error_report_2`}>
                          <ListItemText primary={ reportMetaData?.summary.oveririddenRecords} />
                        </ListItem>
                    </List>
                  </AccordionDetails>
                
                </Accordion>

                <Accordion key={`report_skip`} style={{width: '100%'}}  className={classes.customAccordion}>
                  <AccordionSummary
                    style={{borderBottom: "1px solid #f3f3f3"}}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant='body1' className={classes.headingReport}> { reportMetaData?.skippedMessage } </Typography>
                  </AccordionSummary>
                  { (reportMetaData?.skippedErrors || []).length > 0 &&
                    <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table className={classes.table} size="small" >
                        <TableHead>
                          <TableRow>
                            <TableCell>Row Number</TableCell>
                            <TableCell>Project Name</TableCell>
                            <TableCell>Error Message</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {reportMetaData?.skippedErrors.map((row) => (
                            <TableRow key={`row_${row.row}`}>
                              <TableCell component="th">
                                Row {row.row}
                              </TableCell>
                              <TableCell>{row?.value || 'Nil'}</TableCell>
                              <TableCell>{row.error}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                  }
                  
                
                </Accordion>

                <Accordion key={`report_override`} style={{width: '100%'}}  className={classes.customAccordion}>
                  <AccordionSummary
                    style={{borderBottom: "1px solid #f3f3f3"}}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant='body1' className={classes.headingReport}> {reportMetaData?.notImportMessage} </Typography>
                  </AccordionSummary>
                  { (reportMetaData?.recordErrors || []).length > 0 &&
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" >
                          <TableHead>
                            <TableRow>
                              <TableCell>Row Number</TableCell>
                              <TableCell>Project Name</TableCell>
                              <TableCell>Field Name</TableCell>
                              <TableCell>Error Message</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {reportMetaData?.recordErrors.map((row) => (
                              <TableRow key={`row_${row.row}`}>
                                <TableCell component="th">
                                  Row {row.row}
                                </TableCell>
                                <TableCell>{row?.value || 'Nil'}</TableCell>
                                <TableCell>{row.fieldName}</TableCell>
                                <TableCell>{row.error}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                  </AccordionDetails>
                  }
                  
                </Accordion>
              </Box>
          
              <CustomButton data-testId="addAnotherBtn" style={{ marginTop: 16 }} onClick={() => this.resetImport() }>Add Another</CustomButton>
            </Box>
            
          </Grid>
        </Grid>
      </Box>

    )
  }

  
  render() {
    const stateRoutes =  this.props.navigation.history?.location?.state;
    const isImportSucess = stateRoutes?.status;

    // Customizable Area Start
    return (<>
      <MuiThemeProvider theme={THEME}>
        <div style={customStyle.rootContainer}>
        {this.state.isShowReport ? this.renderSuccessImport() : this.renderImportView()}
          {this.state.hasError && (
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={!!this.state.hasError} 
            onClose={() => {
              this.setState({
                hasError: "",
              });
            }}
          >
            <Alert data-testId="snack-error" severity="error">{ this.state.hasError }</Alert>
          </Snackbar>
        )}
        </div>
      </MuiThemeProvider>
    </>);
    // Customizable Area Start
  }
}

export const customStyle = {
  rootContainer: {
    fontFamily: 'Source Sans Pro',
    display: 'flex',
    backgroundColor: '#F3F3F3',
    width: '100%',
  },
  filterContain: {}
}
export const styles = (theme: Theme) =>
  createStyles({
    expandSidebarIconBox: {   
      alignItems: "center",
      justifyContent: "center",
      height: "20px",
      borderRadius: "8px",
      padding: "5px",
      display: "flex",
      position: "fixed",
      width: "20px",
      backgroundColor: "rgb(253, 215, 47)",
      zIndex: 1,
      cursor: "pointer",
      top: "127px",
      transition: "left 0.4s ease-out",
    },
    earch: {
      position: "relative",
      borderRadius: '8px',
      backgroundColor: "white",
      "& fieldset": {
        border: "1px solid #E8E8E8 !important"
      },
      "&:hover": {
        backgroundColor: "white",
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px'
      },
      marginLeft: 0,
      width: "40%",
      "& .MuiOutlinedInput-adornedStart": {
      },
      "& .MuiOutlinedInput-input": {
        padding: '14px'
      },
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '0px'
      }
    },
    mainContainer: {
      position: "relative",
      display: "flex",
    },
    mainChildWrapper: {
      marginTop: "85px",
      transition: "width 0.4s ease-out",
      minHeight: "100vh",
    },
    uploadFileBox: {
      background: "rgba(0, 148, 167, 0.08)",
      padding: "40px",
      justifyContent: "center",
      gap: "20px",
      borderRadius: "8px",
      // border: "1px solid rgba(0, 148, 167, 0.40)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    sucessBox: {
      background: "rgba(0, 148, 167, 0.08)",
      padding: "40px",
      justifyContent: "center",
      borderRadius: "8px",
      // border: "1px solid rgba(0, 148, 167, 0.40)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: 24
    },
    sidenav: {
      backgroundColor: "rgb(10, 25, 41)",
      paddingTop: "28px",
      width: "250px",

      transition: "width 0.3s ease-in-out",
      height: "100vh",
    },
    sidenavClosed: {
      transition: "width 0.3s ease-in-out",

      width: "60px",
      composes: "sidenav",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      marginTop: "20px",
      backgroundColor: "#FFF",
      gap: "25px",
    },
    sideitem: {
      padding: "10px 20px",
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      overflow: "hidden",
      cursor: "pointer",
      color: "#b2bac2",
     
    },
    "& .sideitem:hover": {
      backgroundColor: "#244f7d1c",
    },
    linkText: {
      paddingLeft: "16px",
    },
    menuBtn: {
      boxShadow: "3px",
      borderRadius: "10px",
      cursor: "pointer",
      paddingLeft: "20px",
      position: "relative",
      transition: "width 0.3s ease-in-out",
      backgroundColor: "transparent",
      border: "none",
      top: "124px",
      alignSelf: "center",
      justifySelf: "flex-end",
      color: "#b2bac2",
      left: "40px",
      zIndex: 1000000,
    },
    menuBtnExpand: {
      backgroundColor: "transparent",
      paddingLeft: "20px",
      position: "relative",
      top: "124px",
      border: "none",
      transition: "width 0.3s ease-in-out",
      zIndex: 1000000,
      cursor: "pointer",
      left: "230px",
      alignSelf: "center",
      justifySelf: "flex-end",
      color: "#b2bac2",
    },
    containerSucess: {
      backgroundColor: "#FFF",
      width: '100%',
      height: "100%",
    "@media (max-width: 960px)": {
      padding: 5
     }
    },
    headingReport: {
      marginLeft: '8px',
      fontSize: 16,
      color: "#6a6e6f",
      "& span": {
        fontWeight: 600,
        color: "#f50057",
        marginLeft: 4
      }
    },

    reportText: {
      marginBottom: '24px',
      color: '#0094a7',
      display: 'block',
      textAlign: 'center',
      fontWeight: "bold",
      boxSizing: 'content-box',
      textTransform: 'uppercase'
    },

    customAccordion: {
      '& .MuiAccordion-root.Mui-expanded': {
        margin: 0
      }
    },
    listDetailReport: {
      "& .MuiTypography-body2": {
        color: "#6a6e6f",
      }
    },
    reportSingleBox: {
      padding: '12px',
      width: '100%',
      margin: '8px 0',
      "& span.title": {
        display: 'inline-block',
        width: 150,
        color: "#1f1f1f"
      },

      "& span.value": {
        fontWeight: '600',
        color: '#3c7e40'
      }
    },
    rowErrorText: {
      textAlign: 'left',
      width: '100%',
      marginLeft: -20,
      color: "#4c4c4c",
      marginBottom: '10px'
    },
    listErrorBox: {
      width: '100%',
      maxHeight: '600px',
      overflowY: 'scroll'
    },
    alertBox: {
      width: '100%',
      marginBottom: '12px'
    },
    table: {
      minWidth: 650,
    },
});

export default withStyles(styles)(ProjectImportData)




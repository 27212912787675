import React, { Component } from "react";
import { Box, TextField, Typography, styled, withStyles } from "@material-ui/core";
import DatePicker from "react-datepicker";
import moment from "moment";
import dayjs from "dayjs";
import { ErrorRounded } from "@material-ui/icons";
import { calendar } from "../assets";

type CustomDatePickerProps = {
  label: string;
  inputLabel?: string;
  placeholder?: string;
  name: string;
  onChange: (val: string) => void;
  dateFormat: string;
  value: string | Date;
  error?: boolean;
  helperText?: string;
  minDate?: Date;
  maxDate?: Date;
  marginBottom?: string | number;
};

export default class CustomDatePicker extends Component<CustomDatePickerProps, {}> {
  handleValidDate = (value: string | Date | null | undefined) => {
    const  { dateFormat } = this.props;
    return value ? (moment(value, dateFormat).isValid() ? moment(value, dateFormat).toDate() : null) : null;
  };

  render() {
    const { name, value, dateFormat, error, helperText, placeholder, minDate, maxDate, label, inputLabel } = this.props;
    const selectedValue = this.handleValidDate(value);
    const todayDate = moment().format(dateFormat);
    const yearsToTarget = dayjs().year() - 1900;

    return (
      <Box
        id={"custom-input-box"}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          p: 0,
          width: "100%",
          marginRight: "16px",
          marginBottom: this.props.marginBottom
        }}
      >
        {label && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              marginBottom: "7px",
            }}
          >
            <StyledLabel>{label}</StyledLabel>
            {error && (
              <ErrorRounded
                color="error"
                fill="#DC2626"
                style={{
                  width: "16px",
                  height: "16px",
                }}
              />
            )}
          </Box>
        )}
        <StyledDatePickerWrapper>
        <CustomeDate
          name={name}
          selected={selectedValue}
          dateFormat={"dd/MM/yyyy"}
          placeholderText={placeholder ?? todayDate}
          onChange={(e: Date | null) => this.props.onChange(e ? moment(e).format(dateFormat) : "")}
          data-test-id="customdaterange"
          minDate={this.handleValidDate(minDate)}
          maxDate={this.handleValidDate(maxDate)}
          customInput={
            <CustomDateField
              fullWidth
              type="text"
              name={name}
              value={selectedValue}
              onChange={(val) => this.props.onChange(val.target.value)}
              variant="outlined"
              InputProps={{
                endAdornment: <img src={calendar} alt="calendar" />,
              }}
              label={inputLabel}
              error={error}
              helperText={helperText}
              InputLabelProps={{ shrink: !!inputLabel ? true : false }}
            />
          }
          showMonthDropdown
          scrollableMonthYearDropdown
          useShortMonthInDropdown
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={yearsToTarget}
        />
        </StyledDatePickerWrapper>
      </Box>
    );
  }
}

const StyledLabel = withStyles({
  root: {
    color: "#1F1F1F",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: 0,
    marginRight: "6px",
  },
})(Typography);

export const CustomeDate = styled(DatePicker)({

  '& .react-datepicker__header': {
    backgroundColor: "red !important",
  },

  "& .react-datepicker__input-container": {
    "& input": {
      width: "100%",
      padding: "13px 14px",
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#2B3132",
      lineHeight: "16px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      padding: 0,
    },
  },
  "& .react-datepicker__month-container":{
    "& .react-datepicker__header": {
      backgroundColor: "red",
    },
  }
});

export const CustomDateField = withStyles({
  root: {
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0094A7",
    },
    "& label.Mui-focused": {
      color: "#0094A7",
    },
    "& .MuiInputBase-input": {
      width: "100%",
      padding: "13px 14px",
      fontFamily: "Source Sans Pro",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      color: "#2B3132",
      lineHeight: "16px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      "&:hover fieldset": {
        border: "1px solid #9F9F9F",
      },
      "& fieldset": {
        border: "1px solid #9F9F9F",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #0094A7",
      },
      "&.error": {
        border: "1px solid #DC2626",
        borderRadius: "8px",
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #DC2626 !important",
      },
    },
    "& .MuiFormHelperText-contained": {
      marginLeft: "1px",
    }
  },
})(TextField);

const StyledDatePickerWrapper = styled('div')(({ theme }) => ({
  "& .react-datepicker-popper": {
    zIndex: 10000,
  },
  '& .react-datepicker': {
    zIndex: 10000,
  },
  '& .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, & .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after': {
    borderBottomColor: 'rgb(214, 238, 241)',
  },
  '& .react-datepicker__header': {
    backgroundColor: 'rgb(214, 238, 241)',
  },
  '& .react-datepicker__day--selected, & .react-datepicker__day--in-selecting-range, & .react-datepicker__day--in-range': {
    backgroundColor: 'rgb(0, 148, 167)',
  },
  '& .react-datepicker__day--selected:hover, & .react-datepicker__day--in-selecting-range:hover, & .react-datepicker__day--in-range:hover': {
    backgroundColor: 'rgb(0, 148, 167)',
  },
  '& .react-datepicker__month-read-view--down-arrow, & .react-datepicker__year-read-view--down-arrow': {
    borderColor: '#aeaeae',
    height: '6.5px',
    width: '6.5px',
  },
  '& .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, & .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow': {
    borderColor: '#aeaeae',
  },
  '& .react-datepicker__month-dropdown, & .react-datepicker__year-dropdown': {
    backgroundColor: '#fff',
    border: '1px solid #aeaeae',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    zIndex: 20000,
  },
  '& .react-datepicker__month-option, & .react-datepicker__year-option': {
    cursor: 'pointer',
  },
  '& .react-datepicker__month-dropdown::-webkit-scrollbar, & .react-datepicker__year-dropdown::-webkit-scrollbar': {
    width: '5px',
  },
  '& .react-datepicker__month-dropdown::-webkit-scrollbar-track, & .react-datepicker__year-dropdown::-webkit-scrollbar-track': {
    backgroundColor: '#f1f1f1',
    borderRadius: '4px',
  },
  '& .react-datepicker__month-dropdown::-webkit-scrollbar-thumb, & .react-datepicker__year-dropdown::-webkit-scrollbar-thumb': {
    backgroundColor: '#888',
    borderRadius: '4px',
  },
  '& .react-datepicker__month-dropdown::-webkit-scrollbar-thumb:hover, & .react-datepicker__year-dropdown::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#555',
  },
  '& .react-datepicker__month-option:hover, & .react-datepicker__year-option:hover': {
    backgroundColor: 'none',
  },
  '& .react-datepicker__month-option--selected_month, & .react-datepicker__year-option--selected_year': {
    backgroundColor: 'rgb(214, 238, 241)',
    color: 'black',
    fontWeight: 600,
  },
}));


Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.apiDeleteMethod = 'DELETE'
exports.apiPostMethod = 'POST'
exports.apiGetMethod = 'GET'
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.exampleErrorMessage = 'Example error message'
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";
exports.peopleDataTableTestId = "people-module-table-main-view-test-id"
exports.projectModuleTableMainViewTestId = 'project-module-table-main-view-test-id'
exports.favProjectErrorMessage = 'Cannot add project to favourite list'
exports.apiEndPointForGetProjects = '/bx_block_profile/projects'
exports.apiEndPointForFavouriteProject = '/bx_block_favourites/favourites'
exports.deleteFavouriteEndPoint = '/bx_block_favourites'
exports.apiEndPointForSaveProjectSearch = '/bx_block_saved_search/saved_searches'
exports.projectModuleSaveSearchBoxTestId = 'project-module-save-search-box-test-id'
exports.apiEndPointForAutoCompleteSearch = '/bx_block_profile/projects/advance_search'
exports.projectModuleTableTestId = 'project-module-table-main-test-id'
exports.projectModuleNoRecordsError = 'No matching records found.'
exports.projectModuleAutoCompleteBoxTestId = 'project-module-autocomplete-box-test-id'
exports.projectModuleSaveSearchCheckBox = 'project-module-save-search-checkbox-test-id'
exports.projectModuleSaveSearchInputTestId = 'project-module-save-search-input-test-id'
exports.projectModuleSaveSearchSubmitButtonTestId = 'project-module-save-search-submit-button-test-id'
exports.projectModuleSaveSearchCancelButtonTestId = 'project-module-save-search-cancel-button-test-id'
exports.onSaveSearchErrorMessage = "Search name is required"
exports.onSaveSearchNoFilterMessage = "At least one filter is required"
exports.projectModuleSaveSearchFormTestId = 'project-module-save-search-form-test-id'
exports.autoCompleteOptionsLength = 5
exports.projectModuleNoRecordsFoundId = 'project-module-no-records-found-id'
exports.projectModuleAutoCompleteTextFieldTextId = 'project-module-autocomplete-textfield'
exports.clearProjectModuleAutoCompleteResultsTestId = 'project-module-autocomplete-clear-result'
exports.autoCompleteTimerToSearch = 500
exports.showMoreResultsId = 'show-More-People-Information-Id'
exports.peopleModuleAddButtonTestId = "people-module-add-button-main"
exports.projectModuleColSpanListDefiniation = []

exports.apiRecentActivity = "bx_block_dashboard/recent_activities"

for (let i = 1; i < 12; i++) {
  exports.projectModuleColSpanListDefiniation.push({
    label: '',
    colSpan: 1
  })
}
exports.projectModuleColSpanListDefiniation.push({
  label: 'Connections',
  colSpan: 4
})

exports.projectModuleColumnListDefiniation = [
  { dataLabel: 'iconValue', displayLabel: '', hideLabel: true, type: 'icon', width: 'fit-content', sortOption: { isSortable: false }, },
  { dataLabel: 'project_name', displayLabel: 'Project name', hideLabel: false, type: 'link', width: '180px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'service_type', displayLabel: 'Type', hideLabel: false, type: 'value', width: '90px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'group_company', displayLabel: 'Group company', hideLabel: false, type: 'value', width: '90px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'country', displayLabel: 'Country', hideLabel: false, type: 'value', width: '90px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'client', displayLabel: 'Client', hideLabel: false, type: 'value', width: '90px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'services', displayLabel: 'Services', hideLabel: false, type: 'projectServices', width: '180px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'sector', displayLabel: 'Sector', hideLabel: false, type: 'value', width: '135px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'value_of_work_range', displayLabel: 'Value of work range(USD)', hideLabel: false, type: 'value', width: '135px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'project_status', displayLabel: 'Status', hideLabel: false, type: 'value', width: '90px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'end_date', displayLabel: 'End date', hideLabel: false, type: 'value', width: '90px', sortOption: { isSortable: false, sortOrder: 'ASC' } },
  { dataLabel: 'people', displayLabel: 'Peoples', hideLabel: false, type: 'connection', width: '180px', sortOption: { isSortable: false, sortOrder: 'ASC' }, textAlign: 'center', connections: { nameKey: 'first_name', idKey: 'id', path: '/People' } },
  { dataLabel: 'suppliers', displayLabel: 'Suppliers', hideLabel: false, type: 'connection', width: '180px', sortOption: { isSortable: false, sortOrder: 'ASC' }, textAlign: 'center', connections: { nameKey: 'company_name', idKey: 'id', path: '/Supplier' } },
  { dataLabel: 'partners', displayLabel: 'Partners', hideLabel: false, type: 'connection', width: '180px', sortOption: { isSortable: false, sortOrder: 'ASC' }, textAlign: 'center', connections: { nameKey: 'company_name', idKey: 'id', path: '/Partner' }  },

]

exports.getTenderDataEndpoint = "bx_block_profile/tenders";

exports.tenderModuleColSpanListDefiniation = []

for (let i = 1; i < 13; i++) {
  exports.tenderModuleColSpanListDefiniation.push({
    label: '',
    colSpan: 1
  })
}
exports.tenderModuleColSpanListDefiniation.push({
  label: 'Connections',
  colSpan: 3
})

exports.tenderModuleColumnListDefiniation = [
  { dataLabel: 'iconValue', displayLabel: '', hideLabel: true, type: 'icon', width: 'fit-content', sortOption: { isSortable: false }, },
  { dataLabel: 'name', displayLabel: 'Tender name', hideLabel: false, type: 'link', width: '180px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'tender_code', displayLabel: 'Tender code', hideLabel: false, type: 'value', width: '130px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'tender_type', displayLabel: 'Type', hideLabel: false, type: 'value', width: '90px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'group_company', displayLabel: 'Group company', hideLabel: false, type: 'value', width: '90px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'country', displayLabel: 'Country', hideLabel: false, type: 'value', width: '90px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'client', displayLabel: 'Client', hideLabel: false, type: 'value', width: '90px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'client_name', displayLabel: 'Client name', hideLabel: false, type: 'value', width: '90px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'services', displayLabel: 'Services', hideLabel: false, type: 'projectServices', width: '180px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'sector', displayLabel: 'Sector', hideLabel: false, type: 'value', width: '135px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'value_of_work_range', displayLabel: 'Value of work range(USD)', hideLabel: false, type: 'value', width: '135px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'tender_status', displayLabel: 'Status', hideLabel: false, type: 'value', width: '90px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'people', displayLabel: 'Peoples', hideLabel: false, type: 'connection', width: '180px', sortOption: { isSortable: false, sortOrder: 'ASC' }, textAlign: 'center', connections: { nameKey: 'first_name', idKey: 'id', path: '/People' } },
  { dataLabel: 'suppliers', displayLabel: 'Suppliers', hideLabel: false, type: 'connection', width: '180px', sortOption: { isSortable: false, sortOrder: 'ASC' }, textAlign: 'center', connections: { nameKey: 'company_name', idKey: 'id', path: '/Supplier' } },
  { dataLabel: 'partners', displayLabel: 'Partners', hideLabel: false, type: 'connection', width: '180px', sortOption: { isSortable: false, sortOrder: 'ASC' }, textAlign: 'center',connections: { nameKey: 'company_name', idKey: 'id', path: '/Partner' } }]

exports.defaultRawsPerPage = 10;
exports.rowsPerPageOptions = [10, 25, 50, 75, 100];

exports.getSupplierDataEndpoint = "bx_block_multipageforms2/suppliers";
exports.apiEndPointForFavouriteSupplier = '/bx_block_favourites/favourites'

exports.supplierModuleColSpanListDefiniation = []

for (let i = 1; i < 10; i++) {
  exports.supplierModuleColSpanListDefiniation.push({
    label: '',
    colSpan: 1
  })
}

exports.supplierModuleColumnListDefiniation = [
  { dataLabel: 'iconValue', displayLabel: '', hideLabel: true, type: 'icon', width: 'fit-content', sortOption: { isSortable: false }, },
  { dataLabel: 'supplier_name', displayLabel: 'Supplier name', hideLabel: false, type: 'link', width: '180px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'group_company', displayLabel: 'Group company', hideLabel: false, type: 'value', width: '158px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'country', displayLabel: 'Country', hideLabel: false, type: 'value', width: '115px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'services', displayLabel: 'Services', hideLabel: false, type: 'projectServices', width: '187px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'sector', displayLabel: 'Sector', hideLabel: false, type: 'value', width: '115px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'internal_rate', displayLabel: 'Internal rate', hideLabel: false, type: 'value', width: '150px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'projects', displayLabel: 'Projects', type: 'connection', hideLabel: false, width: '206px', sortOption: { isSortable: false, sortOrder: 'ASC' }, textAlign: 'center', connections: { nameKey: 'project_name', idKey: 'id', path: '/Projects' } },
  { dataLabel: 'tenders', displayLabel: 'Tenders', type: 'connection', hideLabel: false, width: '206px', sortOption: { isSortable: false, sortOrder: 'ASC' }, textAlign: 'center', connections: { nameKey: 'name', idKey: 'id', path: '/Tender' } }
]


exports.getPartnerDataEndpoint = "bx_block_multipageforms2/partners";
exports.apiEndPointForFavouritePartner = '/bx_block_favourites/favourites'

exports.partnerModuleColSpanListDefiniation = []

for (let i = 1; i < 10; i++) {
  exports.partnerModuleColSpanListDefiniation.push({
    label: '',
    colSpan: 1
  })
}

exports.partnerModuleColumnListDefiniation = [
  { dataLabel: 'iconValue', displayLabel: '', hideLabel: true, type: 'icon', width: 'fit-content', sortOption: { isSortable: false }, },
  { dataLabel: 'company_name', displayLabel: 'Partners name', hideLabel: false, type: 'link', width: '180px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'group_company', displayLabel: 'Group company', hideLabel: false, type: 'value', width: '158px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'country', displayLabel: 'Country', hideLabel: false, type: 'value', width: '115px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'service', displayLabel: 'Services', hideLabel: false, type: 'projectServices', width: '187px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'sector', displayLabel: 'Sector', hideLabel: false, type: 'value', width: '115px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'internal_rating', displayLabel: 'Internal rate', hideLabel: false, type: 'value', width: '150px', sortOption: { isSortable: true, sortOrder: 'ASC' } },
  { dataLabel: 'projects', displayLabel: 'Projects', hideLabel: false, type: 'connection', width: '206px', sortOption: { isSortable: false, sortOrder: 'ASC' }, textAlign: 'center', connections: { nameKey: 'project_name', idKey: 'id', path: '/Projects' } },
  { dataLabel: 'tenders', displayLabel: 'Tenders', type: 'connection', hideLabel: false, width: '206px', sortOption: { isSortable: false, sortOrder: 'ASC' }, textAlign: 'center', connections: { nameKey: 'name', idKey: 'id', path: '/Tender' } }
]

exports.pieChartEmployeeTitle = "Employees [%]"
exports.pieChartCandidateTitle = "Candidates [%]"
exports.donutFieldBasedTitle = "Field Based Tender"
exports.donutYearlyBasedTitle = "Yearly Based Tender"
exports.barChartCompleteTitle = "Completed Projects"
exports.barChartOngoingTitle = "On going Projects"

exports.yearOptionApiEndpoint = "bx_block_people/years"
exports.peopleDataApiEndpoint = "bx_block_dashboard/people_data"
exports.tenderQuarterApiEndpoint = "bx_block_dashboard/tender_quarterly_data"
exports.tenderYearlyApiEndpoint = "bx_block_dashboard/tender_yearly_data"
exports.projectDataApiEndpoint = "bx_block_dashboard/project_data"
exports.projectCompletedApiEndpoint = "bx_block_dashboard/completed_projects"
exports.projectOngoingApiEndpoint = "bx_block_dashboard/on_going_projects"
exports.projectServiceApiEndpoint = "bx_block_company/services"


exports.peopleChartFirstRow = ["name", "percentage", { role: "style" } ]

exports.dateRangeOptions = [
  {
    label: "From",
    render: "start_date",
    value: null,
  },
  {
    label: "To",
    render: "end_date",
    value: null,
  },
];

exports.tenderDropDownName = "tenderDropdown"
exports.completedDropdownName = "projectCompletedDropdown"
exports.onGoingDropdownName = "projectOngoingDropdown"
exports.completedService = "completedService" 
exports.onGoingService = "onGoingService"

exports.peopleData = [
    ['Category', 'Series 1', 'Series 2', 'Series 3'],
    ['A', 100, 200, 300],
    ['B', 200, 300, 400],
    ['C', 300, 400, 500],
    ['D', 30, 40, 50],
];

exports.ChartsColorsLibrary = [
  "#0094A7",
  "#E15656",
  "#1BCEDA",
  "#2B743F",
  "#B89D3D",
  "#FBA47F",
  "#93FFD1",
  "#78CD50",
  "#1f77b4",
  "#93FFD1",
  "#aec7e8",
  "#ff7f0e",
  "#ffbb78",
  "#2ca02c",
  "#98df8a",
  "#d62728",
  "#ff9896",
  "#9467bd",
  "#c5b0d5",
  "#8c564b",
  "#c49c94",
  "#e377c2",
  "#f7b6d2",
  "#7f7f7f",
  "#c7c7c7",
  "#bcbd22",
  "#dbdb8d",
  "#17becf",
  "#9edae5",
  "#1f77b4",
  "#aec7e8",
  "#ff7f0e",
  "#ffbb78",
  "#2ca02c",
  "#98df8a",
  "#d62728",
  "#ff9896",
  "#9467bd",
  "#c5b0d5",
  "#8c564b",
  "#c49c94",
  "#e377c2",
  "#f7b6d2",
  "#7f7f7f",
  "#c7c7c7",
  "#bcbd22",
  "#dbdb8d",
  "#17becf",
  "#9edae5",
  "#1f77b4",
  "#aec7e8",
  "#ff7f0e",
  "#ffbb78",
  "#2ca02c",
  "#98df8a",
  "#d62728",
  "#ff9896",
  "#9467bd",
  "#c5b0d5"
];


// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { extractConfirmationParams } from "../../../components/src/Utils"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string,
  type: string,
  showReponse: boolean,
  responseMessage: string,
  lang: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailConsentController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  confirmAPICallID: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: '',
      type: '',
      lang: '',
      showReponse: false,
      responseMessage: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): Promise<void> {
    
    const query = location.href;
    const {token, lang} = extractConfirmationParams(query) as any;

    this.setState({
      token: token,
      lang: lang
    })

    return Promise.resolve()
  }

  receive(from: string, message: Message): void {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.confirmAPICallID) {
        this.handleConfirmSuccess(responseJson)
      }
    }
  }

  handleSubmit = async () => {
    const { token} = this.state;
    this.setState( {
      type: 'Reject'
    }, async () => {
      const requestQuery = { type: this.state.type, token, lang: this.state.lang } ; 

      this.confirmAPICallID = await this.requestGetMessageCall({
        method: 'POST',
        endPoint: configJSON.confirmEndpoint + `?${new URLSearchParams(requestQuery).toString()}`,
        typeContent: configJSON.validationApiContentType,
      })
    })
    
  }

  handleConfirmSuccess = (responseJson: any) => {
    if (responseJson?.message) {
      this.setState({
        showReponse: true,
        responseMessage: responseJson.message
      })
    }
    
  }
  
  handleCancel = () => {
    const { token} = this.state;

    this.setState( {
      type: 'Accept'
      
    }, async () => {
      const requestQuery = { type: this.state.type, token, lang: this.state.lang } ; 

      this.confirmAPICallID = await this.requestGetMessageCall({
        method: 'POST',
        endPoint: configJSON.confirmEndpoint + `?${new URLSearchParams(requestQuery).toString()}`,
        typeContent: configJSON.validationApiContentType,
      })
    })
  }

  requestGetMessageCall = async (valueData: {
    typeContent?: string;
    apiType?: string;
    method?: string;
    endPoint?: string;
    body?: {},
    type?: string;
  }) => {
    let { typeContent, method, endPoint, body } = valueData;
    const authToken = (await localStorage.getItem("authToken")) || "";
    let headerAuth = {
        "Content-Type": typeContent,
        token: authToken,
    };
    let messageAPI = new Message(getName(MessageEnum.RestAPIRequestMessage));
    
    messageAPI.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    messageAPI.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    messageAPI.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headerAuth)
    );
    body &&
			messageAPI.addData(
				getName(MessageEnum.RestAPIRequestBodyMessage),
                 body
			);
    runEngine.sendMessage(messageAPI.id, messageAPI);
    return messageAPI.messageId;
};
  // Customizable Area End
}

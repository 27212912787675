import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { NavigateToView , SearchApiEndpoint} from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  getProjectsResponse: (responseJson: any) => void;
  getProjectsRequest: (searchQuery: string) => void;
  filterList?: any;
  type: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isLoadingAutoComplete: boolean;
  hasFocusOnAutoComplete: boolean;
  autocompleteRef: React.RefObject<HTMLInputElement>;
  autoCompleteOptionsLength: number;
  autoCompleteSelectedId: number;
  autoCompleteOptions: any[];
  searchQuery: string;
  isVisible: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  searchDebounceTimer: any;
  apiForAutoCompleteSearchRequestId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      autocompleteRef: React.createRef(),
      isLoadingAutoComplete: false,
      autoCompleteOptionsLength: configJSON.autoCompleteOptionsLength,
      autoCompleteSelectedId: -1,
      hasFocusOnAutoComplete: false,
      autoCompleteOptions: [],
      searchQuery: "",
      isVisible: false
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    window.addEventListener("click", this.handleMouseClicksForAutoComplete);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      this.getAdvancedSearchList(token);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);

      if (apiRequestCallId === this.apiForAutoCompleteSearchRequestId) {
        this.handleResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleResponse = (responseJson: any) => {
    const {type} = this.props
    const responseFunctionMap = {
      "Project": this.getAutoCompleteSearchResponse,
      "Tender": this.getAutoCompleteSearchResponseTender,
      "Supplier": this.getAutoCompleteSearchResponseSupplier,
      "Partner": this.getAutoCompleteSearchResponsePartner,
    };
    
    const responseFunction = responseFunctionMap[type as keyof typeof responseFunctionMap ];
    if (responseFunction) {
      responseFunction(responseJson);
    }

  }

  getAutoCompleteSearchResponsePartner = (responseJson: any) => {
    const { error, data } = responseJson;

    if (error && typeof error === "string") {
      if (error === configJSON.projectModuleNoRecordsError) {
        this.setState({
          autoCompleteOptions: []
        });
      }
    }

    this.setState({
      isLoadingAutoComplete: false
    });

    if (data && Array.isArray(data)) {
      const apiPartnerData: any[] = data.map((partner: any) => {
        const { attributes: partnerDetails } = partner;
        return {
          project_name: partnerDetails.company_name,
          id: partner.id
        };
      });
      this.setState({
        autoCompleteOptions: apiPartnerData,
        hasFocusOnAutoComplete: true,
        isLoadingAutoComplete: false
      });
    }
  };

  getAutoCompleteSearchResponseTender = (responseJson: any) => {
    this.setState({
      isLoadingAutoComplete: false
    });
    const { error, data } = responseJson;
    if (error && typeof error === "string") {
      if (error === configJSON.projectModuleNoRecordsError) {
        this.setState({
          autoCompleteOptions: []
        });
      }
    }

      if (data && Array.isArray(data)) {
        const apiData: any[] = data.map((project: any) => {
          const { attributes: projectDetails } = project;
          return {
            project_name: projectDetails.name,
            id: project.id
          };
        });        
        this.setState({
          autoCompleteOptions: apiData,
          hasFocusOnAutoComplete: true,
          isLoadingAutoComplete: false
        });
    }
  };


 getAutoCompleteSearchResponseSupplier = (responseJson: any) => {
    this.setState({
      isLoadingAutoComplete: false
    });
    const { data } = responseJson;
      if (data ) {
        const apiData: any[] = data.map((project: any) => {
          return {
            project_name: project.supplier_name,
            id: project.id
          };
        });        
        this.setState({
          autoCompleteOptions: apiData,
          hasFocusOnAutoComplete: true,
          isLoadingAutoComplete: false
        });
    }
  };

  handleSearchItem = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
    }
    this.setState(
      { searchQuery: event.target.value },
      this.handleSearchDebounce
    );
  };

  handleSearchDebounce = () => {
    this.searchDebounceTimer = setTimeout(() => {
      if (this.state.searchQuery === "") {
        this.setState({
          autoCompleteOptions: []
        });
      } else {
        this.handleSubmitAutoCompleteSearchBar(this.state.searchQuery);
      }
    }, configJSON.autoCompleteTimerToSearch);
  };

  handleSubmitAutoCompleteSearchBar = (searchQuery: string) => {
    const {filterList, type} = this.props
    this.setState({
      isLoadingAutoComplete: true
    });
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined" ? localStorage.getItem("authToken") : null
    };
    let queryParam = "";
    for (let item in filterList) {
      if (Array.isArray(filterList[item]) && filterList[item].length > 0) {
        if (queryParam !== "") {
          queryParam += "&";
        }
        filterList[item].forEach((value: string) => {
          queryParam += `${item}[]=${encodeURIComponent(value)}&`;
        });
      }
    }  

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForAutoCompleteSearchRequestId = requestMessage.messageId;
    const apiEndPoint = SearchApiEndpoint[type as keyof typeof SearchApiEndpoint]
    const queryParams = queryParam ? `&${queryParam}` : '';
    const url = apiEndPoint  + searchQuery + queryParams + '&pages=1&per_page=10';

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAutoCompleteSearchResponse = (responseJson: any) => {
    this.setState({
      isLoadingAutoComplete: false
    });
    const { error, results } = responseJson;
    if (error && typeof error === "string") {
      console.log("Autocomplete error: ", error);
      if (error === configJSON.projectModuleNoRecordsError) {
        this.setState({
          autoCompleteOptions: []
        });
      }
    }

    if (results) {
      const { data } = results;
      if (data && Array.isArray(data)) {
        const apiData: any[] = data.map((project: any) => {
          const { attributes: projectDetails } = project;
          return {
            project_name: projectDetails.project_name,
            id: project.id
          };
        });
        this.setState({
          autoCompleteOptions: apiData,
          hasFocusOnAutoComplete: true,
          isLoadingAutoComplete: false
        });
      }
    }
  };

  handleActionsOnAutoSearchBar = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const {
      hasFocusOnAutoComplete,
      searchQuery,
      autoCompleteSelectedId,
      autoCompleteOptions
    } = this.state;
    if (hasFocusOnAutoComplete && ["ArrowUp", "ArrowDown", "Enter", "Escape"].includes(event.key)) {
      switch (event.key) {
        case "ArrowUp": {
          if (autoCompleteSelectedId !== -1) {
            this.setState({
              autoCompleteSelectedId: autoCompleteSelectedId - 1
            });
          }
          break;
        }
        case "ArrowDown": {
          this.setState({ autoCompleteSelectedId: autoCompleteSelectedId + 1 });
          break;
        }
        case "Enter": {
          if (autoCompleteSelectedId === -1) {
            this.setState({
              hasFocusOnAutoComplete: false
            });
            this.props.getProjectsRequest(searchQuery || " ");
            break;
          }
          if (
            this.state.autoCompleteOptionsLength === 5 &&
            autoCompleteSelectedId === 5
          ) {
            this.setState({
              autoCompleteOptionsLength: this.state.autoCompleteOptions.length
            });
            break;
          }
          const project = autoCompleteOptions[autoCompleteSelectedId];
          this.props.getProjectsRequest(project.project_name);
          this.setState({
            hasFocusOnAutoComplete: false,
            searchQuery: project.project_name
          });
          break;
        }
        case "Escape": {
          this.setState({ hasFocusOnAutoComplete: false });
          break;
        }
      }
    }
  };

  handleMouseClicksForAutoComplete = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      this.state.autocompleteRef.current &&
      !this.state.autocompleteRef.current.contains(target) &&
      this.state.hasFocusOnAutoComplete &&
      target &&
      target.innerText !== "Show more"
    ) {
      this.setState({
        hasFocusOnAutoComplete: false
      });
    }
  };

  handleSearchById = (id: string) => {
    const {type} = this.props
    if (id === configJSON.projectModuleNoRecordsFoundId) {
      this.handleClearAutoCompleteResults();
      return;
    }
    if (
      this.state.autoCompleteOptionsLength ===
        configJSON.autoCompleteOptionsLength &&
      id === configJSON.showMoreResultsId
    ) {
      this.setState({
        autoCompleteOptionsLength: this.state.autoCompleteOptions.length,
        hasFocusOnAutoComplete: true
      });
      return;
    }
    const project = this.state.autoCompleteOptions.find(
      option => option.id === id
    );
     this.props.navigation.push(`/${NavigateToView[type as keyof typeof NavigateToView]}/${project?.id}`)
  };

  handleClearAutoCompleteResults = () => {
    this.props.getProjectsRequest(" ")
    this.setState({
      searchQuery: "",
      hasFocusOnAutoComplete: false,
      autoCompleteOptions: [],
      autoCompleteOptionsLength: configJSON.autoCompleteOptionsLength
    });
  };

  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    }
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  getAdvancedSearchList = (token: string) => {
    if (
      this.state.firstNameSearchText.length === 0 &&
      this.state.lastNameSearchText.length === 0
    ) {
      return;
    }

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let attrs = null;

    if (
      this.state.firstNameSearchText.length > 0 &&
      this.state.lastNameSearchText.length > 0
    ) {
      attrs = {
        first_name: this.state.firstNameSearchText,
        last_name: this.state.lastNameSearchText
      };
    } else if (this.state.firstNameSearchText.length > 0) {
      attrs = {
        first_name: this.state.firstNameSearchText
      };
    } else if (this.state.lastNameSearchText.length > 0) {
      attrs = {
        last_name: this.state.lastNameSearchText
      };
    }

    this.advancedsearchApiCallId = requestMessage.messageId;

    //@ts-ignore
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}

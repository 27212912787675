Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.getUserProfileEndPoint = "account_block/accounts";
exports.putUserProfileEndPoint = "account_block/accounts";
exports.postPasswordCheckEndPoint = "account_block/accounts/check_recent_password";
exports.postNewProfileChangePasswordEndPoint = "account_block/accounts/reset_password";
exports.changeProfilePicture = "Change Profile Picture"
exports.profile = "Profile"
exports.changePassword = "Change Password"
exports.fullName = "Full Name"
exports.phoneNumber = "Phone number"
exports.edit = "Edit"
exports.emailAddress = "Email address"
exports.designation = "Designation"
exports.location = "Location"
exports.role = "Role"
exports.company = "Company"
exports.cancel = "Cancel"
exports.saveChanges = "Save changes"
exports.phoneNumberValidation = "Only numeric values and phone number must contain 7 to 15 digits."
exports.nameValidation = "Full name field is required"
exports.numberValidation = "Phone number field is required"
exports.changePassword = "Change Password"
exports.currentPassword = "Enter Current Password"
exports.toResetPassword = "To reset your password, please enter your current password."
exports.continue = "Continue"
exports.newPassword = "New Password"
exports.enterNewPassword = "Enter new password"
exports.confirmPassword = "Confirm password"
exports.SucessfullyReset = "Your password has been sucessfully reset"
exports.confirmPasswordError = "Password and Confirm Password does not match"
exports.newPasswordError = "Please enter new password"
exports.changePasswordError = "Password incorrect"
// Customizable Area End

import Alert from '@material-ui/lab/Alert';
import {
  Grid,
  Theme,
  Box,
  createTheme,
  MuiThemeProvider,
  createStyles,
  Typography,
  Accordion,
  TableRow as Row,
  TableCell as Cell,
  TableBody as Body,
  TableHead as Head,
  Table,
  TableContainer,
  Paper,
  AccordionDetails,
  AccordionSummary,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  withStyles,
} from "@material-ui/core";
import React from "react";

import ProjectModuleHeader from "../../multipageforms/src/Components/ProjectModule/ProjectModuleHeader.web";
import SupplierImportDataController, { Props, configJSON } from "./SupplierImportDataController.web";
import ImportView from "./Components/ImportView.web";
import CustomButton from "../../../components/src/CustomButton.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const THEME = createTheme({
  typography: {
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontFamily: "Source Sans Pro",
    fontSize: 14,
    fontWeightLight: 300,
  },
});

export class SupplierImportData extends SupplierImportDataController {

  constructor(props: Props) {
    super(props);
  }

  renderImportView() {
    const classes = this.props.classes;

    return (
      <Box marginTop={2} className={classes.containerSucess}>
        <ProjectModuleHeader data-testId="projectModuleHeader" saveAsPDF={false} handleBackClick={this.handleGoBack}/>
        <ImportView
          data-testId="importView"
          navigation={this.props.navigation}
          onSubmitImport = {this.handleUploadFile}
          sampleXLSFile={configJSON.sampleXLSXSupplierData}
          sampleCSVFormat={configJSON.exportSampleSupplierCSVData}
          module={"supplier"}
        />
      </Box>
    );
  }

  renderSuccessImport() {
    const classes = this.props.classes;
    const { reportMetaData } = this.state
    return (
      <Box 
        paddingBottom={2}
        className={classes.containerSucess} 
        marginTop={2} 
      >
        <ProjectModuleHeader 
          handleBackClick={this.handleGoBack}
          saveAsPDF={false} 
          data-testId="projectModuleHeader" 
        />
        <Grid container alignItems="center" justifyContent="center">
          <Grid md={8} lg={7} xs={12} sm={10} item>
            <Box marginTop={2} className={classes.sucessBox}>
              <Alert severity="success" data-testId="summary" className={classes.alertBox}>
                { reportMetaData?.summary.summaryMessage  }
              </Alert>

              <Box className={classes.listErrorBox}>
                <Accordion 
                  style={{width: '100%'}}  
                  className={classes.customAccordion}
                  key={`report_summary`} 
                >
                  <AccordionSummary
                    id="panel1a-header"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    style={{borderBottom: "1px solid #f3f3f3"}}
                    
                  >
                    <Typography className={classes.headingReport} variant='body1'>{ reportMetaData?.importedMessage }</Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <List className={classes.listDetailReport} dense={true}>
                        <ListItem key={`error_report_1`}> <ListItemText primary={ reportMetaData?.summary.createdRecords } /> </ListItem>
                        <ListItem key={`error_report_2`}> <ListItemText primary={ reportMetaData?.summary.oveririddenRecords} /> </ListItem>
                    </List>
                  </AccordionDetails>
              
                </Accordion>

                <Accordion 
                  className={classes.customAccordion}
                  style={{width: '100%'}}
                  key={`report_skip`} 
                >
                  <AccordionSummary
                    id="panel1a-header"
                    expandIcon={<ExpandMoreIcon />}
                    style={{borderBottom: "1px solid #f3f3f3"}}
                    aria-controls="panel1a-content"
                  >
                    <Typography className={classes.headingReport} variant='body1'> { reportMetaData?.skippedMessage } </Typography>
                  </AccordionSummary>
                  { (reportMetaData?.skippedErrors || []).length > 0 &&
                    <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table size="small" className={classes.table}>
                        <Head>
                          <Row>
                            <Cell>Row Number</Cell>
                            <Cell>Company Name</Cell>
                            <Cell>Error Message</Cell>
                          </Row>
                        </Head>
                        <Body>
                          {reportMetaData?.skippedErrors.map((row) => (
                            <Row key={`row_${row.row}`}>
                              <Cell component="th">
                                Row {row.row}
                              </Cell>
                              <Cell>{row?.value || 'Nil'}</Cell>
                              <Cell>{row.error}</Cell>
                            </Row>
                          ))}
                        </Body>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                  }
                  
                
                </Accordion>

                <Accordion 
                  className={classes.customAccordion}
                  style={{width: '100%'}}
                  key={`report_override`} 
                >
                  <AccordionSummary
                    id="panel1a-header"
                    expandIcon={<ExpandMoreIcon />}
                    style={{borderBottom: "1px solid #f3f3f3"}}
                    aria-controls="panel1a-content"
                  >
                    <Typography className={classes.headingReport} variant='body1'> 
                        {reportMetaData?.notImportMessage} 
                    </Typography>
                  </AccordionSummary>
                  { (reportMetaData?.recordErrors || []).length > 0 &&
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table 
                          className={classes.table}
                          size="small" 
                        >
                          <Head>
                            <Row>
                              <Cell>Row Number</Cell>
                              <Cell>Company Name</Cell>
                              <Cell>Field Name</Cell>
                              <Cell>Error Message</Cell>
                            </Row>
                          </Head>
                          <Body>
                            {reportMetaData?.recordErrors.map((row) => (
                              <Row key={`row_${row.row}`}>
                                <Cell component="th">
                                  Row {row.row}
                                </Cell>
                                <Cell>{row?.value || 'Nil'}</Cell>
                                <Cell>{row.fieldName}</Cell>
                                <Cell>{row.error}</Cell>
                              </Row>
                            ))}
                          </Body>
                        </Table>
                      </TableContainer>
                  </AccordionDetails>
                  }
                  
                </Accordion>
              </Box>
          
              <CustomButton data-testId="addAnotherBtn" style={{ marginTop: 16 }} onClick={() => this.resetImport() }>Add Another</CustomButton>
            </Box>
            
          </Grid>
        </Grid>
      </Box>

    )
  }

  
  render() {
    // Customizable Area Start
    return (<>
      <MuiThemeProvider theme={THEME}>
        <div style={customStyle.rootContainer}>
        {
        this.state.showReport ? 
          this.renderSuccessImport() 
        : this.renderImportView()
        }
          {this.state.hasError && (
          <Snackbar
            open={!!this.state.hasError}
            onClose={() => {
              this.setState({
                hasError: "",
              });
            }} 
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert 
              severity="error"
              data-testId="snack-error" 
            >
              { this.state.hasError }
            </Alert>
          </Snackbar>
        )}
        </div>
      </MuiThemeProvider>
    </>);
    // Customizable Area Start
  }
}

export const styles = (theme: Theme) =>
  createStyles({
    expandSidebarIconBox: {   
      width: "20px",
      height: "20px",
      transition: "left 0.4s ease-out",
      borderRadius: "8px",
      padding: "5px",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      top: "127px",
      backgroundColor: "rgb(253, 215, 47)",
      zIndex: 1,
      cursor: "pointer",
      display: "flex",
    },
    earch: {
      position: "relative",
      "&:hover": {
        backgroundColor: "white",
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px'
      },
      "& fieldset": {
        border: "1px solid #E8E8E8 !important"
      },
      borderRadius: '8px',
      "& .MuiOutlinedInput-adornedStart": {
      },
      backgroundColor: "white",
      marginLeft: 0,
      width: "40%",
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '0px'
      },
      "& .MuiOutlinedInput-input": {
        padding: '14px'
      }
    },
    mainChildWrapper: {
      marginTop: "85px",
      transition: "width 0.4s ease-out",
      minHeight: "100vh",
    },
    mainContainer: {
      position: "relative",
      display: "flex",
    },
    sucessBox: {
      background: "rgba(0, 148, 167, 0.08)",
      padding: "40px",
      justifyContent: "center",
      flexDirection: "column",
      borderRadius: "8px",
      alignItems: "center",
      marginBottom: 24,
      // border: "1px solid rgba(0, 148, 167, 0.40)",
      display: "flex",
    },
    sidenav: {
      paddingTop: "28px",
      transition: "width 0.3s ease-in-out",
      height: "100vh",
      width: "250px",
      backgroundColor: "rgb(10, 25, 41)",
    },
    uploadFileBox: {
      flexDirection: "column",
      gap: "20px",
      display: "flex",
      background: "rgba(0, 148, 167, 0.08)",
      borderRadius: "8px",
      alignItems: "center",
      justifyContent: "center",
      padding: "40px",
      // border: "1px solid rgba(0, 148, 167, 0.40)",
    },
    sidenavClosed: {
      composes: "sidenav",
      width: "60px",
      transition: "width 0.3s ease-in-out",
    },
    container: {
      backgroundColor: "#FFF",
      height: "100%",
      display: "flex",
      gap: "25px",
      marginTop: "20px",
      flexDirection: "column",
    },
    sideitem: {
      display: "flex",
      color: "#b2bac2",
      padding: "10px 20px",
      overflow: "hidden",
      textDecoration: "none",
      cursor: "pointer",
      alignItems: "center",
     
    },
    "& .sideitem:hover": {
      backgroundColor: "#244f7d1c",
    },
    menuBtn: {
      cursor: "pointer",
      transition: "width 0.3s ease-in-out",
      top: "124px",
      boxShadow: "3px",
      alignSelf: "center",
      position: "relative",
      backgroundColor: "transparent",
      border: "none",
      borderRadius: "10px",
      justifySelf: "flex-end",
      color: "#b2bac2",
      paddingLeft: "20px",
      left: "40px",
      zIndex: 1000000,
    },
    linkText: {
      paddingLeft: "16px",
    },
    menuBtnExpand: {
      top: "124px",
      backgroundColor: "transparent",
      transition: "width 0.3s ease-in-out",
      paddingLeft: "20px",
      alignSelf: "center",
      border: "none",
      justifySelf: "flex-end",
      color: "#b2bac2",
      position: "relative",
      zIndex: 1000000,
      left: "230px",
      cursor: "pointer",
    },
    containerSucess: {
      height: "100%",
      width: '100%',
      backgroundColor: "#FFF",
      "@media (max-width: 960px)": {
        padding: 5
       },
    
    },
    headingReport: {
      color: "#6a6e6f",
      marginLeft: '8px',
      fontSize: 16,
      "& span": {
        marginLeft: 4,
        color: "#f50057",
        fontWeight: 600,
      }
    },

    customAccordion: {
      '& .MuiAccordion-root.Mui-expanded': {
        margin: 0
      }
    },

    reportText: {
      textTransform: 'uppercase',
      color: '#0094a7',
      fontWeight: 600,
      marginBottom: '24px',
    },

    listDetailReport: {
      "& .MuiTypography-body2": {
        color: "#6a6e6f",
      }
    },
    reportSingleBox: {
      margin: '8px 0',
      width: '100%',
      padding: '12px',

      "& span.value": {
        color: '#3c7e40',
        fontWeight: '600'
      },
      "& span.title": {
        width: 150,
        display: 'inline-block',
        color: "#1f1f1f"
      },
    },
    
    listErrorBox: {
      width: '100%',
      maxHeight: '600px',
      overflowY: 'scroll'
    },

    table: {
      minWidth: 650,
    },

    alertBox: {
      width: '100%',
      marginBottom: '12px'
    },

    rowErrorText: {
      marginLeft: -20,
      color: "#4c4c4c",
      textAlign: 'left',
      width: '100%',
      marginBottom: '10px',
    },
});

export const customStyle = {
  rootContainer: {
    backgroundColor: '#F3F3F3',
    width: '100%',
    display: 'flex',
    fontFamily: 'Source Sans Pro',
  },
  filterContain: {}
}

export default withStyles(styles)(SupplierImportData)
import React from "react";
import { createStyles, withStyles } from "@material-ui/styles";
import Alert from '@material-ui/lab/Alert';
import {
    importExportIconSvg
} from "./assets";
import CustomButton from '../../../components/src/CustomButton.web';
import ProjectImportDataController, {Props} from "./ProjectImportDataController";
import { AccessControl } from "../../../components/src/Permission"
import { Snackbar } from "@material-ui/core";

class ProjectExportData extends ProjectImportDataController {
    constructor(props: Props) {
        super(props);
    }

    renderImportExport() {
        const { classes } = this.props;
        return (<>
            <div ref={this.state.customPaperRef} style={{ position: 'relative' }}>
                <CustomButton
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"
                    colorVariant="dropdownButton"
                    data-testid="exportimportBtn"
                    truncateSM = {true}
                    startIcon={<img src={importExportIconSvg} alt='import/export' />}
                    onClick={this.handleImportExportButtonClick}
                >
                    Import & Export
                </CustomButton>
                {this.state.hasImportExportButtonClicked &&
                    <div
                        data-testId="export-menu"
                        className={classes.customPaper}
                        onMouseLeave={this.handleMouseLeave}
                    >
                        <div
                            className={classes.importExportMenuItem}
                            data-testid="exportAsPDF"
                            onClick={this.handleExportPdfToken}
                        >
                            Export as PDF
                        </div>

                        <div
                            className={classes.importExportMenuItem}
                            data-testid="exportAsCSV"
                            onClick={this.handleExportCSVToken}
                        >
                            Export as CSV
                        </div>

                        <AccessControl
                            module="Project"
                            feature="import"
                        >
                            <div
                                className={classes.importExportMenuItem}
                                data-testid="importAsCSV"
                                onClick={this.navigateImportPage}
                            >
                                Import as CSV
                            </div>
                        </AccessControl>
                        
                    </div>
                }
            </div>
        </>);
    }

    render() {
        return (<>
            {this.renderImportExport()}
            {this.state.hasError && (
              <Snackbar
                data-testId="projectSnackbar"
                open={!!this.state.hasError}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                autoHideDuration={5000}
                onClose={()=>this.setState({ hasError: ""})}
                style={{ border: '1px solid #4caf50', borderRadius: "4px" }}
              >
                <Alert data-testId="projectSnackbarMsg" severity="success">
                  {this.state.hasError}
                </Alert>
              </Snackbar>
            )}
        </>);
    }
}

const styles = () =>
    createStyles({
        importExportMenuItem: {
            padding: '10px 20px',
            color: '#0094A7',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16px',
            fontFamily: 'Source Sans Pro',
            cursor: 'pointer',
            transition: "background-color 0.3s",
            width: '110px',
            '&:hover': {
                backgroundColor: "#F0F0F0",
            }
        },

        customPaper: {
            marginTop: '4px',
            borderRadius: '4px',
            border: '0.5px solid #0094A7',
            boxShadow: '-4px 4px 30px 0px rgba(0, 148, 167, 0.16)',
            backgroundColor: '#FFF',
            position: "absolute",
            zIndex: 5,
            "@media (max-width: 600px)": {
                right: "0",
            },
        },

        progressBox:{
            marginRight: "10px"
        },
 
        exportBox: {
             display: "flex",
             alignItems: "center",
             justifyContent: "space-between"
        },
 
        exportProgressBar:{
            top:0,
            left:0,
            bottom:0,
            right:0,
            position:"absolute",
            display:"flex",
            alignItems:"center",
            justifyContent:"center"
        }
    });


export default withStyles(styles)(ProjectExportData);
export { ProjectExportData };

// Customizable Area Start
import React, { Component } from "react";
import {
  Box,
  ClickAwayListener,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import MoreVertIcon from '@material-ui/icons/MoreVert';
// Customizable Area End

// Customizable Area Start
interface Props {
  id: any;
  navigation: any;
  tableInputs: {
    apiData: any;
    columsList: any;
  };
  pagination: {
    currentPage: number;
    totalLength: number;
    rowsPerPage: number;
  };
  handlePageChange: (event: any, newPage: number) => void;
  handleMorevertMenuClick: (value: string | number, path: string) => void;
  enabledTable?: string;
}

interface S {
  morvertAnchorEl: EventTarget & HTMLElement | null;
  isMorvertOpen: any;
}
// Customizable Area End
class RequestTable extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      morvertAnchorEl: null,
      isMorvertOpen: null,
    }
    // Customizable Area End
  }

  // Customizable Area Start
  renderStatusLabel = (row: any, col: any) => {
    const value = col?.filterOptions?.find((obj: any) => obj.value === row[col.dataLabel])
    const bgColor = value?.bgColor || "#f7f7f7";
    const textColor = value?.textColor || "#f7f7f7";
    const text = value?.label || "";
    const newText = text.charAt(0).toUpperCase() + text.slice(1)
    return (
      <StatusBtn
        style={{ background: bgColor, color: textColor }}
      >
        {newText}
      </StatusBtn>
    );
  }

  handleClose = () => {
    this.setState({
      isMorvertOpen: null,
      morvertAnchorEl: null,
    });
  }

  handleOpenMorevert = (e: any, value: string | number) => {
    this.setState({
      isMorvertOpen: value,
      morvertAnchorEl: e.currentTarget,
    })
  }

  renderMorevert = (row: any, col: any) => {
    const { isMorvertOpen, morvertAnchorEl } = this.state
    const { enabledTable } = this.props
    const menuItems = [
      {
        label: "View Details",
        value: row[col.dataLabel]
      },
      ...(enabledTable && enabledTable === "Request received" && row.status!== "closed" ? [{
        label: "Edit",
        value: row[col.dataLabel]
      }] : []
      )
    ]
    return (
      <>
        <MorevertIconButton
          data-test-id="Menu-open-event"
          onClick={(e) => this.handleOpenMorevert(e, row[col.dataLabel])}
          style={{ background: isMorvertOpen === row[col.dataLabel] ? "#E7E7E7" : "#FFFFFF" }}
        >
          <MoreVertIcon />
        </MorevertIconButton>
        <Popper
          anchorEl={morvertAnchorEl}
          open={isMorvertOpen === row[col.dataLabel]}
          placement="bottom-end"
          style={{ zIndex: 1000000 }}
          transition
          disablePortal
        >
          <ClickAwayListener touchEvent={false} onClickAway={this.handleClose}>
            <CustomPaper>
              <MenuList
                autoFocusItem={this.state.isMorvertOpen}
                id="menu-list-grow"
                data-test-id="Menu-Close-event"
              >
                {menuItems.map(item =>
                  <MenuItem   
                    key={item.label} 
                    onClick={() => {
                      this.props.handleMorevertMenuClick(item.value, item.label);
                      this.handleClose();
                    }}
                  >
                    {item.label}
                  </MenuItem>)}
              </MenuList>
            </CustomPaper>
          </ClickAwayListener>
        </Popper>
      </>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { currentPage, totalLength, rowsPerPage } = this.props.pagination;
    const count = Math.ceil(totalLength / rowsPerPage || 1);

    return (
      <>
        <RequestTableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {this.props.tableInputs.columsList.map(
                  (headerRow: any, indexOfCL: number) => (
                    <TableCell
                      key={`sub-header-${indexOfCL * 5}`}
                      style={{
                        minWidth: headerRow.width,
                      }}
                    >
                      <div
                        className="tableHeadCellContent"
                        style={{
                          justifyContent: headerRow.textAlign
                            ? headerRow.textAlign
                            : "space-between",
                        }}
                      >
                        {!headerRow.hideLabel && headerRow.displayLabel}
                      </div>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.tableInputs.apiData.map(
                (row: any, indexOfAD: number) => (
                  <TableRow key={`${row.name}-${indexOfAD * 6 + 3}`}>
                    {this.props.tableInputs.columsList.map(
                      (headerRow: any, indexOfCL: number) => (
                        <TableCell key={`cellBody--${indexOfCL * 4 + 7}`}>
                          {headerRow.type === "value" &&
                            typeof row[headerRow.dataLabel] == "string" &&
                            row[headerRow.dataLabel]}
                          {
                            headerRow.type === "status" && this.renderStatusLabel(row, headerRow)
                          }
                          {
                            headerRow.type === "morevert" && this.renderMorevert(row, headerRow)
                          }

                        </TableCell>
                      )
                    )}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </RequestTableContainer>
        <CustomePagination
          count={count}
          page={currentPage}
          onChange={this.props.handlePageChange}
          variant="outlined"
          shape="rounded"
          disabled={!count}
        />
      </>
    );

    // Customizable Area End
  }
}

// Customizable Area Start
export const StatusBtn = styled(Box)({
  fontFamily: "Source Sans Pro",
  fontSize: "16px",
  display: "flex",
  width: "fit-content",
  height: "8px",
  borderRadius: "32px",
  padding: "8px 9px",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  flexShrink: 0,
  lineHeight: "16px",
  fontStyle: "normal",
  fontWeight: 400,
})

const CustomePagination = styled(Pagination)({
  flexWrap: "nowrap",
  "& li:first-child": {
    "& button:disabled": {
      display: "none"
    },
    "& button": {
      color: "black"
    },
  },
  "& li:last-child": {
    "& button": {
      color: "black"
    },
  },
  "& li": {
    "& .MuiPaginationItem-outlined": {
      border: 'none',
      color: '#00000066',
      height: "32px",
      fontFamily: "Source Sans Pro",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px"
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      background: 'transparent',
      color: "black",
      cursor: "default",
    },
  },
  "@media (max-width: 600px)": {
    flexWrap: "wrap",
    "& li": {
      "& .MuiPaginationItem-root": {
        margin: "4px",
      },
    },
  }
})

export const RequestTableContainer = styled(TableContainer)({
  maxHeight: "410px",
  "&::-webkit-scrollbar": {
    height: "10px",
    width: "10px",
    backgroundColor: "#E8E8E8",
    borderRadius: "22px",
  },
  backgroundColor: "#fff",
  "&::-webkit-scrollbar-track": {
    backgroundColor: "#E8E8E8",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "22px",
    backgroundColor: "#0094A7",
  },
  "& table": {
    "& thead": {
      background: "white",
      "& tr": {
        "& th": {
          backgroundColor: "white",
          padding: "8px",
          "& .tableHeadCellContent": {
            display: "flex",
            alignItems: "center",
            fontSize: "16px",
            gap: "4px",
            fontWeight: 600,
            color: "#1F1F1F",
            fontFamily: "Source Sans Pro",
          }
        }
      }
    },
    "& tbody": {
      background: "white",
      "& tr": {
        height: "100%",
        minHeight: "45px",
        "& td": {
          padding: "8px",
          fontSize: "16px",
          fontWeight: 400,
          fontFamily: "Source Sans Pro",
          color: "#000"
        }
      }
    }
  }

})

export const CustomPaper = styled(Paper)({
  boxSizing: "border-box",
  borderRadius: "4px",
  border: "1px solid #E0E0E0",
  boxShadow: '0px 4px 11px 0px rgba(0, 0, 0, 0.08) !important',
  "& ul": {
    padding: "4px 1px",
    "& li": {
      color: "#000",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "22px",
      padding: "4px 8px",
    }
  }
})

const MorevertIconButton = styled(IconButton)({
  borderRadius: "50%",
  border: "1px solid transparent",
  padding: "5px",
  "&:hover": {
    background: "#E7E7E7"
  },
  "& .MuiIconButton-label": {
    "& .MuiSvgIcon-root": {
      fill: "#000"
    }
  }
})


export default RequestTable;
// Customizable Area End

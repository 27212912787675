// Customizable Area Start

export const truncateText = (text: string, truncateLength: number) => {
  if (text === undefined || text === null) {
    return "";
  }
  if (text.length > truncateLength) {
    return `${text.slice(0, truncateLength)}...`;
  } else {
    return text;
  }
};
export const isFilterApplied = (arrays: any) => {
  let isFilterApplied: boolean = false;

  for (const array of arrays) {
    if (array && Array.isArray(array) && array.length > 0) {
      return true;
    }
  }
  return isFilterApplied;
};

export enum StatusFieldKeys {
  people = 'status',
  projects = 'project_status',
  tenders = 'tender_status',
  suppliers = 'supplier_status',
  parters = 'parter_status'
}

export enum StatusEndPoints {
  people = "bx_block_company/statuses",
  projects = "bx_block_company/project_statuses",
  tenders = "bx_block_company/tender_status",
  suppliers = "bx_block_company/tender_status",
  partners = "bx_block_company/tender_status"
}

export enum ListSaveSearchType {
  people = "BxBlockPeople::PeopleSavedSearch",
  projects = "BxBlockProfile::ProjectSavedSearch",
  tenders = "BxBlockProfile::TenderSavedSearch",
  suppliers = 'BxBlockSavesearch::SupplierSavedSearch',
  partners = 'BxBlockSavesearch::PartnerSavedSearch'
}

export const filterIcon = require("../assets/FilterIcon.svg");
export const currentPositionIcon = require("../assets/CurrentPosition.svg");
export const educationIcon = require("../assets/Education.svg");
export const experienceIcon = require("../assets/Experience.svg");
export const favouriteIcon = require("../assets/Favourite.svg");
export const groupRelationIcon = require("../assets/GroupRelation.svg");
export const keywordIcon = require("../assets/Keyword.svg");
export const languageIcon = require("../assets/Language.svg");
export const nationalityIcon = require("../assets/Nationality.svg");
export const savedSearchIcon = require("../assets/SavedSearch.svg");
export const expandFilterIcon = require("../assets/expandFilter.svg");
export const expandSavedSearchIcon = require("../assets/expandSavedSearch.svg");
export const closeSavedSearchIcon = require("../assets/closeSavedSearchIcon.svg");
export const filterDropdownIcon = require("../assets/filterDropdown.svg");
export const collapseFilterIcon = require("../assets/collapseFilter.svg");
export const accordionExpandIcon = require("../assets/AccordionExpand.svg");
export const accordionCollapseIcon = require("../assets/AccordionCollapse.svg");
export const paginationDropdownIcon = require("../assets/PaginationDropdown.svg");
export const checkboxCheckedIcon = require("../assets/Checkedbox.svg");
export const saveCheckIcon = require("../assets/saveCheckIcon.svg");
export const saveUncheckIcon = require("../assets/SaveUncheckIcon.svg");
export const saveFilterIcon = require("../assets/SaveFilterIcon.svg");
export const checkboxUncheckedIcon = require("../assets/UncheckedBox.svg");
export const projectNameIcon = require("../assets/projectname.svg");
export const groupCompanyIcon = require("../assets/groupcompany.svg");
export const clientsIcon = require("../assets/clients.svg");
export const countryIcon = require("../assets/country.svg");
export const servicesIcon = require("../assets/services.svg");
export const sectorIcon = require("../assets/sector.svg");
export const valueOfWorkIcon = require("../assets/valueofwork.svg");
export const statusIcon = require("../assets/status.svg");
export const close = require("../assets/close.svg");
// Customizable Area End

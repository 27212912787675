import React from "react";

import {
  Box,
  Typography,
  // Customizable Area 
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  }
});
// Customizable Area End

import Audittrail2Controller, {
  AuditTrailGetlistType,
  Props,
  configJSON,
} from "./Audittrail2Controller";

export default class Audittrail2 extends Audittrail2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box style={webStyle.atMainContainer}>
          <Box>
            <Typography
              style={webStyle.auditheadingbox}
              data-testId="auditTrailTestId"
              >
              Audit trail
            </Typography>
          </Box>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle: Record<string, React.CSSProperties> = {
  auditheadingbox: {
    color: '#1F1F1F',
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: "0.5px",
    marginBottom: '10px',
    fontFamily: 'Source Sans Pro , sans-serif'
  },
  atMainContainer: {
    marginTop:"30px",
    fontFamily: 'Source Sans Pro , sans-serif'
  },
};
// Customizable Area End

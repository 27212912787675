Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SaveAsPdf";
exports.labelBodyText = "SaveAsPdf Body";

exports.labelSaveButton = "Save as PDF";
exports.peopleSaveEndpoint = "/bx_block_saveaspdf2/people_pdf"
exports.projectSaveEndpoint = "/bx_block_saveaspdf2/project_pdf"
exports.tenderSaveEndpoint = "bx_block_saveaspdf2/tender_pdf"
exports.supplierSaveEndpoint = "bx_block_saveaspdf2/supplier_pdf"
exports.partnerSaveEndpoint = "bx_block_saveaspdf2/supplier_pdf"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
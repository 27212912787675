export const requestIcon = require("../assets/image_request.png");

export type IModule = 'people' | 'project' | 'tender' | 'supplier' | 'partner';

export type MapValueFunc = (rowData: any, index: number) => string | React.ReactElement;

export interface RowColumn {  
    label: string;
    dataKey: string;
    type?: 'value' | 'link';
    mapValue?: MapValueFunc;
}

export type RowSchema = {
    [module in IModule]?: RowColumn[];
};


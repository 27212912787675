
// Customizable Area Start
import React from 'react';
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
	getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';
import { countSection, handleSectionNavigateLogic, isValidLink, validateHtmlContent, removeErrorsActiveKey } from "../../../components/src/Utils";
import { getStoredDefaultRole } from '../../../components/src/ContextAPI/AdvancedFilterContext.web';
// Customizable Area End

// Customizable Area Start
export const configJSON = require('./config');

export interface ValidResponseType {
	data: Array<object>;
	errors: Array<ErrorResponseType>
}
export interface ErrorPayloadType {
	key: string;
	message: string;
}
export interface ErrorResponseType {
	errors: string;
}
export interface InvalidResponseType {
	errors: Array<ErrorPayloadType>;
	error: string;
	message: string;
}

export interface Props {
	navigation: any;
	id: string;
	// Customizable Area Start
	classes: Record<string, string>;
	// Customizable Area End
}

export interface ErrorObject {
	[key: string]: string;
}

export type ModuleMode = 'update' | 'read' | 'post';

export interface ISupplierData {
	mode: ModuleMode | string;
	companyname: string;
	email: string;
	countryCode: string;
	phoneNumber: string;
	website: string;
	groupCompany: string;
	country: string;
	services: string;
	sectors: string;
	internalRating: string;
	isCancelButtonEnabled: boolean;
	errors: ErrorObject[];
}

export interface SupplierDetailsData {
	data:
	{
		id: string;
		type: string;
		attributes: SupplierData
	},
	error?: string | null;
}

export interface SupplierData {
	company_name: string,
	email_address: string,
	phone_number: string,
	website: string,
	internal_rating: string,
	company_detail: {
		id: number,
		group_company: string,
		country: string,
		service: [
			{
				id: string,
				name: string,
				created_at: string,
				updated_at: string
			}
		],
		sector: {
			id: string,
			name: string,
			created_at: string,
			updated_at: string
		}
	}
}

export interface HasSuccessErrorType {
	isOpen: boolean;
	isSeverity: 'success' | 'info' | 'warning' | 'error';
	isMessage: string;
}

export interface IAuditTrail {
	mode: ModuleMode;
}

export interface IAuditTrailSteps {
	label: string;
	description: string;
	date: string;
	time: string;
}

export interface DropDownItems {
	label: string;
	value: string;
}
export interface Brochure {
	id : number;
	file_id: number;
	file_name: string;
	file_link: string;
	upload_date: string;
}

export interface BrochureData {
	        id: string,
            type: string,
            attributes: {
                brochures: {
					id : number,
                    file_link: string,
                    file_name: string,
                    upload_date: string,
                    file_id: number
                }
            }
}

export interface SupplierBrochureData {
	data : Array<BrochureData>,
	error: string | null
}

export interface SupplierNotesData {
	notes : string,
	error?: string,
}

// Customizable Area End
export interface S {
	// Customizable Area Start
	hasError: any;
	loggedInUserFullName: string | null;
	activeSection: string;
	supplierId: string;
	supplierDetails: ISupplierData;
	staticSupplierDetails: ISupplierData;
	deleteError: boolean;
	auditTrail: IAuditTrail;
	activeStep: number;
	isLoading: boolean;
	SupplierError: boolean;
	isAuditTrail: boolean;
	groupResponse: DropDownItems[];
	countryResponse: DropDownItems[];
	servicesResponse: DropDownItems[];
	sectorResponse: DropDownItems[];
	internalRateResponse: DropDownItems[];
	companyDetailsId: number;
	mode: string | "get" | "post" | "put";
	brochure: Brochure[];
	staticBrochure: Brochure[];
	brochureData: BrochureData[];
	errors: ErrorObject[];
	brochureToDelete: number[];
	onBrochureUpload: boolean;
	brochureToUpload: File[];
	isPendingApi: boolean;
	isCancelBrochureEnabled: boolean;
	documentError: string;
	notesData: string;
	oldNotes: string;
	staticNotesData: string;
	notesError: string;
	hasSuccessError: HasSuccessErrorType;
	supplierNotesLoader: boolean;
	// Customizable Area End
}

interface SS {
	// Customizable Area Start
	id: any;
	// Customizable Area End
}

export default class SupplierModuleController extends BlockComponent<
	Props,
	S,
	SS
> {
	// Customizable Area Start
	getGroupApiCallId: string = '';
	getCountriesApiCallId: string = '';
	getServicesApiCallId: string = '';
	getSectoresApiCallId: string = '';
	getInternalRateApiCallId: string = '';
	getSupplierDetailsApiCallId: string = '';
	postSupplierDetailsApiCallId: string = '';
	updateSupplierDetailsApiCallId: string = '';
	apiCallIdForUploadBrochure: string = "";
	apiCallIdForRemoveDocument: string = "";
	getBrochureApiCallId : string = "";
	getSupplierNotesApiCallId : string = "";
	postSupplierNotesApiCallId : string = "";
	sections: string[];
	contentRef: React.RefObject<HTMLDivElement>
	countableSection = countSection(configJSON.SupplierModuleSections);
	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);
		// Customizable Area Start
		this.subScribedMessages = [
			getName(MessageEnum.CountryCodeMessage),
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.ReciveUserCredentials),
			getName(MessageEnum.NavigationMessage),
			getName(MessageEnum.NavigationTargetMessage),
			getName(MessageEnum.NavigationPropsMessage),
		];

		this.state = {
			isLoading: true,
			hasError: '',
			loggedInUserFullName: null,
			activeSection: 'Supplier Details',
			supplierId: '',
			deleteError: false,
			activeStep: 0,
			SupplierError: false,
			supplierDetails: {
				mode: 'post',
				companyname: '',
				email: '',
				phoneNumber: '',
				countryCode: '',
				website: '',
				groupCompany: '',
				country: '',
				services: '',
				sectors: '',
				internalRating: '',
				isCancelButtonEnabled: false,
				errors: [],
			},
			staticSupplierDetails: {
				mode: 'read',
				companyname: '',
				email: '',
				countryCode: '',
				phoneNumber: '',
				website: '',
				groupCompany: '',
				country: '',
				services: '',
				sectors: '',
				internalRating: '',
				isCancelButtonEnabled: false,
				errors: [],
			},
			auditTrail: {
				mode: 'post',
			},
			isAuditTrail: false,
			groupResponse: [],
			countryResponse: [],
			servicesResponse: [],
			sectorResponse: [],
			internalRateResponse: [],
			companyDetailsId: 0,mode
			: configJSON.newRecordMode,
			brochure: [],
			brochureData : [],
			staticBrochure: [],
			errors: [],
			brochureToDelete: [],
			onBrochureUpload: false,
			brochureToUpload: [],
			isPendingApi: false,
			isCancelBrochureEnabled: false,
			documentError: "",
			notesData: "",
			oldNotes: "",
			staticNotesData: "",
			notesError: "",
			hasSuccessError: {
				isOpen: false,
				isSeverity: "success",
				isMessage: ""
			},
			supplierNotesLoader: false
		};
		this.sections = [
			'Supplier Details',
			'Brochure',
			'Notes',
			'Connection',
			'Audit trail',
		];

		this.contentRef = React.createRef();
		// Customizable Area End

		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	async componentDidMount() {
		// Customizable Area Start
		this.handleSupplierAPICollection()
		this.setState({ isLoading: false })
		this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
		const { navigation } = this.props;
		if (navigation?.getParam('supplier_id') !== 'add') {
				this.handleGetsupplierDetails()
				this.handleGetBrochureData()
				this.handleGetSupplierNotesData()
		} else {
			this.setState({
				mode: 'post'
			})
		}
		// Customizable Area End
	}
	

	// Customizable Area Start

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
		if (prevState.activeSection != this.state.activeSection) {
			this.countableSection.setActive(this.state.activeSection)
		}
	}

	handleListItemClick = (section: string) => {
		this.setState({
			mode: 'get'
		})

		switch (section) {
			case 'Supplier Details':
				this.setState({ activeSection: 'Supplier Details' });
				break;
			case 'Brochure':
				this.setState({ activeSection: 'Brochure' });
				break;
			case 'Notes':
				this.setState({ activeSection: 'Notes' });
				break;
			case 'Connections':
				this.setState({ activeSection: 'Connections' });
				break;
			case 'Audit trail':
				this.setState({ activeSection: 'Audit trail' });
				break;
		}
	}

	apiCallSupplierModule = async (valueTenderData: {
		methodSupplier?: string;
		contentTypeSupplier?: string;
		bodySupplier?: {};
		endPointSupplier?: string;
		typeSupplier?: string;
		apiTypeSupplier?: string;
	}) => {
		let { contentTypeSupplier, methodSupplier, endPointSupplier, bodySupplier } = valueTenderData;
		const token = (await localStorage.getItem("authToken")) || "";
		let headerTender = {
			"Content-Type": contentTypeSupplier,
			token,
		};
		let requestMessageTender = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);
		requestMessageTender.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			methodSupplier
		);
		requestMessageTender.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			endPointSupplier
		);
		requestMessageTender.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(headerTender)
		);
		bodySupplier &&
			requestMessageTender.addData(
				getName(MessageEnum.RestAPIRequestBodyMessage),
                 bodySupplier
			);
		runEngine.sendMessage(requestMessageTender.id, requestMessageTender);
		return requestMessageTender.messageId;
	};
	// Customizable Area End


	// ----- RECIEVE DATA RESPONSE FROM APIS -----
	async receive(from: string, message: Message) {
		// Customizable Area Start

		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			this.setState({ isLoading: false })
			const apiRequestCallId = message.getData(
				getName(MessageEnum.RestAPIResponceDataMessage)
			);

			const responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (this.isValidResponseController(responseJson)) {
				this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
			} else if (this.isInValidResponseController(responseJson)) {
				this.handleNotFoundRecord(responseJson);
				this.apiFailureCallBacksController(apiRequestCallId, responseJson);
			}

		}
		// Customizable Area End
	}

	// Customizable Area Start

	handleNotFoundRecord = (responseJson: any) => {
		if (responseJson?.meta?.message && !responseJson?.data) {
			this.setState({ isLoading: false })
			let errorMessage: string = responseJson.meta.message;
			  
			this.setState(
				{
					hasError: errorMessage ? errorMessage : 'Error',
				},
				() => {
					this.props.navigation.push("/Supplier/add");
				}
			);

		}
	}

	isValidResponseController = (responseJson: ValidResponseType) => {
		return responseJson && !responseJson.errors;
	};

	isInValidResponseController = (responseJson: InvalidResponseType) => {
		return responseJson && responseJson.errors;
	};

	apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: DropDownItems[] & SupplierDetailsData & SupplierBrochureData & InvalidResponseType & SupplierNotesData) => {
		if (apiRequestCallId === this.getGroupApiCallId) {
			this.getGroupCompanyResponse(responseJson)
		}
		if (apiRequestCallId === this.getCountriesApiCallId) {
			this.getCountryResponse(responseJson)
		}
		if (apiRequestCallId === this.getServicesApiCallId) {
			this.getServicesResponse(responseJson)
		}
		if (apiRequestCallId === this.getSectoresApiCallId) {
			this.getSectorsResponse(responseJson)
		}
		if (apiRequestCallId === this.getInternalRateApiCallId) {
			this.getInternalRateResponse(responseJson)
		}
		if (apiRequestCallId === this.getSupplierDetailsApiCallId) {
			this.handleNotFoundRecord(responseJson);
			this.getSupplierDetailsResponse(responseJson)
		}
		if (apiRequestCallId === this.postSupplierDetailsApiCallId) {
			this.postSupplierDetailsResponse(responseJson)
		}
		if (apiRequestCallId === this.updateSupplierDetailsApiCallId) {
			this.updateSupplierDetailsResponse(responseJson)
		}
		if (apiRequestCallId === this.apiCallIdForUploadBrochure) {
			this.handleGetBrochureData();
		}
		if (apiRequestCallId === this.apiCallIdForRemoveDocument) {
			this.brochureApiResponse(responseJson);
		}
		if (apiRequestCallId === this.getBrochureApiCallId) {
			this.brochureGetApiResponse(responseJson)
		}
		if (apiRequestCallId === this.getSupplierNotesApiCallId) {
			this.notesGetApiResponse(responseJson)
		}
		if (apiRequestCallId === this.postSupplierNotesApiCallId) {
			this.notesPostApiResponse(responseJson)
		}
	};

	getResponseApiFailure = (responseJson: any) => {
		if (responseJson.errors && responseJson.errors.length > 0) {
			this.setState({
				supplierDetails: {
					...this.state.supplierDetails,
					errors: responseJson.errors,
				},
			});
		}
		
	};

	apiFailureCallBacksController = (
		apiRequestCallId: string,
		responseJson: InvalidResponseType
	) => {
		const apiCalls = [
			this.getGroupApiCallId,
			this.getCountriesApiCallId,
			this.getServicesApiCallId,
			this.getSectoresApiCallId,
			this.getInternalRateApiCallId,
			this.postSupplierDetailsApiCallId,
			this.updateSupplierDetailsApiCallId,
			this.getSupplierDetailsApiCallId
		];

		if (apiCalls.includes(apiRequestCallId)) {
			this.getResponseApiFailure(responseJson);
		}
	};

	handleError = (errorMessage: string) => {
		this.setState({
			hasError: errorMessage
		})
	}

	handleGoBack = () =>{
		const to = new Message(getName(MessageEnum.NavigationMessage));
		to.addData(
		  getName(MessageEnum.NavigationTargetMessage),
		  "SuppliersModuleTable"
		);
		to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
		this.send(to);
	  }

	// Handle change the states of Tender Details
	handleChangeSupplierData = (value: string, key: string) => {
		const newErrors = removeErrorsActiveKey(this.state.supplierDetails.errors, key, {
			email: "email_address",
			phoneNumber: "phone_number",
			companyname: "company_name",
			website: "website",
			groupCompany: "company_detail.group_company",
			services: "company_detail.service_ids"
		});

		this.setState({
			supplierDetails: { ...this.state.supplierDetails, [key]: value, errors: newErrors },
		});
	};

	handleChange = (objectKey: keyof S, property: string, value: any) => {
		this.setState((prevState: S) => {
			const currentPart = prevState[objectKey];
			// Return the state with the updated object
			const updatedObject = {
				...currentPart,
				[property]: value,
			};
			return {
				...prevState,
				[objectKey]: updatedObject,
			};
		});
	};

	// Switch the mode of the container
	handleSwitchMode = (section: string, mode: ModuleMode, onCancel?: boolean) => {
		this.setState((prevState) => ({
			supplierDetails: { ...(onCancel ? prevState.staticSupplierDetails : prevState.supplierDetails), mode: mode },
		}))
	};

	handleSupplierAPICollection = () => {
		this.getCompaniesDropdownData();
		this.getCountryDropdownData();
		this.getServicesDropdownData();
		this.getSectorsDropdownData();
		this.getInternalRateDropdownData();
	};

	getCompaniesDropdownData = async () => {
		this.getGroupApiCallId = await this.apiCallSupplierModule({
			contentTypeSupplier: configJSON.validationApiContentType,
			methodSupplier: configJSON.validationApiMethodType,
			endPointSupplier: configJSON.getGroupCompaniesEndPoint,
		})
	};

	getGroupCompanyResponse = (grpRes: any) => {
		if (Array.isArray(grpRes)) {
			const groupCompanies: any = [];
			grpRes.forEach((company: any) => {
				groupCompanies.push({
					value: company.id.toString(),
					label: company.name,
				});
			});
			this.setState({ groupResponse: groupCompanies })
		} 
	};

	getCountryDropdownData = async () => {
		this.getCountriesApiCallId = await this.apiCallSupplierModule({
			contentTypeSupplier: configJSON.validationApiContentType,
			methodSupplier: configJSON.validationApiMethodType,
			endPointSupplier: configJSON.getCountriesEndPoint,
		})
	};

	getCountryResponse = (cntRes: any) => {
		if (Array.isArray(cntRes)) {
			const countries: any = [];
			cntRes.forEach((service: any) => {
				countries.push({
					value: service.id.toString(),
					label: service.name,
				});
			});
			this.setState({ countryResponse: countries })
		} 
	};

	getSectorsDropdownData = async () => {
		this.getSectoresApiCallId = await this.apiCallSupplierModule({
			contentTypeSupplier: configJSON.validationApiContentType,
			methodSupplier: configJSON.validationApiMethodType,
			endPointSupplier: configJSON.getSectorsEndPoint,
		})
	};

	getSectorsResponse = (sectorRes: any) => {
		if (Array.isArray(sectorRes)) {
			const sectors: any = [];
			sectorRes.forEach((sector: any) => {
				sectors.push({
					value: sector.id.toString(),
					label: sector.name,
				});
			});
			this.setState({ sectorResponse: sectors })
		} 
	};

	getServicesDropdownData = async () => {
		this.getServicesApiCallId = await this.apiCallSupplierModule({
			contentTypeSupplier: configJSON.validationApiContentType,
			methodSupplier: configJSON.validationApiMethodType,
			endPointSupplier: configJSON.getServicesEndPoint,
		})
	};

	getServicesResponse = (srvRes: any) => {
		if (Array.isArray(srvRes)) {
			const services: any = [];
			srvRes.forEach((service: any) => {
				services.push({
					value: service.id.toString(),
					label: service.name,
				});
			});
			this.setState({ servicesResponse: services })
		} 
	};

	getInternalRateDropdownData = async () => {
		this.getInternalRateApiCallId = await this.apiCallSupplierModule({
			contentTypeSupplier: configJSON.validationApiContentType,
			methodSupplier: configJSON.validationApiMethodType,
			endPointSupplier: configJSON.getInternalRateEndPoint,
		})
	};

	getInternalRateResponse = (srvRes: any) => {
		if (Array.isArray(srvRes)) {
			const services: any = [];
			srvRes.forEach((service: any) => {
				services.push({
					value: service.id.toString(),
					label: service.name,
				});
			});
			this.setState({ internalRateResponse: services })
		} 
	};
	// GET > Supplier Details
	handleGetsupplierDetails = async () => {
		const supplierId = this.props.navigation.getParam('supplier_id');

		this.getSupplierDetailsApiCallId = await this.apiCallSupplierModule({
			contentTypeSupplier: configJSON.validationApiContentType,
			methodSupplier: configJSON.validationApiMethodType,
			endPointSupplier: `${configJSON.supplierDetailsEndPoint}/${supplierId}`,
		})
	};

	getSupplierDetailsResponse = (responseJson: SupplierDetailsData) => {
		const supplierDetails = responseJson.data.attributes
		const companyDetails = supplierDetails.company_detail

		const serviceData = companyDetails?.service || []
			const services = serviceData
				.map((service: any) => service.name)
				.join(",");

		const supplierData = {
			mode: 'read',
			companyname: supplierDetails.company_name,
			email: supplierDetails.email_address,
			countryCode: supplierDetails.phone_number.split('-')?.[0],
			phoneNumber: supplierDetails.phone_number.split('-')?.[1],
			website: supplierDetails.website,
			groupCompany: companyDetails?.group_company,
			country: companyDetails?.country,
			services: services,
			sectors: companyDetails?.sector?.name,
			internalRating: supplierDetails.internal_rating,
			isCancelButtonEnabled: true,
			errors: [],
		}
		this.setState({
			staticSupplierDetails: supplierData,
			supplierDetails: supplierData,
			companyDetailsId: companyDetails.id,
			supplierId: responseJson.data.id,
			mode: 'get'
		});

		this.handleSwitchMode('supplierDetails', 'read');
	}

	// POST > supplier Details
	  handlePostsupplierDetails = async () => {
		     this.setState({isAuditTrail: true})
		   const { supplierDetails ,servicesResponse , sectorResponse} = this.state
		    const { groupCompany, country , sectors, services, phoneNumber } = this.state.supplierDetails;

		   const sector_id = sectorResponse.find((rec) => rec.label === sectors)?.value;
		   const service_ids: number[] = [];
		    services.split(",").forEach((rec) => {
			   const service = servicesResponse.find((service) => service.label === rec);
			   if (service) {
				  service_ids.push(parseInt(service.value));
			   }
		  });
		   const strippedCountry = this.state.supplierDetails.countryCode.replace(/\D/g,"");

 		  const createData = {
			  company_name: supplierDetails.companyname,
			  email_address: supplierDetails.email,
			  phone_number: phoneNumber ? `+${strippedCountry}-${this.state.supplierDetails.phoneNumber}` : "",
			  website: supplierDetails.website,
			  internal_rating: supplierDetails.internalRating,
		      company_detail_attributes: {
				  country: country,
				  group_company: groupCompany,
				  service_ids: service_ids,
				  sector_id: Number(sector_id)
			  }
		   };

		  const httpPostBody = {
			   supplier: createData,
			   role: getStoredDefaultRole()
		    };

		this.postSupplierDetailsApiCallId = await this.apiCallSupplierModule({
			contentTypeSupplier: configJSON.validationApiContentType,
			methodSupplier: configJSON.apiPostMethod,
			endPointSupplier: `${configJSON.supplierDetailsEndPoint}?role=${getStoredDefaultRole()}`,
			bodySupplier: JSON.stringify(httpPostBody)
		})
	};

	postSupplierDetailsResponse = (responseJson: SupplierDetailsData) =>{
		if(responseJson.error){
			this.handleError(responseJson.error);
			return;
		}
		this.setState({isAuditTrail: false})
		  const supplierDetails = responseJson?.data?.attributes
		  const companyDetails = supplierDetails?.company_detail

		  const serviceData = companyDetails?.service || []
		   const services = serviceData
			    .map((service: any) => service?.name)
			    .join(",");

		const createData = {
			mode: 'read',
			companyname: supplierDetails.company_name,
			email: supplierDetails.email_address,
			countryCode: supplierDetails.phone_number.split('-')?.[0],
			phoneNumber: supplierDetails.phone_number.split('-')?.[1],
			website: supplierDetails.website,
			groupCompany: companyDetails?.group_company,
			country: companyDetails?.country,
			services: services,
			sectors: companyDetails?.sector?.name,
			internalRating: supplierDetails.internal_rating,
			isCancelButtonEnabled: true,
			errors: [],
		}
		this.setState({
			staticSupplierDetails: createData,
			supplierDetails: createData,
			companyDetailsId: companyDetails?.id,
			supplierId: responseJson?.data?.id,
			activeSection: "Brochure"
		});

		 this.handleSwitchMode('supplierDetails', 'read');
	}

	// PUT >  Supplier Details
	handleUpdatesupplierDetails = async () => {
		   this.setState({isAuditTrail: true})
           const { supplierDetails ,servicesResponse , sectorResponse , companyDetailsId} = this.state
		   const { groupCompany, country , sectors, services, phoneNumber } = this.state.supplierDetails;

		   const sector_id = sectorResponse.find((rec) => rec.label === sectors)?.value;
		   const service_ids: number[] = [];
		  services.split(",").forEach((rec) => {
			  const service = servicesResponse.find((service) => service.label === rec);
			  if (service) {
			     	service_ids.push(parseInt(service.value));
			   }
		    });

		let errors = [];
		
		if (service_ids.length == 0) {
			errors.push({ "company_detail.service_ids": "Atlease one service should be present" })

		}

		if (!groupCompany) {
			errors.push({ "company_detail.group_company": "Group company is required" })
		
		}

		if (!supplierDetails.companyname) {
			errors.push({ company_name: "Company name is required" })
		}

		if (errors.length > 0) {
			this.handleChange("supplierDetails", "errors", errors)
			return;
		}

		const strippedCountry = this.state.supplierDetails.countryCode.replace(/\D/g,"");

		const updateData = {
			company_name: supplierDetails.companyname,
			email_address: supplierDetails.email,
			phone_number: phoneNumber ? `+${strippedCountry}-${this.state.supplierDetails.phoneNumber}` : "",
			website: supplierDetails.website,
			internal_rating: supplierDetails.internalRating,
		    company_detail_attributes: {
				id: companyDetailsId,
				country: country,
				group_company: groupCompany,
				service_ids: service_ids,
				sector_id: Number(sector_id)
			}
		};

		 const httpPostBody = {
			  supplier: updateData,
			  role: getStoredDefaultRole()
		 };

		this.updateSupplierDetailsApiCallId = await this.apiCallSupplierModule({
			 contentTypeSupplier: configJSON.validationApiContentType,
			 methodSupplier: configJSON.apiPatchMethod,
			 endPointSupplier:`${configJSON.supplierDetailsEndPoint}/${this.state.supplierId}?role=${getStoredDefaultRole()}`,
			 bodySupplier: JSON.stringify(httpPostBody)
		 })
	};

	updateSupplierDetailsResponse = (responseJson: SupplierDetailsData) =>{
		if(responseJson.error){
			this.handleError(responseJson.error);
			return;
		}
		this.setState({isAuditTrail: false})
		const supplierDetails = responseJson?.data?.attributes
		const companyDetails = supplierDetails?.company_detail

		const serviceData = companyDetails?.service || []
		const services = serviceData
			.map((service: any) => service?.name)
			.join(",");

		const updateData = {
			mode: 'read',
			companyname: supplierDetails.company_name,
			email: supplierDetails.email_address,
			countryCode: supplierDetails.phone_number.split("-")?.[0],
			phoneNumber: supplierDetails.phone_number.split("-")?.[1],
			website: supplierDetails.website,
			groupCompany: companyDetails.group_company,
			country: companyDetails.country,
			services: services,
			sectors: companyDetails?.sector?.name,
			internalRating: supplierDetails.internal_rating,
			isCancelButtonEnabled: true,
			errors: [],
		}
		this.setState({
			staticSupplierDetails: updateData,
			supplierDetails: updateData,
			activeSection: "Supplier Details"
		});
		this.handleSwitchMode('supplierDetails', 'read');
	}

	handleChangeBrochure = (changedPropertyName: keyof S, changedValue: any | null) => {
		const { errors } = this.state;
		if (changedPropertyName) {
			this.setState((prevState: S) => {
				return {
					...prevState,
					[changedPropertyName]: changedValue,
				};
			});
			if (errors.find((error) => error.hasOwnProperty(changedPropertyName))) {
				this.setState({
					errors: errors.filter(
						(error) => !error.hasOwnProperty(changedPropertyName)
					),
				});
			}
		}
	}

	handleDeleteBrochure = (attachment_id: number, type: string) => {
		const { brochureToDelete, brochure } =
			this.state;
			brochureToDelete.push(attachment_id);
		const newCerts = brochure.filter(
			(cert) => cert.id !== attachment_id
		);
		this.setState({
			brochureToDelete,
			brochure: newCerts,
		});
	};

	handleFileChange = (event: any) => {
		const file = event?.target?.files ? event.target.files[0] : null;
		if (file) {
			if (file.size > 10 * 1024 * 1024) {
				this.setState({documentError: configJSON.bigFileErrorMessage})
			} else if(
				!file.name.includes(".pdf") &&
				!file.name.includes(".jpg") &&
				!file.name.includes(".jpeg")
			) {
				this.setState({documentError: configJSON.errorInvalidFormat})
			} else {
				const filesToUpload = this.state.brochureToUpload;
				filesToUpload.push(file);
				this.handleChangeBrochure("brochureToUpload", filesToUpload);
				this.setState({documentError: ''})
			}
			event.target.value = "";
		} else {
			this.handleError(configJSON.fileErrorMessage);
		}
	};

	openImage = (imageUrl: string) => {
		window.open(imageUrl, "_blank");
	};

	removeStagedFile = (fileIndex: number) => {

		const { brochureToUpload } = this.state;
		const newAwardLetters = brochureToUpload.filter(
			(element, index) => index !== fileIndex
		);
		this.handleChangeBrochure("brochureToUpload", newAwardLetters);

	};

	handleCancelButton = () => {
		this.setState((prevState) => {
			return {
				...prevState,
				onBrochureUpload: false,
				brochureToUpload: [],
				brochureToDelete: [],
				brochure: [],
			};
		});
		this.handleGetBrochureData();
		this.handleChangeBrochure("mode", configJSON.viewRecordMode);
	};

	handleCancelSupplier = () => {
		this.setState({
			supplierDetails: this.state.staticSupplierDetails
		})
	}



handleSaveButton = async (): Promise<void> => {
	const { brochureToUpload, brochureToDelete } = this.state;
	let id = this.props.navigation.getParam("supplier_id");

	if(brochureToUpload.length === 0 && brochureToDelete.length === 0){
		this.setState({
			activeSection: handleSectionNavigateLogic(id, !this.countableSection.actived("Notes"), "Notes", "Brochure")
		})
		this.handleChangeBrochure("mode", handleSectionNavigateLogic(id, !this.countableSection.actived("Notes"), "post", "get"));
	}

	await this.handleAwardLetterSection(brochureToUpload, brochureToDelete);

};

handleAwardLetterSection = async (brochureToUpload: any[], brochureToDelete: any[]): Promise<void> => {
	await this.handleUploadAndDelete(brochureToUpload, brochureToDelete, 'brochure', 'onBrochureUpload');
	this.handleChangeBrochure("brochureToUpload", []);
	this.handleChangeBrochure("brochureToDelete", []);
};

handleUploadAndDelete = async (uploads: any[], deletes: any[], type: 'brochure' | 'image', state: keyof S): Promise<void> => {
	if (deletes.length > 0) {
		this.handleChangeBrochure("isPendingApi", true);

		await this.sendDeleteBrochureRequests(deletes, type);
	}

	for (const item of uploads) {
		this.handleChangeBrochure("isPendingApi", true);

		await this.uploadBrochureRequest(item);
		
	}
	
};

uploadBrochureRequest = async (file: File) => {
	this.setState({ isAuditTrail: true, isPendingApi: true })
		const supplierId = this.state.supplierId;
		if (supplierId === "") {
			this.handleError("supplier id not found");
			return;
		}

		const formData = new FormData();
		formData.append("supplier[files][]", file);

		let uploadUrl = `${configJSON.brochureAPIEndPoint}/${supplierId}/upload_brochures?role=${getStoredDefaultRole()}`;

		this.apiCallIdForUploadBrochure = await this.apiCallSupplierModule({
			methodSupplier: configJSON.apiPostMethod,
			endPointSupplier: uploadUrl,
			bodySupplier: formData,
		})
};

handleEditButtonClick = () => {
	this.handleChangeBrochure("mode", configJSON.updateRecordMode);
	this.setState({ mode: configJSON.updateRecordMode, isCancelBrochureEnabled: true })
};

sendDeleteBrochureRequests = async (array: number[], type: string) => {
		this.handleChangeBrochure("isPendingApi", true);
		this.setState({ isAuditTrail: true })
		const { supplierId } = this.state;

		const deleteBody = {
            brochure_ids : array,
		}

		const url = `${configJSON.brochureAPIEndPoint}/${supplierId}/delete_brochures?role=${getStoredDefaultRole()}`;

		this.apiCallIdForRemoveDocument = await this.apiCallSupplierModule({
			contentTypeSupplier: configJSON.validationApiContentType,
			methodSupplier: configJSON.apiDeleteMethod,
			endPointSupplier: url,
			bodySupplier: JSON.stringify(deleteBody)
		})
};

brochureApiResponse = (responseJson: SupplierBrochureData) => {
	this.setState({ isAuditTrail: false })
	if(responseJson.error){
		this.handleError(responseJson.error);
		return;
	}
	
	const { data } = responseJson;
	let id = this.props.navigation.getParam("supplier_id");

	if (data ) {
		const brochures = data.map((item: BrochureData)=>{
			return    item.attributes.brochures
		})
			this.setState((prevState) => {
				return {
					...prevState,
					brochure: brochures,
					staticBrochure: brochures,
					isCancelBrochureEnabled: true,
					mode: handleSectionNavigateLogic(id, !this.countableSection.actived("Notes"), "post", "get"),
					isPendingApi: false
				};
			});
			this.handleChangeBrochure("onBrochureUpload", false)
		}

	this.setState({
		isPendingApi: false,
		isLoading: false,
		mode: handleSectionNavigateLogic(id, !this.countableSection.actived("Notes"), "post", "get"),
		activeSection: handleSectionNavigateLogic(id, !this.countableSection.actived("Notes"), "Notes", "Brochure"),
	});
};

handleGetBrochureData = async () => {
	const supplierId = this.state.supplierId || this.props.navigation.getParam('supplier_id');
	this.setState({ isPendingApi: true })
    let getUrl = `${configJSON.brochureAPIEndPoint}/${supplierId}/get_brochures`;

	this.getBrochureApiCallId = await this.apiCallSupplierModule({
		contentTypeSupplier: configJSON.validationApiContentType,
		methodSupplier: configJSON.validationApiMethodType,
		endPointSupplier: getUrl,
	})
};

 brochureGetApiResponse = (responseJson: SupplierBrochureData) => {
	let id = this.props.navigation.getParam("supplier_id");	
	const { data } = responseJson;
		const brochures = data.map((item: BrochureData) => {
			return item.attributes.brochures
		})
		this.setState((prevState) => {
			return {
				...prevState,
				brochure: brochures,
				staticBrochure: brochures,
			};
		});

		this.setState({
			isPendingApi: false,
			isLoading: false,
			isCancelBrochureEnabled: true,
			onBrochureUpload: !brochures,
			mode: handleSectionNavigateLogic(id, !this.countableSection.actived("Notes"), "post", "get"),
			activeSection: id == "add" ? handleSectionNavigateLogic(id, !this.countableSection.actived("Notes"), "Notes", "Brochure") : this.state.activeSection,
		});
	};

	handleChangeNotes = (value: string) => {
		const container = document.createElement('div');
		const containerData = container;
		containerData.innerHTML = value;
		
		if (!!validateHtmlContent(value)) {
			const messageError = validateHtmlContent(value)

			this.setState({
				notesData: containerData.innerHTML
		 	})

			setTimeout(() => {
				this.setState({
					notesError: messageError,
				 })
			}, 200)

		} else {
			
			this.setState({
				notesData: container.innerHTML,
				notesError: ""
			});
		};
	}

handleGetSupplierNotesData = async () =>{
	const supplierId = this.props.navigation.getParam('supplier_id');

    let getUrl = `${configJSON.supplierNotesAPIEndPoint}/${supplierId}/get_notes`;

	this.getSupplierNotesApiCallId = await this.apiCallSupplierModule({
		contentTypeSupplier: configJSON.validationApiContentType,
		methodSupplier: configJSON.validationApiMethodType,
		endPointSupplier: getUrl,
	})
}

notesGetApiResponse = (responseJson: SupplierNotesData) => {
		this.setState((prevState) => {
			return {
				...prevState,
			    notesData : responseJson.notes,
				staticNotesData : responseJson.notes,
				mode: configJSON.viewRecordMode
			};
		});

	this.setState({
		isPendingApi: false,
		isLoading: false,
	});
};

handleNotesCancelButton = () =>{
	this.setState((prevState) => {
		return {
			...prevState,
		     notesData : prevState.staticNotesData,
			 notesError: ''
		};
	});
  this.setState({mode: configJSON.viewRecordMode});
}

handleCBSubmitDocument = () => {
	const tenderId = this.props.navigation.getParam('supplier_id');
	const { activeSection } = this.state
	this.setState({
		activeSection: handleSectionNavigateLogic(tenderId, this.countableSection.get(activeSection) == 1, "Audit trail", "Connections")
	})
}

supplierAddData = () =>{
	this.setState({mode: configJSON.newRecordMode});
}

handlePostsupplierNotes = async () => {
	this.setState({ isAuditTrail: true })
	if(validateHtmlContent(this.state.notesData)){
		this.setState({ notesError: validateHtmlContent(this.state.notesData)})
		return;
	}
	
    const supplierId = this.state.supplierId;
	const html = document.createElement('div');
	const htmlData = html;
	htmlData.innerHTML = this.state.notesData
	const inValid_links = html.querySelectorAll('a');

	inValid_links.forEach(link => {
		const href = link.getAttribute('href');
		if (!isValidLink(href as string)) {
			link.setAttribute('href', 'https://example.com/404-page-not-found');
		}
	});


	if (supplierId === "") {
		this.handleError("supplier id not found");
		return;
	}
	
	 
	const url = `${configJSON.supplierNotesAPIEndPoint}/${supplierId}/add_notes?role=${getStoredDefaultRole()}`;
	let updatedData = html.innerHTML;
	const httpPostBody = {
		notes: updatedData,
	};

	this.setState({ supplierNotesLoader: true})

    this.postSupplierNotesApiCallId = await this.apiCallSupplierModule({
		contentTypeSupplier: configJSON.validationApiContentType,
		methodSupplier: configJSON.apiPostMethod,
		endPointSupplier: url,
		bodySupplier: JSON.stringify(httpPostBody)
    })
};

notesPostApiResponse = (responseJson: SupplierNotesData ) =>{
	const supplierId = this.props.navigation.getParam('supplier_id');
 if(responseJson.error){
	this.handleError(responseJson.error);
	this.setState({ supplierNotesLoader: false})
	return;
 }

	 this.setState({
		isAuditTrail: false,
		 notesData : responseJson.notes,
		 staticNotesData : responseJson.notes,
		 activeSection: handleSectionNavigateLogic(supplierId, this.countableSection.get("Notes") <= 1, "Connections", "Notes"),
		 mode: handleSectionNavigateLogic(supplierId, this.countableSection.get("Notes") == 1, "post", "get"),
		 supplierNotesLoader: false
	 });
}   

	handleSupplierRequest = () => {
		const requestData = {
			id: this.state.supplierId,
			module: "Supplier",
			recordName: this.state.supplierDetails.companyname
		}
		const supplier = new Message(getName(MessageEnum.NavigationRequestMessage));
		supplier.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
		supplier.addData(getName(MessageEnum.RequestData), requestData);
		this.send(supplier);
	}

	// Customizable Area End
}
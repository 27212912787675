import React from "react";
import { Box, ClickAwayListener, Paper, Popper, styled } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TruncatedTextWithTooltipWeb from "./TruncatedTextWithTooltip.web";

interface Options {
    label: string;
    value: string;
}

interface ServiceFilterDropdownProps {
    name: string;
    options: Options[],
    value: string
    onChange: (name: string, value: string) => void,

}

class ServiceFilterDropdown extends React.Component<ServiceFilterDropdownProps, { isOpen: boolean}> {
  constructor(props: ServiceFilterDropdownProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

    render() {
        const { options, value, onChange, name } = this.props;
        const { isOpen } = this.state;
        return (
            <CustomeDropDown>
                <Box className="mainButton" data-test-id="quarterDropdown" onClick={this.handleOpen}>
                    <TruncatedTextWithTooltipWeb text={value} maxWidth="150px" classNames="title"/>
                    <ExpandMoreIcon style={{ color: "#0094A7", transform: isOpen ? "rotate(180deg)" : "rotate(0deg)" }} />
                </Box>
                <Popper
                    open={isOpen}
                    placement="bottom-start"
                    data-test-id="quarterPopper"
                    className="customPopper"
                    style={{ 
                        zIndex: 1000000, 
                        position: "absolute", 
                        top: "calc(100% + 4px)", 
                        left: window.innerWidth < 460 ? 'unset' : 0,
                        right: window.innerWidth < 460 ? 0 : 'unset'
                     }}
                    // @ts-ignore
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transition
                    disablePortal
                >
                    <ClickAwayListener touchEvent={false} onClickAway={this.handleClose}>
                        <Paper className="customPaper">
                            {options.map(option => {
                                return (
                                    <Box
                                        data-test-id="options"
                                        key={`${option.label}-quarter`}
                                        className="list"
                                        onClick={() => {
                                            onChange(name, option.value);
                                            this.handleClose();
                                        }}
                                        style={{ backgroundColor: value === option.value ? "#C2E5EA" : "white" }}
                                    >
                                        {option.label}
                                    </Box>
                                )
                            })}
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </CustomeDropDown>
        )
    }
}

const CustomeDropDown = styled(Box)({
    position: "relative",
    minWidth: "115px",
    maxWidth: "200px",
    height: "34px",
    "& .mainButton": {
        height: "100%",
        cursor: "pointer",
        position: "relative",
        padding: "0px 10px 0px 15px",
        borderRadius: "4px",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        border: "1px solid #0094A7",
        justifyContent: "space-between",
        gap: "5px",
        "& .title": {
            color: '#0094A7',
            fontSize: "14px",
            fontFamily: 'Source Sans Pro',
            fontWeight: '600',
            lineHeight: "16px",
            wordWrap: 'break-word'
        }
    },
    "& .customPopper": {
        width: "max-content",
        borderRadius: "4px",
        border: "1px solid #E0E0E0",
        "& .customPaper": {
            padding: "5px 0",
            background: "#FFF",
            display: "flex",
            flexDirection: "column",
            maxHeight: "320px",
            overflowY: "auto",
            overflowX: "hidden",
            "&::-webkit-scrollbar-track": {
                backgroundColor: "#E8E8E8",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#0094A7",
                borderRadius: "22px",
              },
              "&::-webkit-scrollbar": {
                backgroundColor: "#E8E8E8",
                height: "5px",
                borderRadius: "15px",
                width: "5px",
              },
            justifyContent: "space-between",
            "& .list": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "8px 12px 8px 12px",
                color: "#000",
                fontSize: "14px",
                fontStyle: "normal",
                fontFamily: "Source Sans Pro",
                fontWeight: 600,
                lineHeight: "14px",
                cursor: "pointer",
                "&:hover": {
                    background: "#EEF1F1"
                },

            },
            "& .datePickerPopper": {
                border: "1px solid #E0E0E0",
                borderRadius: "4px",
                "& .datePickerPaper": {
                    padding: "8px 10px 13px 10px",
                    background: "#FFF",
                    width: "auto",
                }
            }
        }
    }
})


export default ServiceFilterDropdown;

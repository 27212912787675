import React from "react";
import GenericTabs from "../../components/src/GenericTabs/GenericTabs.web";
import { NavbarSections } from './WebAppLayout/config';

type Props = {
  navigation: any;
  selectedTab: number;
};

class Navbar extends React.Component<Props> {
  render() {
    return (
      <>
        <GenericTabs
          tabList={NavbarSections}
          selectedTabIndex={this.props.selectedTab}
          handleTabChange={(event: any, value: number) => {
            switch (value) {
              case 0:
                this.props.navigation.push('/Dashboard');
                break;
              case 1:
                this.props.navigation.push('/Modules/People');
                break;
              case 2:
                this.props.navigation.push('/Modules/Projects');
                break;
              case 3:
                this.props.navigation.push('/Modules/Tenders');
                break;
              case 4:
                this.props.navigation.push('/Modules/Suppliers');
                break;
              case 5:
                this.props.navigation.push('/Modules/Partners');
                break;
              default: return;
            }
          }}
        ></GenericTabs>
      </>
    );
  }
}

export default Navbar;

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { NavbarSections } from "../../../components/src/WebAppLayout/config";
import { calculateDonutFontSize, convertResIntoLabelValue, getQuarterOptions, objectToQueryParams, shuffleSet, transformHistogramData } from "../../../components/src/Utils";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
interface Error  {
  errors?: Array<{[key:string]: string}>; 
  error?: string
}

type DateField = Date | string | null
type PieDonutChartArray = [string, number, string][]

export interface PeopleChartData {
  name: string,
  count: number,
  percentage: number,
}

interface PeopleDataResponse {
  candidate_data?: PeopleChartData[];
  employee_data?: PeopleChartData[];
  errors?: Array<{[key:string]: string}>; 
  error?: string
}

interface Options {
  label: string;
  value: number; 
  values?: {[key:string]: number | string};
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  chartColor: string[];
  hasDashboardError: string;
  candidate_data: PieDonutChartArray;
  employee_data: PieDonutChartArray;
  tenderQuarterChartData: PieDonutChartArray;
  yearValue: string | number;
  yearOptions: Options[];
  tenderYearChartData: PieDonutChartArray, 
  quarterValue: number;
  quarterOptions: Array<Options>;
  quarterOpen: boolean;
  datePickerOpen: boolean;
  dateRange: {[key: string]: DateField}
  complete_project: any[];
  on_going_projects: any[];
  recentActivities: any[];
  fontSize: number | string;
  quarterCompletedValue: number;
  quarterCompletedOpen: boolean;
  datePickerCompletedOpen: boolean;
  dateRangeCompleted: {[key: string]: DateField};
  quarterOngoingValue: number;
  quarterOngoingOpen: boolean;
  datePickerOngoingOpen: boolean;
  dateRangeOngoing: {[key: string]: DateField};
  serviceOptions: { label: string, value: string}[];
  completedService: string;
  onGoingService: string;
  loadingActivity: boolean;
  loadMoreLoading: boolean;
  activityPage: number;
  maxActivityPage: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | undefined;
  // Customizable Area End
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiEndpointForPeopleData = "";
  apiEndpointForQuarterTender = "";
  apiEndpointForYearTender = "";
  apiEndpointForYearOptions = "";
  apiEndpointForCompletedProjectData = "";
  apiEndpointForOngoingProjectData = "";
  apiEndpointForActivityData = "";
  apiEndpointForServiceOptions = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      chartColor: [],
      hasDashboardError: "",
      candidate_data: [configJSON.peopleChartFirstRow],
      employee_data: [configJSON.peopleChartFirstRow],
      tenderQuarterChartData: [configJSON.peopleChartFirstRow],
      yearValue: new Date().getFullYear().toString(),
      yearOptions: [],
      tenderYearChartData: [configJSON.peopleChartFirstRow], 
      quarterValue: 0,
      quarterOptions: getQuarterOptions(3),
      complete_project: [configJSON.peopleChartFirstRow],
      on_going_projects: [configJSON.peopleChartFirstRow],
      recentActivities: [],
      quarterOpen: false,
      datePickerOpen: false,
      dateRange: {
        start_date: null,
        end_date: null,
      },
      loadMoreLoading: true,
      fontSize: calculateDonutFontSize(),
      quarterCompletedValue: 0,
      quarterCompletedOpen: false,
      datePickerCompletedOpen: false,
      dateRangeCompleted: {
        start_date: null,
        end_date: null,
      },
      quarterOngoingValue: 0,
      quarterOngoingOpen: false,
      datePickerOngoingOpen: false,
      dateRangeOngoing: {
        start_date: null,
        end_date: null,
      },
      serviceOptions: [],
      completedService: "",
      onGoingService: "",
      loadingActivity: false,
      activityPage: 1,
      maxActivityPage: 1
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.hitDashboardApis();
    window.addEventListener('resize', this.handleResize);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiDashboardRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const dashboardResponseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    this.handleAllApiResponses(apiDashboardRequestCallId, dashboardResponseJson)

    // Customizable Area End
  }
  // Customizable Area Start


  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  checkViewWindowValues(arr:[][]) {
    const value = arr.slice(1).some(subArr => subArr.slice(1).some(val => val !== 0));
    return value ? undefined : { min:0, max: 1}
  }

  handleResize = () => {
    this.setState({
      fontSize: calculateDonutFontSize(),
    });
  };

  handleQuarterOpen = (name: string, value: boolean) => {
    const dropdownStateMap: {
      [dropdownName: string]: {
        stateKey: keyof S;
        datePickerStateKey: keyof S;
      };
    } = {
      tenderDropdown: { stateKey: 'quarterOpen', datePickerStateKey: 'datePickerOpen' },
      projectCompletedDropdown: { stateKey: 'quarterCompletedOpen', datePickerStateKey: 'datePickerCompletedOpen' },
      projectOngoingDropdown: { stateKey: 'quarterOngoingOpen', datePickerStateKey: 'datePickerOngoingOpen' }
    };

    const dropdownMapping = dropdownStateMap[name];
    if (dropdownMapping) {
      const { stateKey, datePickerStateKey } = dropdownMapping;
      this.setState((prevState) => ({
        ...prevState,
        [stateKey]: value,
        [datePickerStateKey]: !value ? false : prevState[datePickerStateKey]
      }));
    }
  }
  

  handleDatePickerOpen = (name: string, value: boolean) => {
    const datePickerStateMap: { [keys: string]: string } = {
      tenderDropdown: 'datePickerOpen',
      projectCompletedDropdown: 'datePickerCompletedOpen',
      projectOngoingDropdown: 'datePickerOngoingOpen'
    };
  
    const datePickerStateKey = datePickerStateMap[name];
    if (datePickerStateKey) {
      this.setState((prevState) => ({
        ...prevState,
        [datePickerStateKey]: value
      }));
    }
  }
  

  handleDateRange = (keyV: string, event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target){
      const { value, name } = event.target

      const dateRangeStateKey: keyof S | undefined = (() => {
        switch (keyV) {
          case configJSON.tenderDropDownName:
            return 'dateRange';
          case configJSON.completedDropdownName:
            return 'dateRangeCompleted';
          case configJSON.onGoingDropdownName:
            return 'dateRangeOngoing';
          default:
            return undefined;
        }
      })();
  
      if (dateRangeStateKey) {
        this.setState((prevState: S) => ({
          ...prevState,
          [dateRangeStateKey]: {
            ...prevState[dateRangeStateKey],
            [name]: value
          }
        }));
      }
    }
  }

  handleResetDatePicker = (name: string) => {
    const dateRangeStateMap: { [key: string]: keyof S } = {
      tenderDropdown: 'dateRange',
      projectCompletedDropdown: 'dateRangeCompleted',
      projectOngoingDropdown: 'dateRangeOngoing',
    };

    const dateRangeStateKey = dateRangeStateMap[name];
    if (dateRangeStateKey) {
      this.setState((prevState) => ({
        ...prevState,
        [dateRangeStateKey]: {
          start_date: null,
          end_date: null,
        }
      }));
    }
  }

  handleApplyDatePicker = (name: string) => {
    const applyDatePickerMap: {
      [pickerKey: string]: {
        stateValueKey: keyof S;
        stateKey: keyof S;
        datePickerStateKey: keyof S;
      }
    } = {
      tenderDropdown: { stateValueKey : 'quarterValue', stateKey: 'quarterOpen', datePickerStateKey: 'datePickerOpen' },
      projectCompletedDropdown: { stateValueKey : 'quarterCompletedValue', stateKey: 'quarterCompletedOpen', datePickerStateKey: 'datePickerCompletedOpen' },
      projectOngoingDropdown: { stateValueKey : 'quarterOngoingValue', stateKey: 'quarterOngoingOpen', datePickerStateKey: 'datePickerOngoingOpen' }
    };

    const pickerMapping = applyDatePickerMap[name];
    if (pickerMapping) {
      const {stateValueKey, stateKey, datePickerStateKey } = pickerMapping;
      this.setState((prevState) => ({
        ...prevState,
        [stateValueKey]: -1,
        [stateKey]: false,
        [datePickerStateKey]: false,
      }), () => this.handleApiCallForDateFilter(name));
    }
  }

  handleGetColors = (data: any[]) => {
    return data.slice(1).map((colorData) => colorData[colorData.length-1])
  }

  handleQuarter = (name: string, value: any ) => {
    const quarterMap: {
      [quarterKey: string]: {
        stateValueKey: keyof S;
        dateRangeKey: keyof S;
      }
    } = {
      tenderDropdown: { stateValueKey : 'quarterValue', dateRangeKey : 'dateRange'},
      projectCompletedDropdown: { stateValueKey : 'quarterCompletedValue', dateRangeKey: 'dateRangeCompleted' },
      projectOngoingDropdown: { stateValueKey : 'quarterOngoingValue', dateRangeKey: 'dateRangeOngoing' }
    };

    const quartermapping = quarterMap[name];
    if (quartermapping) {
      const {stateValueKey, dateRangeKey } = quartermapping;
      this.setState((prevState) => ({
        ...prevState,
        [stateValueKey]: value,
        [dateRangeKey]: {
          start_date: null,
          end_date: null,
        }
      }), () => {
        this.handleApiCallForDateFilter(name);
        this.handleQuarterOpen(name, false)
      });
    }
  }

  handleServiceChange = (name: string, value: string) => {
    if (value) {
      const stateUpdate: {} = {
        [name]: value,
      };
      this.setState(stateUpdate, () => {
        this.handleApiCallForDateFilter(name)
      });
    }
  }

  handleApiCallForDateFilter = (xKey: string) => {
     switch (xKey) {
      case configJSON.tenderDropDownName:
        this.handleTenderQuarterData();
        break;
      case configJSON.completedDropdownName:
      case configJSON.completedService:
        this.handleProjectCompletedDataApi();
        break;
      case configJSON.onGoingDropdownName:
      case configJSON.onGoingService:
        this.handleProjectOngoingDataApi();
        break;
      default:
        break;
     }
  }

  handleYearlyTender = ( value: any ) => {
    this.setState({ yearValue: value 
    }, () => this.handleTenderYearData())
  } 

  handleAllApiResponses = (dashboardId: string, resp: {}) => {
    const successCallbackMap = {
      [this.apiEndpointForServiceOptions]: this.handleServiceOptionsResponse,
      [this.apiEndpointForPeopleData]: this.handlePeopleDataResponse,
      [this.apiEndpointForYearOptions]: this.handleYearOptionsResponse,
      [this.apiEndpointForYearTender]: this.handleTenderYearResp,
      [this.apiEndpointForQuarterTender]: this.handleTenderQuarterResp,
      [this.apiEndpointForCompletedProjectData]: this.handleProjectDataResponse,
      [this.apiEndpointForOngoingProjectData]: this.handleProjectDataResponse,
      [this.apiEndpointForActivityData]: this.handleRecentActivitySuccess
    };

    if (dashboardId) {
      const successCallback: (resp: {}) => void =
        successCallbackMap[dashboardId];
      !!successCallback && successCallback(resp);
    }
  };

  hitDashboardApis = () => {
    this.handleServiceDataApi();
    this.handlePeopleDataApi();
    this.handleTenderQuarterData();
    this.handleTenderYearData();
    this.handleYearOptionsData();
    this.apiCallRecentActivity(1);
  }

  handleServiceDataApi = async () => {
    this.apiEndpointForServiceOptions = await this.apiCallDashboard({
      contentTypeDashboard: configJSON.exampleApiContentType,
      methodDashboard: configJSON.getApiMethodType,
      endPointDashboard: `${configJSON.projectServiceApiEndpoint}`,
    })
  }

  handleServiceOptionsResponse = (servRes: any) => {
    if (this.handleDashboardResponsesError(servRes)) {
      return;
    }
    if(Array.isArray(servRes) && servRes.length > 0) {
      const servOptions = convertResIntoLabelValue(servRes);
      this.setState({
        serviceOptions: servOptions,
        completedService: servOptions[0].value,
        onGoingService: servOptions[0].value
      })
    }
    this.handleProjectCompletedDataApi();
    this.handleProjectOngoingDataApi()
  }


  handlePeopleDataApi = async () => {
    this.apiEndpointForPeopleData = await this.apiCallDashboard({
      contentTypeDashboard: configJSON.exampleApiContentType,
      methodDashboard: configJSON.getApiMethodType,
      endPointDashboard: `${configJSON.peopleDataApiEndpoint}`,
    })
  }

  processColorData = (data: Array<PeopleChartData>, existingColors: string[]) => {
    const colors = [...configJSON.ChartsColorsLibrary, ...existingColors];
    const filteredColors = colors.filter(color => !existingColors.includes(color));
    const excludedColorsSet = new Set(filteredColors)
    return shuffleSet(excludedColorsSet, data.length);
  }

  handlePeopleDataResponse = (dashboardRes: PeopleDataResponse) => {
    const { candidate_data, employee_data } = dashboardRes;
    
    if (this.handleDashboardResponsesError(dashboardRes)) {
        return;
    }

    let candidateChartColor: string[] = [];
    let employeeChartColor: string[] = [];

    if (candidate_data) {
      candidateChartColor = this.processColorData(candidate_data, employeeChartColor);
      const candidateData = candidate_data.map((item: PeopleChartData, index: number) => [item.name, item.count, candidateChartColor[index]]);
      this.setState({ candidate_data: [configJSON.peopleChartFirstRow, ...candidateData] });
    }
    if (employee_data) {
      employeeChartColor = this.processColorData(employee_data, candidateChartColor);
      const employeeData = employee_data.map((item: PeopleChartData, index: number) => [item.name, item.count, employeeChartColor[index]]);
      this.setState({ employee_data: [configJSON.peopleChartFirstRow, ...employeeData] });
    }

  }

  handleTenderQuarterData = async () => {
    const { quarterValue, quarterOptions, dateRange} = this.state;
    const params = objectToQueryParams({...quarterOptions[quarterValue]?.values, ...dateRange})
    const quarterUrl = `${configJSON.tenderQuarterApiEndpoint}?${params}`
    this.apiEndpointForQuarterTender = await this.apiCallDashboard({
      contentTypeDashboard: configJSON.exampleApiContentType,
      methodDashboard: configJSON.getApiMethodType,
      endPointDashboard: quarterUrl,
    })
  }

  handleTenderQuarterResp = (tendQuarterResp: any) => {
    if (this.handleDashboardResponsesError(tendQuarterResp)) {
      return;
    }
    const { tender_data } = tendQuarterResp
    if(tender_data){
      const quarterColor = this.processColorData(tender_data, []);
      const quarterData = tender_data.map((item: PeopleChartData, index: number) => [item.name, item.count, quarterColor[index]]);
      this.setState({
        tenderQuarterChartData: [configJSON.peopleChartFirstRow, ...quarterData]
      })
    }
  }

  handleTenderYearData = async () => {
    const quarterUrl = `${configJSON.tenderYearlyApiEndpoint}?year=${this.state.yearValue}`
    this.apiEndpointForYearTender = await this.apiCallDashboard({
      contentTypeDashboard: configJSON.exampleApiContentType,
      methodDashboard: configJSON.getApiMethodType,
      endPointDashboard: quarterUrl,
    })
  }

  handleTenderYearResp = (tendYearResp: any) => {
    if (this.handleDashboardResponsesError(tendYearResp)) {
      return;
    }
    const { tender_data } = tendYearResp;
    if(tender_data){
      const yearColor = this.processColorData(tender_data, []);
      const yearData = tender_data.map((item: PeopleChartData, index: number) => [item.name, item.count, yearColor[index]]);
      this.setState({
        tenderYearChartData: [configJSON.peopleChartFirstRow, ...yearData]
      })
    }
  }

  handleYearOptionsData = async () => {
    this.apiEndpointForYearOptions = await this.apiCallDashboard({
      contentTypeDashboard: configJSON.exampleApiContentType,
      methodDashboard: configJSON.getApiMethodType,
      endPointDashboard: configJSON.yearOptionApiEndpoint,
    })
  }

  handleYearOptionsResponse = (yearResp: any) => {
    if (this.handleDashboardResponsesError(yearResp)) {
      return;
    }
    if(yearResp.length>0) {
      this.setState({
					yearOptions: yearResp.reverse().map((valueP: string) => {
						return {
							value: valueP,
							label: valueP,
						};
					}),
			});
    }
  }

  handleProjectCompletedDataApi = async () => {
    const { quarterCompletedValue, quarterOptions, dateRangeCompleted, completedService} = this.state;
    const params = objectToQueryParams({...quarterOptions[quarterCompletedValue]?.values, ...dateRangeCompleted, service: completedService})
    const quarterCompletedUrl = `${configJSON.projectCompletedApiEndpoint}?${params}`
    this.apiEndpointForCompletedProjectData = await this.apiCallDashboard({
      contentTypeDashboard: configJSON.exampleApiContentType,
      methodDashboard: configJSON.getApiMethodType,
      endPointDashboard: quarterCompletedUrl,
    })
  }

  handleProjectOngoingDataApi = async () => {
    const { quarterOngoingValue, quarterOptions, dateRangeOngoing, onGoingService} = this.state;
    const params = objectToQueryParams({...quarterOptions[quarterOngoingValue]?.values, ...dateRangeOngoing, service: onGoingService})
    const quarterOngoingUrl = `${configJSON.projectOngoingApiEndpoint}?${params}`
    this.apiEndpointForOngoingProjectData = await this.apiCallDashboard({
      contentTypeDashboard: configJSON.exampleApiContentType,
      methodDashboard: configJSON.getApiMethodType,
      endPointDashboard: quarterOngoingUrl,
    })
  }

  handleProjectDataResponse = (projectResp: any) => {
    if (this.handleDashboardResponsesError(projectResp)) {
      return;
    }
    let { complete_project, on_going_project } = projectResp

    if (complete_project) {
      const completeData = transformHistogramData(complete_project);
      this.setState({ complete_project: completeData });

    }
    if (on_going_project) {
      const onGoingData = transformHistogramData(on_going_project);
      this.setState({ on_going_projects: onGoingData });
    }
  }

  findActiveTabForTopbar = () => {
    return NavbarSections.findIndex((section) => section === "Dashboard");
  };

  handleDashboardResponsesError = (dashboardError: Error) => {
    if (dashboardError.errors && dashboardError.errors.length > 0) {
      const errorMsg: string = dashboardError.errors.map(erray => Object.values(erray)[0]).join("\n");
      this.setState({
        hasDashboardError: errorMsg,
      });
      return true;
    } else if (dashboardError.error) {
      this.setState({
        hasDashboardError: dashboardError.error,
      });
      return true;
    } else {
      return false;
    }
  };

  apiCallDashboard = async (valueDashboardData: {
    methodDashboard?: string;
    contentTypeDashboard?: string;
    endPointDashboard?: string;
  }) => {
    let { contentTypeDashboard, methodDashboard, endPointDashboard } =
    valueDashboardData;
    const token = (await localStorage.getItem("authToken")) || "";
    let dashboardHeader = {
      "Content-Type": contentTypeDashboard,
      token,
    };
    let requestMessageDashboard = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageDashboard.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodDashboard
    );
    requestMessageDashboard.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointDashboard
    );
    requestMessageDashboard.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(dashboardHeader)
    );
    runEngine.sendMessage(requestMessageDashboard.id, requestMessageDashboard);
    return requestMessageDashboard.messageId;
  };

  apiCallRecentActivity = async (page: number) => {
    if (page == 1) {
      this.setState({ loadingActivity: true });
    }
    const pageSize = 20;
    this.apiEndpointForActivityData = await this.apiCallDashboard({
      methodDashboard: "GET",
      contentTypeDashboard: configJSON.jsonApiContentType,
      endPointDashboard: `${configJSON.apiRecentActivity}?page=${page}&per_page=${pageSize}`
    })
  }

  handleRecentActivitySuccess = (projectResp: any) => {
    if (this.handleDashboardResponsesError(projectResp)) {
      return;
    }

    let feedData = [];
    const feedDataBE = projectResp.recent_activities.data.map((activity: any) => activity.attributes);

    if (projectResp.created_supplier) {
      feedData.push({
        id: Date.now(),
        activitable_type: "BxBlockProfile::Supplier",
        activitable_id: null,
        created_at: projectResp.end_date,
        updated_at: projectResp.end_date,
        status: "Completed",
        activitable: {
          ids: projectResp.created_supplier.ids,
          name: `${projectResp.created_supplier.count} New Suppliers available`,
          sector_name: null
        }
      })
    }

    if (projectResp.created_partner) {
      feedData.push({
        id: Date.now(),
        activitable_type: "BxBlockProfile::Partner",
        activitable_id: null,
        created_at: projectResp.end_date,
        updated_at: projectResp.end_date,
        status: "Completed",
        activitable: {
          ids: projectResp.created_partner.ids,
          name: `${projectResp.created_partner.count} New Partners available`,
          sector_name: null
        }
      })
    }

    if (projectResp.created_people) {
      feedData.push({
        id: Date.now(),
        activitable_type: "BxBlockProfile::People",
        activitable_id: null,
        created_at: projectResp.end_date,
        updated_at: projectResp.end_date,
        status: "Completed",
        activitable: {
          ids: projectResp.created_people.ids,
          name: `${projectResp.created_people.count} new profiles available`,
          sector_name: "People Module"
        }
      })
    }

    if (this.state.activityPage == 1) {
      this.setState({
        recentActivities: [
          ...feedData,
          ...this.state.recentActivities,
          ...feedDataBE
        ],
        loadingActivity: false,
        maxActivityPage: projectResp.recent_activities.meta.total_pages
      })
    } else {
      this.setState({
        recentActivities: [
          ...this.state.recentActivities,
          ...feedDataBE
        ],
        maxActivityPage: projectResp.recent_activities.meta.total_pages
      })
    }
    

  }

  fetchMoreData = () => {

    this.setState({
      activityPage: this.state.activityPage + 1
    }, () => {
      this.apiCallRecentActivity(this.state.activityPage )
    })
    
  };


  // Customizable Area End
}

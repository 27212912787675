// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { Redirect } from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
import { PollProgressBar } from "../../components/src/PollProgressBar"
import { AdvancedFilterContextProvider } from "../../components/src/ContextAPI/AdvancedFilterContext.web";
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import HomeScreen from "../../components/src/HomeScreen";
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import ImportData from "../../blocks/importexportdata/src/ImportData.web";
import ProjectImportData from "../../blocks/importexportdata/src/ProjectImportData.web"
import TenderImportData from "../../blocks/importexportdata/src/TenderImportData.web.tsx"
import ExportData from "../../blocks/importexportdata/src/ExportData.web";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Download from "../../blocks/Download/src/Download";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Customform from "../../blocks/customform/src/Customform";
import OfflineWork from "../../blocks/OfflineWork/src/OfflineWork";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import LoginBlock from "../../blocks/login-block/src/AccountLoginBlock.web"
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP.web";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import DeepLinking from "../../blocks/DeepLinking/src/DeepLinking";
import OfflineBrowsing from "../../blocks/offlinebrowsing/src/OfflineBrowsing";
import ServiceSpecificSettingsAdmin from "../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import LinkShare from "../../blocks/LinkShare/src/LinkShare";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Share from "../../blocks/share/src/Share";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import DownloadPermissions from "../../blocks/DownloadPermissions/src/DownloadPermissions";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import MultipageForms2 from "../../blocks/MultipageForms2/src/MultipageForms2";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Customform3 from "../../blocks/customform3/src/Customform3";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Savesearch from "../../blocks/savesearch/src/Savesearch";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Pagination from "../../blocks/pagination/src/Pagination";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Documentstorage from "../../blocks/documentstorage/src/Documentstorage";
import Switchaccounts2 from "../../blocks/switchaccounts2/src/Switchaccounts2";
import SaveAsPdf from "../../blocks/saveaspdf/src/SaveAsPdf";
import Automatedemailsending from "../../blocks/automatedemailsending/src/Automatedemailsending";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import DashboardVisual from "../../blocks/visualanalytics/src/Dashboard.web";
import Audittrail2 from "../../blocks/audittrail2/src/Audittrail2";
import Audittrail from "../../blocks/audittrail2/src/Audittrail.web";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import Sorting from "../../blocks/sorting/src/Sorting";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import PeopleData from "../../blocks/visualanalytics/src/PeopleDataTable.web"
import PeopleModule from "../../blocks/multipageforms/src/PeopleModule.web";
// import PeopleData2 from "../../blocks/multipageforms/src/PeopleDataTable.web";
import UserProfile  from "../../blocks/customisableuserprofiles/src/UserProfile.web.tsx";
import UserProfilePasswordChange from "../../blocks/customisableuserprofiles/src/UserProfilePasswordChange.web";
import ProjectModule from "../../blocks/multipageforms/src/ProjectModule.web"
import TenderModule from "../../blocks/multipageforms/src/TenderModule.web.tsx"
import ProjectModuleTable from "../../blocks/visualanalytics/src/ProjectModuleTable.web"
import TenderModuleTable from "../../blocks/visualanalytics/src/TenderModuleTable.web"
import SupplierDataTable from "../../blocks/visualanalytics/src/SupplierDataTable.web"
import SupplierModule from "../../blocks/multipageforms/src/SupplierModule.web"
import PartnerDataTable from "../../blocks/visualanalytics/src/PartnerDataTable.web"
import SupplierImportData from "../../blocks/importexportdata/src/SupplierImportData.web"
import PartnerImportData from "../../blocks/importexportdata/src/PartnerImportData.web";
import PartnerModule from "../../blocks/multipageforms/src/PartnerModule.web";
import RequestDataTable from "../../blocks/requestmanagement/src/RequestDataTable.web";
import RequestViewDetails from "../../blocks/requestmanagement/src/RequestViewDetails.web.tsx";
import RequestDataAdd from "../../blocks/requestmanagement/src/RequestAddEdit.web.tsx";
import RequestDataEdit from "../../blocks/requestmanagement/src/RequestAddEdit.web.tsx";
import EmailConsent from "../../blocks/automatedemailsending/src/EmailConsent.web"

// Routes for both logged in and logged out users
const commonRoutes = {
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true,
  },
}

// Routes for logged out users
const loginRoutes = {
  LoginBlock: {
    component: LoginBlock,
    path: "/Login",
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  CVRentention: {
    component: EmailConsent,
    path: '/CV_retention'
  },
  ...commonRoutes
}

// Routes for logged in users
const loggedRoutes = {
  Dashboard: {
    component: DashboardVisual,
    path: "/Dashboard",
    module: "Dashboard"
  },
  PeopleData: {
    component: PeopleData,
    path: "/Modules/People",
    module: "People"
  },
  // PeopleData2: {
  //   component: PeopleData2,
  //   path: "/Modules/PeopleNew",
  // },
  Savesearch: {
    component: Savesearch,
    path: "/Savesearch",
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Pagination: {
    component: Pagination,
    path: "/Pagination"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  Documentstorage: {
    component: Documentstorage,
    path: "/Documentstorage"
  },
  Switchaccounts2: {
    component: Switchaccounts2,
    path: "/Switchaccounts2"
  },
  SaveAsPdf: {
    component: SaveAsPdf,
    path: "/SaveAsPdf"
  },
  Automatedemailsending: {
    component: Automatedemailsending,
    path: "/Automatedemailsending"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Audittrail2: {
    component: Audittrail2,
    path: "/Audittrail2"
  },
  Audittrail: {
    component: Audittrail,
    path: "/Audittrail"
  },
  Favourites: {
    component: Favourites,
    path: "/Favourites"
  },
  AddFavourites: {
    component: AddFavourites,
    path: "/AddFavourites"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  RequestManagement: {
    component: RequestManagement,
    path: "/RequestManagement"
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  Customform3: {
    component: Customform3,
    path: "/Customform3"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Servicespecificsettingsadmin2: {
    component: Servicespecificsettingsadmin2,
    path: "/Servicespecificsettingsadmin2"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  ImportData: {
    component: ImportData,
    path: "/ImportData",
    module: "People"
  },
  ProjectImportData: {
    component: ProjectImportData,
    path: "/ProjectImportData",
    module: "Projects"
  },
  TenderImportData: {
    component: TenderImportData,
    path: "/TenderImportData",
    module: "Tenders"
  },
  ExportData: {
    component: ExportData,
    path: "/ExportData"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  Download: {
    component: Download,
    path: "/Download"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Customform: {
    component: Customform,
    path: "/Customform"
  },
  OfflineWork: {
    component: OfflineWork,
    path: "/OfflineWork"
  },
  PeopleModule: {
    path: '/People/:people_id',
    component: PeopleModule,
    exact: true,
    module: "People"
  },
  DeepLinking: {
    component: DeepLinking,
    path: "/DeepLinking"
  },
  OfflineBrowsing: {
    component: OfflineBrowsing,
    path: "/OfflineBrowsing"
  },
  ServiceSpecificSettingsAdmin: {
    component: ServiceSpecificSettingsAdmin,
    path: "/ServiceSpecificSettingsAdmin"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  LinkShare: {
    component: LinkShare,
    path: "/LinkShare"
  },
  Settings5: {
    component: Settings5,
    path: "/Settings5"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Share: {
    component: Share,
    path: "/Share"
  },
  AccountGroups: {
    component: AccountGroups,
    path: "/AccountGroups"
  },
  DownloadPermissions: {
    component: DownloadPermissions,
    path: "/DownloadPermissions"
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: "/RolesPermissions"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  MultipageForms2: {
    component: MultipageForms2,
    path: "/MultipageForms2"
  },
  AdminConsole: {
    component: AdminConsole,
    path: "/AdminConsole"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  ProjectModule: {
    path: '/Projects/:project_id',
    component: ProjectModule,
    exact: true,
    module: "Project"
  },
  TenderModule: {
    path: '/Tender/:tender_id',
    component: TenderModule,
    exact: true,
    module: "Tender"
  },
  TenderModuleAdd: {
    path: '/Tender/add',
    component: TenderModule,
    exact: true,
    module: "Tender"
  },
  SupplierModule: {
    path: '/Supplier/:supplier_id',
    component: SupplierModule,
    exact: true,
    module: "Supplier"
  },
  SupplierModuleAdd: {
    path: '/Supplier/add',
    component: SupplierModule,
    exact: true,
    module: "Supplier"
  },
  PartnerModule: {
    path: '/Partner/:partner_id',
    component: PartnerModule,
    exact: true,
    module: "Partner"
  },
  PartnerModuleAdd: {
    path: '/Partner/add',
    component: PartnerModule,
    exact: true,
    module: "Partner"
  },
  ProjectModuleTable: {
    path: '/Modules/Projects',
    component: ProjectModuleTable,
    exact: true,
    module: "Project"
  },
  TenderModuleTable: {
    path: '/Modules/Tenders',
    component: TenderModuleTable,
    exact: true,
    module: "Tender"
  },
  SuppliersModuleTable: {
    path: '/Modules/Suppliers',
    component: SupplierDataTable,
    exact: true,
    module: "Suppliers"
  },
  PartnersModuleTable: {
    path: '/Modules/Partners',
    component: PartnerDataTable,
    exact: true,
    module: "Partners"
  },

  PartnerImportData: {
    path: '/PartnerImportData',
    component: PartnerImportData,
    exact: true,
    module: "Partners"
  },

  SuppliersImportData: {
    path: '/SuppliersImportData',
    component: SupplierImportData,
    exact: true,
    module: "Suppliers"
  },
  RequestReport :{
    path: '/RequestReport',
    component: RequestDataTable,
    exact: true,
  },
  RequestView :{
    path: '/RequestView/:id',
    component: RequestViewDetails,
    exact: true,
  },
  RequestAdd :{
    path: '/RequestAdd',
    component: RequestDataAdd,
    exact: true,
  },
  RequestEdit :{
    path: '/RequestEdit/:id',
    component: RequestDataEdit,
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  UserProfile: {
    component: UserProfile,
    path: '/UserProfile'
  },
  UserProfilePasswordChange: {
    component: UserProfilePasswordChange,
    path: '/UserProfilePasswordChange'
  },
  
  CVRentention: {
    component: EmailConsent,
    path: '/CV_retention'
  },
  ...commonRoutes
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <AdvancedFilterContextProvider>
        <View style={{ height: '100vh' }}>
          {WebRoutesGenerator({ loginRoutes, loggedRoutes })}
          <ModalContainer />
          <PollProgressBar />
        </View>
      </AdvancedFilterContextProvider>
    );
  }
}

export default App;
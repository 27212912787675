import React, { ReactElement } from "react";
// Customizable Area Start
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  withStyles,
  Avatar,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Grid,
  Dialog,
  DialogContent,
  styled,
  Popper,
  ClickAwayListener,
  Paper,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { AccountCircle, CloseRounded, CloseOutlined } from "@material-ui/icons";
import { CheckedIcon, DownIcon, LogoutIcon, OffFilter, OnFilter, ProfileIcon, RequestIcon, RoleIcon, SwitchAccIcon, UpIcon, menuIcon } from "./assets"
import CustomTypographyWeb from "../CustomTypography.web";
import CustomButton from "../CustomButton.web";
import { Link } from "react-router-dom";
import { Message } from "../../../framework/src/Message";
import { AdvancedFilterContext } from "../ContextAPI/AdvancedFilterContext.web";
import { convertExactRoles, logoutUser } from "../Utils";
import { defaultRoute, TypeDefaultRole, activityEnable, activityDisable } from "./assets"


// Customizable Area End
const NavbarSections = [
  {
    name: 'Dashboard',
    pathName: "/Dashboard"
  },
  {
    name: 'People',
    pathName: "/Modules/People",
  },
  {
    name: 'Projects',
    pathName: "/Modules/Projects"
  },
  {
    name: 'Tenders',
    pathName: "/Modules/Tenders"
  },
  {
    name: 'Suppliers',
    pathName: "/Modules/Suppliers"
  },
  {
    name: 'Partners',
    pathName: "/Modules/Partners"
  }
]

// Customizable Area Start
type State = {
  drawerOpen: boolean;
  showFillter: boolean;
  isDropdownOpen: boolean;
  hoveredItem: string;
  isModalOpen: boolean;
  switchButton: string;
  isSwitchRoleOpen: boolean;
  isOpenActivityDrawer: boolean;
};

type Props = {
  navigation: any;
  title?:string;
  ActivityComponent?: ReactElement;
  children?:  ( (props: { closeDrawer: () => void }) => ReactElement) | never[]
};

const MainContainer = styled(Box)({
  "& .firstButton": {
    backgroundColor: "#C2E5EA",
    textTransform: 'none',
    fontFamily: 'Source Sans Pro',
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "#C2E5EA",
    }
  },
  "& .menuItem": {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    padding: 15,
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: '#F0F0F0',
      color: '#2B3132',
      fontWeight: 700
    }
  },
  "& .divider": { margin: '0 15px', borderBottom: 'none' },
  "& .menuItemLink": {
    textDecoration: "none",
    color: '#0094A7',
  },
  "& .MuiButton-label" : {
    display: "flex",
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  
})

const LogOutModal = styled(Dialog)({
  "& .modal": {
    fill: 'rgba(31, 31, 31, 0.40)',
    backdropFilter: "blur(8px)"
  },
  "& .paperModal": {
    fontFamily: "Source Sans Pro",
    borderRadius: 16,
    width:487
  },
  "& .modalContent": {
    padding: 20,
    fontFamily: "Source Sans Pro",
  },
  "& .modalTitle": {
    fontFamily: 'Source Sans Pro',
    fontSize: 20,
    fontWeight: 600,
    width: '100%',
    margin: '0 0 30px 0',
    textAlign: 'center'
  },
  "& .modalButtonBox": {
    margin: "0 18%",
    display: 'flex',
    justifyContent: 'space-between'
  },
  "& .modalButton": {
    width: 140,
    height: 50,
    borderRadius: 8,
    border: '1.5px solid #0094A7',
    color: '#0094A7',
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'none'
  },
})

const CustomList = styled(List)({
    "& .MuiListItem-button:hover": {
      backgroundColor: "#C2E5EA"
    },
    "& .MuiListItem-root": {
      marginBottom: 10,
      borderRadius: 8
    },
    "& .MuiTypography-body1, .MuiListItemText-root, .MuiListItem-button":{
      fontFamily: "Source Sans Pro",
    },
    padding: "10px 20px",
    "& .MuiList-padding":{
      padding: 5
    },
  "& .listItem": {
    backgroundColor: "#C2E5EA",
    borderRadius: 8,
    "& .MuiTypography-body1, .MuiListItemText-root, .MuiListItem-button":{
      fontFamily: "Source Sans Pro",
    },
    fontFamily: "Source Sans Pro",
    "& span":{
      fontWeight: 600,
    }
  }
})

const CustomPopper = styled(Popper)({
  top: "50% !important",
  right: "0 !important",
  left: "auto !important",
  "& .papper": {
    borderRadius: "4px",
    padding: "5px 0px",
    border: "1px solid #9F9F9F",
    maxWidth: "300px",
    minWidth: "180px",
    background: "#FFF",
    boxShadow: "-4px 4px 24px 0px rgba(0, 0, 0, 0.16)",
    "& .menuList": {
      padding: "0px",
      "& li": {
        display: 'flex',
        flexDirection: 'row',
        gap: 12,
        alignItems: 'center',
        padding: "11px 48px",
        cursor: 'pointer',
        color: '#0094A7',
        fontSize: "1rem",
        fontFamily: 'Source Sans Pro',
        boxSizing: 'border-box', 
        fontWeight: '600',
        "&:hover": {
          backgroundColor: '#F0F0F0',
          color: '#2B3132',
        },
        "& p": {
          margin: "0",
        },
      },
      "& .selectedMenu":{
        backgroundColor: "rgb(214, 238, 241) !important",
        cursor: "default",
        "&:hover": {
          color: '#0094A7',
          fontWeight: '600',
        },
        "& .icon": {
          position: "absolute",
          width: "24px",
          height: "24px",
          left: "12px",
        },
      }
    },
  },
})







export default class ResponsiveTopBar extends React.Component<Props, State> {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.state = {
      drawerOpen: false,
      showFillter: false,
      isDropdownOpen: false,
      hoveredItem: '',
      isModalOpen: false,
      switchButton: "",
      isSwitchRoleOpen: false,
      isOpenActivityDrawer: false
    };
  }


  receive(from: string, message: Message): void {
    console.log("from: ", from, message)
  }

  handleTabChange = (value: string) => {
    switch (value) {
      case "Dashboard":
        this.props.navigation.push('/Dashboard');
        break;
      case "People":
        this.props.navigation.push('/Modules/People');
        break;
      case "Projects":
        this.props.navigation.push('/Modules/Projects');
        break;
      case "Tenders":
        this.props.navigation.push('/Modules/Tenders');
        break;
      case "Suppliers":
        this.props.navigation.push('/Modules/Suppliers');
        break;
      case "Partners":
        this.props.navigation.push('/Modules/Partners');
        break;
      default: return;
    }
    this.setState({ drawerOpen: !this.state.drawerOpen });

  }

  toggleDrawer = (open: any) => (event: any) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ drawerOpen: !this.state.drawerOpen , showFillter: false , isDropdownOpen:false});
  };

  toggleDrawerActivity = (open: any) => (event: any) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ isDropdownOpen: !this.state.isOpenActivityDrawer});
  };

  toggleActivity = () => {
    this.setState({ 
      isDropdownOpen: false,
      isOpenActivityDrawer: !this.state.isOpenActivityDrawer
    });
  }

 async componentDidMount() {
  let currentpath = window.location.pathname;  
  if(currentpath.includes("Modules")){
    this.setState({switchButton: "menu"})
  }else if(currentpath.includes("ImportData") || currentpath.includes("UserProfile") || currentpath.includes("RequestReport") || currentpath.includes("Dashboard")){
    this.setState({switchButton: ""})
  }else{
    this.setState({switchButton: "filter"})
  }
}


  list = () => {
    return (
      <CustomList className="listroot" style={ { width: '100%'}}>
        {NavbarSections.map((text, index) => (
          <ListItem
            className={this.props.title === text.name ? "listItem" : ""}
            button key={index} onClick={() => this.handleTabChange(text.name)}>
            <ListItemText primary={text.name} />
          </ListItem>
        ))}
      </CustomList>
    )
  }

  FillterOnClick = () => {
    if(this.state.showFillter){
      this.setState({ 
        showFillter: false,
        drawerOpen: false,
      })
    }else {
      this.setState({ 
        showFillter: true,
        drawerOpen: true,
      })
    }
    this.setState({  isDropdownOpen: false })
  }

  handleMenu = () => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen , showFillter: false , drawerOpen: false});
  };

  handleItemHover = (item: string) => {
    this.setState({ hoveredItem: item })
  }

  handleModalClose = () => {
    this.setState({ isModalOpen: false })
  }

  handleToggelSwitchRole = (event: React.MouseEvent<HTMLDivElement>) => {
    const { currentTarget } = event;
    this.setState((prevState) => ({
      isSwitchRoleOpen: !prevState.isSwitchRoleOpen,
    }));
  };

  handleCloseSwitchRole = () => {
    this.setState({
      isSwitchRoleOpen: false,
    })
  }

  handleMenuClose = () => {
    this.setState({
      isDropdownOpen: false,
    })
  }

  handleRequest = () => {
    this.props.navigation.navigate("RequestReport")
  }

  handleSwitchRole = (role: string) => {
    const currentModule = this.props.navigation?.module as TypeDefaultRole || 'People';
    const user = { ...this.context?.globalContext.currentUser, defaultRole: role }
    localStorage.removeItem("currentUser")
    this.context?.setGlobalContext((prev: any) => ({
      ...prev,
      currentUser: user
    }))
    localStorage.setItem("currentUser", JSON.stringify(user))
    this.handleMenuClose();

    this.props.navigation.navigate(defaultRoute[currentModule])

  }


  handleTitleButoon = () => {
    if(this.state.showFillter) {
      this.setState({
       showFillter: false,
       drawerOpen:  true
      })
    } else {
      this.setState({  
        drawerOpen: this.state.drawerOpen ? false : true,
      })
    }
  }

  handleCloseDrawer = () => {
    this.setState({
      drawerOpen: false,
      isDropdownOpen: false,
      isOpenActivityDrawer: false
    })
    setTimeout(() => {
      this.setState({
        showFillter: false,
      })
    },300)
  }

  handleOpenActivityDrawer = () => {
    this.setState({
      isOpenActivityDrawer: true
    })
  }

  render() {
    const { isDropdownOpen, isModalOpen, hoveredItem, isSwitchRoleOpen } = this.state;
    const { title, ActivityComponent } = this.props;
    const {currentUser, permissions} = this.context?.globalContext;
    const roles = convertExactRoles(currentUser.roles);
    const { module } = this.props.navigation
    const children = this.props.children as any;
    
    return (
      <MainContainer component={"div"}>
        <AppBar position="static" style={{ backgroundColor: "#FFF", fontFamily: "Source Sans Pro" }}>
          <Toolbar>
            <Grid container justifyContent="space-between" style={{ fontFamily: "Source Sans Pro", alignItems: "center",  minHeight: "65px"}}>
              <Grid item>
                <React.Fragment>
                  <Box>
                    {this.state.switchButton !== "" && <IconButton onClick={this.FillterOnClick}>
                      {this.state.switchButton === "filter" && <img src={this.state.showFillter ? menuIcon : menuIcon} />}
                      {this.state.switchButton === "menu" && <img src={this.state.showFillter ? OnFilter : OffFilter} />}
                    </IconButton>}
                    <Button className="firstButton" endIcon={<img src={this.state.drawerOpen && !this.state.showFillter ? DownIcon : UpIcon} />} onClick={()=>this.handleTitleButoon()} >{title}</Button>
                  </Box>

                  {/* Menu Drawer */}
                  <SwipeableDrawer
                    anchor={'left'}
                    open={this.state.drawerOpen}
                    onClose={() => this.handleCloseDrawer()}
                    onOpen={this.toggleDrawer(true)}
                    BackdropProps={{
                      style: { top: 64 }
                    }}
                    PaperProps={{
                      style: { top: 64, width: 300 }
                    }}
                    style={{ top: 64 }}
                  >
                    <Box style={{display: "flex" , overflow:"hidden", height:"100%", width: '100%'}}>
                    {
                      this.state.showFillter && 
                      children ? children({
                        closeDrawer: this.handleCloseDrawer
                      }) :
                      this.list()
                    }
                    </Box>
                  </SwipeableDrawer>

                  {/* Activity Drawer */}
                  <SwipeableDrawer
                    anchor={'left'}
                    open={this.state.isOpenActivityDrawer}
                    onClose={() => this.handleCloseDrawer()}
                    onOpen={this.toggleDrawerActivity(true)}
                    BackdropProps={{
                      style: { top: 64 },
                    }}
                    PaperProps={{
                      style: { top: 66, width: '90vw', height: "calc(100% - 66px)" },
                      id: "scrollableDiv",
                    }}
                      
                    style={{ top: 66 }}
                  >
                    <Box mx={2}>
                      <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between" alignItems="center">
                        <CustomTypographyWeb style={{ marginLeft: 12, fontWeight: 600 }} variant="h6">Recent Activities</CustomTypographyWeb>
                        <IconButton onClick={this.toggleActivity}>
                          <CloseRounded />
                        </IconButton>
                      </Box>
                      {ActivityComponent}
                    </Box>
                    
                  </SwipeableDrawer>
                </React.Fragment>
              </Grid>
              <Grid item>

                { this.props.ActivityComponent && 
                  <CustomButton
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"
                    onClick={this.toggleActivity}
                    style={{
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                      paddingRight: 0,
                      minWidth: 'unset'
                    }}
                  >
                    <img src={ !this.state.isOpenActivityDrawer ? activityDisable : activityEnable}/>
                  </CustomButton>
                }
                

                <CustomButton
                  aria-controls="dropdown-menu"
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  style={{
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                    paddingRight: 0,
                    minWidth: 'unset'
                  }}
                >
                  {currentUser?.profilePhoto ? <Avatar alt={currentUser?.fullName || ""} src={currentUser.profilePhoto} style={{ width: 30, height: 30 }} /> : <AccountCircle style={{ color: "#000000", fontSize: 30 }} />}
                </CustomButton>

                

                <div
                  style={{
                    display: this.state.isDropdownOpen
                      ? "flex"
                      : "none",
                    right: 22,
                    flexDirection: 'column',
                    position: 'absolute',
                    width: '180px',
                    zIndex: 1001,
                    borderRadius: 4,
                    fontFamily: 'Source Sans Pro',
                    fontSize: '1rem',
                    fontWeight: 600,
                    border: '1px solid #9f9f9f',
                    background: '#fff',
                    boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.24)',
                    color: '#0094A7'
                  }}

                >
                  <div
                    className="menuItem"
                    onClick={() => {
                      console.log('Role')
                    }}
                    onMouseEnter={() => this.handleItemHover('role')}
                    onMouseLeave={() => this.handleItemHover('')}
                  >
                    <img src={RoleIcon} />
                    {currentUser?.defaultRole}
                  </div>

                  <hr
                    className="divider"
                    style={{ color: this.state.hoveredItem === 'role' ? 'rgba(159, 159, 159, 0.5)' : 'rgba(232,232,232, 0.2)' }}
                  />
                  <Link to='/UserProfile' className="menuItemLink">
                    <div
                      className="menuItem"
                      onClick={() => {
                        console.log('Profile')
                      }}
                      onMouseEnter={() => this.handleItemHover('profile')}
                      onMouseLeave={() => this.handleItemHover('')}
                    >
                      <img src={ProfileIcon} />
                      Profile
                    </div>
                  </Link>

                  <hr
                    className="divider"
                    style={{ color: this.state.hoveredItem === 'profile' ? 'rgba(159, 159, 159, 0.5)' : 'rgba(232,232,232, 0.2)' }}
                  />

                  <div
                    className="menuItem"
                    onClick={this.handleToggelSwitchRole}
                    onMouseEnter={() => this.handleItemHover('switch')}
                    onMouseLeave={() => this.handleItemHover('')}
                  >
                    <img src={SwitchAccIcon} />
                    Switch Account
                  </div>
                  
                  {/* Navigate Drawer */}
                  <CustomPopper
                    open={isSwitchRoleOpen}
                    transition
                    disablePortal
                    placeholder="bottom"
                    style={{ position: 'absolute' }}
                  >
                    <ClickAwayListener onClickAway={() => this.handleCloseSwitchRole()}>
                      <Paper className="papper">
                        <MenuList
                          autoFocusItem={isSwitchRoleOpen}
                          id="menu-list-grow"
                          className="menuList"
                        >
                          {roles?.map((item: any, index: number) => {
                            const isMatchRole = currentUser?.defaultRole === item.role_name 
                            return <MenuItem 
                            key={`${item.role_name}-${index}`} 
                            className={`${isMatchRole ? "selectedMenu" : ""}`}
                            onClick={() => !isMatchRole ? this.handleSwitchRole(item.role_name) : {}}
                            >
                              {isMatchRole && <img src={CheckedIcon} className="icon" />}
                              <p>{item.role_name}</p>
                            </MenuItem>
                          })}
                        </MenuList>
                      </Paper>
                    </ClickAwayListener>
                  </CustomPopper>
                                  

                  <hr
                    className="divider"
                    style={{ color: this.state.hoveredItem === 'switch' ? 'rgba(159, 159, 159, 0.5)' : 'rgba(232,232,232, 0.2)' }}
                  />

                  <div
                    className="menuItem"
                    onClick={() => this.handleRequest()}
                    onMouseEnter={() => this.handleItemHover('request')}
                    onMouseLeave={() => this.handleItemHover('')}
                  >
                    <img src={RequestIcon} />
                    Request
                  </div>

                  <hr
                    className="divider"
                    style={{ color: this.state.hoveredItem === 'request' ? 'rgba(159, 159, 159, 0.5)' : 'rgba(232,232,232, 0.2)' }}
                  />

                  <div
                    className="menuItem"
                    onClick={() => this.setState({ isModalOpen: true })}
                  >
                    <img src={LogoutIcon} />
                    Log Out
                  </div>
                </div>
              </Grid>
            </Grid>
            <div>
            </div>
          </Toolbar>
        </AppBar>
        <LogOutModal open={this.state.isModalOpen} maxWidth={"xs"} BackdropProps={{ className: "modal" }} PaperProps={{className:"paperModal"}}>
          <DialogContent className="modalContent">
            <CustomTypographyWeb className="modalTitle">Are you sure you want to logout this account?</CustomTypographyWeb>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <Button
                  className="modalButton"
                  onClick={this.handleModalClose}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className="modalButton"
                  style={{ background: '#0094A7', color: '#fff' }}
                  onClick={async () => await logoutUser(this.props)}
                >
                  Logout
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </LogOutModal>
      </MainContainer >
    );
  }
  // Customizable Area End
}
// Customizable Area Start
ResponsiveTopBar.contextType = AdvancedFilterContext;
// Customizable Area End

import React, { Component, ReactNode } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

const styles = createStyles({
  root: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    lineHeight: '20px',
    letterSpacing: 0,
  },
  header: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#1F1F1F'
  },
  label: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#1F1F1F',
  },
  content: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#2B3132',
    wordWrap: 'break-word'
  },
  sideBar: {
    color: '#9F9F9F',
    fontSize: '14px',
    fontWeight: 600,
    "&.active":{
      color: '#0094A7',
      fontWeight: 600,
    }
  },
});

interface StyledTextProps extends TypographyProps {
  isHeader?: boolean;
  isLabel?: boolean;
  isContent?: boolean;
  isSideBar?: boolean;
  isActiveSideBar?: boolean;
  children: ReactNode;
}

type Props = StyledTextProps & { classes: Record<string, string> };

class CustomTypography extends Component<Props> {
  render() {
    const { classes, isHeader, isLabel, isContent, isSideBar, isActiveSideBar, children, ...typographyProps } = this.props;

    return (
      <Typography
        className={`
        ${classes.root} ${isHeader ? classes.header : ''} 
        ${isLabel ? classes.label : ''}
        ${isContent ? classes.content : ''}
        ${isSideBar ? isActiveSideBar ? classes.sideBar + " active": classes.sideBar : ''}
        `}
        {...typographyProps}
      >
        {children}
      </Typography>
    );
  }
}

export default withStyles(styles)(CustomTypography);

import React from "react";
import {
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TablePagination,
  makeStyles,
  Tabs,
  Tab,
  Box,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, withStyles } from "@material-ui/core/styles";

const theme: any = createTheme({
  MuiTab: {
    root: {
      backgroundColor: "red",
      "&$selected": {
        backgroundColor: "blue",
      },
      "& .MuiTabs-indicator": { display: 'none' }
    },
  },
  // TextField: {
  //   padding: "10.5px 14px"
  // },
  // palette: {
  //   primary: {
  //     main: "#fff",
  //     contrastText: "#fff",
  //   },
  // },
  // typography: {
  //   h6: {
  //     fontWeight: 500,
  //   },
  //   subtitle1: {
  //     margin: "20px 0px",
  //   },
  // },
} as any);

const styles = {
  tabsContainer: {
    borderRadius: '8px',
    marginTop: '10px',
    backgroundColor: '#fff',
  }
}

interface Props {
  classes: any;
  tabList: string[];
  selectedTabIndex: number;
  handleTabChange: (event: any, newValue: number) => void;
}

interface S { }

interface SS { }

// Customizable Area End

import { BlockComponent } from "../../../framework/src/BlockComponent";

export class GenericTabs extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  calculatePercentage = (length: number) => {
    if (length <= 0) {
      return "0%";
    }

    const percentage = 100 / length; // Ensure two decimal places
    return `${percentage}%`;
  }
  // Customizable Area End

  render() {
    const { classes } = this.props;

    return (
      <Box style={{ display: 'flex' }}>
        <Tabs
          // classes={{root: classes.root, indicator: classes.indicator}}
          className={classes.tabsContainer}
          value={this.props.selectedTabIndex}
          onChange={this.props.handleTabChange}
          TabIndicatorProps={{ style: { display: 'none' } }}
          variant={'scrollable'}
          style={{ flexGrow: 1 }}
        >
          {this.props.tabList.map((tab: string, index: number) => (
            <CustomTab
              key={tab}
              label={tab}
              style={{
                borderTop: this.props.selectedTabIndex !== index ? "none" : "3px solid #0094A7",
                width: this.calculatePercentage(this.props.tabList.length),
                backgroundColor: this.props.selectedTabIndex !== index ? "" : "#D6EEF1",
                fontFamily: "Source Sans Pro",
                fontSize: "18px",
                fontStyle: 'normal',
                fontWeight: this.props.selectedTabIndex !== index ? 600 : 700,
                lineHeight: '16px',
                color: this.props.selectedTabIndex !== index ? '#2B3132' : ''
              }}
            />
          ))}
        </Tabs>
      </Box>
    );
  }
}

// Customizable Area Start
const CustomTab = withStyles({
  root: {
    textTransform: "none",
    maxWidth: '100%'
  }
})(Tab);

const webStyle = {
  TableCell: { borderRight: "1px solid #01949A" },
  divRoot0: { display: "flex" },
  divRoot1: {
    display: "flex",
    backgroundColor: "#01949A",
    height: "50px",
    position: "sticky",
    marginTop: "19px",
  },
  divRoot2: { marginTop: "9px", width: "7rem", height: "2rem", fill: "white" },
  divRoot3: { color: "white", margin: "13px 0px 0px -30px" },
  divRoot4: {
    color: "white",
    backgroundColor: "#01949A",
    border: "1px solid white",
    marginLeft: "60vw",
    marginTop: "0.4vw",
  },
  divRoot5: { height: "67vh", overflow: "scroll" },
  divRoot22: { width: "78%", margin: "15px" },
  tableRoot: { display: "flex" },
  tabRoots: { height: "90.7vh" },
  divRoot: { backgroundColor: "#01949A", height: "7%" },
};
export default withStyles(styles)(GenericTabs);
// Customizable Area End



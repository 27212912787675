export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const successIcon = require('../assets/success.png')
export const bonficaLogo = require('../assets/bonfica_logo.svg')
export const renPartLogo = require('../assets/ren_part_logo.svg')
export const profertLogo = require('../assets/profert_logo.svg')
export const renConseilsLogo = require('../assets/ren_conseils_logo.svg')
export const greyTick = require('../assets/greyTick.svg')
export const greenTick = require('../assets/greenTick.svg')
export const redTick = require('../assets/redTick.svg')


export const srcTick = (length: number, validation: string) => {
    if(length){
        return validation ? greenTick : redTick
    }else {
        return greyTick
    }
}

export const getFontColor = (length: number, validation: string) => {
    if(length){
        return validation ? "" : "#EE2020"
    }else {
        return ""
    }
}
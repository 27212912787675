import React, { Component } from "react";
import { Box, CircularProgress } from "@material-ui/core";

type Props = {
	height?: string;
	background?: string;
};

type State = {};

export default class Loading extends Component<Props, State, any> {
	state = {};
	render() {
		console.log(this.props);

		return (
			<Box
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					minHeight: "calc(50vh)",
					color: "black",
				}}>
				<CircularProgress
					color="inherit"
					style={{ color: "black" }}
					size={"5rem"}
				/>
			</Box>
		);
	}
}

import React, { Component } from "react";
import {
  Box,
  Button,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "./CustomButton.web";
import {
  Documents,
  PropertyKey,
  S,
} from "../../blocks/PeopleModule/src/PeopleModuleController";

interface MyProps {
  children?: React.ReactNode;
  mandatory?: boolean; // Optional prop to make file upload mandatory
  errorMessage?: string; // Optional prop to provide a custom error message
  borderDashed?: boolean;
  state: keyof S;
  fileProp: any;
  disabled?: boolean;
  file?: any;
  label: string;
  labelStyle?: object;
  otherDocumentUpload?: boolean;
  hideButtonAfterUpload?: boolean;
  onFileChange: (documentType: string, file: File | null) => void;
  documentType: string;
  handleError?: (errorMessage: string) => void;
  handleErrorOtherDocs?:(errorMsg: string)=> void;
  style?: object
}

type State = {
  selectedFile: File | null;
  error: string | null;
  isHighlighted?: boolean;
  highlightCounter?: number;
};

export default class FileUploadButton extends Component<MyProps, State> {
  fileInputRef = React.createRef<HTMLInputElement>();

  constructor(props: MyProps) {
    super(props);
    this.state = {
      selectedFile: null,
      error: props.errorMessage || null,
    };
  }

  handleButtonClick = () => {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    }

    if (this.props.mandatory && !this.state.selectedFile) {
      this.setState({
        error: this.props.errorMessage || "File upload is mandatory",
      });
    }
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0] || null;
      const name = event.target.name
      const allowedFileTypes = ['application/pdf', 'text/csv'];
      if (file.size > 10 * 1024 * 1024 && (name === 'otherDocumentUpload' || name === 'resumeUrl')) {
        // File is larger than 10MB, return false or handle it as needed
        // alert('File size exceeds 10MB. Please choose a smaller file.');
        // fileInput.value = ''; // Clear the file input
        this.props.handleErrorOtherDocs?.("File size should not exceed 10 MB.")
        event.target.value = ''
        return;
      }
      else if(!allowedFileTypes.includes(file.type)){
        this.props.handleErrorOtherDocs?.("Please select valid file format.")
        event.target.value = ''
      }
      else{
        if (file) {
          this.props.onFileChange(this.props.documentType, file)
          this.props.handleErrorOtherDocs?.("")
          this.setState({
            error: null,
          });
        }
      }
    }
  };

  removeFile = () => {
    this.setState({
      selectedFile: null,
      error: this.props.errorMessage || null,
    });

    this.props.onFileChange(this.props.documentType, null)
  };

  render() {
    const { mandatory, disabled } = this.props;

    return (
      <Box
        style={{
          color: "#0094A7",
          width:'70%'
        }}
      >
        <input
          ref={this.fileInputRef}
          type="file"
          hidden
          accept={this.props.fileProp === "resume"? ".pdf" : ".pdf, .csv"}
          onChange={this.handleFileChange}
          name={this.props.fileProp}
        />

        <Box hidden={!!this.props.otherDocumentUpload}>
          <CustomButton
            onClick={this.handleButtonClick}
            disabled={disabled}
            colorVariant={
              this.props.borderDashed ? "uploadDocumentButton" : "default"
            }
            style={{...this.props?.style, borderColor: disabled ? "#e0e0e0" : "#0094a7" }}
          >
            {this.props.children ? (
              this.props.children
            ) : (
              <Typography style={this.props.labelStyle}>
                {this.props.label}
              </Typography>
            )}
          </CustomButton>
        </Box>
        {this.props.file && (
          <Box
            style={{ display: "flex", alignItems: "center", marginTop: "16px" }}
          >
            <Typography style={{...webStyle.selectedFile, wordBreak: "break-word"}}>
              {this.props.file.name}
            </Typography>
            <Button
              style={{ marginLeft: "8px" }}
              onClick={this.removeFile}
              size="small"
              hidden={this.props.file && this.props.hideButtonAfterUpload}
            >
              <CloseIcon />
            </Button>
          </Box>
        )}
        {/* Error message */}
      </Box>
    );
  }
}

const webStyle = {
  selectedFile: {
    color: "#0094A7",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
  },

  error: {
    color: "#DC2626",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    marginTop: "16px",
  },
};

import React, { Component } from 'react';
import ReadWork from './ReadWork.web';
import UpdateWork from './UpdateWork.web';
import { IWork } from '../../PeopleModuleController.web';

type Props = {
	isPersonalDataDone: boolean;
	work: IWork;
	handleChangeValue: (val: string, key: string) => void;
	handlePostWorkData: () => void;
	handleUpdateWorkData: () => void;
	handleSwitchMode: (section: string, mode: 'update' | 'read' | 'post', onCancel?: boolean) => void;
	handleSelectLang: (
		language: string,
		level: 'Beginner' | 'Advance' | string | undefined,
		langObj?:any
	) => void;
	handleChangeGroupRelation: (
		value: "related" | "unrelated",
		groupRelationMenus: any,
	) => void;
	handleShowOtherCurrentPositions: () => void;
	handleOpenGroupRelation: (value: boolean) => void;
	handlePeopleRequestNavigation: () => void;
	renderErrors: (errkey: string, errors: { [errorKey: string]: string }[]) => {error: boolean, text: string | undefined};
	currentPositionData?: string
};

type State = {};

export default class WorkStep extends Component<Props, State> {
	render() {
		const { mode } = this.props.work;

		if (mode === 'read') {
			return (
				<ReadWork
					work={this.props.work}
					handleSwitchMode={this.props.handleSwitchMode}
					handlePeopleRequestNavigation={this.props.handlePeopleRequestNavigation}
				/>
			);
		} else {
			return (
				<UpdateWork
					isPersonalDataDone={this.props.isPersonalDataDone}
					work={this.props.work}
					handleChangeValue={this.props.handleChangeValue}
					handlePostWorkData={this.props.handlePostWorkData}
					handleUpdateWorkData={this.props.handleUpdateWorkData}
					handleSelectLang={this.props.handleSelectLang}
					handleChangeGroupRelation={this.props.handleChangeGroupRelation}
					handleOpenGroupRelation={this.props.handleOpenGroupRelation}
					handleSwitchMode={this.props.handleSwitchMode}
					handleShowOtherCurrentPositions={
						this.props.handleShowOtherCurrentPositions
					}
	                currentPositionData = {this.props.currentPositionData}
					renderErrors={this.props.renderErrors}
				/>
			);
		}
	}
}

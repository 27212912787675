import React, { Component } from "react";
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from '../../../framework/src/RunEngine';
import { IBlock } from "../../../framework/src/IBlock";
import { logoutUser } from "../Utils"
import { removeStorageData } from "../../../framework/src/Utilities";

export interface Props {
    isLoggedIn: boolean;
    navigation: any;
}

export interface S {

} 

export class SessionGate extends BlockComponent<Props, S, {}> {

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage)
        ]

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    
    receive(from: string, message: Message): void {
        
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
            
            if (responseJson && responseJson?.errors?.[0]?.token) {
                this.handleLogout()
            }

            
        }

    }

    handleLogout = async () => {

        if (this.props.isLoggedIn) {
            const keysToKeep = ["ProfertEmail", "ProfertPassword"];
            Object.keys(localStorage).forEach(async(key) => {
                if (!keysToKeep.includes(key)) {
                    await removeStorageData(key);
                }
            });

            this.props.navigation.history.replace("LoginBlock")

        }

    }

    render() {
        return (
            <>
                {this.props.children}
            </>
        )
    }
    
}
import React, { Component } from 'react';
import { Box, Divider, Grid} from '@material-ui/core';
import CustomButton from '../../../../../components/src/CustomButton.web';
import { CustomTextField, DisabledTextField, NumericTextField, projectModuleCommonStyle } from '../../assets';
import { ITenderData, DropDownItems } from '../../TenderModuleController.web';
import dayjs from 'dayjs';
import customParseFormat from "dayjs/plugin/customParseFormat";
import AutocompleteSelect from '../../../../../components/src/CustomFields/AutocompleteSelectMenu.web';
import MultiSelectMenu from '../../../../../components/src/CustomFields/MultiSelectMenu.web';
import CustomTypographyWeb from '../../../../../components/src/CustomTypography.web';
import { NumericFormat } from 'react-number-format';
import getSymbolFromCurrency from "currency-symbol-map";
import CustomDatePicker from '../../../../../components/src/CustomFields/CustomDatePicker.web';
import moment from 'moment';

dayjs.extend(customParseFormat);

type Props = {
    tenderDetails: ITenderData;
    TenderErrors: boolean;
    handleChangeValue: (val: string, key: string) => void;
    handlePosTenderDetails: () => void;
    handleUpdateTenderDetails: () => void;
    handleSwitchMode: (section: string, mode: 'update' | 'read' | 'post', onCancel?: boolean) => void;
    groupResponse: DropDownItems[];
    countryresponse: DropDownItems[];
    clientResponse: DropDownItems[];
    tenderTypeResponse: DropDownItems[];
    servicesResponse: DropDownItems[];
    sectorsResponse: DropDownItems[];
    ValueOfWorksResponse: DropDownItems[];
    statusResponse: DropDownItems[];
    typeCurrencyResponse: DropDownItems[]
};

export const CustomInputComponent = ({ inputRef,prefix, ...rest } : any) => {
 return (
    <NumericFormat
        ref={inputRef}
        {...rest}
        thousandsGroupStyle={"lakh"}
        thousandSeparator=","
        allowNegative={false}
    />
 )
};

type State = {};

export default class UpdateTenderDetails extends Component<Props, State> {
    constructor(props: any) {
        super(props);
    }

    renderTenderError = (errorKey: string) => {
        const { errors } = this.props.tenderDetails;
        const errorP = errors.some((error) => error.hasOwnProperty(errorKey))
        const textP = errors.find((x) => x.hasOwnProperty(errorKey))?.[errorKey]
        return { error: errorP, text: textP ?? undefined }
    }

    render() {
        const maxDate = new Date(); // Today's date
        maxDate.setDate(maxDate.getDate() - 1); // Set the maximum date to today
        const webStyle = projectModuleCommonStyle

        const {
            tendername,
            tenderCode,
            country,
            tenderType,
            mode,
            groupCompany,
            clientName,
            client,
            services,
            sectors,
            typeCurrencyLocal,
            valueOfWorkLocal,
            ourFeeLocal,
            valueOfWorkRangsUsd,
            valueOfWorkUsd,
            ourFeeUsd,
            date,
            status,
            isCancelButtonEnabled,
        } = this.props.tenderDetails;
        const {
            groupResponse,
            countryresponse,
            clientResponse,
            servicesResponse,
            sectorsResponse,
            tenderTypeResponse,
            ValueOfWorksResponse,
            statusResponse,
            typeCurrencyResponse
        } = this.props

        return (
            <Box
                sx={{ display: 'flex', flexDirection: 'column', marginBottom: '52px' }}>
                {/* TOP PART */}
                <Box>
                    {/* TENDER NAME */}
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        margin="32px 0">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={8}>
                                <CustomTypographyWeb style={{ marginBottom: "7px" }} isLabel >Tender Name</CustomTypographyWeb>
                                <CustomTextField
                                    variant="outlined"
                                    placeholder='Tender Name'
                                    data-test-id={"tendernameTestId"}
                                    fullWidth
                                    value={tendername}
                                    onChange={(event: any) => this.props.handleChangeValue(event.target.value, 'tendername')}
                                    error={this.renderTenderError("name").error}
                                    helperText={this.renderTenderError("name").text}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    {/* CODE - TYPE */}
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        marginBottom={'32px'}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb style={{ marginBottom: "7px" }} isLabel >Tender Code</CustomTypographyWeb>
                                <CustomTextField
                                    variant="outlined"
                                    placeholder='Tender Code'
                                    data-test-id={"tenderCodeTestId"}
                                    fullWidth
                                    value={tenderCode}
                                    onChange={(event: any) => this.props.handleChangeValue(event.target.value, 'tenderCode')}
                                    error={this.renderTenderError("tender_code").error}
                                    helperText={this.renderTenderError("tender_code").text}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb style={{ marginBottom: "7px" }} isLabel >Tender Type</CustomTypographyWeb>
                                <AutocompleteSelect
                                    data-test-id={"tenderTypeTestId"}
                                    value={tenderType}
                                    options={tenderTypeResponse}
                                    onChange={(value: string) => this.props.handleChangeValue(value, 'tenderType')}
                                    placeholder='Tender Type'
                                    error={false}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Divider />

                {/* BOTTOM PART */}
                <Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        marginBottom={'32px'} marginTop={'32px'}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb style={{ marginBottom: "7px" }} isLabel >Group Company</CustomTypographyWeb>
                                <AutocompleteSelect
                                    data-test-id={"group_companyTestId"}
                                    value={groupCompany}
                                    options={groupResponse}
                                    onChange={(value: string) => this.props.handleChangeValue(value, 'groupCompany')}
                                    placeholder='Group Company'
                                    error={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb isLabel style={{ marginBottom: 7 }}>Country</CustomTypographyWeb>
                                <AutocompleteSelect
                                    data-test-id={"countryTestId"}
                                    value={country}
                                    options={countryresponse}
                                    onChange={(value: string) => this.props.handleChangeValue(value, 'country')}
                                    placeholder='Country'
                                    error={false}
                                />
                            </Grid>

                        </Grid>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        marginBottom={'32px'}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb isLabel style={{ marginBottom: 7 }}>Client</CustomTypographyWeb>
                                <AutocompleteSelect
                                    data-test-id={"clientTestId"}
                                    value={client}
                                    options={clientResponse}
                                    onChange={(value: string) => this.props.handleChangeValue(value, 'client')}
                                    placeholder='Client'
                                    error={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb style={{ marginBottom: "7px" }} isLabel >Client Name</CustomTypographyWeb>
                                <CustomTextField
                                    variant="outlined"
                                    placeholder='Client Name'
                                    data-test-id={"clientNameTestId"}
                                    fullWidth
                                    value={clientName}
                                    onChange={(event: any) => this.props.handleChangeValue(event.target.value, 'clientName')}
                                />
                            </Grid>

                        </Grid>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        marginBottom={'32px'}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb isLabel style={{ marginBottom: 7 }}>Services</CustomTypographyWeb>
                                <MultiSelectMenu
                                    data-test-id={"servicesTestId"}
                                    placeholder='Services'
                                    showSearch={true}
                                    onChange={(value: string) => this.props.handleChangeValue(value, 'services')}
                                    value={services}
                                    options={servicesResponse}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb isLabel style={{ marginBottom: 7 }}>Sectors</CustomTypographyWeb>
                                <AutocompleteSelect
                                    data-test-id={"sectorsTestId"}
                                    value={sectors}
                                    options={sectorsResponse}
                                    onChange={(value: string) => this.props.handleChangeValue(value, 'sectors')}
                                    placeholder='Sectors'
                                    error={false}
                                />
                            </Grid>

                        </Grid>
                    </Box>
                    <Divider/>
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        marginBottom={'32px'}
                        marginTop={"32px"}
                        >
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb isLabel style={{ marginBottom: 7 }}>Local Currency</CustomTypographyWeb>
                                <AutocompleteSelect
                                    data-test-id={"typeCurrencyTestId"}
                                    value={typeCurrencyLocal}
                                    options={typeCurrencyResponse}
                                    onChange={(value: string) => this.props.handleChangeValue(value, 'typeCurrencyLocal')}
                                    placeholder='Select Local Currency'
                                    error={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb style={{ marginBottom: "7px" }} isLabel >Value of Work(Local Currency)</CustomTypographyWeb>
                                <NumericTextField
                                    variant="outlined"
                                    value={valueOfWorkLocal}
                                    fullWidth
                                    onChange={(event: any) => this.props.handleChangeValue(event.target.value, 'valueOfWorkLocal')}
                                    disabled={!typeCurrencyLocal}
                                    data-test-id={"valueOfWorkLocalTestId"}
                                    placeholder='Enter Value of Work'
                                    InputProps={{
                                        inputComponent: CustomInputComponent ,
                                        startAdornment: <span style={{fontSize: "14px"}}>{`${getSymbolFromCurrency(typeCurrencyLocal) || ""}`}</span>,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb style={{ marginBottom: "7px" }} isLabel >Our Fee(Local Currency)</CustomTypographyWeb>
                                <NumericTextField
                                    variant="outlined"
                                    value={ourFeeLocal}
                                    fullWidth
                                    onChange={(event: any) => this.props.handleChangeValue(event.target.value, 'ourFeeLocal')}
                                    disabled={!typeCurrencyLocal}
                                    data-test-id={"currencyValueLocalTestId"}
                                    placeholder='Enter Fee'
                                    InputProps={{
                                        inputComponent: CustomInputComponent,
                                        startAdornment: <span style={{fontSize: "14px"}}>{`${getSymbolFromCurrency(typeCurrencyLocal) || ""}`}</span>
                                    }}
                                />
                            </Grid>

                        </Grid>
                    </Box>
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        marginBottom={'32px'}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb isLabel style={{ marginBottom: 7 }}>Value of Work Range(USD)</CustomTypographyWeb>
                                <DisabledTextField
                                    variant="outlined"
                                    data-test-id={"valueOfWorkRangsUsdTestId"}
                                    fullWidth
                                    placeholder='-'
                                    disabled
                                    value={valueOfWorkRangsUsd}
                                    onChange={(event: any) => this.props.handleChangeValue(event.target.value, 'valueOfWorkRangsUsd')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb style={{ marginBottom: "7px" }} isLabel >Value of Work(USD)</CustomTypographyWeb>
                                <DisabledTextField
                                    variant="outlined"
                                    placeholder='$ -'
                                    data-test-id={"ValueOfWorkUsdTestId"}
                                    fullWidth
                                    disabled
                                    value={`$ ${valueOfWorkUsd}`}
                                    onChange={(event: any) => this.props.handleChangeValue(event.target.value, 'valueOfWorkUsd')}
                                    InputProps={{
                                        inputComponent: CustomInputComponent,
                                        startAdornment: <span style={{fontSize: "14px"}}>{`${getSymbolFromCurrency("USD")}`}</span>
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb style={{ marginBottom: "7px" }} isLabel >Our fee(USD)</CustomTypographyWeb>
                                <DisabledTextField
                                    variant="outlined"
                                    placeholder='$ -'
                                    data-test-id={"ourFeeTestId"}
                                    fullWidth
                                    disabled
                                    value={`$ ${ourFeeUsd}`}
                                    onChange={(event: any) => this.props.handleChangeValue(event.target.value, 'ourFeeUsd')}
                                    InputProps={{
                                        inputComponent: CustomInputComponent,
                                        startAdornment: <span style={{fontSize: "14px"}}>{`${getSymbolFromCurrency("USD")}`}</span>
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        marginBottom={'32px'}
                        marginTop={'32px'}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb isLabel style={{ marginBottom: 7 }}>Status</CustomTypographyWeb>
                                <AutocompleteSelect
                                    data-test-id={"statusTestId"}
                                    value={status}
                                    options={statusResponse}
                                    onChange={(value: string) => this.props.handleChangeValue(value, 'status')}
                                    placeholder='Status'
                                    error={false}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={5} lg={4}>
                                <CustomTypographyWeb isLabel style={{ marginBottom: 7 }}>Date</CustomTypographyWeb>
                                <CustomDatePicker
									name="date"
                                    data-test-id={"dateTestId"}
									dateFormat='DD/MM/YYYY'
									value={date ? moment(date).format('DD/MM/YYYY') : ''}
									onChange={(val) =>
										this.props.handleChangeValue(val, 'date')
									}
                                    minDate={new Date("01/01/1900")}
                                    label=""
									placeholder='dd/mm/yyyy'
									error={this.renderTenderError("tender_date").error}
                                    helperText={this.renderTenderError("tender_date").text}
								/>
                            </Grid>


                        </Grid>
                    </Box>
                </Box>

                {/* BUTTONS */}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            style={{
                                gap: '20px',
                                marginTop: '20px',
                            }}>
                            {isCancelButtonEnabled && (
                                <CustomButton
                                    onClick={() =>
                                        this.props.handleSwitchMode('tenderDetails', 'read', true)
                                    }
                                    data-testId="btnCancelPerson"
                                    disabled={!isCancelButtonEnabled}
                                    colorVariant="special">
                                    Cancel
                                </CustomButton>
                            )}

                            <CustomButton
                                colorVariant="default"
                                data-testId="btnSavePerson"
                                onClick={
                                    mode === 'post'
                                        ? this.props.handlePosTenderDetails
                                        : this.props.handleUpdateTenderDetails
                                }
                            // disabled={this.state.isValid}
                            >
                                Save
                            </CustomButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}
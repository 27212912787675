import React from "react";

// Customizable Area Start

import SupplierDataTableController, {
  Props,
  configJSON,
} from "./SupplierDataTableController.web";
import { TableLayout } from "../../../components/src/WebAppLayout/TableLayout.web";
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Snackbar,
  Typography,
  styled,
} from "@material-ui/core";
import {
  SaveSearchCheckBox,
  notifyCheckedIcon,
  projectModuleTableCommonStyle,
} from "./assets";
import ProjectModuleSearch from "../../advancedsearch/src/ProjectModuleSearch.web";
import { projectModuleCommonStyle } from "../../multipageforms/src/assets";
import { AccessControl } from "../../../components/src/Permission";
import TableData from "../../../components/src/Table/Table.web";
import NoRecordsFoundPage from "../../../components/src/Table/NoRecordsFoundPage.web";
import { saveUncheckIcon } from "../../filteritems/src/assets";
import { SaveRounded } from "@material-ui/icons";
import { CustomeSaveSearchPopper } from "../../../components/src/Popper/CustomeSaveSearchPopper.web";
import SupplierExportData from "../../importexportdata/src/SupplierExportData.web";

// Customizable Area End


export default class SupplierDataTable extends SupplierDataTableController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderNotifyMeSupplier = () => {
    const { isShowSaveSearchSupplierLabel, isSupplierSearchSaved } = this.state;
    return (
      isSupplierSearchSaved &&
      isShowSaveSearchSupplierLabel === undefined && (
        <FormControlLabel
          data-test-id={"notifyMeSupplier"}
          style={{margin: 0}}
          disabled={true}
          control={
            <SaveSearchCheckBox
              checkedIcon={<img src={notifyCheckedIcon} alt="" />}
              data-test-id={"notifyMeSupplierCheck"}
              value={true}
              name="notifyMe-suppliermodule"
              checked={true}
            />
          }
          label={
            <NotifyMeLabel id="supplier-label">
              Notify me for new entries
            </NotifyMeLabel>
          }
        />
      )
    );
  };

  renderSupplierSaveSearchBox = () => {
    const { supplierFilterObj, isSupplierSearchSaved, isShowSaveSearchSupplierLabel, isSupplierSavingSearch, savingSearchSupplierName, onSaveSearchErrorSupplierMessage, onSaveSearchErrorSupplier } = this.state;
    const isAvailableSupplierSaveSearch = Object.values(supplierFilterObj).some((array: any) => array.length > 0)
    return (
      <TableHeaderLeftContainer>
        <span
          data-test-id="searchResultsSupplierText"
          className="supplierSearchHeading"
        >
          {isShowSaveSearchSupplierLabel === true ? "Saved Results" : "Search Results"}
        </span>
        {(isAvailableSupplierSaveSearch && isShowSaveSearchSupplierLabel === undefined) && (
          <FormControlLabel
            data-test-id="SaveSearchSupplierLable"
            style={{margin: 0}}
            control={
              <SaveSearchCheckBox
                icon={<img src={saveUncheckIcon} alt="" />}
                data-test-id="SaveSearchSupplierrController"
                name="savedSearch-Suppliermodule"
                checkedIcon={<SaveRounded />}
                value={isSupplierSearchSaved}
                checked={isSupplierSearchSaved}
                onChange={this.handleCheckedSupplierSaveSearch}
              />
            }
            disabled={isSupplierSearchSaved}
            label={
              <span
                style={{
                  color: "#2B3132",
                  fontStyle: "normal",
                  fontSize: "14px",
                  fontFamily: "Source Sans Pro",
                  fontWeight: 600,
                  lineHeight: "16px",
                }}
              >
                Save search
              </span>
            }
          />
        )}
        {this.renderNotifyMeSupplier()}
        <CustomeSaveSearchPopper
          data-test-id="supplierSaveSearchPopup"
          open={isSupplierSavingSearch}
          value={savingSearchSupplierName}
          onChange={this.onChangeSupplierSavedSeachName}
          submitSaveSearch={this.saveSupplierSaveSearchRequest}
          cancelSaveSearch={this.cancelSavingSupplierSearch}
          error={onSaveSearchErrorSupplier}
          errorMsg={onSaveSearchErrorSupplierMessage}
        />
      </TableHeaderLeftContainer>)
  }

  renderSupplierChild = () => {
    const {
      isSupplierListLoading,
      supplierFilterObj,
      tableSupplierInputs,
      supplierPaginationDetails,
      hasSupplierError,
    } = this.state;
    return (
      <Box
        data-test-id={configJSON.tenderAddTestId}
        sx={projectModuleTableCommonStyle.mainWrapper}
      >
        <div>
          <ProjectModuleSearch
            id={"AdvancedSearch"}
            navigation={this.props.navigation}
            getProjectsResponse={this.handleSupplierDataResponse}
            getProjectsRequest={this.handleSupplierData}
            filterList={supplierFilterObj}
            type={"Supplier"}
            data-test-id="advanceSupplierSearch"
          />
        </div>
        {isSupplierListLoading ? (
          <Box
            style={projectModuleCommonStyle.loadingSpinner}
            data-test-id="loader"
          >
            <CircularProgress color="inherit" size={"5rem"} />
          </Box>
        ) : (
          <SupplierTable>
            <div
              style={{
                display: isSupplierListLoading ? "none" : "flex",
              }}
              className="supplierTableHeader"
            >
              <div className="supplierSearchBox">
                {this.renderSupplierSaveSearchBox()}
              </div>
              <div className="supplierBtnBox">
                <AccessControl module="Supplier" feature="create">
                  <SupplierAddBtn
                    data-test-id="supplierAddTestId"
                    onClick={this.handleSupplierAddView}
                  >
                    + Add
                  </SupplierAddBtn>
                </AccessControl>
                <SupplierExportData navigation={this.props.navigation} id={""} testid={"projectexport"} classes={{}} searchIds={this.state.searchIds} count={this.state.supplierPaginationDetails.totalLength} />
              </div>
            </div>

            {tableSupplierInputs.apiData.length > 0 ? (
              <TableData
                navigation={this.props.navigation}
                tableLink="Supplier"
                data-test-id="containerTableTestId"
                pagination={supplierPaginationDetails}
                handlePageChange={this.handleSupplierListChangePage}
                handleChangeRowsPerPage={
                  this.handleSupplierListChangeRowsPerPage
                }
                onFavIconClick={(index: number) =>
                  this.handleFavSupplierIconClicked(index)
                }
                handleSort={(column, sortOrder) =>
                  this.handleSupplierSort(column, sortOrder)
                }
                tableInputs={tableSupplierInputs}
                id={""}
              />
            ) : (
              <NoRecordsFoundPage
                data-test-id="reloadBtn"
                activeModule="Suppliers"
                loadData={this.handleLoadSupplierBtn}
                text="No record found"
              />
            )}
            {hasSupplierError && typeof hasSupplierError === "string" && (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={!!hasSupplierError}
                style={{ zIndex: 100000}}
                onClose={() => {
                  this.setState({ hasSupplierError: "" });
                }}
                message={hasSupplierError}
                key="error-message"
              />
            )}
          </SupplierTable>
        )}
      </Box>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <TableLayout
        data-test-id="supplier-tableLayout"
        module="Suppliers"
        open={this.state.isOpenSupplierFilter}
        navigation={this.props.navigation}
        isUpdateSaveSearch={this.state.isSupplierSearchSaved}
        handleFilterList={this.handleSupplierFilterObj}
        isResetFilters={this.state.isResetSupplierFilters}
        toggleOpen={this.toggleSupplierFilterOpen}
        preFilter={this.state.supplierFilterObj}
      >
        {this.renderSupplierChild()}
      </TableLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const NotifyMeLabel = styled(Typography)({
  lineHeight: "16px",
  fontFamily: "Source Sans Pro",
  fontStyle: "normal",
  fontSize: "14px",
  fontWeight: 600,
  color: '#2B3132',
});

const SupplierTable = styled(Box)({
  position: "relative",
  backgroundColor: "#FFF",
  "& .supplierTableHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FFF",
    height: "60px",
    "@media (max-width: 573px)": {
      height: 'auto',
      flexWrap: "wrap",
    },
    padding: "0 15px",
    "@media (max-width: 767px)": {
      padding: "0 5px",
    },
    "@media (max-width: 960px)": {
      padding: "0 10px",
    },
    "& .supplierSearchBox": {
      display: "flex",
      alignItems: "center",
    },
    "& .supplierBtnBox": {
      display: "flex",
      "@media (max-width: 573px)": {
        margin: "5px 0px 5px auto"
      },
      gap: "16px",
      "@media (max-width: 960px)": {
        gap: "7px",
      },
    },
  },
});

const SupplierAddBtn = styled(Button)({
  width: "100px",
  border: "1.5px solid #01949A",
  color: "#01949A",
  textTransform: "none",
  borderRadius: "8px",
  height: 45,
  "@media (max-width: 960px)": {
    maxWidth: 50,
    minWidth: 50,
    fontSize: 12,
    height: 38,
  },
});

const TableHeaderLeftContainer = styled(Box)({
  height: '42px',
  alignItems: "center",
  gap: "20px",
  display: "flex",
  "@media (max-width: 573px)": {
    justifyContent: "space-between",
  },
  "@media (max-width: 513px)": {
    gap: "13px",
  },
  marginRight: "14px",
  "@media (max-width: 482px)": {
    marginRight: "0px",
    gap: "5px",
  },
  "@media (max-width: 453px)": {
    gap: "0px",
  },
 
  "& .supplierSearchHeading": {
    fontFamily: "Source Sans Pro",
    maxWidth: "fit-content",
    fontStyle: "normal",
    width: "100%",
    fontSize: "20px",
   
    lineHeight: "16px",
    color: '#000',
    "@media (max-width: 960px)": {
      fontSize: "17px",
    },
    fontWeight: 600,
    "@media (max-width: 767px)": {
      fontSize: "14px",
    },
  },
})

// Customizable Area End

import React, { Component, CSSProperties } from "react";
import { Button, Typography } from "@material-ui/core";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import TenderSaveAsPdf from "../../../../saveaspdf/src/ProfertSaveAsPdf.web"

interface HeaderProps {
  saveAsPDF?: boolean;
  navigation?: any;
  handleGoBack?: any;
  tenderId?: string;
}

class TenderModuleHeader extends Component<HeaderProps> {

  render() {
    const style: CSSProperties = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "16px 34px 16px 24px",
      backgroundColor: "#0094A7",
      color: "#FFF",
      zIndex: 1000, // This will make sure the header is rendered above other components
    };

    return (
      <div style={style}>
        <Button
          onClick={this.props.handleGoBack}
          data-testId="btnBackToPeople"
          style={{
            alignItems: "center",
            display: "flex",
            color: "#FFF",
            gap: "3px",
          }}
        >
          <KeyboardBackspace style={{ height: "24px", width: "24px" }} />
          <Typography style={{
            fontFamily: "Source Sans Pro",
            fontStyle: "normal",
            fontSize: "20px",
            textTransform: 'none',
            fontWeight: 600,
            lineHeight: "16px",
            marginLeft: '8px'
          }}>Back</Typography>
        </Button>
        {this.props.saveAsPDF && (
           <TenderSaveAsPdf module="tender" targetId={this.props.tenderId || ''} />
        )}
      </div>
    );
  }
}

export default TenderModuleHeader;

export type PropertyKey<T extends keyof S> = keyof S[T];

// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { countSection, handleSectionNavigateLogic ,parseCompanyDetailsProjectModule, parseViewProjectDetails, removeUndefinedPropertiesFromGivenObject, validateAndUpdateErrors } from "../../../components/src/Utils";
import { getStoredDefaultRole } from "../../../components/src/ContextAPI/AdvancedFilterContext.web";
import { projectDetailsValidationSchema } from "../../../components/src/validationSchema";

// Customizable Area End


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface dropDownItems {
  label: string;
  value: string;
}

export interface ErrorObject {
  [key: string]: string;
}

export interface Certificate {
  certificate_id: number;
  certificate_name: string;
  certificate_link: string;
  upload_date: string;
}
export interface Image {
  certificate_id: number;
  image_name: string;
  image_link: string;
  upload_date: string;
}

interface Suppliers {
  name: string;
  sector: string;
  country: string;
  services: string;
  internalRate: string;
}

interface Partners {
  name: string;
  sector: string;
  country: string;
  services: string;
  internalRate: string;
  JVShare: string;
}

interface Tenders {
  name: string;
  groupCompany: string;
  country: string;
  client: string;
  services: string;
}

interface People {
  name: string;
  relation: string;
}

interface Connection {
  suppliers: Suppliers[];
  partners: Partners[];
  tenders: Tenders[];
  people: People[];
}

export interface S {
  // Customizable Area Start
  isPendingApi: boolean;
  isProfileLoading: boolean;
  hasError: string;
  projectDetailsResponse: any;
  projectSectors: dropDownItems[];
  projectServices: dropDownItems[];
  projectClients: dropDownItems[];
  projectGroupCompanies: dropDownItems[];
  projectValuesOfWork: dropDownItems[];
  projectTypesOfCurrency: dropDownItems[];
  projectServiceTypes: dropDownItems[];
  projectStatusOptions: dropDownItems[];
  projectCountries: dropDownItems[];
  errors: ErrorObject[];
  projectId: string;
  company_detail_id: string;
  activeSection: string;
  mode: string | "get" | "post" | "put";
  project_name: string;
  project_code: string;
  project_title: string;
  joint_venture: boolean;
  joint_venture_share: number | null;
  group_company: string;
  country: string;
  client: string;
  client_name: string;
  services: string;
  sector: string;
  type_of_currency: string;
  currency_value: string;
  value_of_work_range: string;
  fee: string;
  value_of_work: string;
  value_of_work_usd: string
  service_type: string;
  start_date: string;
  end_date: string;
  technical_summary: string;
  certificates: Certificate[];
  loadingCertificates: boolean;
  gallery: Image[];
  loadingGalleries: boolean;
  connection: Connection;
  onCertificateUpload: boolean;
  onImageUpload: boolean;
  project_status: string;
  certificatesToUpload: File[];
  certificatesToDelete: number[];
  imagesToUpload: File[];
  imagesToDelete: number[];
  isAuditTrail: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ProjectModuleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sections: string[];
  apiCallIdForGetSectors: string = "";
  apiCallIdForGetServices: string = "";
  apiCallIdForCreateProject: string = "";
  apiCallIdForUploadCertificate: string = "";
  apiCallIdForGetProjectDetails: string = "";
  apiCallIdForGetClients: string = "";
  apiCallIdForGetGroupCompanies: string = "";
  apiCallIdForGetValuesOfWork: string = "";
  apiCallIdForGetTypesOfCurrency: string = "";
  apiCallIdForGetServiceTypes: string = "";
  apiCallIdForGetProjectStatusOptions: string = "";
  apiCallIdForGetCountries: string = "";
  apiCallIdForUpdateTechnicalSummary: string = "";
  apiCallIdForRemoveDocument: string = "";
  countableSection = countSection(configJSON.sections);
  uploadCertificationStatusWatch: { [key: string]: boolean} = {}

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.RequestData),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      isPendingApi: false,
      isProfileLoading: false,
      projectDetailsResponse: null,
      hasError: "",
      projectSectors: [],
      projectServices: [],
      projectClients: [],
      projectGroupCompanies: [],
      projectValuesOfWork: [],
      projectTypesOfCurrency: [],
      projectServiceTypes: [],
      projectStatusOptions: [],
      projectCountries: [],
      errors: [],
      activeSection: configJSON.sections[0],
      mode: configJSON.newRecordMode,
      projectId: "",
      company_detail_id: "",
      project_name: "",
      project_code: "",
      project_title: "",
      joint_venture: configJSON.jointVentureFalse,
      joint_venture_share: null,
      group_company: "",
      country: "",
      client: "",
      client_name: "",
      services: "",
      sector: "",
      type_of_currency: "",
      currency_value: "",
      fee: "",
      value_of_work: "",
      value_of_work_range: "",
      value_of_work_usd: "",
      service_type: "",
      start_date: "",
      end_date: "",
      technical_summary: "",
      project_status: "",
      certificates: [],
      loadingCertificates: false,
      gallery: [],
      loadingGalleries: false,
      connection: {
        suppliers: [],
        partners: [],
        tenders: [],
        people: [],
      },
      onCertificateUpload: false,
      onImageUpload: false,
      certificatesToUpload: [],
      certificatesToDelete: [],
      imagesToUpload: [],
      imagesToDelete: [],
      isAuditTrail: false
    };

    this.sections = configJSON.sections;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const project_id = this.props.navigation.getParam("project_id");
    if (project_id && project_id !== "add") {
      this.setState({ projectId: project_id }, () =>
        this.getProjectDetailsRequest(project_id)
      );
    } else {
      this.setState({
        mode: 'post'
      })
    }
    this.getProjectModuleFields();
    // Customizable Area End
  }

  // ----- RECIEVE DATA RESPONSE FROM APIS ----- //
  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (
        responseJson &&
        responseJson.errors &&
        Array.isArray(responseJson.errors) &&
        responseJson.errors.length > 0 &&
        responseJson.errors[0].token
      ) {
        localStorage.removeItem("authToken");
        this.props.navigation.navigate("LoginBlock");
        return;
      }
      this.getProjectModuleApiResponse(apiRequestCallId, responseJson);
    }

    // Customizable Area End
  }

  getProjectModuleApiResponse = (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.apiCallIdForGetSectors:
        this.getSectorsResponse(responseJson);
        break;
      case this.apiCallIdForGetServices:
        this.getServicesResponse(responseJson);
        break;
      case this.apiCallIdForGetClients:
        this.getClientsResponse(responseJson);
        break;
      case this.apiCallIdForGetGroupCompanies:
        this.getGroupCompaniesResponse(responseJson);
        break;
      case this.apiCallIdForGetValuesOfWork:
        this.getValuesOfWorkResponse(responseJson);
        break;
      case this.apiCallIdForGetTypesOfCurrency:
        this.getTypesOfCurrencyResponse(responseJson);
        break;
      case this.apiCallIdForGetServiceTypes:
        this.getServiceTypesResponse(responseJson);
        break;
      case this.apiCallIdForGetProjectStatusOptions:
        this.getProjectStatusOptionsResponse(responseJson);
        break;
      case this.apiCallIdForGetCountries:
        this.getCountriesResponse(responseJson);
        break;
      case this.apiCallIdForUploadCertificate:
        this.handleNotFoundRecord(responseJson);
       
      
        this.projectApiResponse(responseJson);
        this.handleChange("certificatesToUpload", []);
        this.handleChange("imagesToUpload", []);
       
        break;
      case this.apiCallIdForCreateProject:
      case this.apiCallIdForGetProjectDetails:
      case this.apiCallIdForUpdateTechnicalSummary:
      case this.apiCallIdForRemoveDocument:
        this.handleNotFoundRecord(responseJson)
        this.projectApiResponse(responseJson);
        break;
      default:
        // Handle cases not covered above, if necessary
        break;
    }
  };

  handleNotFoundRecord = (responseJson: any) => {
		if (responseJson?.meta?.message && !responseJson?.data) {
			this.setState({ isProfileLoading: false })
			let errorMessage: string = responseJson.meta.message;
			  
			this.setState(
				{
					hasError: errorMessage ? errorMessage : 'Error',
				},
				() => {
					this.props.navigation.push("/Projects/add");
				}
			);

		}
	}


  // Customizable Area Start
  handleRequestNavigation = () =>{
    const requestData = {
        id : this.state.projectId,
        module : "Project",
        recordName : this.state.project_name
    }
    const project = new Message(getName(MessageEnum.NavigationRequestMessage));
    project.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    project.addData(getName(MessageEnum.RequestData), requestData);
    this.send(project);
  }

  handleListItemClick = (section: any) => {

    this.setState({
      activeSection: section,
      mode: 'get'
    });

    this.countableSection.setActive(section)

    if(section === "Audit trail"){
      this.handleEditButtonClick()
    }
  };

  handleError = (errorMessage: string) => {
    this.setState({
      hasError: errorMessage,
    });
  };

  handleChange = (changedPropertyName: keyof S, changedValue: any | null) => {
    const { errors } = this.state;
   
    if (changedPropertyName) {
      this.setState((prevState: S) => {
        return {
          ...prevState,
          [changedPropertyName]: changedValue,
        };
      });
      if ( configJSON.errorsKey.includes(changedPropertyName) || errors.find((error) => error.hasOwnProperty(changedPropertyName))) {
        const newErrors = validateAndUpdateErrors(projectDetailsValidationSchema, this.state.errors, changedPropertyName, changedValue)
        this.setState({
          errors: newErrors
        });
    }
  };
}

  handleGoBack = () => {
    this.props.navigation.navigate("ProjectModuleTable")
  };
  
  getProjectDetailsRequest = (project_id: string) => {
    this.setState({ isProfileLoading: true });
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdForGetProjectDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getProjectDetailsEndPoint}/${project_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleRadioButtonChange = (event: any) => {
    if (event.target.name === "yes") {
      this.handleChange("joint_venture", configJSON.jointVentureTrue);
      this.handleChange("joint_venture_share", 0);
    }
    if (event.target.name === "no") {
      this.handleChange("joint_venture", configJSON.jointVentureFalse);
      this.handleChange("joint_venture_share", null);
    }
  };

  handleCancelButton = () => {
    const {
      activeSection,
      onCertificateUpload,
      projectDetailsResponse,
      projectId,
    } = this.state;
    if (projectId === "")
      this.props.navigation.navigate(configJSON.goBackParam);
    if (
      (activeSection === configJSON.sections[0] ||
        activeSection === configJSON.sections[1]) &&
      projectDetailsResponse
    ) {
      this.setState((prevState) => {
        return {
          ...prevState,
          ...projectDetailsResponse,
        };
      });
    }
    if (activeSection === configJSON.sections[2] || activeSection === configJSON.sections[3]) {
      this.setState((prevState) => {
        return {
          ...prevState,
          ...projectDetailsResponse,
          onCertificateUpload: false,
          certificatesToUpload: [],
          certificatesToDelete: [],
          onImageUpload: false,
          imagesToUpload: [],
          imagesToDelete: [],
        };
      });
      this.getProjectDetailsRequest(this.state.projectId)
    }
    this.handleChange("mode", configJSON.viewRecordMode);
  };

  handleSaveButton = async (): Promise<void> => {
    const { activeSection, certificatesToUpload, imagesToUpload, certificatesToDelete, imagesToDelete } = this.state;

    const errors = this.validationCheck();
    if (errors.length > 0) {
      this.setState({ errors });
      return;
    }

    switch (activeSection) {
      case configJSON.sections[0]:
        this.createProjectRequest();
        this.setState({ isAuditTrail: true })
        break;
      case configJSON.sections[1]:
        this.editTechnicalSummaryRequest();
        break;
      case configJSON.sections[2]:
        await this.handleCertificateSection(certificatesToUpload, certificatesToDelete);
        break;
      case configJSON.sections[3]:
        await this.handleImageSection(imagesToUpload, imagesToDelete, certificatesToUpload);
        break;
    }
  };
  
  handleCBSubmitDocument = () => {
		const projectId = this.props.navigation.getParam('project_id');

    this.setState({
      mode: 'get',
			activeSection: handleSectionNavigateLogic(projectId, this.countableSection.get(configJSON.sections[4]) == 1, configJSON.sections[5], configJSON.sections[4])
		})
	}

  handleEditCB = () => {
    this.setState({
      mode: 'put'
    })
  }

  handleCertificateSection = async (certificatesToUpload: any[], certificatesToDelete: any[]): Promise<void> => {
    const projectId = this.props.navigation.getParam('project_id');
    await this.handleUploadAndDelete(certificatesToUpload, certificatesToDelete, 'certificate', 'onCertificateUpload');
    this.handleChange("certificatesToDelete", []);
  };

  handleImageSection = async (imagesToUpload: any[], imagesToDelete: any[], certificatesToUpload: any[]): Promise<void> => {
    const projectId = this.props.navigation.getParam('project_id');
    await this.handleUploadAndDelete(imagesToUpload, imagesToDelete, 'image', 'onImageUpload');
    this.handleChange("imagesToDelete", []);
  };

  handleUploadAndDelete = async (uploads: any[], deletes: any[], type: 'certificate' | 'image', state: keyof S): Promise<void> => {
    this.handleChange(state, false);
    this.handleChange("isPendingApi", true);
    if (deletes.length > 0) {
      await this.sendDeleteCertificateRequests(deletes, type);
    }
    
    for (const item of uploads) {
      await this.uploadCertificateRequest(item);
    }
    
    if (uploads.length == 0 && deletes.length ==0 ) {
      this.handleChange("mode", 'get');
      this.handleChange("isPendingApi", false);
    }
  };

  handleDeleteCertificate = (attachment_id: number, type: string) => {
    const { imagesToDelete, certificatesToDelete, certificates, gallery } =
      this.state;
    switch (type) {
      case "image": {
        imagesToDelete.push(attachment_id);
        const newGallery = gallery.filter(
          (img) => img.certificate_id !== attachment_id
        );
        this.setState({
          imagesToDelete,
          gallery: newGallery,
        });
        break;
      }
      case "certificate": {
        certificatesToDelete.push(attachment_id);
        const newCerts = certificates.filter(
          (cert) => cert.certificate_id !== attachment_id
        );
        this.setState({
          certificatesToDelete,
          certificates: newCerts,
        });
        break;
      }
    }
  };

  sendDeleteCertificateRequests = async (array: number[], type: string) => {
    await new Promise(async (resolve, reject) => {
      this.handleChange("isPendingApi", true);
      this.setState({ isAuditTrail: true })
      const { projectId } = this.state;

      const url = `${configJSON.deleteCertificateEndPoint}/${projectId}/delete_certificate`;

      const header = {
        "Content-Type": "application/json",
        token:
          typeof window !== "undefined"
            ? localStorage.getItem("authToken")
            : null,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.apiCallIdForRemoveDocument = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        url
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiDeleteMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify({ 
          attachment_ids: array, 
          type,
          role: getStoredDefaultRole()
        })
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      resolve(true);
    });
  };

  handleFileChange = (event: any) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        this.handleError(configJSON.bigFileErrorMessage);
      } else {
        switch (this.state.activeSection) {
          case configJSON.sections[2]: {

            const filesToUpload = this.state.certificatesToUpload;
            filesToUpload.push(file);
            this.handleChange("certificatesToUpload", filesToUpload);
            break;
          }
          case configJSON.sections[3]: {

            const filesToUpload = this.state.imagesToUpload;
            filesToUpload.push(file);
            this.handleChange("imagesToUpload", filesToUpload);
            break;
          }
        }
      }
      event.target.value = "";
    } else {
      this.handleError(configJSON.fileErrorMessage);  
    }
  };

  removeStagedFile = (fileIndex: number) => {
    switch (this.state.activeSection) {
      case configJSON.sections[2]: {
        const { certificatesToUpload } = this.state;
        const newCertificates = certificatesToUpload.filter(
          (element, index) => index !== fileIndex
        );
        this.handleChange("certificatesToUpload", newCertificates);
        break;
      }
      case configJSON.sections[3]: {
        const { imagesToUpload } = this.state;
        const newImages = imagesToUpload.filter(
          (element, index) => index !== fileIndex
        );
        this.handleChange("imagesToUpload", newImages);
        break;
      }
    }
  };

  validationCheck = (): ErrorObject[] => {
    let errors: ErrorObject[] = [...this.state.errors];
    const { activeSection, project_name, joint_venture, joint_venture_share, currency_value } = this.state;

    switch (activeSection) {
      case configJSON.sections[0]:
        errors = [...errors, ...this.validateSection0(project_name, joint_venture, joint_venture_share, currency_value)];
        break;
      case configJSON.sections[1]:
        // Add validation for section 1 if needed
        break;
      // Add more cases for other sections if needed
    }
    return errors;
  };

  validateSection0 = (projectName: string, jointVenture: boolean, jointVentureShare: number | null, currencyValue: string): ErrorObject[] => {
    const errors: ErrorObject[] = [];

    // Validate project name
    if (!projectName || projectName.trim().length === 0) {
      errors.push({ project_name: "Project name is required." });
    }

    // Validate joint venture share
    if (jointVenture) {
      if (jointVentureShare && (jointVentureShare <= 0 || jointVentureShare >= 100)) {
        errors.push({ joint_venture_share: configJSON.jointVentureShareInputError });
      }
    }

    // Validate currency value
    if (currencyValue) {
      const enterValue = parseFloat(currencyValue);
      if (isNaN(enterValue) || enterValue < 0) {
        console.log("currencyValue violates validation");
        errors.push({ currency_value: configJSON.enterValueInputError });
      }
    }

    return errors;
  };

  getProjectModuleFields = () => {
    this.getSectorsRequest();
    this.getServicesRequest();
    this.getClientsRequest();
    this.getGroupCompaniesRequest();
    this.getValuesOfWorkRequest();
    this.getTypesOfCurrencyRequest();
    this.getServiceTypesRequest();
    this.getCountriesRequest();
    this.getProjectStatusOptionsRequest();
  };

  getSectorsRequest = () => {
    this.handleChange("isPendingApi", true);
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdForGetSectors = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSectorsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSectorsResponse = (responseJson: any) => {
    if (Array.isArray(responseJson)) {
      const sectors: any = [];
      responseJson.forEach((sector: any) => {
        sectors.push({
          value: sector.id.toString(),
          label: sector.name,
        });
      });
      this.handleChange("projectSectors", sectors);
    } else {
      this.handleError(
        "Project Sectors not consumed properly. Check network logs"
      );
    }
    this.handleChange("isPendingApi", false);
  };
  getServicesRequest = () => {
    this.handleChange("isPendingApi", true);
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdForGetServices = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getServicesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getServicesResponse = (responseJson: any) => {
    if (Array.isArray(responseJson)) {
      const services: any = [];
      responseJson.forEach((service: any) => {
        services.push({
          value: service.id.toString(),
          label: service.name,
        });
      });
      this.handleChange("projectServices", services);
    } else {
      this.handleError(
        "Project Services not consumed properly. Check network logs"
      );
    }
    this.handleChange("isPendingApi", false);
  };
  getClientsRequest = () => {
    this.handleChange("isPendingApi", true);
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdForGetClients = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getClientsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getClientsResponse = (responseJson: any) => {
    if (Array.isArray(responseJson)) {
      const clients: any = [];
      responseJson.forEach((service: any) => {
        clients.push({
          value: service.id.toString(),
          label: service.name,
        });
      });
      this.handleChange("projectClients", clients);
    } else {
      this.handleError(
        "Project Clients not consumed properly. Check network logs"
      );
    }
    this.handleChange("isPendingApi", false);
  };
  getGroupCompaniesRequest = () => {
    this.handleChange("isPendingApi", true);
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdForGetGroupCompanies = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroupCompaniesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getGroupCompaniesResponse = (responseJson: any) => {
    if (Array.isArray(responseJson)) {
      const projectGroupCompanies: any = [];
      responseJson.forEach((service: any) => {
        projectGroupCompanies.push({
          value: service.id.toString(),
          label: service.name,
        });
      });
      this.handleChange("projectGroupCompanies", projectGroupCompanies);
    } else {
      this.handleError(
        "Project Group Companies not consumed properly. Check network logs"
      );
    }
    this.handleChange("isPendingApi", false);
  };
  getTypesOfCurrencyRequest = () => {
    this.handleChange("isPendingApi", true);
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdForGetTypesOfCurrency = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTypesOfCurrencyEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getTypesOfCurrencyResponse = (responseJson: any) => {
    if (Array.isArray(responseJson)) {
      const typesOfCurrency: any = [];
      responseJson.forEach((service: any) => {
        typesOfCurrency.push({
          value: service.id.toString(),
          label: service.currency_type,
        });
      });
      this.handleChange("projectTypesOfCurrency", typesOfCurrency);
    } else {
      this.handleError(
        "Project Types of currency not consumed properly. Check network logs"
      );
    }
    this.handleChange("isPendingApi", false);
  };
  getValuesOfWorkRequest = () => {
    this.handleChange("isPendingApi", true);
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdForGetValuesOfWork = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getValuesOfWorkEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getValuesOfWorkResponse = (responseJson: any) => {
    if (Array.isArray(responseJson)) {
      const valuesOfWork: any = [];
      responseJson.forEach((service: any) => {
        valuesOfWork.push({
          value: service.id.toString(),
          label: service.value_of_work,
        });
      });
      this.handleChange("projectValuesOfWork", valuesOfWork);
    } else {
      this.handleError(
        "Project Values of work not consumed properly. Check network logs"
      );
    }
    this.handleChange("isPendingApi", false);
  };
  getServiceTypesRequest = () => {
    this.handleChange("isPendingApi", true);
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdForGetServiceTypes = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getServiceTypesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getServiceTypesResponse = (responseJson: any) => {
    if (Array.isArray(responseJson)) {
      const serviceTypes: any = [];
      responseJson.forEach((service: any) => {
        serviceTypes.push({
          value: service.id.toString(),
          label: service.name,
        });
      });
      this.handleChange("projectServiceTypes", serviceTypes);
    } else {
      this.handleError(
        "Project Service types not consumed properly. Check network logs"
      );
    }
    this.handleChange("isPendingApi", false);
  };
  getCountriesRequest = () => {
    this.handleChange("isPendingApi", true);
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdForGetCountries = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountriesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCountriesResponse = (responseJson: any) => {
    if (Array.isArray(responseJson)) {
      const countries: any = [];
      responseJson.forEach((service: any) => {
        countries.push({
          value: service.id.toString(),
          label: service.name,
        });
      });
      this.handleChange("projectCountries", countries);
    } else {
      this.handleError(
        "Project Countries not consumed properly. Check network logs"
      );
    }
    this.handleChange("isPendingApi", false);
  };
  getProjectStatusOptionsRequest = () => {
    this.handleChange("isPendingApi", true);
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdForGetProjectStatusOptions = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectStatusOptionsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getProjectStatusOptionsResponse = (responseJson: any) => {
    if (Array.isArray(responseJson)) {
      const statusOptions: any = [];
      responseJson.forEach((service: any) => {
        statusOptions.push({
          value: service.id.toString(),
          label: service.status,
        });
      });
      this.handleChange("projectStatusOptions", statusOptions);
    } else {
      this.handleError(
        "Project status options not consumed properly. Check network logs"
      );
    }
    this.handleChange("isPendingApi", false);
  }

  uploadCertificateRequest = async (file: File) => {
    this.setState({ isAuditTrail: true })
    await new Promise(async (resolve, reject) => {
      const projectId = this.state.projectId;
      if (projectId === "") {
        this.handleError("project id not found");
        return;
      }

      const formData = new FormData();
      const activeSection = this.state.activeSection;
      if (activeSection === configJSON.sections[2]) {
        formData.append("project[certificates][]", file);
      }
      if (activeSection === configJSON.sections[3]) {
        formData.append("project[images][]", file);
      }

      formData.append("role", getStoredDefaultRole())

      const header = {
        token:
          typeof window !== "undefined"
            ? localStorage.getItem("authToken")
            : null,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.apiCallIdForUploadCertificate = requestMessage.messageId;
      this.uploadCertificationStatusWatch[requestMessage.messageId] = false;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_profile/projects/${projectId}/upload_certificates`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiPatchMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);

      setTimeout(() => {
        resolve(true);
      }, 300);
    });
  };
//istanbul ignore next
  createProjectRequest = () => {
    this.handleChange("isPendingApi", true);
    const {
      projectId,
      company_detail_id,
      project_name,
      project_code,
      project_title,
      joint_venture,
      joint_venture_share,
      start_date,
      end_date,
      group_company,
      country,
      client,
      client_name,
      sector,
      services,
      type_of_currency,
      currency_value,
      value_of_work,
      fee,
      project_status,
      projectSectors,
      projectServices,
      projectServiceTypes,
      service_type,
    } = this.state;

    const sector_id = projectSectors.find((rec) => rec.label === sector)?.value;
    const service_ids: number[] = [];
    services.split(",").forEach((rec) => {
      const service = projectServices.find((service) => service.label === rec);
      if (service) {
        service_ids.push(parseInt(service.value));
      }
    });
    const service_type_id = projectServiceTypes.find(
      (rec) => rec.label === service_type
    );
    const currency = currency_value.toString().replace(configJSON.actualValue, "");
		const valueOfWork = value_of_work.toString().replace(configJSON.actualValue, "");
    
    const company_detail_attributes: any = {
      id: company_detail_id === "" ? "" : company_detail_id,
      group_company,
      country,
      client,
      client_name,
      sector_id: sector_id ? parseInt(sector_id) : null,
      currency_value: currency,
      type_of_currency,
      value_of_work: valueOfWork
    };
    if (service_ids.length > 0) {
      company_detail_attributes.service_ids = service_ids;
    } else {
      company_detail_attributes.service_ids = [];
    }
    const data: any = {
      project_name,
      title: project_title,
      code: project_code,
      joint_venture,
      service_type_id: service_type_id ? service_type_id.value : null,
      start_date,
      end_date,
      company_detail_attributes: company_detail_attributes,
      project_status,
    };
    if (joint_venture)
      data.joint_venture_share = joint_venture_share ? (joint_venture_share).toFixed(
        configJSON.fixedJVfloating
      ): null;
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };

    const httpBody = {
      project: data,
      role: getStoredDefaultRole()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdForCreateProject = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      projectId === ""
        ? `/bx_block_profile/projects`
        : `/bx_block_profile/projects/${projectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      projectId !== "" ? configJSON.apiPatchMethod : configJSON.apiPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isAuditTrail: true })
  };

  projectApiResponse = (responseJson: any, appCallId?: string) => {
    this.setState({ isAuditTrail: false })
    if (responseJson === null || responseJson === undefined) {
      this.handleError("Backend error. please contact with administrator");
      this.setState({
        isPendingApi: false,
        isProfileLoading: false,
      });
      return;
    }
    const { errors, data, error } = responseJson;
    const { activeSection } = this.state;
    const projectId = this.props.navigation.getParam("project_id");

    if (error && typeof error === "string") {
      this.handleError(error);
      this.handleChange("isPendingApi", false);
      return;
    }

    if (errors && Array.isArray(errors)) {
      this.handleBackendValidationErrors(errors)
      return;
    }

    if (data && data.attributes && !errors) {
      let company_details: any = {};
      let service_type: string = "";
      const projectDetails = data.attributes;

      company_details = parseCompanyDetailsProjectModule(data)

      if (projectDetails.service_type && projectDetails.service_type.data) {
        service_type = projectDetails.service_type.data.attributes.name;
      }
      const viewedProjectDetails = parseViewProjectDetails(projectDetails, company_details, service_type);
      this.setState((prevState) => {
        return {
          ...prevState,
          ...removeUndefinedPropertiesFromGivenObject(viewedProjectDetails),
          projectDetailsResponse: viewedProjectDetails,
          projectId: viewedProjectDetails.projectId,
        };
      });
    }
    if (projectId == 'add') {
      this.handleActiveSection(activeSection, projectId);
    }

    this.setState({
      isPendingApi: false,
      isProfileLoading: false,
      mode: handleSectionNavigateLogic(projectId, this.countableSection.get(activeSection) <= 1, "post", "get")
    });
  };

  handleBackendValidationErrors = (errors: any[]) => {
    const backendValidationErrors: ErrorObject[] = [];
    errors.forEach((error) => {
      const errorCode = Object.keys(error)[0].replace("company_detail.", "");
      const errorLabel = Object.values(error)[0] as string;
      switch (errorCode) {
        case "code":
          backendValidationErrors.push({ project_code: errorLabel });
          break;
        default:
          backendValidationErrors.push({
            [errorCode]: errorLabel,
          });
      }
    });
    this.handleChange("errors", backendValidationErrors);
    this.handleChange("isPendingApi", false);
  }

  handleActiveSection = (activeSection: string, projectId: string) => {
    const { mode } = this.state;
    switch (activeSection) {
      case configJSON.sections[0]:
  
        this.setState({
          activeSection: mode == 'post' && !this.countableSection.actived(configJSON.sections[1]) ? configJSON.sections[1] :  configJSON.sections[0],
          mode: handleSectionNavigateLogic(projectId, !this.countableSection.actived(configJSON.sections[1]), "post", "get")
        })
        this.countableSection.setActive(configJSON.sections[1])
        break;
      case configJSON.sections[1]:
        this.setState({
          activeSection: mode == 'post' ? configJSON.sections[2] :  configJSON.sections[1],
          mode: handleSectionNavigateLogic(projectId, !this.countableSection.actived(configJSON.sections[2]), "post", "get")
        })
        this.countableSection.setActive(configJSON.sections[2])
        break;
      case configJSON.sections[2]:
        this.setState({
          onImageUpload: false,
          mode: handleSectionNavigateLogic(projectId, !this.countableSection.actived(configJSON.sections[3]), "post", "get"),
          activeSection: mode == 'post' ? configJSON.sections[3] :  configJSON.sections[2]
        })
        this.countableSection.setActive(configJSON.sections[3])
        break;
        
      case configJSON.sections[3]:
        this.setState({
          onImageUpload: false,
          mode: handleSectionNavigateLogic(projectId, !this.countableSection.actived(configJSON.sections[4]), "post", "get"),
          activeSection: mode == 'post' ? configJSON.sections[4] :  configJSON.sections[3]
        })
        this.countableSection.setActive(configJSON.sections[4]);

        break;
    }
  }

  editTechnicalSummaryRequest = () => {
    this.handleChange("isPendingApi", true);
    this.setState({ isAuditTrail: true })
    const { projectId, technical_summary } = this.state;
    const data = {
      id: projectId,
      technical_summary,
    };

    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };

    const httpBody = {
      project: data,
      role: getStoredDefaultRole()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdForUpdateTechnicalSummary = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_profile/projects/${projectId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPatchMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleEditButtonClick = () => {
    this.handleChange("mode", configJSON.updateRecordMode);
  };

  openImage = (imageUrl: string) => {
    window.open(imageUrl, "_blank");
  };


  // Customizable Area End
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypeDelete = "DELETE";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.brandAPiEndPoint = "catalogue/brands";
exports.filterbrandAPiEndPoint = "filter_items/filtering?q[brand_id]=";
exports.singleCategoryAPiEndPoint = "filter_items/filtering?q[category_id]=";
exports.cataloguesAPiEndPoint = "filter_items/filtering?";

exports.getLevelEducationEndPoint = "bx_block_company/education_degree";
exports.getDisciplineEducatioEndpoint = "bx_block_company/discipline";
exports.getExperienceEndPoint = "bx_block_company/people_experience";
exports.getCurrentPositionEndPoint = "bx_block_company/current_position";
exports.getNationalityApiCallId = "bx_block_people/countries"
exports.getLanguagesEndPoint = "bx_block_company/other_language";
exports.getSaveSearchListEndPoint = "bx_block_saved_search/saved_searches"

exports.getProjectNameEndPoint = "bx_block_profile/projects";
exports.getGroupCompanyEndPoint = "bx_block_company/group_companies";
exports.getClientsEndPoint = "bx_block_company/clients";
exports.getCountryEndPoint = "bx_block_company/countries";
exports.getServicesEndPoint = "bx_block_company/services";
exports.getSectorsEndPoint = "bx_block_company/sectors";
exports.getValueOfWorkEndPoint = "bx_block_company/values_of_work";
exports.getProjectStatusEndPoint = "bx_block_company/project_statuses";
exports.getTenderStatusEndPoint = "bx_block_company/tender_status";
exports.getSavedProjectSearchEndPoint = "bx_block_saved_search/saved_searches";
exports.savedSearchListTestId = "saved-search-list-test-id";
exports.notifyMeEndPoint = "bx_block_profile/projects/notify_project"
exports.savedProjectSearchListLengthTestId =
  "saved-project-search-list-length-test-id";
exports.savedSearchViewContainerTestId = "saved-search-view-container-test-id";
exports.seeAllSavedSearchTestId = "see-all-saved-search-test-id";
exports.savedFilterListContainerTestId = "saved-filter-list-container-test-id";
exports.noRecordAddedTestId = "saved-filter-no-record-added";
exports.closeButtonSavedSearchListTestId = "close-saved-filter-list-test-id";
exports.savedFilterListApplyButtonTestId =
  "saved-filter-list-apply-button-test-id";
exports.savedFilterListResetButtonTestId =
  "saved-filter-list-Reset-button-test-id";
exports.listedSavedFilterItemTestId = "listed-saved-filter-item-test-id";
exports.onSaveSearchErrorMessage = "Search name is required";
exports.onSaveSearchNoFilterMessage = "At least one filter is required";
exports.showSavedFilterItemContainerTestId =
  "show-saved-filter-item-container-test-id";
exports.editSavedSearchItemButtonTestId =
  "edit-saved-search-item-button-test-id";
exports.deleteSavedSearchItemButtonTestId =
  "delete-saved-search-item-button-test-id";
exports.savedSearchEditInputSearchNameTestId =
  "edit-saved-search-input-test-id";
exports.savedBtnIndexedSavedSearchTEstId = 
  "saved-button-indexed-search-item-test-id;";
exports.successEditSavedSearchNotificationTestId = "sucess-edit-saved-search-notification-test-id";
exports.deleteConfirmationContainerTestId = "delete-confirmation-cntainer-test-id";
exports.dismissDeletionForSavedSearchTestId = "dismiss-deletion-for-saved-search-test-id";
exports.savedFilterListItemTestId = "saved-filter-list-item-test-id"
exports.acceptDeletionForSavedSearchTestId = "accept-deletion-for-saved-search-test-id"
exports.closeNotificationInSavedSearchTestId = "close-notification-in-saved-search-test-i";
exports.cancelIndexedSavedSearchTestId = "cancel-indexed-saved-search-test-id";
// Customizable Area End

import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import OfflineWorkController, {
  Props,
  configJSON,
} from "./OfflineWorkController";

export default class OfflineWork extends OfflineWorkController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
            <Box sx={webStyle.inputStyle}>
              <Button style={webStyle.buttonStyle}>{configJSON.btnExampleTitle}</Button>
              <Typography>{configJSON.ChooseProfilePicture}</Typography>
            </Box>
        </Container>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  inputStyle: {
    boxShadow: '0px 0px 10px #979797',
    padding: "25px",
    marginTop: "20%",
    marginBottom: "20px",
    borderRadius: "8px"
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End

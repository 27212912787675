import React, { Component, CSSProperties } from "react";
import { Button, Typography } from "@material-ui/core";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import { configJSON } from "../../ProjectModuleController.web";
import CustomButton from "../../../../../components/src/CustomButton.web";
import { projectModuleCommonStyle } from "../../assets";
import ProjectSaveAsPdf from "../../../../saveaspdf/src/ProfertSaveAsPdf.web"

interface HeaderProps {
  projectId?: string;
  activeSection?: string;
  saveAsPDF?: boolean;
  handleBackClick: () => void;
  handleSavePdfClick?: () => void;
}

export default class ProjectModuleHeader extends Component<HeaderProps> {
  render() {
    const style: CSSProperties = {
      // position: "sticky",
      // top: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "16px 24px",
      backgroundColor: "#0094A7",
      color: "#FFF",
      zIndex: 10, // This will make sure the header is rendered above other components
    };

    const { saveAsPDF, projectId } = this.props;

    return (
      <div style={style}>
        <Button
          data-test-id={configJSON.backButtonTestId}
          onClick={this.props.handleBackClick}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "3px",
            color: "#FFF",
          }}
        >
          <KeyboardBackspace style={{ width: "24px", height: "24px" }} />
          <Typography
            style={{
              ...projectModuleCommonStyle.commonText,
              ...projectModuleCommonStyle.headerText,
              textTransform: "none",
            }}
          >
            Back
          </Typography>
        </Button>
        {saveAsPDF && (
          <ProjectSaveAsPdf module="project" targetId={projectId || ''} />
        )}
      </div>
    );
  }
}

import { Checkbox, withStyles } from "@material-ui/core";

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const notifyCheckedIcon = require("../assets/NotifyCheckedIcon.svg");
export const notifyUnheckedIcon = require("../assets/NotifyUncheckedIcon.svg");


export const NotifyCheckBox = withStyles({
    root: {
        padding: "0 9px"
    }
})(Checkbox);

export enum CheckboxStateEnum {
    people = 'people_notify',
    projects = 'project_notify',
    tenders = 'notify_me',
    suppliers = 'notify_me',
    partners = 'notify_me',
}
  
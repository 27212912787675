import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { CheckboxStateEnum, imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { totalFilterCountFromObject } from "../../../components/src/Utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  open: boolean;
  savedObj: any;
  module: string;
  handleOpenEditPopup: (value:boolean, obj?:any) => void;
  applyEditSaveSearch: () => void;
  isFilterOpen?: boolean;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  inputField: string;
  isEditError: boolean;
  editErrorMsg: string;
  filterList: any;
  filterCounter: number;
  notifyMe: boolean;
  isOpenFilter: boolean;
  isLoadUpdate: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SavesearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  editSaveSearchApiCallId: string = "";
  emailSaveSearchApiCallId: string = "";

  updateEndpoint: {[key:string]:string} = {
    people: `${configJSON.notifyMePeopleEndPoint}?search_id=${this.props.savedObj.id}`,
    projects: `${configJSON.notifyMeProjectEndPoint}?search_id=${this.props.savedObj.id}`,
    tenders: `${configJSON.notifyMeTenderEndPoint}?search_id=${this.props.savedObj.id}`,
    suppliers: `${configJSON.notifyMeSupplierEndPoint}?search_id=${this.props.savedObj.id}`,
    partners: `${configJSON.notifyMePartnerEndPoint}&search_id=${this.props.savedObj.id}`
  }

  updateMethod: {[key:string]:string} = {
    people: configJSON.validationApiMethodType,
    projects: configJSON.validationApiMethodType,
    tenders: configJSON.validationApiMethodType,
    suppliers: configJSON.patchApiMethod,
    partners: configJSON.patchApiMethod,
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      inputField: "",
      isEditError: false,
      notifyMe: false,
      isLoadUpdate: false,
      editErrorMsg: "",
      filterList: {},
      filterCounter: 0,
      isOpenFilter: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const editSuccessCallMap = {
        [this.editSaveSearchApiCallId]: this.editSaveResponse,
        [this.emailSaveSearchApiCallId]: this.emailResponse,
      };
  
      if (apiRequestCallId) {
        const successCallback: (responseJson: any) => void =
          editSuccessCallMap[apiRequestCallId];
        !!successCallback && successCallback(responseJson);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    const { search_name, filters ,filterCounter } = this.props.savedObj
    this.setState({
      inputField: search_name,
      filterList: filters,
      filterCounter: filterCounter,
      notifyMe: this.props.savedObj[CheckboxStateEnum[this.props.module as keyof typeof CheckboxStateEnum]],
      isOpenFilter: !!this.props.isFilterOpen
    })
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    const { search_name, filters ,filterCounter } = this.props.savedObj
    if(prevProps.savedObj !== this.props.savedObj){
      this.setState({
        inputField: search_name,
        filterList: filters,
        filterCounter: filterCounter,
        notifyMe: this.props.savedObj[CheckboxStateEnum[this.props.module as keyof typeof CheckboxStateEnum]]
      })
    }
  }

  handleNotifyChange = () => {
    this.setState({
      notifyMe: !this.state.notifyMe,
    });
  }

  handleSetFilters = ( value:any) => {
    const counter = totalFilterCountFromObject(value)
    this.setState({ filterList: value, filterCounter: counter})
  }

  handleCloseEditFilter = () => {
    this.setState({ isOpenFilter: false })
  }

  handleChangeInputValue = (event: React.ChangeEvent<HTMLInputElement>) => { 
    this.setState({
      isEditError: false,
      inputField: event.target.value
    })
  }

  handleCloseIcon = () => {
    this.props.handleOpenEditPopup(false)
    this.setState({
      inputField: "",
      isEditError: false,
      editErrorMsg: "",
    })
  }

  editSavedSearchRequest = () =>{
    const { id } = this.props.savedObj;
    const { inputField, filterList } = this.state
    const editBody = {
      search_name: inputField.trim(),
      filters: filterList,
    }    
    const url = `${configJSON.saveSearchEndPoint}/${id}`;
    this.editSaveSearchApiCallId = this.editApiCall({
      contentType: configJSON.validationApiContentType,
      endPoint: url,
      editBody, 
      method: 'PATCH'
    })
  }

  editSaveResponse = (responseJson: any) => {
    if(responseJson.data) {
      const oldNotifyMe = this.props.savedObj[CheckboxStateEnum[this.props.module as keyof typeof CheckboxStateEnum]]
      if(this.state.notifyMe !== oldNotifyMe) {
        const url = this.updateEndpoint[this.props.module.toLowerCase()]
        this.emailSaveSearchApiCallId = this.editApiCall({
          contentType: configJSON.validationApiContentType,
          method: this.updateMethod[this.props.module.toLowerCase()],
          endPoint: url,
        });
        this.setState({
          isLoadUpdate: true,
        })
      } else {
        this.props.handleOpenEditPopup(false);
        this.props.applyEditSaveSearch()
        this.setState({
          inputField: "",
          isEditError: false,
          editErrorMsg: "",
          filterList: {},
        })
      }
    }
    if (responseJson.message) {
      this.setState({
        isEditError: true,
        editErrorMsg: responseJson.message
      })
    }
  }

  emailResponse = (res: any) => {
    if (res.error) {
      this.setState({
        isEditError: true,
        editErrorMsg: res.message,
        isLoadUpdate: false,
      })
    }
    if (res.message) {
      this.setState({
        isLoadUpdate: false,
      }, () =>{
        this.props.handleOpenEditPopup(false);
        this.props.applyEditSaveSearch()
      })
      this.setState({
        editErrorMsg: "",
        inputField: "",
        filterList: {},
        isEditError: false,
      })
    }
  }

  editApiCall = (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    editBody?: {};
  }) => {
    const editToken = (localStorage.getItem("authToken")) || "";
    let { contentType, method, endPoint, editBody } = valueData;
    let editSaveHeader = {
      "Content-Type": contentType,
      token: editToken,
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    editBody &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(editBody)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(editSaveHeader)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End
}

import {
    InputAdornment,
    IconButton,
    TextFieldProps,
    TextField
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { ChangeEvent, Component } from "react";



type Props = TextFieldProps;
type State = {
    enablePasswordField: boolean;
};
export default class ToggleEyeTextField extends Component<Props, State> {


    constructor(props: Props) {
        super(props)
        this.state = {
            enablePasswordField: true
        }
    }

    handleClickShowPassword = () => {
        this.setState({
            enablePasswordField: !this.state.enablePasswordField,
        });
    };

    render() {
        
        return (
            <TextField
                {...this.props}
                type={this.state.enablePasswordField ? "password" : "text"}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                edge="end"
                            >
                            {this.state.enablePasswordField ? (
                                <Visibility />
                            ) : (
                                <VisibilityOff />
                            )}
                            </IconButton>
                        </InputAdornment>
                    )
                    
                }}
            />
        )
    }

}


import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";
import { getProgressCSVDownload, downloadCSVContent, getProgressPDFDownload, downloadPDFContent, importProjectStatus } from "../../../components/src/Utils"
import { DownloadManager as DownloadProgress } from "../../../components/src/PollProgressBar/DownloadManager"
import { getStoredDefaultRole } from "../../../components/src/ContextAPI/AdvancedFilterContext.web";

export const configJSON = require("./config");


export interface ValidResponseType {
  data: object;
  errors: Array<ErrorPayloadType>;
}
export interface ErrorPayloadType {
  key: string;
  message: string;
}

export interface SupplierExportData {
  message: string,
  export_token: string
}

export interface SupplierExportStatusData {
  progress: number,
  status: string,
  file_type: string
}

export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}

export interface SupplierExportStatusData {
  status: string,
  progress: number,
  file_type: string
}

export interface Props {
  id: string;
  searchIds?: number[];
  navigation: any;
  testid: string;
  classes: Record<string, string>;
  count?: number;
}

interface S {
  open: boolean
  loadingJson: boolean;
  token: string;
  selectedFile: File | null;
  hasError: string
  isFileImported: boolean
  reportMetaData: ReportMetaData | null,
  showReport: boolean,
  isLoading: boolean;
  customPaperRef:  React.RefObject<HTMLInputElement>;
  activeImportExportButtonClicked: boolean;
  supplierExportPdfToken: string;
  supplierExportCSVToken: string;
  supplierImportToken: string;
  hasSuccessError: HasSuccessErrorType;
}

interface RecordErrorReport {
  row: number;
  fieldName: string;
  value: null | string;
  error: string
}

interface ReportMetaData {
  skippedMessage: string;
  notImportMessage: string;
  overriddenRows: number;
  totalRecords: number;
  summary: {
    createdRecords: string,
    summaryMessage: string,
    oveririddenRecords: string
  },
  recordErrors: RecordErrorReport[],
  lastProcessedRows: number;
  skippedErrors: RecordErrorReport[],
  importedMessage: string;
  skippedRows: number;
}


interface SS {
  id: any;
}

export default class SupplierImportDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  postImportSupplierCSVId: string = "";
  getExportPDFSupplierCallId : string | null = null;
  getExportCSVSupplierCallId : string | null = null;
  getImportReportSupplierCallId: string | null = null;
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess)
    ];

    this.state = {
      selectedFile: null,
      hasError: '',
      open: true,
      loadingJson: false,
      token: "",
      isLoading: false,
      reportMetaData: null,
      showReport: false,
      activeImportExportButtonClicked: false,
      isFileImported: false,
      customPaperRef: React.createRef(),
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      supplierExportPdfToken:"",
      supplierExportCSVToken:"",
      supplierImportToken: "",
      
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    super.componentDidMount();
    const { status, importToken } = this.props.navigation.history.location?.state || {};

    if (status == 'success') {
      this.setState({
        showReport: true
      }, () => {
        this.requestGetImportLogReport(importToken)
      })
    }
    
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      if (this.postImportSupplierCSVId !== "" && this.postImportSupplierCSVId === apiRequestCallId) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.handleImportDataCSVSuccess(responseJson)
      }

    } 
     
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors) {
        if (this.isValidResponseController(responseJson)) {
          this.apiSuccessCallBacks(apiRequestCallId, responseJson);
        }
      } 
    }
  }


  toggleOpen = (isOpen: boolean) => {
    this.setState({ open: isOpen });
  };

  handleGoBack = () => {
    this.props.navigation.navigate("SuppliersModuleTable")
  };



  handleUploadFile = (fileUpload: File, isSkip: boolean) => {
    this.setState({  isLoading: true })
    const formUploadData = new FormData();
    const method = isSkip ? 'skip' : 'override'

    if (fileUpload instanceof File) {
      formUploadData.append('file', fileUpload)
    } else {
      this.setState({
        hasError: 'File not selected!'
      })
      return false;
    }

    formUploadData.append("activity_type", "supplier");
    formUploadData.append("method", method);
    formUploadData.append("role", getStoredDefaultRole())

    const headeRequest = {
      token:
        typeof window !== 'undefined'
          ? localStorage.getItem('authToken')
          : null
    };

    const apiRequestUpload = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postImportSupplierCSVId = apiRequestUpload.messageId;

    apiRequestUpload.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_data_import_export/export/import_csv?role=${getStoredDefaultRole()}`,
    );

    apiRequestUpload.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headeRequest)
    );

    apiRequestUpload.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    apiRequestUpload.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formUploadData
    );

    runEngine.sendMessage(apiRequestUpload.id, apiRequestUpload);
    return true;
  }

  isValidResponseController = (responseJson: ValidResponseType) => {
    return responseJson && !responseJson.errors;
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: SupplierExportData & SupplierExportStatusData) => {
    if (apiRequestCallId === this.getExportPDFSupplierCallId) {
      this.exportPdfTokenSuccess(responseJson)
    }
    if (apiRequestCallId === this.getExportCSVSupplierCallId) {
      this.exportCSVTokenSuccess(responseJson)
    }

    if (apiRequestCallId == this.getImportReportSupplierCallId) {
      this.importProjectSuccess(responseJson)
    }
  };


  handleImportDataCSVSuccess = async (responseJson: any) => {

    if (!responseJson?.import_token) {
      this.setState({
        hasError: responseJson?.message || responseJson.error || "Unexpected error"
      })
      return;
    }

    const importToken = responseJson.import_token;
    const userToken = (await localStorage.getItem("authToken")) || "";

    const mapProgressDownload = async (exportToken: string, userToken: string) => {
      const progessResponse = await importProjectStatus(exportToken, userToken, "supplier");
  
      if (progessResponse?.progress == 100) {
        this.setState({
          showReport: true
        }, () => {
          this.requestGetImportLogReport(importToken)
        })
      }

      return progessResponse?.progress
  
    };

    this.setState({supplierImportToken: importToken},()=>{
      DownloadProgress.setup(importToken, {
        exportMessage: "Importing supplier data",
        ignoreShownPathname: "/SuppliersImportData",
        trackingToken: importToken,
        onDownloadSucess: (importToken: string) => this.navigateImportSuccessPage(importToken),
        trackingDownloadProgressSync: (token) => mapProgressDownload(importToken, userToken),
        finishTrackingDownloadSync: null,
        outputFileConfig: {
          filename: '',
          contentType: ''
        }
      })

      DownloadProgress.startDownload(importToken)
    })
  }

  apiCall = async (valueData: {
    contentType?: string;
    method?: string
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    let { contentType, method, endPoint, body } = valueData;
    const accessToken = (await localStorage.getItem("authToken")) || "";
    let header = {
      "Content-Type": contentType,
      token: accessToken,
    };

    console.log("call token: ", header)
    let messageAPI = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    messageAPI.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    messageAPI.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    if (body) {
      messageAPI.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }
    messageAPI.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    runEngine.sendMessage(messageAPI.id, messageAPI);
    return messageAPI.messageId;
  };

  requestGetImportLogReport = async (importToken: string) => {
    this.getImportReportSupplierCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.importPeopleCSVEndpoint + `?import_token=${importToken}`,
    });
  }

  handleImportExportButtonClick = (event:any) => {
     this.setState({activeImportExportButtonClicked : !this.state.activeImportExportButtonClicked})
  };

  navigateImportPage = (state: any) => {
    this.props.navigation.navigate("SuppliersImportData");
  };

  navigateImportSuccessPage = (importToken: string) => {
    const projectPathName = "/SuppliersImportData";

    if (location.pathname == projectPathName) {
      // set state instead
      this.setState({
        showReport: true
      }, () => {
        this.requestGetImportLogReport(importToken)
      })
    }  else {
      this.props.navigation.history.push({
        pathname: projectPathName,
        state: { status: 'success', importToken }
      })
    }
    
  }


  handleExportPdfToken = async () =>{
    const searchIds = this.props.searchIds || [];
    const idsQuery = searchIds.map(id => `ids[]=${id}`).join('&');
    const exportQuery = searchIds?.length > 0 ? `?activity_type=supplier&${idsQuery}` : "?activity_type=supplier"
    
    this.getExportPDFSupplierCallId = await this.apiCall({
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.exportPdfTokenEndpoint + exportQuery,
      contentType: configJSON.validationApiContentType,
    });
  }

  exportPdfTokenSuccess = async (responseJson : SupplierExportData) =>{
    const { count } = this.props;
    const exportToken = responseJson.export_token;
    
    if(!!exportToken && count && count > 100){
      this.setState({
        hasError: configJSON.pdfEmailMessage
      })
      return
    }
    const userToken = (await localStorage.getItem("authToken")) || "";

    const mapProgress = async (exportToken: string, userToken: string) => {
      const progessResponse = await getProgressPDFDownload(exportToken, userToken, "supplier");
      
      return progessResponse?.progress
  
    };

    const getDownloadContent = async (exportToken: string, userToken: string) => {
      const downloadResonse = await downloadPDFContent(exportToken, userToken)

      return downloadResonse?.link
    }

    this.setState({supplierExportPdfToken: exportToken},()=>{
      DownloadProgress.setup(exportToken, {
        trackingToken: exportToken,
        exportMessage: "Exporting as pdf",
        ignoreShownPathname: "/SuppliersImportData",
        trackingDownloadProgressSync: (token) => mapProgress(exportToken, userToken),
        finishTrackingDownloadSync: (token) => getDownloadContent(exportToken, userToken),
        outputFileConfig: {
          filename: 'exportproject.pdf',
          contentType: 'application/pdf;charset=utf-8'
        },
      })
      DownloadProgress.startDownload(exportToken);

    })
  }


  handleExportCSVToken = async () =>{
    const searchIds = this.props.searchIds || [];
    const idsQuery = searchIds.map(id => `ids[]=${id}`).join('&');
    const exportQuery = searchIds?.length > 0 ? `?activity_type=supplier&${idsQuery}` : "?activity_type=supplier"

    this.getExportCSVSupplierCallId = await this.apiCall({
      endPoint: configJSON.exportCSVTokenEndpoint  + exportQuery,
      method: configJSON.validationApiMethodType,
      contentType: configJSON.validationApiContentType,
    });
  }

  exportCSVTokenSuccess = async (responseJson : SupplierExportData) =>{
    const exportToken = responseJson.export_token;
    const userToken = (await localStorage.getItem("authToken")) || "";
    const mapProgress = async (exportToken: string, userToken: string) => {
      const progessResponse = await getProgressCSVDownload(exportToken, userToken);
  
      return progessResponse?.progress
  
    };

    const mapLinkDownload = async (exportToken: string, userToken: string) => {
      const downloadResonse = await downloadCSVContent(exportToken, userToken, "supplier")
      return downloadResonse?.link
    }

    this.setState({supplierExportCSVToken: exportToken}, ()=>{
      DownloadProgress.setup(exportToken, {
        exportMessage: "Exporting as csv",
        ignoreShownPathname: "/SuppliersImportData",
        trackingToken: exportToken,
        trackingDownloadProgressSync: (token) => mapProgress(exportToken, userToken),
        finishTrackingDownloadSync: (token) => mapLinkDownload(exportToken, userToken),
        outputFileConfig: {
          filename: '',
          contentType: 'text/csv;charset=utf-8'
        },
      })
      DownloadProgress.startDownload(exportToken);
    })
  }

  importProjectSuccess = (responseJson: any) => {
    try {
      const metaData = responseJson?.data.attributes; 

      this.setState({
        reportMetaData: {
          totalRecords: metaData.total_record,
          importedMessage: metaData.imported_message,
          skippedMessage: metaData.skipped_message,
          notImportMessage: metaData.not_imported_message,
          recordErrors: metaData.record_errors?.map((row: any) => ({
            row: row.row,
            fieldName: row.field_name,
            value: row.value,
            error: row.error
          })),
          lastProcessedRows: metaData.last_processed_rows,
          overriddenRows: metaData.overridden_rows,
          summary: {
            summaryMessage: metaData.summary.summary_message,
            createdRecords: metaData.summary.created_record,
            oveririddenRecords: metaData.summary.overridden_record
          },
          skippedErrors: metaData.skip_errors?.map((row: any) => ({
            row: row.row,
            fieldName: row.value,
            value: row.value,
            error: row.error
          })),
          skippedRows: metaData.skip_errors?.map((row: any) => ({
            row: row.row,
            fieldName: row.field_name,
            value: row.value,
            error: row.error
          })),
        },
      })
    } catch(err) {
      console.log("parse error", err)
    }
    
  }

  resetImport () {
    this.setState({
      showReport: false,
      token: ''
    })
  }

  handleMouseLeave = () => {
    this.setState({
      activeImportExportButtonClicked: false
    })
  }

}

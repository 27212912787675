import React from "react";

// Customizable Area Start
import PartnerDataTableController, {
  Props,
  configJSON,
} from "./PartnerDataTableController.web";
import { TableLayout } from "../../../components/src/WebAppLayout/TableLayout.web";
import {
  Box,
  Button,
  Snackbar,
  FormControlLabel,
  CircularProgress,
  styled,
  Typography,
} from "@material-ui/core";
import {
  notifyCheckedIcon,
  SaveSearchCheckBox,
  projectModuleTableCommonStyle,
} from "./assets";
import { AccessControl } from "../../../components/src/Permission";
import TableData from "../../../components/src/Table/Table.web";
import { CustomeSaveSearchPopper } from "../../../components/src/Popper/CustomeSaveSearchPopper.web";
import NoRecordsFoundPage from "../../../components/src/Table/NoRecordsFoundPage.web";
import ProjectModuleSearch from "../../advancedsearch/src/ProjectModuleSearch.web";
import PartnerExportData from "../../importexportdata/src/PartnerExportData.web";
import { projectModuleCommonStyle } from "../../multipageforms/src/assets";
import { SaveRounded } from "@material-ui/icons";
import { saveUncheckIcon } from "../../filteritems/src/assets";
// Customizable Area End

export default class PartnerDataTable extends PartnerDataTableController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderNotifyMePartner = () => {
    const { isShowSaveSearchPartnerLabel, isPartnerSearchSaved } = this.state;
    return (
      isPartnerSearchSaved &&
      isShowSaveSearchPartnerLabel === undefined && (
        <FormControlLabel
          data-test-id={"notifyMePartner"}
          disabled={true}
          style={{ margin: 0 }}
          control={
            <SaveSearchCheckBox
              checkedIcon={<img src={notifyCheckedIcon} alt="" />}
              data-test-id={"notifyMePartnerCheck"}
              value={true}
              name="notifyMe-partnermodule"
              checked={true}
            />
          }
          label={
            <NotifyMeLabel id="partner-label">
              Notify me for new entries
            </NotifyMeLabel>
          }
        />
      )
    );
  };

  renderPartnerSaveSearchBox = () => {
    const {
      partnerFilterObj,
      isPartnerSearchSaved,
      isShowSaveSearchPartnerLabel,
      isPartnerSavingSearch,
      savingSearchPartnerName,
      onSaveSearchErrorPartnerMessage,
      onSaveSearchErrorPartner,
    } = this.state;
    const isAvailablePartnerSaveSearch = Object.values(partnerFilterObj).some(
      (array: any) => array.length > 0
    );
    return (
      <TableHeaderLeftContainer>
        <span
          data-test-id="searchResultsPartnerText"
          className="partnerSearchHeading"
        >
          {isShowSaveSearchPartnerLabel === true
            ? "Saved Results"
            : "Search Results"}
        </span>
        {isAvailablePartnerSaveSearch &&
          isShowSaveSearchPartnerLabel === undefined && (
            <FormControlLabel
              style={{ margin: 0 }}
              data-test-id="SaveSearchPartnerLable"
              control={
                <SaveSearchCheckBox
                  icon={<img src={saveUncheckIcon} alt="" />}
                  data-test-id="SaveSearchPartnerrController"
                  name="savedSearch-partnermodule"
                  checkedIcon={<SaveRounded />}
                  value={isPartnerSearchSaved}
                  checked={isPartnerSearchSaved}
                  onChange={this.handleCheckedPartnerSaveSearch}
                />
              }
              disabled={isPartnerSearchSaved}
              label={
                <span
                  style={{
                    color: "#2B3132",
                    fontStyle: "normal",
                    fontSize: "14px",
                    fontFamily: "Source Sans Pro",
                    fontWeight: 600,
                    lineHeight: "16px",
                  }}
                >
                  Save search
                </span>
              }
            />
          )}
        {this.renderNotifyMePartner()}
        <CustomeSaveSearchPopper
          data-test-id="partnerSaveSearchPopup"
          open={isPartnerSavingSearch}
          value={savingSearchPartnerName}
          onChange={this.onChangePartnerSavedSeachName}
          cancelSaveSearch={this.cancelSavingPartnerSearch}
          submitSaveSearch={this.savePartnerSaveSearchRequest}
          error={onSaveSearchErrorPartner}
          errorMsg={onSaveSearchErrorPartnerMessage}
        />
      </TableHeaderLeftContainer>
    );
  };

  renderPartnerChild = () => {
    const {
      isPartnerListLoading,
      partnerFilterObj,
      tablePartnerInputs,
      partnerPaginationDetails,
      hasPartnerError,
    } = this.state;
    return (
      <Box
        data-test-id={configJSON.tenderAddTestId}
        sx={projectModuleTableCommonStyle.mainWrapper}
      >
        <div>
          <ProjectModuleSearch
            id={"AdvancedSearch"}
            data-test-id="advancedSearch"
            navigation={this.props.navigation}
            getProjectsResponse={this.handlePartnerDataResponse}
            getProjectsRequest={this.handlePartnerData}
            filterList={partnerFilterObj}
            type={"Partner"}
          />
        </div>
        {isPartnerListLoading ? (
          <Box
            style={projectModuleCommonStyle.loadingSpinner}
            data-test-id="loader"
          >
            <CircularProgress color="inherit" size={"5rem"} />
          </Box>
        ) : (
          <PartnerTable>
            <div
              style={{
                display: isPartnerListLoading ? "none" : "flex",
                justifyContent: "space-between",
              }}
              className="PartnerTableHeader"
            >
              <div className={"PartnerSearchBoxActive"}>
                {this.renderPartnerSaveSearchBox()}
              </div>
              <div className="PartnerBtnBox">
                <AccessControl module="Partner" feature="create">
                  <PartnerAddBtn
                    data-test-id="partnerAddTestId"
                    onClick={this.handlePartnerAddView}
                  >
                    + Add
                  </PartnerAddBtn>
                </AccessControl>
                <div>
                  <PartnerExportData
                    innerRef={this.refExportBox}
                    navigation={this.props.navigation}
                    id={""}
                    testid={"partnerexport"}
                    classes={{}}
                    searchIds={this.state.searchIds}
                    count={this.state.partnerPaginationDetails.totalLength}
                  />
                </div>
              </div>
            </div>
            {tablePartnerInputs.apiData.length > 0 ? (
              <TableData
                navigation={this.props.navigation}
                data-test-id="containerTableTestId"
                tableLink="Partner"
                pagination={partnerPaginationDetails}
                handlePageChange={this.handlePartnerListChangePage}
                handleChangeRowsPerPage={
                  this.handlePartnerListChangeRowsPerPage
                }
                onFavIconClick={(index: number) =>
                  this.handleFavPartnerIconClicked(index)
                }
                handleSort={(column, sortOrder) =>
                  this.handlePartnerSort(column, sortOrder)
                }
                tableInputs={tablePartnerInputs}
                id={""}
              />
            ) : (
              <NoRecordsFoundPage
                data-test-id="reloadBtn"
                activeModule="Partners"
                loadData={this.handleLoadPartnerBtn}
                text="No record found"
              />
            )}
            {hasPartnerError && typeof hasPartnerError === "string" && (
              <Snackbar
                open={!!hasPartnerError}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                style={{ zIndex: 100000 }}
                onClose={() => {
                  this.setState({ hasPartnerError: "" });
                }}
                key="error-message"
                message={hasPartnerError}
              />
            )}
          </PartnerTable>
        )}
      </Box>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <TableLayout
        data-test-id="partner-tableLayout"
        module="Partners"
        open={this.state.isOpenPartnerFilter}
        navigation={this.props.navigation}
        isUpdateSaveSearch={this.state.isPartnerSearchSaved}
        handleFilterList={this.handlepartnerFilterObj}
        isResetFilters={this.state.isResetPartnerFilters}
        toggleOpen={this.togglePartnerFilterOpen}
        preFilter={this.state.partnerFilterObj}
      >
        {this.renderPartnerChild()}
      </TableLayout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const NotifyMeLabel = styled(Typography)({
  color: "#2B3132",
  fontFamily: "Source Sans Pro",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "16px",
  fontStyle: "normal",
});

const PartnerTable = styled(Box)({
  backgroundColor: "#FFF",
  position: "relative",
  "& .PartnerTableHeader": {
    justifyContent: "space-between",
    padding: "0 15px",
    alignItems: "center",
    height: "60px",
    "@media (max-width: 573px)": {
      flexWrap: "wrap",
      height: "auto",
    },
    backgroundColor: "#FFF",
    display: "flex",
    "@media (max-width: 767px)": {
      padding: "0 5px",
    },
    "& .PartnerSearchBoxActive": {
      display: "flex",
      alignItems: "center",
    },
    "@media (max-width: 960px)": {
      padding: "0 10px",
    },
    "& .PartnerBtnBox": {
      "@media (max-width: 573px)": {
        margin: "5px 0px 5px auto",
      },
      gap: "16px",
      "@media (max-width: 960px)": {
        gap: "7px",
      },
      display: "flex",
    },
  },
});

const PartnerAddBtn = styled(Button)({
  width: "100px",
  height: 45,
  textTransform: "none",
  borderRadius: "8px",
  border: "1.5px solid #01949A",
  color: "#01949A",
  "@media (max-width: 960px)": {
    fontSize: 12,
    maxWidth: 50,
    height: 38,
    minWidth: 50,
  },
});

const TableHeaderLeftContainer = styled(Box)({
  "@media (max-width: 573px)": {
    justifyContent: "space-between",
  },
  alignItems: "center",
  "@media (max-width: 513px)": {
    gap: "13px",
  },
  display: "flex",
  gap: "20px",
  "@media (max-width: 482px)": {
    gap: "5px",
    marginRight: "0px",
  },
  height: "42px",
  "@media (max-width: 453px)": {
    gap: "0px",
  },
  marginRight: "14px",
  "& .partnerSearchHeading": {
    fontStyle: "normal",
    fontSize: "20px",
    maxWidth: "fit-content",
    fontFamily: "Source Sans Pro",
    lineHeight: "16px",
    width: "100%",
    "@media (max-width: 960px)": {
      fontSize: "17px",
    },
    fontWeight: 600,
    "@media (max-width: 767px)": {
      fontSize: "14px",
    },
    color: "#000",
  },
});

// Customizable Area End

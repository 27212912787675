import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import ExcelJS from "exceljs";
import { getStoredDefaultRole } from "../../../components/src/ContextAPI/AdvancedFilterContext.web";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface ValidResponseType {
  data: object;
  errors: Array<ErrorPayloadType>;
}
export interface ErrorPayloadType {
  key: string;
  message: string;
}
export interface ErrorResponseType {
  errors: string;
}
export interface InvalidResponseType {
  errors: string
  message : string;
}

export interface PeopleExportData {
  message: string,
  export_token: string
}

export interface PeopleExportStatusData {
  status: string,
  progress: number,
  file_type: string
}

export interface PeopleExport {
 link : string
}

export interface PeopleImportData {
  message: string,
  import_token: string,
  error?: string
}

export interface PeopleImportStatusData {
  status: string,
  progress: number,
  file_type: string
}

export interface PeopleImport {
 data : ImportCSVData
}

export interface ImportCSVData {
  id: string,
  type: string,
  attributes: {
      total_record: number,
      last_processed_rows: number,
      skipped_rows: number,
      overridden_rows: number,
      record_errors: Array<PeopleImportLogs>,
      skip_errors: Array<PeopleSkipLogs>,
      created_at: string,
      updated_at: string,
      summary: {
          summary_message: string,
          created_record: string,
          overridden_record: string
      },
      imported_message: string,
      skipped_message: string,
      not_imported_message: string
  }
}

export interface PeopleSkipLogs {
  row?: number,
  value?: string,
  error?: string
}

export interface PeopleImportLogs {
  row?: number,
  field_name?: string,
  value?: string,
  error?: string
}

export interface HasSuccessErrorType {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}

export interface HasProgressDataType {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isStatus: string,
  fileType: string,
  progressValue: number
}

export interface ReportMetaData {
  totalRecords: number;
  lastProcessedRows: number;
  skippedRows: number;
  overriddenRows: number
}

export interface PeopleSummary {
    summaryMessage: string,
    createdRecord: string,
    overriddenRecord: string
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  testid: string;
  classes: Record<string, string>;
  searchIds: Array<number>;
  count?: number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loadingCSV: boolean;
  loadingJson: boolean;
  token: string;
  selectedFile: File | null ;
  hasError: string
  isSkipped: boolean
  isFileImported: boolean
  isLoading: boolean
  hasImportExportButtonClicked: boolean;
  peopleExportPdfToken: string;
  peopleExportCSVToken: string;
  peopleExportPdfProgress : number;
  peopleExportCSVProgress : number;
  peopleImportCSVProgress : number;
  intervalPdf : number |  NodeJS.Timeout;
  intervalCSV : number |  NodeJS.Timeout;
  importPeopleCSV : number | NodeJS.Timeout;
  tab: string;
  exportLink: string;
  peopleImportCSVToken: string;
  peopleRecordLogs: Array<object>;
  peopleSkipLogs: Array<object>;
  peopleSummary : PeopleSummary;
  peopleImportMessage: string;
  peopleSkipMessage: string;
  notImportMessage: string;
  showImportReport: boolean;
  showValidImportFile: boolean;
  searchIds: Array<number>;
  hasSuccessError: HasSuccessErrorType;
  hasProgressData: HasProgressDataType;
  isImportMethod: string;
  reportMetaData: ReportMetaData,
  customPaperRef:  React.RefObject<HTMLInputElement>;
  hasExportPdf: boolean;
  hasExportCSV: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ImportExportDataController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  jsonDataApiCallId: string = "";
  importDataCSVCallId: string = "";
  exportSampleDataCallId: string = "";
  getExportPdfTokenApiCallId : string | null = null;
  getExportCSVTokenApiCallId : string | null = null;
  getPeopleExportPdfStatusApiCallId : string | null = null;
  getPeopleExportCSVStatusApiCallId : string | null = null;
  getPeopleExportApiCallId : string | null = null;
  postPeopleImportDataApiCallId : string | null = null;
  getPeopleImportCSVStatusApiCallId : string | null = null;
  getPeopleImportApiCallId : string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loadingCSV: false,
      loadingJson: false,
      token: "",
      selectedFile: null,
      hasError: '',
      isSkipped: true,
      isFileImported: false,
      isLoading: false,
      hasImportExportButtonClicked: false,
      peopleExportPdfToken:"",
      peopleExportCSVToken:"",
      peopleImportCSVToken:"",
      peopleExportPdfProgress : 0,
      peopleExportCSVProgress : 0,
      peopleImportCSVProgress : 0,
      customPaperRef: React.createRef(),
      intervalPdf : 0,
      intervalCSV : 0,
      importPeopleCSV : 0,
      peopleRecordLogs : [],
      peopleSkipLogs: [],
      peopleSummary : {
        summaryMessage: "",
        createdRecord: "",
        overriddenRecord: ""
      },
      peopleImportMessage: "",
      peopleSkipMessage: "",
      notImportMessage: "",
      showImportReport: false,
      showValidImportFile: false,
      hasExportPdf: false,
      hasExportCSV: false,
      searchIds: [],
      tab:"",
      exportLink:"",
      isImportMethod: "skip",
      reportMetaData: {
        totalRecords: 0,
        lastProcessedRows: 0,
        skippedRows: 0,
        overriddenRows: 0
      },
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      hasProgressData: {
        isOpen: false,
        isSeverity: "success",
        isStatus: "",
        fileType: "",
        progressValue: 0
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.isValidResponseController(responseJson)) {
        this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
      }

      if (this.isInValidResponseController(responseJson)) {
        this.apiFailureCallBacksController(apiRequestCallId, responseJson);

      }
    }

    // Customizable Area End
  }

   // Customizable Area Start
   isValidResponseController = (responseJson: ValidResponseType) => {
    return responseJson && !responseJson.errors;
  };

  isInValidResponseController = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: PeopleExportData & PeopleExportStatusData & PeopleExport & PeopleImportData & PeopleImportStatusData & PeopleImport) => {
    if (apiRequestCallId === this.getExportPdfTokenApiCallId) {
      this.exportPdfTokenSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getExportCSVTokenApiCallId) {
      this.exportCSVTokenSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getPeopleExportPdfStatusApiCallId) {
      this.peopleExportPdfStatusSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getPeopleExportCSVStatusApiCallId) {
      this.peopleExportCSVStatusSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getPeopleExportApiCallId) {
      this.peopleExportSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.postPeopleImportDataApiCallId) {
      this.peopleImportCSVTokenSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getPeopleImportCSVStatusApiCallId) {
      this.peopleImportCSVStatusSuccessCallBack(responseJson)
    }
    if (apiRequestCallId === this.getPeopleImportApiCallId) {
      this.peopleImportSuccessCallBack(responseJson)
    }
  };

  apiFailureCallBacksController = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.getExportPdfTokenApiCallId) {
      this.exportPdfTokenFailurCallBack(responseJson)
    }
    if (apiRequestCallId === this.getExportCSVTokenApiCallId) {
      this.exportCSVTokenFailurCallBack(responseJson)
    }
    if (apiRequestCallId === this.getPeopleExportPdfStatusApiCallId) {
      this.PeopleExportPdfStatusFailurCallBack(responseJson)
    }
    if (apiRequestCallId === this.getPeopleExportCSVStatusApiCallId) {
      this.PeopleExportCSVStatusFailurCallBack(responseJson)
    }
    if (apiRequestCallId === this.getPeopleExportApiCallId) {
      this.peopleExportFailurCallBack(responseJson)
    }
    if (apiRequestCallId === this.postPeopleImportDataApiCallId) {
      this.PeopleImportCSVTokenFailurCallBack(responseJson)
    }
    if (apiRequestCallId === this.getPeopleImportCSVStatusApiCallId) {
      this.PeopleImportCSVStatusFailurCallBack(responseJson)
    }
    if (apiRequestCallId === this.getPeopleImportApiCallId) {
      this.peopleImportFailurCallBack(responseJson)
    }
  };
  // Customizable Area End
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.searchIds !== prevProps.searchIds) {
      this.setState({ searchIds: this.props.searchIds },
      );
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  isJSON = (text: any) => {
    try {
      var json = JSON.parse(text);
      return typeof json === "object";
    } catch (error) {
      return false;
    }
  };

  exportFile = (data: any, type: string) => {
    const blob = new Blob([data], {
      type: type === ".json" ? "application/json" : "text/csv"
    });
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = `${Date.now()}${type}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    this.setState({ loadingCSV: false, loadingJson: false });
  };

  downloadCSVData = async () => {
    // This function uses fetch rather than RunEngine for the network request by design
    // It is done because the RestApiClientBlock does not handle CSV responses

    this.setState({ loadingCSV: true });
    try {
      const response = await fetch(
        `${configJSON.baseURL}/${configJSON.exportDataEndpoint}`,
        {
          headers: {
            "Content-Type": configJSON.csvApiContentType,
            token: this.state.token
          }
        }
      );
      const res = await response.text();
      let result = res && this.isJSON(res) ? JSON.parse(res) : null;
      if (result?.errors) {
        this.showAlert('Error downloading CSV', configJSON.errorMsg);
        this.setState({ loadingCSV: false });
      } else {
        this.exportFile(res, ".csv");
      }
    } catch (e) {
      this.setState({ loadingCSV: false });
    }
  };

  downloadJsonData = () => {
    this.setState({ loadingJson: true });
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: this.state.token
    };

    const getJsonMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.jsonDataApiCallId = getJsonMessage.messageId;

    getJsonMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.exportDataEndpoint
    );

    getJsonMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getJsonMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exportDataMethod
    );

    runEngine.sendMessage(getJsonMessage.id, getJsonMessage);
    return true;
  };

  handleImportExportMenuClose = ()=>{
    this.setState({
      hasImportExportButtonClicked: false
    })
  }

  handleImportExportButtonClick = () => {
    this.setState({ hasImportExportButtonClicked: !this.state.hasImportExportButtonClicked })
  }

  handleImportData = () => {
    this.setState({
      isLoading: true
    })
    const { selectedFile } = this.state
    const formData = new FormData();

    if (selectedFile instanceof File) {
      formData.append('file', selectedFile)
    } else {
      this.setState({
        hasError: 'File not selected!'
      })
      return false;
    }

    const header = {
      token:
        typeof window !== 'undefined'
          ? localStorage.getItem('authToken')
          : null
    };

    const apiRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.importDataCSVCallId = apiRequest.messageId;

    apiRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_people/people/import_csv'
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    apiRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(apiRequest.id, apiRequest);
    return true;
  }

  handleImportDataCSVResponse = (responseJson: any) => {
    const { errors, message, error } = responseJson
    if (errors) {
      this.setState({
        hasError: errors,
      })
    }

    if (error) {
      this.setState({
        hasError: error,
      })
    }

    if (message) {
      this.setState({
        isFileImported: true,
      })
    }
    this.setState({
      isLoading: false
    })
  }

  handleError = (error: string) => {
    this.setState({
      hasError: error,
    });
  }

  isValidFiles = (file: File) => {
    return file.size <  5 * 1024 * 1024 && (file.name.includes(".xlsx") || file.name.includes(".csv"))
  }

  handleFileChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (this.isValidFiles(file)) {
        this.setState({
          selectedFile: file,
          showValidImportFile: false
        });
      } else {
        this.setState({showValidImportFile: true})
        event.target.value = "";
      }
    }
  }

  truncateText = (text: string | null, maxLength: number) => {
    if (text && text.length > maxLength) {
      return `${text.slice(0, maxLength) + '...'}`;
    }
    return text;
  }

  removeFile = () => {
    this.setState({
      selectedFile: null,
    });
  };

  exportSampleCSVData = () => {
    const csvData = configJSON.exportSamplePeopleCSVData;
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const filename = `sample_people_csv.csv`;

    this.downloadSampleFile(url, filename);
  };

  exportSampleXLSData = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    const xlsxData = configJSON.sampleXLSXPeopleData;
    worksheet.addRows(xlsxData);
    worksheet.getRow(1).font = { bold: true };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    this.downloadSampleFile(url, "sample_people_xlsx.xlsx");
  };

  downloadSampleFile = (url: string, filename: string) => {
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  apiCallImportExport = async (valueData: {
    method?: string;
    contentType?: string;
    body?: {};
    endPoint?: string;
    type?: string;
    apiType?: string;
   }) => {
   let { contentType, method, endPoint, body } = valueData;
   const token = (await localStorage.getItem("authToken")) || "";
   let header = {
     "Content-Type": contentType,
      token,
    };
   let requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
   );
   requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
   );
   requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
   );
   requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
    );
   body &&
     requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
   runEngine.sendMessage(requestMessage.id, requestMessage);
   return requestMessage.messageId;
 };

  handleCloseSnack = () => {
    this.setState({
      hasSuccessError: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      }
    })
  }

  handleCloseProgressSnack = () => {
    this.setState({
      hasProgressData: {
        isOpen: false,
        isSeverity: "success",
        isStatus: "",
        fileType:"",
        progressValue: 0
      }
    })
  }

  navigateImportPage = () => {
    this.props.navigation.navigate("ImportData");
    this.setState({tab : "importcsv"})
  };

  handleExportPdfToken = async () =>{
    const idsArray = this.state.searchIds;
    const idsQueryString = idsArray.map(id => `ids[]=${id}`).join('&');
    this.getExportPdfTokenApiCallId = await this.apiCallImportExport({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.exportPdfTokenEndpoint}?activity_type=people&${idsQueryString}`,
    });
  }

  exportPdfTokenSuccessCallBack = (responseJson : PeopleExportData) =>{
    const { count } = this.props;
    if(!!responseJson.export_token && count && count > 100){
      this.setState({
        hasSuccessError: {
          isOpen: true,
          isSeverity: "success",
          isMessage: configJSON.pdfEmailMessage
        }
      })
      return
    }
    this.setState({peopleExportPdfToken: responseJson.export_token , tab: "exportpdf", hasExportPdf: true})
    if(responseJson.export_token.length > 0){
      const intervalPdf = setInterval(() => this.handlePeopleExportPdfStatus(), 1000);
      this.setState({ intervalPdf: intervalPdf });
    }
  }

  exportPdfTokenFailurCallBack = (responseJson: InvalidResponseType) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: responseJson.message
      }
    })
  }

  handleExportCSVToken = async () =>{
    const idsArray = this.state.searchIds;
    const idsQueryString = idsArray.map(id => `ids[]=${id}`).join('&');
    this.getExportCSVTokenApiCallId = await this.apiCallImportExport({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.exportCSVTokenEndpoint}?activity_type=people&${idsQueryString}`,
    });
  }

  exportCSVTokenSuccessCallBack = (responseJson : PeopleExportData) =>{
    this.setState({peopleExportCSVToken: responseJson.export_token, tab: "exportcsv", hasExportCSV: true})
    if(responseJson.export_token.length > 0){
      const intervalCSV = setInterval(() => this.handlePeopleExportCSVStatus(), 1000);
      this.setState({ intervalCSV: intervalCSV });
    }
  }

  exportCSVTokenFailurCallBack = (responseJson: InvalidResponseType) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: responseJson.message 
      }
    })
  }

  handlePeopleExportPdfStatus = async () =>{
    this.getPeopleExportPdfStatusApiCallId = await this.apiCallImportExport({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.exportPeopleStatusPdfEndpoint}?export_token=${this.state.peopleExportPdfToken}`,
    });
  }
  
  peopleExportPdfStatusSuccessCallBack = (responseJson : PeopleExportStatusData) =>{
    this.setState({peopleExportPdfProgress: responseJson.progress})
    this.setState({
      hasProgressData : {
        isOpen: true,
        isSeverity: "success",
        isStatus: responseJson.status,
        fileType: responseJson.file_type,
        progressValue: responseJson.progress
      }
    })
      if(responseJson.status === "completed"){
        clearInterval(this.state.intervalPdf as NodeJS.Timeout);
        this.handlePeopleExport()
      }
  }

  PeopleExportPdfStatusFailurCallBack = (responseJson: InvalidResponseType) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: responseJson.message
      }
    })
  }

  handlePeopleExportCSVStatus = async () =>{
    this.getPeopleExportCSVStatusApiCallId = await this.apiCallImportExport({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.exportPeopleStatusCSVEndpoint}?export_token=${this.state.peopleExportCSVToken}`,
    });
  }
  
  peopleExportCSVStatusSuccessCallBack = (responseJson : PeopleExportStatusData) =>{
    this.setState({peopleExportCSVProgress: responseJson.progress})
    this.setState({
      hasProgressData : {
        isOpen: true,
        isSeverity: "success",
        isStatus: responseJson.status,
        fileType: responseJson.file_type,
        progressValue: responseJson.progress
      }
    })
    if(responseJson.status === "completed"){
      clearInterval(this.state.intervalCSV as NodeJS.Timeout);
      this.handlePeopleExport()
    }
  }

  PeopleExportCSVStatusFailurCallBack = (responseJson: InvalidResponseType) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: responseJson.message
      }
    })
  }

  handlePeopleExport = async () => {
    this.getPeopleExportApiCallId = await this.apiCallImportExport({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${this.state.hasProgressData.fileType === "CSV" ? configJSON.exportPeopleCSVEndpoint : configJSON.exportPeoplePdfEndpoint}?export_token=${this.state.hasProgressData.fileType === "CSV" ? this.state.peopleExportCSVToken : this.state.peopleExportPdfToken}`
    });
  }

  peopleExportSuccessCallBack = (responseJson : PeopleExport) =>{
    const { link } = responseJson;
    this.setState({ exportLink : link , hasExportPdf: false , hasExportCSV: false },()=>{
        this.downloadFile(link, this.state.hasProgressData.fileType)
    })
  }

  peopleExportFailurCallBack = (responseJson: InvalidResponseType) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: responseJson.message
      }
    })
  }

  downloadFile = (fileUrl: string, fileType:string) => {
    const link = fileUrl;
    const a = document.createElement('a');
    a.style.display = 'none';
    
    let fileName = '';
  
    if (fileType === 'PDF') {
      fileName = 'PeoplePDF.pdf';
    } else if (fileType === 'CSV') {
      fileName = 'PeopleCSV.csv';
    } else {
      console.error('Unsupported file type');
      return;
    }
  
    fetch(link)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        a.href = url;
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => console.error('Error downloading file:', error));
  };

  handlePeopleImportSkip = () => {
    this.setState({ isImportMethod: "skip" })
  }

  handlePeopleImportOverride = () => {
    this.setState({ isImportMethod: "override" })
  }

  handleImportBack = () => {
    this.setState({
      showImportReport: false,
      peopleImportCSVToken: "",
      selectedFile: null,
      hasProgressData: {
        isOpen: false,
        isSeverity: "success",
        isStatus: "",
        fileType: "",
        progressValue: 0
      }
    })
  }
  
  handlePeopleImportData = async () => {
    const fileContent = this.state.selectedFile;
    if (fileContent) {
      let formdata = new FormData();
      formdata.append("file", fileContent);
      formdata.append("method", this.state.isImportMethod);

      this.postPeopleImportDataApiCallId = await this.apiCallImportExport({
        method: configJSON.exampleAPiMethod,
        endPoint: `${configJSON.importPeopleTokenCSVEndpoint}?activity_type=people&role=${getStoredDefaultRole()}`,
        body: formdata,
      });
    }
  }

  peopleImportCSVTokenSuccessCallBack = (responseJson: PeopleImportData) => {
    if (!responseJson?.import_token) {
      this.setState({
        hasError: responseJson.error || responseJson?.message || "Unexpected error"
      })
      return;
    }
    
    this.setState({ peopleImportCSVToken: responseJson.import_token })
    if(responseJson.import_token.length > 0) {
      const importPeopleCSV = setInterval(() => this.handlePeopleImportCSVStatus(), 1000);
      this.setState({ importPeopleCSV: importPeopleCSV });
    }
  }

  PeopleImportCSVTokenFailurCallBack = (responseJson: InvalidResponseType) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: responseJson.message
      }
    })
  }

  handlePeopleImportCSVStatus = async () =>{
    this.getPeopleImportCSVStatusApiCallId = await this.apiCallImportExport({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.importPeopleStatusCSVEndpoint}?import_token=${this.state.peopleImportCSVToken}`,
    });
  }
  
  peopleImportCSVStatusSuccessCallBack = (responseJson : PeopleImportStatusData) =>{
    this.setState({peopleImportCSVProgress: responseJson.progress})
    this.setState({
      hasProgressData : {
        isOpen: true,
        isSeverity: "success",
        isStatus: responseJson.status,
        fileType: responseJson.file_type,
        progressValue: responseJson.progress
      }
    })
    if(responseJson.status === "completed"){
      clearInterval(this.state.importPeopleCSV as NodeJS.Timeout);
      this.handlePeopleImport();
      this.setState({showImportReport : true})
    }
  }

  PeopleImportCSVStatusFailurCallBack = (responseJson: InvalidResponseType) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: responseJson.message
      }
    })
  }

  handlePeopleImport = async () => {
    this.getPeopleImportApiCallId = await this.apiCallImportExport({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.importPeopleCSVEndpoint}?import_token=${this.state.peopleImportCSVToken}`
    });
  }

  peopleImportSuccessCallBack = (responseJson : PeopleImport) =>{
          try {
         this.peopleImportResponseData(responseJson)
          } catch(err) {
          console.log("parse error", err)
         }
      }

  peopleImportResponseData = (responseJson : PeopleImport) =>{
      const metaPeopleData = responseJson?.data.attributes;

      this.setState({
        reportMetaData: {
          totalRecords: metaPeopleData.total_record,
          lastProcessedRows: metaPeopleData.last_processed_rows,
          skippedRows: metaPeopleData.skipped_rows,
          overriddenRows: metaPeopleData.overridden_rows
          },
        peopleSummary : {
          summaryMessage: metaPeopleData.summary.summary_message,
          createdRecord: metaPeopleData.summary.created_record,
          overriddenRecord: metaPeopleData.summary.overridden_record
        },
          peopleRecordLogs: metaPeopleData.record_errors,
          peopleSkipLogs: metaPeopleData.skip_errors,
          peopleImportMessage: metaPeopleData.imported_message,
          peopleSkipMessage: metaPeopleData.skipped_message,
          notImportMessage: metaPeopleData.not_imported_message
         })
    }  

  peopleImportFailurCallBack = (responseJson: InvalidResponseType) => {
    this.setState({
      hasSuccessError: {
        isOpen: true,
        isSeverity: "error",
        isMessage: responseJson.message
      }
    })
  }

  handleMouseLeave = () => {
    this.setState({
      hasImportExportButtonClicked: false
    })
  }

  handleGoBack = () => {
    this.props.navigation.navigate("PeopleData")
  };
  // Customizable Area End
}

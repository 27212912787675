export type PropertyKey<T extends keyof S> = keyof S[T];

// Customizable Area Start
import React from 'react';
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
	getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';
import { countSection,  convertResIntoLabelValue, getUnmatchedIds, logoutUser, validateAndUpdateErrors, validateAll } from "../../../components/src/Utils";
import Documentation from './Components/PeopleModule/Documentation.web';
import { WithAuthenticatedProps } from '../../../components/src/Permission/withAccess';
import { getStoredDefaultRole } from '../../../components/src/ContextAPI/AdvancedFilterContext.web';
import { peopleIdentificationValidationSchema, peoplePersonalValidationSchema, peopleWorkValidationSchema } from '../../../components/src/validationSchema';
// Customizable Area End

export const configJSON = require('./config');

export interface Props extends WithAuthenticatedProps {
	navigation: any;
	id: string;
	// Customizable Area Start

	// Customizable Area End
}

export interface ErrorObject {
	[key: string]: string;
}

type ModuleMode = 'update' | 'read' | 'post';

type GetResArr = { label: string, value: string}

export interface IPersonalData {
	mode: ModuleMode | string;
	first_name: string;
	last_name: string;
	date_of_birth: string;
	nationality: string;
	email: string;
	country_code: string;
	phone_number: string;
	isCancelButtonEnabled: boolean;
	errors: ErrorObject[];
}
export interface IIdentification {
	mode: ModuleMode | string;
	visa_designation: string;
	joining_date: string;
	end_date: string | Date;
	diploma: string;
	year: string | number;
	yearOptions: { label: string; value: string }[];
	passport_number: string;
	passport_expiry_date: string | Date;
	local_id: string;
	local_expiry_date: string | Date;
	driving_license: string;
	diplomaList: GetResArr[] | [];
	isCancelButtonEnabled: boolean;
	errors: ErrorObject[];
}
export interface IWork {
	mode: ModuleMode | string;
	education_degree: string;
	education_stream: string;
	disciplineOptions: { label: string; value: string }[];
	primary_experience: string;
	primary_year: string;
	secondary_experience: string;
	secondary_year: string;
	experienceOptions: { label: string; value: string }[];
	region_of_experience: string;
	otherLangList: GetResArr[] | [];
	design: 'Yes' | 'No' | undefined;
	supervision: 'Yes' | 'No' | undefined;
	current_position: string;
	currentPositionList: { label: string; value: string }[];
	currentOtherPosition: string;
	currentOtherPositionOptions: { label: string; value: string }[];
	groupRelationRadio: 'related' | 'unrelated' | string | null;
	openGroupRelation: boolean;
	groupRelationMenus: any;
	employeeStatus: string | null;
	statusOption: string | null;
	internal_rate: { label: string; value: string } | string;
	regionList: GetResArr[] | [],
	internalRateList: GetResArr[] | [],
	expYearsList: GetResArr[] | [],
	levelList: GetResArr[] | [],
	languages: {
		name: string;
		level: 'Beginner' | 'Advance' | string | undefined;
	}[];
	isCancelButtonEnabled: boolean;
	errors: ErrorObject[];
}
export interface Documents {
	mode: ModuleMode | string;
    resume: Document | File | null;
	loading: boolean;
	documents: any[];
	addDocument: boolean;
	otherDocumentUpload: File | string | null;
	otherDocumentFileName: string | null;
	isCancelButtonEnabled: boolean;
	errors: ErrorObject[];
}
export type Document = {
	document_id: number;
	document_name: string;
	document_link: string;
	image_link: string;
	uploaded_by: string;
	upload_date: string;
	file?: File;
	attached_file: string;
};
export interface IConnections {
	mode: ModuleMode;
}

export interface IAuditTrail {
	mode: ModuleMode;
}

export interface IAuditTrailSteps {
	label: string;
	description: string;
	date: string;
	time: string;
}
export interface S {
	// Customizable Area Start
	hasError: any;
	loggedInUserFullName: string | null;
	profile_photo: string | null; // Assuming profile_photo is a URL
	activeSection: string;
	PeopleId: string;
	IdentificationId: string;
	WorkId: string;
	yeardata: any;
	personalData: IPersonalData;
	staticPersonalData: IPersonalData;
	staticIdentification: IIdentification;
	identification: IIdentification;
	work: IWork;
	langDeleteId: number[];
	deleteError: boolean;
	staticWork: IWork;
	documents: Documents;
	connections: IConnections;
	auditTrail: IAuditTrail;
	activeStep: number;
	isLoading: boolean;
	isAuditTrail: boolean;
	editIndex: any,
	editedName: string;
	documentId: string;
	buttonType: string;
	nationalityArray: any;
	documentEdit: boolean;
	currentPositionData: string;
	// Customizable Area End
}

interface SS {
	// Customizable Area Start
	id: any;
	// Customizable Area End
}

export default class PeopleModuleController extends BlockComponent<
	Props,
	S,
	SS
> {
	// Customizable Area Start
	apiForNationalities: string = '';
	apiForPersonalDataPost = '';
	apiForPersonalDataUpdate = '';
	apiForWorkPost = '';
	apiForWorkUpdate = '';
	apiForIdentificationPost: string = '';
	apiForIdentificationUpdate: string = '';
	apiForYearOptions: string = '';
	apiForPeopleshow: string = '';
	apiForIdentificationGet: string = '';
	apiForWorkDataGet: string = '';
	apiForOtherCurrentPositions: string = '';
	apiForCurrentPositionList: string = '';
	apiForDisciplineOptions: string = '';
	apiForExperienceOptions: string = '';
	apiForDocumentUpload: string = '';
	apiForDocumentEditName: string = '';
	apiForDocumentUpdate: string = '';
	apiForOtherDocumentUpload: string = '';
	apiForDocumentDelete: string = '';
	apiForDiplomaOptions: string = '';
	apiForCountryList: string = '';
	apiForHighestLevel: string = '';
	apiForExpYearOptions: string = '';
	apiForInternalRate: string = '';
	apiForOtherLanguages: string = '';
	apiForDeleteLanguages: string = '';
	apiForDeleteGroupRelation: string = '';
    sections: string[];
	documentRef = React.createRef<Documentation>();
	countableSection: any = {};

	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);
		// Customizable Area Start
		this.subScribedMessages = [
			getName(MessageEnum.CountryCodeMessage),
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.ReciveUserCredentials),
		];
		this.handleSelectLang = this.handleSelectLang.bind(this);

		this.state = {
			isLoading: true,
			hasError: '',
			loggedInUserFullName: null,
			profile_photo: null,
			activeSection: 'Personal Data',
			PeopleId: '',
			IdentificationId: '',
			WorkId: '',
			langDeleteId: [],
			nationalityArray: [],
			deleteError: false,
			yeardata: [],
			activeStep: 0,
			personalData: {
				mode: 'post',
				first_name: '',
				last_name: '',
				date_of_birth: '',
				nationality: '',
				email: '',
				country_code: '',
				phone_number: '',
				isCancelButtonEnabled: false,
				errors: [],
			},
			staticPersonalData: {
				first_name: '',
				mode: 'post',
				nationality: '',
				last_name: '',
				errors: [],
				phone_number: '',
				date_of_birth: '',
				email: '',
				isCancelButtonEnabled: false,
				country_code: '',
			},
			identification: {
				mode: 'post',
				visa_designation: '',
				joining_date: '',
				end_date: '',
				diploma: '',
				year: '',
				yearOptions: [],
				diplomaList: [],
				passport_number: '',
				passport_expiry_date: '',
				local_id: '',
				local_expiry_date: '',
				driving_license: '',
				isCancelButtonEnabled: false,
				errors: [],
			},
			staticIdentification: {
				visa_designation: '',
				joining_date: '',
				end_date: '',
				diplomaList: [],
				passport_number: '',
				diploma: '',
				yearOptions: [],
				passport_expiry_date: '',
				errors: [],
				mode: 'post',
				local_id: '',
				isCancelButtonEnabled: false,
				local_expiry_date: '',
				year: '',
				driving_license: '',
			},
			work: {
				mode: 'post',
				education_degree: '',
				education_stream: '',
				disciplineOptions: [],
				primary_experience: '',
				regionList: [],
				primary_year: '',
				secondary_experience: '',
				secondary_year: '',
				experienceOptions: [],
				levelList: [],
				region_of_experience: '',
				design: undefined,
				supervision: undefined,
				otherLangList: [],
				current_position: 'Others',
				currentOtherPosition: '',
				internalRateList: [],
				expYearsList: [],
				currentPositionList: [],
				currentOtherPositionOptions: [],
				groupRelationRadio: null,
				openGroupRelation: false,
				groupRelationMenus: [],
				employeeStatus: null,
				statusOption: null,
				internal_rate: { label: "", value: "" },
				languages: [],
				isCancelButtonEnabled: false,
				errors: [],
			},
			staticWork: {
				primary_experience: '',
				secondary_year: '',
				education_degree: '',
				mode: 'post',
				primary_year: '',
				levelList: [],
				education_stream: '',
				experienceOptions: [],
				regionList: [],
				internalRateList: [],
				expYearsList: [],
				openGroupRelation: false,
				otherLangList: [],
				region_of_experience: '',
				disciplineOptions: [],
				currentPositionList: [],
				design: undefined,
				current_position: 'Others',
				supervision: undefined,
				secondary_experience: '',
				errors: [],
				currentOtherPositionOptions: [],
				groupRelationRadio: null,
				currentOtherPosition: '',
				isCancelButtonEnabled: false,
				groupRelationMenus: [],
				employeeStatus: null,
				statusOption: null,
				languages: [],
				internal_rate: { label: "", value: "" },
			},
			documents: {
				mode: 'post',
				loading: false,
				resume: null,
				documents: [],
				addDocument: false,
				otherDocumentUpload: null,
				otherDocumentFileName: '',
				isCancelButtonEnabled: false,
				errors: [],
			},
			connections: {
				mode: 'post',
			},
			auditTrail: {
				mode: 'post',
			},
			isAuditTrail: false,
			editIndex: -1,
			editedName: "",
			documentId: "",
			buttonType: "",
			documentEdit: false,
			currentPositionData : ""
		};

		// @ts-ignore
		this.sections = [
			'Personal Data',
			'Identification',
			'Work',
			'Documents',
			'Connections',
			'Audit trail',
		];
		this.countableSection = countSection(this.sections);

		// @ts-ignore
		this.contentRef = React.createRef();

		// Customizable Area End

		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	async componentDidMount() {
		const { navigation } = this.props;

		this.handleGetOtherCurrentPositions();
		this.handleGetDisciplineOptions();
		this.handleGetExperienceOptions();
		this.handleGetYearOptions();
		this.apiForDiplomaOptions = this.getApiCall("bx_block_company/diploma");
		this.apiForCountryList = this.getApiCall("bx_block_company/countries");
		this.apiForHighestLevel = this.getApiCall("bx_block_company/education_degree");
		this.apiForExpYearOptions = this.getApiCall("bx_block_company/experience_year")
		this.apiForInternalRate = this.getApiCall("bx_block_company/internal_rate");
		this.apiForOtherLanguages = this.getApiCall("bx_block_company/other_language");
		this.apiForCurrentPositionList = this.getApiCall("bx_block_company/current_position");
		this.apiForNationalities = this.getApiCall("bx_block_people/countries");
		  
		if (navigation?.getParam('people_id') !== 'add') {
			await Promise.all([
				this.handleGetPersonalData(),
				this.handleGetIdentificationData(),
				this.handleGetWorkData(),
			]);
			this.setState({
				currentPositionData : "Others"
			});
		} else {
			this.setState({
				documentEdit: true
			})
		}
		this.persistCurrentUser();
		this.setState({ isLoading: false })
		this.send(new Message(getName(MessageEnum.RequestUserCredentials)));

		// Customizable Area Start
		window.addEventListener('scroll', this.handleScroll);

		// Customizable Area End
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
		if (prevState.activeSection != this.state.activeSection) {
			this.countableSection.setActive(this.state.activeSection)
		}
	}

	async componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	customScrollToComponent = (componentId: any, additionalOffset = 75) => {
		const element = document.getElementById(componentId);

		if (element) {
			const elementRect = element.getBoundingClientRect();
			const scrollTop = window.scrollY || document.documentElement.scrollTop;
			const newScrollPosition = scrollTop + elementRect.top - additionalOffset;
			// Scroll to the new position
			window.scrollTo({
				top: newScrollPosition,
				behavior: "smooth",
			});
		}
	}

	persistCurrentUser() {
		const userDataRaw = localStorage.getItem("currentUser");
		if (userDataRaw) {
			const userData = JSON.parse(userDataRaw);
			this.setState({
				loggedInUserFullName: userData.fullName,
				profile_photo: userData.profilePhoto
			})
		}
	}

	// Left sidebar ListItem click
	handleListItemClick = (section: any) => {
		const {PeopleId } = this.state;
	
		switch (section) {
			case 'Personal Data':
				this.setState({ activeSection: section });
				break;
			default:
				if (PeopleId) {
					this.setState({ activeSection: section });
				}
		}
		
		const element = document.getElementById(section);
		if (element) {
			this.customScrollToComponent(section)
		}
	}

	// Handle scroll on container
	handleScroll = () => {
		// @ts-ignore
		const sections = this.sections;

		let activeSection = null;

		for (let section of sections) {
			const element = document.getElementById(section);
			if (element) {
				const rect = element.getBoundingClientRect();
				if (rect.bottom >= 75) {
					activeSection = section;
					break;
				}
			}
		}
		if (activeSection) {
			this.setState({ activeSection });
		}
	};

	//  -- PERSONAL DATA - POST RESPONSE
	handlePersonalDataPostResponse = (responseJson: any) => {
		this.setState({ isAuditTrail: false })
		if (responseJson.error) {
			this.setState({
				hasError: responseJson.error
			})
			return
		}
		if (responseJson.errors && responseJson.errors.length > 0) {
			this.setState({
				personalData: {
					...this.state.personalData,
					errors: responseJson.errors,
				},
			});
		} else {
			const pdata = {
				email: responseJson.data.attributes.contact_information.email,
				mode: 'read',
				last_name: responseJson.data.attributes.people.last_name,
				country_code: responseJson.data.attributes.contact_information.phone_number?.split(
					'-'
				)?.[0],
				nationality: responseJson.data.attributes.people.nationality,
				date_of_birth: responseJson.data.attributes.people.date_of_birth,
				first_name: responseJson.data.attributes.people.first_name,
				phone_number: responseJson.data.attributes.contact_information.phone_number?.split(
					'-'
				)?.[1],
				errors: [],
				isCancelButtonEnabled: true,
			}
			this.setState({
				personalData: pdata,
				staticPersonalData: pdata,
				PeopleId: responseJson.data.id,
				activeSection: this.props.currentUser.defaultRole == "HR" ? "Identification" : "Work"
			});

			this.handleSwitchMode('personalData', 'read');
		}
	};
	

	//  -- PERSONAL DATA - UPDATE RESPONSE
	handlePersonalDataUpdateResponse = (responseJson: any) => {
		this.setState({ isAuditTrail: false })
		if (responseJson.error) {
			this.setState({
				hasError: responseJson.error
			})
		} else if (responseJson.errors && responseJson.errors.length > 0) {
			this.setState({
				personalData: {
					...this.state.personalData,
					errors: responseJson.errors,
				},
			})
		} else {
			const updatedData = {
				mode: "read",
				first_name: responseJson.data.attributes.people.first_name,
				last_name: responseJson.data.attributes.people.last_name,
				email: responseJson.data.attributes.contact_information.email,
				date_of_birth: responseJson.data.attributes.people.date_of_birth,
				nationality: responseJson.data.attributes.people.nationality,
				country_code: responseJson.data.attributes.contact_information.phone_number?.split(
					'-'
				)?.[0],
				phone_number: responseJson.data.attributes.contact_information.phone_number?.split(
				  "-"
				)?.[1],
				isCancelButtonEnabled: false,
				errors: [],
			}
			this.setState({
				personalData: updatedData,
				staticPersonalData: updatedData
			});
			this.handleSwitchMode('personalData', 'read');
		}
	};

	//  -- IDENTIFICATION - POST RESPONSE
	handleIdentificationPostResponse = (responseJson: any) => {
		if (responseJson.error) {
			this.setState({
				hasError: responseJson.error
			})
		} else if (responseJson.errors && responseJson.errors.length > 0) {
			this.setState({ isAuditTrail: false })
			let errorMessage = '';
			responseJson.errors.forEach((error: any) => {
				errorMessage += error + '\n';
			});
			this.setState({
				identification: {
					...this.state.identification,
					errors: responseJson.errors,
				},
			});
		} else {
			const uIData = {
				// isEdit: false,
				mode: 'read',
				visa_designation: responseJson.visa_designation,
				joining_date: responseJson.joining_date,
				end_date: responseJson.end_date,
				diploma: responseJson.diploma,
				year: responseJson.year,
				passport_number: responseJson.passport_number,
				passport_expiry_date: responseJson.passport_expiry_date,
				local_id: responseJson.local_id,
				local_expiry_date: responseJson.local_expiry_date,
				driving_license: responseJson.driving_license,
				diplomaList: this.state.identification.diplomaList,
				isCancelButtonEnabled: true,
				errors: [],
			}
			this.setState((prevState) => {
				return {
					...prevState,
					identification: {...uIData, yearOptions: prevState.identification.yearOptions,},
					staticIdentification: {...uIData, yearOptions: prevState.identification.yearOptions,},
					PeopleId: responseJson.people_id,
					IdentificationId: responseJson.id,
					activeSection:"Work",
				};
			},()=>{
				this.setState({ isAuditTrail: false })
			});
			this.handleSwitchMode('identification', 'read');
			this.handleGetYearOptions();
		}
	};

	//  -- IDENTIFICATION - UPDATE RESPONSE
	handleIdentificationUpdateResponse = (responseJson: any) => {
		if (responseJson.error) {
			this.setState({
				hasError: responseJson.error
			})
		} else if (responseJson.errors && responseJson.errors.length > 0) {
			let errorMessage = '';
			responseJson.errors.forEach((error: any) => {
				errorMessage += error + '\n';
			});
			this.setState({
				identification: {
					...this.state.identification,
					errors: responseJson.errors,
				},
				isAuditTrail: false 
			});
			return;
		} else {
			const updatedIdata =  {
				...this.state.identification,
				mode: 'read',
				visa_designation: responseJson.visa_designation,
				joining_date: responseJson.joining_date,
				end_date: responseJson.end_date,
				diploma: responseJson.diploma,
				year: responseJson.year,
				passport_number: responseJson.passport_number,
				local_id: responseJson.local_id,
				passport_expiry_date: responseJson.passport_expiry_date,
				local_expiry_date: responseJson.local_expiry_date,
				driving_license: responseJson.driving_license,
				isCancelButtonEnabled: true,
				errors: responseJson.errors ? responseJson.errors : [],
			}
			this.setState((prevState) => {
				return {
					identification: {...updatedIdata, yearOptions: prevState.identification.yearOptions },
					staticIdentification: {...updatedIdata, yearOptions: prevState.identification.yearOptions },
					PeopleId: responseJson.people_id,
					IdentificationId: responseJson.id,
				};
			},()=>{
				this.setState({ isAuditTrail: false })
			});
			this.handleSwitchMode('identification', 'read');
			this.handleGetYearOptions();
		}
	};

	// -- IDENTIFICATION - YEAR OPTIONS RESPONSE
	handleGetYearOptionsResponse = (responseJson: any) => {
		if (responseJson.errors && responseJson.errors.length > 0) {
			let errorMessage = '';
			responseJson.errors.forEach((error: any) => {
				errorMessage += Object.values(error)[0] + '\n'
			});
			this.setState({
				hasError: errorMessage,
			});
		} else {
			this.setState({
				identification: {
					...this.state.identification,
					yearOptions: responseJson.map((p: string) => {
						return {
							value: p,
							label: p,
						};
					}),
				},
			});
		}
	};

	handleWorkResponseError = (responseJson: any) => {
		if (responseJson.error) {
			this.setState({
				hasError: responseJson.error
			})
			return true;
		} else if (responseJson.errors && responseJson.errors.length > 0) {
			this.setState({
				work: {
					...this.state.work,
					errors: responseJson.errors,
				},
			});
			return true;
		} 
		return false
	}

	//  -- WORK - POST RESPONSE
	handleWorkPostResponse = (responseJson: any) => {
		const { work, group_relation, languages } = responseJson;
		this.setState({ isAuditTrail: false })
		if (this.handleWorkResponseError(responseJson)) {
			return;
		} 
		const pWorkData = {
			...this.state.work,
			mode: 'read',
			education_degree: work.education_degree,
			education_stream: work.education_stream,
			primary_experience: work.primary_experience,
			primary_year: work.primary_year,
			secondary_experience: work.secondary_experience,
			secondary_year: work.secondary_year,
			region_of_experience: work.region_of_experience,
			design: work.design,
			supervision: work.supervision,
			current_position: work.current_position,
			currentOtherPosition: '',
			internal_rate: { value: work.internal_rate, label: work.internal_rate},
			groupRelationRadio: (Array.isArray(group_relation) && group_relation[0]?.relation) || null,
			groupRelationMenus: group_relation,
			employeeStatus: group_relation.employe_status ?? undefined,
			statusOption: group_relation.status_option,
			languages: languages,
			isCancelButtonEnabled: true,
			errors: [],
		}
		this.setState({
			WorkId: work.id,
			work: pWorkData,
			staticWork: pWorkData,
			activeSection:'Documents'
		});
		
	};

	//  -- WORK - UPDATE RESPONSE
	handleWorkUpdateResponse = (responseJson: any) => {
		
		this.setState({ isAuditTrail: false })
		if (this.handleWorkResponseError(responseJson)) {
			return;
		}
		const { work, languages, group_relation, errors } = responseJson;
		let internal_rate = {
			label: '',
			value: ''
		}

		if (work?.internal_rate) {
			const internalRateOption = this.state.work.internalRateList.find(x => x.value === work.internal_rate)
			if (internalRateOption) {
				internal_rate = internalRateOption
			}
		}
		const uWorkData = {
			...this.state.work,
			mode: 'read',
			education_stream: work.education_stream,
			education_degree: work.education_degree,
			secondary_experience: work.secondary_experience,
			secondary_year: work.secondary_year,
			primary_experience: work.primary_experience,
			primary_year: work.primary_year,
			region_of_experience: work.region_of_experience,
			design: work.design,
			supervision: work.supervision,
			current_position: work.current_position,
			internal_rate,
			currentOtherPosition: '',
			groupRelationRadio: (Array.isArray(group_relation) && group_relation[0]?.relation) || null,
			groupRelationMenus: group_relation,
			employeeStatus: group_relation.employe_status ?? undefined,
			statusOption: group_relation ? group_relation.status_option : null,
			languages: languages,
			isCancelButtonEnabled: true,
			regionList: this.state.work.regionList,
			errors: errors || [],
		} 
		this.handleChangeInBulk('work', uWorkData);
		this.handleChangeInBulk('staticWork', uWorkData);
		this.setState({ WorkId: work.id });
		this.handleSwitchMode('work', 'read');

	};

	getApiCall = (endPoint: string) => {

		const header = {
			"Content-Type": "application/json",
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			endPoint
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			"GET"
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		return requestMessage.messageId
	}

	handleResponsesError = ( errRes: any ) => {
		if (errRes.errors && errRes.errors.length > 0) {
			let errorMsg = '';
			errRes.errors.forEach((err: any) => {
				errorMsg += Object.values(err)[0] + '\n'
			});
			this.setState({
				hasError: errorMsg
			})
			return true
		} else if (errRes.error) {
			this.setState({
				hasError: errRes.error
			})
			return true
		} else {
            return false
        }
	}

	handleCountryListResponse = (regList: any) => {
		if(this.handleResponsesError(regList)){
			return
		}
		const res = convertResIntoLabelValue(regList)
		this.setState({
			work: {
				...this.state.work,
				regionList: res
			}
		});
	}

	handleDeplomaListResponse = (diplomaRes: any) => {
		if(this.handleResponsesError(diplomaRes)){
			return
		}
		const dip = convertResIntoLabelValue(diplomaRes)
		this.setState({
			identification: {
				...this.state.identification,
				diplomaList: dip
			}
		});
	}

	handleHighestLevelListResponse = (levelRes: any) => {
		if(this.handleResponsesError(levelRes)){
			return
		}
		const levelList = convertResIntoLabelValue(levelRes)
		this.setState({
			work: {
				...this.state.work,
				levelList: levelList,
			}
		});
	}

	handleInternalRateListResponse = (rateRes: any) => {
		if(this.handleResponsesError(rateRes)){
			return
		}
		const levelList = convertResIntoLabelValue(rateRes)
		this.setState({
			work: {
				...this.state.work,
				internalRateList: levelList,
			}
		});
	}

	handleExperienceYearsResponse = (yearRes: any) => {
		if(this.handleResponsesError(yearRes)){
			return
		}
		const expYearsList = convertResIntoLabelValue(yearRes)
		this.setState({
			work: {
				...this.state.work,
				expYearsList: expYearsList,
			}
		});
	}

	handleOtherLanguagesListResponse = (langRes: any) => {
		if(this.handleResponsesError(langRes)){
			return
		}
		const removeLangList = ['English', 'French', 'Arabic', 'Spanish', 'Italian'];
		const langList = convertResIntoLabelValue(langRes, "name", removeLangList)
		this.setState({
			work: {
				...this.state.work,
				otherLangList: langList,
			}
		});
	}

	handleCurrentPostionListResopnse = ( cplRes : any ) => { 
		if(this.handleResponsesError(cplRes)){
			return
		}
		const cplList = convertResIntoLabelValue(cplRes, "position_name")
		this.setState({
			work: {
				...this.state.work,
				currentPositionList: cplList,
			}
		});
	}

	handleDocEditNameResponse = (response: any) => {
		const { documents } = response.data?.attributes;
		const {buttonType} = this.state
		if(response?.data){
			this.setState({
				editIndex: -1,
				documents: {
				...this.state.documents,
				mode: buttonType ? "read" : 'update',
				documents: documents ? documents : [],
				addDocument: false,
				loading: false
				}
			})
		}
	}

	// -- GET OTHER CURRENT POSITIONS RESPONSE
	handleGetOtherCurrentPositionsResponse = (responseJson: any) => {
		if (responseJson.errors && responseJson.errors.length > 0) {
			let errorMessage = '';
			responseJson.errors.forEach((error: any) => {
				errorMessage += Object.values(error)[0] + '\n'
			});
			this.setState({
				hasError: errorMessage,
			});
		} else {
			const cpList = convertResIntoLabelValue(responseJson, "position_name",)
			this.setState({
				work: {
					...this.state.work,
					currentOtherPositionOptions: cpList
				},
			});
		}
	};

	// -- GET DISCIPLINE OPTIONS RESPONSE
	handleGetDisciplineOptionsResponse = (responseJson: any) => {
		if (responseJson.errors && responseJson.errors.length > 0) {
			let errorMessage = '';
			responseJson.errors.forEach((error: any) => {
				errorMessage += Object.values(error)[0] + '\n'
			});
			this.setState({
				hasError: errorMessage,
			});
		} else {
			const displinList = convertResIntoLabelValue(responseJson, "stream_name")
			this.setState({
				work: {
					...this.state.work,
					disciplineOptions: displinList,
				}
			});
		}
	};

	// -- GET EXPERIENCE OPTIONS RESPONSE
	handleGetExperienceOptionsResponse = (responseJson: any) => {
		if (responseJson.errors && responseJson.errors.length > 0) {
			let errorMessage = '';
			responseJson.errors.forEach((error: any) => {
				errorMessage += Object.values(error)[0] + '\n'
			});
			this.setState({
				hasError: errorMessage,
			});
		} else {
			const opts = convertResIntoLabelValue(responseJson, "sector_name")
			this.setState({
				work: {
					...this.state.work,
					experienceOptions: opts,
				},
			});
		}
	};

	// RESUME - UPLOAD RESPONSE
	handleResumeUploadResponse = (responseJson: any) => {
		const peopleId = this.props.navigation.getParam('people_id');		
		this.setState({ isAuditTrail: false })
		this.handleChange('documents', 'loading', false)
		if (
			!responseJson.data &&
			responseJson.errors &&
			responseJson.errors.length > 0
		) {
			let errorMessage = '';
			responseJson.errors.forEach((error: any) => {
				errorMessage += error + '\n';
			});
			this.setState({
				hasError: errorMessage,
			});
		} else if (responseJson.error) {
			this.setState({
				hasError: responseJson.error
			})
			return;
		}
		else {
			const { attributes } = responseJson.data;
			if (!attributes.documents) {
				this.setState({
					hasError: 'Document upload failed',
				});
			}
			this.handleChange('documents', 'mode', 'read')
			this.handleChange('documents', 'loading', false)
			this.handleGetPersonalData();
			this.setState({activeSection: peopleId == "add" && !this.countableSection.actived("Connections") ? "Connections": "Documents"})
		}
	};
	// DOCUMENT - REMOVE REQUEST
	handleDocumentDeleteResponse = (responseJson: any) => {
		if (
			responseJson.errors &&
			responseJson.errors.length > 0
		) {
			let errorMessage = '';
			responseJson.errors.forEach((error: any) => {
				errorMessage += error + '\n';
			});
			this.setState({
				hasError: errorMessage,
			});
			this.handleChange('documents', 'loading', false)
		} else {
			this.handleChange('documents', 'mode', 'read')
			this.handleChange('documents', 'loading', false)
			this.handleGetPersonalData()
		}
	};
	

	handleGetPersonalDataResponse = (responseJson: any) => {
		if (
			!responseJson.data &&
			responseJson.errors &&
			responseJson.errors.length > 0
		) {
			let errorMessage = '';
			responseJson.errors.forEach((error: any) => {
				errorMessage += error + '\n';
			});
			this.setState({
				hasError: errorMessage,
			});
		} else {
			const {
				contact_information,
				documents,
				resume,
				people,
			} = responseJson.data?.attributes;
			const receivedPersonalData = {
				mode: 'read',
				first_name: people.first_name,
				last_name: people.last_name,
				email: contact_information.email,
				date_of_birth: people.date_of_birth,
				nationality: people.nationality,
				country_code: contact_information.phone_number?.split('-')?.[0],
				phone_number: contact_information.phone_number?.split('-')?.[1],
				errors: [],
			}

			this.setState(
				(prevState: any) => {
					return {
						...prevState,
						PeopleId: responseJson.data.id,
						personalData: receivedPersonalData,
						staticPersonalData: receivedPersonalData,
						documents: {
							mode: 'read',
							loading: false,
							resume:
								resume && resume.document_link !== undefined ? resume : '',
							resumeUrl:
								resume && resume.document_name !== undefined
									? resume.document_name
									: '',
							documents: documents ? documents : [],
							addDocument: false,
							otherDocumentFileName: '',
							otherDocumentUpload: null,
							// otherDocumentsToUpload: [],
						},
					};
				}
			);
		}
	};
	handleGetIdentificationDataResponse = (responseJson: any) => {
		if(!responseJson) {
			this.setState((prevState) => {
				return {
					...prevState,
					staticIdentification: {...prevState.identification, yearOptions: prevState.identification?.yearOptions},
					identification: {...prevState.identification, yearOptions: prevState.identification?.yearOptions}
				} 
			})
			return
		};
		const receivedIdata = {
			    ...this.state.identification,
				mode: 'read',
				visa_designation: responseJson?.visa_designation,
				joining_date: responseJson?.joining_date,
				end_date: responseJson?.end_date,
				diploma: responseJson?.diploma,
				year: responseJson?.year,
				passport_number: responseJson?.passport_number,
				passport_expiry_date: responseJson?.passport_expiry_date,
				local_id: responseJson?.local_id,
				local_expiry_date: responseJson?.local_expiry_date,
				driving_license: responseJson?.driving_license,
				isCancelButtonEnabled: true,
				errors: []
		}
		this.setState(
			(prevState: any) => {
				return {
					...prevState,
					IdentificationId: responseJson?.id,
					staticIdentification: {...receivedIdata, yearOptions: prevState.identification?.yearOptions},
					identification: {...receivedIdata, yearOptions: prevState.identification?.yearOptions}
				};
			}
		);
	};

	handleGetWorkDataResponse = (responseJson: any) => {
		if (
			!responseJson.data &&
			responseJson.errors &&
			responseJson.errors.length > 0
		) {
			let errorMessage = '';
			responseJson.errors.forEach((error: any) => {
				errorMessage += error + '\n';
			});
			this.setState({
				hasError: errorMessage,
			});
		} else {
			const { work, group_relation, languages } = responseJson;
			let internal_rate = {
				label: '',
				value: ''
			}

			if (work?.internal_rate) {
				const internalRateOption = this.state.work.internalRateList.find(x => x.value === work.internal_rate)
				if (internalRateOption) {
					internal_rate = internalRateOption
				}
			}
			const workData = work ? {
				mode: 'read',
				education_degree: work?.education_degree,
				education_stream: work?.education_stream,
				primary_year: work?.primary_year,
				primary_experience: work?.primary_experience,
				secondary_experience: work?.secondary_experience,
				region_of_experience: work?.region_of_experience,
				secondary_year: work?.secondary_year,
				design: work?.design,
				supervision: work?.supervision,
				currentOtherPosition: '',
				current_position: work?.current_position,
				internal_rate,
				isCancelButtonEnabled: true,
			} : {}
			const receivedWorkData = {
				...this.state.work,
				...workData,
				groupRelationRadio: (Array.isArray(group_relation) && group_relation[0]?.relation) || null,
				groupRelationMenus: group_relation,
				employeeStatus: group_relation?.employe_status,
				statusOption: group_relation?.status_option,
				languages: languages,
				errors: [],
			}
			const cloneObj = JSON.parse(JSON.stringify(receivedWorkData))
			this.setState(
				(prevState: any) => {
					return {
						...prevState,
						WorkId: work?.id,
						work: { ...receivedWorkData},
						staticWork:cloneObj
					};
				}
			);
		}
	};
	handleGetIdentificationData = () => {
		const peopleId = this.props.navigation.getParam('people_id');
		if (!!!peopleId || peopleId === 'add') {
			return;
		}
		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
			people_id: this.state.PeopleId,
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForIdentificationGet = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`bx_block_people/people/${peopleId}/identifications/${peopleId}?role=${getStoredDefaultRole()}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'GET'
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	handleGetWorkData = () => {
		const peopleId = this.props.navigation?.getParam('people_id');
		if (!!!peopleId || peopleId === 'add') {
			return;
		}
		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
			people_id: this.state.PeopleId,
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForWorkDataGet = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`bx_block_people/people/${peopleId}/works/${peopleId}`
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'GET'
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	// ----- RECIEVE DATA RESPONSE FROM APIS -----
	async receive(from: string, message: Message) {
		// Customizable Area Start

		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			this.setState({ isLoading: false })
			const apiRequestCallId = message.getData(
				getName(MessageEnum.RestAPIResponceDataMessage)
			);

			const responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			// Error in case of deactivated account or expired token
			if (responseJson
				&& responseJson.errors
				&& (responseJson.errors.includes(configJSON.deactivatedUserError)
					|| responseJson.errors.includes(configJSON.expiredAuthTokenError)
				)) {
				await logoutUser(this.props, responseJson.errors[0])
				return
			}

			this.apiSuccessCallBackController(apiRequestCallId, responseJson)
            this.handleDeleteLangResponse(apiRequestCallId, responseJson)

			// getPeopleData
			if (responseJson && apiRequestCallId === this.apiForPeopleshow) {
				this.setState({ isLoading: false })
				this.handleGetPersonalDataResponse(responseJson);
			}
		}

		// Customizable Area End
	}

	// Customizable Area Start

	handleDeleteLangResponse = (apiRequestCallId: string, responseJson: any) => {
		if(responseJson && apiRequestCallId === this.apiForDeleteLanguages) {
			this.setState({langDeleteId: []})
			this.handleGetWorkData()
		}
	}

	handleDeleteGroupRelRes = (responseJson: any) => {
		if(responseJson.message) {
			this.setState({ 
				staticWork: {...this.state.staticWork, groupRelationMenus: this.state.work.groupRelationMenus}
			}, () => this.handleUpdateWorkData())
		}
	}

	apiSuccessCallBackController = (
		apiRequestCallId: string,
		responseJson: any
	) => {
		const successCallbackMap = {
			[this.apiForPersonalDataPost]: this.handlePersonalDataPostResponse,
			[this.apiForPersonalDataUpdate]: this.handlePersonalDataUpdateResponse,
			[this.apiForIdentificationPost]: this.handleIdentificationPostResponse,
			[this.apiForIdentificationUpdate]: this.handleIdentificationUpdateResponse,
			[this.apiForYearOptions]: this.handleGetYearOptionsResponse,
			[this.apiForWorkPost]: this.handleWorkPostResponse,
			[this.apiForWorkUpdate]: this.handleWorkUpdateResponse,
			[this.apiForOtherCurrentPositions]: this.handleGetOtherCurrentPositionsResponse,
			[this.apiForDisciplineOptions]: this.handleGetDisciplineOptionsResponse,
			[this.apiForExperienceOptions]: this.handleGetExperienceOptionsResponse,
			[this.apiForDocumentUpload]: this.handleResumeUploadResponse,
			[this.apiForDocumentDelete]: this.handleDocumentDeleteResponse,
			[this.apiForIdentificationGet]: this.handleGetIdentificationDataResponse,
			[this.apiForWorkDataGet]: this.handleGetWorkDataResponse,
			[this.apiForCountryList]: this.handleCountryListResponse,
			[this.apiForDiplomaOptions] : this.handleDeplomaListResponse,
			[this.apiForHighestLevel] : this.handleHighestLevelListResponse,
		    [this.apiForExpYearOptions] : this.handleExperienceYearsResponse,
			[this.apiForInternalRate] : this.handleInternalRateListResponse,
			[this.apiForOtherLanguages] : this.handleOtherLanguagesListResponse,
			[this.apiForCurrentPositionList] : this.handleCurrentPostionListResopnse,
			[this.apiForDeleteGroupRelation] : this.handleDeleteGroupRelRes,
			[this.apiForDocumentEditName]: this.handleDocEditNameResponse,
			[this.apiForNationalities] : this.handleNationalitiesResponse
		};

		if (apiRequestCallId) {
			const successCallback: ((responseJson: any) => void) = successCallbackMap[apiRequestCallId];
			!!successCallback && successCallback(responseJson);
		}
	};

	handleError = (errorMessage: string) => {
		this.setState({
			hasError: errorMessage
		})
	}

	handleGoBack = () => {
		const { navigation } = this.props;	  
		navigation.navigate("PeopleData");
	  };

	handleNationalitiesResponse = (nationRes:any) => {
		if(this.handleResponsesError(nationRes)){
			return
		}
		const convertedData = nationRes.map((x:any)=>{
			return {name: x}
		})
		const result = convertResIntoLabelValue(convertedData)
		this.setState({
			nationalityArray: result
		});
	}

	handleChangePersonalData =(value: any, valueKey: string) => {
		const { errors } = this.state.personalData
		const newErrors = validateAndUpdateErrors(peoplePersonalValidationSchema,errors, valueKey, value)
		this.setState({
			personalData: { ...this.state.personalData, [valueKey]: value, errors: newErrors },
		});
	};

	handleChangeIdentification = (value: any, identKey: string) => {
		const  identErrors = this.state.identification.errors;
		const newIdentErrors = validateAndUpdateErrors(peopleIdentificationValidationSchema, identErrors, identKey, value )
		this.setState({
			identification: { ...this.state.identification, [identKey]: value, errors: newIdentErrors },
		});
	};

	handleChangeWork = (value: any, workKey: string) => {
		const workErrors = this.state.work.errors;
		const newWorkErrors = validateAndUpdateErrors(peopleWorkValidationSchema, workErrors, workKey, value)
		if (workKey === "currentOtherPosition") {
			this.setState({
				work: { ...this.state.work, [workKey]: value, current_position: "Others", errors: newWorkErrors},
			});
		} else {
			this.setState({
				work: { ...this.state.work, [workKey]: value, errors: newWorkErrors},
			});
		}
	};

	renderErrors = (errorKey: string, errors: ErrorObject[]) : {error: boolean, text: string | undefined}=> {
		const errorP = errors.some((error) => error.hasOwnProperty(errorKey))
		const textP = errors.find((x) => x.hasOwnProperty(errorKey))?.[errorKey]
		return {error: errorP, text : textP ?? undefined}
	}

	handleSelectLang(language: string, level: 'Advance' | 'Beginner' | string | undefined, obj:any) {				
		const officialLangs = [
			"English",
			"French",
			"Arabic",
			"Spanish",
			"Italian"
		];
		let deleteOtherLangIds: any[] = []

		this.setState((prevState: any) => {
		  const updatedLanguages = [...prevState.work.languages];
		  const langIndex = updatedLanguages.findIndex((lang: any) => lang.name === language);
		  if (langIndex !== -1 && level === "") {
			updatedLanguages.splice(langIndex, 1);
		  } else {
			if (langIndex !== -1) {
			  updatedLanguages[langIndex].level = level;
			} else {
				if (!officialLangs.includes(language)) {
					const removeOtherIndex = updatedLanguages.findIndex(lang => !officialLangs.includes(lang.name))
					if (removeOtherIndex != -1) {
						deleteOtherLangIds.push(updatedLanguages[removeOtherIndex]?.id)
						updatedLanguages.splice(removeOtherIndex, 1)
					}
				}
			  if (level !== "") {
				updatedLanguages.push({ name: language, level });
			  }
			}
		  }
		  return {
			...prevState,
			work: {
			  ...prevState.work,
			  languages: updatedLanguages,
			  errors: validateAndUpdateErrors(peopleWorkValidationSchema, this.state.work.errors, "languages", updatedLanguages)
			},
			langDeleteId: [...prevState.langDeleteId, ...deleteOtherLangIds]
		  };
		});

		if(obj?.id && level === ""){
			this.setState({langDeleteId: [...this.state.langDeleteId, obj.id]})
		}

		
	}

	deleteLanguageApi = () => {
		if (this.state.langDeleteId.length === 0 || this.state.work.languages.length === 0) {
			this.setState({deleteError: true,langDeleteId: []})
			return;
		}

		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
		};


		const httpBody = {
			ids: this.state.langDeleteId,
			role: getStoredDefaultRole(),
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForDeleteLanguages = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`bx_block_people/people/${this.state.PeopleId}/works/${this.state.WorkId}/delete_language`
		);

		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'DELETE'
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		this.setState({ isAuditTrail: true })
	}

	handleChangeGroupRelation = (
		value: "related" | "unrelated",
		groupRelationMenus: any,
	) => {   
		this.setState({
			work: {
				...this.state.work,
				groupRelationRadio: value,
				...(groupRelationMenus ? {
					groupRelationMenus: groupRelationMenus,
					errors: validateAndUpdateErrors(peopleWorkValidationSchema, this.state.work.errors, "group_relation", groupRelationMenus)
				} : 
				{ openGroupRelation: true}),
			},
		});
	};

	handleOpenGroupRelation = (value: boolean) =>{
		this.setState({ work:{
			...this.state.work,
			openGroupRelation: value
		}})
	}

	handleChange = (objectKey: keyof S, property: any, value: any | null) => {
		this.setState((prevState: S) => {
			const currentPart = prevState[objectKey];
			// Return the state with the updated object
			const updatedObject = {
				...currentPart,
				[property]: value,
			} as S[typeof objectKey];
			return {
				...prevState,
				[objectKey]: updatedObject,
			};
		});
	};

	handleChangeInBulk = (objectKey: keyof S, newStates: Object) => {
		this.setState(
			(prevState: S) => {
				const currentPart = prevState[objectKey];
				const updatedState = {
					...currentPart,
					...newStates,
				};

				const deepClone = JSON.parse(JSON.stringify(updatedState));

				return {
					...prevState,
					[objectKey]: deepClone,
				};
			}
		);
	};

	// Switch the mode of the container
	handleSwitchMode = (section: string, mode: ModuleMode, onCancel?: boolean) => {
		
		switch (section) {
			case 'personalData':
				this.setState((prevState) => ({
					personalData: { ...(onCancel ? prevState.staticPersonalData: prevState.personalData), mode },
				}))
				break;
			case 'identification':
				this.setState((prevState) => ({
					identification: { ...(onCancel ? prevState.staticIdentification: prevState.identification), mode },
				}))
				break;
			case 'work':
				this.setState((prevState) => ({
					work: { ...(onCancel ? prevState.staticWork: prevState.work), mode },
					langDeleteId:[]
				}))
				break;
			case 'documents':
				this.setState((prevState) => ({
					documents: { ...prevState.documents, mode },
				}));
				break;
			default:
				break;
		}
	};

	// ------------------- API CALLS -------------------

	// --------- PERSONAL DATA ---------
	// POST > Personal Data
	handlePostPersonalData = () => {
		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
		};

		const strippedCountry = this.state.personalData.country_code.replace(
			/\D/g,
			""
		);

		const data = {
			date_of_birth: this.state.personalData.date_of_birth,
			last_name: this.state.personalData.last_name,
			email: this.state.personalData.email,
			first_name: this.state.personalData.first_name,
			phone_number: `+${strippedCountry}-${this.state.personalData.phone_number}`,
			nationality: this.state.personalData.nationality,
		};

		const newData = {
			...data,
			phone_number: this.state.personalData.phone_number,
			country_code: this.state.personalData.country_code
		}

		if(validateAll(peoplePersonalValidationSchema, newData).length > 0) {
			this.setState((prevState)=>{
				return {
					...prevState,
					personalData: {
						...prevState.personalData,
						errors: validateAll(peoplePersonalValidationSchema, newData)
					}
			    }
			})
			return;
		}

		const requestMessage = new Message(
		getName(MessageEnum.RestAPIRequestMessage)
		);
			
		const httpPostBody = {
			people: data,
			role: getStoredDefaultRole()
		};
		
		this.apiForPersonalDataPost = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			'bx_block_people/people'
		);

		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpPostBody)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'POST'
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		this.setState({ isAuditTrail: true })
	};

	// PUT > Personal Data
	handleUpdatePersonalData = () => {
		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
			people_id: this.state.PeopleId,
		};

		const strippedCountryCode = this.state.personalData.country_code.replace(
			/\D/g,
			""
		);

		const data = {
			first_name: this.state.personalData.first_name,
			last_name: this.state.personalData.last_name,
			email: this.state.personalData.email,
			phone_number: `+${strippedCountryCode}-${this.state.personalData.phone_number}`,
			nationality: this.state.personalData.nationality,
			date_of_birth: this.state.personalData.date_of_birth,
		};

		const updatedData = {
			...data,
			country_code: this.state.personalData.country_code,
			phone_number: this.state.personalData.phone_number,
		}

		if(validateAll(peoplePersonalValidationSchema, updatedData).length > 0) {
			this.setState((prevState)=>{
				return {
					...prevState,
					personalData: {
						...prevState.personalData,
						errors: validateAll(peoplePersonalValidationSchema, updatedData)
					}
			    }
			})
			return;
		}

		const httpBody = {
			people: data,
			role: getStoredDefaultRole()
		};

		
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForPersonalDataUpdate = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`bx_block_people/people/${this.state.PeopleId}`
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'PUT'
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		this.setState({ isAuditTrail: true })
	};

	// GET > Personal Data
	handleGetPersonalData = () => {
		const propPeopleId = this.props.navigation.getParam('people_id');
		const statePeopleId = this.state.PeopleId;
		const peopleId = statePeopleId || propPeopleId;

		if (!!!peopleId || (peopleId === 'add' && statePeopleId == "")) {
			return;
		}


		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
			people_id: peopleId,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForPeopleshow = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`bx_block_people/people/${peopleId}`
		);
		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'GET'
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	// POST > Identification
	handlePostIdentificationData = () => {

		const postIdentheader = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
			people_id: this.state.PeopleId,
		};

		const data = {
			joining_date: this.state.identification.joining_date,
			end_date: this.state.identification.end_date,
			year: this.state.identification.year,
			passport_number: this.state.identification.passport_number,
			local_expiry_date: this.state.identification.local_expiry_date,
			visa_designation: this.state.identification.visa_designation,
			driving_license: this.state.identification.driving_license,
			diploma: this.state.identification.diploma,
			local_id: this.state.identification.local_id,
			passport_expiry_date: this.state.identification.passport_expiry_date,
		};

		const postIdentificationErrors = validateAll(peopleIdentificationValidationSchema, data)
		if(postIdentificationErrors.length > 0) {
			this.setState((prevState)=>{
				return {
					...prevState,
					identification:{
						...prevState.identification,
						errors: postIdentificationErrors
					}
				}
			})
			return;
		}

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForIdentificationPost = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`bx_block_people/people/${this.state.PeopleId}/identifications`
		);

		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(postIdentheader)
		);

		const httpIBody = {
			identification: data,
			role: getStoredDefaultRole(),
		};

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpIBody)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'POST'
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		this.setState({ isAuditTrail: true })
	};

	// PUT > Identification
	handleUpdateIdentificationData = () => {
		const data = {
			identification: {
				visa_designation: this.state.identification.visa_designation,
				joining_date: this.state.identification.joining_date,
				end_date: this.state.identification.end_date,
				diploma: this.state.identification.diploma,
				year: this.state.identification.year,
				passport_number: this.state.identification.passport_number,
				passport_expiry_date: this.state.identification.passport_expiry_date,
				local_id: this.state.identification.local_id,
				local_expiry_date: this.state.identification.local_expiry_date,
				driving_license: this.state.identification.driving_license,
			},
			role: getStoredDefaultRole()
		};

		const updateIdentificationErrors = validateAll(peopleIdentificationValidationSchema, data.identification)
		if(updateIdentificationErrors.length > 0) {
			this.setState((prevState)=>{
				return {
					...prevState,
					identification:{
						...prevState.identification,
						errors: updateIdentificationErrors
					}
				}
			})
			return;
		}

		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
			people_id: this.state.PeopleId,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForIdentificationUpdate = requestMessage.messageId;
		const method = !!this.state.IdentificationId ? 'PUT' : 'POST';
		const endpoint = method =='POST' ? `bx_block_people/people/${this.state.PeopleId}/identifications` :
									`bx_block_people/people/${this.state.PeopleId}/identifications/${this.state.IdentificationId}`											

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			endpoint
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(data)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			method
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		this.setState({ isAuditTrail: true })
	};

	// --------- WORK ---------
	// POST > Work
	handlePostWorkData = () => {
		const postWorkHeader = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
			people_id: this.state.PeopleId,
		};

		const data = {
			education_degree: this.state.work.education_degree,
			current_position:
				this.state.work.current_position === 'Others'
					? this.state.work.currentOtherPosition
					: this.state.work.current_position,
			education_stream: this.state.work.education_stream,
			primary_experience: this.state.work.primary_experience,
			primary_year: this.state.work.primary_year,
			secondary_year: this.state.work.secondary_year,
			region_of_experience: this.state.work.region_of_experience,
			design: this.state.work.design,
			secondary_experience: this.state.work.secondary_experience,
			internal_rate: this.state.work.internal_rate,
			supervision: this.state.work.supervision,
		};

		const postWork = {
			...data, 
			languages: this.state.work.languages, 
			currentOtherPosition: this.state.work.currentOtherPosition,
			group_relation: this.state.work.groupRelationMenus,
			current_position: this.state.work.current_position, 
		}
		const postWorkErrors = validateAll(peopleWorkValidationSchema, postWork)
		if(postWorkErrors.length > 0) {
			this.setState((prevState)=>{
				return {
					...prevState,
					work:{
						...prevState.work,
						errors: postWorkErrors
					}
				}
			})
			return;
		}

		const { groupRelationMenus } = this.state.work;

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		const httpBody = {
			work: data,
			group_relation: groupRelationMenus,
			role: getStoredDefaultRole(),
			languages: this.state.work.languages,
		};

		if(!!groupRelationMenus) {
			this.apiForWorkPost = requestMessage.messageId;
		} else {
			this.setState({
				hasError: "Choose at least one group relation along with at least one field",
			});
		}

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`/bx_block_people/people/${this.state.PeopleId}/works`
		);

		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(postWorkHeader)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'POST'
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		this.setState({ isAuditTrail: true })
	};

	// PUT > Work
	handleUpdateWorkData = () => {
		if(this.state.langDeleteId.length > 0){
			this.deleteLanguageApi()
		}

		const unMatchedRelationIds = getUnmatchedIds(this.state.work.groupRelationMenus, this.state.staticWork.groupRelationMenus);
		if(unMatchedRelationIds.length > 0){
			this.deleteGroupRelationApi(unMatchedRelationIds)
			return
		}
		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
			people_id: this.state.PeopleId,
		};

		const data = {
			education_degree: this.state.work.education_degree,
			education_stream: this.state.work.education_stream,
			primary_experience: this.state.work.primary_experience,
			primary_year: this.state.work.primary_year,
			secondary_experience: this.state.work.secondary_experience,
			secondary_year: this.state.work.secondary_year,
			region_of_experience: this.state.work.region_of_experience,
			design: this.state.work.design,
			supervision: this.state.work.supervision,
			current_position:
				this.state.work.current_position === 'Others'
					? this.state.work.currentOtherPosition
					: this.state.work.current_position,
			internal_rate: this.state.work.internal_rate,
		};

		const { groupRelationMenus } = this.state.work;

        const updatedWork = {
			...data, 
			currentOtherPosition: this.state.work.currentOtherPosition, 
			current_position: this.state.work.current_position,
			languages: this.state.work.languages,
			group_relation: groupRelationMenus
		}
		const updateWorkErrors = validateAll(peopleWorkValidationSchema, updatedWork)

		if(updateWorkErrors.length > 0) {
			this.setState((prevState)=>{
				return {
					...prevState,
					work:{
						...prevState.work,
						errors: updateWorkErrors
					}
				}
			})
			return;
		}

		const httpBody = {
			work: data,
			role: getStoredDefaultRole(),
			group_relation: groupRelationMenus,
			languages: this.state.work.languages,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		if(!!groupRelationMenus) {
			this.apiForWorkUpdate = requestMessage.messageId;
		} else {
			this.setState({
				hasError:
					"Choose at least one group relation along with at least one field",
			});
		}
		const method = !!this.state.WorkId ? 'PUT' : 'POST';
		const endpoint = method == 'POST' ? `bx_block_people/people/${this.state.PeopleId}/works`
										: `bx_block_people/people/${this.state.PeopleId}/works/${this.state.WorkId}`

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			endpoint
		);

		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			method
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		this.setState({ isAuditTrail: true })
	};

	deleteGroupRelationApi = (Ids: any[]) => {
		const delGroupHeader = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
		};

		const httpGroupBody = {
			ids: Ids,
			role: getStoredDefaultRole(),
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForDeleteGroupRelation = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`bx_block_people/people/${this.state.PeopleId}/works/${this.state.WorkId}/destroy_group_relation`
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(delGroupHeader)
		);
		
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'DELETE'
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpGroupBody)
		);

		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		this.setState({ isAuditTrail: true })
	}


	// POST > Documents
	handleSubmitResume = (newDocument: any) => {

		this.handlePatchUploadDocuments(newDocument);
		
	};

	handleAddBulkDocument = (documents: any[], resume: any) => {
		if (documents.length == 0 && resume?.document_id) {
			return
		}

		const formData = new FormData();
		formData.append("people_id", this.state.PeopleId);

		documents.forEach(document => {
			formData.append("people[other_documents][][file]", document.file)
			formData.append("people[other_documents][][name]", document.document_name)
		});

		formData.append("role", getStoredDefaultRole())
		if (resume && !resume?.document_id) {
			formData.append("people[resume]", resume)
		}
		
		const header = {
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForDocumentUpload = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`/bx_block_people/upload_document`
		);

		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			formData
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'PATCH'
		);


		runEngine.sendMessage(requestMessage.id, requestMessage);
		this.setState({ isAuditTrail: true });
		this.handleChange('documents', 'loading', true);
	}

	handleEditDocumentNameApi = (documentId: number, buttonType?: string) => {
		if(!documentId){
			return
		}
		this.setState({buttonType: buttonType ? buttonType : ""})
		const data = {
			people_id: parseInt(this.state.PeopleId),
			type: 'other',
			document_id: documentId,
			role: getStoredDefaultRole(),
			filename: this.state.editedName
		};
		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForDocumentEditName = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`/bx_block_people/update_document`
		);

		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(data)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'PATCH'
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
		this.handleChange('documents', 'loading', true);
	}

	handleDeleteBulkDocument = (documentIds: number[]) => {
		const data = {
			people_id: parseInt(this.state.PeopleId),
			role: getStoredDefaultRole(),
			type: 'other',
			document_id: documentIds,
		};
		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
		};

		const documentRequestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForDocumentDelete = documentRequestMessage.messageId;

		documentRequestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`/bx_block_people/delete_document`
		);

		documentRequestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);

		documentRequestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		documentRequestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(data)
		);

		documentRequestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'DELETE'
		);

		runEngine.sendMessage(documentRequestMessage.id, documentRequestMessage);
		this.handleChange('documents', 'loading', true);
	}

	handlePatchUploadDocuments = (uploadDocument: Documents) => {
		const documents = Array.from(uploadDocument?.documents) || [];
		
		const addDocuments = documents?.filter(doc => doc.file && !doc?.document_id);
		const existDocument = documents?.map(doc => doc.document_id);
		
		const deletedDocuments = Array.from(this.state.documents.documents).filter(doc => {
			return !existDocument.includes(doc?.document_id)
		})?.map(doc => doc?.document_id || doc?.id);

		if(this.state.documentId){
			this.handleEditDocumentNameApi(Number(this.state.documentId),"save")
		}
		
		if (deletedDocuments.length > 0) {
			this.handleDeleteBulkDocument(deletedDocuments);
		}
		
		if (addDocuments.length > 0 || uploadDocument.resume) {
			this.handleAddBulkDocument(addDocuments, uploadDocument?.resume as File);
		}

		if (deletedDocuments.length == 0 && addDocuments.length == 0) {
			this.handleChange('documents', 'mode', 'read')
			this.setState({editIndex: -1})
		}
		
	}

	// GET > Other current positions
	handleGetOtherCurrentPositions = () => {
		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForOtherCurrentPositions = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			'bx_block_company/current_position?others=others'
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'GET'
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	// GET > DisciplineOptions
	handleGetDisciplineOptions = () => {
		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForDisciplineOptions = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			'bx_block_company/discipline'
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'GET'
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	// GET > Experience Options
	handleGetExperienceOptions = () => {
		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForExperienceOptions = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			'bx_block_company/people_experience'
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'GET'
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	// GET > Year Options
	handleGetYearOptions = () => {
		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
		};

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForYearOptions = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			'bx_block_people/years'
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'GET'
		);

		runEngine.sendMessage(requestMessage.id, requestMessage);
	};


	handleCancelDocumentation = () => {
		this.handleChange('documents', 'addDocument', false);
		this.handleChange('documents', 'mode', 'read');
		this.setState({editIndex: -1})
		// this.documentRef.current?.resetToReadMode(this.state.documents.documents, this.state.documents.resume)
	}

	handleEditDocument = (i:number, document:any) => {		
		this.setState({editIndex:i, editedName: document?.document_name, documentId: document?.document_id})
	}

	handleCBSubmitDocument = () => {
		const { documentEdit, activeSection } = this.state
		const peopleId = this.props.navigation.getParam('people_id');
		this.setState({
			activeSection: documentEdit && this.countableSection.get(activeSection) == 1 ? "Audit trail" : 'Connections',
			documentEdit: false
		})
	}

	handleEditDocumentName = (value: string) => {
		this.setState({
			editedName: value

		})
	}

	handlePeopleRequestNavigation = () =>{
		const {first_name , last_name} = this.state.personalData
		const requestData = {
			id : this.state.PeopleId,
			module : "People",
			recordName : `${first_name} ${last_name}`
		}
		const people = new Message(getName(MessageEnum.NavigationRequestMessage));
		people.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
		people.addData(getName(MessageEnum.RequestData), requestData);
		this.send(people);
	  }

	// Customizable Area End
}
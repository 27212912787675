export type PropertyKey<T extends keyof S> = keyof S[T];

// Customizable Area Start
import React from 'react';
import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
	getName,
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';
import { logoutUser, countSection, handleSectionNavigateLogic, removeErrorsActiveKey } from "../../../components/src/Utils";
import { getStoredDefaultRole } from '../../../components/src/ContextAPI/AdvancedFilterContext.web';
// Customizable Area End

export const configJSON = require('./config');

export interface Props {
	navigation: any;
	id: string;
	// Customizable Area Start
	// Customizable Area End
}

export interface ErrorObject {
	[key: string]: string;
}

type ModuleMode = 'update' | 'read' | 'post';

export interface ITenderData {
	mode: ModuleMode | string;
	tendername: string;
	tenderCode: string;
	tenderType: string;
	groupCompany: string;
	country: string;
	clientName: string;
	client: string;
	services: string;
	sectors: string;
	typeCurrencyLocal: string
	valueOfWorkLocal: string
	ourFeeLocal: string
	valueOfWorkRangsUsd: string
	valueOfWorkUsd: string
	ourFeeUsd: string
	status: string;
	date: string;
	isCancelButtonEnabled: boolean;
	errors: ErrorObject[];
}

export interface IAuditTrail {
	mode: ModuleMode;
}

export interface IAuditTrailSteps {
	label: string;
	description: string;
	date: string;
	time: string;
}

export interface DropDownItems {
	label: string;
	value: string;
}
export interface AwardLetters {
	award_letter_id: number;
	file_name: string;
	award_letter_url: string;
	upload_date: string;
}
export interface S {
	// Customizable Area Start
	hasError: any;
	loggedInUserFullName: string | null;
	activeSection: string;
	tenderId: string;
	tenderDetails: ITenderData;
	staticTenderDetails: ITenderData;
	deleteError: boolean;
	auditTrail: IAuditTrail;
	activeStep: number;
	isLoading: boolean;
	TenderError: boolean;
	isAuditTrail: boolean;
	groupResponse: DropDownItems[];
	countryresponse: DropDownItems[];
	tenderTypeResponse: DropDownItems[];
	clientResponse: DropDownItems[];
	servicesResponse: DropDownItems[];
	sectorsResponse: DropDownItems[];
	ValueOfWorksResponse: DropDownItems[];
	statusResponse: DropDownItems[];
	typeCurrencyResponse: DropDownItems[];
	companyDetailsId: string;
	mode: string | "get" | "post" | "put";
	AwardLetters: AwardLetters[];
	staticAwardLetters: AwardLetters[];
	loadingAwardLetter: boolean;
	errors: ErrorObject[];
	awardLettersToDelete: number[];
	onAwardLetterUpload: boolean;
	awardLettersToUpload: File[];
	isPendingApi: boolean;
	isCancelAwardEnabled: boolean;
	documentError: string;
	// Customizable Area End
}

interface SS {
	// Customizable Area Start
	id: any;
	// Customizable Area End
}

export default class TenderModuleController extends BlockComponent<
	Props,
	S,
	SS
> {
	// Customizable Area Start
	apiForTenderDetailsPost = '';
	apiForTenderDetailsUpdate = '';
	apiForTenderShow: string = '';
	getClientApiCallId: string = '';
	getGroupApiCallId: string = '';
	getCountriesApiCallId: string = '';
	getSectorsCallId: string = '';
	getAllServicesApiCallId: string = '';
	getValueApiCallId: string = '';
	getStatusApiCallId: string = '';
	getTenderTypeApiCallId: string = '';
	getTypeCurrencyApiCallId: string = '';
	apiCallIdForUploadAwardLetter: string = "";
	apiCallIdForRemoveDocument: string = ""
	countableSection = countSection(configJSON.SupplierModuleSections);
	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);
		// Customizable Area Start
		this.subScribedMessages = [
			getName(MessageEnum.CountryCodeMessage),
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.ReciveUserCredentials),
		];

		this.state = {
			isLoading: true,
			hasError: '',
			loggedInUserFullName: null,
			activeSection: 'Tender Details',
			tenderId: '',
			deleteError: false,
			activeStep: 0,
			TenderError: false,
			tenderDetails: {
				mode: 'post',
				tendername: '',
				tenderCode: '',
				tenderType: '',
				groupCompany: '',
				country: '',
				clientName: '',
				client: '',
				services: '',
				date: '',
				sectors: '',
				typeCurrencyLocal: '',
				valueOfWorkLocal: '',
				ourFeeLocal: '',
				valueOfWorkRangsUsd: '',
				valueOfWorkUsd: '',
				ourFeeUsd: '',
				status: '',
				isCancelButtonEnabled: false,
				errors: [],
			},
			staticTenderDetails: {
				mode: 'read',
				tendername: '',
				tenderCode: '',
				tenderType: '',
				groupCompany: '',
				country: '',
				clientName: '',
				client: '',
				services: '',
				date: '',
				sectors: '',
				typeCurrencyLocal: '',
				valueOfWorkLocal: '',
				ourFeeLocal: '',
				valueOfWorkRangsUsd: '',
				valueOfWorkUsd: '',
				ourFeeUsd: '',
				status: '',
				isCancelButtonEnabled: false,
				errors: [],
			},
			auditTrail: {
				mode: 'post',
			},
			isAuditTrail: false,
			groupResponse: [],
			countryresponse: [],
			tenderTypeResponse: [],
			clientResponse: [],
			servicesResponse: [],
			sectorsResponse: [],
			ValueOfWorksResponse: [],
			statusResponse: [],
			typeCurrencyResponse: [],
			companyDetailsId: '',
			mode: configJSON.newRecordMode,
			AwardLetters: [],
			staticAwardLetters: [],
			loadingAwardLetter: false,
			errors: [],
			awardLettersToDelete: [],
			onAwardLetterUpload: false,
			awardLettersToUpload: [],
			isPendingApi: false,
			isCancelAwardEnabled: false,
			documentError: ""
		};
		// @ts-ignore
		this.sections = [
			'Tender Details',
			'Award Letter',
			'Connections',
			'Audit trail',
		];

		// @ts-ignore
		this.contentRef = React.createRef();
		// Customizable Area End

		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
	}

	async componentDidMount() {
		const { navigation } = this.props;
		if (navigation?.getParam('tender_id') !== 'add') {
			await Promise.all([
				this.handleGetTenderDetails(),
			]);
		}
		this.handleAPICollection()
		this.setState({ isLoading: false })
		this.send(new Message(getName(MessageEnum.RequestUserCredentials)));

		// Customizable Area Start
		// Customizable Area End
	}

	// Left sidebar ListItem click
	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
		if (prevState.activeSection != this.state.activeSection) {
			this.countableSection.setActive(this.state.activeSection)
		}
	}

	// ALL GET API FOR DROPDOWNS
	handleAPICollection = () => {
		this.getClientApiCallId = this.dropDownGetApiCall(
			configJSON.getClientsEndPoint
		);
		this.getGroupApiCallId = this.dropDownGetApiCall(
			configJSON.getGroupCompaniesEndPoint
		);
		this.getCountriesApiCallId = this.dropDownGetApiCall(
			configJSON.getCountriesEndPoint
		);
		this.getSectorsCallId = this.dropDownGetApiCall(
			configJSON.getSectorsEndPoint
		);
		this.getAllServicesApiCallId = this.dropDownGetApiCall(
			configJSON.getServicesEndPoint
		);
		this.getValueApiCallId = this.dropDownGetApiCall(
			configJSON.getValuesOfWorkEndPoint
		);
		this.getStatusApiCallId = this.dropDownGetApiCall(
			configJSON.getTenderStatusOptionsEndPoint
		);
		this.getTenderTypeApiCallId = this.dropDownGetApiCall(
			configJSON.getTenderTypeOptionsEndPoint
		);
		this.getTypeCurrencyApiCallId = this.dropDownGetApiCall(
			configJSON.getTypesOfCurrencyEndPoint
		);
	};
	dropDownGetApiCall = (url: string) => {
		const header = {
			"Content-Type": "application/json",
			token:
				typeof window !== "undefined"
					? localStorage.getItem("authToken")
					: null,
		};

		const apiRequestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		apiRequestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			url
		);


		apiRequestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		apiRequestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			configJSON.validationApiMethodType
		);

		runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
		return apiRequestMessage.messageId;
	};

	getGroupCompanyResponse = (grpRes: any) => {
		if (Array.isArray(grpRes)) {
			const groupCompanies: any = [];
			grpRes.forEach((service: any) => {
				groupCompanies.push({
					value: service.id.toString(),
					label: service.name,
				});
			});
			this.setState({ groupResponse: groupCompanies })
		} else {
			this.handleError(
				"Tender Group Companies not consumed properly. Check network logs"
			);
		}
	};
	getClientResponse = (cliRes: any) => {
		if (Array.isArray(cliRes)) {
			const clients: any = [];
			cliRes.forEach((service: any) => {
				clients.push({
					value: service.id.toString(),
					label: service.name,
				});
			});
			this.setState({ clientResponse: clients })
		} else {
			this.handleError(
				"Tender Clients not consumed properly. Check network logs"
			);
		}
	};

	getCountryResponse = (cntRes: any) => {
		if (Array.isArray(cntRes)) {
			const countries: any = [];
			cntRes.forEach((service: any) => {
				countries.push({
					value: service.id.toString(),
					label: service.name,
				});
			});
			this.setState({ countryresponse: countries })
		} else {
			this.handleError(
				"Tender Countries not consumed properly. Check network logs"
			);
		}
	};

	getSectorsResponse = (sectorRes: any) => {
		if (Array.isArray(sectorRes)) {
			const sectors: any = [];
			sectorRes.forEach((sector: any) => {
				sectors.push({
					value: sector.id.toString(),
					label: sector.name,
				});
			});
			this.setState({ sectorsResponse: sectors })
		} else {
			this.handleError(
				"Tender Sectors not consumed properly. Check network logs"
			);
		}
	};

	getServicesResponse = (srvRes: any) => {
		if (Array.isArray(srvRes)) {
			const services: any = [];
			srvRes.forEach((service: any) => {
				services.push({
					value: service.id.toString(),
					label: service.name,
				});
			});
			this.setState({ servicesResponse: services })
		} else {
			this.handleError(
				"Tender Services not consumed properly. Check network logs"
			);
		}
	};

	getValueOfWorkResponse = (vlwRes: any) => {
		if (Array.isArray(vlwRes)) {
			const valuesOfWork: any = [];
			vlwRes.forEach((service: any) => {
				valuesOfWork.push({
					value: service.id.toString(),
					label: service.value_of_work,
				});
			});
			this.setState({ ValueOfWorksResponse: valuesOfWork })
		} else {
			this.handleError(
				"Tender Values of work not consumed properly. Check network logs"
			);
		}
	};

	getTenderTypeResponse = (typeResponse: any) => {
		if (Array.isArray(typeResponse)) {
			const tenderType: any = [];
			typeResponse.forEach((type: any) => {
				tenderType.push({
					value: type.id.toString(),
					label: type.name,
				});
			});
			this.setState({ tenderTypeResponse: tenderType })
		} else {
			this.handleError(
				"Tender Values of work not consumed properly. Check network logs"
			);
		}
	};

	getStatusResponse = (statusRes: any) => {
		if (Array.isArray(statusRes)) {
			const statusOptions: any = [];
			statusRes.forEach((service: any) => {
				statusOptions.push({
					value: service.id.toString(),
					label: service.status,
				});
			});
			this.setState({ statusResponse: statusOptions })
		} else {
			this.handleError(
				"Tender status options not consumed properly. Check network logs"
			);
		}

	};

	getTypeCurrencyResponse = (typeCurrencyRes: any) => {
		if (Array.isArray(typeCurrencyRes)) {
			const typeCurrencyOptions: any = [];
			typeCurrencyRes.forEach((service: any) => {
				typeCurrencyOptions.push({
					value: service.id.toString(),
					label: service.currency_type,
				});
			});
			this.setState({ typeCurrencyResponse: typeCurrencyOptions })
		} else {
			this.handleError(
				"Tender status options not consumed properly. Check network logs"
			);
		}

	};
	getTenderResponse = (tenderRes: any) => {
		const tenderDetails = tenderRes.data.attributes
		const companyDetails = tenderDetails.company_detail.data.attributes
		const serviceData = companyDetails.services.data
		const services = serviceData
			.map((service: any) => service.attributes.name)
			.join(",");
		const awardData = tenderDetails.award_letters? tenderDetails.award_letters :[]


		const tenderData = {
			mode: 'read',
			tenderType: tenderDetails.tender_type,
			tendername: tenderDetails.name,
			tenderCode: tenderDetails.tender_code,
			country: companyDetails.country,
			groupCompany: companyDetails.group_company,
			client: companyDetails.client,
			clientName: companyDetails.client_name,
			date: tenderDetails.tender_date,
			services: services,
			typeCurrencyLocal: companyDetails.type_of_currency,
			valueOfWorkLocal: companyDetails.value_of_work,
			valueOfWorkRangsUsd: companyDetails.value_of_work_range,
			ourFeeLocal: companyDetails.currency_value,
			valueOfWorkUsd: companyDetails.value_of_work_usd,
			ourFeeUsd: companyDetails.fee,
			sectors: companyDetails?.sector?.name,
			isCancelButtonEnabled: true,
			errors: [],
			status: tenderDetails.tender_status,
		}

		this.setState({
			staticTenderDetails: tenderData,
			tenderDetails: tenderData,
			companyDetailsId: companyDetails.id,
			tenderId: tenderRes.data.id,
			isCancelAwardEnabled: true,
			isPendingApi: false,
			staticAwardLetters:  awardData,
			AwardLetters: tenderDetails.award_letters? tenderDetails.award_letters :[],
			mode: "get"
		});

		this.handleSwitchMode('tenderDetails', 'read');


	}
	handleListItemClick = (section: any) => {
		switch (section) {
			case 'Tender Details':
				this.setState({ activeSection: 'Tender Details' });
				break;
			case 'Award Letter':
				this.setState({ activeSection: 'Award Letter' });
				break;
			case 'Connections':
				this.setState({ activeSection: 'Connections' });
				break;
			case 'Audit trail':
				this.setState({ activeSection: 'Audit trail' });
				break;
		}
	}

	//  -- Tender Details - POST RESPONSE
	handleTenderDetailsPostResponse = (responseJson: any) => {
		this.setState({ isAuditTrail: false })
		if (responseJson.error) {
			this.setState({
				hasError: responseJson.error
			})
			return
		}
		if (responseJson.errors && responseJson.errors.length > 0) {
			this.setState({
				tenderDetails: {
					...this.state.tenderDetails,
					errors: responseJson.errors,
				},
			});
		} else {
			const data = responseJson.data.attributes
			const companyDetails = data.company_detail.data.attributes
			const serviceData = companyDetails.services.data
			const services = serviceData
				.map((service: any) => service.attributes.name)
				.join(",");


			const tenderData = {
				mode: 'read',
				tendername: data.name,
				tenderCode: data.tender_code,
				groupCompany: companyDetails.group_company,
				tenderType: data.tender_type,
				clientName: companyDetails.client_name,
				country: companyDetails.country,
				client: companyDetails.client,
				date: data.tender_date,
				services: services,
				isCancelButtonEnabled: true,
				typeCurrencyLocal: companyDetails.type_of_currency,
				valueOfWorkLocal: companyDetails.value_of_work,
				valueOfWorkRangsUsd: companyDetails.value_of_work_range,
				ourFeeLocal: companyDetails.currency_value,
				valueOfWorkUsd: companyDetails.value_of_work_usd,
				ourFeeUsd: companyDetails.fee,
				sectors: companyDetails?.sector?.name,
				status: data.tender_status,
				errors: [],
			}
			this.setState({
				companyDetailsId: companyDetails.id,
				staticTenderDetails: tenderData,
				tenderDetails: tenderData,
				tenderId: responseJson.data.id,
				activeSection: "Award Letter"
			});

			this.handleSwitchMode('tenderDetails', 'read');
		}
	};


	//  -- Tender Details - UPDATE RESPONSE
	handleTenderDetailsUpdateResponse = (responseJson: any) => {
		this.setState({ isAuditTrail: false })
		if (responseJson.error) {
			this.setState({
				hasError: responseJson.error
			})
		} else if (responseJson.errors && responseJson.errors.length > 0) {
			this.setState({
				tenderDetails: {
					...this.state.tenderDetails,
					errors: responseJson.errors,
				},
			})
		} else {
			const data = responseJson.data.attributes
			const companyDetails = data.company_detail.data.attributes
			const serviceData = companyDetails.services.data
			const services = serviceData
				.map((service: any) => service.attributes.name)
				.join(",");

			const updateData = {
				mode: 'read',
				tendername: data.name,
				tenderCode: data.tender_code,
				tenderType: data.tender_type,
				groupCompany: companyDetails.group_company,
				country: companyDetails.country,
				clientName: companyDetails.client_name,
				client: companyDetails.client,
				services: services,
				date: data.tender_date,
				typeCurrencyLocal: companyDetails.type_of_currency,
				valueOfWorkLocal: companyDetails.value_of_work,
				valueOfWorkRangsUsd: companyDetails.value_of_work_range,
				ourFeeLocal: companyDetails.currency_value,
				valueOfWorkUsd: companyDetails.value_of_work_usd,
				ourFeeUsd: companyDetails.fee,
				sectors: companyDetails?.sector?.name,
				status: data.tender_status,
				isCancelButtonEnabled: true,
				errors: [],
			}
			this.setState({
				tenderDetails: updateData,
				staticTenderDetails: updateData
			});
			this.handleSwitchMode('tenderDetails', 'read');
		}
	};

	// ----- RECIEVE DATA RESPONSE FROM APIS -----
	async receive(from: string, message: Message) {
		// Customizable Area Start

		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			this.setState({ isLoading: false })
			const apiRequestCallId = message.getData(
				getName(MessageEnum.RestAPIResponceDataMessage)
			);

			const responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			// Error in case of deactivated account or expired token
			this.handleDeactiveAccountError(responseJson)

			this.handleNotFoundRecord(responseJson)

			this.apiSuccessCallBackController(apiRequestCallId, responseJson)
			
			// getTenderDetails
			this.handleApiError(responseJson,apiRequestCallId)

		}
		// Customizable Area End
	}

	// Customizable Area Start

	handleDeactiveAccountError = async (responseJson: any) => {
		if (responseJson
			&& responseJson.errors
			&& (responseJson.errors.includes(configJSON.deactivatedUserError)
				|| responseJson.errors.includes(configJSON.expiredAuthTokenError)
			)) {
			await logoutUser(this.props, responseJson.errors[0])
		}
	}

	handleApiError = (responseJson: any, apiRequestCallId: string) => {
		if (responseJson && apiRequestCallId === this.apiForTenderShow) {
			this.setState({ isLoading: false })
			if (responseJson.errors || responseJson.error) {
				let errorMessage: string = '';
				if (responseJson.errors.length > 0) {
					errorMessage = responseJson.errors.join('.')
				}
				if (responseJson.error) {
					errorMessage = responseJson.error
				}
				this.setState(
					{
						hasError: errorMessage ? errorMessage : 'Error',
					},
					() => {
						this.props.navigation.push("/Tender/add");
					}
				);
			}
		}
	}

	handleNotFoundRecord = (responseJson: any) => {
		if (responseJson?.meta?.message && !responseJson?.data) {
			this.setState({ isLoading: false })
			let errorMessage: string = responseJson.meta.message;
			  
			this.setState(
				{
					hasError: errorMessage ? errorMessage : 'Error',
				},
				() => {
					this.props.navigation.push("/Tender/add");
				}
			);

		}
	}

	apiSuccessCallBackController = (
		apiRequestCallId: string,
		responseJson: any
	) => {
		const successCallbackMap = {
			[this.apiForTenderDetailsPost]: this.handleTenderDetailsPostResponse,
			[this.apiForTenderDetailsUpdate]: this.handleTenderDetailsUpdateResponse,
			[this.getStatusApiCallId]: this.getStatusResponse,
			[this.getGroupApiCallId]: this.getGroupCompanyResponse,
			[this.getClientApiCallId]: this.getClientResponse,
			[this.getSectorsCallId]: this.getSectorsResponse,
			[this.getCountriesApiCallId]: this.getCountryResponse,
			[this.getAllServicesApiCallId]: this.getServicesResponse,
			[this.getValueApiCallId]: this.getValueOfWorkResponse,
			[this.getTenderTypeApiCallId]: this.getTenderTypeResponse,
			[this.getTypeCurrencyApiCallId]: this.getTypeCurrencyResponse,
			[this.apiCallIdForUploadAwardLetter]: (responseJson: any) => {
				const tenderId = this.props.navigation.getParam('tender_id');
				if (tenderId == 'add' && !this.countableSection.actived("Connections")) {
					this.awardLettersApiResponse(responseJson);
				} else {
					this.handleGetTenderDetails();
					this.handleChangeAward("onAwardLetterUpload", false)
				}
			},
			[this.apiCallIdForRemoveDocument]: this.awardLettersApiResponse,
			[this.apiForTenderShow]: this.getTenderResponse

		};

		if (apiRequestCallId) {
			const successCallback: ((responseJson: any) => void) = successCallbackMap[apiRequestCallId];
			!!successCallback && successCallback(responseJson);
		}
	};

	handleError = (errorMessage: string) => {
		this.setState({
			hasError: errorMessage
		})
	}

	handleGoBack = () => {
	   const { navigation } = this.props;
	   navigation.navigate("TenderModuleTable");
	};

	// Handle change the states of Tender Details
	handleChangeTenderData = (value: any, key: string) => {
			const newErrors = removeErrorsActiveKey(this.state.tenderDetails.errors, key, {
				tendername: "name",
				tenderCode: "tender_code",
				date: "tender_date",
			})
			
			this.setState({
				tenderDetails: { ...this.state.tenderDetails, [key]: value, errors: newErrors },
			});
	};

	handleChange = (objectKey: keyof S, property: any, value: any | null) => {
		this.setState((prevState: S) => {
			const currentPart = prevState[objectKey];
			// Return the state with the updated object
			const updatedObject = {
				...currentPart,
				[property]: value,
			};
			return {
				...prevState,
				[objectKey]: updatedObject,
			};
		});
	};

	// Switch the mode of the container
	handleSwitchMode = (section: string, mode: ModuleMode, onCancel?: boolean) => {
		this.setState((prevState) => ({
			tenderDetails: { ...(onCancel ? prevState.staticTenderDetails : prevState.tenderDetails), mode },
		}))
	};

	// ------------------- API CALLS -------------------

	// --------- TENDER DETAILS ---------
	// POST > Tender Details
	handlePosTenderDetails = () => {
		const { tenderDetails, sectorsResponse, servicesResponse, statusResponse, tenderTypeResponse } = this.state
		const { sectors, services, status, tenderType } = this.state.tenderDetails
		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
		};

		const sector_id = sectorsResponse.find((rec) => rec.label === sectors)?.value;
		const tenderType_id = tenderTypeResponse.find((rec) => rec.label === tenderType)?.value;
		const status_id = statusResponse.find((rec) => rec.label === status)?.value;
		const service_ids: number[] = [];
		services?.split(",").forEach((rec) => {
			const service = servicesResponse.find((service) => service.label === rec);
			if (service) {
				service_ids.push(parseInt(service.value));
			}
		});
		const ourFee = tenderDetails.ourFeeLocal.replace(configJSON.actualValue, "");
		const valueOfWork = tenderDetails.valueOfWorkLocal.replace(configJSON.actualValue, "");
		
		const data = {
			tender_date: tenderDetails.date,
			tender_code: tenderDetails.tenderCode,
			name: tenderDetails.tendername,
			tender_status_id: Number(status_id),
			tender_type_id: Number(tenderType_id),
			company_detail_attributes: {
				country: tenderDetails.country,
				client: tenderDetails.client,
				group_company: tenderDetails.groupCompany,
				client_name: tenderDetails.clientName,
				currency_value: ourFee,
				sector_id: Number(sector_id),
				service_ids: service_ids,
				value_of_work: valueOfWork,
				type_of_currency: tenderDetails.typeCurrencyLocal,
			}
		};

		const apiRequestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		const httpPostBody = {
			tender: data,
			role: getStoredDefaultRole()
		};

		this.apiForTenderDetailsPost = apiRequestMessage.messageId;

		
		apiRequestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);
			
		apiRequestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			'bx_block_profile/tenders'
		);

		apiRequestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpPostBody)
		);

		apiRequestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);


		apiRequestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'POST'
		);

		runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
		this.setState({ isAuditTrail: true })
	};

	// PUT > Tender Details
	handleUpdateTenderDetails = () => {
		const { tenderDetails, sectorsResponse, servicesResponse, statusResponse, tenderTypeResponse, tenderId, companyDetailsId } = this.state
		const { sectors, services, status, tenderType } = this.state.tenderDetails
		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
			people_id: this.state.tenderId,
		};

		const sector_id = sectorsResponse.find((rec) => rec.label === sectors)?.value;
		const status_id = statusResponse.find((rec) => rec.label === status)?.value;
		const tenderType_id = tenderTypeResponse.find((rec) => rec.label === tenderType)?.value;
		const service_ids: number[] = [];
		services.split(",").forEach((rec) => {
			const service = servicesResponse.find((service) => service.label === rec);
			if (service) {
				service_ids.push(parseInt(service.value));
			}
		});
		const ourFee = tenderDetails?.ourFeeLocal?.toString().replace(configJSON.actualValue, "");
		const valueOfWork = tenderDetails?.valueOfWorkLocal?.toString().replace(configJSON.actualValue, "");
		const data = {
			id: tenderId,
			tender_code: tenderDetails.tenderCode,
			tender_type_id: Number(tenderType_id),
			tender_date: tenderDetails.date,
			name: tenderDetails.tendername,
			tender_status_id: Number(status_id),
			company_detail_attributes: {
				id: companyDetailsId,
				group_company: tenderDetails.groupCompany,
				country: tenderDetails.country,
				client: tenderDetails.client,
				client_name: tenderDetails.clientName,
				sector_id: Number(sector_id),
				type_of_currency: tenderDetails.typeCurrencyLocal,
				currency_value: ourFee,
				service_ids: service_ids,
				value_of_work: valueOfWork,
			}
		};

		const httpBody = {
			tender: data,
			role: getStoredDefaultRole()
		};


		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForTenderDetailsUpdate = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`bx_block_profile/tenders/${tenderId}`
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);

		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			JSON.stringify(httpBody)
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'PATCH'
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
		this.setState({ isAuditTrail: true })
	};

	// GET > Tender Details
	handleGetTenderDetails = () => {
		const tenderId = this.state.tenderId || this.props.navigation.getParam('tender_id');

		const header = {
			'Content-Type': 'application/json',
			token:
				typeof window !== 'undefined'
					? localStorage.getItem('authToken')
					: null,
			tender_id: tenderId,
		};
		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);

		this.apiForTenderShow = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			`bx_block_profile/tenders/${tenderId}`
		);
		requestMessage.addData(
			getName(MessageEnum.NavigationPropsMessage),
			this.props
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			'GET'
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	};

	handleDeleteAwardLetter = (attachment_id: number, type: string) => {
		const { awardLettersToDelete, AwardLetters } =
			this.state;
		awardLettersToDelete.push(attachment_id);
		const newCerts = AwardLetters.filter(
			(cert) => cert.award_letter_id !== attachment_id
		);
		this.setState({
			awardLettersToDelete,
			AwardLetters: newCerts,
		});
	};

	awardLettersApiResponse = (responseJson: any, appCallId?: string) => {
		this.setState({ isAuditTrail: false })
		let id = this.props.navigation.getParam("tender_id");
		if (responseJson === null || responseJson === undefined) {
			this.handleError("Backend error. please contact with administrator");
			this.setState({
				isPendingApi: false,
				isLoading: false,
			});
			return;
		}
		const { errors, data, error } = responseJson;

		if (error && typeof error === "string") {
			this.handleError(error);
			this.handleChangeAward("isPendingApi", false);
			return;
		}

		if (errors && Array.isArray(errors)) {
			this.handleBackendValidationErrors(errors)
			return;
		}

		if (data && data.attributes && !errors) {
			const tenderDetails = data.attributes;
			const award = JSON.parse(JSON.stringify(tenderDetails.award_letters))
			this.setState((prevState) => {
				return {
					...prevState,
					AwardLetters: tenderDetails.award_letters,
					staticAwardLetters: award,
					activeSection: prevState.isCancelAwardEnabled ? prevState.activeSection : "Connections",
					isCancelAwardEnabled: true,
					mode: handleSectionNavigateLogic(id, !this.countableSection.actived("Connections"), "post", "get")
				};
			});
			this.handleChangeAward("onAwardLetterUpload", false)
		}
		if (responseJson.message === 'Award letter is deleted') {
			this.setState({
				mode: handleSectionNavigateLogic(id, !this.countableSection.actived("Connections"), "post", "get")
			})
		}
		this.setState({
			isPendingApi: false,
			isLoading: false,
			isCancelAwardEnabled: true,
			mode: 'get'
		});
	};
	handleChangeAward = (changedPropertyName: keyof S, changedValue: any | null) => {
		const { errors } = this.state;
		if (changedPropertyName) {
			this.setState((prevState: S) => {
				return {
					...prevState,
					[changedPropertyName]: changedValue,
				};
			});
			if (errors.find((error) => error.hasOwnProperty(changedPropertyName))) {
				this.setState({
					errors: errors.filter(
						(error) => !error.hasOwnProperty(changedPropertyName)
					),
				});
			}
		}
	}

	handleBackendValidationErrors = (errors: any[]) => {
		const backendValidationErrors: ErrorObject[] = [];
		errors.forEach((error) => {
			const errorCode = Object.keys(error)[0].replace("company_detail.", "");
			const errorLabel = Object.values(error)[0] as string;
			if (errorCode === 'code') {
				backendValidationErrors.push({ tender_code: errorLabel });
			} else {
				backendValidationErrors.push({
					[errorCode]: errorLabel,
				});
			this.setState({documentError: errorLabel})
			}
		});
		this.handleChangeAward("errors", backendValidationErrors);
		this.handleChangeAward("isPendingApi", false);
	}
	handleCancelButton = () => {
			this.setState((prevState) => {
				return {
					...prevState,
					onAwardLetterUpload: false,
					awardLettersToUpload: [],
					awardLettersToDelete: [],
					AwardLetters: [],
				};
			});
		this.handleGetTenderDetails()
		this.handleChangeAward("mode", configJSON.viewRecordMode);
	};

	handleSaveButton = async (): Promise<void> => {
		const { awardLettersToUpload, awardLettersToDelete } = this.state;
		let id = this.props.navigation.getParam("tender_id");
		if(awardLettersToUpload.length === 0 && awardLettersToDelete.length === 0){
			this.setState({activeSection: handleSectionNavigateLogic(id, !this.countableSection.actived("Connections"), "Connections", "Award Letter") })
		    this.handleChangeAward("mode", handleSectionNavigateLogic(id, !this.countableSection.actived("Connections"), "post", "get"))
		}

		await this.handleAwardLetterSection(awardLettersToUpload, awardLettersToDelete);
	
	};

	handleCBSubmitDocument = () => {
		const tenderId = this.props.navigation.getParam('tender_id');
		const { activeSection } = this.state;
		this.setState({
			activeSection: handleSectionNavigateLogic(tenderId, this.countableSection.get(activeSection) == 1, "Audit trail", "Connections")
		})
	}

	handleAwardLetterSection = async (awardLettersToUpload: any[], awardLettersToDelete: any[]): Promise<void> => {
		await this.handleUploadAndDelete(awardLettersToUpload, awardLettersToDelete, 'AwardLetter', 'onAwardLetterUpload');
		this.handleChangeAward("awardLettersToUpload", []);
		this.handleChangeAward("awardLettersToDelete", []);
	};

	handleFileChange = (event: any) => {
		const file = event.target.files ? event.target.files[0] : null;
		if (file) {
			if (file.size > 10 * 1024 * 1024) {
				this.setState({documentError: configJSON.bigFileErrorMessage})
			} else {
				const filesToUpload = this.state.awardLettersToUpload;
				filesToUpload.push(file);
				this.handleChangeAward("awardLettersToUpload", filesToUpload);
				this.setState({documentError: ''})
			}
			event.target.value = "";
		} else {
			this.handleError(configJSON.fileErrorMessage);
		}
	};
	openImage = (imageUrl: string) => {
		window.open(imageUrl, "_blank");
	};
	removeStagedFile = (fileIndex: number) => {
		
				const { awardLettersToUpload } = this.state;
				const newAwardLetters = awardLettersToUpload.filter(
					(element, index) => index !== fileIndex
				);
				this.handleChangeAward("awardLettersToUpload", newAwardLetters);
	
	};
	handleUploadAndDelete = async (uploads: any[], deletes: any[], type: 'AwardLetter' | 'image', state: keyof S): Promise<void> => {
		this.handleChangeAward("isPendingApi", true);
		if (deletes.length > 0) {
			await this.sendDeleteAwardLetterRequests(deletes, type);
		}

		for (const item of uploads) {
			await this.uploadAwardLetterRequest(item);
		}
		
	};
	uploadAwardLetterRequest = async (file: File) => {
		this.setState({ isAuditTrail: true })
		await new Promise(async (resolve, reject) => {
			const tenderId = this.state.tenderId;
			if (tenderId === "") {
				this.handleError("tender id not found");
				return;
			}

			const formData = new FormData();
			const activeSection = this.state.activeSection;
			if (activeSection === configJSON.TenderModuleSections[1]) {
					formData.append("award_letters[]", file);
			}
			formData.append("role", getStoredDefaultRole());

			const header = {
				token:
					typeof window !== "undefined"
						? localStorage.getItem("authToken")
						: null,
			};
			const requestMessage = new Message(
				getName(MessageEnum.RestAPIRequestMessage)
			);

			this.apiCallIdForUploadAwardLetter = requestMessage.messageId;
			let uploadUrl = `/bx_block_profile/tenders/${tenderId}/upload_award_letter`;
			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				uploadUrl
			);
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestHeaderMessage),
				JSON.stringify(header)
			);
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestBodyMessage),
				formData
			);
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.apiPatchMethod
			);
			runEngine.sendMessage(requestMessage.id, requestMessage);

			setTimeout(() => {
				resolve(true);
			}, 300);
		});
	};
	handleEditButtonClick = () => {
		this.handleChangeAward("mode", configJSON.updateRecordMode);
		this.setState({ mode: configJSON.updateRecordMode, isCancelAwardEnabled: true })
	};
	sendDeleteAwardLetterRequests = async (array: number[], type: string) => {
		await new Promise(async (resolve, reject) => {
			this.handleChangeAward("isPendingApi", true);
			this.setState({ isAuditTrail: true })
			const { tenderId } = this.state;

			const header = {
				"Content-Type": "application/json",
				token:
					typeof window !== "undefined"
						? localStorage.getItem("authToken")
						: null,
			};
			const url = `${configJSON.deleteAwardLetterEndPoint}/${tenderId}/delete_award_letter`;

			const requestMessage = new Message(
				getName(MessageEnum.RestAPIRequestMessage)
			);
			this.apiCallIdForRemoveDocument = requestMessage.messageId;
			requestMessage.addData(
				getName(MessageEnum.RestAPIResponceEndPointMessage),
				url
			);
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestMethodMessage),
				configJSON.apiDeleteMethod
			);
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestHeaderMessage),
				JSON.stringify(header)
			);
			requestMessage.addData(
				getName(MessageEnum.RestAPIRequestBodyMessage),
				JSON.stringify({ 
					attachment_id: array ,
					role: getStoredDefaultRole()
				})
			);
			runEngine.sendMessage(requestMessage.id, requestMessage);
			resolve(true);
		});
	};

	handleTenderRequestNavigation = () =>{
		const requestData = {
			id : this.state.tenderId,
			module : "Tender",
			recordName : this.state.tenderDetails.tendername
		}
		const tender = new Message(getName(MessageEnum.NavigationRequestMessage));
		tender.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
		tender.addData(getName(MessageEnum.RequestData), requestData);
		this.send(tender);
	  }
	
	// Customizable Area End
}
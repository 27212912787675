function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const MappingTableHeaderLabels = (label: string) => {
    switch (label) {
        case 'current_position':
            return 'Current position'
        case 'roe':
            return 'Country'
        case 'primary_year':
        case 'secondary_year':
            return 'Years'
        case 'group_relation':
            return 'Group relation'
        case 'internal_rate':
            return 'Internal rate'
        default:
            return capitalizeFirstLetter(label);
    }
}

export const MappingTableHeaderTypes = (label: string) => {
    switch (label) {
        case 'name':
            return 'link';
        case 'icon':
            return 'icon';
        case 'design':
        case 'supervision':
            return 'boolean'
        case 'projects':
        case 'tenders': 
            return 'connection'
        default:
            return 'value'
    }
}

export const MappingConnections = (label: string) => {
  switch (label) {
    case 'projects':
      return {nameKey: 'project_name', idKey: 'id', path: '/Projects' }
    case 'tenders': 
      return {nameKey: 'name', idKey: 'id', path: '/Tender' }
    default:
      return null
  }
}

export const MappingTableCellWidth = (label: string) => {
  switch (label) {
    case 'icon':
      return 'fit-content';
    
    case 'projects':
    case 'tenders': 
      return '270px'
    default:
        return '90px'
  }
}
export const MappingTableHeaderSortables = (label: string,column:string,sortOrder:string)=> {
    switch (label) {
        case "projects":
        case "tenders": {
          
          return {isSortable : false , sortOrder : label ===column ? sortOrder:'ASC'}
        }
        // case 'current_position':
        //     return {isSortable :false }
        default:
            return {isSortable :true , sortOrder : label ===column ? sortOrder:'ASC'}
    }
    
}

export const MappingFilterNameForPayload = (label: string) => {
  switch (label) {
    case "Level":
      return "education_degree";
    case "Discipline":
      return "education_discipline";
    case "Position":
      return "current_position";
    case "Primary":
      return "primary";
    case "Secondary":
      return "secondary";
    case "NATIONALITY":
      return "nationality";
    case "LANGUAGE":
      return "language";
    case "Currently Employed":
      return "[Currently Employed]";
    case "Past Employed":
      return "[Past Employed]"
    case "Submitted for Tender":
      return "[Submitted for tender]"
    case "FAVOURITES":
      return "favourite"
    default:
      return label;
  }
};

export const MappingColumnNameForSortingPayload = (label: string) => {
  switch (label) {
    case "name":
      return "first_name";
    case "level":
      return "education_degree";
    case "discipline":
      return "education_stream";
    case "primary":
      return "primary_experience";
    case "secondary":
      return "secondary_experience";
    case "year":
      return "region_of_experience";
    case "design":
      return "design";
    case "supervision":
      return "supervision";

    default:
      return label.toLowerCase();
  }
};

export const MapingResponseForTableData = (responseData: any) => {
  let apiResponse = {...responseData};
  if(apiResponse.results?.data) { // for search
    apiResponse.data = [...apiResponse.results.data];
    delete apiResponse.results;
  } else if (apiResponse.data?.data) { // normal view or filter
    apiResponse.data = [...apiResponse.data.data];
    // delete apiResponse.data;
  }
  return apiResponse;
}

export const mappingFilterSubItemToLabel = ( key : string) => {
  const result = key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  switch (key) {
    case "education_degree":
      return "Level";
    case "education_discipline":
      return "Discipline";
    case "current_position":
      return "Position";
    default:
      return result;
  }
}




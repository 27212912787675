import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Input,
  Typography,
  InputLabel,
  Divider
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import ImageComponent from "./imageComponent.web";
import { loginBackground } from "../../login-block/src/assets";

// Customizable Area End

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

export class ForgotPassword1 extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const { classes } = this.props;

    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Box className={classes.mainBox}>
          <ImageComponent navigation={undefined} />
          <Box className={classes.mainBox61}>
            <Box className={classes.mainBox71}>
                <Typography className="loginText">
                  Forgot Password
                </Typography>
              <Box className={`${classes.mainBox91} ${classes.mainBox81}`}>
                <InputLabel>
                  Enter your email for the verification process, we will send 4
                  digit code to your email.
                </InputLabel>
              </Box>
              <Box>
                <Divider className={classes.dividerRoot} />
              </Box>
              <Box className={classes.mainBox81}>
                <InputLabel>Email address</InputLabel>
              </Box>
              <Box className={classes.mainBox111}>
                <Input
                  data-testId="startForgotPassword"
                  value={this.state.emailValue}
                  onChange={event =>
                    this.startForgotPassword(event.target.value)
                  }
                  className={classes.mainBox131}
                  type="text"
                  style={{
                    borderColor: !!this.renderForgotError("otp") ? "#DC2626" : "#0094A7"
                  }}
                />
              </Box>
              { !!this.renderForgotError("otp") && 
                <Typography style={{
                  color: "#DC2626",
                  fontSize: "14px",
                  lineHeight: "24px",
                  fontWeight: 400
                }}>{this.renderForgotError("otp")}</Typography>
              }
              

              <Box className={classes.mainBox121}>
                <Button
                  data-testId="goToOtpAfterEmailValidation"
                  onClick={value => this.goToOtpAfterEmailValidation(value)}
                  style={{ ...webStyle.mainBox141, textTransform: "none" }}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}
const webStyle = {
  mainBox: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#01949A",
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${loginBackground})`,
    backgroundSize: "100% 100%",
    fontFamily: "Source Sans Pro"
  },
  mainBox61: {
    display: "flex",
    backgroundColor: "white",
    width: "50vw",
    borderRadius: "50px 0 0 50px",
    '@media (max-width: 600px)': {
      justifyContent: "center",
      borderRadius: "50px 50px 0 0px",
      width: "100vw",
      height: "100%",
      top: "120px",
      position: "absolute",
    },
  },
  mainBox71: { 
    width: "50%", 
    "&::-webkit-scrollbar": {
      display: "none"
    },
    margin: "auto",
    '@media (max-height: 400px)': {
      overflowY: "scroll", 
      overflowX: "hidden",
      height: "100%",
    },
    "& .loginText":{
      fontFamily: "Source Sans Pro",
      fontWeight: 700,
      '@media (max-width: 1280px)': {
        fontSize: 32,
        lineHeight: "32px",
      },
      textAlign: "center",
      lineHeight: "40px",
      fontSize: 40,
      '@media (max-width: 600px)': {
        fontSize: 28,
        lineHeight: "28px",
        fontWeight: 600,
      },
    },
    '@media (max-width: 1280px)': {
      width: "65%",
    },
    '@media (max-width: 790px)': {
      width: "70%",
    },
    '@media (max-width: 600px)': {
      margin: "150px 25px 25px",
      paddingBottom: "25px",
      width: "75%",
    },
  },
  mainBox81: {
    "& .MuiFormLabel-root": {
      color: "#9F9F9F",
      fontFamily: "Source Sans Pro",
      fontSize: 18
    }
  },
  mainBox91: {
    display: "flex",
    marginTop: 15,
    marginBottom: 5
  },
  mainBox111: { display: "flex", marginTop: "10px" },
  mainBox121: { display: "flex", marginTop: 40 },
  mainBox131: {
    border: "1px solid #0094A7",
    borderRadius: "6px",
    width: "100%",
    padding: "1.5vh 1.5vw",
    fontSize: 18,
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    color: "#494949",
    "&::before":{
      borderBottom: "none",
    },
    "&::after":{
      borderBottom: "none",
    }
  },
  mainBox141: {
    backgroundColor: "#0094A7",
    color: "white",
    width: "100%",
    height: "3rem",
    fontWeight: 600,
    borderRadius: 8,
    fontSize: 18,
    fontFamily: "Source Sans Pro"
  },
  dividerRoot: {
    backgroundColor: "E8E8E8",
    margin: "30px 0"
  }
};

export default withStyles(webStyle)(ForgotPassword1);

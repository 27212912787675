Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.advancedsearchApiContentType = "application/json";
exports.getAdvancedSearchApiEndPoint = "advanced_search/search";
exports.errorTitle = "Error";
exports.apiEndPointForAutoCompleteSearch =
  "/bx_block_profile/projects/advance_search";
exports.projectModuleNoRecordsError = "No matching records found.";
exports.projectModuleAutoCompleteBoxTestId =
  "project-module-autocomplete-box-test-id";
exports.projectModuleAutoCompleteItemTestId = 
  "project-module-autocomplete-results-test-id";
exports.autoCompleteOptionsLength = 5;
exports.projectModuleNoRecordsFoundId = "project-module-no-records-found-id";
exports.projectModuleAutoCompleteTextFieldTextId =
  "project-module-autocomplete-textfield";
exports.clearProjectModuleAutoCompleteResultsTestId =
  "project-module-autocomplete-clear-result";
exports.autoCompleteTimerToSearch = 500;
exports.showMoreResultsId = "show-More-People-Information-Id";


// Customizable Area End

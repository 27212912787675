import React from "react";
// Customizable Area Start
import {
    Box,
    CircularProgress,
    Snackbar,
    Typography,
    Button
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { createStyles, withStyles } from "@material-ui/styles";
import {
    importExportIconSvg
} from "./assets";
import CustomButton from '../../../components/src/CustomButton.web';
import AccessControl from "../../../components/src/Permission/ActionControl";

// Customizable Area End

import ImportExportDataController, {
    Props,
    // Customizable Area Start
    // Customizable Area End
} from "./ImportExportDataController.web";

class ExportData extends ImportExportDataController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderImportExport() {
        const { classes } = this.props;
        const { tab } = this.state
        return (<>
            <div ref={this.state.customPaperRef} style={{ position: 'relative' }}>
                <CustomButton
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"
                    colorVariant="dropdownButton"
                    truncateSM = {true}
                    startIcon={<img src={importExportIconSvg} alt='import/export' />}
                    onClick={this.handleImportExportButtonClick}
                    data-testid="exportImportButtonTestId"
                >
                    Import & Export
                </CustomButton>
                {this.state.hasImportExportButtonClicked &&
                    <div
                        className={classes.customPaper}
                        onMouseLeave={this.handleMouseLeave}
                        data-testId="export-menu"
                    >
                        <Button
                            className={tab === "exportpdf" ? classes.importExportMenuItemActive : classes.importExportMenuItem}
                            data-testid="exportasPdfTestId"
                            onClick={this.handleExportPdfToken}
                            disabled={this.state.hasExportPdf}
                        >
                            Export as PDF
                        </Button>
                        <Button
                            className={tab === "exportcsv" ? classes.importExportMenuItemActive : classes.importExportMenuItem}
                            data-testid="exportasCSVTestId"
                            onClick={this.handleExportCSVToken}
                            disabled={this.state.hasExportCSV}
                        >
                            Export as CSV
                        </Button>
                        <AccessControl 
                            module="People" 
                            feature="import"
                        >
                            <Button
                                className={tab === "importcsv" ? classes.importExportMenuItemActive  : classes.importExportMenuItem}
                                data-testid="importAsCSV"
                                onClick={this.navigateImportPage}
                            >
                                Import as CSV
                            </Button>
                        </AccessControl>
                        
                    </div>
                }
            </div>
            {this.state.hasProgressData.isOpen && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={this.state.hasProgressData.isOpen}
                    onClose={this.handleCloseProgressSnack}
                >
                    <Alert icon={false}  onClose={this.handleCloseProgressSnack}>
                        <Box className={classes.exportBox}>
                            <Box position="relative" display="inline-flex" className={classes.progressBox}>
                                <CircularProgress variant="determinate" color="inherit" value={this.state.hasProgressData.progressValue} />
                                <Box className={classes.exportProgressBar}>
                                    <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                                        this.state.hasProgressData.progressValue
                                    )}%`}</Typography>
                                </Box>
                            </Box>
                            <Box> Export as {this.state.hasProgressData.fileType} {this.state.hasProgressData.isStatus} </Box>
                        </Box>
                    </Alert>
                </Snackbar>
            )}
        </>);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (<>
            {this.renderImportExport()}
            {this.state.hasSuccessError.isOpen && (
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={this.state.hasSuccessError.isOpen}
                    onClose={this.handleCloseSnack}
                    autoHideDuration={5000}
                    style={{ border: this.state.hasSuccessError.isSeverity ? '1px solid #4caf50' : "none", borderRadius: "4px" }}
                >
                    <Alert data-testid="snackBarCloseTestId" severity={this.state.hasSuccessError.isSeverity}>
                        {this.state.hasSuccessError.isMessage}
                    </Alert>
                </Snackbar>
            )}
        </>);
        // Customizable Area End
    }
}

// Customizable Area Start
const styles = 
    createStyles({
        importExportMenuItem: {
            padding: '10px 20px',
            color: '#0094A7',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16px',
            fontFamily: 'Source Sans Pro',
            cursor: 'pointer',
            transition: "background-color 0.3s",
            textTransform:"inherit",
            width: '140px',
            '&:hover': {
                backgroundColor: "#F0F0F0",
            },
            "@media (max-width: 767px)": {
                width: '120px',
                fontSize: '13px',
                padding: '10px',
            }
        },

        importExportMenuItemActive: {
            padding: '10px 20px',
            color: '#0094A7',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16px',
            fontFamily: 'Source Sans Pro',
            cursor: 'pointer',
            transition: "background-color 0.3s",
            textTransform:"inherit",
            width: '140px',
            backgroundColor: "#F0F0F0", 
            "@media (max-width: 767px)": {
                width: '120px',
                fontSize: '13px',
                padding: '10px',
            }
        },

        customPaper: {
            marginTop: '4px',
            borderRadius: '4px',
            border: '0.5px solid #0094A7',
            boxShadow: '-4px 4px 30px 0px rgba(0, 148, 167, 0.16)',
            backgroundColor: '#FFF',
            position: "absolute",
            zIndex: 5,
            width: "140px",
            "@media (max-width: 767px)": {
                width: "120px",
            },
        },

        progressBox: {
            marginRight: "10px"
        },

        exportBox: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },

        exportProgressBar: {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        
        exportdownloadbtn: {
            fontSize: "12px",
            color:"blue",
            textDecoration: "underline",
            marginLeft: "10px",
            cursor: "pointer",
        }
    });

// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(ExportData);
export { ExportData };
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  module: string;
  open: boolean;
  savedSearchList: any;
  selectedSaveSearchId: any;
  handleSeeAllSaveSearch: (value: boolean) => void;
  submitSaveSearchList: (obj: any, flag?:boolean) => void;
  handleSaveSearchAPI: () => void;
  isSuccessUpdate?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedId: string | number;
  isConfirmDelete: boolean;
  isLoadList: boolean;
  isSuccessUpdated: boolean | string;
  isEditOpen: boolean;
  editObj: any;
  innerWidth:number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SaveSearchAllController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiForDeleteSaveSearch: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      selectedId: this.props.selectedSaveSearchId,
      isConfirmDelete: false,
      isLoadList: false,
      isSuccessUpdated: false,
      isEditOpen: false,
      editObj: {},
      innerWidth:window.innerWidth
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    window.addEventListener("resize", this.resizeFunction)
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const successCallbackMap = {
        [this.apiForDeleteSaveSearch]: this.deleteSaveResponse,
      };
  
      if (apiRequestCallId) {
        const successCallback: (responseJson: any) => void =
          successCallbackMap[apiRequestCallId];
        !!successCallback && successCallback(responseJson);
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction)
  }
  resizeFunction = () => {
    this.setState({innerWidth: window.innerWidth})
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, ): void {
    if(prevProps.savedSearchList !== this.props.savedSearchList) {
      this.setState({
        isLoadList: false,
      });
      if(this.props.isSuccessUpdate){
        this.setState({
          isSuccessUpdated: "List has been updated successfully",
          isLoadList: true,
        })
        setTimeout(()=>{
          this.setState({isLoadList: false})
        }, 700)
        }
    }
    if(prevProps.selectedSaveSearchId !== this.props.selectedSaveSearchId) {
      this.setState({
        selectedId: this.props.selectedSaveSearchId
      })
    }
  }

  deleteSaveResponse = ( delRes:any ) => { 
    this.setState({ isSuccessUpdated: delRes.message ?? delRes.error})
    this.setState({
      isConfirmDelete: false,
      isLoadList: true,
      selectedId: -1,
    })
    this.props.handleSaveSearchAPI();
  }

  clickEditSaveSearch = (item: any) => {
    this.setState({
      editObj: item,
      isEditOpen: true,
    })
  }

  handleOpenEditPopup = (value:boolean) => {
    this.setState({ isEditOpen: value})
    if(!value) {
      this.setState({ editObj: false})
    }
  }

  setCloseSuccessUpdate = () => {
    this.setState({
      isSuccessUpdated: false,
    })
  }

  handleRadioSavedSearchListItem = (id: string | number) => {
    this.setState({
      selectedId: id === this.state.selectedId ? -1 : id,
      isConfirmDelete: false,
    });
  };

  resetSavedSearch = () => {
    this.setState({ selectedId: -1 })
    const obj = { id: -1, filters: {} };
    this.props.submitSaveSearchList(obj, true);
  }

  applySaveSearch = () => {
    const { selectedId } = this.state
    const obj = this.props.savedSearchList.find((s:any) => s.id === selectedId) ?? { id: -1 };
    this.setState({
      isConfirmDelete: false,
      isLoadList: false,
      isSuccessUpdated: false,
    })
    this.props.submitSaveSearchList(obj, true);
    this.props.handleSeeAllSaveSearch(false)
  }

  clickDeleteSaveSearch = (id: string | number) => {
    this.setState({
      isConfirmDelete: true,
      selectedId: id,
    });
  };

  dismissDeleteSearch = () => {
    this.setState({ 
      isConfirmDelete: false,
      isSuccessUpdated: false,
      isLoadList: false,
    });
  };

  deleteSavedSearchRequest = () => {
    this.apiForDeleteSaveSearch = this.deleteApiCall(
      `${configJSON.deleteSaveSearchEndPoint}/${this.state.selectedId}`,
      configJSON.deletApiMethod
    );
  };

  handleCloseAllPopup = () => {
    this.setState({
      isConfirmDelete: false,
      isSuccessUpdated: false,
      isLoadList: false,
      selectedId: this.props.selectedSaveSearchId
    })
    this.props.handleSeeAllSaveSearch(false)
  }

  applyEditSaveSearch = () => {
    this.props.handleSaveSearchAPI();
    this.setState({
      isLoadList: true,
      isSuccessUpdated: "List has been updated successfully",
    })
    setTimeout(()=>{
      this.setState({isLoadList: false})
    }, 800)
  }

  deleteApiCall = (delPoint: string, method: string) => {
    const saveHeader = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(saveHeader)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      delPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End
}

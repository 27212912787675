import React from "react";
import {
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableContainer,
  Paper,
  TableHead,
  TablePagination,
  makeStyles,
  withStyles,
  createMuiTheme,
  MuiThemeProvider,
  Select,
  MenuItem,
  Button,
  Input,
  Box,
  Hidden,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ConnectionCell from "./Cell/ConnectionCell"
const SortIcon = require("./sort.svg");

const THEME = createTheme({
  typography: {
    fontFamily: "Source Sans Pro",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
  },
});

const styles: any = {
  cursorPointer: {
    cursor: "pointer",
  },
  tablePaginaition: {
    "& .MuiIconButton-root": {
      borderRadius: "11px",
      color: "white",
      background: "#3695A7",
    },
  },
  paginationContainer: {
    marginTop: "4px",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    gap: "36px",
    fontSize: "12px",
    fontWeight: 600,
    "@media (max-width: 960px)": {
      margin: 10,
    },
    "& .MuiSelect-iconOutlined": {
      right: "0 !important",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined": {
      paddingLeft: "5px !important",
      paddingRight: "14px",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
  },
  rowPerPageContainer: {
    "& .MuiSelect-iconOutlined-158": {
      right: "0 !important",
    },
    "& .MuiSelect-outlined-153.MuiSelect-outlined-153": {
      paddingLeft: "5px !important",
      paddingRight: "14px",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
  },

  paginationNavigation: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    gap: "8px",
  },
  paginatinoButtons: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    gap: "4px",
  },
  paginationOfResults: {
    fontSize: "12px",
    fontWeight: 600,
  },
  paginationSelect: {
    fontSize: "12px",
  },
  select: {
    "& fieldset": {
      border: "0.5px solid #0094A7 !important",
    },
    "& div": {
      "&:focus": {
        background: "none",
      },
      paddingRight: "7px !important",
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingLeft: "6px",
    },

    fontSize: "12px",
  },
  paginationButtons: {
    backgroundColor: "#3696A7",
    padding: "9px",
    color: "white",
    width: "24px",
    minWidth: "24px",
    maxWidth: "24px",
    height: "24px",
    minHeight: "24px",
    maxHeight: "24px",
    "& .MuiSvgIcon-root": {
      fontSize: "15px",
    },
    "&.Mui-disabled": {
      backgroundColor: "#B9DDE1"
    }
  },
  paginationBackArrow: {
    paddingLeft: "7px",
  },
  paginationBtnDisabled: {
    fontSize: "17px",
    color: "white",
    backgroundColor: "#B9DDE1",
    "&.Mui-disabled": {
      backgroundColor: "#B9DDE1"
    }
  },
  paginationBtnEnabled: {
    fontSize: "17px",
    color: "white",
    backgroundColor: "#3695A7",
  },
  textWrap: {
    textWrap: "nowrap",
  },
  onHoverBtn: {
    "&:hover": {
      backgroundColor: "#3695A7",
    },
  },
  paginationButtonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  tableContainer: {
    backgroundColor: "#fff",
    "&::-webkit-scrollbar": {
      backgroundColor: "#E8E8E8",
      borderRadius: "22px",
      height: "12px",
      width: "12px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#E8E8E8",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0094A7",
      borderRadius: "22px",
    },
  },
  sortImgBox : {
    background: "none",
    border: "none",
    margin: 0,
    padding: 0
  },

  stickyHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    borderCollapse : "separate"
  },

  tablebox: {
    display : "contents"
  }
};

interface Props {
  id: any;
  classes: any;
  navigation: any;
  tableLink?: string;
  tableInputs: {
    apiData: any;
    colSpanList: any;
    columsList: any;
    columnSep: any;
  };
  pagination: {
    currentPage: number;
    rowsPerPageOptions: number[];
    totalLength: number;
    rowsPerPage: number;
  };
  onFavIconClick: (index: number, iconValue: string) => void;
  handlePageChange: (event: any, newPage: number) => void;
  handleChangeRowsPerPage: (event: any) => void;
  handleSort: (columnName: string, sortOrder: string) => void;
}

interface S {
  isRowPerPageMenuOpened: boolean;
}

interface SS { }

// Customizable Area End

import { BlockComponent } from "../../../framework/src/BlockComponent";
import { paginationDropdownIcon } from "../FilterView/assests";

class TableData extends BlockComponent<Props, S, SS> {
  selectRef: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.selectRef = React.createRef();
    this.state = {
      isRowPerPageMenuOpened: false
    }
    // Customizable Area End
  }

  toggleSelect = () => {
    this.setState((prevState: S) => ({
      isRowPerPageMenuOpened: !prevState.isRowPerPageMenuOpened,
    }));
  };

  // Customizable Area Start
  // Customizable Area End

  handleChangePage(event: unknown, newPage: number) {
    this.props.handlePageChange(event, newPage);
  }

  handleChangeRowsPerPage() { }

  handleMultipleSelectedRecords(data: any) { // array
    if (data && data.length > 0) {
      const services = data.split(',')
      const firstElement = services[0]
      const amount = services.length
      return (
        <Box style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          {firstElement}
          <div style={{
            // position: 'absolute',
            // top: '0',
            // right: '40px',
            // transform: 'translateY(-50%)',
            backgroundColor: '#0094A7',
            borderRadius: '35px',
            color: '#FFF',
            padding: '2px 3px',
            display: (amount > 1) ? 'inline' : 'none',
          }}>+ {amount} </div>
        </Box>
      )

    } else {

    }

  }

  render() {
    const { classes } = this.props;
    console.log('Table comp. props:', this.props.tableInputs.apiData)
    return (
      <MuiThemeProvider theme={THEME}>
        <TableContainer className={classes.tableContainer} style={{ maxHeight: "calc(100vh - 165px)" }}>
          <Table className={classes.tablebox}>
            <TableHead style={{ backgroundColor: "#EBF7F8" }} className={classes.stickyHeader}>
              <TableRow >
                {this.props.tableInputs.colSpanList.map(
                  (headerRow: any, indexOfCSL: number) => (
                    <TableCell
                      key={`header-${headerRow.label}-${indexOfCSL}`}
                      className={classes.tableCell}
                      style={{
                        fontSize: "16px",
                        padding: "8px",
                        textAlign: "center",
                        borderBottom: "none",
                        background: "#edf6f8",
                        borderRight: 
                          indexOfCSL ===
                            this.props.tableInputs.colSpanList.length - 1
                            ? "none"
                            : "1px solid #80CAD3",
                        // borderLeft:
                        //   indexOfCSL == 0 ? "none" : "1px solid #80CAD3",
                        // border: "1px solid black"
                      }}
                      colSpan={headerRow.colSpan}
                    >
                      {(headerRow.colSpan !== 1 || !!headerRow.isForceLabel) && headerRow.label}
                    </TableCell>
                  )
                )}
              </TableRow>
              <TableRow>
                {this.props.tableInputs.columsList.map(
                  (headerRow: any, indexOfCL: number) => (
                    <TableCell
                      key={`sub-header-${headerRow.dataLalbel
                        }-${indexOfCL}`}
                      className={classes.textWrap}
                      style={{
                        fontSize: "16px",
                        padding: "8px",
                        borderBottom: "none",
                        background: "#edf6f8",
                        minWidth: headerRow.width,
                        borderRight:
                          indexOfCL ===
                            this.props.tableInputs.columsList.length - 1
                            ? "none"
                            : this.props.tableInputs.columnSep.indexOf(
                              indexOfCL + 1
                            ) > -1
                              ? "1px solid #80CAD3"
                              : "1px solid #80CAD3",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: headerRow.textAlign ? headerRow.textAlign : "space-between",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        {!headerRow.hideLabel && headerRow.displayLabel}
                        {headerRow.sortOption.isSortable && (
                          <button  onClick={() =>
                            this.props.handleSort(
                              headerRow.dataLabel,
                              headerRow.sortOption.sortOrder
                            )
                          }  
                           className={classes.sortImgBox}
                           disabled={headerRow.dataLabel === "value_of_work"}
                          >
                            <img
                              className={classes.cursorPointer}
                              data-testid="sortIcon"
                              style={{
                                transform:
                                  headerRow.sortOption.sortOrder === "ASC"
                                    ? "rotate(180deg)"
                                    : "",
                              }}
                              src={SortIcon}
                              alt="sortIcon"
                            />
                          </button>
                        )}
                      </div>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody style={{ background: "white" }}>
              {this.props.tableInputs.apiData.map(
                (row: any, indexOfAD: number) => (
                  <TableRow key={`${row.name}-${indexOfAD}`}>
                    {this.props.tableInputs.columsList.map(
                      (headerRow: any, indexOfCL: number) => (
                        <TableCell
                          key={`cell-${headerRow.dataLabel}-${indexOfCL}`}
                          style={{
                            padding: "8px",
                            fontWeight: 600,
                            borderBottom: "none",
                            borderRight:
                              indexOfCL ===
                                this.props.tableInputs.columsList.length - 1
                                ? "none"
                                : this.props.tableInputs.columnSep.indexOf(
                                  indexOfCL + 1
                                ) > -1
                                  ? "1px solid #80CAD3"
                                  : "1px solid #80CAD3",
                          }}
                        >
                          {headerRow.type === "value" && 
                          typeof row[headerRow.dataLabel] == "string" && row[headerRow.dataLabel]}
                          {headerRow.type === "projectServices" && row[headerRow.dataLabel]}
                          {headerRow.type === "icon" && (
                            <img
                              className={classes.cursorPointer}
                              data-test-id="favIcon"
                              onClick={() =>
                                this.props.onFavIconClick(
                                  indexOfAD,
                                  row[headerRow.dataLabel]
                                )
                              }
                              src={row[headerRow.dataLabel]}
                            />
                          )}
                          {headerRow.type === "boolean" &&
                            (row[headerRow.dataLabel] ? "Yes" : "No")}
                          {headerRow.type === "link" && (
                            <a style={{
                              cursor: "pointer", textDecoration: "underline", color: "#0094A7",
                            }}
                              {...row["id"] ? { target: "_blank", href: `/${this.props.tableLink ? this.props.tableLink : 'People'}/${row["id"]}` } : {}}>
                              {row[headerRow.dataLabel]}
                            </a>
                          )}

                          { headerRow.type == "connection" && (
                           <ConnectionCell 
                              connections={row[headerRow.dataLabel]} 
                              mapField={headerRow.connections}
                              navigation = {this.props.navigation}
                            />
                          )}


                        </TableCell>
                      )
                    )}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.paginationContainer}>
          <Hidden xsDown>
          <div className={classes.rowPerPageContainer}>
            <span style={{ padding: "0px 16px" }}>Results per page</span>
            <Select
              name="asdf"
              className={classes.select}
              open={this.state.isRowPerPageMenuOpened}
              value={this.props.pagination.rowsPerPage}
              variant="outlined"
              onChange={this.props.handleChangeRowsPerPage}
              onOpen={this.toggleSelect}
              onClose={this.toggleSelect}
              ref={this.selectRef}
              MenuProps={{
                disableScrollLock: true,
              }}
              IconComponent={() => (
                <img
                  onClick={this.toggleSelect}
                  style={{ cursor: "pointer", paddingRight: "8px" }}
                  src={paginationDropdownIcon}
                />
              )}
            >
              {this.props.pagination.rowsPerPageOptions.map(
                (val: number) => (
                  <MenuItem key={val} value={val}>
                    {val}
                  </MenuItem>
                )
              )}
            </Select>
          </div>
          </Hidden>
          <div className={classes.paginationNavigation}>
            <div className={classes.paginationOfResults}>
              {this.props.tableInputs.apiData.length} of{" "}
              {this.props.pagination.totalLength} Results
            </div>
            <div className={classes.paginationButtonsContainer}>
              <Button
                className={`${classes.paginationButtons} ${this.props.pagination.currentPage === 0
                  ? ""
                  : classes.onHoverBtn
                  }`}
                onClick={() => this.onPageChange("previous")}
                disabled={this.props.pagination.currentPage === 0}
                variant="contained"
              >
                <ArrowBackIosIcon
                  className={`${classes.paginationBackArrow} ${this.props.pagination.currentPage === 0
                    ? classes.paginationBtnDisabled
                    : classes.paginationBtnEnabled
                    }`}
                />
              </Button>
              <div>
                {this.props.pagination.currentPage + 1 > 10
                  ? this.props.pagination.currentPage + 1
                  : "0" + (this.props.pagination.currentPage + 1)}
              </div>
              <Button
                className={`${classes.paginationButtons} ${this.props.pagination.currentPage ===
                  Math.floor(
                    this.props.pagination.totalLength /
                    this.props.pagination.rowsPerPage
                  )
                  ? ""
                  : classes.onHoverBtn
                  }`}
                onClick={() => this.onPageChange("next")}
                disabled={
                  this.props.pagination.currentPage ===
                  Math.floor(
                    this.props.pagination.totalLength /
                    this.props.pagination.rowsPerPage
                  )
                }
                variant="contained"
              >
                <ArrowForwardIosIcon
                  className={`${this.props.pagination.currentPage ===
                    Math.floor(
                      this.props.pagination.totalLength /
                      this.props.pagination.rowsPerPage
                    )
                    ? classes.paginationBtnDisabled
                    : classes.paginationBtnEnabled
                    }`}
                />
              </Button>
            </div>
          </div>
        </div>
      </MuiThemeProvider>
    );
  }

  onPageChange(value: string) {
    if (value === "previous") {
      this.props.handlePageChange("", this.props.pagination.currentPage - 1);
    } else if (value === "next") {
      this.props.handlePageChange("", this.props.pagination.currentPage + 1);
    }
  }
}
// Customizable Area Start
const webStyle = {
  TableCell: { borderRight: "1px solid #01949A" },
  divRoot0: { display: "flex" },
  divRoot1: {
    display: "flex",
    backgroundColor: "#01949A",
    height: "50px",
    position: "sticky",
    marginTop: "19px",
  },
  divRoot2: { marginTop: "9px", width: "7rem", height: "2rem", fill: "white" },
  divRoot3: { color: "white", margin: "13px 0px 0px -30px" },
  divRoot4: {
    color: "white",
    backgroundColor: "#01949A",
    border: "1px solid white",
    marginLeft: "60vw",
    marginTop: "0.4vw",
  },
  divRoot5: { height: "67vh", overflow: "scroll" },
  divRoot22: { width: "78%", margin: "15px" },
  tableRoot: { display: "flex" },
  tabRoots: { height: "90.7vh" },
  divRoot: { backgroundColor: "#01949A", height: "7%" },
};
export default withStyles(styles)(TableData);
// Customizable Area End

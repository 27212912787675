export const SwitchAccIcon = require('./assets/switch-acc-icon.svg')
export const ProfileIcon = require('./assets/profile-icon.svg')
export const RequestIcon = require('./assets/request-icon.svg')
export const LogoutIcon = require('./assets/logout-icon.svg')
export const RoleIcon = require('./assets/role-icon.svg')
export const DownIcon = require('./assets/down-icon.svg')
export const UpIcon = require('./assets/up-icon.svg')
export const OffFilter = require('./assets/off-filter-icon.svg')
export const OnFilter = require('./assets/on-filter-icon.svg')
export const menuIcon = require('./assets/menu-icon.svg')
export const CheckedIcon = require('./assets/checked-icon.svg')
export const activityEnable = require("./assets/activity-icon-enable.svg")
export const activityDisable = require("./assets/activity-icon-disable.svg")

export const defaultRoute = {
    "People": "PeopleData",
    "Project": "ProjectModuleTable",
    "Projects": "ProjectModuleTable",
    "Tender": "TenderModuleTable",
    "Tenders": "TenderModuleTable",
    "Suppliers": "SuppliersModuleTable",
    "Supplier": "SuppliersModuleTable",
    "Partner": "PartnersModuleTable",
    "Partners": "PartnersModuleTable"
}
  
export type TypeDefaultRole = keyof typeof defaultRoute
import React from "react";
import { Box, ClickAwayListener, Paper, Popper, TextField, Typography, styled } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomButton from "../CustomButton.web";

interface Options {
    label: string;
    value: number;
    values?: { [key: string]: number | string };
}

interface DateFilterDropdownProps {
    name: string;
    quarterOptions: Array<Options>,
    quarterValue: number,
    quarterOpen: boolean,
    datePickerOpen: boolean,
    dateRange: { [key: string]: Date | string | null },
    handleQuarterOpen: (name: string, value: boolean) => void,
    handleDatePickerOpen: (name: string, value: boolean) => void,
    handleQuarter: (name: string, value: string | number) => void,
    handleDateRange: (name: string, event: React.ChangeEvent<HTMLInputElement>) => void,
    handleResetDatePicker: (name: string) => void,
    handleApplyDatePicker: (name: string) => void,
    position?: "left" | "right",
}

const dateRangeOptions = [
    {
        label: "From",
        render: "start_date",
        value: null,
    },
    {
        label: "To",
        render: "end_date",
        value: null,
    },
];

class DateFilterDropdown extends React.Component<DateFilterDropdownProps> {

    renderDatePickerDialog = () => {
        const { datePickerOpen, dateRange, name, position } = this.props;
        const { start_date, end_date } = dateRange;
        const positionKey = position || "right"
        const isDisabled = start_date && end_date
        return (
            <Popper
                open={datePickerOpen}
                data-test-id="datePickerPopper"
                placement="bottom-start"
                className="datePickerPopper"
                style={{
                    zIndex: 1000000, position: "absolute",
                    left: positionKey === 'right' ? window.innerWidth < 760 ? "calc(-100% - 14px)" : "calc(100% + 4px)" : "unset",
                    top: "4px",
                    right: positionKey === 'left' ? window.innerWidth < 760 ? "0px" : "calc(100% + 4px)" : 'unset'
                }}
                // @ts-ignore
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transition
                disablePortal
            >
                <ClickAwayListener touchEvent={false} onClickAway={() => this.props.handleDatePickerOpen(name, false)}>
                    <Paper className="datePickerPaper">
                        <CustomDateRangeContainer>
                            {
                                dateRangeOptions.map((item: any) => {
                                    const selectedValueDate = dateRange[item.render];
                                    return (
                                        <Box key={item.render} className="dateRange">
                                            <Typography className="title">{item.label}</Typography>
                                            <CustomDateRangeField
                                                data-test-id="datePicker"
                                                type="date"
                                                name={item.render}
                                                value={selectedValueDate || new Date()}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleDateRange(name, event)}
                                                variant="outlined"
                                            />
                                        </Box>
                                    )
                                })
                            }
                        </CustomDateRangeContainer>
                        <Box className="footerReq">
                            <CustomButton
                                id="cancel"
                                colorVariant="special"
                                data-test-id="resetReqButton"
                                onClick={() => this.props.handleResetDatePicker(name)}
                                style={{
                                    minWidth: "89px",
                                    lineHeight: "17px",
                                    marginRight: "20px",
                                }}
                                disabled={!isDisabled}
                            >
                                Reset
                            </CustomButton>
                            <CustomButton
                                id="save"
                                data-test-id="saveReqButton"
                                onClick={() => this.props.handleApplyDatePicker(name)}
                                style={{
                                    minWidth: "119px",
                                    lineHeight: "17px",
                                }}
                                disabled={!isDisabled}
                            >
                                Apply
                            </CustomButton>
                        </Box>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        )
    }

    render() {
        const { quarterOptions, quarterValue, quarterOpen, datePickerOpen, name } = this.props;
        return (
            <CustomeDropDown>
                <Box className="mainButton" data-test-id="quarterDropdown" onClick={() => this.props.handleQuarterOpen(name, true)}>
                    <Box className="title" data-test-id="quarterDropdownTitle">{quarterOptions[quarterValue]?.label ?? "Custom"}</Box>
                    <ExpandMoreIcon style={{ color: "#0094A7", transform: quarterOpen ? "rotate(180deg)" : "rotate(0deg)" }} />
                </Box>
                <Popper
                    open={quarterOpen}
                    placement="bottom-start"
                    data-test-id="quarterPopper"
                    className="customPopper"
                    style={{ zIndex: 1000000, position: "absolute", top: "calc(100% + 4px)" }}
                    // @ts-ignore
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transition
                    disablePortal
                >
                    <ClickAwayListener touchEvent={false} onClickAway={() => this.props.handleQuarterOpen(name, false)}>
                        <Paper className="customPaper">
                            <Box className="list" style={{ background: quarterValue === -1 ? "#C2E5EA" : "white" }} data-test-id="quarterCustomOption" onClick={() => this.props.handleDatePickerOpen(name, true)}>
                                <Box className="title">Custom</Box>
                                <ExpandMoreIcon style={{ height: "20px", marginRight: "-5px", color: "#0094A7", transform: datePickerOpen ? "rotate(270deg)" : "rotate(0deg)" }} />
                            </Box>
                            {this.renderDatePickerDialog()}
                            {quarterOptions.map(option => {
                                return (
                                    <Box
                                        data-test-id="quarterOptions"
                                        key={`${option.label}-quarter`}
                                        className="list"
                                        onClick={() => this.props.handleQuarter(name, option.value)}
                                        style={{ backgroundColor: quarterValue === option.value ? "#C2E5EA" : "white" }}
                                    >
                                        {option.label}
                                    </Box>
                                )
                            })}
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </CustomeDropDown>
        )
    }
}

export const CustomDateRangeContainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
    margin: "4px 0 10px",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "15px",
    "& .dateRange": {
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        "& .title": {
            fontFamily: "Source Sans Pro",
            color: "#000",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "16px",
        },
    },
});

export const CustomDateRangeField = styled(TextField)({
    width: "113px",
    "& .MuiInputBase-root": {
        paddingRight: "4px",
        "& .MuiInputBase-input": {
            borderRadius: "4px",
            color: "#000",
            fontFamily: "Source Sans Pro",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "16px",
            padding: "7px 2px 7px 5px",
        },
    },
    "& .MuiOutlinedInput-adornedStart": {
        "& .MuiInputBase-input": {
            paddingLeft: "1px !important",
        },
    },
    "& .Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#9F9F9F",
        },
    },
    "& .MuiFormHelperText-root": {
        margin: 0,
        color: "#ea2f2f",
        marginTop: "5px",
    },
});

const CustomeDropDown = styled(Box)({
    position: "relative",
    width: "115px",
    height: "34px",
    "& .mainButton": {
        height: "100%",
        width: "fit-content",
        cursor: "pointer",
        position: "relative",
        padding: "0px 10px 0px 15px",
        borderRadius: "4px",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        border: "1px solid #0094A7",
        justifyContent: "space-between",
        gap: "5px",
        "& .title": {
            color: '#0094A7',
            fontSize: "14px",
            fontFamily: 'Source Sans Pro',
            fontWeight: '600',
            lineHeight: "16px",
            wordWrap: 'break-word'
        }
    },
    "& .customPopper": {
        width: "100%",
        borderRadius: "4px",
        border: "1px solid #E0E0E0",
        "& .customPaper": {
            padding: "5px 0",
            background: "#FFF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            "& .list": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 14px 10px 15px",
                color: "#000",
                fontSize: "16px",
                fontStyle: "normal",
                fontFamily: "Source Sans Pro",
                fontWeight: 600,
                lineHeight: "16px",
                cursor: "pointer",
                "&:hover": {
                    background: "#EEF1F1"
                },

            },
            "& .datePickerPopper": {
                border: "1px solid #E0E0E0",
                borderRadius: "4px",
                "& .datePickerPaper": {
                    padding: "8px 10px 13px 10px",
                    background: "#FFF",
                    width: "auto",
                    "& .footerReq":{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "nowrap"
                    }
                }
            }
        }
    }
})


export default DateFilterDropdown;

import React from "react";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import {
  MuiThemeProvider,
  CssBaseline,
  Button,
  Box,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const theme = createTheme({});

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "calc(100vh - 150px)",
    backgroundColor: "#FFF",
  },
  content: {
    textAlign: "center",
  },
  recordImageBox:{
    width : "175px",
    height : "173px",
    "@media (max-width: 767px)": {
      width : "95px",
      height : "90px",
    },
    "@media (max-width: 960px)": {
      width : "120px",
      height : "115px",
    }
  },
  typography: {
    color: "#000",
    fontFamily: "Source Sans Pro",
    fontSize: 24,
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    marginTop: "29px",
    marginBottom: "25px",
    "@media (max-width: 767px)": {
      fontSize: "16px",
    },
    "@media (max-width: 960px)": {
      fontSize: "20px"
    }
  },
  buttonText: {
    color: "#FFF",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
    transition: "color 0.5s", 
    "@media (max-width: 767px)": {
      fontSize: "14px",
    }
  },
  buttonStyle: {
    backgroundColor: "#0094A7",
    color: "white",
    borderRadius: "4px",
    textTransform: "none",
    padding: "10px",
    transition: "background-color 0.5s", 
    "&:hover": {
      backgroundColor: "#ebf7f8",
    },
    "&:hover $buttonText": {
      color: "#0094A7", 
    },
  },
});

type Props = {
  text: string;
  activeModule?: string;
  loadData?: () => void;
  buttonData?: string;
  style?: any;
};

const NoRecordsFoundPage = (props: Props) => {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Box className={classes.root} style={{...props.style}}>
        <Box className={classes.content}>
          <Box className={classes.recordImageBox}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 177 173"
              fill="none"
            >
              <g clip-path="url(#clip0_1401_20575)">
                <path
                  d="M112.367 38.9071H64.6733C63.5865 38.9085 62.5445 39.3414 61.776 40.1109C61.0075 40.8804 60.5751 41.9237 60.5738 43.012V149.151L60.0272 149.318L48.3271 152.905C47.7726 153.075 47.1737 153.017 46.6618 152.744C46.15 152.472 45.767 152.007 45.5969 151.452L10.7946 37.6237C10.6253 37.0684 10.683 36.4686 10.9551 35.956C11.2272 35.4433 11.6915 35.0599 12.2459 34.8898L30.2756 29.3619L82.5446 13.3419L100.574 7.81401C100.849 7.72946 101.137 7.69991 101.423 7.72706C101.709 7.75421 101.986 7.83753 102.24 7.97225C102.493 8.10697 102.718 8.29044 102.901 8.51216C103.083 8.73388 103.221 8.98951 103.305 9.2644L112.2 38.3598L112.367 38.9071Z"
                  fill="#F2F2F2"
                />
                <path
                  d="M122.773 38.3593L112.051 3.29265C111.873 2.70837 111.581 2.16502 111.193 1.69363C110.805 1.22225 110.328 0.832074 109.789 0.545405C109.25 0.258736 108.66 0.0811869 108.052 0.0229116C107.445 -0.0353638 106.832 0.0267761 106.249 0.205774L80.9001 7.97495L28.634 23.9977L3.28526 31.7696C2.10769 32.1317 1.1217 32.9468 0.543702 34.036C-0.0342929 35.1252 -0.157067 36.3996 0.202333 37.5794L36.8466 157.423C37.1386 158.375 37.7277 159.209 38.5275 159.801C39.3273 160.394 40.2957 160.714 41.2906 160.715C41.7511 160.715 42.2089 160.646 42.6488 160.51L60.0254 155.184L60.572 155.015V154.443L60.0254 154.61L42.4876 159.987C41.4482 160.304 40.3255 160.196 39.3659 159.685C38.4063 159.175 37.688 158.304 37.3687 157.264L0.727184 37.4179C0.568967 36.9026 0.513825 36.3611 0.564912 35.8244C0.615999 35.2877 0.772308 34.7664 1.0249 34.2902C1.27749 33.8141 1.6214 33.3926 2.03693 33.0497C2.45245 32.7069 2.93144 32.4495 3.44646 32.2923L28.7952 24.5204L81.0614 8.50038L106.41 0.728468C106.801 0.609097 107.207 0.548233 107.615 0.547852C108.492 0.549822 109.345 0.832774 110.049 1.35526C110.753 1.87775 111.272 2.61232 111.529 3.45137L122.201 38.3593L122.371 38.9066H122.939L122.773 38.3593Z"
                  fill="#3F3D56"
                />
                <path
                  d="M33.5284 34.9793C33.0017 34.9789 32.4889 34.8095 32.0653 34.496C31.6417 34.1824 31.3297 33.7412 31.1749 33.237L27.6547 21.7237C27.5601 21.4144 27.5273 21.0895 27.5581 20.7676C27.5889 20.4456 27.6828 20.1328 27.8343 19.8472C27.9859 19.5616 28.1921 19.3086 28.4413 19.1028C28.6905 18.897 28.9777 18.7423 29.2866 18.6477L77.371 3.90739C77.9948 3.7168 78.6686 3.7818 79.2446 4.08813C79.8206 4.39447 80.2516 4.9171 80.4432 5.54133L83.9634 17.0548C84.1536 17.6794 84.0886 18.354 83.7827 18.9307C83.4768 19.5074 82.9549 19.939 82.3316 20.1309L34.247 34.8712C34.0142 34.9428 33.772 34.9792 33.5284 34.9793Z"
                  fill="#0094A7"
                />
                <path
                  d="M51.9699 12.3023C54.9887 12.3023 57.436 9.85189 57.436 6.82914C57.436 3.80638 54.9887 1.35596 51.9699 1.35596C48.9511 1.35596 46.5039 3.80638 46.5039 6.82914C46.5039 9.85189 48.9511 12.3023 51.9699 12.3023Z"
                  fill="#0094A7"
                />
                <path
                  d="M51.9691 10.2949C53.8807 10.2949 55.4303 8.74317 55.4303 6.82907C55.4303 4.91497 53.8807 3.36328 51.9691 3.36328C50.0575 3.36328 48.5078 4.91497 48.5078 6.82907C48.5078 8.74317 50.0575 10.2949 51.9691 10.2949Z"
                  fill="white"
                />
                <path
                  d="M164.703 159.317H72.327C71.7111 159.316 71.1206 159.071 70.6851 158.635C70.2496 158.199 70.0046 157.608 70.0039 156.991V46.1591C70.0046 45.5424 70.2496 44.9511 70.6851 44.5151C71.1206 44.079 71.7111 43.8337 72.327 43.833H164.703C165.319 43.8337 165.909 44.079 166.345 44.5151C166.78 44.9512 167.025 45.5424 167.026 46.1591V156.991C167.025 157.608 166.78 158.199 166.345 158.635C165.909 159.071 165.319 159.316 164.703 159.317Z"
                  fill="#E6E6E6"
                />
                <path
                  d="M122.203 38.3599H64.6735C63.4418 38.3616 62.261 38.8523 61.3901 39.7244C60.5191 40.5965 60.0291 41.7788 60.0273 43.0121V154.61L60.5739 154.443V43.0121C60.5753 41.9238 61.0076 40.8805 61.7761 40.1109C62.5447 39.3414 63.5866 38.9085 64.6735 38.9072H122.373L122.203 38.3599ZM172.354 38.3599H64.6735C63.4418 38.3616 62.261 38.8523 61.3901 39.7244C60.5191 40.5965 60.0291 41.7788 60.0273 43.0121V168.348C60.0291 169.581 60.5191 170.763 61.3901 171.636C62.261 172.508 63.4418 172.998 64.6735 173H172.354C173.586 172.998 174.767 172.508 175.638 171.636C176.509 170.763 176.999 169.581 177 168.348V43.0121C176.999 41.7788 176.509 40.5965 175.638 39.7244C174.767 38.8523 173.586 38.3616 172.354 38.3599ZM176.454 168.348C176.452 169.436 176.02 170.479 175.252 171.249C174.483 172.018 173.441 172.451 172.354 172.453H64.6735C63.5866 172.451 62.5447 172.018 61.7761 171.249C61.0076 170.479 60.5753 169.436 60.5739 168.348V43.0121C60.5753 41.9238 61.0076 40.8805 61.7761 40.1109C62.5447 39.3414 63.5866 38.9085 64.6735 38.9072H172.354C173.441 38.9085 174.483 39.3414 175.252 40.1109C176.02 40.8805 176.452 41.9238 176.454 43.0121V168.348Z"
                  fill="#3F3D56"
                />
                <path
                  d="M143.657 50.4007H93.3699C92.7177 50.4 92.0925 50.1402 91.6314 49.6785C91.1703 49.2168 90.9109 48.5907 90.9102 47.9378V35.8968C90.9109 35.2438 91.1703 34.6178 91.6314 34.156C92.0925 33.6943 92.7177 33.4346 93.3699 33.4338H143.657C144.309 33.4346 144.935 33.6943 145.396 34.156C145.857 34.6178 146.116 35.2438 146.117 35.8968V47.9378C146.116 48.5907 145.857 49.2168 145.396 49.6785C144.935 50.1402 144.309 50.4 143.657 50.4007Z"
                  fill="#0094A7"
                />
                <path
                  d="M118.513 34.255C121.532 34.255 123.979 31.8045 123.979 28.7818C123.979 25.759 121.532 23.3086 118.513 23.3086C115.494 23.3086 113.047 25.759 113.047 28.7818C113.047 31.8045 115.494 34.255 118.513 34.255Z"
                  fill="#0094A7"
                />
                <path
                  d="M118.513 32.1154C120.352 32.1154 121.842 30.6228 121.842 28.7817C121.842 26.9405 120.352 25.448 118.513 25.448C116.674 25.448 115.184 26.9405 115.184 28.7817C115.184 30.6228 116.674 32.1154 118.513 32.1154Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1401_20575">
                  <rect width="100%" height="100%" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Box>
          <Typography
            className={classes.typography}
            data-testid={"no-records-found"}
          >
            {props.text}
          </Typography>
          
          { props.loadData && 
              <Button className={classes.buttonStyle} onClick={props.loadData}>
                { props.text === "No notes found"? 
                <Typography className={classes.buttonText}>
                  {props.buttonData}
                </Typography>
                :
                <Typography className={classes.buttonText}>
                Click to {props.activeModule} data
              </Typography>
                }
              </Button>
          }
        </Box>
      </Box>
    </MuiThemeProvider>
  );
};

export default NoRecordsFoundPage;

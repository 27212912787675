import React, {PropsWithChildren} from "react";

import {
  Box,
  Backdrop,
  Modal as BaseModal,
  Paper,
  styled,
} from "@material-ui/core";
import CustomTypographyWeb from "../../../../components/src/CustomTypography.web";
import CustomButton from "../../../../components/src/CustomButton.web";


const Modal = styled(BaseModal)({
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    backdropFilter: "blur(4px)",
    borderRadius: "16px",
    "& .mainContent": {
      width: "80%",
      maxWidth: "570px",
      background: "#FFF",
      color: "#000",
      position: "relative",
      maxHeight: "365px",
      display: "flex",
      flexDirection: "column",
      padding: "26px 45px 40px 45px",
      "@media (max-width: 960px)": {
        margin: " 0 40px",
      },
      "@media (max-width: 767px)": {
        padding: "26px"
      },
      "& .firstContainer": {
        display: "flex",
        flexDirection: "column",
        "& .appliedButton": {
          marginBottom: "8px",
          display: "flex",
          gap: "10px",
          justifyContent: "flex-end",
          alignItems: "center",
          "& .applied": {
            display: "flex",
            alignItems: "center",
            color: 'black',
            fontSize: "14PX",
            fontFamily: 'Source Sans Pro',
            fontWeight: 400,
            lineHeight: "16PX",
            "& .filterCountBadge": {
              borderRadius: "35px",
              padding: "0px 8px",
              color: "white",
              marginLeft: "4px",
              fontFamily: "Source Sans Pro",
              backgroundColor: "#3695A7",
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "16px",
              letterSpacing: "0em",
              textAlign: "left",
            },
          },
        },
        "& .titleContainer": {
          display: "flex",
          gap: "12px",
          alignItems: "center",
          "& .required": {
            backgroundColor: "#EAFBFD",
            borderRadius: "16px",
            width: "67px",
            height: "26px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#0094A7",
            textAlign: "right",
            fontFamily: "Inter",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "18px",
          },
        },
        "& .input": {
          marginBottom: "28px",
          "& .MuiInputBase-root":{
            height: "52px",
            "& .PrivateNotchedOutline-root-67":{
              borderColor: "#C9CDCD"
            }
          }
        },
      },
      "& .CloseEditSaveSeachButton": {
        right: 0,
        cursor: "pointer",
        top: -40,
        position: "absolute",
      },
    },
    "& .MuiInputBase-root.MuiInput-root": {
      background: "#f6f6f6",
      border: "1px solid #E8E8E8!important"
    }
})


interface Props {
  title: string
  isOpen: boolean
  onClose?: () => void
  onSubmit: () => void
}

interface State {

}


export class ConnectionModal extends React.Component<PropsWithChildren<Props>, State> {

    constructor(props: Props) {
        super(props);

    }

    render() {
      
      return (
        <Modal
            aria-labelledby="transition-modal-title"
            data-test-id="saveSearchAllPopup"
            aria-describedby="transition-modal-description"
            open={this.props.isOpen}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 400,
            }}
            onEscapeKeyDown={this.props.onClose}
        >
            <Paper className="mainContent">
            <Box
                data-test-id="closeEditSaveSearch"
                className="CloseEditSaveSeachButton"
            >
            </Box>
            <div className="firstContainer">
                <div className="appliedButton">

                </div>
                <div className="titleContainer">
                    <CustomTypographyWeb isHeader>{ this.props.title }</CustomTypographyWeb>
                </div>

                <div style={{ margin: "18px 0 40px 0" }}>
                    { this.props.children }
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "16px",
                }}
            >
                <CustomButton
                  data-test-id="cancel-btn"
                  colorVariant="special"
                  onClick={this.props.onClose}
                >
                    Cancel
                </CustomButton>
                <CustomButton
                  data-test-id="saveButton"
                  onClick={() => this.props.onSubmit()}
                >
                    Save
                </CustomButton>
            </div>
            
            </Paper>
        </Modal>
      )
    }
}
// Customizable Area Start
import React, { Component } from "react";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/styles";
import CustomTypographyWeb from "../../../components/src/CustomTypography.web";
import { editPageButtonSvg, requestIcon } from "./assets";
import { configJSON } from "./SupplierModuleController.web";
import { AccessControl } from "../../../components/src/Permission";
import CustomButton from "../../../components/src/CustomButton.web";
import ReactQuill from 'react-quill';
import NoRecordsFoundPage from "../../../components/src/Table/NoRecordsFoundPage.web";
import { checkHtmlContentLength } from '../../../components/src/Utils';
const icons = ReactQuill.Quill.import('ui/icons');
icons["clean"] = `<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.2195 7.25025L9.70886 3.75638C9.54389 3.59231 9.3202 3.50015 9.08697 3.50015C8.85374 3.50015 8.63005 3.59231 8.46508 3.75638L6.10941 6.1005L4.23384 0.875H3.35453L1.15625 7H2.03556L2.47478 5.6875H5.11271L5.46575 6.741L2.2932 9.898C2.21151 9.97926 2.14671 10.0757 2.1025 10.1819C2.05828 10.2881 2.03553 10.4019 2.03553 10.5168C2.03553 10.6318 2.05828 10.7456 2.1025 10.8518C2.14671 10.958 2.21151 11.0544 2.2932 11.1357L4.29187 13.125H8.50816L13.2195 8.43631C13.2978 8.35843 13.36 8.26594 13.4024 8.16415C13.4448 8.06236 13.4666 7.95325 13.4666 7.84306C13.4666 7.73287 13.4448 7.62377 13.4024 7.52197C13.36 7.42018 13.2978 7.3277 13.2195 7.24981V7.25025ZM2.76759 4.8125L3.79242 1.75L4.81946 4.8125H2.76759ZM8.14457 12.25H4.65591L2.91487 10.5166L5.68997 7.75556L9.17556 11.2236L8.14457 12.25ZM9.79767 10.605L6.31209 7.13694L9.08719 4.375L12.5723 7.84306L9.79767 10.605Z" fill="#33354D"/>
</svg>
`
icons["link"] = `<svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5852_2561)">
<path d="M13.2068 2.95762C12.9617 2.7129 12.6705 2.51873 12.3499 2.38624C12.0292 2.25375 11.6854 2.18555 11.3382 2.18555C10.9911 2.18555 10.6473 2.25375 10.3266 2.38624C10.006 2.51873 9.71478 2.7129 9.46971 2.95762L10.094 3.57887C10.2577 3.41599 10.452 3.28678 10.6659 3.19863C10.8797 3.11048 11.109 3.06511 11.3404 3.06511C11.5719 3.06511 11.8011 3.11048 12.015 3.19863C12.2289 3.28678 12.4232 3.41599 12.5869 3.57887C12.7505 3.74175 12.8804 3.93511 12.969 4.14793C13.0576 4.36074 13.1031 4.58883 13.1031 4.81918C13.1031 5.04953 13.0576 5.27762 12.969 5.49043C12.8804 5.70324 12.7505 5.89661 12.5869 6.05949L9.06962 9.55949C8.73963 9.88844 8.29184 10.0735 7.82475 10.0739C7.35767 10.0743 6.90955 9.89005 6.57898 9.56168C6.2484 9.23331 6.06246 8.78771 6.06205 8.32291C6.06163 7.85812 6.24679 7.41219 6.57678 7.08324L7.19669 6.46199L6.57678 5.84074L5.95247 6.46199C5.70654 6.70586 5.51141 6.99564 5.37827 7.31472C5.24513 7.63379 5.17659 7.97588 5.17659 8.32137C5.17659 8.66685 5.24513 9.00894 5.37827 9.32802C5.51141 9.64709 5.70654 9.93687 5.95247 10.1807C6.44993 10.6694 7.12198 10.9417 7.821 10.9376C8.16952 10.939 8.51487 10.8717 8.8371 10.7396C9.15933 10.6074 9.45204 10.413 9.69833 10.1676L13.2156 6.66762C13.7084 6.17427 13.9843 5.50643 13.9826 4.81081C13.981 4.11518 13.7019 3.44865 13.2068 2.95762Z" fill="#33354D"/>
<path d="M2.18902 10.8589C2.02485 10.6963 1.89458 10.503 1.80569 10.2901C1.71679 10.0773 1.67103 9.84905 1.67103 9.61855C1.67103 9.38806 1.71679 9.15983 1.80569 8.94698C1.89458 8.73413 2.02485 8.54085 2.18902 8.37824L5.70626 4.87824C5.86967 4.71488 6.0639 4.58524 6.2778 4.49679C6.4917 4.40833 6.72105 4.3628 6.95268 4.3628C7.18432 4.3628 7.41367 4.40833 7.62757 4.49679C7.84147 4.58524 8.0357 4.71488 8.19911 4.87824C8.36224 5.04213 8.49066 5.23693 8.57675 5.45111C8.66284 5.66529 8.70486 5.89448 8.70031 6.12512C8.70165 6.35647 8.65688 6.58579 8.56859 6.79981C8.48029 7.01383 8.35023 7.20831 8.18592 7.37199L7.25385 8.31262L7.87816 8.93387L8.81023 8.00637C9.30637 7.51265 9.58511 6.84303 9.58511 6.1448C9.58511 5.44658 9.30637 4.77696 8.81023 4.28324C8.31408 3.78952 7.64115 3.51216 6.93949 3.51216C6.23783 3.51216 5.56491 3.78952 5.06876 4.28324L1.55152 7.78324C1.30493 8.02719 1.10925 8.31725 0.975713 8.63672C0.842181 8.9562 0.773438 9.2988 0.773438 9.6448C0.773438 9.99081 0.842181 10.3334 0.975713 10.6529C1.10925 10.9724 1.30493 11.2624 1.55152 11.5064C2.05221 11.9913 2.72579 12.2588 3.42445 12.2501C4.12926 12.2508 4.80572 11.974 5.30617 11.4801L4.68186 10.8589C4.51846 11.0222 4.32422 11.1519 4.11033 11.2403C3.89643 11.3288 3.66708 11.3743 3.43544 11.3743C3.20381 11.3743 2.97446 11.3288 2.76056 11.2403C2.54666 11.1519 2.35243 11.0222 2.18902 10.8589Z" fill="#33354D"/>
</g>
<defs>
<clipPath id="clip0_5852_2561">
<rect width="14.069" height="14" fill="white" transform="translate(0.34668)"/>
</clipPath>
</defs>
</svg>`

// Customizable Area End

// Customizable Area Start
interface Props {
  mode: string;
  notesData: string;
  testId: string;
  onChange?: (value: string, delta: any, source: any, editor: ReactQuill.UnprivilegedEditor) => void;
  handleEditButtonClick: () => void;
  supplierAddData: ()=> void;
  module: "People" | "Project" | "Tender" | "Supplier" | "Partner";
  hasError?: string;
  notesError?: string; 
  classes: Record<string, string>;
  supplierRequest?: () => void;
  notesLoader: boolean;
}
// Customizable Area End


class SupplierNotes extends Component<Props> {
  constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End

	}

  // Customizable Area Start
  renderNotes = () => {
    const {notesError, notesData, mode, supplierAddData, onChange} = this.props
    const { classes } = this.props;
    const notesText =  checkHtmlContentLength(notesData)
    return mode !== configJSON.viewRecordMode ? (
      <Box className={classes.supplierNotesHeading}>
        <ReactQuill
          className={classes.supplierNoteBox}
          theme="snow"
          defaultValue={notesData}
          scrollingContainer="html"
          onChange={onChange}
          modules={{
            toolbar: {
              container: [
                [
                  "bold",
                  "italic",
                  "underline",
                  "image",
                  "clean",
                  "link",
                ],
              ],
            },
            clipboard: {
              matchVisual: false,
            },
          }}
          formats={["bold", "italic", "underline", "image", "link"]}
        />
        {!!notesError && (
          <Typography className={classes.notesValidationError}>
            {notesError}
          </Typography>
        )}
      </Box>
    ) : (
      <Box className={classes.notesDataViewBox}>
        {notesText.length === 0 ? (
          <NoRecordsFoundPage
            data-test-id={this.props.testId}
            activeModule="Suppliers"
            buttonData="Add Notes"
            loadData={supplierAddData}
            text="No notes found"
            style={{ minHeight: "450px" }}
          />
        ) : (
          <>
            <Typography
              className={classes.notesViewContent}
              dangerouslySetInnerHTML={{
                __html: `${notesData ?? ""}`,
              }}
            ></Typography>
          </>
        )}
      </Box>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      notesData,
      testId,
      mode,
      notesLoader,
      handleEditButtonClick,
    } = this.props;
    const { classes } = this.props;
    const notesText =  checkHtmlContentLength(notesData)
    return (
      <Box className={classes.containerWrapper} data-test-id={testId}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12}>
            <Box className={classes.galleryContainer}>
              <Box className={classes.topContainer}>
                <Box>
                  <CustomTypographyWeb isHeader>Notes</CustomTypographyWeb>
                </Box>
                {notesData !== "<p><br></p>" &&
                  mode === configJSON.viewRecordMode &&
                  notesText.length > 0 && (
                    <>
                      <Box className={classes.changeEditAwardLetterMode} style={{ right: 40 }}>
                        <AccessControl
                          module={this.props.module}
                          feature="update"
                        >
                          <CustomButton
                            data-test-id={configJSON.editButtonTestId}
                            colorVariant="noBackground"
                            startIcon={
                              <img src={editPageButtonSvg} alt="Edit Page" />
                            }
                            onClick={handleEditButtonClick}
                          >
                            Edit
                          </CustomButton>
                        </AccessControl>
                      </Box>
                    </>
                  )}
              </Box>

              {notesLoader ? (
                <Box data-test-id="loader" className="notesLoaderContainer">
                  <CircularProgress color="inherit" size={"5rem"} />
                </Box>
              ) : this.renderNotes()}
              <Box className={classes.rightSideOfContainer}>
                {notesData !== "<p><br></p>" &&
                  mode === configJSON.viewRecordMode && (
                    <Box className={classes.requestAwardLetterButton}>
                      <CustomButton
                        colorVariant="projectRequest"
                        startIcon={<img src={requestIcon} alt="warn" />}
                        onClick={this.props.supplierRequest}
                      >
                        Request
                      </CustomButton>
                    </Box>
                  )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles =
	createStyles({
		containerWrapper : {
      display: "flex",
      flexDirection: "column",
      fontFamily :"Source Sans Pro",
    },

    gridContainer:{
      marginBottom: '8px'
    },

    galleryContainer:{
      display: "flex",
      flexDirection: "column",
      gap: "25px",
      "& .notesLoaderContainer":{
        display: "flex",
        minHeight: "calc(40vh)",
        justifyContent: "center",
        color: "black",
        alignItems: "center",
      }
    },

    topContainer:{
      display: "flex",
      justifyContent: "space-between",
      minHeight: "100%",
      marginTop: "18px",
    },
    
    supplierNotesHeading:{
      width:"100%",
      minHeight: "300px"
    },

    changeEditAwardLetterMode: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      position: "sticky",
      top: 100,
    },

    rightSideOfContainer:{
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: "100%",
    },

    requestAwardLetterButton:{
      position: "sticky",
      alignSelf: "flex-end",
      bottom: 10,
      width: '150px'
    },

    notesDataViewBox :{
      fontFamily :"Source Sans Pro",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "23.5px",
      minHeight: "300px"
    },

    notesValidationError : {
      fontFamily :"Source Sans Pro",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "23.5px",
      color: "#f44336"
    },

    supplierNoteBox:{
      "& .quill" :{
        fontFamily :"Source Sans Pro",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "23.5px",
        height: "100%"
      },
      "& .ql-clipboard": {
        position: "fixed !important",
        left: "50% !important",
        top: "50% !important",
        display: "none"
      },
      "& .ql-toolbar.ql-snow" :{
        width: "100%",
        border: "none",
        backgroundColor: "#CAECF054",
        borderRadius: "5px 5px 0 0",
      },
      "& .ql-container.ql-snow":{
       border: "none",
       fontSize: "16px",
       fontFamily :"Source Sans Pro",
      },
      "& .ql-tooltip.ql-editing": {
        left: "unset!important"
      }
   },
   
   notesViewContent: {
      wordBreak: 'break-word',
     "& p img" : {
       width: "270px",
       height: "200px"
     }
   }
	})
// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(SupplierNotes);
export { SupplierNotes };
// Customizable Area End
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "automatedemailsending";
exports.labelBodyText = "automatedemailsending Body";

exports.btnExampleTitle = "CLICK ME";

exports.confirmEndpoint = "/bx_block_people/people/reject_deletion"

exports.confirmation_eng = "Please provide your consent below to keep your CV"
exports.confirmation_it = "Fornisci qui sotto il tuo consenso per conservare il tuo CV"
exports.consentButton_eng = "I Consent"
exports.consentButton_it = "Acconsento"
exports.refuseConsentButton_eng = "I Do Not Consent"
exports.refuseConsentButton_it = "Non acconsento"
// Customizable Area End
import React, { useEffect, useState } from "react";
import { createStyles, withStyles } from "@material-ui/styles";
import {
    Box,
    Snackbar,
    Typography,
} from "@material-ui/core";
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';

export interface StyledReportSnackBarProps {
    classes?: any,
    visible: boolean,
    message: string,
    onClose?: () => void,
    onClickResult: () => void
}



export const ReportSnackBar: React.FC<StyledReportSnackBarProps> = ({
    classes,
    message,
    visible,
    onClose,
    onClickResult
}) => {
    const [isVisible, setVisible] = useState<boolean>(visible);

    useEffect(() => {
        setVisible(visible)
    }, [visible])


    const toggleClose = () => {
        setVisible(!isVisible);
        onClose?.()
    }

    const onClickLink = () => {
        onClickResult()
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={isVisible}
            onClose={onClose}
            style={{zIndex: 999999}}
        >
            <Alert icon={false} data-testid="snackBarCloseTestId" onClose={toggleClose}>
                <Box className={classes.exportBox}>
                    <Box>
                        {message}
                        <Link underline="always" className={classes.linkResult} onClick={onClickLink} color="textPrimary">View result</Link>
                    </Box>  
                </Box>
            </Alert>
        </Snackbar>
    )
}

const styles = () =>
createStyles({
    importExportMenuItem: {
        padding: '10px 20px',
        color: '#0094A7',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16px',
        fontFamily: 'Source Sans Pro',
        cursor: 'pointer',
        transition: "background-color 0.3s",
        width: '110px',
        '&:hover': {
            backgroundColor: "#F0F0F0",
        }
    },

    customPaper: {
        marginTop: '4px',
        borderRadius: '4px',
        border: '0.5px solid #0094A7',
        boxShadow: '-4px 4px 30px 0px rgba(0, 148, 167, 0.16)',
        backgroundColor: '#FFF',
        position: "absolute",
        zIndex: 5,
    },

    progressBox:{
        marginRight: "10px"
    },

    exportBox: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
    },

    exportProgressBar:{
        top:0,
        left:0,
        bottom:0,
        right:0,
        position:"absolute",
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
    },

    linkResult: {
        paddingLeft: 4,
        cursor: 'pointer'
    }

});

const StyledReportSnackBar = withStyles(styles)(ReportSnackBar)
export {
    StyledReportSnackBar
}

import React from "react";

// Customizable Area Start
import {
  Button,
  TextField,
  FormControlLabel,
  Snackbar,
  CircularProgress,
  createStyles,
} from "@material-ui/core";

const styles = createStyles({
  search: {
    position: "relative",
    borderRadius: "8px",
    backgroundColor: "white",
    "& fieldset": {
      border: "1px solid #E8E8E8 !important",
    },
    "&:hover": {
      backgroundColor: "white",
    },
    marginLeft: 0,
    width: "40%",
    "@media (max-width: 960px)": {
      width: "100%",
    },
    "& .MuiOutlinedInput-adornedStart": {},
    "& .MuiOutlinedInput-input": {
      padding: "14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
  },
  searchIcon: {
    padding: "2px",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  peopleContainerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    "@media (max-width: 573px)": {
      flexWrap: "wrap",
      height: "auto",
    },
    backgroundColor: "#FFF",
    height: "60px",
    padding: "0 15px",
    "@media (max-width: 767px)": {
      padding: "0 5px",
    },
    "@media (max-width: 960px)": {
      padding: "0 10px",
    },
  },

  searchResultContainer: {
    display: "flex",
    "@media (max-width: 573px)": {
      justifyContent: "space-between",
    },
    alignItems: "center",
    "@media (max-width: 513px)": {
      gap: "13px",
    },
    gap: "20px",
    "@media (max-width: 482px)": {
      marginRight: "0px",
      gap: "5px",
    },
    marginRight: "14px",
    "@media (max-width: 453px)": {
      gap: "0px",
    },
  },
  saveSearchText: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "0em",
  },
  searchResultText: {
    fontFamily: "Source Sans Pro",
    fontSize: "20px",
    fontStyle: "normal",
    maxWidth: "fit-content",
    fontWeight: 600,
    lineHeight: "16px",
    color: "#000",
    "@media (max-width: 960px)": {
      fontSize: "17px",
    },
    width: "100%",
    "@media (max-width: 767px)": {
      fontSize: "14px",
    },
  },
  saveSearchCheckbox: {
    color: "#3695A7 !important",
  },
  importExportMenu: {
    padding: 0,
  },
  importExportMenuItem: {
    padding: "10px 20px",
    color: "#0094A7",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    fontFamily: "Source Sans Pro",
    cursor: "pointer",
    transition: "background-color 0.3s",
    width: "110px",
  },
  importExportMenuButton: {
    width: "170px",
    backgroundColor: "#0094A7",
    color: "white",
    textTransform: "none",
    borderRadius: "8px",
  },
  customPaper: {
    marginTop: "4px",
    borderRadius: "4px",
    border: "0.5px solid #0094A7",
    boxShadow: "-4px 4px 30px 0px rgba(0, 148, 167, 0.16)",
    backgroundColor: "#FFF",
    position: "absolute",
    zIndex: 5,
  },

  addPeopleBtn: {
    width: "100px",
    border: "1.5px solid #01949A",
    color: "#01949A",
    textTransform: "none",
    borderRadius: "8px",
    height: 45,
    "@media (max-width: 960px)": {
      maxWidth: 50,
      minWidth: 50,
      fontSize: 12,
      height: 38,
    },
  },

  btnContainer: {
    "@media (max-width: 573px)": {
      margin: "5px 0px 5px auto",
    },
    display: "flex",
    gap: "16px",
    "@media (max-width: 960px)": {
      gap: "7px",
    },
  },

  searchAutocompleteBox: {
    "&::-webkit-scrollbar": {
      width: "6px",
      height: "6px",
      borderRadius: "14px",
      backgroundColor: "#E8E8E8",
    },
    overflowX: "hidden",
    position: "absolute",
    marginTop: "8px",
    width: "40%",
    maxHeight: "350px",
    overflowY: "auto",
    zIndex: 99,
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "8px 8px 16px 0px rgba(0, 148, 167, 0.16)",
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0094A7",
      borderRadius: "14px",
    },
    "@media (max-width: 960px)": {
      width: "100%",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#E8E8E8",
    },
  },
});

import PeopleDataTableController, {
  Props,
  configJSON,
} from "./PeopleDataTableController";
import withStyles from "@material-ui/core/styles/withStyles";
import TableData from "../../../components/src/Table/Table.web";
import NoRecordsFoundPage from "../../../components/src/Table/NoRecordsFoundPage.web";
import {
  closeIconSvg,
  searchIconSvg,
  highlightMatchingCharacters,
  SaveSearchCheckBox,
  notifyCheckedIcon,
} from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";
import Loading from "../../../components/src/Loading/Loading";
import ExportData from "../../importexportdata/src/ExportData.web";
import { TableLayout } from "../../../components/src/WebAppLayout/TableLayout.web";
import { SaveRounded } from "@material-ui/icons";
import { CustomeSaveSearchPopper } from "../../../components/src/Popper/CustomeSaveSearchPopper.web";
import { saveUncheckIcon } from "../../filteritems/src/assets";
import ActionControl from "../../../components/src/Permission/ActionControl";
import TruncatedTextWithTooltipWeb from "../../../components/src/Chart/TruncatedTextWithTooltip.web";
import { truncateText } from "../../../components/src/Utils";
// Customizable Area End

export class PeopleDataTable extends PeopleDataTableController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  handleFocus = () => {
    this.setState({ hasFocus: true });
  };

  autoCompleteEndAdornment = () => {
    const { searchQuery, isLoadingAutoComplete } = this.state;
    if (searchQuery) {
      return (
        <div
          data-testid="clearSearchBtn"
          style={{
            display: "flex",
            background: "none",
            boxShadow: "none",
            alignItems: "center",
            margin: "0 16px",
            justifyContent: "center",
          }}
          onClick={this.handleClearAutoCompleteResults}
        >
          {isLoadingAutoComplete ? (
            <CircularProgress
              color="inherit"
              size={"20px"}
              style={{ color: "black" }}
            />
          ) : (
            <img src={closeIconSvg} style={{ cursor: "pointer"}} alt="closeSearch" />
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  renderSearchField() {
    const { classes } = this.props;
    const { searchQuery } = this.state;
    return (
      <TextField
        data-testid="searchField"
        className={classes.search}
        id="standard-bare"
        variant="outlined"
        placeholder="Search people"
        value={searchQuery}
        onChange={this.handleSearchItem}
        onFocus={this.handleFocus}
        onKeyDown={this.handleActionsOnAutoSearchBar}
        InputProps={{
          startAdornment: searchQuery === "" && (
            <img src={searchIconSvg} alt="Search Icon" />
          ),
          endAdornment: this.autoCompleteEndAdornment(),
        }}
      />
    );
  }

  renderAutoCompleteItems() {
    const { classes } = this.props;
    const {
      autoCompleteOptions,
      autoCompleteOptionsLength,
      autoCompleteSelectedId,
    } = this.state;
    let autoComplete = autoCompleteOptions.slice(0, autoCompleteOptionsLength);

    if (autoCompleteOptions.length > 5 && autoCompleteOptionsLength === 5) {
      autoComplete.push({
        id: configJSON.showMoreResultsId,
        name: "Show more",
      });
    }

    return (
      autoComplete.length > 0 && (
        <div className={classes.searchAutocompleteBox}>
          {autoComplete.map((record: any, index: number) => (
            <div
              key={record.id}
              data-testid="auto-complete-item"
              style={{
                padding: "10px",
                cursor: "pointer",
                backgroundColor:
                  autoCompleteSelectedId === index ? "#F0F0F0" : "transparent",
              }}
              onMouseEnter={() =>
                this.setState({ autoCompleteSelectedId: index })
              }
              onMouseLeave={() => this.setState({ autoCompleteSelectedId: -1 })}
              onClick={() => this.handleSearchById(index)}
            >
              <TruncatedTextWithTooltipWeb
                text={highlightMatchingCharacters(
                  this.state.searchQuery,
                  truncateText(record.name, 300)
                )}
                maxWidth="95%"
              />
            </div>
          ))}
        </div>
      )
    );
  }

  renderTableOrNoRecords() {
    const { isLoading, tableInputs } = this.state;
    if (isLoading) {
      return <Loading height="72vh" background="white" />;
    } else if (tableInputs.apiData.length > 0) {
      return (
        <TableData
          navigation={this.props.navigation}
          data-testid="containerTable"
          pagination={this.state.paginationDetails}
          handlePageChange={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          onFavIconClick={(index: number, iconValue: string) =>
            this.handleFavIconClicked(index, iconValue)
          }
          handleSort={(column, sortOrder) => this.handleSort(column, sortOrder)}
          tableInputs={this.state.tableInputs}
          id={configJSON.peopleDataTableTestId}
        />
      );
    } else {
      return (
        <NoRecordsFoundPage
          loadData={this.handleLoadPeopleBtn}
          data-testid="reloadBtn"
          activeModule="people"
          text="No record found"
        />
      );
    }
  }

  renderSearchResults() {
    const { classes } = this.props;
    const {
      filterList,
      isPeopleSearchSaved,
      isShowSaveSearchLabel,
      isPeopleSavingSearch,
      savingSearchPeopleName,
      onSaveSearchErrorPeopleMessage,
      onSaveSearchErrorPeople,
    } = this.state;
    const isSaveSearch = Object.values(filterList).some(
      (array) => array.length > 0
    );
    return (
      <div className={classes.peopleContainerBox}>
        <div
          className={classes.searchResultContainer}
          style={{ display: "flex" }}
        >
          <span
            data-testid="searchResultsText"
            className={classes.searchResultText}
          >
            {isShowSaveSearchLabel === true
              ? "Saved Results"
              : "Search Results"}
          </span>
          {isSaveSearch && isShowSaveSearchLabel === undefined && (
            <FormControlLabel
              data-testid="SaveSearchLable"
              control={
                <SaveSearchCheckBox
                  data-testid="SaveSearchController"
                  icon={<img src={saveUncheckIcon} alt="" />}
                  checkedIcon={<SaveRounded />}
                  name="savedSearch-peoplemodule"
                  onChange={this.handleCheckedSaveSearch}
                  checked={isPeopleSearchSaved}
                  value={isPeopleSearchSaved}
                />
              }
              disabled={isPeopleSearchSaved}
              style={{ margin: 0 }}
              label={
                <span
                  style={{
                    fontFamily: "Source Sans Pro",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "16px",
                    color: "#2B3132",
                  }}
                >
                  Save search
                </span>
              }
            />
          )}
          {this.renderNotifyMePeople()}
        </div>
        <div className={classes.btnContainer}>
          <ActionControl module="People" feature="create">
            <Button
              data-test-id={configJSON.peopleModuleAddButtonTestId}
              onClick={this.handleAddButton}
              className={classes.addPeopleBtn}
            >
              + Add
            </Button>
          </ActionControl>
          <ExportData
            innerRef={this.refExportBox}
            navigation={this.props.navigation}
            id={""}
            testid={""}
            classes={{}}
            searchIds={this.state.searchIds}
            count={this.state.paginationDetails.totalLength}
          />
        </div>
        <CustomeSaveSearchPopper
          data-testid="peoleSaveSearchPopup"
          open={isPeopleSavingSearch}
          error={onSaveSearchErrorPeople}
          errorMsg={onSaveSearchErrorPeopleMessage}
          value={savingSearchPeopleName}
          onChange={this.onChangeSavedSeachName}
          cancelSaveSearch={this.cancelSavingProjectSearch}
          submitSaveSearch={this.savePeopleSaveSearchRequest}
        />
      </div>
    );
  }

  renderNotifyMePeople = () => {
    const { isShowSaveSearchLabel, isPeopleSearchSaved } = this.state;
    return (
      <>
        {isPeopleSearchSaved && isShowSaveSearchLabel === undefined && (
          <FormControlLabel
            data-test-id={"notifyMePeople"}
            disabled={true}
            control={
              <SaveSearchCheckBox
                checkedIcon={<img src={notifyCheckedIcon} alt="" />}
                data-test-id={"notifyMePeopleCheck"}
                value={true}
                name="notifyMe-peoplemodule"
                checked={true}
              />
            }
            style={{ margin: 0 }}
            label={
              <span
                style={{
                  fontWeight: 600,
                  fontFamily: "Source Sans Pro",
                  fontStyle: "normal",
                  color: "#2B3132",
                  fontSize: "14px",
                  lineHeight: "16px",
                }}
              >
                Notify me for new entries
              </span>
            }
          />
        )}
      </>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      hasFocus,
      hasError,
      isOpenFilterList,
      isPeopleSearchSaved,
      isResetFilters,
      filterList,
    } = this.state;
    return (
      <div data-testid="containerDivTestId" onClick={this.onClickOutsidePeople}>
        <TableLayout
          data-testid="people-tableLayout"
          module="People"
          open={isOpenFilterList}
          navigation={this.props.navigation}
          isUpdateSaveSearch={isPeopleSearchSaved}
          handleFilterList={this.handleFilterList}
          isResetFilters={isResetFilters}
          toggleOpen={this.toggleOpen}
          preFilter={filterList}
        >
          <React.Fragment>
            <div style={{ backgroundColor: "#f3f3f3" }}>
              <div style={{ marginTop: "10px" }}>
                <div
                  style={{ position: "relative", margin: "20px 0px" }}
                  ref={this.state.autocompleteRef}
                >
                  {this.renderSearchField()}
                  {hasFocus && this.renderAutoCompleteItems()}
                </div>
              </div>

              {this.renderSearchResults()}
              {this.renderTableOrNoRecords()}
            </div>
            {hasError && (
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={!!hasError}
                style={{ zIndex: 100000 }}
                onClose={() => this.setState({ hasError: "" })}
                message={hasError}
                key="error-message"
              />
            )}
          </React.Fragment>
        </TableLayout>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(PeopleDataTable);
// Customizable Area End

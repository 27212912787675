import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
export const configJSON = require("./config");
import { SearchApiEndpoint } from "./assets";
import { capitalizeFirstLetter } from "../../../components/src/Utils";
// Customizable Area End

// Customizable Area Start
export interface ValidResponseType {
  data: object;
  errors: Array<ErrorPayloadType>;
}
export interface ErrorPayloadType {
  key: string;
  message: string;
  account: string;
  comments : {
    account: string;
  }
}
export interface ErrorResponseType {
  errors: string;
}
export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}

export interface RequestGetData {
  data : RequestViewData
}

export interface RequestDropdownOption {
  value : string;
  label : string ;
}

export interface RequestGetValue {
  id: string,
  module: string,
  recordName: string
}
export interface RequestViewData {
    id: string,
    type: string,
    attributes: RequestAttributesData
}

export interface AdvanceSearchData {
  results : {
    data: AdvanceSearch[]
  },
  data : AdvanceSearch[]
}

export interface AdvanceSearch {
  id: string,
  type: string,
  supplier_name: string,
  attributes: {
    id: string,
    project_name: string,
    people: {
      name : string
    },
    company_name: string;
    name: string;
  }
}

export interface RequestableSearch {
    id : string,
    record : string
}

export interface RequestAttributesData {
  account_id: number,
  status: string,
  created_at: string,
  updated_at: string,
  module_name: string,
  role: string,
  record_details: string,
  company_name: string,
  requestable: {
    id: number,
    company_name : string,
    project_name : string,
    first_name: string,
    last_name: string,
    name : string,

  },
  requested_by: string,
  comments: {
      data : Array<RequestCommentData>
  }
}
export interface RequestCommentData {
  id: string,
  type: string,
  attributes: {
      id: number,
      account_id: number,
      commentable_id: number,
      commentable_type: string,
      comment: string,
      created_at: string,
      updated_at: string,
      commentable: {
          id: number,
          sender_id: number,
          status: string,
          account_group_id: string,
          request_text: string,
          rejection_reason: string,
          created_at: string,
          updated_at: string,
          account_id: number,
          role: string,
          module_name: string,
          company_name: string,
          requestable_type: string,
          requestable_id: string,
          record_details: string
      },
      account: {
          account_id: number,
          full_name: string,
          company_name: string
      },
      file : {
          file_name: string,
          link: string,
          upload_date: string,
          file_id: number
      }
  }
}

export interface RequestData {
  companyName : string,
  role : string,
  moduleName : string,
  status : string;
  newRecord : string,
  requestableId : string,
  existingRecord : string | null | undefined,
  commentData : RequestCommentData[]
  
}

export interface RequestAddData {
  companyName : string,
  role : string,
  moduleName : string,
  status : string;
  existingRecord : string,
  newRecord : string,
  comment : string,
  selectedFile: File | null ;
  requestableId : string,
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  status : Array<RequestDropdownOption>;
  requestViewData : RequestViewData | undefined;
  comment : string;
  fileLink : string;
  fileName : string;
  selectedFile : File | null;
  requestData : RequestData;
  staticRequestData : RequestData;
  hasNameError : boolean;
  hasRoleError : boolean;
  hasModuleError : boolean;
  hasRecordError : boolean;
  hasCommentError : boolean;
  hasStatusError : boolean;
  hasUpdateBtn : boolean;
  hasStatusBtn : boolean;
  hasExistingRecord : boolean;
  hasError : string;
  companyName : string;
  requestId : string;
  requestValue : RequestGetValue;
  companyOptions : Array<RequestDropdownOption>;
  roleOptions : Array<RequestDropdownOption>;
  moduleOption : Array<RequestDropdownOption>;
  isExisting : boolean;
  hasFileError: string;
  accountId: string;
  searchData : string;
  existingSearchList : Array<RequestableSearch>;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class RequestViewDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getRequestViewDataApiCallId: string | null = null;
  postRequestDataApiCallId : string | null = null;
  updateRequestDataApiCallId : string | null = null;
  getSearchDataApiCallId : string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.NavigationPayLoadMessage), 
      getName(MessageEnum.RequestData),
      getName(MessageEnum.RequestDataTableMessage)    
    ];

    this.state = {
      status : [{value : "Open", label : "Open" }, {value : "Pending", label:"Pending"}, {value : "Closed", label:"Closed"}],
      companyOptions : [{value : "Profert", label : "Profert" },{value : "Bonifica", label : "Bonifica" },{value : "Renardet Oman", label : "Renardet Oman" }, {value : "Renardet SA", label : "Renardet SA" } ],
      roleOptions : [{value : "Tech Manager", label : "Tech Manager"}, {value : "Business Development Manager", label : "Business Development Manager"}, {value : "HR", label : "HR"}],
      moduleOption : [ {value : "People", label : "People" }, {value : "Project", label : "Project" },{value : "Tender", label : "Tender" }, {value : "Supplier", label : "Supplier" }, {value : "Partner", label : "Partner" }],
      requestViewData : undefined,
      hasNameError: false,
      hasRoleError: false,
      hasModuleError: false,
      hasRecordError : false,
      hasCommentError : false,
      hasStatusError : false,
      hasUpdateBtn: false,
      hasStatusBtn : false,
      hasExistingRecord: false,
      requestValue: {
        id: "",
        module: "",
        recordName: "",
      },
      hasError : "",
      companyName : "",
      requestId : "",
      isExisting: false,
      hasFileError: "",
      accountId : "",
      searchData : "",
      existingSearchList : [],
      comment : "",
      fileLink : "",
      fileName : "",
      selectedFile : null,
      requestData : {
        companyName : "",
        role : "",
        moduleName : "",
        status : "",
        newRecord : "",
        existingRecord : "",
        requestableId : "",
        commentData : []
     },
     staticRequestData : {
      companyName : "",
      role : "",
      moduleName : "",
      status : "",
      newRecord : "",
      existingRecord : "",
      requestableId : "",
      commentData : []
     },
    }
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
       // Customizable Area Start
       const Id = this.props.navigation.getParam("id");

    if (Id) {
      this.handleGetRequestViewData();
      this.setState({requestId : Id })
    }

    const accountIdString: string | null = localStorage.getItem("currentUser");
    const accountId = accountIdString ? JSON.parse(accountIdString) : null;
    const userId = accountId?.id ?? null;
    this.setState({ accountId: userId });
       // Customizable Area End
  }

  async receive(from: string, message: Message) {
        // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.isValidResponseController(responseJson)) {
        this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
      }
      if (this.isInValidResponseController(responseJson)) {
        this.apiFailureCallBacksController(apiRequestCallId, responseJson);
      }
    }else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let data = message.getData(getName(MessageEnum.RequestData));
      this.setState({requestValue : data , isExisting: true }, ()=>this.handleGetNavigationData())
     }
        // Customizable Area End
  }
     // Customizable Area Start
     isValidResponseController = (responseJson: ValidResponseType) => {
      return responseJson && !responseJson.errors;
    };
  
    isInValidResponseController = (responseJson: InvalidResponseType) => {
      return responseJson && responseJson.errors;
    };
  
    apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: RequestGetData & AdvanceSearchData) => {
      if (apiRequestCallId === this.getRequestViewDataApiCallId) {
        this.getRequestViewSuccessCallBack(responseJson)
      }
      if (apiRequestCallId === this.postRequestDataApiCallId) {
        this.postRequestDataSuccessCallBack(responseJson)
      }
      if (apiRequestCallId === this.updateRequestDataApiCallId) {
        this.updateRequestDataSuccessCallBack(responseJson)
      }
      if (apiRequestCallId === this.getSearchDataApiCallId) {
        this.getExistingSearchDataSuccessCallBack(responseJson)
      }
    };
  
    apiFailureCallBacksController = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
      if (apiRequestCallId === this.getRequestViewDataApiCallId) {
        this.getRequestViewFailurCallBack(responseJson)
      }
      if (apiRequestCallId === this.postRequestDataApiCallId) {
        this.postRequestDataFailurCallBack(responseJson)
      }
      if (apiRequestCallId === this.updateRequestDataApiCallId) {
        this.updateRequestDataFailurCallBack(responseJson)
      }
    };

    apiCall = async (valueData: {
      contentType?: string;
      method?: string;
      endPoint?: string;
      body?: {};
      apiType?: string;
      type?: string;
    }) => {
      let { contentType, method, endPoint, body } = valueData;
      const token = (await localStorage.getItem("authToken")) || "";
      let header = {
        "Content-Type": contentType,
        token,
      };
      let requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      body &&
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
           body
        );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return requestMessage.messageId;
    };

  handleBackButton = () => {
    const backBtn = new Message(getName(MessageEnum.NavigationMessage));
    backBtn.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "RequestReport"
    );
    backBtn.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(backBtn);
  }

    handleGetRequestViewData = async () => {
      const Id = this.props.navigation.getParam("id")

      this.getRequestViewDataApiCallId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: `${configJSON.getRequestApiEndPoint}/${Id}`,
      });
    };
  
    getRequestViewSuccessCallBack = (responseJson : RequestGetData ) =>{
        const  responseData = responseJson?.data?.attributes
        if(responseData.status === "closed"){
            this.setState({hasStatusBtn : true})
            if(!window.location.pathname.includes("RequestView")){
              const toAddRequest = new Message(getName(MessageEnum.NavigationMessage));
              toAddRequest.addData(
                getName(MessageEnum.NavigationTargetMessage),
                "RequestReport"
              );
              toAddRequest.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
              this.send(toAddRequest);
            }
        }
        const getRequestdata = {
          companyName : responseData?.company_name,
          role : responseData?.role,
          moduleName : responseData?.module_name,
          status : capitalizeFirstLetter(responseData.status),
          newRecord : responseData?.record_details,
          existingRecord : this.handleExistingResponse(responseJson),
          requestableId : JSON.stringify(responseData?.requestable?.id),
          commentData : responseData?.comments?.data
        }
        this.setState({requestViewData : responseJson?.data , requestData : getRequestdata , staticRequestData : getRequestdata })
    }

  handleExistingResponse = (responseJson: RequestGetData) => {
    const moduleName = responseJson.data.attributes.module_name
    const requestable = responseJson.data.attributes.requestable
    if (moduleName === "People") {
      return `${requestable?.first_name} ${requestable?.last_name}`
    } else if (moduleName === "Project") {
      return requestable?.project_name
    } else if (moduleName === "Tender") {
      return requestable?.name
    } else if (moduleName === "Supplier") {
      return requestable?.company_name
    } else if (moduleName === "Partner") {
      return requestable?.company_name
    }
  }

    getRequestViewFailurCallBack = (responseJson: InvalidResponseType) => {
      this.setState({hasError : responseJson.errors[0].message })
    }

    getExistingSearchData = async () =>{
      const module = this.state.requestData?.moduleName
      const moduleName = module ? module : "People" 
      const apiEndPoint = SearchApiEndpoint[ moduleName as keyof typeof SearchApiEndpoint]

      this.getSearchDataApiCallId = await this.apiCall({
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: `${apiEndPoint}${this.state.searchData}`,
      });
    }

    getExistingSearchDataSuccessCallBack = (responseJson : AdvanceSearchData) => {
       const moduleName = this.state.requestData.moduleName
      if(moduleName === "People"){
      const searachValue = responseJson.results.data.map((item: AdvanceSearch) => ({
          id: item.id,
          record: item.attributes.people.name
      }));
       this.setState({ existingSearchList: searachValue});
      }else if(moduleName === "Project"){
        const searachValue = responseJson.results.data.map((item: AdvanceSearch) => ({
          id: item.id,
          record: item.attributes.project_name
      }));
        this.setState({ existingSearchList: searachValue});
      }else if(moduleName === "Tender"){
        const searachValue = responseJson.data.map((item: AdvanceSearch) => ({
          id: item.id,
          record: item.attributes.name
      }));
        this.setState({ existingSearchList: searachValue});
      }else if(moduleName === "Supplier"){
        const searachValue = responseJson.data.map((item: AdvanceSearch) => ({
          id: item.id,
          record: item.supplier_name
      }));
        this.setState({ existingSearchList: searachValue});
      }else if(moduleName === "Partner"){
        const searachValue = responseJson.data.map((item: AdvanceSearch) => ({
          id: item.id,
          record: item.attributes.company_name
      }));
        this.setState({ existingSearchList: searachValue});
      }
  }

    handleExistingSearchData = (event: React.ChangeEvent<HTMLTextAreaElement>) =>{
         const searchValue = event.target.value
        this.setState({searchData : searchValue },()=>this.getExistingSearchData())
    }

    handleGetNavigationData = () =>{
      const requestdata = this.state.requestValue
      this.setState({
        requestData : {
          requestableId: requestdata?.id,
          moduleName: requestdata?.module,
          existingRecord: requestdata?.recordName,
          companyName: "",
          role: "",
          status: "",
          newRecord: "",
          commentData: []
        }
      })
    this.handleExisitngData()
  }

  handleExisitngData = () => {
    this.setState({ hasExistingRecord: true })
  } 

    handleExisitngRecord = () =>{
      this.setState({hasExistingRecord : false})
    }

    handleSearchSelected = (newValue: RequestableSearch) => {
      this.setState(prevState => ({
        hasNameError: false,
        requestData: {
          ...prevState.requestData,
          existingRecord: newValue?.record,
          requestableId: newValue?.id
        }
      }));
    }
    
    handleCompanyName = (event: string) => {
      this.setState(prevState => ({
        hasNameError: false,
        requestData: {
          ...prevState.requestData,
          companyName: event,
        }
      }));
    }

    handleRoleValue = (event: string) => {
      this.setState(prevState => ({
        hasRoleError: false,
        requestData: {
          ...prevState.requestData,
          role: event,
        }
      }));
    }

    handleModuleName = (event: string) => {
      this.setState({existingSearchList : []})
      this.setState(prevState => ({
        hasModuleError: false,
        hasExistingRecord: false,
        requestData: {
          ...prevState.requestData,
          moduleName: event,
          existingRecord: ""
        }
      }));
    }

    handleRadioExistingRecord = () =>{
      this.setState({
        isExisting: true,
      });
    }

    handleRadioNewRecord = () =>{
      this.setState({
        isExisting: false,
      });
    }

    handleStatusData = (event: string) => {
      this.setState(prevState => ({
        hasStatusError: false,
        requestData: {
          ...prevState.requestData,
           status: event,
        }
      }));
    }

    handleRecordData = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newRecordData = event.target.value
     this.setState(prevState => ({
      hasRecordError: false,
       requestData: {
         ...prevState.requestData,
         newRecord: newRecordData,
       }
     }));
   }

    handleCommentData = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
       const commentData = event.target.value
      this.setState({comment : commentData , hasCommentError: false});
    }

    handleRequestCancelBtn = () =>{
      this.setState({
         requestData: this.state.staticRequestData,
         comment : "",
         selectedFile : null
      })
      this.handleBackButton();
    }

    handleUploadButtonClick = (uploadFileRef: React.RefObject<HTMLInputElement>) => {
      if (uploadFileRef.current) {
        uploadFileRef.current.click();
      }
    };

    isInValidFiles = (file: File) => {
      const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
      const allowedExtensions = ['.jpg', '.pdf'];
      
      if (file.size > maxFileSize) {
        return "File size exceeds the allowed limit of 5MB.";
      }
      
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (!fileExtension || !allowedExtensions.includes(`.${fileExtension}`)) {
        return "Only JPG and PDF files are allowed.";
      }
      
      return "";
    }
  
    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        if (this.isInValidFiles(file)){
          this.setState({selectedFile : null, hasFileError : this.isInValidFiles(file)});
        } else {
          this.setState({selectedFile : file, hasFileError: ""})
          event.target.value = "";
        }
      }
    }
    
    handleCommentFile = (fileUrl : string) =>{
      const file = fileUrl;
      if (file) {
        window.open(file, '_blank');
      }
    } 
    
    handlePostRequestData = async () => {
       if(this.state.requestData.companyName === ""){
           this.setState({hasNameError : true});
           return;
       }
       if(this.state.requestData.role === ""){
        this.setState({hasRoleError : true});
        return;
       }
       if(this.state.requestData.moduleName === ""){
        this.setState({hasModuleError : true});
        return;
      }
      const falsyRecordError = this.state.isExisting ? this.state.requestData.existingRecord : this.state.requestData.newRecord


      if( !falsyRecordError ){
        this.setState({hasRecordError : true});
        return;
      } else {
        this.setState({hasRecordError : false});
      }

      if(this.state.comment === ""){
        this.setState({hasCommentError : true});
        return;
      } else {
        this.setState({hasCommentError : false});
      }
        let formdata = new FormData();
        formdata.append("request[account_id]", this.state.accountId);
        formdata.append("request[role]", this.state.requestData.role);
        formdata.append("request[requestable_id]", this.state.requestData.requestableId);
        formdata.append("request[company_name]", this.state.requestData.companyName);
        formdata.append("request[module_name]", this.state.requestData.moduleName);
        formdata.append("request[comments_attributes][][comment]",this.state.comment);
        formdata.append("request[comments_attributes][][account_id]", this.state.accountId);
        formdata.append("request[record_details]", this.state.requestData.newRecord);
        if(this.state.selectedFile){
        formdata.append("request[comments_attributes][][file]", this.state.selectedFile);
        }

        this.postRequestDataApiCallId = await this.apiCall({
            method: configJSON.exampleAPiMethod,
            endPoint: configJSON.getRequestApiEndPoint,
            body: formdata,
        });
    };

    postRequestDataSuccessCallBack = (responseJson : RequestGetData ) =>{
      const  responseData = responseJson?.data?.attributes
      const createRequestdata = {
        companyName : responseData?.company_name,
        role : responseData?.role,
        status : capitalizeFirstLetter(responseData.status),
        moduleName : responseData?.module_name,
        newRecord : responseData?.record_details,
        existingRecord : this.handleExistingResponse(responseJson),
        requestableId : JSON.stringify(responseData?.requestable?.id),
        commentData : responseData?.comments?.data
      }
      this.setState({requestViewData : responseJson?.data , requestData : createRequestdata , staticRequestData: createRequestdata})
      this.handleBackButton();
    }
  
    postRequestDataFailurCallBack = (responseJson: InvalidResponseType) => {
      if(responseJson.errors[0].account){
        this.setState({hasError : responseJson.errors[0].account })
      }else{
        this.setState({hasError : responseJson.errors[0].comments.account })
      }
    }

    handleUpdateRequestData = async () => {
      if(this.state.comment === ""){
        this.setState({hasCommentError : true});
        return;
      }
      if(this.state.requestData.status === ""){
        this.setState({hasStatusError : true});
        return;
      }
      let formdata = new FormData();
      formdata.append("request[comments_attributes][][comment]", this.state.comment);
      formdata.append("request[comments_attributes][][account_id]", this.state.accountId);
      formdata.append("request[record_details]", this.state.requestData.newRecord);
      formdata.append("request[status]", this.state.requestData.status.toLowerCase());
      if(this.state.selectedFile){
      formdata.append("request[comments_attributes][][file]", this.state.selectedFile);
      }

      this.updateRequestDataApiCallId = await this.apiCall({
          method: configJSON.apiPatchMethod,
          endPoint: `${configJSON.getRequestApiEndPoint}/${this.state.requestId}`,
          body: formdata,
      });
  };

  updateRequestDataSuccessCallBack = (responseJson : RequestGetData ) =>{
    const  responseData = responseJson?.data?.attributes
    const updateRequestdata = {
      companyName : responseData?.company_name,
      role : responseData?.role,
      moduleName : responseData?.module_name,
      newRecord : responseData?.record_details,
      existingRecord : this.handleExistingResponse(responseJson),
      status : capitalizeFirstLetter(responseData.status),
      requestableId : JSON.stringify(responseData?.requestable?.id),
      commentData : responseData?.comments?.data
    }
    this.setState({requestViewData : responseJson?.data ,requestData: updateRequestdata, staticRequestData: updateRequestdata})
    this.handleGetRequestViewData();
    this.handleBackButton();
  }

  updateRequestDataFailurCallBack = (responseJson: InvalidResponseType) => {
    if(responseJson.errors[0].account){
      this.setState({hasError : responseJson.errors[0].account })
    }else{
      this.setState({hasError : responseJson.errors[0].comments.account })
    }
  }

  handleExistRecordNavigation = () =>{
    const { moduleName, requestableId } = this.state.requestData;
    let existRecord = window.document.getElementById('anchorID') as HTMLAnchorElement;
    existRecord.href = `/${configJSON.mappingPaths[moduleName]}/${requestableId}`
    existRecord.target = "_blank";
    existRecord.click();
	  }
     // Customizable Area End
}

import React from "react";

// Customizable Area Start
import { Box, withStyles } from "@material-ui/core";
import { bonficaLogo, renPartLogo, profertLogo, renConseilsLogo } from "./assets";

// Customizable Area End

import ForgotPasswordController, {
  Props
} from "./ForgotPasswordController.web";

export class ImageComponent extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  render() {

    const { classes } = this.props

    return (
      // Required for all blocks
      <Box className={classes.mainBox0}>
        <Box className={classes.mainBox2}>
          DATA PLATFORM
        </Box>
        <Box className={classes.mainBox3}>
          <Box style={{ flexDirection: 'column' }} className={classes.mainBox1}>
            <img src={bonficaLogo} alt=""  />
            <img src={renPartLogo} alt="" />
            <img src={profertLogo} alt="" />
            <img src={renConseilsLogo} alt="" />
          </Box>
        </Box>
      </Box>
    );
  }
}
const webStyle: any = {
  mainBox0: {
    flexDirection: 'column',
    height: "100%",
    width: "50vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Source Sans Pro",
    '@media (max-width: 600px)': {
      height: "160px",
      width: "100vw",
      position: "absolute",
      top: "120px",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 99,
    },
  },
  mainBox1: { 
    flexDirection: 'column',
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    maxWidth: "25vw",
    '@media (max-width: 600px)': {
      gap: "10px",
      alignItems: "center",
      "& img":{
        maxWidth: "100%",
        width: "100%",
      }
    },
  },
  mainBox2: {
    textAlign: "center",
    fontWeight: 700,
    fontSize: "3.5vw",
    color: "#fff",
    textShadow: "3px 4px 4px #00000052",
    paddingBottom: "2rem",
    '@media (max-width: 600px)': {
      paddingBottom: '0.5rem'
    },
  },
  mainBox3: {
    border: "2px solid rgba(255, 255, 255, 0.34)",
    borderRadius: "0 25px",
    padding: "3rem 2.5rem",
    background: "linear-gradient(211deg, rgba(255, 255, 255, 0.10) 2.28%, rgba(0, 148, 167, 0.22) 100%)",
    backdropFilter: "blur(10px)",
    '@media (max-width: 600px)': {
      padding: "1.5rem",
      backdropFilter: "blur(20px)",
      background: "linear-gradient(to top right, rgba(36, 116, 129, 1), rgba(0, 148, 167, 0.4))",
    },
    '@media (min-width: 600px) and (max-width: 1280px)': {
      backdropFilter: "blur(50px)",
    },
  }
};

export default withStyles(webStyle)(ImageComponent)

import React, { PropsWithChildren } from "react";
import { LightTooltip } from "../LightToolTip";
import { withAccess, WithAuthenticatedProps } from "./withAccess";

export interface ActionControlProps extends PropsWithChildren<WithAuthenticatedProps> {
    module: "People" | "Project" | "Tender" | "Supplier" | "Partner",
    feature: "view" | "create" | "update" | "import" | "export",
    disableView?: any;
}

const ActionControl: React.FC<ActionControlProps> = ({
    children,
    disableView,
    permissions,
    currentUser,
    module,
    feature
}) => {
    const currentRole = currentUser?.defaultRole || "HR";
    const accessList = permissions?.[currentRole];
    const accessPermission = accessList?.find(access => access.moduleName == module);
    const canAccess = accessPermission?.[feature] || false;

    if (canAccess) {
        return <>{children}</>
    } else {
        return (
            <LightTooltip title="This feature is limit access" placement="bottom">
                {disableView || <></>}
            </LightTooltip>
        )
    }
    
}

export default withAccess(ActionControl)


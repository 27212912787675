import React from "react";
// Customizable Area Start
import { Box, InputLabel, Grid, CircularProgress, Popper, Paper, Divider, FormControl, RadioGroup, FormControlLabel, Radio, Typography } from "@material-ui/core";
import FilterDropDownController, {
  Props
} from "./FilterDropDownController.web";
import { styled } from "@material-ui/core/styles";
import { close } from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";
import GenericFilterInputs from "../../../components/src/GenericFilterInputs.web";
import { mappingFilterSubItemToLabel } from "../../../components/src/MappingTableHeaderLabels";
import { accordionCollapseIcon } from "../../../components/src/FilterView/assests";
import { countCheckedTrue } from "../../../components/src/Utils";

// Customizable Area End

export default class FilterDropDown extends FilterDropDownController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDetails = (item: any, i: number) => {
    return (
      <>
        {this.renderRadioGroups(item, i)}
        {Object.keys(item.filterOption || {}).map((subItem, index) => {
          const checkBoxList = item.filterOption[subItem] || [];
          return (
            <div key={`filter-${i}-${subItem}-${index * 2}`} style={{ marginTop: "9px"}}>
              <span
                  style={{
                    color: 'black',
                    fontSize: "12px",
                    fontFamily: 'Source Sans Pro',
                    fontWeight: 700,
                    lineHeight: "16px",
                    marginLeft: "-19px",
                  }}
                >
                  {item.isSubLabel ? mappingFilterSubItemToLabel(subItem) : ""}
                </span>
                {checkBoxList.length === 0 && (
                  <div 
                    style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "#9F9F9F",
                    margin: "10px 0",
                    fontFamily: "Source Sans Pro , sans-serif",
                    display: "flex",
                    justifyContent: "center",
                    }}>No record found</div>
                )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "5px 0 0"
                }}
              >
                <GenericFilterInputs
                  data-test-id="optionList"
                  key={`field-${i}-${subItem}-${index * 2}`}
                  id={`select-${i}`}
                  checkBoxList={checkBoxList}
                  options={item}
                  radioOptionList={[]}
                  onCheckBoxListChange={(checkedList: string[]) =>
                    this.handleCheckBoxChange(checkedList, subItem, item)
                  }
                />
              </div>
            </div>
          );
        })}
        {Object.keys(item.filterOption || {}).length === 0 && !item.filterOptionForRadio && (
          <div style={{
            display: "flex",
            fontSize: "14px",
            margin: "10px 0",
            justifyContent: "center",
            fontFamily: "Source Sans Pro , sans-serif",
            fontWeight: 600,
            color: "#9F9F9F",
            }}>No record found</div>
        )}
      </>
    );
  };

  renderRadioGroups = (item: any, i:number) => {
    const { selectedGroupRelation } = this.state;
    const { filterOptionForRadio, radioOption } = item;
    return (
      <>
        {filterOptionForRadio && (
          <>
            {radioOption.length > 0 && (
              <>
                <FormControl>
                  <CustomeRadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    data-test-id="radioGroup"
                    onChange={this.handleRadioChange.bind(this)}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "nowrap",
                    }}
                  >
                    {radioOption.map((radioItem: any) => (
                      <FormControlLabel
                        key={radioItem.label}
                        data-test-id="radioLabel"
                        value={radioItem.value}
                        control={<Radio className="radioBtn" />}
                        label={radioItem.label}
                        checked={radioItem.value === selectedGroupRelation}
                      />
                    ))}
                  </CustomeRadioGroup>
                </FormControl>
                <hr />
              </>
            )}

            {Object.keys(filterOptionForRadio[selectedGroupRelation] || {}).map(
              (subItem, index) => (
               <Box key={subItem}>
                  {subItem !== "" ? (
                    <>
                      <InputLabel 
                        className="filterLabel" 
                        onClick={() =>
                          this.toggleInnerRadioAcrd(
                            i,
                            selectedGroupRelation,
                            subItem
                          )
                        }
                      >
                        <Typography>
                          {subItem}
                        </Typography>
                        {countCheckedTrue(filterOptionForRadio[selectedGroupRelation][subItem]) > 0 && (
                          <span className="filterCountBadge">
                            {countCheckedTrue(filterOptionForRadio[selectedGroupRelation][subItem])}
                          </span>
                        )}
                        <img
                            style={{transform:  filterOptionForRadio[
                              selectedGroupRelation
                            ][subItem].show ? "rotate(180deg)" : "rotate(270deg)"}}
                            src={accordionCollapseIcon}
                            alt="collapse"
                        />
                      </InputLabel>
                      <Box style={{ padding: "5px 20px 5px 10px" }}>
                        {filterOptionForRadio[
                              selectedGroupRelation
                            ][subItem].show && 
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              margin: "5px 0 0"
                            }}
                          >
                            <GenericFilterInputs
                                key={`${index * 2 + 3}`}
                                id=""
                                checkBoxList={
                                  filterOptionForRadio[selectedGroupRelation][
                                    subItem
                                  ].optionsList || []
                                }
                                options={item}
                                radioOptionList={[]}
                                onCheckBoxListChange={(checkedList) =>
                                  this.handleCheckBoxChange(
                                    checkedList,
                                    subItem,
                                    item,
                                    selectedGroupRelation
                                  )
                                }
                              />
                          </div>
                        }
                      </Box>
                    </>
                  ) : (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <GenericFilterInputs
                        key={`${index * 2 + 3}`}
                        id=""
                        checkBoxList={
                          filterOptionForRadio[selectedGroupRelation][subItem]
                            .optionsList || []
                        }
                        options={item}
                        radioOptionList={[]}
                        onCheckBoxListChange={(checkedList) =>
                          this.handleCheckBoxChange(
                            checkedList,
                            subItem,
                            item,
                            selectedGroupRelation
                          )
                        }
                      />
                    </div>
                  )}
                </Box>
              )
            )}
          </>
        )}
      </>
    );

  }

  renderSummary = (item: any, index: number) => {
    const { text, filterOption, show, filterOptionForRadio } = item;
    const total = Object.keys(filterOption).length > 0  ? filterOption : filterOptionForRadio

    return (
        <InputLabel className="filterLabel" data-test-id="FilterTitle" onClick={()=>this.toggleMainLabel(index)}>
          {text.toLowerCase()}
          {countCheckedTrue(total) > 0 && (
            <span className="filterCountBadge">
              {countCheckedTrue(total)}
            </span>
          )}
          <img
              style={{transform: show ? "rotate(180deg)" : "rotate(270deg)"}}
              src={accordionCollapseIcon}
              alt="collapse"
            />
        </InputLabel>
    );
  };

  renderAccordion = (item: any, index: number) => {
    return (
      <Box key={index}>
        {this.renderSummary(item, index)}
        <Box style={{ padding: "0 20px" }}>
          {item.show && this.renderDetails(item, index)}
        </Box>
        <Divider style={{margin: "12px 23px 12px 0"}}/>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open } = this.props;
    const isDisabled = !Object.values(this.state.filterList).some((array:any) => array.length > 0 || array === true);
    // Customizable Area End
    return (
      // Customizable Area Start
      <FilterPopper 
        open={open} 
        transition 
        disablePortal 
        style={{ position: 'relative' }}
      >
        <Paper className="cardStyle">
          <Box className="closeIcon">
            <img
              id= "close"
              src={close}
              onClick={this.clickedCloseIcon}
              alt=""
            />
          </Box>
          {this.state.isLoader ? (
            <Box className="loader-filter">
              <CircularProgress
                color="inherit"
                size={"3rem"}
                style={{ color: "black" }}
              />
            </Box>
          ) : (
            <>
              <Box className="filter-dialog-containt">
                <div className="container">
                {this.state.filterMenu.length > 0 &&
                  this.state.filterMenu.map(this.renderAccordion)}
                </div>
              </Box>
              <Box className="filter-dialog-containt-footer">
                <Grid container spacing={2} justifyContent="flex-end">
                  <Grid item>
                    <CustomButton
                      id="cancel"
                      colorVariant="special"
                      data-test-id="resetButton"
                      onClick={this.handleResetFilters}
                      style={{
                        minWidth: "90px",
                        lineHeight: "16px",
                      }}
                      disabled={isDisabled}
                    >
                      Reset
                    </CustomButton>
                  </Grid>
                  <Grid item>
                    <CustomButton
                      id="save"
                      onClick={this.handleSaveFilter}
                      style={{
                        minWidth: "90px",
                        lineHeight: "16px",
                      }}
                      disabled={isDisabled}
                    >
                      Apply
                    </CustomButton>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Paper>
      </FilterPopper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const CustomeRadioGroup = styled(RadioGroup)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  "& .radioBtn":{
    color: "#3695A7 !important",
  }
})

export const FilterPopper = styled(Popper)({
  zIndex: 1000,
  position: "relative",
  "& .cardStyle" :{
    width: "265px",
    height: "400px",
    maxHeight: "calc(100vh - 350px)",
    minHeight: "315px",
    borderRadius: 8,
    border: "1px solid #E0E0E0",
    background: "#FFF",
    boxShadow: "0px 4px 11px 1px rgba(0, 0, 0, 0.09)",
    padding: "20px 0px 20px 20px",
    position: "absolute",
    right: 0,
    top: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
    "& .loader-filter": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    "& .filter-dialog-containt": {
      display: "contents",
      "& .container":{
        overflowY: "scroll",
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#0094A7",
          borderRadius: "22px",
        },
        width: "100%",
        paddingTop: "8px",
        "&::-webkit-scrollbar": {
          backgroundColor: "#E8E8E8",
          borderRadius: "22px",
          height: "9px",
          width: "9px",
        }, 
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#E8E8E8",
        },
        height: "fit-content",
      }
    },
    "& .filter-dialog-containt-footer": {
      margin: "18px 18px 0 auto"
    },
    "& .closeIcon": {
      position: "absolute",
      top: 1,
      right: 9,
      cursor: "pointer",
    },
    "& .filterCountBadge": {
      backgroundColor: "#3695A7",
      borderRadius: "35px",
      padding: "0px 8px",
      fontFamily: "Source Sans Pro",
      fontSize: "12px",
      marginLeft: 10,
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0em",
      textAlign: "left",
      color: "white"
    },
    "& .filterItem": {
      display: "flex",
      flexDirection: "column"
    },
    "& .filterLabel": {
      fontWeight: 600,
      color: "#2B3132",
      textTransform: "capitalize",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& img":{
        marginLeft: "auto",
        marginRight: "23px",
        cursor: "pointer"
      }
    }
  }
});

// Customizable Area End

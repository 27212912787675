import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { NavbarSections } from "../../../components/src/WebAppLayout/config";
import { favEmpty, favFilled } from '../src/assets';
import { buildUrlParams } from "../../../components/src/Utils";

export interface ValidResponseType {
  data: object;
  errors: Array<ErrorPayloadType>;
}
export interface ErrorPayloadType {
  key: string;
  message: string;
}
export interface ErrorResponseType {
  errors: string;
}
export interface InvalidResponseType {
  errors: Array<ErrorPayloadType>;
}

export interface TenderData {
  data : Array<TenderDataResponse>
  meta : TenderDataMeta
 }
 
 export interface TenderDataResponse {
  id: string,
  type: string,
  attributes: {
      id: number,
      name: string,
      tender_code: string,
      tender_date: string,
      award_letters: string,
      tender_status: string,
      tender_type: string,
      favourite:{
        is_favourite: boolean | null,
        favourite_id: number | string | undefined | null,
      },
      company_detail: {
          data: {
              id: string,
              type: string,
              attributes: {
                  id: number,
                  group_company: string,
                  country: string,
                  client: string,
                  client_name: string,
                  sector: string,
                  type_of_currency: string,
                  currency_value: string,
                  value_of_work_range: string,
                  fee:string,
                  services: {
                      data: [
                        {
                          id: string,
                          type: string,
                          attributes: {
                              id: number,
                              name: string
                          }
                      }
                      ]
                  }
              }
          }
      }
  }
}

export interface TenderDataMeta {
  current_page: number,
  next_page: number,
  prev_page: number,
  total_pages: number,
  total_count: number
  search_ids?: number[]
 }
export interface TenderDataCompanyDetail {
  id: number,
  group_company?: string,
  country: string,
  client: string,
  client_name: string,
  sector: string,
  type_of_currency?: string,
  currency_value?: string,
  value_of_work_range: string,
  fee?: string,
  services: {
    data: [
      {
        id: string,
        type: string,
        attributes: {
          id: number,
          name: string
        }
      }
    ]
  }
}

type TenderFilType = {[key:string]: string[] | boolean | string}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  info: any;
  data: any;
  token: any;
  googleChartData: any;
  hasError: string | null;
  isTenderSavingSearch: boolean;
  onSaveSearchErrorTender: boolean;
  onSaveSearchErrorTenderMessage: string;
  savingSearchTenderName: string;
  isLoading: boolean;
  searchQuery: string;
  tenderPaginationDetails: {
    currentPage: number;
    rowsPerPage: number;
    totalLength: number;
    rowsPerPageOptions: number[];
  };
  tableTenderInputs: {
    apiData: TenderDataResponse[];
    colSpanList: TenderDataResponse[];
    columsList: TenderDataResponse[];
    columnSep: TenderDataResponse[];
  };
  open: boolean;
  currentSortColumn: string;
  sortOrder: string;
  hasImportExportClicked: boolean,
  isTenderSearchSaved: boolean,
  tenderFilterObj: TenderFilType,
  isResetTenderFilters: boolean;
  searchIds: number[];
  isShowSaveSearchTenderLabel: boolean | undefined;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VisualAnalyticsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetDataCallId: any;
  // Customizable Area Start
  getTenderApiCallId: string | null = null;
  apiForFavouriteTender: string | null = null;
  apiForSaveTenderSearch: string | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: null,
      info: {
        labels: [],
        data: [],
        barColors: [],
      },
      data: {
        weekly: {
          labels: ["week1", "week2", "week3", "week4", "week5"],
          data: [[5], [9], [3], [6], [2]],
          barColors: ["#7db6b0"],
        },
        monthly: {
          labels: [
            "Jun",
            "Fab",
            "Mar",
            "Apr",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nom",
            "Dec",
          ],
          data: [[9], [5], [6], [3], [2], [7], [1], [4], [2], [6], []],
          barColors: ["#7db6b0"],
        },
      },
      googleChartData: ["Title", "Value"],
      hasError: null,
      isTenderSavingSearch: false,
      isLoading: true,
      searchQuery: '',
      tableTenderInputs: {
        apiData: [],
        colSpanList: [],
        columsList: [],
        columnSep: [],
      },
      tenderPaginationDetails: {
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 75, 100],
        currentPage: 0,
        totalLength: 0,
      },
      sortOrder: "",
      open: true,
      currentSortColumn: "",
      hasImportExportClicked: false,
      isTenderSearchSaved: false,
      tenderFilterObj: {},
      isResetTenderFilters: false,
      searchIds: [],
      isShowSaveSearchTenderLabel: false,
      savingSearchTenderName: "",
      onSaveSearchErrorTenderMessage: "",
      onSaveSearchErrorTender: false,
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.isValidResponseController(responseJson)) {
        this.apiSuccessCallBacksController(apiRequestCallId, responseJson);
      }

      if (apiRequestCallId === this.apiForFavouriteTender){
        this.favouriteTenderResponse(responseJson);
      }

      if (apiRequestCallId === this.apiForSaveTenderSearch) {
        this.saveTenderSearchResponse(responseJson)
      }
      
      if (this.isInValidResponseController(responseJson)) {
        this.apiFailureCallBacksController(apiRequestCallId, responseJson);

      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.handleTenderData();
    localStorage.removeItem('tenders');
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  // Customizable Area Start

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      JSON.stringify(prevState.tenderFilterObj) !== JSON.stringify(this.state.tenderFilterObj)
    ) {
      this.setState((prevState) => ({
        searchQuery: "",
      }), () => {
        this.handleTenderData();
      });
    }
  }

  handleTenderFilterObj = (value: TenderFilType, isApplySaveSearch?:boolean | undefined) => {
    this.setState({ tenderFilterObj: value, isShowSaveSearchTenderLabel: isApplySaveSearch , isTenderSearchSaved: !!isApplySaveSearch });
  }

  handleCheckedTenderSaveSearch = () => {
    if(!this.state.isTenderSearchSaved){
      this.setState({
        isTenderSavingSearch: true,
        onSaveSearchErrorTender: false
      })
    }
  }

  onChangeTenderSavedSeachName = (event: React.ChangeEvent<HTMLInputElement>) => { 
    this.setState({
      onSaveSearchErrorTender: false,
      savingSearchTenderName: event.target.value
    })
  }

  cancelSavingTenderSearch = ()=>{
    this.setState({
      savingSearchTenderName: '',
      isTenderSavingSearch: false,
      isTenderSearchSaved: false,
    })
  }

  saveTenderSearchResponse = (resp: any)=>{
    if (this.handleGetTenderResponsesError(resp)) {
      this.setState({
        isTenderSavingSearch: false,
      });
      return;
    }
    if(resp.data) {
      this.setState({
        savingSearchTenderName: '',
        isTenderSavingSearch: false,
        isTenderSearchSaved: true,
        hasError: null
      })
    }
  }

  handleGetTenderResponsesError = (tenderError: any) => {
    if (tenderError.errors && tenderError.errors.length > 0) {
      let errorMsg = "";
      tenderError.errors.forEach((err: any) => {
        errorMsg += Object.values(err)[0] + "\n";
      });
      this.setState({
        hasError: errorMsg,
      });
      return true;
    } else if (tenderError.error) {
      this.setState({
        hasError: tenderError.error,
      });
      return true;
    } else {
      return false;
    }
  };

  saveTenderSaveSearchRequest = () => {
    const {
      savingSearchTenderName,
      searchQuery,
      sortOrder,
      tenderFilterObj,
      currentSortColumn,
    } = this.state;

    if(savingSearchTenderName === ''){
      this.setState({
        onSaveSearchErrorTender: true,
        onSaveSearchErrorTenderMessage: configJSON.onSaveSearchErrorMessage
      })
      return;
    }
    let isFIlter = Object.values(tenderFilterObj).some((array:any) => array.length > 0);
    if(!isFIlter){
      this.setState({
        onSaveSearchErrorTenderMessage: configJSON.onSaveSearchNoFilterMessage,
        onSaveSearchErrorTender:true
      })
      return
    }
    const body = {
      search_name: savingSearchTenderName,
      filters: {
        ...(searchQuery ? { project_name: searchQuery } : {}),
        ...tenderFilterObj,
        ...(currentSortColumn ? {
          sort_key: currentSortColumn,
          sort_direction: sortOrder,
        } : {})
      },
      type: "BxBlockProfile::TenderSavedSearch",
    }      
    const url = `${configJSON.apiEndPointForSaveProjectSearch}`

    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForSaveTenderSearch = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleLoadTenderBtn = async () => {
    this.setState(
      {
        searchQuery: "",
        isResetTenderFilters: true,
        currentSortColumn: "",
        sortOrder: "ASC",
        tenderFilterObj: {},
        searchIds:[]
      },  async () => {
        try {
          this.getTenderApiCallId = await this.apiCallTenderModule({
            contentTypeTender: configJSON.exampleApiContentType,
            methodTender: configJSON.getApiMethodType,
            endPointTender: `${configJSON.getTenderDataEndpoint}?page=1&per_page=10`
          });
          this.setState({ isLoading: true });
        } catch (error) {
          console.error('Error in handleLoadTenderBtn:', error);
        }
      })
  }

  isValidResponseController = (responseJson: ValidResponseType) => {
    return responseJson && !responseJson.errors;
  };

  isInValidResponseController = (responseJson: InvalidResponseType) => {
    return responseJson && responseJson.errors;
  };

  apiSuccessCallBacksController = (apiRequestCallId: string, responseJson: TenderData) => {
    if (apiRequestCallId === this.getTenderApiCallId) {
      this.getTenderDataSuccessCallBack(responseJson)
    }
  };

  apiFailureCallBacksController = (apiRequestCallId: string, responseJson: InvalidResponseType) => {
    if (apiRequestCallId === this.getTenderApiCallId) {
      this.getTenderDataFailurCallBack(responseJson)
    }
  };

  findActiveTabForNavbar = () => {
    return NavbarSections.findIndex((section) => section === "Tenders");
  };

  handleTenderAddView = () =>{
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "TenderModuleAdd"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  }

  toggleOpen = () => {
    this.setState({
      open: !this.state.open
    });
  };

  apiCallTenderModule = async (valueTenderData: {
     methodTender?: string;
     contentTypeTender?: string;
     endPointTender?: string;
     typeTender?: string;
     apiTypeTender?: string;
     }) => {
     let { contentTypeTender, methodTender, endPointTender } = valueTenderData;
    const token = (await localStorage.getItem("authToken")) || "";
    let headerTender = {
      "Content-Type": contentTypeTender,
       token,
     };
    let requestMessageTender = new Message(
     getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageTender.addData(
     getName(MessageEnum.RestAPIRequestMethodMessage),
     methodTender
    );
    requestMessageTender.addData(
     getName(MessageEnum.RestAPIResponceEndPointMessage),
     endPointTender
    );
    requestMessageTender.addData(
     getName(MessageEnum.RestAPIRequestHeaderMessage),
     JSON.stringify(headerTender)
     );
    runEngine.sendMessage(requestMessageTender.id, requestMessageTender);
    return requestMessageTender.messageId;
   };

 handleChangePage = (event: React.ChangeEvent<HTMLInputElement> , newPage: number) => {
  this.setState(
    (prevState, props) => ({
      tenderPaginationDetails: {
        ...prevState.tenderPaginationDetails,
        currentPage: newPage,
      },
    }),
    () => {
      this.handleTenderData()
    }
  );
};

handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState(
    (prevState, props) => ({
      tenderPaginationDetails: {
        ...prevState.tenderPaginationDetails,
        rowsPerPage: +event.target.value,
        currentPage: 0,
      },
    }),
    () => {
      this.handleTenderData()
    }
  );
};

handleSort(columnName: string, sortOrder: string) {
  const sort = sortOrder === "ASC" ? "DESC" : "ASC";
  this.setState({
    sortOrder: sort,
    currentSortColumn: columnName,
    tableTenderInputs: {
      ...this.state.tableTenderInputs,
    }
  }, () => this.handleTenderData())
}
 
 handleTenderData = async (searchQuery?: string ) =>{
  const { tenderPaginationDetails, tenderFilterObj, currentSortColumn, sortOrder } = this.state
  const { currentPage, rowsPerPage } = tenderPaginationDetails;
  const queryParams =  buildUrlParams(tenderFilterObj);

  let endpoint = ""

  if(!currentSortColumn && !sortOrder){
    this.setState({isLoading : true});
  }
  currentSortColumn && queryParams.append('sort_key', currentSortColumn);
  sortOrder && queryParams.append('sort_direction', sortOrder.toLowerCase());

  if(!searchQuery){
    endpoint = `${configJSON.getTenderDataEndpoint}?page=${currentPage + 1}&per_page=${rowsPerPage}&${queryParams.toString()}&advance_search=${this.state.searchQuery}`;
  }
  else{
    this.setState({searchQuery: searchQuery.trim()});
    endpoint = `${configJSON.getTenderDataEndpoint}?advance_search=${searchQuery}&page=${currentPage + 1}&per_page=${rowsPerPage}&${queryParams.toString()}`;
  }
  
  this.getTenderApiCallId = await this.apiCallTenderModule({
    contentTypeTender: configJSON.exampleApiContentType,
    methodTender: configJSON.getApiMethodType,
    endPointTender: endpoint,
  });
}

getTenderDataSuccessCallBack = (responseJson: TenderData) => {
  this.setState({
    isLoading: false,
    isResetTenderFilters: false
  });

  let data = responseJson.data;

  if (data && Array.isArray(data)) {
    const apiData: TenderDataResponse[] = this.prepareTenderModuleTableResult(data);
    const tableTenderInputs = {
      apiData,
      colSpanList: configJSON.tenderModuleColSpanListDefiniation,
      columsList: this.hasFormatColumnSortingOrder(),
      columnSep: [],
    };

    let totalLength: number = 0,
      currentPage: number = 1;

    if (responseJson.meta?.total_count && responseJson.meta?.current_page) {
      totalLength = responseJson.meta.total_count;
      currentPage = responseJson.meta.current_page;
    }

    const tenderPaginationDetails = {
      rowsPerPageOptions: this.state.tenderPaginationDetails.rowsPerPageOptions,
      rowsPerPage: this.state.tenderPaginationDetails.rowsPerPage,
      totalLength: totalLength,
      currentPage: currentPage - 1,
    };
    const searchIds = responseJson.meta?.search_ids || [];    
    this.setState({
      tenderPaginationDetails,
      tableTenderInputs,
      searchIds
    });
  } else {
    const tableTenderInputs = {
      apiData: [],
      colSpanList: configJSON.tenderModuleColSpanListDefiniation,
      columsList: configJSON.tenderModuleColumnListDefiniation,
      columnSep: [],
    };

    this.setState({
      tableTenderInputs,
    });
  }
};

getTenderDataFailurCallBack = (responseJson: InvalidResponseType) => {
  if (this.handleGetTenderResponsesError(responseJson)) {
    this.setState({
      isTenderSavingSearch: false,
      isResetTenderFilters: false,
    });
  }
}

prepareTenderModuleTableResult = (data: TenderDataResponse[])=>{
  return data.map((project: any) => {
    let company_details : any = {};
    let iconValue: string = favEmpty;
    const { attributes: projectDetails } = project;
    if (
      projectDetails.company_detail &&
      projectDetails.company_detail.data &&
      projectDetails.company_detail.data.id
    ) {
      const attributes = projectDetails.company_detail.data.attributes;
      company_details = this.getTenderModuleCompanyDetails(
        attributes,
        projectDetails.company_detail.data.id
      );
      if (attributes.sector && attributes.sector.id) {
        company_details.sector = attributes.sector.name;
      }
      if (attributes.services && Array.isArray(attributes.services.data)) {
        const services = attributes.services.data
          .map((service: any) => service.attributes.name)
          .join(",");
        company_details.services = this.truncateTenderText(services, 25);
      }
    }

    if (projectDetails.favourite && projectDetails.favourite.is_favourite) {
      iconValue = favFilled;
    }

    const viewedProjectDetails = this.getTenderModuleProjectDetails(
      projectDetails,
      company_details,
      projectDetails.connections,
      iconValue
    );
    return this.removeUndefinedTenderPropertiesFromGivenObject(viewedProjectDetails);
  });
}

getTenderModuleCompanyDetails = (attributes: TenderDataCompanyDetail, id:string)=>{
  return {
    client: this.truncateTenderText(attributes?.client, 25),
    client_name: this.truncateTenderText(attributes?.client_name, 25),
    country: attributes?.country,
    group_company: attributes?.group_company,
    sector: attributes?.sector,
    value_of_work_range: attributes?.value_of_work_range,
    company_detail_id: id,
  }
}

getTenderModuleProjectDetails = (projectDetails: any, company_details: any, connections: any, iconValue:string )=>{
  return {
    name: this.truncateTenderText(projectDetails?.name, 25),
    id: projectDetails.id,
    tender_code: this.truncateTenderText(projectDetails?.tender_code, 20),
    services: company_details?.services,
    iconValue,
    tender_status: projectDetails?.tender_status,
    tender_type: projectDetails?.tender_type,
    favourite: projectDetails?.favourite,
    ...connections,
    ...company_details,
  }
}

removeUndefinedTenderPropertiesFromGivenObject = (obj:string) => {
  const result : any = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== undefined && value !==null && value !== '') {
      result[key] = value;
    }
  }
  return result;
}

truncateTenderText = (text:string, truncateLength: number)=>{
  if(text === undefined || text === null) return ''
  if(text.length > truncateLength){
    return `${text.slice(0, truncateLength)}...`
  }else{
    return text
  }
}

hasFormatColumnSortingOrder = () => {
  const { currentSortColumn, sortOrder } = this.state;
  const columnName = currentSortColumn === "type" ? "tender_type" : currentSortColumn;
  return configJSON.tenderModuleColumnListDefiniation.map((column: { dataLabel: string; sortOption: { isSortable: boolean }; }) => ({
    ...column,
    sortOption: columnName === column.dataLabel &&
      column.sortOption?.isSortable
      ? { ...column.sortOption, sortOrder }
      : column.sortOption
  }));
}

handleFavTenderIconClicked = (rowIndex: number) => {
  const { tableTenderInputs } = this.state;
  const tender:any = tableTenderInputs.apiData[rowIndex];
  const favouriteable_id = tender.id;

  const tenderHeader = {
    "Content-Type": "application/json",
    token:
      typeof window !== "undefined"
        ? localStorage.getItem("authToken")
        : null,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  let tenderUrl: string = "";
  let tenderMethod: string = "";
  if (tender?.favourite?.is_favourite) {
    tenderUrl = `${configJSON.apiEndPointForFavouriteProject}/${tender.favourite.favourite_id}`;
    tenderMethod = configJSON.apiDeleteMethod;
    const newtableTenderInputs = tableTenderInputs.apiData.map((rectd: TenderDataResponse) => {
      if (rectd.id !== tender.id) return rectd;
    
      const shouldClearSuppliertd = !!this.state.tenderFilterObj.favourites;
      if (shouldClearSuppliertd) return {...rectd, id: ""};
  
      return {
        ...rectd,
        favourite: {
          is_favourite: false,
          favourite_id: null,
        },
        iconValue: favEmpty,
      };
    });
    this.setState({
      tableTenderInputs: {
        ...tableTenderInputs,
        apiData: newtableTenderInputs.filter((objectT) => !!objectT.id),
      },
    });
  } else {
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          favouriteable_id,
          favouriteable_type: "BxBlockProfile::Tender",
        },
      })
    );
    tenderUrl = configJSON.apiEndPointForFavouriteProject;
    tenderMethod = configJSON.apiPostMethod;
  }

  this.apiForFavouriteTender = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    tenderUrl
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(tenderHeader)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    tenderMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

favouriteTenderResponse = (responseJson: any) => {
  if (this.handleGetTenderResponsesError(responseJson)) {
    this.setState({
      isTenderSavingSearch: false,
    });
    return;
  }

  const { data } = responseJson;
  if (data && data.attributes && data.attributes.favouriteable_id) {
    const favTenderId = data.attributes.favouriteable_id;
    const { tableTenderInputs } = this.state;
    const apiData = tableTenderInputs.apiData.map((row: any) => {
      if (row.id === favTenderId) {
        return {
          ...row,
          favourite: {
            is_favourite: true,
            favourite_id: data.id,
          },
          iconValue: favFilled,
        };
      } else {
        return row;
      }
    });
    this.setState({
      tableTenderInputs: {
        ...tableTenderInputs,
        apiData,
      },
      hasError: null,
    });
  }
}
  // Customizable Area End
}

import React, { Component, ReactNode } from 'react';
import { Typography, Divider, IconButton, Grid, Box, Button, WithStyles, withStyles, createStyles, Theme, Tooltip } from '@material-ui/core';
import { Documents, S, configJSON } from '../../PeopleModuleController.web';
import CustomSecondaryButton from '../../../../../components/src/CustomSecondaryButton.web';
import OtherDocuments from './OtherDocuments.web';
import CloseIcon from '@material-ui/icons/Close';
import TableDocuments from './TableDocuments.web';
import { editPageButtonSvg, projectModuleCommonStyle, requestIcon } from '../../assets';
import FileUploadButton from '../../../../../components/src/CustomFileUploadButton.web';
import {compareObjects} from "../../../../../components/src/Utils"
import CustomButton from '../../../../../components/src/CustomButton.web';
import { AccessControl } from "../../../../../components/src/Permission"
import { DisableEditButton } from '../../../../../components/src/DisableEditButton';


interface Props {
  testid: string
  documents: Documents;
  loggedInUserFullName: string | null;
  profile_photo: string | null;
  handleSubmitResume?: (newDocument: any) => void;
  handleCancelDocumentation:()=>void;
  onChange?: (objectKey: keyof S, property: any, value: any | null) => void;
  handleError?: (errorMessage: string) => void;
  handleEditDocument?: any;
  editDocumentState?:number;
  handleEditDocumentName?:any
  editedName?: string
  handleEditDocumentNameApi?:any;
  handlePeopleRequestNavigation?: () => void;
}

type State = {
  highlightUploadResume: boolean;
  internalDocuments: Documents;
  resumeError: string;
};




class Documentation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      highlightUploadResume: false,
      internalDocuments: this.props.documents,
      resumeError: ""
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    const oldDocument = prevProps.documents;
    const currentDocument = this.props.documents;
    if (!compareObjects(oldDocument, currentDocument)) {
      this.setState({
        internalDocuments: currentDocument
      })
    }

  }
  

  truncateText = (text: string | null, maxLength: number) => {
    if (text && text.length > maxLength) {
      return `${text.slice(0, maxLength) + '...'}`;
    }
    return text;
  }

  handleChange = (objectKey: any, property: any, value: any | null) => {
		this.setState((prevState: any) => {
			const currentPart = prevState[objectKey];
			const updatedObject = {
				...currentPart,
				[property]: value,
			}

			return {
				...prevState,
				[objectKey]: updatedObject,
			};
		});
	};

  handleResume = (fileUpload: any) => {
    const { internalDocuments } = this.state;
    if (fileUpload.size > 10 * 1024 * 1024) {
      this.setState({  
        internalDocuments: {
        ...internalDocuments,
        resume: null,
      },
      resumeError: "File size should not exceed 10 MB."
    })
    }else if(!fileUpload.name.includes(".pdf")){
      this.setState({  
        internalDocuments: {
        ...internalDocuments,
        resume: null,
      },
      resumeError: "Please upload a PDF file."})
    }
    
    else{ 
      this.setState({
        internalDocuments: {
          ...internalDocuments,
          resume: fileUpload,
        },
        resumeError: ""
      })
    }
  }
  handleResumeError = () => {
    return(
      <>
      {this.state.resumeError && <Typography style={{fontSize:12, color: "red", marginTop: 5}}>{this.state.resumeError}</Typography>}
      </>
    )
  }
  handleOtherFileChange = (params: any[]) => {
    const fileUpload = params[1];
    const { internalDocuments } = this.state;

    const currentDate = new Date();
    const newDocument = {
      id: Date.now(),
      document_name: internalDocuments.otherDocumentFileName,
      upload_date: `${currentDate.getFullYear()}-${currentDate.getMonth()}-${currentDate.getDate()}`,
      uploaded_by: this.props.loggedInUserFullName,
      file: fileUpload
    }
    const cloneDocument = Array.from(internalDocuments.documents);

    if (cloneDocument.length == 0 && !internalDocuments.addDocument) {
      this.setState({
        internalDocuments: {
          ...internalDocuments,
          documents: [
            ...cloneDocument,
            newDocument 
          ]
        }
      })

      return;
    }

    this.setState({
      internalDocuments: {
        ...internalDocuments,
        otherDocumentUpload: fileUpload
      }
    })

    this.handleChange?.("internalDocuments", "otherDocumentUpload", fileUpload)
  }


  actionCenterHandle(functionName: string, ...params: any[]) {
    const { internalDocuments } = this.state;

    switch(functionName) {

      case "handleCancelDocumentation":  {
        // this.resetToReadMode(internalDocuments.documents, internalDocuments.resume, false)
        this.props.handleCancelDocumentation();
        this.setState({resumeError: ""})
        break;
      }

      case "handleDeleteDocument": {
        const targetType = params[1];
        const id = params[0];

        if (targetType == "resume") {
          this.setState({
            internalDocuments: {
              ...this.state.internalDocuments,
              resume: null
            },
            resumeError: "Resume field is mandatory"
          })
        } else {
          const newDeletedDocuments = internalDocuments.documents.filter((document) => (
              (document.document_id && document.document_id != id) || 
              (document.id && document.id != id)
          ));

          const clearObject = newDeletedDocuments?.length == 0 
          ? {
            otherDocumentUpload: null,
            otherDocumentFileName: ''
          } : {}

          this.setState({
            internalDocuments: {
              ...internalDocuments,
              documents: newDeletedDocuments,
              ...clearObject
            }
          })
        }
        
        break;
      }

      case "handleSubmitResume": {
        this.props.handleSubmitResume?.(internalDocuments)
        break;
      }

      case "handleDocumentToUpload": {
        // handle upload other document
        const {otherDocumentFileName, otherDocumentUpload} = internalDocuments
        const currentDate = new Date();
        const newDocument = {
          id: Date.now(),
          document_name: otherDocumentFileName,
          upload_date: `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`,
          uploaded_by: this.props.loggedInUserFullName,
          file: otherDocumentUpload
        }

        const oldDocuments = Array.from(internalDocuments?.documents || [])

        this.setState({
          internalDocuments: {
            ...internalDocuments,
            addDocument: false,
            otherDocumentUpload: otherDocumentUpload,
            otherDocumentFileName: otherDocumentFileName,
            documents: [
              ...oldDocuments,
              newDocument
            ]
          }
        })

        this.handleChange(
          'internalDocuments',
          'addDocument',
          false
        );
        break;
      }

      default: {
        // handleFileChange
        const [uploadType, fileUpload] = params

        if (uploadType == "resume") {
          this.handleResume(fileUpload)
          
        } else {
          this.handleOtherFileChange(params)

        }
        
        break;
      }
    }
  }

  resetOtherDocument = () => {
    this.setState((prev) => {
      return {
        ...prev,
        internalDocuments: {
          ...prev.internalDocuments, 
          addDocument: true,
          otherDocumentUpload: null,
          otherDocumentFileName: '',
        }
      }
    })
  }

  renderOtherDocumentView2(isShowButton: boolean = true) {
    const { internalDocuments } = this.state;
    return (
      <Box style={{
        marginTop: '15px'
      }}>
        {internalDocuments.mode !== 'read' && (
          // addDocument Other page on
          <OtherDocuments
            label='Other document'
            showButton = {isShowButton}
            documents={internalDocuments}
            onChange={this.props?.onChange}
            handleInputChange={(e: any) => {
              this.handleChange?.(
                'internalDocuments',
                'otherDocumentFileName',
                e.target.value
              );
            }}
            data-testId="uploadOther"
            handleFileChange = {(...params) => {
              this.actionCenterHandle("handleFileChange", ...params)
            }}
            state={'documents'}
            fileProp={'otherDocumentUpload'}
            handleBackButton={() => {
              this.handleChange?.('internalDocuments', 'addDocument', false);
            }}
            handleSubmitButton={() => { 
              this.actionCenterHandle("handleDocumentToUpload") 
            }}
            handleError={this.props.handleError}
          />
        )}
      </Box>
    )
  }

  renderTableDocument() {
    const { internalDocuments } = this.state;

    return (
      <>
      {(internalDocuments.documents?.length > 0) && (
          <>
            <TableDocuments
              handleDeleteDocument={(documentId) => {
                this.actionCenterHandle("handleDeleteDocument", documentId, 'other')
              }}
              handleEditDocumentName={this.props.handleEditDocumentName}
              documents={internalDocuments.documents}
              loggedInUserFullName={this.props.loggedInUserFullName}
              profile_photo={this.props.profile_photo}
              mode={
                internalDocuments.mode !== 'read'
              }
              editDocument={this.props.handleEditDocument}
              editDocumnetID={this.props.editDocumentState}
              editedName={this.props.editedName}
              handleEditDocumentNameApi={this.props.handleEditDocumentNameApi}

            />
          </>
      )}
      </>
    )
  }

  renderActionClose = () => {
    const { internalDocuments } = this.state;

    return (
      <>
        {internalDocuments.mode !== 'read' && (
          <IconButton
            onClick={() => {
              const resume = internalDocuments.resume as any;
              if (internalDocuments.resume)
                this.actionCenterHandle("handleDeleteDocument", resume.document_id, 'resume')

            }}
            style={{ color: '#0094A7' }}>
            <CloseIcon
              style={{ width: '14px', height: '14px' }}
            />
          </IconButton>
        )} 
      </>
    )
  }

  renderResumeUpload = () =>  {
    const { internalDocuments } = this.state;

    return (
      <Grid item xs={12} sm={5}>
        {internalDocuments.mode !== 'read' ? (
          <>
            <FileUploadButton
              mandatory={!!internalDocuments.resume}
              data-testId="fileUploadButton"
              errorMessage="Please upload your resume"
              onFileChange={(...params) => {
                this.actionCenterHandle("handleFileChange", ...params)
              }}
              documentType='resume'
              state={'documents'}
              fileProp={'resume'}
              label={(!!internalDocuments.resume) ? 'Re-Upload' : 'Upload'}
              style={{
                width: '100%',
              }}
              handleError={this.props.handleError}
            />

            {(internalDocuments.resume) && (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  marginTop: '12px',
                }}>
                <Tooltip title={internalDocuments.resume instanceof File ? internalDocuments.resume.name : internalDocuments.resume.document_name}>
                <a
                  href={internalDocuments.resume instanceof File ? internalDocuments.resume.name : internalDocuments.resume.document_name}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#0094A7', textDecoration: 'none' }}>
                  <Typography
                    data-testId="downloadFile"
                    style={{
                      color: '#0094A7',
                      fontFamily: 'Source Sans Pro',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: '16px',
                      textDecorationLine: 'underline',
                    }}>
                    {this.truncateText(internalDocuments.resume instanceof File ? internalDocuments.resume.name : internalDocuments.resume.document_name, 16)}
                  </Typography>
                </a>
                </Tooltip>
                {this.renderActionClose()}
              </Box>
            )}

          </>
        ) : internalDocuments.resume && (
          <StyledDownloadButton
            data-testId="downloadBtn" 
            children={<Tooltip title={internalDocuments.resume instanceof File ? internalDocuments.resume.name : internalDocuments.resume.document_name}><span>{this.truncateText(internalDocuments.resume instanceof File ? internalDocuments.resume.name : internalDocuments.resume.document_name, 16)}</span></Tooltip>} 
            fileUrl={internalDocuments.resume instanceof File ? URL.createObjectURL(internalDocuments.resume) : internalDocuments.resume.document_link} />
            
            )}
            {this.handleResumeError()}
      </Grid>
    )
  }

  renderMainTable() {
    const { internalDocuments } = this.state;
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            border: '1px solid #9F9F9F',
            padding: '25px',
            borderRadius: '8px',
          }}>
          <Typography
            variant="h6"
            style={{
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: "16px",
              fontStyle: "normal",
              color: "#000",
              fontFamily: "Source Sans Pro",
            }}
            data-testid={'PeopleModule-Documentation-Resume'}>
            Resume
          </Typography>
          <Grid container>
            {this.renderResumeUpload()}
          </Grid>

          { internalDocuments.documents?.length > 0 && <Divider /> }

          { internalDocuments.mode == 'read' && this.renderTableDocument()}

          {
            internalDocuments.documents?.length > 0 && internalDocuments.mode !== 'read' ?
              <>
                { this.renderTableDocument() }
                <Box>
                  <CustomButton
                    colorVariant="special"
                    data-testId="addMoreBtn"
                    onClick={() => {
                      this.resetOtherDocument()
                    }}>
                    Add More
                  </CustomButton>
                </Box>
              </>
              :
              this.renderOtherDocumentView2(false)
          }
        </Box>

        {internalDocuments.mode !== 'read' && (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'end',
              gap: '20px',
              marginTop: '25px',
            }}>
            {internalDocuments.mode === 'update' && (
              <CustomButton
                colorVariant="special"
                data-testId="cancelBtn"
                disabled={this.props.documents.loading}
                onClick={() => this.actionCenterHandle("handleCancelDocumentation")}>
                Cancel
              </CustomButton>
            )}
            <CustomButton
              onClick={() => { this.actionCenterHandle("handleSubmitResume") }}
              disabled={!internalDocuments.resume || this.props.documents.loading}
              data-testid='PeopleModule-Documentation-SaveButton'>
              Save
            </CustomButton>
          </Box>
        )}
      </Box>
    )
  }


  render() {
    const { documents } = this.props;
    const { internalDocuments } = this.state;
    const webStyleDoc = projectModuleCommonStyle;

    return (
      <Grid container>
        {/* EDIT BUTTON */}
        {internalDocuments.mode === 'read' && (
          <Grid item xs={12} sm={12}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}>
              <AccessControl feature='update' module='People'>
                <CustomSecondaryButton
                  label="Edit"
                  startIcon={<img src={editPageButtonSvg} alt="Edit Page" />}
                  style={{
                    color: '#0094A7',
                    boxShadow: 'none',
                    border: 'none',
                    textDecoration: 'underline',
                    backgroundColor: 'transparent',
                    marginBottom: '27px'
                  }}
                  data-testId="editDocument"
                  onClick={() =>
                    this.props.onChange?.('documents', 'mode', 'update')
                  }
                />
              </AccessControl>
              
            </Box>
          </Grid>
        )}

        <Grid item xs={12} sm={12}>
          <Box
            data-testid={this.props.testid}
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}>
              { !internalDocuments.addDocument ?  this.renderMainTable() : this.renderOtherDocumentView2()}
          </Box>
          {internalDocuments.mode === 'read' && <Box sx={webStyleDoc.requestButtonTender} id="doc-request">
            <CustomButton
              colorVariant="projectRequest"
              startIcon={<img src={requestIcon} alt="doc-warn" />}
              onClick={this.props.handlePeopleRequestNavigation}
            >
              Request
            </CustomButton>
          </Box>
          }
        </Grid>
      </Grid>
    );
  }
}

export default Documentation;



const styles = (theme: Theme) =>
  createStyles({
    button: {
      textTransform: 'none',
      justifyContent: 'center',
      height: 35,
      flexShrink: 0,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 8,
      background: 'rgba(0, 148, 167, 0.2)',
      width: 181,
    },
    buttonText: {
      fontSize: "16px",
      fontWeight: 400,
      textDecoration: "underline",
      lineHeight: "16px",
      textTransform: 'none',
      color: "#0094A7",
      fontFamily: "Source Sans Pro",
      fontStyle: "normal",
    },
  });

interface DownloadButtonProps extends WithStyles<typeof styles> {
  children: ReactNode;
  fileUrl: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ classes, children, fileUrl }) => {
  return (
    <Button
      target="_blank"
      rel="noopener noreferrer"
      className={classes.button}
      component="a"
      href={fileUrl}
    >
      <Typography className={classes.buttonText}>{children}</Typography>
    </Button>
  );
};


const StyledDownloadButton = withStyles(styles)(DownloadButton);

import React, { Component } from 'react';
import { Box, Divider, Grid, withStyles } from '@material-ui/core';
import CustomSecondaryButton from '../../../../../components/src/CustomSecondaryButton.web';
import { IPersonalData, S } from '../../PeopleModuleController.web';
import { editPageButtonSvg, projectModuleCommonStyle, requestIcon } from '../../assets';
import CustomTypography from '../../../../../components/src/CustomTypography.web';
import CustomButton from '../../../../../components/src/CustomButton.web';
import { AccessControl } from "../../../../../components/src/Permission"
import { DisableEditButton } from '../../../../../components/src/DisableEditButton';

type Props = {
	personalData: IPersonalData;
	handleSwitchMode: (section: string, mode: 'update' | 'read' | 'post') => void;
  onChange: (objectKey: keyof S, property: any, value: any | null) => void;
  handlePeopleRequestNavigation?: ()=>void
};

type State = {};

export default class ReadPersonalData extends Component<Props, State> {
	render() {
		const {
			first_name,
			last_name,
			country_code,
			date_of_birth,
			email,
			nationality,
			phone_number,
		} = this.props.personalData;
		const webStylePersonal = projectModuleCommonStyle;

		return (
			<Box
				sx={{ display: 'flex', flexDirection: 'column', marginBottom: '52px' }}>
				{/* TOP PART */}
				<Box>
					<HeaderBox>
						<CustomTypography
							data-testid={'PeopleModule-ReadPersonalData-Personal'}
              isHeader
              style={{
                marginBottom: '7px'
              }}>
							Personal
						</CustomTypography>
						<AccessControl feature='update' module='People'>
							<CustomSecondaryButton
								label="Edit"
								startIcon={<img src={editPageButtonSvg} alt="Edit Page" />}
								style={{
									color: '#0094A7',
									boxShadow: 'none',
									border: 'none',
									textDecoration: 'underline',
									backgroundColor: 'transparent',
								}}
								id="edit-btn-personal-data"
								onClick={() =>
									{
										this.props.handleSwitchMode('personalData', 'update')
										this.props.onChange('personalData', 'isCancelButtonEnabled', true)
									}
								}
							/>
						</AccessControl>
						
					</HeaderBox>
					{/* FIRST-LAST NAME */}
					<RowBox>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={4}>
								<Box>
									<CustomTypography isLabel style={{marginBottom: '7px'}}>First name:</CustomTypography>
									<CustomTypography isContent data-testId="firstNameText">{first_name}</CustomTypography>
								</Box>
							</Grid>
							<Grid item xs={12} sm={4}>
								<CustomTypography isLabel style={{marginBottom: '7px'}}>Last name:</CustomTypography>
								<CustomTypography isContent>{last_name}</CustomTypography>
							</Grid>
						</Grid>
					</RowBox>

					{/* BIRTHDATE - NATIONALITY */}
					<RowBox>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={4}>
								<Box>
									<CustomTypography isLabel style={{marginBottom: '7px'}}>Date of Birth:</CustomTypography>
									<CustomTypography isContent>{date_of_birth}</CustomTypography>
								</Box>
							</Grid>

							<Grid item xs={12} sm={4}>
								<Box>
									<CustomTypography isLabel style={{marginBottom: '7px'}}>Nationality:</CustomTypography>
									<CustomTypography isContent>{nationality}</CustomTypography>
								</Box>
							</Grid>
						</Grid>
					</RowBox>
				</Box>

				<HeaderBox>
					<CustomTypography
            isHeader
						data-testid={'PeopleModule-ReadPersonalData-Personal'}>
						Contact details
					</CustomTypography>
				</HeaderBox>
				{/* BOTTOM PART */}

				{/* EMAIL */}
				<RowBox>
					<Grid container>
						<Grid item xs={12}>
							<Box>
								<CustomTypography isLabel style={{marginBottom: '7px'}}>Email:</CustomTypography>
								<CustomTypography isContent>{email}</CustomTypography>
							</Box>
						</Grid>
					</Grid>
				</RowBox>
				{/* PHONE */}
				<RowBox>
					<Grid item xs={12}>
						<CustomTypography isLabel style={{marginBottom: '7px'}}>Phone number:</CustomTypography>
						<CustomTypography isContent>
							{country_code} {phone_number}
						</CustomTypography>
					</Grid>
				</RowBox>

				<Divider />
				<Box sx={webStylePersonal.requestButtonTender} id="personal-reqest">
					<CustomButton
						colorVariant="projectRequest"
						startIcon={<img src={requestIcon} alt="personal-warn" />}
						onClick={this.props.handlePeopleRequestNavigation}
						data-testId="peopleRequestLabelTestID"
					>
						Request
					</CustomButton>
				</Box>
			</Box>
		);
	}
}

const RowBox = withStyles({
	root: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		marginBottom: '32px',
	},
})(Box);

const HeaderBox = withStyles({
	root: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
    marginBottom: '24px'
	},
})(Box);

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import {
  Brochure,
  BrochureData,
  DropDownItems,
  ISupplierData,
  InvalidResponseType,
  ModuleMode,
  SupplierBrochureData,
  SupplierDetailsData,
  SupplierNotesData,
  ValidResponseType,
} from "./SupplierModuleController.web";
import { getStoredDefaultRole } from "../../../components/src/ContextAPI/AdvancedFilterContext.web";
import { ErrorObject } from "./PeopleModuleController.web";
export const configJSON = require("./config");
import { countSection, handleSectionNavigateLogic, isValidLink, validateHtmlContent, removeErrorsActiveKey } from "../../../components/src/Utils";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: Record<string, string>;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  activePartnerSection: string;
  partnerId: string;
  hasPartnerError: any;
  isPartnerLoading: boolean;
  companyDetailsId: number;
  groupPartnerResponse: DropDownItems[];
  countryPartnerResponse: DropDownItems[];
  servicesPartnerResponse: DropDownItems[];
  sectorPartnerResponse: DropDownItems[];
  internalRatePartnerResponse: DropDownItems[];
  PartnerDetails: ISupplierData;
  staticPartnerDetails: ISupplierData;
  mode: string | "get" | "post" | "put";
  isAuditTrail: boolean;
  brochure: Brochure[];
  staticPartnerBrochure: Brochure[];
  brochurePartnerData: BrochureData[];
  errors: ErrorObject[];
  brochureToDeletePartner: number[];
  onBrochureUpload: boolean;
  brochureToUploadPartner: File[];
  isCancelBrochureEnabledPartner: boolean;
  documentBrochureError: "";
  isPendingApi: boolean;
  notesData: string;
  partnerNotesError: string;
  staticNotesData: string;
  partnerNotesLoader: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PartnerModuleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupPartnerApiCallId: string = "";
  getCountriesPartnerApiCallId: string = "";
  getServicesPartnerApiCallId: string = "";
  getSectorsPartnerApiCallId: string = "";
  getInternalRatePartnerApiCallId: string = "";
  getPartnerDetailsApiCallId: string = "";
  postPartnerDetailsApiCallId: string = "";
  updatePartnerDetailsApiCallId: string = "";
  getPartnerBrochureApiCallId: string = "";
  apiCallIdForPartnerUploadBrochure: string = "";
  apiCallIdForRemovePartnerDocument: string = "";
  getPartnerNotesApiCallId: string = "";
  postPartnerNotesApiCallId: string = "";
  sections: string[];
  contentPartnerRef: React.RefObject<HTMLDivElement>;
  countableSection = countSection(configJSON.PartnerModuleSections);

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
    ];

    this.sections = [
      "Partner Details",
      "Brochure",
      "Notes",
      "Connection",
      "Audit trail",
    ];

    this.state = {
      staticPartnerBrochure: [],
      brochure: [],
      brochureToDeletePartner: [],
      onBrochureUpload: false,
      brochurePartnerData: [],
      errors: [],
      brochureToUploadPartner: [],
      activePartnerSection: "Partner Details",
      hasPartnerError: "",
      partnerId: "",
      partnerNotesError: "",
      isPartnerLoading: true,
      isAuditTrail: false,
      companyDetailsId: 0,
      groupPartnerResponse: [],
      countryPartnerResponse: [],
      servicesPartnerResponse: [],
      sectorPartnerResponse: [],
      internalRatePartnerResponse: [],
      mode: configJSON.newRecordMode,
      isPendingApi: false,
      notesData: "",
      staticNotesData: "",
      PartnerDetails: {
        phoneNumber: "",
        mode: "post",
        countryCode: "",
        website: "",
        companyname: "",
        groupCompany: "",
        country: "",
        services: "",
        sectors: "",
        email: "",
        errors: [],
        internalRating: "",
        isCancelButtonEnabled: false,
      },
      staticPartnerDetails: {
        mode: "read",
        email: "",
        countryCode: "",
        phoneNumber: "",
        companyname: "",
        website: "",
        groupCompany: "",
        country: "",
        services: "",
        sectors: "",
        internalRating: "",
        isCancelButtonEnabled: false,
        errors: [],
      },
      isCancelBrochureEnabledPartner: false,
      documentBrochureError: "",
      partnerNotesLoader: false,
    };
    this.contentPartnerRef = React.createRef();

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handlePartnerAPICollection();
    this.setState({ isPartnerLoading: false });
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    const { navigation } = this.props;
    if (navigation?.getParam("partner_id") !== "add") {
      this.handleGetPartnerDetails();
      this.handleGetBrochurePartnerData();
      this.handleGetPartnerNotesData();
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.setState({ isPartnerLoading: false });
      const apiRequestPartnerCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const partnerResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponseControllerPartner(partnerResponseJson)) {
        this.apiSuccessCallBacksController(
          apiRequestPartnerCallId,
          partnerResponseJson
        );
      } else if (this.isInValidResponseControllerPartner(partnerResponseJson)) {
        this.handleNotFoundRecordPartner(partnerResponseJson);
        this.apiFailureCallBacksController(
          apiRequestPartnerCallId,
          partnerResponseJson
        );
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
		if (prevState.activePartnerSection != this.state.activePartnerSection) {
			this.countableSection.setActive(this.state.activePartnerSection)
		}
	}

  handlePartnerAPICollection = () => {
    this.getCompaniesDropdownDataPartner();
    this.getCountryDropdownDataPartner();
    this.getServicesDropdownDataPartner();
    this.getSectorsDropdownDataPartner();
    this.getInternalRateDropdownDataPartner();
  };

  apiSuccessCallBacksController = (
    apiRequestCallId: string,
    responseJson: DropDownItems[] &
      SupplierDetailsData &
      SupplierBrochureData &
      InvalidResponseType &
      SupplierNotesData
  ) => {
    const responseFunctions = {
      [this.getGroupPartnerApiCallId]: this.getGroupCompanyPartnrResponse,
      [this.getCountriesPartnerApiCallId]: this.getCountryPartnerResponse,
      [this.getServicesPartnerApiCallId]: this.getServicesPartnerResponse,
      [this.getSectorsPartnerApiCallId]: this.getSectorsPartnerResponse,
      [this.getInternalRatePartnerApiCallId]:
        this.getInternalRatePartnerResponse,
      [this.getPartnerDetailsApiCallId]: () => {
        this.handleNotFoundRecordPartner(responseJson);
        this.getPartnerDetailsResponse(responseJson);
      },
      [this.postPartnerDetailsApiCallId]: this.postPartnerDetailsResponse,
      [this.updatePartnerDetailsApiCallId]: this.updatePartnerDetailsResponse,
      [this.getPartnerBrochureApiCallId]: this.getPartnerApiResponse,
      [this.apiCallIdForPartnerUploadBrochure]:
        this.handleGetBrochurePartnerData,
      [this.apiCallIdForRemovePartnerDocument]:
        this.postUpdateBrochureApiResponse,
      [this.getPartnerNotesApiCallId]: this.partnerNotesGetApiResponse,
      [this.postPartnerNotesApiCallId]: this.partnerNotesPostApiResponse,
    };

    const responseFunction = responseFunctions[apiRequestCallId];
    if (responseFunction) {
      responseFunction(responseJson);
    }
  };

  apiFailureCallBacksController = (
    apiRequestCallId: string,
    responseJson: InvalidResponseType
  ) => {
    const apiCalls = [
      this.getGroupPartnerApiCallId,
      this.getCountriesPartnerApiCallId,
      this.getServicesPartnerApiCallId,
      this.getSectorsPartnerApiCallId,
      this.getInternalRatePartnerApiCallId,
      this.postPartnerDetailsApiCallId,
      this.updatePartnerDetailsApiCallId,
      this.getPartnerDetailsApiCallId,
    ];

    if (apiCalls.includes(apiRequestCallId)) {
      this.getResponseApiFailurePartner(responseJson);
    }
  };

  handlePartnerError = (errorMessage: string) => {
    this.setState({
      hasPartnerError: errorMessage,
    });
  };

  getCompaniesDropdownDataPartner = async () => {
    this.getGroupPartnerApiCallId = await this.apiCallPartnerModule({
      contentTypePartners: configJSON.validationApiContentType,
      methodPartners: configJSON.validationApiMethodType,
      endPointPartners: configJSON.getGroupCompaniesEndPoint,
    });
  };

  getGroupCompanyPartnrResponse = (grpRes: any) => {
    if (Array.isArray(grpRes)) {
      const groupCompanies: any = [];
      grpRes.forEach((company: any) => {
        groupCompanies.push({
          value: company.id.toString(),
          label: company.name,
        });
      });
      this.setState({ groupPartnerResponse: groupCompanies });
    }
  };

  getCountryDropdownDataPartner = async () => {
    this.getCountriesPartnerApiCallId = await this.apiCallPartnerModule({
      contentTypePartners: configJSON.validationApiContentType,
      methodPartners: configJSON.validationApiMethodType,
      endPointPartners: configJSON.getCountriesEndPoint,
    });
  };

  getCountryPartnerResponse = (cntRes: any) => {
    if (Array.isArray(cntRes)) {
      const countries: any = [];
      cntRes.forEach((service: any) => {
        countries.push({
          value: service.id.toString(),
          label: service.name,
        });
      });
      this.setState({ countryPartnerResponse: countries });
    }
  };

  getSectorsDropdownDataPartner = async () => {
    this.getSectorsPartnerApiCallId = await this.apiCallPartnerModule({
      contentTypePartners: configJSON.validationApiContentType,
      methodPartners: configJSON.validationApiMethodType,
      endPointPartners: configJSON.getSectorsEndPoint,
    });
  };

  getSectorsPartnerResponse = (sectorRes: any) => {
    if (Array.isArray(sectorRes)) {
      const sectors: any = [];
      sectorRes.forEach((sector: any) => {
        sectors.push({
          value: sector.id.toString(),
          label: sector.name,
        });
      });
      this.setState({ sectorPartnerResponse: sectors });
    }
  };

  getServicesDropdownDataPartner = async () => {
    this.getServicesPartnerApiCallId = await this.apiCallPartnerModule({
      contentTypePartners: configJSON.validationApiContentType,
      methodPartners: configJSON.validationApiMethodType,
      endPointPartners: configJSON.getServicesEndPoint,
    });
  };

  getServicesPartnerResponse = (srvRes: any) => {
    if (Array.isArray(srvRes)) {
      const services: any = [];
      srvRes.forEach((service: any) => {
        services.push({
          value: service.id.toString(),
          label: service.name,
        });
      });
      this.setState({ servicesPartnerResponse: services });
    }
  };

  getInternalRateDropdownDataPartner = async () => {
    this.getInternalRatePartnerApiCallId = await this.apiCallPartnerModule({
      contentTypePartners: configJSON.validationApiContentType,
      methodPartners: configJSON.validationApiMethodType,
      endPointPartners: configJSON.getInternalRateEndPoint,
    });
  };

  getInternalRatePartnerResponse = (srvRes: any) => {
    if (Array.isArray(srvRes)) {
      const services: any = [];
      srvRes.forEach((service: any) => {
        services.push({
          value: service.id.toString(),
          label: service.name,
        });
      });
      this.setState({ internalRatePartnerResponse: services });
    }
  };

  handleGetPartnerDetails = async () => {
    const partnerId = this.props.navigation.getParam("partner_id");
    this.getPartnerDetailsApiCallId = await this.apiCallPartnerModule({
      contentTypePartners: configJSON.validationApiContentType,
      methodPartners: configJSON.validationApiMethodType,
      endPointPartners: `${configJSON.PartnerDetailsEndPoint}/${partnerId}`,
    });
  };

  getPartnerDetailsResponse = (responseJson: SupplierDetailsData) => {
    const PartnerDetails = responseJson.data.attributes;
    const companyDetails = PartnerDetails.company_detail;

    const serviceData = companyDetails?.service || [];
    const services = serviceData.map((service: any) => service.name).join(",");

    const partnerData = {
      mode: "read",
      companyname: PartnerDetails.company_name,
      email: PartnerDetails.email_address,
      phoneNumber: PartnerDetails.phone_number.split("-")?.[1],
      countryCode: PartnerDetails.phone_number.split("-")?.[0],
      website: PartnerDetails.website,
      country: companyDetails?.country,
      services: services,
      groupCompany: companyDetails?.group_company,
      sectors: companyDetails?.sector?.name,
      isCancelButtonEnabled: true,
      internalRating: PartnerDetails.internal_rating,
      errors: [],
    };
    this.setState({
      staticPartnerDetails: partnerData,
      PartnerDetails: partnerData,
      companyDetailsId: companyDetails.id,
      partnerId: responseJson.data.id,
      mode: "get",
    });

    this.handleSwitchPartnerMode("PartnerDetails", "read");
  };

  handlePostPartnerDetails = async () => {
    this.setState({ isAuditTrail: true });

    const { PartnerDetails, servicesPartnerResponse, sectorPartnerResponse } =
      this.state;

    const { groupCompany, country, sectors, services, phoneNumber } =
      this.state.PartnerDetails;

    const sector_id = sectorPartnerResponse.find(
      (rec) => rec.label === sectors
    )?.value;

    const service_ids: number[] = [];

    services.split(",").forEach((rec) => {
      const service = servicesPartnerResponse.find(
        (service) => service.label === rec
      );
      if (service) {
        service_ids.push(parseInt(service.value));
      }
    });

    const strippedCountry = this.state.PartnerDetails.countryCode.replace(
      /\D/g,
      ""
    );

    const createData = {
      company_name: PartnerDetails.companyname,
      email_address: PartnerDetails.email,
      phone_number: phoneNumber
        ? `+${strippedCountry}-${this.state.PartnerDetails.phoneNumber}`
        : "",
      website: PartnerDetails.website,
      internal_rating: PartnerDetails.internalRating,
      company_detail_attributes: {
        country: country,
        service_ids: service_ids,
        group_company: groupCompany,
        sector_id: Number(sector_id),
      },
    };

    const httpPostBody = {
      partner: createData,
      role: getStoredDefaultRole(),
    };

    this.postPartnerDetailsApiCallId = await this.apiCallPartnerModule({
      contentTypePartners: configJSON.validationApiContentType,
      methodPartners: configJSON.apiPostMethod,
      endPointPartners: `${
        configJSON.PartnerDetailsEndPoint
      }?role=${getStoredDefaultRole()}`,
      bodyPartners: JSON.stringify(httpPostBody),
    });
  };

  postPartnerDetailsResponse = (responseJson: SupplierDetailsData) => {
    if (responseJson.error) {
      this.handlePartnerError(responseJson.error);
      return;
    }
    this.setState({ isAuditTrail: false });
    const PartnerDetails = responseJson?.data?.attributes;
    const companyDetails = PartnerDetails?.company_detail;

    const serviceData = companyDetails?.service || [];
    const services = serviceData.map((service: any) => service?.name).join(",");

    const createData = {
      mode: "read",
      phoneNumber: PartnerDetails.phone_number.split("-")?.[1],
      companyname: PartnerDetails.company_name,
      email: PartnerDetails.email_address,
      countryCode: PartnerDetails.phone_number.split("-")?.[0],
      website: PartnerDetails.website,
      groupCompany: companyDetails?.group_company,
      internalRating: PartnerDetails.internal_rating,
      country: companyDetails?.country,
      sectors: companyDetails?.sector?.name,
      services: services,
      errors: [],
      isCancelButtonEnabled: true,
    };

    this.setState({
      staticPartnerDetails: createData,
      partnerId: responseJson?.data?.id,
      companyDetailsId: companyDetails?.id,
      PartnerDetails: createData,
      activePartnerSection: "Brochure",
    });

    this.handleSwitchPartnerMode("PartnerDetails", "read");
  };

  handleUpdatePartnerDetails = async () => {
    this.setState({ isAuditTrail: true });
    const {
      PartnerDetails,
      sectorPartnerResponse,
      servicesPartnerResponse,
      companyDetailsId,
    } = this.state;
    const service_ids: number[] = [];

    const { groupCompany, services, phoneNumber, country, sectors } =
      this.state.PartnerDetails;

    services.split(",").forEach((recs) => {
      const service = servicesPartnerResponse.find((ser) => ser.label === recs);
      if (service) {
        service_ids.push(parseInt(service.value));
      }
    });

    let errors = [];

    if (service_ids.length == 0) {
      errors.push({ "company_detail.service_ids": "Atlease one service should be present" })

    }

    if (!groupCompany) {
      errors.push({ "company_detail.group_company": "Group company is required" })
    
    }

    if (!PartnerDetails.companyname) {
			errors.push({ company_name: "Company name is required" })
		}

    if (errors.length > 0) {
      this.handlePartnerChange("PartnerDetails", "errors", errors)
      return;
    }


    const sector_id = sectorPartnerResponse.find(
      (rec) => rec.label === sectors
    )?.value;

    const strippedUpdateCountry = this.state.PartnerDetails.countryCode.replace(
      /\D/g,
      ""
    );

    const updateData = {
      company_name: PartnerDetails.companyname,
      email_address: PartnerDetails.email,
      phone_number: phoneNumber
        ? `+${strippedUpdateCountry}-${this.state.PartnerDetails.phoneNumber}`
        : "",
      internal_rating: PartnerDetails.internalRating,
      website: PartnerDetails.website,
      company_detail_attributes: {
        id: companyDetailsId,
        country: country,
        group_company: groupCompany,
        service_ids: service_ids,
        sector_id: Number(sector_id),
      },
    };

    const httpPostBody = {
      role: getStoredDefaultRole(),
      partner: updateData,
    };

    this.updatePartnerDetailsApiCallId = await this.apiCallPartnerModule({
      contentTypePartners: configJSON.validationApiContentType,
      methodPartners: configJSON.apiPatchMethod,
      endPointPartners: `${configJSON.PartnerDetailsEndPoint}/${
        this.state.partnerId
      }?role=${getStoredDefaultRole()}`,
      bodyPartners: JSON.stringify(httpPostBody),
    });
  };

  updatePartnerDetailsResponse = (responseJson: SupplierDetailsData) => {
    if (responseJson.error) {
      this.handlePartnerError(responseJson.error);
      return;
    }
    this.setState({ isAuditTrail: false });
    const PartnerDetails = responseJson?.data?.attributes;
    const companyDetails = PartnerDetails?.company_detail;

    const serviceData = companyDetails?.service || [];
    const services = serviceData.map((service: any) => service?.name).join(",");

    const updateData = {
      companyname: PartnerDetails.company_name,
      email: PartnerDetails.email_address,
      phoneNumber: PartnerDetails.phone_number.split("-")?.[1],
      groupCompany: companyDetails.group_company,
      website: PartnerDetails.website,
      mode: "read",
      country: companyDetails.country,
      services: services,
      errors: [],
      countryCode: PartnerDetails.phone_number.split("-")?.[0],
      sectors: companyDetails?.sector?.name,
      internalRating: PartnerDetails.internal_rating,
      isCancelButtonEnabled: true,
    };
    this.setState({
      staticPartnerDetails: updateData,
      activePartnerSection: "Partner Details",
      PartnerDetails: updateData,
    });
    this.handleSwitchPartnerMode("PartnerDetails", "read");
  };

  handleNotFoundRecordPartner = (resp: any) => {
    if (resp?.meta?.message && !resp?.data) {
      this.setState({ isPartnerLoading: false });
      let errorMessage: string = resp.meta.message;
      this.setState(
        {
          hasPartnerError: errorMessage ? errorMessage : "Error",
        },
        () => {
          this.props.navigation.push("/Partner/add");
        }
      );
    }
  };

  isValidResponseControllerPartner = (validResp: ValidResponseType) => {
    return validResp && !validResp.errors;
  };

  isInValidResponseControllerPartner = (inValidResp: InvalidResponseType) => {
    return inValidResp && inValidResp.errors;
  };

  handleGoBackPartner = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "PartnersModuleTable"
    );
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  };

  handleChangePartnerData = (value: string, key: string) => {
    const newErrors = removeErrorsActiveKey(this.state.PartnerDetails.errors, key, {
			email: "email_address",
			phoneNumber: "phone_number",
			companyname: "company_name",
			website: "website",
			groupCompany: "company_detail.group_company",
			services: "company_detail.service_ids"
		});

    this.setState({
      PartnerDetails: { ...this.state.PartnerDetails, [key]: value, errors: newErrors },
    });
  };

  handlePartnerChange = (
    objectKey: any,
    property: string,
    value: any
  ) => {
    this.setState((prevState: any) => {
      const currPart = prevState[objectKey];
      const updatedObject = {
        ...currPart,
        [property]: value,
      };
      return {
        ...prevState,
        [objectKey]: updatedObject,
      };
    });
  };

  handleChangePartnerBrochure = (
    changePropName: any,
    updatedValue: any | null
  ) => {
    const { errors } = this.state;
    if (changePropName) {
      this.setState((prevState: any) => {
        return {
          ...prevState,
          [changePropName]: updatedValue,
        };
      });
      if (errors.find((err) => err.hasOwnProperty(changePropName))) {
        this.setState({
          errors: errors.filter((erv) => !erv.hasOwnProperty(changePropName)),
        });
      }
    }
  };

  handleDeletePartnerBrochure = (attach_id: number, typeP: string) => {
    const { brochureToDeletePartner, brochure } = this.state;
    brochureToDeletePartner.push(attach_id);
    const newCerts = brochure.filter((cert) => cert.id !== attach_id);
    this.setState({
      brochure: newCerts,
      brochureToDeletePartner,
    });
  };

  handlePartnerFileChange = (ev: any) => {
    const file = ev?.target?.files ? ev.target.files[0] : null;
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        this.setState({
          documentBrochureError: configJSON.bigFileErrorMessage,
        });
      } else {
        const filesToUpload = this.state.brochureToUploadPartner;
        filesToUpload.push(file);
        this.handleChangePartnerBrochure(
          "brochureToUploadPartner",
          filesToUpload
        );
        this.setState({ documentBrochureError: "" });
      }
      ev.target.value = "";
    } else {
      this.handlePartnerError(configJSON.fileErrorMessage);
    }
  };

  handleEditBrochureButtonClick = () => {
    this.handleChangePartnerBrochure("mode", configJSON.updateRecordMode);
    this.setState({
      isCancelBrochureEnabledPartner: true,
      mode: configJSON.updateRecordMode,
    });
  };

  removeStagedPartnerFile = (fileI: number) => {
    const { brochureToUploadPartner } = this.state;
    const newLetters = brochureToUploadPartner.filter((e, i) => i !== fileI);
    this.handleChangePartnerBrochure("brochureToUploadPartner", newLetters);
  };

  handleCancelBrochureButton = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        brochureToDeletePartner: [],
        brochure: [],
        brochureToUploadPartner: [],
        onBrochureUpload: false,
      };
    });
    this.handleGetBrochurePartnerData();
    this.handleChangePartnerBrochure("mode", configJSON.viewRecordMode);
  };

  handleSaveBrochureButton = async (): Promise<void> => {
    const { brochureToUploadPartner, brochureToDeletePartner } = this.state;
    let updatedId = this.props.navigation.getParam("partner_id");

    if (
      brochureToUploadPartner.length === 0 &&
      brochureToDeletePartner.length === 0
    ) {
      this.setState({
        activePartnerSection:  handleSectionNavigateLogic(updatedId, !this.countableSection.actived("Notes"), "Notes", "Brochure")
      });
      this.handleChangePartnerBrochure("mode", handleSectionNavigateLogic(updatedId, !this.countableSection.actived("Notes"), "post", "read"));
    }

    await this.handleAwardLetterSectionBrochure(
      brochureToUploadPartner,
      brochureToDeletePartner
    );
  };

  handleAwardLetterSectionBrochure = async (
    brochureToUploadPartner: any[],
    brochureToDeletePartner: any[]
  ): Promise<void> => {
    await this.handleBrochureUploadAndDelete(
      brochureToUploadPartner,
      brochureToDeletePartner,
      "brochure",
      "onBrochureUpload"
    );
    this.handleChangePartnerBrochure("brochureToUploadPartner", []);
    this.handleChangePartnerBrochure("brochureToDeletePartner", []);
  };

  handleGetBrochurePartnerData = async () => {
    const partnerId =  this.state.partnerId || this.props.navigation.getParam("partner_id");

    let getUrl = `${configJSON.PartnerDetailsEndPoint}/${partnerId}/get_brochures`;

    this.getPartnerBrochureApiCallId = await this.apiCallPartnerModule({
      contentTypePartners: configJSON.validationApiContentType,
      methodPartners: configJSON.validationApiMethodType,
      endPointPartners: getUrl,
    });
  };

  getPartnerApiResponse = (getResp: SupplierBrochureData) => {
    const { data } = getResp;
    let id = this.props.navigation.getParam("partner_id");
    const partnerBrochures = data.map((ob: BrochureData) => {
      return ob.attributes.brochures;
    });
    this.setState((prevState) => {
      return {
        ...prevState,
        brochure: partnerBrochures,
        staticPartnerBrochure: partnerBrochures,
      };
    });

    this.setState({
      isPendingApi: false,
      isPartnerLoading: false,
      isCancelBrochureEnabledPartner: true,
      onBrochureUpload: !partnerBrochures,
      activePartnerSection: id == "add" ? handleSectionNavigateLogic(id, !this.countableSection.actived("Notes"), "Notes", "Brochure") : this.state.activePartnerSection,
      mode: handleSectionNavigateLogic(id, !this.countableSection.actived("Notes"), "post", "get"),
    });

  };

  handleBrochureUploadAndDelete = async (
    uploadsFile: any[],
    deletesFile: any[],
    types: "brochure" | "image",
    state: keyof S
  ): Promise<void> => {
    this.handleChangePartnerBrochure("isPendingApi", true);
    for (const item of uploadsFile) {
      await this.uploadBrochureRequestPartner(item);
    }
    if (deletesFile.length > 0) {
      await this.sendDeleteBrochurePartnerRequests(deletesFile, types);
    }
  };

  uploadBrochureRequestPartner = async (fileBrochure: File) => {
    this.setState({ isAuditTrail: true });
    const partnerId = this.state.partnerId;
    if (partnerId === "") {
      this.handlePartnerError("partner id not found");
      return;
    }

    const formData = new FormData();
    formData.append("partner[files][]", fileBrochure);

    let uploadUrl = `${
      configJSON.PartnerDetailsEndPoint
    }/${partnerId}/upload_brochures?role=${getStoredDefaultRole()}`;

    this.apiCallIdForPartnerUploadBrochure = await this.apiCallPartnerModule({
      methodPartners: configJSON.apiPostMethod,
      endPointPartners: uploadUrl,
      bodyPartners: formData,
    });
  };

  sendDeleteBrochurePartnerRequests = async (array: number[], type: string) => {
    this.handleChangePartnerBrochure("isPendingApi", true);
    this.setState({ isAuditTrail: true });
    const { partnerId } = this.state;

    const deleteBrochureBody = {
      brochure_ids: array,
    };

    const url = `${
      configJSON.PartnerDetailsEndPoint
    }/${partnerId}/delete_brochures?role=${getStoredDefaultRole()}`;

    this.apiCallIdForRemovePartnerDocument = await this.apiCallPartnerModule({
      contentTypePartners: configJSON.validationApiContentType,
      methodPartners: configJSON.apiDeleteMethod,
      endPointPartners: url,
      bodyPartners: JSON.stringify(deleteBrochureBody),
    });
  };

  postUpdateBrochureApiResponse = (postupdateResp: SupplierBrochureData) => {
    this.setState({ isAuditTrail: false });
    if (postupdateResp.error) {
      this.handlePartnerError(postupdateResp.error);
      return;
    }

    const { data } = postupdateResp;
    let id = this.props.navigation.getParam("partner_id");

    if (data) {
      const newBrochure = data.map((obj: BrochureData) => {
        return obj.attributes.brochures;
      });
      this.setState((prevState) => {
        return {
          ...prevState,
          staticPartnerBrochure: newBrochure,
          isCancelBrochureEnabled: true,
          brochure: newBrochure,
          mode: handleSectionNavigateLogic(id, !this.countableSection.actived("Notes"), "post", "get"),
        };
      });
      this.handleChangePartnerBrochure("onBrochureUpload", false);
    }

    this.setState({
      activePartnerSection: handleSectionNavigateLogic(id, !this.countableSection.actived("Notes"), "Notes", "Brochure"),
      isPendingApi: false,
      mode: handleSectionNavigateLogic(id, !this.countableSection.actived("Notes"), "post", "get"),
      isPartnerLoading: false,
    });
  };

  openPartnerBrochureImage = (image: string) => {
    window.open(image, "_blank");
  };

  handleCancelPartner = () => {
    this.setState({
      PartnerDetails: this.state.staticPartnerDetails,
    });
  };

  handleCBSubmitDocument = () => {
    const partnerId = this.props.navigation.getParam('partner_id');
    const { activePartnerSection } = this.state
    this.setState({
      activePartnerSection: handleSectionNavigateLogic(partnerId, this.countableSection.get(activePartnerSection) == 1, "Audit trail", "Connections")
    })
  }

  handleSwitchPartnerMode = (
    section: string,
    mode: ModuleMode,
    onCancel?: boolean
  ) => {
    this.setState((prevState) => ({
      PartnerDetails: {
        ...(onCancel
          ? prevState.staticPartnerDetails
          : prevState.PartnerDetails),
        mode: mode,
      },
    }));
  };

  handlePartnerListItemClick = (label: string) => {
    this.setState({
			mode: 'get'
		})

    switch (label) {
      case "Partner Details":
        this.setState({ activePartnerSection: "Partner Details" });
        break;
      case "Brochure":
        this.setState({ activePartnerSection: "Brochure" });
        break;
      case "Notes":
        this.setState({ activePartnerSection: "Notes" });
        break;
      case "Connections":
        this.setState({ activePartnerSection: "Connections" });
        break;
      case "Audit trail":
        this.setState({ activePartnerSection: "Audit trail" });
        break;
    }
  };

  getResponseApiFailurePartner = (respJ: any) => {
    if (respJ.errors && respJ.errors.length > 0) {
      this.setState({
        PartnerDetails: {
          ...this.state.PartnerDetails,
          errors: respJ.errors,
        },
      });
    }
  };

  handleChangePartnerNotes = (value: string) => {
    const container = document.createElement('div');
		const containerData = container;
		containerData.innerHTML = value;

    if (!!validateHtmlContent(value)) { 

      const messageError = validateHtmlContent(value);
      
			this.setState({
				notesData: containerData.innerHTML
		 	})

			setTimeout(() => {
				this.setState({
					partnerNotesError: messageError,
				 })
			}, 200)
		}else {
			this.setState({
				notesData: container.innerHTML,
				partnerNotesError: validateHtmlContent(value)
			});
    }
  };

  handleGetPartnerNotesData = async () => {
    const getId = this.props.navigation.getParam("partner_id");

    let getUrl = `${configJSON.PartnerDetailsEndPoint}/${getId}/get_notes`;

    this.getPartnerNotesApiCallId = await this.apiCallPartnerModule({
      contentTypePartners: configJSON.validationApiContentType,
      methodPartners: configJSON.validationApiMethodType,
      endPointPartners: getUrl,
    });
  };

  partnerNotesGetApiResponse = (responseJson: SupplierNotesData) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        notesData: responseJson.notes,
        staticNotesData: responseJson.notes,
        mode:configJSON.viewRecordMode,
      };
    });

    this.setState({
      isPendingApi: false,
      isPartnerLoading: false,
    });
  };

  partnerAddData = () => {
    this.setState({ mode: configJSON.newRecordMode });
  };

  handlePartnerNotesCancelButton = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        notesData: prevState.staticNotesData,
        partnerNotesError: ''
      };
    });
    this.setState({
      mode: configJSON.viewRecordMode,
    });
  };

  handlePostPartnerNotes = async () => {
    this.setState({ isAuditTrail: true });
    const { partnerId, notesData } = this.state
    
    if(validateHtmlContent(notesData)){
      this.setState({ partnerNotesError: validateHtmlContent(notesData)})
      return;
    }

    const htmlPartner = document.createElement('div');
    const htmlPartnerData = htmlPartner;
    htmlPartnerData.innerHTML = this.state.notesData
    const inValidPartner_links = htmlPartner.querySelectorAll('a');

    inValidPartner_links.forEach(link => {
      const hrefPartner = link.getAttribute('href');
      if (!isValidLink(hrefPartner as string)) {
        link.setAttribute('href', 'https://example.com/404-page-not-found');
      }
    });
    
    
    const postId = partnerId;
    if (postId === "") {
      this.handlePartnerError("partner id not found");
      return;
    }

    const noteUrl = `${configJSON.PartnerDetailsEndPoint}/${postId}?role=${getStoredDefaultRole()}`;

    let createdData = notesData === "<p><br></p>" ? "" : htmlPartner.innerHTML;

    const httpPostNotesBody = {
      notes: createdData,
    };

    this.setState({ partnerNotesLoader: true})
      
    this.postPartnerNotesApiCallId = await this.apiCallPartnerModule({
      methodPartners: configJSON.apiPatchMethod,
      contentTypePartners: configJSON.validationApiContentType,
      endPointPartners: noteUrl,
      bodyPartners: JSON.stringify(httpPostNotesBody),
    });
  };

  partnerNotesPostApiResponse = (responseJson: any) => {
    if (responseJson.error) {
      this.handlePartnerError(responseJson.error);
      this.setState({ partnerNotesLoader: false})
      return;
    }
    const noteId = this.props.navigation.getParam('partner_id');
    const updatedNotes = responseJson?.data?.attributes.notes
    this.setState({
      activePartnerSection: handleSectionNavigateLogic(noteId, this.countableSection.get("Notes") == 1, 'Connections', "Notes"),
      isAuditTrail: false,
      staticNotesData: updatedNotes,
      notesData: updatedNotes,
      mode: handleSectionNavigateLogic(noteId, this.countableSection.get("Notes") == 1, "post", "get"),
      partnerNotesLoader: false,
    });
  };

  apiCallPartnerModule = async (valueTenderData: {
    methodPartners?: string;
    contentTypePartners?: string;
    bodyPartners?: {};
    endPointPartners?: string;
    typePartners?: string;
    apiTypePartners?: string;
  }) => {
    let {
      contentTypePartners,
      methodPartners,
      endPointPartners,
      bodyPartners,
    } = valueTenderData;
    const token = (await localStorage.getItem("authToken")) || "";
    let headerTender = {
      token,
      "Content-Type": contentTypePartners,
    };
    let requestMessageTender = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageTender.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodPartners
    );
    requestMessageTender.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointPartners
    );
    requestMessageTender.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerTender)
    );
    bodyPartners &&
      requestMessageTender.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        bodyPartners
      );
    runEngine.sendMessage(requestMessageTender.id, requestMessageTender);
    return requestMessageTender.messageId;
  };

  handlePartnerReqeust = () =>{
    const requestData = {
			id: this.state.partnerId,
			module: "Partner",
			recordName: this.state.PartnerDetails.companyname
		}
		const partner = new Message(getName(MessageEnum.NavigationRequestMessage));
		partner.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
		partner.addData(getName(MessageEnum.RequestData), requestData);
		this.send(partner);
  }

  // Customizable Area End
}

// Customizable Area Start
import React, { Component } from 'react';
import { Box, Typography, Divider, Grid } from '@material-ui/core';
import { createStyles, withStyles } from "@material-ui/styles";
import CustomInput from '../../../components/src/CustomInput.web';
import CustomPhoneInput from '../../../components/src/CustomPhoneInput.web';
import CustomButton from '../../../components/src/CustomButton.web';
import AutocompleteSelect from '../../../components/src/CustomFields/AutocompleteSelectMenu.web';
import MultiSelectMenu from "../../../components/src/CustomFields/MultiSelectMenu.web"
import { ISupplierData, DropDownItems } from './SupplierModuleController.web';
import { parseErrorResponse } from '../../../components/src/Utils';
const Config = require("./config");
const regexDialCode = Config.isDialCode;
// Customizable Area End

// Customizable Area Start
type Props = {
	supplierDetails: ISupplierData;
	emailErrors: boolean;
	phoneNumberError: boolean;
	handleChangeValue: (val: string, key: string) => void;
	handlePostSupplierData: () => void;
	handleUpdateSupplierData: () => void;
	handleSwitchMode: (section: string, mode: 'update' | 'read' | 'post', onCancel?: boolean) => void;
	handleCancelUpdate: () => void,
	isPhoneInvalid: boolean;
	groupResponse: DropDownItems[];
	countryResponse: DropDownItems[];
	servicesResponse: DropDownItems[];
	sectorResponse: DropDownItems[];
	internalRateResponse: DropDownItems[];
	classes: Record<string, string>;
};

type State = {};
// Customizable Area End

class UpdateSupplierDetails extends Component<Props, State> {
	constructor(props: any) {
		super(props);
		// Customizable Area Start
		// Customizable Area End

	}
	// Customizable Area Start
	renderPersonalError = (errorKey: string) => {
		const { errors } = this.props.supplierDetails;
		const errorP = errors?.some((error) => error.hasOwnProperty(errorKey))
		const textP = errors?.find((x) => x.hasOwnProperty(errorKey))?.[errorKey]
		
		return { error: errorP, text: textP ?? undefined }
	}
	// Customizable Area End

	render() {
		// Customizable Area Start
		const {
			mode,
			email,
			phoneNumber,
			countryCode,
			companyname,
			website,
			groupCompany,
			country,
			services,
			sectors,
			internalRating,
			isCancelButtonEnabled,
		} = this.props.supplierDetails;
		const {
			groupResponse,
			countryResponse,
			servicesResponse,
			sectorResponse,
			internalRateResponse
		} = this.props
		const emailError = this.renderPersonalError('email_address').text;
		const emailInvalid = <Typography style={{ color: '#DC2627', fontSize: '12px' }}>Enter a valid email!</Typography>
		const phoneNumberError = this.renderPersonalError('phone_number').text;
		const phoneNumberInvalid = <Typography style={{ color: '#DC2627', fontSize: '12px' }}>Enter a valid phone number!</Typography>

		const cuttedCountryCodes = countryCode?.match(regexDialCode);
        const formatedCountryCode = cuttedCountryCodes && cuttedCountryCodes.length > 0 ? cuttedCountryCodes[0] : countryCode;

		const { classes } = this.props;
		return (
			<Box className={classes.supplierModuleMainContainer} >
				<Box>
					<Box
						className={classes.supplierUpdateBox}
					>
						<Grid container >
							<Grid item xs={12} sm={8} md={8}>
							    <Typography className={classes.supplierheadtext}>Company Name</Typography>
								<CustomInput
									required
									fullWidth
									variant="outlined"
									value={companyname}
									data-test-id="companyNameTestId"
									onChangeValue={(val) =>
										this.props.handleChangeValue(val as string, 'companyname')
									}
									placeholder="Company name"
									type="text"
									error={this.renderPersonalError("company_name").error}
									helperText={this.renderPersonalError("company_name").text}
								/>
							</Grid>

						</Grid>
					</Box>
				</Box>

				<Divider />

				<Box>
					<Typography
					    data-test-id="contactDetailsTestId"
						className={classes.contactDetails}
					>
						Contact details
					</Typography>

					<Grid container >
						<Grid item xs={12} sm={8} md={8}>
						    <Typography className={classes.supplierheadtext}>Email address</Typography>
							<CustomInput
								fullWidth
								variant="outlined"
								value={email}
								onChangeValue={(e: any) =>
									this.props.handleChangeValue(e, 'email')
								}
								data-test-id="emailTestId"
								placeholder="Email address"
								type="text"
								error={this.renderPersonalError("email_address").error}
								helperText={this.props.emailErrors ? emailInvalid : emailError ?? undefined}
							/>
						</Grid>
					</Grid>

					<Box
						className={classes.phoneNumberBox}
					>
						<Typography
							className={classes.updatePhoneNumber}
						>
							Phone number
						</Typography>
						   <Grid container spacing={2}>
							   <Grid item xs={12} sm={8} md={8}>
								 <CustomPhoneInput
									 variant="outlined"
									 value={phoneNumber}
									 onChangeValue={(val: string) =>
										 this.props.handleChangeValue(val, 'phoneNumber')
									   }
									 data-testId="inputPhoneNumber"
									 label="Phone number"
									 placeholder="Phone number"
									 type="text"
									 countryCode={formatedCountryCode}
									 handleCountryCode={(val) =>
										  this.props.handleChangeValue(val, 'countryCode')
									   }
									  isPhoneInvalid={this.props.isPhoneInvalid}
									  error={this.renderPersonalError("phone_number").error}
									  countryError={this.renderPersonalError("phone_number").error}
									 helperText={this.props.phoneNumberError ? phoneNumberInvalid : phoneNumberError ?? undefined}
								   />
							     </Grid>
						      </Grid>
					         </Box>
					   <Grid container className={classes.supplierheadToptext}>
						  <Grid item xs={12} sm={8} md={8}>
						     <Typography className={classes.supplierheadtext}>Website</Typography>
							  <CustomInput
								fullWidth
								variant="outlined"
								data-test-id="websiteTestId"
								value={website}
								onChangeValue={(e: any) =>
									this.props.handleChangeValue(e, 'website')
								}
								placeholder="website"
								type="text"
								helperText={this.renderPersonalError("website")?.text}
								error={this.renderPersonalError("website").error}
							/>
						</Grid>
					</Grid>
				</Box>

				<Divider className={classes.supplierheadToptext} />

				<Box>
					<Box
						className={classes.groupCompanyCountryBox}
					>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={5} lg={4}>
								<Typography className={classes.supplierheadtext} >Group Company</Typography>
								<AutocompleteSelect
									data-test-id="groupCompanyTestId"
									value={groupCompany}
									options={groupResponse}
									helperText={this.renderPersonalError("company_detail.group_company").text || ""}
									onChange={(value: string) => this.props.handleChangeValue(value, 'groupCompany')}
									placeholder='Group Company'
									error={this.renderPersonalError("company_detail.group_company").error}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={5} lg={4}>
								<Typography className={classes.supplierheadtext}>Country</Typography>
								<AutocompleteSelect
									data-test-id="countryTestId"
									value={country}
									options={countryResponse}
									onChange={(value: string) => this.props.handleChangeValue(value, 'country')}
									placeholder='Country'
									error={false}
								/>
							</Grid>

						</Grid>
					</Box>

					<Box
						className={classes.supplierUpdateBox}
					>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={5} lg={4}>
								<Typography className={classes.supplierheadtext}>Services</Typography>
								<MultiSelectMenu
									data-test-id={"servicesTestId"}
									placeholder='Services'
									helperText={this.renderPersonalError("company_detail.service_ids").text || ""}
									error={this.renderPersonalError("company_detail.service_ids").error}
									onChange={(value: string) => this.props.handleChangeValue(value, 'services')}
									value={services}
									options={servicesResponse}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={5} lg={4}>
								<Typography className={classes.supplierheadtext}>Sector</Typography>
								<AutocompleteSelect
									data-test-id={"sectorsTestId"}
									value={sectors}
									options={sectorResponse}
									onChange={(value: string) => this.props.handleChangeValue(value, 'sectors')}
									placeholder="Sectors"
									error={false}
								/>
							</Grid>

						</Grid>
					</Box>
					<Box
						className={classes.supplierUpdateBox}
					>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={5} lg={4}>
								<Typography className={classes.supplierheadtext}>Internal Rating</Typography>
								<AutocompleteSelect
									data-test-id={"internalRateTestId"}
									value={internalRating}
									options={internalRateResponse}
									onChange={(value: string) => this.props.handleChangeValue(value, 'internalRating')}
									placeholder='Internal rating'
									error={false}
								/>
							</Grid>
						</Grid>
					</Box>
				</Box>

				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Box
							className={classes.updateSaveCancelBtn}
						>
							{isCancelButtonEnabled && (
								<CustomButton
									data-testId="btnCancelPerson"
									disabled={!isCancelButtonEnabled}
									onClick={this.props.handleCancelUpdate}
									colorVariant="special">
									Cancel
								</CustomButton>
							)}

							<CustomButton
								colorVariant="default"
								data-testid="btnSavePerson"
								onClick={
									mode === 'post'
										? this.props.handlePostSupplierData
										: this.props.handleUpdateSupplierData
								}
							// disabled={this.state.isValid}
							>
								Save
							</CustomButton>
						</Box>
					</Grid>
				</Grid>
			</Box>
		);
		// Customizable Area End
	}
}
// Customizable Area Start
const styles =
	createStyles({
		supplierModuleMainContainer: {
			display: 'flex',
			flexDirection: 'column',
			marginBottom: '52px'
		},

		supplierUpdateBox: {
			display: "flex",
			alignItems: "center",
			flexDirection: "row",
			marginBottom: "32px",
			marginTop: "32px"
		},

		contactDetails: {
			fontSize: '18px',
			fontWeight: 700,
			fontStyle: 'normal',
			lineHeight: '16px',
			marginTop: '24px',
			marginBottom: '24px',
			fontFamily: 'Source Sans Pro',
		},

		phoneNumberBox: {
			display: 'flex',
			flexDirection: 'column',
		},

		updatePhoneNumber: {
			fontSize: '16px',
			fontWeight: 600,
			fontStyle: 'normal',
			lineHeight: '16px',
			marginTop: '24px',
			fontFamily: 'Source Sans Pro',
			marginBottom: "7px"
		},

		groupCompanyCountryBox: {
			display: "flex",
			alignItems: "center",
			flexDirection: "row",
			marginBottom: "32px",
			marginTop: "32px"
		},

		supplierheadtext: {
			fontSize: '16px',
            fontWeight: 700,
            color: '#1F1F1F',
			marginBottom: "7px",
			fontStyle: 'normal',
			lineHeight: '16px',
			fontFamily: 'Source Sans Pro',			
		},

		updateSaveCancelBtn: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			gap: '20px',
			marginTop: '20px',
		},
		supplierheadToptext: {
			marginTop: "32px"
		}
		
	})
// Customizable Area End

// Customizable Area Start
export default withStyles(styles)(UpdateSupplierDetails);
export { UpdateSupplierDetails };
// Customizable Area End
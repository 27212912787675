import React, { Component, CSSProperties } from "react";
import { Button, Typography } from "@material-ui/core";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import PeopleSaveAsPdf from "../../../../saveaspdf/src/ProfertSaveAsPdf.web"

interface HeaderProps {
  peopleId: string;
  saveAsPDF?: boolean;
  navigation?: any;
  handleGoBack?: any;
}


class PeopleModuleHeader extends Component<HeaderProps> {
  handleBackClick = () => {
    this.props.navigation.push('/Modules/People')
  };

  render() {
    const style: CSSProperties = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "16px 34px 16px 24px",
      backgroundColor: "#0094A7",
      color: "#FFF",
      zIndex: 100, // This will make sure the header is rendered above other components
    };

    const { saveAsPDF } = this.props;

    return (
      <div style={style}>
        <Button
          data-testId="btnBackToPeople"
          onClick={this.props.handleGoBack}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "3px",
            color: "#FFF",
          }}
        >
          <KeyboardBackspace style={{ width: "24px", height: "24px" }} />
          <Typography style={{
            fontFamily: "Source Sans Pro",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "16px",
            textTransform: 'none',
            marginLeft: '8px'
          }}>Back</Typography>
        </Button>
        {saveAsPDF && (
          <PeopleSaveAsPdf module="people" targetId={this.props.peopleId}/>
        )}
      </div>
    );
  }
}

export default PeopleModuleHeader;

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Pagination";
exports.labelBodyText = "Pagination Body";
exports.SelectDoc = "Upload"
exports.UploadDoc = '/bx_block_pagination/products';
exports.getDoc = "bx_block_pagination/products"
exports.btnExampleTitle = "CLICK ME";
exports.placevalue = "Items on single page";
exports.Textlable = "Number of items in inventory"
// Customizable Area End
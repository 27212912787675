// Customizable Area Start
import React from "react";
import { configJSON } from "./RequestDataTableController.web";
import {
  Box,
  InputLabel,
  Paper,
  Popper,
  TextField,
  Typography,
  styled,
} from "@material-ui/core";
import CustomButton from "../../../components/src/CustomButton.web";
import GenericFilterInputs from "../../../components/src/GenericFilterInputs.web";
import {
  close,
  filterDropdownIcon,
  saveFilterIcon,
} from "../../filteritems/src/assets";
import { EditFilterButton } from "../../savesearch/src/Savesearch.web";
import { calendar } from "./assets";
import DatePicker from "react-datepicker";
import moment from "moment";
import { AccordianRequestItem } from "../../../components/src/Types";
import { setDisableForObject } from "../../../components/src/Utils";

interface FilterProps {
    anchorEl: HTMLElement | null;
    accordian: any;
    requestFilterObj: any;
    handleOpenFilter: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleCloseFilter: () => void;
    handleApplyFilters: () => void;
    handleResetFilters: () => void;
    handleDateRange: (e: any, f: string, g: string) => void;
    handleCheckChange: (checkedItems: string[], subitem: any, parentItem: any) => void;
}
// Customizable Area End

class RequestFilterComponent extends React.Component<FilterProps> {
  constructor(props: FilterProps) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDatePickerRange = (item: AccordianRequestItem, i: number) => {
    const todayDate = moment().format("DD/MM/YYYY");
    const { requestFilterObj } = this.props;

    return (
      <DateRangeContainer>
        {Object.entries(item?.dateRangeOptions || {}).map(([key, values]) => {
          return (
            <>
              {values.map((option: any) => {
                const selectedValue =
                  requestFilterObj?.[key]?.[option.label] || null;
                return (
                  <Box className="dateRange" key={`${option.label}-dateRange`}>
                    <Typography className="title">{option.label}</Typography>
                    <CustomeDatePicker
                      name={item.label}
                      selected={selectedValue}
                      dateFormat={configJSON.dateFormat}
                      placeholderText={todayDate}
                      value={option.value}
                      popperPlacement="top-start"
                      showMonthDropdown
                      showYearDropdown
                      onChange={(e) =>
                        this.props.handleDateRange(e, key, option.label)
                      }
                      data-test-id="daterange"
                      customInput={
                        <CustomDateField
                          type="text"
                          value={
                            selectedValue
                              ? moment(selectedValue).format(
                                  configJSON.dateFormat
                                )
                              : ""
                          }
                          onChange={(e) =>
                            this.props.handleDateRange(e, key, option.label)
                          }
                          variant="outlined"
                          InputProps={{
                            endAdornment: <img src={calendar} alt="calendar" />,
                          }}
                          placeholder={configJSON.dateFormat}
                        />
                      }
                    />
                  </Box>
                );
              })}
            </>
          );
        })}
      </DateRangeContainer>
    );
  };

  renderDetails = (item: AccordianRequestItem, i: number) => {
    return (
      <>
        {Object.keys(item.filterOption || {}).map((subItem, index) => {
          const checkBoxList = item.filterOption[subItem] || [];
          return (
            <ReqRadioCheckbox key={`filter-${i}-${subItem}-${index * 2}`}>
              <GenericFilterInputs
                data-test-id="optionReqList"
                id={`select-${i}`}
                key={`field-${i}-${subItem}-${index * 2}`}
                options={item}
                checkBoxList={checkBoxList}
                radioOptionList={[]}
                onCheckBoxListChange={(checkedList: string[]) =>
                  this.props.handleCheckChange(checkedList, subItem, item)
                }
              />
            </ReqRadioCheckbox>
          );
        })}
      </>
    );
  };

  renderReqSummary = (item: AccordianRequestItem, index: number) => {
    const { label } = item;
    return (
      <InputLabel className="filterTitle" data-test-id="FilterTitle">
        {label}
      </InputLabel>
    );
  };

  renderReqAccordion = (item: AccordianRequestItem, index: number) => {
    return (
      <Box key={index}>
        {this.renderReqSummary(item, index)}
        <Box style={{ padding: "0 130px 0 5px" }}>
          {this.renderDetails(item, index)}
        </Box>
        <Box style={{ padding: "0 8px" }}>
          {item.type === "datePickerRange" &&
            this.renderDatePickerRange(item, index)}
        </Box>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { anchorEl, accordian, requestFilterObj } = this.props;
    const open = Boolean(anchorEl);
    const isDisabled = !setDisableForObject(requestFilterObj)
    return (
      <span style={{ position: "relative" }}>
        <EditFilterButton
          data-test-id="filterButton"
          endIcon={
            <img
              src={filterDropdownIcon}
              style={{
                transform: anchorEl ? "rotate(180deg)" : "rotate(0deg)",
              }}
              alt="arrow"
            />
          }
          startIcon={
            <img
              src={saveFilterIcon}
              style={{ height: "20px", width: "20px" }}
              alt=""
            />
          }
          onClick={this.props.handleOpenFilter}
        >
          <Typography className="text">Filter</Typography>
        </EditFilterButton>
        <FilterReqPopper
          open={open}
          placement="bottom-end"
          anchorEl={anchorEl}
          transition
          disablePortal
          style={{ position: "relative" }}
          data-test-id="filterPopper"
        >
          <Paper className="cardReqStyle">
            <Box className="closePopper">
              <img
                id="close"
                src={close}
                onClick={this.props.handleCloseFilter}
                alt="close"
              />
            </Box>
            <div className="reqontainer">
              {accordian.length > 0 &&
                accordian.map(this.renderReqAccordion)}
            </div>
            <Box className="footerReq">
              <CustomButton
                id="cancel"
                colorVariant="special"
                data-test-id="resetReqButton"
                onClick={this.props.handleResetFilters}
                style={{
                  minWidth: "89px",
                  lineHeight: "20px",
                }}
                disabled={isDisabled}
              >
                Reset
              </CustomButton>
              <CustomButton
                id="save"
                data-test-id="saveReqButton"
                onClick={this.props.handleApplyFilters}
                style={{
                  minWidth: "119px",
                  lineHeight: "20px",
                }}
              >
                Apply
              </CustomButton>
            </Box>
          </Paper>
        </FilterReqPopper>
      </span>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export const FilterReqPopper = styled(Popper)({
  position: "relative",
  zIndex: 100,
  "& .cardReqStyle": {
    overflowY: "scroll",
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0094A7",
      borderRadius: "22px",
    },
    "&::-webkit-scrollbar": {
      backgroundColor: "#E8E8E8",
      borderRadius: "22px",
      height: "9px",
      width: "9px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#E8E8E8",
    },
    width: "243px",
    maxHeight: "60vh",
    borderRadius: 8,
    boxShadow: "0px 4px 11px 1px rgba(0, 0, 0, 0.09)",
    border: "1px solid #E0E0E0",
    position: "absolute",
    right: 0,
    background: "#FFF",
    display: "flex",
    flexDirection: "column",
    top: "10px",
    padding: "20px 0px 17px 20px",
    alignItems: "center",
    overflow: "hidden",
    "& .loader-filter": {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .closePopper": {
      position: "absolute",
      right: 10,
      cursor: "pointer",
      top: 18,
    },
    "& .footerReq": {
      margin: "18px 18px 0 auto",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      gap: "15px",
      alignItems: "center",
    },
    "& .filterTitle": {
      color: "#000",
      alignItems: "center",
      lineHeight: "22px",
      textTransform: "capitalize",
      flexDirection: "row",
      fontWeight: 600,
      fontSize: "16px",
      display: "flex",
    },
  },
});

export const ReqRadioCheckbox = styled(Box)({
  display: "flex",
  margin: "3px 0 8px",
  flexDirection: "column",
  "& .MuiFormControlLabel-root": {
    "& .PrivateSwitchBase-root-75": {
      padding: "4px 9px 4px 7px",
    },
    "& .MuiTypography-body1": {
      lineHeight: "16px",
      color: "#000",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
    },
  },
});

export const DateRangeContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  margin: "4px 0 10px",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "15px",
  "& .dateRange": {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    "& .title": {
      fontFamily: "Source Sans Pro",
      color: "#000",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px",
    },
  },
});

export const CustomeDatePicker = styled(DatePicker)({
  "& .react-datepicker__input-container": {
    "& .MuiTextField-root": {
      "& input": {},
      "& .MuiOutlinedInput-notchedOutline": {
        padding: 0,
      },
    },
  },
});

export const CustomDateField = styled(TextField)({
  maxWidth: "104px",
  "& .MuiInputBase-root": {
    paddingRight: "4px",
    "& .MuiInputBase-input": {
      borderRadius: "4px",
      color: "#000",
      fontFamily: "Source Sans Pro",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "16px",
      padding: "7px 2px 7px 5px",
    },
  },
  "& .MuiOutlinedInput-adornedStart": {
    "& .MuiInputBase-input": {
      paddingLeft: "1px !important",
    },
  },
  "& .Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#9F9F9F",
    },
  },
  "& .MuiFormHelperText-root": {
    margin: 0,
    color: "#ea2f2f",
    marginTop: "5px",
  },
});

export default RequestFilterComponent;
// Customizable Area End

import React from "react";
import { 
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    Box,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import moment from "moment";
import CustomTypography from "../../CustomTypography.web"
import { toArrayQuery } from "../../Utils";

const award = require("./award.svg");
const hummem = require("./hummen.svg");
const timber = require("./timmer.svg");
const verify = require("./verify.svg");


const CustomActivityList = styled(List)(({ theme }) => ({
    width: '100%',
    
    "& .MuiListItemAvatar-root": {
        minWidth: '40px',
        "& .MuiListItemIcon-root": {
            minWidth: '40px',
        }
    },

    "& li": {
        width: '100%',
        borderBottom: "1px solid #F3F3F3",
        
        "& .MuiListItem-secondaryAction": {
            width: '80%',
            
        }
    },
    "& a": {
        color: "#4192a4",
        lineClamp: 2,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        "-webkit-box-orient": "vertical",
        WebkitBoxOrient: "vertical",
        display: "inline"
    },


    "& .mainItem": {
        width: "90%",
        "& .MuiListItemText-primary": {
            fontFamily: "Source Sans Pro",
            color: "#4192a4",
            fontWeight: "600",
            fontSize: 18,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: "list-item",
            [theme.breakpoints.down("sm")]: {
                fontSize: 14,
            }

        },

        "& .MuiListItemText-secondary": {
            fontFamily: "Source Sans Pro",
            color: "#9F9F9F",
            fontWeight: "600"
        },
        "& .customSecondary": {
            fontFamily: "Source Sans Pro",
            fontSize: 14,
            fontWeight: "600",
            [theme.breakpoints.down("sm")]: {
                fontSize: 14,
            }
        }
    },

    "& .additionItem": {
        "& .MuiListItemText-primary": {
            fontFamily: "Source Sans Pro",
            color: "#525256",
            fontSize: 18,
            fontWeight: "600",
            [theme.breakpoints.down("sm")]: {
                fontSize: 14,
            },
            
        },

        "& .MuiListItemText-secondary": {
            fontFamily: "Source Sans Pro",
            color: "#9F9F9F",
            fontWeight: "400",
            textAlign: "right",
        }
    }
}))




export interface Props {
    activities: any[],
    navigation: any
}

interface S {

}



export class ActivityList extends React.Component<Props, S> {

    render(): React.ReactNode {
        const { activities, navigation } = this.props;

        return (
            <CustomActivityList>
                { activities.map((activity: any) => {
                    const historyTime = activity.created_at;
                    const formatedTime = moment(historyTime).format("DD MMM YYYY")
                    switch(activity.activitable_type) {
                        case "BxBlockProfile::Project": 
                            return (
                                <ListItem style={{ height: 78 }}>
                                    <ListItemAvatar>
                                        <ListItemIcon>
                                            <img src={activity.status == "On-going" ? timber : verify}/>
                                        </ListItemIcon>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<a href={`/Projects/${activity.activitable_id}`} target="_blank">{ activity.activitable.name }</a>}
                                        className="mainItem"
                                        secondary={activity.activitable.sector_name}
                                    />
                                    <ListItemSecondaryAction>
                                        <ListItemText
                                            primary={activity.status == "On-going" ? "On going" : "Completed"}
                                            className="additionItem"
                                            secondary={formatedTime}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        case "BxBlockProfile::Tender": 
                            return (
                                <ListItem style={{ height: 78 }}>
                                    <ListItemAvatar>
                                        <ListItemIcon>
                                            <img src={award}/>
                                        </ListItemIcon>
                                    </ListItemAvatar>

                                    <ListItemText
                                        primary="Tender Awarded"
                                        className="mainItem"
                                        secondary={<a href={`/Tender/${activity.activitable_id}`} target="_blank" style={{ whiteSpace: 'pre-line' }}>{activity.activitable.name}</a>}
                                    />
                                    <ListItemSecondaryAction>
                                        <ListItemText
                                            
                                            className="additionItem"
                                            secondary={formatedTime}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        case "BxBlockProfile::People": {
                            return (
                                <ListItem style={{ height: 78 }}>
                                    <ListItemAvatar>
                                        <ListItemIcon>
                                            <img src={award}/>
                                        </ListItemIcon>
                                    </ListItemAvatar>

                                    <Box className="mainItem">
                                        <CustomTypography className="customSecondary" style={{ fontWeight: 600 }}>{activity.activitable.sector_name}</CustomTypography>
                                        {/* <CustomTypography className="MuiListItemText-primary" style={{ fontWeight: 600 }}>{activity.activitable.name}</CustomTypography> */}
                                        <a className="MuiListItemText-primary" href={`/Modules/People?${toArrayQuery('ids', activity.activitable.ids)}`} target="_blank" style={{ whiteSpace: 'pre-line' }}>{ activity.activitable.name }</a>
                                    </Box>
                                    <ListItemSecondaryAction>
                                        <ListItemText
                                            className="additionItem"
                                            secondary={formatedTime}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        }

                        default: 
                            const navigatePage = activity.activitable_type;
                            const regex = /::(.*)/;
                            const match = navigatePage.match(regex);
                            const page = match?.[1] || "";

                            return (
                                <ListItem style={{ height: 78 }}>
                                    <ListItemAvatar>
                                        <ListItemIcon>
                                            <img src={hummem}/>
                                        </ListItemIcon>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<a style={{ whiteSpace: 'pre-line' }} href={`Modules/${page}s?${toArrayQuery('ids', activity.activitable.ids)}`} target="_blank">{ activity.activitable.name }</a>}
                                        className="mainItem"
                                    />
                                    <ListItemSecondaryAction>
                                        <ListItemText
                                            className="additionItem"
                                            secondary={formatedTime}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                    }
                })

                }
                
            </CustomActivityList>
        )
    }
}   


import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start
import {
  MapingResponseForTableData,
  MappingColumnNameForSortingPayload,
  MappingTableHeaderLabels,
  MappingTableHeaderSortables,
  MappingTableHeaderTypes,
  MappingTableCellWidth,
  MappingConnections,
} from "../../../components/src/MappingTableHeaderLabels";
import { favFilled, favEmpty } from "./assets";
import { truncateText, queryArray, checkLoadTimes } from "../../../components/src/Utils";
import { ExportData } from "../../importexportdata/src/ExportData.web";
import { WithAuthenticatedProps } from "../../../components/src/Permission/withAccess";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithAuthenticatedProps {
  classes: any;
  navigation: any;
  id: string;
}

export type FilterListType = {
  [key: string]: string[] | string;
};
// Customizable Area End

interface S {
  // Customizable Area Start
  isOpenFilterList: boolean;
  isShowSaveSearchLabel: boolean | undefined;
  filterList: FilterListType;
  hasError: string;
  tabValue: string;
  mainTabValue: number;
  rows: any;
  rowsPerPage: any;
  showMessage: boolean;
  page: any;
  message: string;
  searchQuery: string;
  isSearchEnabled: boolean;
  isLoading: boolean;
  tableInputs: {
    apiData: any;
    colSpanList: any;
    columsList: any;
    columnSep: any;
  };
  paginationDetails: {
    currentPage: number;
    rowsPerPage: number;
    totalLength: number;
    rowsPerPageOptions: number[];
  };
  autoCompleteOptions: any;
  autoCompleteOptionsLength: number;
  autoCompleteSelectedId: number;
  autocompleteRef: React.RefObject<HTMLInputElement>;
  isLoadingAutoComplete: boolean;
  hasFocus: boolean;
  sortOrder: string;
  currentSortColumn: string;
  customPaperRef: React.RefObject<HTMLInputElement>;
  anchorEl: null | HTMLElement;
  filterIds: Array<number>;
  searchIds: Array<number>;
  isPeopleSearchSaved: boolean;
  isPeopleSavingSearch: boolean;
  onSaveSearchErrorPeople: boolean;
  onSaveSearchErrorPeopleMessage: string;
  savingSearchPeopleName: string;
  isResetFilters: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class PeopleDataTableController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiForYear: string = "";
  apiForSearch: string = "";
  apiForFilter: string = "";
  apiForAutoComplete: string = "";
  apiForFavDeletion: string = "";
  apiForFavAddition: string = "";
  fileInputRef: any;
  showMessage: any;
  responseData: any;
  responseDataForAutoComplete: any;
  currentSortColumn: string = "";
  sortOrder: string = "ASC";
  apiForSavePeopleSearch: string = "";

  refExportBox = React.createRef<ExportData>();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.fileInputRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      isOpenFilterList: true,
      filterList: {},
      hasError: "",
      autocompleteRef: React.createRef(),
      customPaperRef: React.createRef(),
      isLoadingAutoComplete: false,
      mainTabValue: 0,
      tabValue: "",
      rows: [],
      rowsPerPage: 5,
      page: 0,
      showMessage: false,
      message: "",
      searchQuery: "",
      isSearchEnabled: false,
      isLoading: false,
      tableInputs: {
        apiData: [],
        colSpanList: [],
        columsList: [],
        columnSep: [],
      },
      paginationDetails: {
        rowsPerPage: 10,
        rowsPerPageOptions: [10, 25, 50, 75, 100],
        currentPage: 0,
        totalLength: 0,
      },
      autoCompleteOptions: [],
      isResetFilters: false,
      autoCompleteOptionsLength: 5,
      autoCompleteSelectedId: -1,
      hasFocus: false,
      sortOrder: "ASC",
      currentSortColumn: "",
      anchorEl: null,
      filterIds: [],
      searchIds: [],
      isPeopleSearchSaved: false,
      isPeopleSavingSearch: false,
      onSaveSearchErrorPeople: false,
      onSaveSearchErrorPeopleMessage: "",
      savingSearchPeopleName: "",
      isShowSaveSearchLabel: false,
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
   
    if (checkLoadTimes() > 1) {
      history.pushState(null, '', '/Modules/People')
    } 
    
    this.setState({ isLoading: true })
    this.handleFilterTableDataShow();
    window.addEventListener("click", this.handleMouseClicksForAutoComplete);
    localStorage.removeItem("people");
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (this.isAuthTokenError(responseJson)) {
      localStorage.removeItem("authToken");
      this.props.navigation.navigate("LoginBlock");
      return;
    }

    if (this.isApiResponseEmpty(responseJson)) {
      this.setState({ hasError: "Response not found (Internal Error)" });
      return;
    }

    switch (apiRequestCallId) {
      case this.apiForYear:
      case this.apiForFilter:
        this.handleYearOrFilterResponse(responseJson);
        break;

      case this.apiForSearch:
        this.handleSearchResponse(responseJson);
        break;

      case this.apiForAutoComplete:
        this.handleAutoCompleteResponse(responseJson);
        break;

      case this.apiForFavAddition:
      case this.apiForFavDeletion:
        this.handleFavResponse();
        break;

      case this.apiForSavePeopleSearch:
        this.handleSaveSearchResponse(responseJson);
        break;

      default:
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      JSON.stringify(prevState.filterList) !==
      JSON.stringify(this.state.filterList)
    ) {
      this.setState(
        (prevState) => ({
          searchQuery: "",
          isSearchEnabled: false,
        }),
        () => {
          this.handleFilterTableDataShow();
        }
      );
    }
  }

  async componentWillUnmount(): Promise<void> {
    window.removeEventListener("click", this.handleMouseClicksForAutoComplete);
  }

  handleFilterList = (
    value: FilterListType,
    isApplySaveSearch?: boolean | undefined
  ) => {
    this.setState({
      filterList: value,
      isShowSaveSearchLabel: isApplySaveSearch,
      isPeopleSearchSaved: !!isApplySaveSearch,
    });
  };

  toggleOpen = () => {
    this.setState({ isOpenFilterList: !this.state.isOpenFilterList });
  };

  handleCheckedSaveSearch = () => {
    if (!this.state.isPeopleSearchSaved) {
      this.setState({
        isPeopleSavingSearch: true,
        onSaveSearchErrorPeople: false,
      });
    }
  };

  onChangeSavedSeachName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      onSaveSearchErrorPeople: false,
      savingSearchPeopleName: event.target.value,
    });
  };

  cancelSavingProjectSearch = () => {
    this.setState({
      savingSearchPeopleName: "",
      isPeopleSavingSearch: false,
      isPeopleSearchSaved: false,
    });
  };

  savePeopleSaveSearchRequest = () => {
    const { savingSearchPeopleName, searchQuery, filterList, sortOrder } =
      this.state;

    if (savingSearchPeopleName === "") {
      this.setState({
        onSaveSearchErrorPeople: true,
        onSaveSearchErrorPeopleMessage: configJSON.onSaveSearchErrorMessage,
      });
      return;
    }
    let isFIlter = Object.values(filterList).some((array) => array.length > 0);
    if (!isFIlter) {
      this.setState({
        onSaveSearchErrorPeopleMessage: configJSON.onSaveSearchNoFilterMessage,
        onSaveSearchErrorPeople: true,
      });
      return;
    }
    const sort_key = this.currentSortColumn
      ? MappingColumnNameForSortingPayload(this.currentSortColumn)
      : "";
    const body = {
      search_name: savingSearchPeopleName,
      filters: {
        ...(searchQuery ? { project_name: searchQuery } : {}),
        ...filterList,
        ...(sort_key
          ? {
              sort_key: sort_key,
              sort_direction: sortOrder,
            }
          : {}),
      },
      type: "BxBlockPeople::PeopleSavedSearch",
    };
    const url = `${configJSON.apiEndPointForSaveProjectSearch}`;

    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForSavePeopleSearch = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleMouseClicksForAutoComplete = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (
      this.state.autocompleteRef.current &&
      !this.state.autocompleteRef.current.contains(target) &&
      this.state.hasFocus &&
      target &&
      target.innerText !== "Show more"
    ) {
      this.setState({
        hasFocus: false,
      });
    }
  };

  isAuthTokenError(responseJson: any): boolean {
    return responseJson?.errors?.[0]?.token ? true : false;
  }

  isApiResponseEmpty(responseJson: any): boolean {
    return !responseJson;
  }

  handleSaveSearchResponse = (responseJson: any) => {
    const { error, data } = responseJson;

    if (error) {
      this.setState({ hasError: error, isLoading: false });
      return;
    }

    if (data) {
      this.setState({
        savingSearchPeopleName: "",
        isPeopleSavingSearch: false,
        isPeopleSearchSaved: true,
        isLoading: false,
      });
    }
  };

  handleYearOrFilterResponse(responseJson: any) {
    this.responseData = MapingResponseForTableData(responseJson);
    this.setState(
      {
        isSearchEnabled: false,
        rows: responseJson.data,
        searchIds: responseJson.search_ids,
      },
      this.prepareTableInputs
    );
  }

  handleSearchResponse(responseJson: any) {
    if (responseJson.error) {
      this.setState({
        tableInputs: {
          apiData: [],
          colSpanList: [],
          columsList: [],
          columnSep: [],
        },
        isLoading: false,
        isResetFilters: false,
      });
      return;
    }
    this.responseData = MapingResponseForTableData(responseJson);
    this.setState(
      {
        isSearchEnabled: true,
        rows: responseJson.data,
        searchIds: responseJson.search_ids,
        isResetFilters: false,
      },
      this.prepareTableInputs
    );
  }

  handleAutoCompleteResponse(responseJson: any) {
    this.setState({ isLoadingAutoComplete: false });
    if (responseJson.error) {
      this.setState({ autoCompleteOptions: [], autoCompleteSelectedId: -1, isLoading: false});
      return;
    }
    this.responseDataForAutoComplete = responseJson;
    this.setState(
      { rows: responseJson.data, searchIds: responseJson.search_ids, isLoading: false},
      this.prepareAutoComplete
    );
  }

  handleFavResponse() {
    if (this.state.isSearchEnabled) {
      this.handleSearchTableDataShow(
        this.state.searchQuery,
        this.state.paginationDetails.currentPage + 1
      );
    } else {
      this.handleFilterTableDataShow(
        this.state.paginationDetails.currentPage + 1
      );
    }
  }
  prepareAutoComplete() {
    let autoCompleteResponse;
    if (this.responseDataForAutoComplete.data?.data) {
      autoCompleteResponse = this.responseDataForAutoComplete.data.data;
    } else if (this.responseDataForAutoComplete.results?.data) {
      autoCompleteResponse = this.responseDataForAutoComplete.results.data;
    } else {
      return;
    }
    if (autoCompleteResponse.length > 0) {
      const autoCompleteOptions = autoCompleteResponse
        .filter((record: any) => {
          // Check if the name includes the search string (case-insensitive)
          return record.attributes.people.name
            .toLowerCase()
            .includes(this.state.searchQuery.toLowerCase());
        })
        .map((record: any) => {
          return {
            name: record.attributes.people.name,
            id: record.id,
          };
        });
      this.setState({
        autoCompleteOptions,
        autoCompleteSelectedId: -1,
        autoCompleteOptionsLength: 5,
        hasFocus: true,
      });
    } else {
      this.setState({
        autoCompleteOptions: [],
        hasFocus: false,
      });
    }
  }

  convertColSpanList = (columsList: any, mappingList: any) => {
    columsList = columsList.filter(
      (fEle: any) =>
        fEle.dataLabel != "is_favourite" && fEle.dataLabel != "favourite_id"
    );

    let colSpanList = Object.keys(mappingList[0].attributes)
      .filter((fEle) => fEle !== "favourite")
      .map((e: any) => {
        let arr = {
          label: MappingTableHeaderLabels(e),
          colSpan: Object.keys(mappingList[0].attributes[e]).length,
          ...(e === "education" ? { isForceLabel: true } : {}),
        };
        return arr;
      });


    colSpanList = colSpanList.filter(item => item.label != "Relation");
    colSpanList.unshift({ label: "", colSpan:1 })

    
    columsList.unshift({
      dataLabel: "iconValue",
      displayLabel: "",
      width: MappingTableCellWidth("icon"),
      type: MappingTableHeaderTypes("icon"),
      hideLabel: true,
      sortOption: { isSortable: false },
    });

    const columnSep: number[] = colSpanList.reduce(
      (carry: number[], ele: any) => {
        carry.push(
          carry.length > 0
            ? carry[carry.length - 1] + +ele.colSpan
            : +ele.colSpan
        );
        return carry;
      },
      []
    );


    return {
      columsList: columsList,
      colSpanList: colSpanList,
      columnSep: columnSep,
    };
  };

  prepareTableInputs() {
    let columsList: any = [];
    let mappingList: any[] | null = this.responseData?.data;

    if (mappingList === null || !mappingList?.length) {
      this.setState({
        tableInputs: {
          apiData: [],
          colSpanList: [],
          columsList: [],
          columnSep: [],
        },
        isLoading: false,
      });
      return;
    }

    let apiData = this.prepareTableMappingList(mappingList, columsList);
    this.setState(() => ({
      tableInputs: {
        apiData,
        ...this.convertColSpanList(columsList, mappingList),
      },
      isLoading: false,
    }));
    this.setState((prevState, props) => {
      return {
        paginationDetails: {
          rowsPerPageOptions: prevState.paginationDetails.rowsPerPageOptions, //[10, 20, 30],
          rowsPerPage: prevState.paginationDetails.rowsPerPage,
          totalLength: this.responseData["total_items"],
          currentPage: this.responseData["current_page"] - 1,
        },
      };
    });
  }

  prepareTableMappingList = (
    mappingList: any[],
    columsList: {
      dataLabel: string;
      displayLabel: string;
      type: string;
      width: string;
      sortOption: { isSortable: boolean; sortOrder: string };
    }[]
  ) => {
    let apiData = mappingList.map((e: any, i: number) => {
      let arr: any = {};
      Object.keys(e.attributes).forEach((atr: any) => {
        arr = {
          ...arr,
          ...e.attributes[atr],
          ...{
            iconValue: e.attributes[atr]["is_favourite"] ? favFilled : favEmpty,
          },
          id: e.id,
        };
        if (
          atr === "experience" &&
          e.attributes[atr]["group_relation"] !== null
        ) {
          arr.group_relation = truncateText(
            e.attributes[atr]["group_relation"],
            30
          );
        }
        if (atr === "people" && e.attributes[atr]["name"] !== null) {
          arr.name = truncateText(e.attributes[atr]["name"], 20);
        }
        
        if (i === 0 && atr != "relation") {
          columsList.push(
            ...Object.keys(e.attributes[atr]).map((e: string) => {
              return {
                dataLabel: e,
                displayLabel: MappingTableHeaderLabels(e),
                type: MappingTableHeaderTypes(e),
                width: MappingTableCellWidth(e),
                connections: MappingConnections(e),
                sortOption: MappingTableHeaderSortables(
                  e,
                  this.currentSortColumn,
                  this.sortOrder
                ),
              };
            })
          );
        }
  
      });
      return arr;
    });

    return apiData;
  };

  handleFavIconClicked(rowIndex: number, iconValue: string) {
    const { apiData } = this.state.tableInputs;
    const selectRow = apiData?.at(rowIndex);
    if (selectRow.is_favourite) {
      this.handleFavDeletion(
        this.responseData.data[rowIndex].attributes["favourite"]["favourite_id"]
      );
    } else {
      this.handleFavAddition(this.responseData.data[rowIndex].id);
    }
  }

  handleAddButton = () => {
    this.props.navigation.push("/People/add");
  };

  onClickOutsidePeople = () => {
    const { current: refExportBox } = this.refExportBox;

    refExportBox?.state.hasImportExportButtonClicked &&
      refExportBox.handleImportExportButtonClick();
  };

  handleChangePage = (event: any, newPage: number) => {
    this.setState({ isLoading: true })
    if (this.state.isSearchEnabled) {
      this.handleSearchTableDataShow(this.state.searchQuery, newPage + 1);
    } else {
      this.handleFilterTableDataShow(newPage + 1);
    }
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      (prevState, props) => ({
        isLoading: true,
        paginationDetails: {
          ...prevState.paginationDetails,
          rowsPerPage: +event.target.value,
          currentPage: 0,
        },
      }),
      () => {
        if (this.state.isSearchEnabled) {
          this.handleSearchTableDataShow(
            this.state.searchQuery,
            this.state.paginationDetails.currentPage + 1
          );
        } else {
          this.handleFilterTableDataShow(
            this.state.paginationDetails.currentPage + 1
          );
        }
      }
    );
  };

  handleSort(columnName: string, sortOrder: string) {
    this.sortOrder = sortOrder === "ASC" ? "DESC" : "ASC";
    this.currentSortColumn = columnName;
    this.handleFilterTableDataShow();
  }

  handleSubmitAutoCompleteSearchBar = (searchQuery: string) => {
    const { filterList } = this.state;
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    let queryParam = "";
    for (let item in filterList) {
      if (JSON.stringify(filterList[item]) === "[]") {
        continue;
      }
      if (queryParam !== "") {
        queryParam += "&";
      }
      queryParam += item + "=" + JSON.stringify(filterList[item]);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForAutoComplete = requestMessage.messageId;
    const apiEndPoint = "bx_block_people/search_people?q=";
    const queryParams = queryParam ? `&${queryParam}` : "";

    let urlPath: string =
      apiEndPoint + searchQuery.toLowerCase() + queryParams + "&page=1";

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoadingAutoComplete: true });
  };

  handlePeopleTableDataShow = (pageNumber?: number) => {
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForYear = requestMessage.messageId;

    let urlPath: string =
      pageNumber !== undefined
        ? `bx_block_people/people?page=${pageNumber}`
        : "bx_block_people/people?page=1&per_page=10";

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFilterTableDataShow = (pageNumber?: number) => {
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };

    let queryParam = "";
    let filterIdsString = ""
    let filterList = this.state.filterList;
    const targetIds = queryArray("ids", window.location.href);
    if (targetIds.length > 0) {
       filterIdsString = `ids=[${targetIds.join(',')}]`;
       queryParam += filterIdsString 
    }

    let sortOptions = {
      sort_key: this.currentSortColumn
        ? MappingColumnNameForSortingPayload(this.currentSortColumn)
        : "",
      sort_direction: this.sortOrder ? this.sortOrder : "", //["DESC"]
    };

    if (sortOptions.sort_key) {
      if (queryParam !== "") queryParam += "&";
      queryParam +=
        "sort_key=" +
        sortOptions.sort_key +
        "&sort_direction=" +
        sortOptions.sort_direction;
    } 

    for (let item in filterList) {
      if (JSON.stringify(filterList[item]) === "[]") {
        continue;
      }
      if (queryParam !== "") {
        queryParam += "&";
      }
      const last = encodeURIComponent(JSON.stringify(filterList[item]))
      queryParam += item + "=" + last;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForFilter = requestMessage.messageId;

    let urlPath: string =
      pageNumber !== undefined
        ? `bx_block_people/people?${queryParam}&page=${pageNumber}&per_page=${this.state.paginationDetails.rowsPerPage}`
        : `bx_block_people/people?${queryParam}&page=1&per_page=${this.state.paginationDetails.rowsPerPage}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSearchTableDataShow = (searchString: string, pageNumber?: number) => {
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForSearch = requestMessage.messageId;

    let urlPath: string =
      pageNumber !== undefined
        ? `bx_block_people/search_people?q=${searchString.toLowerCase()}&page=${pageNumber}&per_page=${
            this.state.paginationDetails.rowsPerPage
          }`
        : `bx_block_people/search_people?q=${searchString.toLowerCase()}&page=1&per_page=10`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  searchDebounceTimer: any;

  handleSearchDebounce = () => {
    this.searchDebounceTimer = setTimeout(() => {
      this.setState({
        paginationDetails: {
          rowsPerPage: 10,
          rowsPerPageOptions: [10, 25, 50, 75, 100],
          currentPage: this.responseData?.["current_page"] - 1,
          totalLength: this.responseData?.["total_items"]
        },
      });
      if (this.state.searchQuery === "") {
        this.setState({
          autoCompleteOptions: [],
        });
      } else {
        this.handleSubmitAutoCompleteSearchBar(this.state.searchQuery);
      }
    }, 400);
  };

  handleLoadPeopleBtn = () => {
    this.currentSortColumn = "";
    this.setState(
      {
        searchQuery: "",
        autoCompleteOptions: [],
        autoCompleteSelectedId: -1,
        isResetFilters: true,
        filterList: {},
        currentSortColumn: "",
        sortOrder: "ASC",
      },
      () => this.handleSearchTableDataShow("")
    );
  };

  handleSearchItem = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
    }
    this.setState(
      { searchQuery: event.target.value },
      this.handleSearchDebounce
    );
  };

  handleSearchById = (id: number) => {
    if (this.state.autoCompleteOptionsLength === 5 && id === 5) {
      this.setState({
        autoCompleteOptionsLength: this.state.autoCompleteOptions.length,
        hasFocus: true,
      });
      return;
    }
    const person = this.state.autoCompleteOptions[id];
    if (person) {
      this.props.navigation.push(`/People/${person.id}`);
    }
  };

  handleFavAddition = (id: number) => {
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const httpBody = {
      data: {
        favouriteable_type: "BxBlockPeople::People",
        favouriteable_id: id,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForFavAddition = requestMessage.messageId;

    let urlPath: string = `bx_block_favourites/favourites?token=${header.token}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    requestMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFavDeletion = (id: number) => {
    const header = {
      "Content-Type": "application/json",
      token:
        typeof window !== "undefined"
          ? localStorage.getItem("authToken")
          : null,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForFavDeletion = requestMessage.messageId;

    let urlPath: string = `bx_block_favourites/favourites/${id}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    requestMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleClearAutoCompleteResults = () => {
    this.setState(
      {
        searchQuery: "",
        hasFocus: false,
      },
      () => this.handlePeopleTableDataShow()
    );
  };

  handleActionsOnAutoSearchBar = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const {
      hasFocus,
      searchQuery,
      autoCompleteSelectedId,
      autoCompleteOptions,
    } = this.state;

    if (
      hasFocus &&
      (event.key === "ArrowUp" ||
        event.key === "ArrowDown" ||
        event.key === "Enter")
    ) {
      event.preventDefault();

      switch (event.key) {
        case "ArrowUp": {
          if (autoCompleteSelectedId !== -1) {
            this.setState({
              autoCompleteSelectedId: autoCompleteSelectedId - 1,
            });
          }

          break;
        }
        case "ArrowDown": {
          this.setState({ autoCompleteSelectedId: autoCompleteSelectedId + 1 });
          break;
        }
        case "Enter": {
          if (autoCompleteSelectedId === -1) {
            this.handleSearchTableDataShow(searchQuery);
            this.setState({ hasFocus: false });
            break;
          }
          if (
            this.state.autoCompleteOptionsLength === 5 &&
            autoCompleteSelectedId === 5
          ) {
            this.setState({
              autoCompleteOptionsLength: this.state.autoCompleteOptions.length,
            });
            break;
          }
          const person = autoCompleteOptions[autoCompleteSelectedId];
          this.handleSearchTableDataShow(person.name);
          this.setState({ hasFocus: false, searchQuery: person.name });
          break;
        }
        default:
          return;
      }
    }
  };

  // Customizable Area End
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "audittrail2";
exports.labelBodyText = "audittrail2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.GetAuditTrailEndPoint = "bx_block_logs/logs"
exports.GetAuditTrailLinkEndPoint = "bx_block_logs/pdf_links"
exports.FilterOptions = [
  {
    label: "Current 90 days",
    value: "current 90 days",
  },
  {
    label: "Previous 90 days",
    value: "previous 90 days",
  }
]
// Customizable Area End

import React from "react"
import { Close, Done, Warning, Error } from "@material-ui/icons";
import {
    Snackbar,
    SnackbarProps,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CustomButton from "./CustomButton.web";


const SnackbarMsg = styled(Snackbar)({
    borderRadius: "8px",
    width: "420px",
    "&.snakDelete":{
      backgroundColor: "#F19D38",
      border: "1px solid #DC2626",
      borderLeftWidth: "5px",
      "@media (max-width: 600px)": {
        width: "auto"
      },
    },
    "&.snakSuccess":{
      border: "1px solid #2D97A5FC",
      backgroundColor: "#5D9E52",
      borderLeftWidth: "5px",
      "@media (max-width: 600px)": {
        width: "auto"
      },
    },
    "&.snakError":{
      border: "1px solid #f73016",
      backgroundColor: "#EE2020",
      borderLeftWidth: "5px",
      "@media (max-width: 600px)": {
        width: "auto"
      },
    },
    "& .container": {
      width: "100%",
      gap: "16px",
      color: "#FFF",
      display: "flex",
      borderRadius: "8px",
      justifyContent: "space-between",
      zIndex: 5,
      alignItems: "center",
      padding: "18px",
      
      "& .message": {
        lineHeight: "17px",
        color: "white",
        fontSize: "15px",
        display: "flex",
        gap: "8px",
        alignItems: "center",
        fontWeight: 400,
        fontFamily: "Source Sans Pro",
        "@media (max-width: 650px)": {
          fontSize: "12px"
        },
      },
      "& .buttonContainer": {
        display: "flex",
        gap: "16px",
        "& button": {
          fontSize: "15px",
          fontFamily: "Source Sans Pro",
          textDecoration: "none",
          fontWeight: 700,
          color: "white",
          lineHeight: "17px",
        },
        "@media (max-width: 650px)": {
          "& button":{
            fontSize: "12px"
          }
        },
      },
    },
});

export interface CustomAlertProps extends SnackbarProps {
    onConfirmClose?: () => void;
    onConfirmYes?: () => void;
    message: string;
}

export const CustomAlertSuccess: React.FC<CustomAlertProps> = ({
    message,
    onConfirmClose,
    onConfirmYes,
    ...rest 
}) => {
    return (
        <SnackbarMsg
          className="snakSuccess"
          {...rest}
        >
            <div className="container">
              <span className="message">
                <Done />
                 { message }
              </span>
              <div className="buttonContainer">
                <CustomButton
                  colorVariant="noBackground"
                  onClick={ onConfirmClose }
                >
                  <Close />
                </CustomButton>
              </div>
            </div>
        </SnackbarMsg>
    )
}

export const CustomAlertWarning: React.FC<CustomAlertProps> = ({
    message,
    onConfirmClose,
    onConfirmYes,
    ...rest 
}) => {
  return  (
      <SnackbarMsg
        className="snakDelete"
        {...rest}
      >
          <div className="container">
            <span className="message" data-test-id="deleteAlertMsg">
              <Warning /> { message }
            </span>
            <div className="buttonContainer">
              <CustomButton
                data-test-id="deleteSaveSearch"
                colorVariant="noBackground"
                onClick={onConfirmYes}
              >
                DELETE
              </CustomButton>
              <CustomButton
                data-test-id="dismissSaveSearch"
                colorVariant="noBackground"
                onClick={onConfirmClose}
              >
                DISMISS
              </CustomButton>
            </div>
          </div>
      </SnackbarMsg>
  )

}

export const CustomAlertError: React.FC<CustomAlertProps> = ({
  message,
  onConfirmClose,
  onConfirmYes,
  ...rest 
}) => {
  return (
    <SnackbarMsg
        className="snakError"
        {...rest}
    >
       <div className="container">
          <span className="message" data-test-id="deleteAlertMsg">
            <Error /> { message }
          </span>
        </div>
    </SnackbarMsg>
   
  )

}
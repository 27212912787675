import Alert from '@material-ui/lab/Alert';
import {
  Theme,
  Grid,
  Box,
  MuiThemeProvider,
  createTheme,
  Typography,
  createStyles,
  Accordion,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  AccordionDetails,
  Paper,
  AccordionSummary,
  Snackbar,
  List,
  ListItem,
  withStyles,
  ListItemText,
} from "@material-ui/core";
import React from "react";

import TenderImportDataController, { Props,configJSON } from "./TenderImportDataController.web";
import CustomButton from "../../../components/src/CustomButton.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ImportView from "./Components/ImportView.web";
import TenderModuleHeader from '../../multipageforms/src/Components/TenderModule/TenderModuleHeader.web';

const THEME = createTheme({
  typography: {
    fontWeightMedium: 600,
    fontFamily: "Source Sans Pro",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
  },
});

export class TenderImportData extends TenderImportDataController {

  constructor(props: Props) {
    super(props);
  }

  renderImportView() {
    const classes = this.props.classes;

    return (
      <Box marginTop={2} className={classes.containerSucess}>
        <TenderModuleHeader data-testId="tenderModuleHeader" saveAsPDF={false} handleGoBack={this.handleGoBack}/>
        <ImportView
          navigation={this.props.navigation}
          sampleCSVFormat={configJSON.exportSampleTenderCSVData}
          sampleXLSFile={configJSON.sampleXLSXTenderData}
          data-testId="importView"
          onSubmitImport = {this.handleImportFile}
          module={"tenders"}
        />
      </Box>
    );
  }

  renderSuccessImport() {
    const { tenderReportMetaData } = this.state
    const classes = this.props.classes;
    return (
      <Box marginTop={2} className={classes.containerSucess} paddingBottom={2}>
        <TenderModuleHeader data-testId="tenderModuleHeader" handleGoBack={this.handleGoBack} saveAsPDF={false}/>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12}  md={8} sm={10} lg={7}>
            <Box className={classes.sucessBox} marginTop={2}>
              <Alert severity="success" data-testId="summary" className={classes.alertBox}>{ tenderReportMetaData?.summary.summaryMessage  }</Alert>

              <Box className={classes.listErrorBox}>

                <Accordion  style={{width: '100%'}} key={`report_summary`} className={classes.customAccordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    style={{borderBottom: "1px solid #f3f3f3"}}
                    id="panel1a-header"
                  >
                    <Typography variant='body1' className={classes.headingReport}>{ tenderReportMetaData?.importedMessage }</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List className={classes.listDetailReport} dense={true} >
                        <ListItem key={`error_report_1`}>
                          <ListItemText primary={ tenderReportMetaData?.summary.createdRecords } />
                        </ListItem>

                        <ListItem key={`error_report_2`}>
                          <ListItemText primary={ tenderReportMetaData?.summary.oveririddenRecords} />
                        </ListItem>
                    </List>
                  </AccordionDetails>
                
                </Accordion>

                <Accordion key={`report_skip`}   className={classes.customAccordion} style={{width: '100%'}}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    style={{borderBottom: "1px solid #f3f3f3"}}
                    id="panel1a-header"
                  >
                    <Typography variant='body1' className={classes.headingReport}> { tenderReportMetaData?.skippedMessage } </Typography>
                  </AccordionSummary>
                  { (tenderReportMetaData?.skippedErrors || []).length > 0 &&
                    <AccordionDetails>
                    <TableContainer component={Paper}>
                      <Table className={classes.table} size="small" >
                        <TableHead>
                          <TableRow>
                            <TableCell>Row Number</TableCell>
                            <TableCell>Tender Name</TableCell>
                            <TableCell>Error Message</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tenderReportMetaData?.skippedErrors.map((tableRow) => (
                            <TableRow key={`row_${tableRow.row}`}>
                              <TableCell component="th">
                                Row {tableRow.row}
                              </TableCell>
                              <TableCell>{tableRow?.value || 'Nil'}</TableCell>
                              <TableCell>{tableRow.error}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                  }
                  
                
                </Accordion>

                <Accordion key={`report_override`} style={{width: '100%'}}  className={classes.customAccordion}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    style={{borderBottom: "1px solid #f3f3f3"}}
                    id="panel1a-header"
                  >
                    <Typography variant='body1' className={classes.headingReport}> {tenderReportMetaData?.notImportMessage} </Typography>
                  </AccordionSummary>
                  { (tenderReportMetaData?.recordErrors || []).length > 0 &&
                    <AccordionDetails>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} size="small" >
                          <TableHead>
                            <TableRow>
                              <TableCell>Row Number</TableCell>
                              <TableCell>Tender Name</TableCell>
                              <TableCell>Field Name</TableCell>
                              <TableCell>Error Message</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tenderReportMetaData?.recordErrors.map((tableRow) => (
                              <TableRow key={`row_${tableRow.row}`}>
                                <TableCell component="th">
                                  Row {tableRow.row}
                                </TableCell>
                                <TableCell>{tableRow?.value || 'Nil'}</TableCell>
                                <TableCell>{tableRow.fieldName}</TableCell>
                                <TableCell>{tableRow.error}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                  </AccordionDetails>
                  }
                  
                </Accordion>
              </Box>
          
              <CustomButton data-testId="addAnotherBtn"  onClick={() => this.resetImport() } style={{ marginTop: 16 }}>Add Another</CustomButton>
            </Box>
            
          </Grid>
        </Grid>
      </Box>

    )
  }

  
  render() {
    // Customizable Area Start
    return (<>
      <MuiThemeProvider theme={THEME}>
        <Box sx={customStyle.rootContainer}>
        {this.state.isShowReport ? this.renderSuccessImport() : this.renderImportView()}
          {this.state.hasError && (
          <Snackbar
          open={!!this.state.hasError} 
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onClose={() => {
              this.setState({
                hasError: "",
              });
            }}
          >
            <Alert data-testId="snack-error" severity="error">{ this.state.hasError }</Alert>
          </Snackbar>
        )}
        </Box>
      </MuiThemeProvider>
    </>);
    // Customizable Area Start
  }
}

export const customStyle = {
  rootContainer: {
    display: 'flex',
    fontFamily: 'Source Sans Pro',
    backgroundColor: '#F3F3F3',
    width: "100%" ,
  }
}
export const styles = (theme: Theme) =>
  createStyles({
    expandSidebarIconBox: {   
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      height: "20px",
      display: "flex",
      padding: "5px",
      width: "20px",
      position: "fixed",
      zIndex: 1,
      cursor: "pointer",
      backgroundColor: "rgb(253, 215, 47)",
      transition: "left 0.4s ease-out",
      top: "127px",
    },
    earch: {
      borderRadius: '8px',
      backgroundColor: "white",
      position: "relative",
      "& fieldset": {
        border: "1px solid #E8E8E8 !important"
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px'
      },
      "& .MuiOutlinedInput-adornedStart": {
      },
      "&:hover": {
        backgroundColor: "white",
      },
      width: "40%",
      marginLeft: 0,
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: '0px'
      },
      "& .MuiOutlinedInput-input": {
        padding: '14px'
      },
      
    },
    mainContainer: {
      display: "flex",
      position: "relative",
    },
    mainChildWrapper: {
      transition: "width 0.4s ease-out",
      minHeight: "100vh",
      marginTop: "85px",
    },
    uploadFileBox: {
      padding: "40px",
      background: "rgba(0, 148, 167, 0.08)",
      gap: "20px",
      borderRadius: "8px",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
    },
    sucessBox: {
      background: "rgba(0, 148, 167, 0.08)",
      padding: "40px",
      justifyContent: "center",
      borderRadius: "8px",
      marginBottom: 24,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    sidenav: {
      width: "250px",
      backgroundColor: "rgb(10, 25, 41)",
      transition: "width 0.3s ease-in-out",
      paddingTop: "28px",
      height: "100vh",
    },
    sidenavClosed: {
      composes: "sidenav",
      width: "60px",
      transition: "width 0.3s ease-in-out",
    },
    container: {
      height: "100%",
      flexDirection: "column",
      display: "flex",
      marginTop: "20px",
      backgroundColor: "#FFF",
      gap: "25px",

    },
    sideitem: {
      padding: "10px 20px",
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
      textDecoration: "none",
      color: "#b2bac2",
      cursor: "pointer",
    },
    "& .sideitem:hover": {
      backgroundColor: "#244f7d1c",
    },
    linkText: {
      paddingLeft: "16px",
    },
    menuBtn: {
      paddingLeft: "20px",
      boxShadow: "3px",
      borderRadius: "10px",
      transition: "width 0.3s ease-in-out",
      cursor: "pointer",
      alignSelf: "center",
      backgroundColor: "transparent",
      position: "relative",
      justifySelf: "flex-end",
      border: "none",
      zIndex: 1000000,
      left: "40px",
      color: "#b2bac2",
      top: "124px",
    },
    menuBtnExpand: {
      position: "relative",
      backgroundColor: "transparent",
      paddingLeft: "20px",
      border: "none",
      top: "124px",
      transition: "width 0.3s ease-in-out",
      cursor: "pointer",
      alignSelf: "center",
      color: "#b2bac2",
      zIndex: 1000000,
      justifySelf: "flex-end",
      left: "230px",
    },
    containerSucess: {
      width: '100%',
      height: "100%",
      backgroundColor: "#FFF",
    },
    headingReport: {
      fontSize: 16,
      color: "#6a6e6f",
      marginLeft: '8px',
      "& span": {
        color: "#f50057",
        marginLeft: 4,
        fontWeight: 600,
      }
    },

    reportText: {
      fontWeight: 600,
      marginBottom: '24px',
      color: '#0094a7',
      textTransform: 'uppercase',
    },

    customAccordion: {
      '& .MuiAccordion-root.Mui-expanded': {
        margin: 0
      }
    },
    listDetailReport: {
      "& .MuiTypography-body2": {
        color: "#6a6e6f",
      }
    },
    reportSingleBox: {
      width: '100%',
      padding: '12px',
      margin: '8px 0',
      "& span.title": {
        width: 150,
        color: "#1f1f1f",
        display: 'inline-block',
      },

      "& span.value": {
        color: '#3c7e40',
        fontWeight: '600',
      }
    },
    rowErrorText: {
      textAlign: 'left',
      width: '100%',
      marginLeft: -20,
      marginBottom: '10px',
      color: "#4c4c4c",
    },
    listErrorBox: {
      overflowY: 'scroll',
      width: '100%',
      maxHeight: '600px',
    },
    alertBox: {
      marginBottom: '12px',
      width: '100%',
    },
    table: {
      minWidth: 650,
    },
});

export default withStyles(styles)(TenderImportData)
import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  TextField,
  withStyles,
  Box,
  styled,
  Tooltip,
  ClickAwayListener,
} from "@material-ui/core";
import CustomButton from "../../../../../components/src/CustomButton.web";
import { Document } from "../../PeopleModuleController.web";
import { deleteButtonSvg, editDocButtonSvg, personIconSvg } from '../../assets';
import CustomTypographyWeb from '../../../../../components/src/CustomTypography.web';

interface TableDocument extends Document {
  id?: number
}
type Props = {
  documents: TableDocument[];
  mode: boolean;
  loggedInUserFullName: string | null;
  profile_photo: string | null;
  handleDeleteDocument?: (docId: number, documentType: string) => void
  editDocument?:any;
  editDocumnetID?:number
  handleEditDocumentName?: any;
  editedName?:string;
  handleEditDocumentNameApi?:any;
};

type State = {
  editIndex: number;
  editedName: string | null;
};

export default class TableDocuments extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      editIndex: -1,
      editedName: "",
    };
  }


  
  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ editedName: event.target.value });
  };


  truncateText = (text: string | null, maxLength: number) => {
    if (text && text.length > maxLength) {
      return `${text.slice(0, maxLength) + '...'}`;
    }
    return text;
  }

  render() {
    return (
      <Box
        style={{
          marginTop: "24px",
        }}
      >
        <Typography
          style={{
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "16px",
            color: "#000",
            fontFamily: "Source Sans Pro",
            fontSize: "18px",
          }}
          data-testid={"PeopleModule-Documentation-OtherDocuments"}
        >
          Other Documents
        </Typography>

        <TableContainer style={{ overflow: "auto" }}>
          <CssCustomTable>
            <TableHead>
              <TableRow>
                <CssCustomTableHeader>Document Name</CssCustomTableHeader>
                <CssCustomTableHeader>Attached File </CssCustomTableHeader>
                <CssCustomTableHeader>Uploaded By</CssCustomTableHeader>
                <CssCustomTableHeader>Uploaded Date</CssCustomTableHeader>
                {this.props.mode && <CssCustomTableActionCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.documents.length > 0 &&
                this.props.documents.map((document, index) => (
                  <CssCustomTableRow
                  data-testId="documentRow"
                  key={index}
                  >
                    <CssCustomTableCell>
                      {this.props.editDocumnetID === index ? (
                      <ClickAwayListener data-test-id="clickAway" onClickAway={()=> this.props.handleEditDocumentNameApi(document.document_id)}>
                        <TextField
                          id="inputOtherFileName"
                          value={this.props.editedName}
                          onChange={(e)=>this.props.handleEditDocumentName(e.target.value)}
                        />
                      </ClickAwayListener>
                      ) : 
                      <Tooltip title={document.document_name}>
                        <p style={{cursor: "pointer", width:'10%'}}>{this.truncateText(document.document_name, 10)}</p>
                      </Tooltip>
                      }
                    </CssCustomTableCell>
                    <CssCustomTableCell>
                      <Tooltip title={document.document_link}>
                      <a
                        href={document?.document_link}
                        target="_blank"
                        style={{
                          textDecoration: "underline",
                          fontFamily: "Source Sans Pro",
                          color: "#0094A7",
                          fontWeight: 400,
                          lineHeight: "16px",
                          fontSize: "16px",
                          fontStyle: "normal",
                        }}
                      >
                        {
                          this.truncateText(document.file?.name as string, 10) || 
                          this.truncateText(document?.attached_file, 10)
                        }
                      </a>
                      </Tooltip>
                    </CssCustomTableCell>
                    <CssCustomTableCell>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Box style={{ color: "#0094A7", height: '32px', width: '32px' }}>
                          <img src={document.image_link?document.image_link:personIconSvg} style={{height: '32px', width: '32px', borderRadius: "50%"}} alt="personIcon" />
                        </Box>
                        <CustomTypographyWeb isContent>{document.uploaded_by}</CustomTypographyWeb>
                      </Box>
                    </CssCustomTableCell>
                    <CssCustomTableCell>
                      {document.upload_date}
                    </CssCustomTableCell>

                    {this.props.mode && (
                      <CssCustomTableActionCell>
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                            <CustomButton
                            id="editOtherDocument"
                            data-testId="editOtherDocument"
                              colorVariant="tableRowEdit"
                              onClick={() => this.props.editDocument(index,document)} // Enter edit mode
                              style={{
                                width: "24px",
                                height: "24px",
                                visibility: document.file ? "hidden" : "visible"
                              }}
                              disabled={this.props.editDocumnetID !== -1 && this.props.editDocumnetID === index}
                            >
                              <img src={editDocButtonSvg} alt="edit" />
                            </CustomButton>

                          <CustomButton
                            colorVariant="tableRowDelete"
                            style={{
                              width: "24px",
                              height: "24px",
                            }}
                            data-testId="tableRowDelete"
                            onClick={() => {
                              this.props.handleDeleteDocument?.(document.document_id || document.id || 0, 'other')
                            }} // Delete the row
                          >
                            <img src={deleteButtonSvg} alt="delete" />
                          </CustomButton>
                        </Box>
                      </CssCustomTableActionCell>
                    )}
                  </CssCustomTableRow>
                ))}
            </TableBody>
          </CssCustomTable>
        </TableContainer>
      </Box>
    );
  }
}

const CssCustomTable = withStyles({
  root: {
    borderCollapse: "separate",
    borderSpacing: "0 15px",
    overflow: "hidden",
  },
})(Table);

const CssCustomTableRow = styled(TableRow)(({ theme }) => ({
  '&&': { // Add an extra level of specificity
    background: '#e9e9e9',
    borderRadius: '8px',
  },
}));

const CssCustomTableCell = withStyles({
  root: {
    padding: "0 12px",
    color: "#000",
    fontFamily: "Source Sans Pro",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    height: '40px'
  }
})(TableCell);
const CssCustomTableHeader = withStyles({
  root: {
    color: "#1F1F1F",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "16px",
  },
})(TableCell);

const CssCustomTableActionCell = withStyles({
  root: {
    padding: "0 8px",
    width: "100px",
  },
})(TableCell);

import React, { Component } from "react";
import { Backdrop, Box, Modal, Paper, styled } from "@material-ui/core";
import CustomTypographyWeb from "../CustomTypography.web";
import { CustomSaveSearchInput } from "../../../blocks/visualanalytics/src/assets";
import CustomButton from "../CustomButton.web";

interface Props {
  open: boolean;
  error: boolean;
  errorMsg: string;
  value: string;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  cancelSaveSearch: () => void;
  submitSaveSearch: () => void;
}

const CustomeModalSave = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "16px",
  backdropFilter: "blur(4px)",
  "& .mainDiv": {
    width: "80%",
    maxWidth: "440px",
    height: "auto",
    position: "relative",
    background: "#FFF",
    color: "#000",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    padding: "34px 24px",
    "& .topHeader": {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "16px",
    },
  },
});


export class CustomeSaveSearchPopper extends Component<Props, {}> {
  render() {
    const { open, error, errorMsg, value } = this.props;
    return (
      <CustomeModalSave
        aria-labelledby="transition-modal-title"
        open={open}
        aria-describedby="transition-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        onEscapeKeyDown={()=>this.props.cancelSaveSearch()}
      >
        <Paper className="mainDiv">
          <CustomTypographyWeb className="topHeader">
            Save this search result
          </CustomTypographyWeb>
          <CustomSaveSearchInput
            data-test-id={"module-seve-seach-id"}
            fullWidth
            placeholder="Enter name to this search for future"
            onChange={this.props.onChange}
            value={value}
            variant="outlined"
            error={error}
            helperText={error ? errorMsg : null}
          />
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "12px",
            }}
          >
            <CustomButton
              data-test-id="cancelSaveSearchId"
              colorVariant="special"
              width={107}
              height={45}
              onClick={() => this.props.cancelSaveSearch()}
            >
              Cancel
            </CustomButton>
            <CustomButton
              data-test-id="submitSavesearchId"
              width={107}
              height={45}
              onClick={() => this.props.submitSaveSearch()}
            >
              Save
            </CustomButton>
          </Box>
        </Paper>
           
      </CustomeModalSave>
    );
  }
}
